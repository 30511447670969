import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetInvestmentsResponse, GetInvestments } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IInvestmentsState extends ILoadableState {
    investments: GetInvestmentsResponse
}

export const initialInvestmentsState: IInvestmentsState = {
    investments: {} as GetInvestmentsResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'investments',
    initialState: {} as IInvestmentsState,
    reducers: {
        resetState: () => {
            return {
                ...initialInvestmentsState
            }
        },
        requestInvestments: (state: IInvestmentsState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveInvestments: (state: IInvestmentsState, action: PayloadAction<{ investments: GetInvestmentsResponse }>) => {
            const { investments } = action.payload;
            return {
                ...state,
                investments,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveInvestmentsError: (state: IInvestmentsState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialInvestmentsState)
    }
})

const { resetState, requestInvestments, receiveInvestments, receiveInvestmentsError } = slice.actions;

function fetchInvestments() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().investments;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestInvestments());

        try {

            const investments = await client.post(new GetInvestments());

            dispatch(receiveInvestments({ investments }));

            if (investments.investments?.length > 0)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receiveInvestmentsError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }

    }
}

export const investmentsActionCreators = {
    fetchInvestments,
    resetState
};

export const investmentsReducer = slice.reducer;
