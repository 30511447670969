import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from '@reduxjs/toolkit';
import { accountActionCreators } from '../../store/AccountStore';
import { GatewayPath } from '@wespath/gateway-navigation'
import { NavLink, Link } from 'react-router-dom';
import { Alert, Button, Spinner, Row, Col, Card } from 'react-bootstrap';
import { Logoff, RequestType } from '../../Gateway.dtos';
import { AppDispatch } from '../..';
import { client } from '../../App';
import { resetApplication } from '../../functions/resetApplication';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { cmsActionCreators } from '../../store/CmsStore';

type TLogoutProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const AccessDenied = (props: TLogoutProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "Benefits Access - Access Denied"

        if (stateNeedsLoading(props.cmsData))
            props.fetchCmsData();
    }, []);

    useEffect(() => {
        dispatch(resetApplication({ keepLoginHelpRequestType: true, keepPwLock: true }));

        client.post(new Logoff())
    }, [dispatch]);

    const defaultMsg = props.cmsData.fragments?.find(f => f.name === "AccessDeniedMsg")?.html;
    const regMsg = props.cmsData.fragments?.find(f => f.name === "AccessDeniedRegMsg")?.html;
    const pwMsg = props.cmsData.fragments?.find(f => f.name === "AccessDeniedPwMsg")?.html;

    const alertMsg = () => {
        if (props.requestType === RequestType.REGISTRATION)
            return regMsg;
        else if (props.isPwLocked)
            return pwMsg;
        else if (props.requestType === RequestType.NEEDHELP || props.isLocked)
            return defaultMsg;
        else 
            return defaultMsg;
    }

    const isLoginHelp = props.requestType === RequestType.NEEDHELP || props.requestType === RequestType.REGISTRATION;

    const colClassName = "col-12 col-lg-10 col-xl-9 loginBox";
    return (
        <Row className="justify-content-center mt-5">
            <Col className={colClassName}>
                <Card className="fullPageCard" style={{ minHeight: "30em" }}>
                    <Card.Body>

                        {alertMsg() && <Alert variant="danger" className="my-3">
                            {alertMsg()}
                        </Alert>}

                        {!isLoginHelp && props.isPwLocked && <Row className="justify-content-center mt-5">
                            <Col className="col-12 col-sm-5">
                                <NavLink to={GatewayPath.NeedHelp} activeClassName="active">
                                    <Button variant="primary" type="submit">
                                        {props.isLoading ? <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> :  "Reset Access"}
                                    </Button>
                                </NavLink>
                            </Col>
                        </Row>}

                        <div className="termsAndConditions mt-2">
                            <a href="https://www.wespath.org" target="_blank" rel="noopener noreferrer">
                                Wespath&apos;s Public Website
                            </a>

                            <span className="py-1 d-none d-sm-inline"> | </span>
                            <br className="d-inline d-sm-none" />

                            <Link to={GatewayPath.Login}>
                                Benefits Access Login
                            </Link>
                        </div>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function mapStateToProps(state: AppState) {
    const requestType = state.loginHelp.requestType;

    return {
        ...state.account,
        cmsData: state.cms.login,
        requestType
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...accountActionCreators, fetchCmsData: cmsActionCreators.fetchLoginCmsData }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccessDenied);
