import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import BenefitsAccessSso from '../BenefitsAccessSso';
import { AppState } from '../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../..';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { lifeStageInvestmentOptionsActionCreators } from '../../store/LifeStageInvestmentOptionsStore';
import { BAPageHeader } from '../BAPageHeader';
import { parseCmsContent } from '../../functions/parseCmsContent';
import { LabelAndContent } from '../LabelAndContent';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import BALayout from '../BALayout';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { accountActionCreators } from '../../store/AccountStore';
import { friendlyDate } from '../../functions/friendlyDate';
import { getOrdinalSuffixDate } from '../../functions/getOrdinalSuffixDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { InvestmentMix } from '../LifeStageInvestmentMix';
import { cmsActionCreators } from '../../store/CmsStore';

type TLifeStageInvestmentOptionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const labelAndContentClass = "col-12 mb-3";
const LifeStageInvestmentOptions = (props: TLifeStageInvestmentOptionsProps): ReactElement => {
    const hasError = props.loadState === DataLoadState.Error

    const dispatch: AppDispatch = useDispatch();
    const [expandAll, setExpandAll] = useState(false);
    const [isLoading, setIsLoading] = useState(stateNeedsLoading(props));
    const [targetAllocationCols, setTargetAllocationCols] = useState(6);

    //Not used any where.
    //const cmsDisplayCenterTopMessage = props.cmsData.fragments?.find(f => f.name === "CenterTopMessage")?.html ?? '';
    const cmsDisplayCenterMessage = props.cmsData.fragments?.find(f => f.name === "CenterMessage")?.html ?? '';
    const cmsLifeStageDisplayMessage = props.cmsData.fragments?.find(f => f.name === "LifeStageDisplayMessage")?.html ?? '';
    const cmsLifeStageNotAvailableMessage = props.cmsData.fragments?.find(f => f.name === "LifeStageNotAvailableMessage")?.html ?? '';
    const cmsUnderstandTargetContent = props.cmsData.fragments?.find(f => f.name === "CenterBottomMessage")?.html ?? '';

    useEffect(() => {
        document.title = "Benefits Access - LifeStage Investment Management Service";
        setIsLoading(true);
        dispatch(lifeStageInvestmentOptionsActionCreators.fetchLifeStageInvestmentOptions())
            .then(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //useEffect(() => {
    //    if (stateNeedsLoading(props)) {
    //        dispatch(lifeStageInvestmentOptionsActionCreators.fetchLifeStageInvestmentOptions())
    //            .then(() => {
    //                setIsLoading(false);
    //            })
    //    }
    //});

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchLifeStageInvestmentOptionCmsData();
        }
    });

    useEffect(() => {
        if (!isLoading && !hasError) {
            if (props.lifeStageInvestmentOptions?.invMppDisplay && props.lifeStageInvestmentOptions?.invLsriDisplay && props.lifeStageInvestmentOptions.invDcaDisplay) {
                setTargetAllocationCols(4);
            }
        }
    }, [isLoading, hasError,
        props.lifeStageInvestmentOptions?.invMppDisplay,
        props.lifeStageInvestmentOptions?.invLsriDisplay,
        props.lifeStageInvestmentOptions.invDcaDisplay
    ]);

    const participantDetails = {
        Dob: '1/1/1900'
    }

    return (<>
        <BALayout>

            {isLoading && <LoadingSkeletonFullpage />}

            {(!isLoading) &&
                <Row>
                    <Col>
                        <BAPageHeader name="LifeStage Investment Management Service" />

                        {hasError &&
                            <Alert className="my-3" variant="danger">
                                <div>Error Occurred : {props.errorMessage}</div>
                            </Alert>
                        }

                        {!hasError && <>

                            {props.lifeStageInvestmentOptions.lifeStageDisplay ?
                                (
                                    <>
                                        <p className="mt-2"
                                            dangerouslySetInnerHTML={parseCmsContent(cmsLifeStageDisplayMessage)}
                                        />

                                    </>
                                )
                                :
                                (props.lifeStageInvestmentOptions.lifeStageAvailable?.toUpperCase() === 'N') ?
                                    (

                                        <p className="mt-2"
                                            dangerouslySetInnerHTML={parseCmsContent(cmsLifeStageNotAvailableMessage)}
                                        />

                                    )
                                    :
                                    (<>
                                        {/*<div>Page starts from here </div>*/}
                                        <p className="mt-2" dangerouslySetInnerHTML={parseCmsContent(cmsDisplayCenterMessage)} />

                                        <p className="pt-1">
                                            {props.lifeStageInvestmentOptions.lifeStageViewMsg}
                                        </p>
                                        <Row>
                                            <Col md={6} className="mx-3">
                                                <Row>
                                                    <Col className="border rounded">
                                                        {props.lifeStageInvestmentOptions.profileDisplay &&
                                                            <>
                                                                <h4 className="text-center text-gold my-3">Your Personal Investment Profile</h4>
                                                                {props.lifeStageInvestmentOptions.mppLifeStageDisplay &&
                                                                    <Row>
                                                                        <LabelAndContent className={labelAndContentClass} label="Ministerial Pension Plan Account Managed by LifeStage:">Required</LabelAndContent>
                                                                    </Row>

                                                                }
                                                                {props.lifeStageInvestmentOptions.lsriLifeStageDisplay &&
                                                                    <Row>
                                                                        <LabelAndContent className={labelAndContentClass} label="LifeStage Retirement Income Account Managed by LifeStage:">Required</LabelAndContent>
                                                                    </Row>

                                                                }
                                                                {props.lifeStageInvestmentOptions.dcaLifeStageDisplay &&
                                                                    <>
                                                                        <Row>
                                                                            <LabelAndContent className={labelAndContentClass} label="Defined Contribution Account Managed by LifeStage:">
                                                                                {props.lifeStageInvestmentOptions.dcaLifeStageMsg}
                                                                            </LabelAndContent>
                                                                        </Row>
                                                                        {/*{(props.pageData.dcalifeStageMsg.toUpperCase() == 'YES' || props.pageData.dcalifeStageMsg.toUpperCase() == 'NO') &&
                                                                        <Row>
                                                                            <LabelAndContent className={labelAndContentClass} label="Defined Contribution Account Managed by LifeStage:">{props.pageData.dcalifeStageMsg}</LabelAndContent>
                                                                        </Row>*/
                                                                        }
                                                                    </>
                                                                }

                                                                {(props.lifeStageInvestmentOptions.participantDob !== '' && props.lifeStageInvestmentOptions.participantDob !== participantDetails.Dob && props.lifeStageInvestmentOptions.dobDisplay) &&

                                                                    <Row>
                                                                        <LabelAndContent className={labelAndContentClass} label="Date of Birth:">{friendlyDate(props.lifeStageInvestmentOptions.participantDob)}</LabelAndContent>
                                                                    </Row>
                                                                }

                                                                {props.lifeStageInvestmentOptions.riskToleranceDisplay &&
                                                                    <Row>
                                                                        <LabelAndContent className={labelAndContentClass} label="Risk Tolerance:">{props.lifeStageInvestmentOptions.riskTolerance}</LabelAndContent>
                                                                    </Row>
                                                                }

                                                                {props.lifeStageInvestmentOptions.socialSecurityDisplay &&

                                                                    <Row>
                                                                        <LabelAndContent className={labelAndContentClass} label="Social Security Eligibility:">{props.lifeStageInvestmentOptions.socialSecurityValue}</LabelAndContent>
                                                                    </Row>

                                                                }
                                                                {props.lifeStageInvestmentOptions.mppExpectedBcdKeyValue?.toUpperCase() === 'AN' ?
                                                                    (
                                                                        <Row>
                                                                            <LabelAndContent className={labelAndContentClass} label="Expected Benefits Commencement Date:">Receiving Benefits</LabelAndContent>
                                                                        </Row>

                                                                    ) : (
                                                                        <>
                                                                            {props.lifeStageInvestmentOptions.mppExpectedBcdOption &&
                                                                                <> {props.lifeStageInvestmentOptions.mppExpectedBcd &&
                                                                                    <Row>
                                                                                        <LabelAndContent className={labelAndContentClass} label="Expected Benefits Commencement Date:">{getOrdinalSuffixDate(props.lifeStageInvestmentOptions.mppExpectedBcdKeyValue)}</LabelAndContent>
                                                                                    </Row>

                                                                                }
                                                                                    {props.lifeStageInvestmentOptions.mppExpectedAgeDisplay &&

                                                                                        <Row noGutters className="d-flex align-items-end mt-2 mb-2">
                                                                                            <Col xs={12}> You expect to be age {props.lifeStageInvestmentOptions.mppExpectedAge} when you begin receiving benefits.</Col>
                                                                                        </Row>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                                {props.lifeStageInvestmentOptions.mppIntention &&
                                                                    <Row>
                                                                        <LabelAndContent className={labelAndContentClass} label="Intention for Non-Annuitized MPP Balance:">{props.lifeStageInvestmentOptions.mppIntentionValue}</LabelAndContent>
                                                                    </Row>
                                                                }
                                                                {/*Added Row- Col instead of P tag using P tag link is not diplaying Properly in IE browser.*/}
                                                                <Row className="pb-3">
                                                                    <Col xs={12}>
                                                                        <BenefitsAccessSso to={`/takeaction_lifestage.aspx`} className="customLink ml-auto"><span className="position-relative">Change LifeStage Personal Investment Profile</span></BenefitsAccessSso>
                                                                    </Col>
                                                                </Row>

                                                            </>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {props.lifeStageInvestmentOptions.invTotalDisplay &&
                                                <>
                                                    <Col className="mx-md-0 px-md-0 mt-3">
                                                        {/*<h4 className="text-center">Your Total LifeStage Target Investment Mix</h4>*/}

                                                        {!props.lifeStageInvestmentOptions.invTotalSectionDisplay && <>
                                                            <h4 className="text-center">Your Total LifeStage Target Investment Mix</h4>
                                                            {props.lifeStageInvestmentOptions.invTotalMessage}
                                                        </>}

                                                        {props.lifeStageInvestmentOptions.invTotalSectionDisplay && props.lifeStageInvestmentOptions.invTotalOptions &&
                                                            <InvestmentMix investmentTitle={"Your Total LifeStage Target Investment Mix"} investmentMixData={props.lifeStageInvestmentOptions.invTotalOptions} />
                                                        }
                                                    </Col>
                                                </>}
                                        </Row>
                                        {props.lifeStageInvestmentOptions.understandTargetDisplay && <>
                                            <Row>
                                                <Col>
                                                    <p className="mt-4" dangerouslySetInnerHTML={parseCmsContent(cmsUnderstandTargetContent)} />
                                                </Col>
                                            </Row>
                                        </>}
                                        {props.lifeStageInvestmentOptions.targetAllocationsDisplay &&
                                            <> <Row className="mb-3 mt-3">
                                                <Col>
                                                    <Button variant="link" className="py-2" onClick={() => { setExpandAll(!expandAll) }}>
                                                        <FontAwesomeIcon className="mr-2 mt-1" icon={expandAll ? faMinusSquare : faPlusSquare} /> Your account specific target allocations
                                                    </Button>
                                                </Col>
                                            </Row>
                                                {expandAll &&
                                                    <>
                                                        <Row>
                                                            {props.lifeStageInvestmentOptions.invMppDisplay && <>
                                                                <Col md={targetAllocationCols}>
                                                                    {/*<div className="row">*/}
                                                                    {/*    <h4 className="text-center mt-2 pb-0">*/}
                                                                    {/*        Ministerial Pension Plan Account*/}
                                                                    {/*    </h4>*/}
                                                                    {/*</div>*/}
                                                                    <InvestmentMix investmentTitle={"Ministerial Pension Plan Account"} investmentMixData={props.lifeStageInvestmentOptions.invMppOptions} />
                                                                </Col>
                                                            </>}
                                                            {props.lifeStageInvestmentOptions.invLsriDisplay && <>
                                                                <Col md={targetAllocationCols}>
                                                                    {/*<div className="row">*/}
                                                                    {/*    <h4 className="text-center mt-4 mt-sm-2 pb-0 pb-md-4 pb-lg-0">*/}
                                                                    {/*        LifeStage Retirement Income Account*/}
                                                                    {/*    </h4>*/}
                                                                    {/*</div>*/}
                                                                    <InvestmentMix investmentTitle={"LifeStage Retirement Income Account"} investmentMixData={props.lifeStageInvestmentOptions.invLsriOptions} />
                                                                </Col>
                                                            </>}
                                                            {props.lifeStageInvestmentOptions.invDcaDisplay && <>
                                                                <Col md={targetAllocationCols}>
                                                                    {/*<div className="row">*/}
                                                                    {/*    <h4 className="text-center mt-4 mt-sm-2 pb-0 pb-md-4 pb-lg-0">*/}
                                                                    {/*        Defined Contribution Account*/}
                                                                    {/*    </h4>*/}
                                                                    {/*</div>*/}
                                                                    <InvestmentMix investmentTitle={"Defined Contribution Account"} investmentMixData={props.lifeStageInvestmentOptions.invDcaOptions} />
                                                                </Col>
                                                            </>}
                                                        </Row>
                                                        <Row className="ml-3 mt-3">
                                                            <p>{'See "Learn More" for how LifeStage determines these investment mixes.'}</p>
                                                        </Row>
                                                    </>
                                                }

                                            </>
                                        }
                                    </>
                                    )
                            }
                        </>
                        }

                    </Col>
                </Row>

            }


        </BALayout>

    </>
    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.lifeStateInvestmentOptions,
        cmsData: state.cms.lifeStageInvestmentOption
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { accessDenied } = accountActionCreators;

    return bindActionCreators({
        accessDenied,
        fetchLifeStageInvestmentOptionCmsData: cmsActionCreators.fetchLifeStageInvestmentOptionCmsData
    }, dispatch);
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LifeStageInvestmentOptions);

