import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetTransactionsResponse, GetTransactions } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface ITransactionsState extends ILoadableState {
    transactions: GetTransactionsResponse
}

export const initialTransactionsState: ITransactionsState = {
    transactions: {} as GetTransactionsResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'transactions',
    initialState: {} as ITransactionsState,
    reducers: {
        resetState: () => {
            return {
                ...initialTransactionsState
            }
        },
        requestTransactions: (state: ITransactionsState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveTransactions: (state: ITransactionsState, action: PayloadAction<{ transactions: GetTransactionsResponse }>) => {
            const { transactions } = action.payload;
            return {
                ...state,
                transactions,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveTransactionsError: (state: ITransactionsState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialTransactionsState)
    }
})

const { resetState, requestTransactions, receiveTransactions, receiveTransactionsError } = slice.actions;

function fetchTransactions() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().transactions;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestTransactions());

        try {

            const transactions = await client.post(new GetTransactions());

            dispatch(receiveTransactions({ transactions }));

            if (transactions.transactions?.length > 0)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receiveTransactionsError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }

    }
}

export const transactionsActionCreators = {
    fetchTransactions,
    resetState
};

export const transactionsReducer = slice.reducer;
