import React, { ReactElement } from 'react';
import { Button, Row, Col, Card } from 'react-bootstrap';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { activityActionCreators } from '../store/ActivityStore';
import { useDispatch } from 'react-redux';

const PageTitle = (props: { title: string, hidePrintButton?: boolean, printBtnText?: string, color?: string }): ReactElement => {
    const dispatch = useDispatch();

    const handlePrint = () => {
        dispatch(activityActionCreators.logActivity("button_print"));
        window.print();
        return false;
    }

    return (
        <Card.Header className={`${props.color}`}>
            <Row className="titleRow">
                <Col className="col-12 col-md-8">
                    {props.title}
                </Col>
                {!props.hidePrintButton && <Col className="desktop col-5 col-md-4 printButton">
                    <Button variant="outline-secondary" onClick={() => handlePrint()}>
                        <FontAwesomeIcon icon={faPrint} />
                        <span className="pl-1">{props.printBtnText ?? 'Print Summary'}</span>
                    </Button>
                </Col>}
            </Row>
        </Card.Header>

    );
}
export { PageTitle };