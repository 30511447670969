import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Row, Col, Alert, Table, Card } from 'react-bootstrap';
import { AppState } from '../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../..';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { useWindowDimensions } from '@wespath/gateway-navigation';
import { investmentsSummaryActionCreators } from '../../store/InvestmentsSummaryStore';
import { BAPageHeader } from '../BAPageHeader';
import BALayout from '../BALayout';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import { GetInvestmentsSummaryResponse, InvestmentSummaryDetails, InvestmentSummaryTotalDetails } from '../../Gateway.dtos';
import { SectionHeader } from '../SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { InvestmentCurrentAllocationGraphs } from './Investments/InvestmentCurrentAllocationGraphs';
import { MobilePlanGridOptionGrid, PlanGridOptionGrid } from './Investments/PlanGridOptionGrid';
import { MobileNetRateofReturnGrid, NetRateofReturnGrid } from './Investments/NetRateofReturnGrid';
import { FormatDollar } from '../FormatDollar';
import { cmsActionCreators } from '../../store/CmsStore';
import { bindActionCreators } from 'redux';
import { MobileLabelAndContent } from '../MobileLabelAndContent';
import BenefitsAccessSso from '../BenefitsAccessSso';
import { push } from 'connected-react-router';
import { BAConvertedRoutes } from '../../definitions/BAConvertedRoutes';

type TInvestmentsSummaryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const InvestmentsSummary = (props: TInvestmentsSummaryProps): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(stateNeedsLoading(props));
    const { investmentsSummary } = props;
    const cmsFooterNotesData = props.cmsData.fragments?.find(f => f.name === "FooterNotesMessage")?.html ?? '';
    const cmsBalanceMessage = props.cmsData.fragments?.find(f => f.name === "BalanceMessage")?.html ?? '';
    const cmsNoFundBalances = props.cmsData.fragments?.find(f => f.name === "NoFundBalances")?.html ?? '';

    const cmsMppLifeStageManagedMessage = props.cmsData.fragments?.find(f => f.name === "MppLifeStageManagedMessage")?.html ?? '';
    const cmsMppSelfManagedMessage = props.cmsData.fragments?.find(f => f.name === "MppSelfManagedMessage")?.html ?? '';
    const cmsMppNonSpouseBene = props.cmsData.fragments?.find(f => f.name === "MppNonSpouseBene")?.html ?? '';

    const cmsLsriLifeStageManagedMessage = props.cmsData.fragments?.find(f => f.name === "LsriLifeStageManagedMessage")?.html ?? '';

    const cmsDcLifeStageManagedMessage = props.cmsData.fragments?.find(f => f.name === "DcLifeStageManagedMessage")?.html ?? '';
    const cmsDcSelfManagedMessage = props.cmsData.fragments?.find(f => f.name === "DcSelfManagedMessage")?.html ?? '';
    const cmsDcNonSpouseBene = props.cmsData.fragments?.find(f => f.name === "MppDcSpouseBene")?.html ?? '';

    const { isSmallScreen, isMediumScreen } = useWindowDimensions();

    let planNames = "";
    let totalPlanNames = "";

    useEffect(() => {
        document.title = "Benefits Access - Investments Summary"
    }, []);

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            dispatch(investmentsSummaryActionCreators.fetchInvestmentsSummary())
                .then(() => {
                    setIsLoading(false);
                })
        }
    });

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchInvestmentsSummaryCmsData();
        }
    });

    const PageTitle = () => (
        <BAPageHeader name="Investments" />
    )

    const MobileInvestmentFundSummaryGrid = (props: {
        investmentSummaryResponse: GetInvestmentsSummaryResponse,
    }) => {
        const { investmentSummaryResponse } = props;
        return (<>
            <div className="summary-table-mobile">
                {investmentSummaryResponse.investmentSummary?.map((lstPlanSummary, i) =>
                    <React.Fragment key={"sumtr" + i}>
                        <Row className="summary-table-mobile-type">
                            <Col className="col-7 px-0 mx-0">
                                <a className="secondary" style={{ whiteSpace: "nowrap" }} href={lstPlanSummary.link} target="_blank" rel="noreferrer">
                                    {lstPlanSummary.name}
                                </a>
                            </Col>
                            <Col className="col-5 text-right px-0 mx-0">
                                <FormatDollar amount={lstPlanSummary.total} />
                            </Col>
                        </Row>

                        <MobileCard lstPlanSummary={lstPlanSummary} />

                    </React.Fragment>
                )
                }

            </div>

            <div className="summary-table-mobile">
                {investmentSummaryResponse.investmentSummaryTotal &&
                    <React.Fragment key="sumtr">
                        <Row className="summary-table-mobile-type">
                            <Col className="col-12 px-0 mx-0">
                                Plan Total
                            </Col>
                        </Row>

                        <MobileCardTotal lstPlanSummaryTotals={investmentSummaryResponse.investmentSummaryTotal} />

                    </React.Fragment>
                }
            </div>
        </>)
    }


    function MobileCardRow(lstPlanSummary: InvestmentSummaryDetails, currentCounter: number) {

        if (lstPlanSummary.plan.length === currentCounter + 1) {
            if (!planNames.includes(lstPlanSummary.plan[currentCounter].name)) {
                planNames = "";

                return (
                    <MobileLabelAndContent
                        label={lstPlanSummary.plan[currentCounter].name} value={<FormatDollar amount={lstPlanSummary.plan[currentCounter].total} />} />
                )
            }
            planNames = "";
        }
        else if (lstPlanSummary.plan.length > currentCounter + 1) {
            if (!planNames.includes(lstPlanSummary.plan[currentCounter].name)) {
                planNames = planNames + "," + lstPlanSummary.plan[currentCounter + 1].name;

                return (
                    <MobileLabelAndContent
                        label={lstPlanSummary.plan[currentCounter].name} value={<FormatDollar amount={lstPlanSummary.plan[currentCounter].total} />}
                        label2={lstPlanSummary.plan[currentCounter + 1].name} value2={<FormatDollar amount={lstPlanSummary.plan[currentCounter + 1].total} />} />
                )
            }
        }
    }

    function MobileCardRowTotal(lstPlanSummaryTotals: InvestmentSummaryTotalDetails[], currentCounter: number) {

        if (lstPlanSummaryTotals.length === currentCounter + 1) {
            if (!totalPlanNames.includes(lstPlanSummaryTotals[currentCounter].name)) {
                totalPlanNames = "";

                return (
                    <MobileLabelAndContent
                        label={lstPlanSummaryTotals[currentCounter].name} value={<FormatDollar amount={lstPlanSummaryTotals[currentCounter].total} />} />
                )
            }
            totalPlanNames = "";
        }
        else if (lstPlanSummaryTotals.length > currentCounter + 1) {
            if (!totalPlanNames.includes(lstPlanSummaryTotals[currentCounter].name)) {
                totalPlanNames = totalPlanNames + "," + lstPlanSummaryTotals[currentCounter + 1].name;

                return (
                    <MobileLabelAndContent
                        label={lstPlanSummaryTotals[currentCounter].name} value={<FormatDollar amount={lstPlanSummaryTotals[currentCounter].total} />}
                        label2={lstPlanSummaryTotals[currentCounter + 1].name} value2={<FormatDollar amount={lstPlanSummaryTotals[currentCounter + 1].total} />} />
                )
            }
        }
    }


    const MobileCard = (props: { lstPlanSummary: InvestmentSummaryDetails }) => {
        const { lstPlanSummary } = props;
        if (lstPlanSummary && lstPlanSummary.plan) {

            return (
                <Card className="summary-table-mobile-card">
                    <Card.Body>
                        {
                            lstPlanSummary.plan?.map((pl, i) => MobileCardRow(lstPlanSummary, i))
                        }
                    </Card.Body>
                </Card>
            );
        }
        return (<></>);
    }

    const MobileCardTotal = (props: { lstPlanSummaryTotals: InvestmentSummaryTotalDetails[] }) => {
        const { lstPlanSummaryTotals } = props;
        if (lstPlanSummaryTotals) {

            return (
                <Card className="summary-table-mobile-card">
                    <Card.Body>
                        {
                            lstPlanSummaryTotals?.map((plt, i) => MobileCardRowTotal(lstPlanSummaryTotals, i))
                        }
                    </Card.Body>
                </Card>
            );
        }
        return (<></>);
    }

    interface InvestmentFundSummaryGridRowProps { investmentPlanSummary: InvestmentSummaryDetails, i: number }
    function InvestmentFundSummaryGridRow(props: InvestmentFundSummaryGridRowProps) {
        const invPlanSummary = props.investmentPlanSummary;
        return (
            <>
                <tr className="border-bottom">
                    <td className="text-left border-left border-right">
                        <a className="secondary" style={{ whiteSpace: "nowrap" }} title={invPlanSummary.tooltip} href={invPlanSummary.link} target="_blank" rel="noreferrer">
                            {invPlanSummary.name}
                        </a>
                    </td>
                    {
                        invPlanSummary.plan?.map((lstPlan, i) => {
                            return (
                                <td className="border-right text-right" key={i}>
                                    <FormatDollar amount={lstPlan.total} />
                                </td>
                            )
                        })
                    }
                    <td className="text-right border-left border-right"><FormatDollar amount={invPlanSummary.total} /></td>
                </tr>
            </>
        );
    }

    function InvestmentFundSummaryGrid(props: {
        investmentSummaryResponse: GetInvestmentsSummaryResponse,
    }) {
        const { investmentSummaryResponse } = props;
        return (
            <>
                <div>
                    <Table size="sm" className="table-striped-ba">
                        <thead>
                            <tr className="pl-2">
                                <th className="border-left border-right border-bottom-none">Fund Name</th>
                                {
                                    investmentSummaryResponse.investmentSummaryPlan?.map((lstPlan, i) => {
                                        return (
                                            <th className="border-left border-right border-bottom-none text-right" key={i}>
                                                <a className="secondary" title={lstPlan.tooltip} href={lstPlan.link} target="_blank" rel="noreferrer">
                                                    {lstPlan.name}
                                                </a>
                                            </th>
                                        )
                                    })
                                }
                                <th className="border-left border-right border-bottom-none">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                investmentSummaryResponse.investmentSummary?.map((lstPlanSummary, i) => {
                                    return (
                                        <InvestmentFundSummaryGridRow investmentPlanSummary={lstPlanSummary} i={i} key={i} />
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr className="border-top border-bottom pl-2">
                                <td className="border-left border-right">&nbsp;</td>
                                {
                                    investmentSummaryResponse.investmentSummaryTotal?.map((lstPlanTotal, i) => {
                                        return (
                                            <td className="text-right border-right" key={i}><strong><FormatDollar amount={lstPlanTotal.total} /></strong></td>
                                        )
                                    })
                                }
                                <td className="text-right border-right"><strong><FormatDollar amount={investmentSummaryResponse.investmentSummaryPlanTotal} /></strong></td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </>
        );
    }

    function InvestmentFundSummary() {
        return (<><SectionHeader name="Investment Fund Summary" className="my-3" suppressHr />
            {isSmallScreen || isMediumScreen ? <MobileInvestmentFundSummaryGrid investmentSummaryResponse={investmentsSummary} /> : <InvestmentFundSummaryGrid investmentSummaryResponse={investmentsSummary} />}
        </>);
    }

    function FooterNotes() {
        return (<>
            <p className="mt-5" dangerouslySetInnerHTML={{ __html: cmsFooterNotesData }} />

            <p className="mt-5"><a href="http://www.wespath.org/sri_funds/performance/home.asp" target="_blank" rel="noreferrer">View Investment Fund Performance</a></p>
        </>);
    }

    const handlePrint = () => {
        window.print();
        return false;
    }

    const ChangeInvestmentCurrentBalanceLink = () => (
        <Row className="pt-3 pb-3 pt-md-4 pb-md-4">
            <Col xs={12}>
                <BenefitsAccessSso to={`/takeaction_current_balance.aspx`} className="customLink ml-auto"><span className="position-relative">Change Investment Of Current Balance</span></BenefitsAccessSso>
            </Col>
        </Row>
    )

    const TextWithSeeDetailsLink = (props: { content: string }) => (<>

        <span className="text-left" dangerouslySetInnerHTML={{ __html: props.content.replace("$SeeDetailsLink", "") }} />

        {props.content.includes("$SeeDetailsLink") && <span className="btn-link p-0" style={{cursor: 'pointer'}} onClick={() => (dispatch(push(BAConvertedRoutes.LifeStageInvestmentOptions)))}>
            See details and investment allocations
        </span>}

    </>)

    return (
        <BALayout>

            {isLoading && <LoadingSkeletonFullpage />}

            {!isLoading && <>

                {props.loadState === DataLoadState.Error && <>
                    <PageTitle />
                    <Alert variant="error">
                        {props.errorMessage}
                    </Alert>
                </>}

                <div className="text-right m-0 p-0 pt-0 pt-md-1">
                    <div className="d-flex flex-wrap bottom-border-dotted mb-3 position-relative pagemainsubtitle">
                        <span className="pagesubtitleprint">
                            <Button onClick={() => handlePrint()} target="_self" className="secondary" variant="link"><FontAwesomeIcon className="mr-2 mt-1" icon={faPrint} />Print</Button>
                        </span>
                        <span className="pagesubtitledate">as of {investmentsSummary.previousBusinessDay}</span>
                        <PageTitle />
                    </div>
                </div>

                {(!investmentsSummary.dcBalance && !investmentsSummary.mppBalance && !investmentsSummary.lsriBalance)
                    ? <p className="text-left" dangerouslySetInnerHTML={{ __html: cmsNoFundBalances }} />
                    : <>
                        <p className="text-left" dangerouslySetInnerHTML={{ __html: cmsBalanceMessage }} />

                        {(investmentsSummary.dcBalance || investmentsSummary.mppBalance || investmentsSummary.lsriBalance) && <InvestmentFundSummary />}

                        {(investmentsSummary.mppBalance) && <>
                            <SectionHeader name={investmentsSummary.byplanGroupMPPName} title="Your Ministerial Pension Plan (MPP) account balance." className="my-3" suppressHr />
                            <Row>
                                <Col className="col-12 col-sm-6">
                                    {(investmentsSummary.byplanGroupMPPAccountMgmt === 'lifestage') && <TextWithSeeDetailsLink content={cmsMppLifeStageManagedMessage}/>}
                                    {(investmentsSummary.byplanGroupMPPAccountMgmt === 'self' && investmentsSummary.view === 'standard') && <TextWithSeeDetailsLink content={cmsMppSelfManagedMessage}/>}
                                    {(investmentsSummary.byplanGroupMPPAccountMgmt === 'nonspousebene') && <p className="pt-2" dangerouslySetInnerHTML={{ __html: cmsMppNonSpouseBene }} />}
                                </Col>
                                <Col className="victoryPieSvg">
                                    <div className="victoryPieSvgMargin">
                                        <InvestmentCurrentAllocationGraphs planGroupOption={investmentsSummary.byPlanGroupOptionMPP} />
                                    </div>
                                </Col>
                            </Row>
                            {isSmallScreen || isMediumScreen
                                ? <MobilePlanGridOptionGrid planGroupOption={investmentsSummary.byPlanGroupOptionMPP} planGroupBalanceTotal={investmentsSummary.byPlanGroupMPPBalanceTotal} planGroupRatioTotal={investmentsSummary.byPlanGroupMPPRatioTotal} />
                                : <PlanGridOptionGrid planGroupOption={investmentsSummary.byPlanGroupOptionMPP} planGroupBalanceTotal={investmentsSummary.byPlanGroupMPPBalanceTotal} planGroupRatioTotal={investmentsSummary.byPlanGroupMPPRatioTotal} />
                            }
                            {investmentsSummary.changeInvestmentsMPP &&
                                <ChangeInvestmentCurrentBalanceLink />
                            }
                            <p>{investmentsSummary.footer}</p>
                            {isSmallScreen || isMediumScreen
                                ? <MobileNetRateofReturnGrid netRateofReturn={investmentsSummary.netRateofReturnDetailMPP} />
                                : <Row>
                                    <Col className="col-sm-3"><strong title="The total investment gains or losses (after all fees and expenses are deducted) divided by the initial investment cost.">Net Rate of Return*</strong></Col>
                                    <Col className="col-sm-9"><NetRateofReturnGrid netRateofReturn={investmentsSummary.netRateofReturnDetailMPP} /></Col>
                                </Row>
                            }
                        </>}

                        {(investmentsSummary.dcBalance) && <>
                            <hr />
                            <SectionHeader name={investmentsSummary.byplanGroupDCAName} title="Your Defined Contribution Account includes your balances, if any, in CRSP DC, RPGA, UMPIP and Horizon." className="my-3" suppressHr />
                            <Row>
                                <Col className="col-12 col-sm-6">
                                    {(investmentsSummary.byplanGroupDCAAccountMgmt === 'lifestage') && <TextWithSeeDetailsLink content={cmsDcLifeStageManagedMessage}/>}
                                    {(investmentsSummary.byplanGroupDCAAccountMgmt === 'self' && investmentsSummary.view === 'standard') && <TextWithSeeDetailsLink content={cmsDcSelfManagedMessage}/>}
                                    {(investmentsSummary.byplanGroupDCAAccountMgmt === 'nonspousebene') && <p className="text-left" dangerouslySetInnerHTML={{ __html: cmsDcNonSpouseBene }} />}
                                </Col>
                                <Col className="victoryPieSvg">
                                    <div className="victoryPieSvgMargin">
                                        <InvestmentCurrentAllocationGraphs planGroupOption={investmentsSummary.byPlanGroupOptionDCA} />
                                    </div>
                                </Col>
                            </Row>
                            {isSmallScreen || isMediumScreen
                                ? <MobilePlanGridOptionGrid planGroupOption={investmentsSummary.byPlanGroupOptionDCA} planGroupBalanceTotal={investmentsSummary.byPlanGroupDCABalanceTotal} planGroupRatioTotal={investmentsSummary.byPlanGroupDCARatioTotal} />
                                : <PlanGridOptionGrid planGroupOption={investmentsSummary.byPlanGroupOptionDCA} planGroupBalanceTotal={investmentsSummary.byPlanGroupDCABalanceTotal} planGroupRatioTotal={investmentsSummary.byPlanGroupDCARatioTotal} />
                            }
                            {investmentsSummary.changeInvestmentsDCA &&
                                <ChangeInvestmentCurrentBalanceLink />
                            }
                            {isSmallScreen || isMediumScreen
                                ? <MobileNetRateofReturnGrid netRateofReturn={investmentsSummary.netRateofReturnDetailDCA} />
                                : <Row>
                                    <Col className="col-sm-3"><strong title="The total investment gains or losses (after all fees and expenses are deducted) divided by the initial investment cost.">Net Rate of Return*</strong></Col>
                                    <Col className="col-sm-9"><NetRateofReturnGrid netRateofReturn={investmentsSummary.netRateofReturnDetailDCA} /></Col>
                                </Row>
                            }
                        </>}

                        {(investmentsSummary.lsriBalance) && <>
                            <hr />
                            <SectionHeader name={investmentsSummary.byplanGroupLSRIName} title="Your LifeStage Retirement account balance." className="my-3" suppressHr />
                            <Row>
                                <Col className="col-12 col-sm-6">
                                    {(investmentsSummary.byplanGroupLSRIAccountMgmt === 'lifestage') && <TextWithSeeDetailsLink content={cmsLsriLifeStageManagedMessage}/>}
                                </Col>
                                <Col className="victoryPieSvg">
                                    <div className="victoryPieSvgMargin">
                                        <InvestmentCurrentAllocationGraphs planGroupOption={investmentsSummary.byPlanGroupOptionLSRI} />
                                    </div>
                                </Col>
                            </Row>
                            {isSmallScreen || isMediumScreen
                                ? <MobilePlanGridOptionGrid planGroupOption={investmentsSummary.byPlanGroupOptionLSRI} planGroupBalanceTotal={investmentsSummary.byPlanGroupLSRIBalanceTotal} planGroupRatioTotal={investmentsSummary.byPlanGroupLSRIRatioTotal} />
                                : <PlanGridOptionGrid planGroupOption={investmentsSummary.byPlanGroupOptionLSRI} planGroupBalanceTotal={investmentsSummary.byPlanGroupLSRIBalanceTotal} planGroupRatioTotal={investmentsSummary.byPlanGroupLSRIRatioTotal} />
                            }
                            {investmentsSummary.changeInvestmentsLSRI &&
                                <ChangeInvestmentCurrentBalanceLink />
                            }
                            {isSmallScreen || isMediumScreen
                                ? <MobileNetRateofReturnGrid netRateofReturn={investmentsSummary.netRateofReturnDetailLSRI} />
                                : <Row className="pt-4">
                                    <Col className="col-sm-3"><strong title="The total investment gains or losses (after all fees and expenses are deducted) divided by the initial investment cost.">Net Rate of Return*</strong></Col>
                                    <Col className="col-sm-9"><NetRateofReturnGrid netRateofReturn={investmentsSummary.netRateofReturnDetailLSRI} /></Col>
                                </Row>
                            }
                        </>}

                        {(investmentsSummary.dcBalance || investmentsSummary.mppBalance || investmentsSummary.lsriBalance) &&
                                <FooterNotes />
                        }
                    </>}
                </>}
        </BALayout>
    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.investmentsSummary,
        cmsData: state.cms.investmentsSummary,
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchInvestmentsSummaryCmsData: cmsActionCreators.fetchInvestmentsSummaryCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvestmentsSummary);

