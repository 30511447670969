import { AppState } from "./configureStore";
import { GetBannerOptionsResponse, GetBannerOptions } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

interface Error {
    errorMessage: string | unknown
}

export interface IBannerOptionsState extends IEnumLoadableState {
    showCompassBanner: boolean
}

export const initialBannerOptions: IBannerOptionsState = {
    showCompassBanner: false,
    loadState: DataLoadState.None
};

const fetchBannerOptions = createAsyncThunk<
    GetBannerOptionsResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'bannerOptions/fetch',
    async (_, thunkApi) => {
        try {
            const timeout = await client.post(new GetBannerOptions());
            return timeout;

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const slice = createSlice({
    name: 'bannerOptions',
    initialState: initialBannerOptions,
    reducers: {
        resetState: () => initialBannerOptions
    },
    extraReducers: builder => {
        builder
            .addCase(fetchBannerOptions.pending, (state: IBannerOptionsState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchBannerOptions.fulfilled, (state: IBannerOptionsState, { payload }) => {
                state.loadState = DataLoadState.Loaded
                state.showCompassBanner = payload.showCompassBanner;
            })
            .addCase(fetchBannerOptions.rejected, (state: IBannerOptionsState) => {
                state.loadState = DataLoadState.Error;
            })
            .addCase(getResetAction(), (_state, _action) => initialBannerOptions)
    }
})

const { resetState } = slice.actions;

export const bannerOptionsActionCreators = {
    fetchBannerOptions,
    resetState
};

export const bannerOptionsReducer = slice.reducer;
