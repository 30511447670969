import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetMonthlyBenefitResponse, GetMonthlyBenefit } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IMonthlyBenefitState extends ILoadableState {
    monthlyBenefit: GetMonthlyBenefitResponse
}

export const initialMonthlyBenefitState: IMonthlyBenefitState = {
    monthlyBenefit: {} as GetMonthlyBenefitResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'monthlyBenefit',
    initialState: {} as IMonthlyBenefitState,
    reducers: {
        resetState: () => {
            return {
                ...initialMonthlyBenefitState
            }
        },
        requestMonthlyBenefit: (state: IMonthlyBenefitState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveMonthlyBenefit: (state: IMonthlyBenefitState, action: PayloadAction<{ monthlyBenefit: GetMonthlyBenefitResponse }>) => {
            const { monthlyBenefit } = action.payload;
            return {
                ...state,
                monthlyBenefit,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveMonthlyBenefitError: (state: IMonthlyBenefitState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialMonthlyBenefitState)
    }
})

const { resetState, requestMonthlyBenefit, receiveMonthlyBenefit, receiveMonthlyBenefitError } = slice.actions;

function fetchMonthlyBenefit() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().monthlyBenefit;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestMonthlyBenefit());

        try {

            const monthlyBenefit = await client.post(new GetMonthlyBenefit());

            dispatch(receiveMonthlyBenefit({ monthlyBenefit }));

            if (monthlyBenefit.monthlyBenefitAmt > 0)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receiveMonthlyBenefitError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }

    }
}

export const monthlyBenefitActionCreators = {
    fetchMonthlyBenefit,
    resetState
};

export const monthlyBenefitReducer = slice.reducer;
