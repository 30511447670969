import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { loginHelpCreators } from '../../../store/LoginHelpStore';
import { Card, Button, Row, Col, Alert, Form, Spinner } from 'react-bootstrap';
import { GetThreatMetrixParameters, RequestType } from '../../../Gateway.dtos';
import parse from 'date-fns/parse/index';
import { getUnformattedString } from '../../../functions/getUnformattedString';
import NumberFormat from 'react-number-format';
import { accountActionCreators } from '../../../store/AccountStore';
import { Link } from 'react-router-dom';
import { activityActionCreators } from '../../../store/ActivityStore';
import { AppDispatch } from '../../..';
import { formatSsn } from '../../../functions/formatSsn';
import { client } from '../../../App';
import { threatMetrixReprofile } from '../../../functions/threatMetrixReprofile';
import { resetApplication } from '../../../functions/resetApplication';
import { push } from 'connected-react-router';
import { GatewayPath } from '@wespath/gateway-navigation';
import Skeleton from 'react-loading-skeleton';

type TPersonalInformationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const PersonalInformation = (props: TPersonalInformationProps) => {

    const isDevelopment = process.env.NODE_ENV === 'development';
    const [lastName, setLastName] = useState(isDevelopment ? 'Barkley' : '');
    const [suffix, setSuffix] = useState(props.suffix);
    const [ssn, setSsn] = useState(isDevelopment ? '322809142' : '');
    const [formattedSsn, setFormattedSsn] = useState(isDevelopment ? formatSsn(ssn).replace(/[0-9]/g, '•') : '');
    const [birthday, setBirthday] = useState(isDevelopment ? '07/21/1979' : ''); 
    const [threatMetrixSessionId, setThreatMetrixSessionId] = useState("");

    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        document.title = "Benefits Access - Personal Information"
    }, []);

    //Use this to hide server errors when user changes data until next time the submit
    const [hideServerErrMsg, setHideServerErrMsg] = useState(false);
    const serverErrMsg = () => {
        const msg = props.personalInformationResponse?.responseStatus?.message;

        if (showLastNameErr || showSsnErr || showBirthdayErr)
            return;
        else if (msg === "registered")
            return (<>You have already registered for Benefits Access. Do you need to<Link to="#" onClick={() => props.goToNeedHelp()}> reset your access</Link >?</>);
        else if (msg)
            return (<>Some of the information you have entered does not match our records. Please review your information and try again.</>);

    }

    const [showLastNameErr, setShowLastNameErr] = useState(false);
    function checkLastName(val: string | undefined) {
        const isValid = (val !== undefined && val.length > 0);
        setShowLastNameErr(!isValid);
        return isValid;
    }

    function lastNameChange(val: string) {
        setHideServerErrMsg(true);
        setLastName(val);
        if (showLastNameErr) { checkLastName(val); }
    }

    const [showSsnErr, setShowSsnErr] = useState(false);
    function checkSsn(val: string | undefined) {
        const isValid = (val !== undefined && getUnformattedString(val).length === 9);
        setShowSsnErr(!isValid);
        return isValid;
    }

    function ssnChange(val: string) {
        setHideServerErrMsg(true);

        let newSsn = '';
        const newValue = val.replace(/-/g, "");
        const oldValue = ssn;

        if (val.length === val.replace(/•/g, '').length)     //Value was pasted
            newSsn = val;
        else if (newValue.length < 1)
            newSsn = '';
        else if (newValue.length > oldValue.length)
            newSsn = oldValue + newValue.replace(/\D/g, '');
        else if (newValue.length < ssn.length && oldValue.length > 0)   
            newSsn = oldValue.slice(0, -1);
        else
            newSsn = oldValue;  

        //Remove all non-numeric chars
        newSsn = newSsn.replace(/\D/g, '')

        setSsn(newSsn);
        setFormattedSsn(formatSsn(newSsn).replace(/[0-9]/g, '•'));
        if (showSsnErr) { checkSsn(newSsn); }
    }

    const [showBirthdayErr, setShowBirthdayErr] = useState(false);
    function checkBirthday(val: string | undefined) {
        const isValid = (val !== undefined && val.length === 10 && birthdateIsOk(val));
        setShowBirthdayErr(!isValid);
        return isValid;
    }

    function birthdayChange(val: string) {
        setHideServerErrMsg(true);
        setBirthday(val);
        if (showBirthdayErr) { checkBirthday(val); }
    }       

    const submit = () => {
        setHideServerErrMsg(false);

        //Check all fields to show inline validations
        checkSsn(ssn);
        checkBirthday(birthday);
        checkLastName(lastName);

        //Check again for errors to disable the update
        if (checkLastName(lastName) && checkSsn(ssn) && checkBirthday(birthday)) {
            props.validatePersonalInformation(
                lastName,
                ssn,
                birthday,
                suffix,
                threatMetrixSessionId
            );
        }
    }  

    const threatMetrixProfileCompleteHandler = (event: MessageEvent<string>) => {
        if (typeof event.data === "string") {
            const parts = event.data.split(":")

            if (parts.length === 2 && parts[0] === "tmx_profiling_complete") {
                setThreatMetrixSessionId(parts[1])
            }
        }
    }

    useEffect(() => {
        if (props.personalInformationResponse?.responseStatus?.message === "deny")
            props.accessDenied();
    })

    useEffect(() => {
        window.addEventListener("message", threatMetrixProfileCompleteHandler, false);


        client.get(new GetThreatMetrixParameters())
            .then((parameters) => {
                threatMetrixReprofile(parameters)
                setTimeout(() => window.postMessage(`tmx_profiling_complete:${parameters.sessionId}`, "*"), 2000)
            })
            .catch(() => {
                dispatch(resetApplication());
                dispatch(push(GatewayPath.Maintenance));
            });

        return () => window.removeEventListener("message", threatMetrixProfileCompleteHandler);
    }, [dispatch])

    const { logActivity } = props;
    useEffect(() => {
        logActivity("PersonalInformation");
    }, [logActivity])

    const colClassName = "col-12 col-lg-10 col-xl-9";

    if (!threatMetrixSessionId) {
        return <LoadingSkeleton />;
    }


    return (
        <Row className="justify-content-center">
            <Col className={colClassName}>
                <Card className="fullPageCard">
                    <Card.Title>
                        Personal Information
                    </Card.Title>

                    <Card.Body>

                        <p>
                            {props.requestType === RequestType.NEEDHELP && "Having trouble logging in? Don’t worry, we can help you get access to your account in just a few easy steps. First, we need to verify your personal information."}
                            {props.requestType === RequestType.REGISTRATION && "You can setup your user account in a few easy steps. First, we need to verify your personal information so we can establish your eligibility.  International participants cannot register for Benefits Access online and should call 1-800-851-2201, Wespath representatives are available business days from 8:00 a.m. to 6:00 p.m., Central Time."}
                        </p>

                        {!hideServerErrMsg && serverErrMsg() && <Alert variant="warning">{serverErrMsg()}</Alert>}

                        {birthday && birthday.charAt(9) !== "_" && !birthdateIsOk(birthday) &&
                            <Alert variant="danger">
                                Birth date must be a valid date between 1/1/1900 and the current date.
                            </Alert>
                        }

                        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            submit();
                        }}>

                            <Row>
                                <Col className="col-12 col-sm-8 col-md-6 ">
                                    <Form.Group controlId="lastName" className={showLastNameErr ? "error" : ""}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={lastName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { lastNameChange(e.target.value) }}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { checkLastName(e.target.value) }}
                                            maxLength={30}
                                            tabIndex={1}
                                            disabled={props.isLoading}
                                            autoFocus
                                        />
                                        {showLastNameErr && <div className="errMsg">Invalid Last Name</div>} 
                                    </Form.Group> 
                                </Col>
                                <Col className="col-sm-4 col-md-3">
                                    <Form.Group controlId="suffix" className="optional">
                                        <Form.Label>Suffix</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={suffix}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSuffix(e.target.value) }}
                                            maxLength={8}
                                            tabIndex={2}
                                            disabled={props.isLoading}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group controlId="ssn" className={showSsnErr ? "error" : ""}>
                                        <Form.Label>Social Security Number (SSN)</Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                className="col-sm-4 col-md-3"
                                                type="text"
                                                value={formattedSsn}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ssnChange(e.target.value); }}
                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { checkSsn(e.target.value) }}
                                                maxLength={11}
                                                tabIndex={3}
                                                disabled={props.isLoading}
                                            />
                                        </div>
                                        {showSsnErr && <div className="errMsg">Invalid Social Security Number</div>}                                    
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-12 col-sm-6 col-md-4">
                                    <Form.Group controlId="dob" className={showBirthdayErr ? "error" : ""}>
                                        <Form.Label>Date of Birth</Form.Label>
                                        <NumberFormat
                                            customInput={Form.Control}
                                            format="##/##/####"
                                            mask="_"
                                            value={birthday}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { birthdayChange(e.target.value) }}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { checkBirthday(e.target.value) }}
                                            tabIndex={4}
                                            disabled={props.isLoading}
                                        />
                                        {!showBirthdayErr && <div className="infoMsg">MM/DD/YYYY</div>} 
                                        {showBirthdayErr && <div className="errMsg">Invalid Date of Birth. MM/DD/YYYY</div>} 
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button className="mt-4" variant="primary" type="submit" tabIndex={5} disabled={props.isLoading}>
                                {props.isLoading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : "Next"}
                            </Button>

                        </Form>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function mapStateToProps(state: AppState) {
    const requestType = state.loginHelp.requestType;
    const isLoading = state.loginHelp.isLoading;

    return {
        ...state.loginHelp,
        isLoading,
        requestType
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { accessDenied } = accountActionCreators;
    return bindActionCreators({ ...loginHelpCreators, accessDenied, ...activityActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalInformation);


function birthdateIsOk(birthdate: string) {

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() - 1);
    const minDate = new Date("01/01/1900");
    const checkDate = parse(birthdate, "MM/dd/yyyy", new Date())

    return maxDate > checkDate && checkDate >= minDate;
}

const LoadingSkeleton = () => (
    <Row className="justify-content-center">
        <Col className="col-12 col-lg-10 col-xl-9">
            <Card className="fullPageCard">
                <Card.Title>
                    Personal Information
                 </Card.Title>
                <Card.Body>
                    <Form>

                        <Form.Group>
                            <Skeleton />
                            <Skeleton height={25} />
                        </Form.Group>

                        <Form.Group>
                            <Skeleton />
                            <Skeleton height={25} />
                        </Form.Group>


                        <div className="my-3">
                            <Skeleton height={40} />
                        </div>
                    </Form>

                    <div className="my-3">
                        <Skeleton height={25} />
                    </div>

                    <div className="mt-3 mb-2">
                        <Skeleton count={2} />
                    </div>

                </Card.Body>
            </Card>
        </Col>
    </Row>
);