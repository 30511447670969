import React, { useEffect } from 'react';
import { faFileMedicalAlt } from '@fortawesome/pro-solid-svg-icons';
import { PanelWithIcon } from '../../PanelWithIcon';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { cmsActionCreators } from '../../../store/CmsStore';
import { connect } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';

type TBlueprintProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Blueprint = (props: TBlueprintProps) => {

    const hasMedical = props.hasMedical;

    const cmsBlueprintIncentive = props.cmsData.fragments?.find(f => f.name === "BlueprintIncentive");
    const cmsBlueprintForWellness = props.cmsData.fragments?.find(f => f.name === "BlueprintForWellness");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (
        <PanelWithIcon icon={faFileMedicalAlt} iconColor="wellbeing-color" title="Blueprint For Wellness">
            {hasMedical && cmsBlueprintIncentive && <><span dangerouslySetInnerHTML={{ __html: cmsBlueprintIncentive.html }} />&nbsp;</>}
            {cmsBlueprintForWellness ? <span dangerouslySetInnerHTML={{ __html: cmsBlueprintForWellness.html }} /> : <LoadingSkeleton className="mb-0" />}
        </PanelWithIcon>
    );

};
function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.wellbeing,
        hasMedical: state.healthPlans?.healthPlans?.hasMedical
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchWellBeingCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Blueprint);