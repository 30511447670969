import * as React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { NetRateofReturnDetail } from "../../../Gateway.dtos";
import { MobileLabelAndContentThreeColumn } from "../../MobileLabelAndContent";
import { NetRateofReturnGridRow } from "./NetRateofReturnGridRow";

export interface NetRateofReturnGridProps { netRateofReturn: NetRateofReturnDetail[] }
function NetRateofReturnGrid(props: NetRateofReturnGridProps): React.ReactElement {
    const netRateofReturn = props.netRateofReturn;

    const netRateGridTableMinWidths = {
        netRateCol: "80px",
    }

    return (<>
        <div>
            <Table size="sm" className="table-striped-ba">
                <thead>
                    <tr className="pl-2">
                        <th className="border-left border-right border-bottom-none text-right" style={{ minWidth: netRateGridTableMinWidths.netRateCol }} title="Previous full calendar month.">1 Mo</th>
                        <th className="border-right border-bottom-none text-right" style={{ minWidth: netRateGridTableMinWidths.netRateCol }} title="Previous three months.">3 Mos</th>
                        <th className="border-right border-bottom-none text-right" style={{ minWidth: netRateGridTableMinWidths.netRateCol }} title="Year to date.">YTD</th>
                        <th className="border-right border-bottom-none text-right" style={{ minWidth: netRateGridTableMinWidths.netRateCol }} title="Previous 12 months.">1 Yr</th>
                        <th className="border-right border-bottom-none text-right" style={{ minWidth: netRateGridTableMinWidths.netRateCol }} title="Previous 3 years.">3 Yr</th>
                        <th className="border-right border-bottom-none text-right" style={{ minWidth: netRateGridTableMinWidths.netRateCol }} title="Previous 5 years.">5 Yr</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="border-bottom">
                        {
                            netRateofReturn.map((lstNetRateofReturnDetail, i) => {
                                return (
                                    <NetRateofReturnGridRow netRateofReturnDetail={lstNetRateofReturnDetail} i={i} key={i} />
                                )
                            })
                        }
                    </tr>
                </tbody>
            </Table>
        </div>
    </>)
}

function MobileNetRateofReturnGrid(props: NetRateofReturnGridProps): React.ReactElement {
    const netRateofReturn = props.netRateofReturn;

    return (<>
        <div className="summary-table-mobile">
            {
                <React.Fragment>
                    <Row className="summary-table-mobile-type">
                        <Col className="col-12 px-0 mx-0">
                            Net Rate of Return*
                        </Col>
                    </Row>

                    <Card className="summary-table-mobile-card">
                        <Card.Body>
                            <MobileLabelAndContentThreeColumn
                                label="1 Mo" value={netRateofReturn[0]?.amount.concat(netRateofReturn[0]?.amount !== "N/A" ? "%" : "")}
                                label2="3 Mo" value2={netRateofReturn[1]?.amount.concat(netRateofReturn[1]?.amount !== "N/A" ? "%" : "")}
                                label3="YTD" value3={netRateofReturn[2]?.amount.concat(netRateofReturn[2]?.amount !== "N/A" ? "%" : "")}                            />
                            <MobileLabelAndContentThreeColumn
                                label="1 Yr" value={netRateofReturn[3]?.amount.concat(netRateofReturn[3]?.amount !== "N/A" ? "%" : "")}
                                label2="3 Yr" value2={netRateofReturn[4]?.amount.concat(netRateofReturn[4]?.amount !== "N/A" ? "%" : "")}
                                label3="5 Yr" value3={netRateofReturn[5]?.amount.concat(netRateofReturn[5]?.amount !== "N/A" ? "%" : "")}
                            />
                        </Card.Body>
                    </Card>
                </React.Fragment>
            }

        </div>

    </>)
}

export { NetRateofReturnGrid, MobileNetRateofReturnGrid };