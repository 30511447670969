import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

const WizardStep = (props: { to: string; text: string; isActive?: boolean; }): ReactElement => {
    const isActive = props.isActive === undefined || props.isActive;

    return (
        <NavLink to={isActive ? props.to : "#"} className="nav-link wizard-nav-link" activeClassName={isActive ? "active" : "inactive"}>
            {props.text}
        </NavLink>
    );
}

export const WizardHeader = (props: { stepNames: string[], stepNum: number }): ReactElement => {

    return (<>
        <div className="sticky-top wizard-second-nav">
            <Navbar expand="md" className="py-0 px-0 flex-column">
            <Nav className="justify-content-center w-100">
                    {props.stepNames.map((step, i) => (
                        <div key={i}>
                            <div className={`${props.stepNum >= i + 1 ? "wizard-underline" : "wizard-no-underline"} ${props.stepNum === (i + 1) ? "active" : ""}`} />
                            <WizardStep to="#" text={(i + 1) + ". " + step} isActive={props.stepNum === (i + 1)} />
                        </div>
                    ))}
                </Nav>
            </Navbar>
        </div>
    </>)
}