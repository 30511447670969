import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { cppActionCreators } from '../../../store/CppStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { AppDispatch } from '../../..';
import { PanelWithIcon } from '../../PanelWithIcon';
import { NavLink } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation';
import { cmsActionCreators } from '../../../store/CmsStore';
import { activityActionCreators } from '../../../store/ActivityStore';

export interface OwnProps { isSummary?: boolean }
type TCppProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Cpp = (props: TCppProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { cppEligibility } = props;

    const cmsCppEligibleMessage = props.cmsData.fragments?.find(f => f.name === "CppEligibleMessage");

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchCppEligibility();
        }
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });


    if (props.isLoading || !cppEligibility) {
        return <></>;
    }
    else if (props.isSummary) {

        return (<>

            {cppEligibility.cppEligible === "Y" && <>
                <PanelWithIcon icon={faUserShield} iconColor="lifedis-color">
                    <div className="panelHeader">
                        You Are Protected <FontAwesomeIcon icon={faCheck} className="dtlCheckMark" />
                    </div>
                    <div>
                        <strong>You are currently {cppEligibility.clergyStatus === "active" ? "enrolled in" : "eligible for"} the Comprehensive Protection Plan (CPP).</strong>
                    </div>
                    <div>
                        The Comprehensive Protection Plan (CPP) provides peace of mind for you and financial security
                        for your family. The plan, which is self-insured and funded by plan sponsor contributions,
                        includes death benefits, long-term disability income replacement and other survivor benefits.
                    </div>

                </PanelWithIcon>
            </>}

            {(cppEligibility.cppEligible === "I" || cppEligibility.cppEligible === "R") &&
                <PanelWithIcon icon={faUserShield} iconColor="lifedis-color">
                    <div className="panelHeader">
                        Not Available
                    </div>
                    <div>
                        You are currently not eligible for the Comprehensive Protection Plan (CPP).
                    </div>
                    <div>
                        There are a number of reasons you may not be eligible for this <NavLink to={GatewayPath.LifeAndDisability}>learn more</NavLink>.
                    </div>
                </PanelWithIcon>
            }
        </>);

    }
    else {

        return (
            <Card className="pageCard">

                <CardTitle cppEligible={cppEligibility.cppEligible} clergyStatus={cppEligibility.clergyStatus} />

                <Card.Body>

                    {cppEligibility.cppEligible === "Y" ?

                        cmsCppEligibleMessage ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsCppEligibleMessage.html }} /> : null
                        : <>

                            <p className="thin">There are a number of reasons you may not be eligible for the Comprehensive Protection Plan (CPP).</p>

                            {cppEligibility.cppEligible === "R" && <NeedsResearch />}

                            {cppEligibility.cppEligible === "I" && <IneligibleText msg={cppEligibility.cppMsg} />}

                            <p className="thin">Please contact Wespath business days from 8:00 a.m. to 6:00 p.m., Central time, by calling 1-800-851-2201.</p>
                        </>
                    }

                    <div className="mt-5">
                        <a href="https://www.wespath.org/health-well-being/benefit-programs/comprehensive-protection-plan" target="_blank" rel="noopener noreferrer" onClick={() => dispatch(activityActionCreators.logActivity("button_cppPlanDetails"))}>
                            <Button variant="secondary">
                                Plan Details
                            </Button>
                        </a>
                    </div>

                </Card.Body>
            </Card>
        );

    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...state.cpp,
        cmsData: state.cms.lifeanddisability,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...cppActionCreators,
        fetchCmsData: cmsActionCreators.fetchLifeAndDisabilityCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cpp);


const CardTitle = (props: { cppEligible: string, clergyStatus: string }) => {
    const { cppEligible, clergyStatus } = props;

    return (
        <>
            <Card.Title>
                <FontAwesomeIcon icon={faUserShield} className="lifedis-color smaller"/>
                {cppEligible === "Y" ? <>
                    You Are <span className="text-nowrap">Protected<FontAwesomeIcon icon={faCheck} className="dtlCheckMark" /></span>
                </> :
                    "Not Available"
                }
            </Card.Title>

            <Card.Subtitle>
                {cppEligible === "Y" ? <>
                    You are currently {clergyStatus === "active" ? "enrolled in" : "eligible for"} the Comprehensive Protection Plan (CPP).
                </> :
                    "You are currently not eligible for the Comprehensive Protection Plan (CPP)."
                }
            </Card.Subtitle>
        </>
    );
}


const NeedsResearch = () => (
    <p>
        You may be eligible for the retiree death benefit through CPP. Further research is needed.
    </p>
);

const IneligibleText = (props: {msg: string}) => (
    <p>
        Our records indicate that you are not eligible for the following reason: {sentenceCase(props.msg)}.
    </p>
);

function sentenceCase(str: string) {
    return str.toLowerCase()
        .replace(/[a-z]/i, function (letter: string) { return letter.toUpperCase(); })
        .replace(/: [a-z]/g, function (text: string) { return text.toUpperCase(); })
        .replace(/; [a-z]/g, function (text: string) { return text.toUpperCase(); })
        .replace(/cpp/g, "CPP")
        .replace(/ mths /g, " MTHS ")
        .replace(/ immed /g, " IMMED ")
        .trim();
}