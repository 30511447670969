import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { AppState } from '../../../../store/configureStore';
import { AppDispatch } from '../../../..';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

type TUnlockProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps { evaluateInitElection?: boolean, isDismissable?: boolean }
const LsriMinPaymentMsg = (props: TUnlockProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { lsri } = props;
    const minPaymentAmt = lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.minPaymentAmt ?? 100
    const paymentAmt = props.evaluateInitElection ? lsri.systemElection?.lsriLifestage : lsri.userElection?.lsriLifestage
    const sysElectionIsEmpty = Object.keys(lsri.systemElection).length === 0

    const handleCloseBtn = () => (
        dispatch(LsriActionCreators.setShowMinPaymentMsg())
    )

    if (sysElectionIsEmpty || paymentAmt >= minPaymentAmt || (props.isDismissable && lsri.minPaymentMsgClosed))
        return <></>

    return (
        <Alert variant="info" className="my-4">
            <FontAwesomeIcon icon={faInfoCircle} />
            The amount currently allocated to LifeStage Retirement Income does not support the program&apos;s minimum 
            monthly payment of $100. To enroll in the program, you must allocate more money to
            it. {props.isDismissable && <span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => handleCloseBtn()}>Close message</span>}
        </Alert>
    );
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriMinPaymentMsg);
