import React, { ReactElement } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeDollar } from '@fortawesome/pro-solid-svg-icons';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import MonthlyBenefit, { ShowAnnuityPaymentLink } from './MonthlyBenefit';
import Balance from './Balance';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { balanceActionCreators } from '../../../store/BalanceStore';
import { connect } from 'react-redux';
import Payments from './Payments';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../..';
import { Link } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation';
import { monthlyBenefitActionCreators } from '../../../store/MonthlyBenefitStore';

type TRetirementPlansProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const RetirementPlans = (props: TRetirementPlansProps): ReactElement => {
    const isLoading = (props.isLoading && !props.isLoaded);

    const DirectDepositLink = () => {
        return (

                <p className="pt-4 pb-0 retirement-text-dd">
                    You can <Link to={GatewayPath.ManageDirectDeposit}>change direct deposit</Link> bank information at any time.
                </p>

        )
    }

    const ShowDirectDepositlink = () => {
        return (props.balance?.installments?.length > 0 || (props.monthlyBenefit?.monthlyBenefitAmt > 0 && (props.monthlyBenefit?.label === "Annuity Payment")))
    }

    return (<>

        {isLoading && <LoadingSkeleton />}

        {!isLoading &&
            <Card className="pageCard">
                <Card.Title><FontAwesomeIcon icon={faBadgeDollar} className="ret-color" />Retirement Plans</Card.Title>
                {props.balance?.plans && <Card.Subtitle>Your plans include: {props.balance.plans}.</Card.Subtitle>}
                <Card.Body>

                    <Balance />

                    <MonthlyBenefit />

                    <Payments />

                    {ShowDirectDepositlink() && <DirectDepositLink />}

                    <BenefitsAccessSso to={`/mybenefits_summary.aspx`}>
                        <Button className={ShowDirectDepositlink() ? "mt-2" : "mt-4"} variant="secondary">View Account & Plan Details</Button>
                    </BenefitsAccessSso>
                    <BenefitsAccessSso to={`/mybenefits_statements.aspx`}>
                        <Button className="mt-md-4 mt-2 ml-md-4" variant="link">View Statements</Button>
                    </BenefitsAccessSso>



                </Card.Body>
            </Card>
        }
    </>);
}
export { RetirementPlans };

function mapStateToProps(state: AppState) {
    return {
        ...state.balance,
        ...state.monthlyBenefit
    };
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...monthlyBenefitActionCreators, ...balanceActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RetirementPlans);


const LoadingSkeleton = () => (
    <Card className="pageCard pb-3">
        <div><Skeleton width={'50%'} height={'2em'} /></div>
        <div><Skeleton width={'50%'} /></div>

        <div className="mt-3"><Skeleton height={'2.5em'} /></div>

        <div className="mt-3"><Skeleton height={'1.5em'} /></div>
    </Card>
);