import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { GatewayPath } from '@wespath/gateway-navigation'
import { NavLink } from 'react-router-dom';
import { AppState } from '../../../store/configureStore';
import { connect } from 'react-redux';
import Blueprint from '../wellbeing/Blueprint';
import HealthQuotient from '../wellbeing/HealthQuotient';
import VirginPulse from '../wellbeing/VirginPulse';
import { CollapsibleCard } from '../../CollapsibleCard';

type TWellBeingSummaryProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps { expandCard?: boolean }
const WellBeingSummary = (props: TWellBeingSummaryProps) => {
    const hasMedical = props.healthPlans?.hasMedical || false;
    const hasVp = props.wellBeing?.vpIsQualified || false;
    const hasBfw = props.wellBeing?.bfwIsQualified || false;

    return (
        <CollapsibleCard headerClassName="wellbeing-color-bg" headerContent="Well-Being" expandCard={props.expandCard}>
            <Card.Title className="sumAsOfDate">
                Data as of {props.healthPlans?.dateAsOf}
            </Card.Title>
            <Card.Body className="pt-0">

                {hasVp && <VirginPulse isSummary/>}

                {hasBfw && <Blueprint />}                   

                {hasMedical && <HealthQuotient isSummary/>}

                <hr />

                <p className="footnote amountPanelSmall">
                    You have additional benefits that can help you live a healthier life and improve your well-being.
                </p>

                <span className="text-center">
                    <NavLink to={GatewayPath.WellBeing} className="nav-link px-0" activeClassName="active">
                        <Button variant="primary" className="summaryBtn">Well-Being Details</Button>
                    </NavLink>
                </span>

            </Card.Body>
        </CollapsibleCard>

    );
}
function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        ...state.healthPlans,
        ...state.wellBeing
    }
}

export default connect(
    mapStateToProps
)(WellBeingSummary);
