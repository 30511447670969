import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Row, Col, Card } from 'react-bootstrap';
import { PageTitle } from '../PageTitle';
import PersonalInformation from '../cards/accountManagement/PersonalInformation';
import ContactInformation from '../cards/accountManagement/ContactInformation';
import PasswordCard from '../cards/accountManagement/Password';
import UsernameCard from '../cards/accountManagement/Username';
import DeliveryPreferences from '../cards/accountManagement/DeliveryPreferences';
import EyAuthorization from '../cards/accountManagement/EyAuthorization';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { personalInfoActionCreators } from '../../store/accountManagement/PersonalInfoStore';
import BeneficiaryReviewModal from '../BeneficiaryReviewModal';
import { AppDispatch } from '../..';
import UnlockBanner from '../UnlockBanner';
import MultiFactorModal from '../cards/multiFactor/MultiFactorModal';


type TAccountManagementProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const AccountManagement = (props: TAccountManagementProps) => {
    const { showRetirement, fetchPersonalInfo } = props;

    useEffect(() => {
        document.title = "Benefits Access - Account Management"
    }, []);

    useEffect(() => {
        if (stateNeedsLoading(props)) fetchPersonalInfo();
    });


    const colClass = "col-12 col-lg-6";
    const DesktopView = () => (
        <Card className="detailCard desktop" style={{marginTop: '2em'}}>
            <PageTitle title="Account Management" hidePrintButton={true} color="acctmgmt-color-bg" />
            <Card.Title id="cardAsOfDate"></Card.Title>
            <Card.Body className="px-4 pt-1">
                <Row>
                    <Col className={colClass}>
                        <PersonalInformation />
                        <ContactInformation />
                    </Col>
                    <Col className={colClass}>
                        <PasswordCard />
                        <UsernameCard />
                        <DeliveryPreferences />
                        {showRetirement && <EyAuthorization />}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );

    const MobileView = () => (
        <div className="detailCard mobile">
            <Card>
                <PageTitle title="Account Management" hidePrintButton={true} color="acctmgmt-color-bg" />
                <Card.Title id="cardAsOfDate"></Card.Title>
                <Card.Body>
                    <PersonalInformation />
                </Card.Body>
            </Card>

            <ContactInformation />
            <PasswordCard />
            <UsernameCard />
            <DeliveryPreferences />
            {showRetirement && <EyAuthorization />}
        </div>
    );

    return (<>
        <BeneficiaryReviewModal />

        <UnlockBanner />

        <DesktopView />

        <MobileView />

        <MultiFactorModal />

    </>);
}

function mapStateToProps(state: AppState) {
    const { showRetirement } = state.displayPage;

    return {
        hasCompletedMultiFactor: state.authMultiFactor.hasCompletedMultiFactor,
        isChallenging: state.authMultiFactor.isChallenging,
        ...state.personalInfo,
        showRetirement
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...personalInfoActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountManagement);