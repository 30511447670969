import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamcorder } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';

type TLearnMoreProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const LearnMore = (props: TLearnMoreProps) => {
    const cmsData = props.cmsData.fragments?.find(f => f.name === "LearnMore");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (

        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faCamcorder} className="health-color smaller" />
                Learn More About HealthFlex
            </Card.Title>
            <Card.Body>

                {!cmsData ? <LoadingSkeleton /> : <>

                    {cmsData ? <div dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                </>}

            </Card.Body>
        </Card>

    );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LearnMore);
