import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { cmsActionCreators } from '../../../store/CmsStore';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriTermsAndConditions = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const caDesignated = lsri.lsriInfo?.caDesignated
    //const mppDoNotInclude = !lsri.mppIneligible && !lsri.userElection?.includeMpp
    const mppInclude = !lsri.mppIneligible && lsri.userElection?.includeMpp
    const isBridge = lsri.userElection?.scenario.includes("B");
    const isQlac = lsri.userElection?.scenario.includes("Q");
    const hasRoth = lsri.lsriInfo.rothMsg;
    const hasMpp35 = lsri.lsriInfo?.balances?.find(x => x.planName?.toUpperCase() === 'MPP35')?.amount ? true : false;

    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "TermsAndConditions"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const topMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/TopMsg`)?.html ?? '';
    const bridgeMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/BridgeMsg`)?.html ?? '';
    const fundingUnqRothMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/FundingUnqRothMsg`)?.html ?? '';
    const fundingWithMppMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/FundingWithMppMsg`)?.html ?? '';
    const fundingWithoutMppMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/FundingWithoutMppMsg`)?.html ?? '';
    const mpp65Msg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/Mpp65Msg`)?.html ?? '';
    const mpp65MarriedMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/Mpp65MarriedMsg`)?.html.replace("&amp;spouseName", lsri.lsriInfo?.personalInfo?.spouseName) ?? '';
    const qlacMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/QlacMsg`)?.html ?? '';
    const qlacMarriedMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/QlacMarriedMsg`)?.html.replace("&amp;spouseName", lsri.lsriInfo?.personalInfo?.spouseName) ?? '';
    const qlacNotMarriedMsg = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/QlacNotMarriedMsg`)?.html ?? '';

    //End CMS Section


    return (<>

        {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

            <div className="mt-5 ul-noMargin" dangerouslySetInnerHTML={{ __html: topMsg }} />

            {mppInclude && <>

                <div className="ul-noMargin" dangerouslySetInnerHTML={{ __html: mpp65Msg }} />

                {caDesignated && <div className="ul-noMargin" dangerouslySetInnerHTML={{ __html: mpp65MarriedMsg }} />}

            </>}

            {hasMpp35 && <>

                <div className="ul-noMargin" dangerouslySetInnerHTML={{ __html: fundingWithMppMsg }} />

            </>}

            {(!lsri.userElection?.includeMpp && hasMpp35) && <div className="ul-noMargin" dangerouslySetInnerHTML={{ __html: fundingWithoutMppMsg }} />}

            {hasRoth && <div className="ul-noMargin" dangerouslySetInnerHTML={{ __html: fundingUnqRothMsg }} />}

            {isBridge && <div className="ul-noMargin" dangerouslySetInnerHTML={{ __html: bridgeMsg }} />}

            {isQlac && <>

                <div className="mt-4 ul-noMargin" dangerouslySetInnerHTML={{ __html: qlacMsg }} />

                <div className="ul-noMargin" dangerouslySetInnerHTML={{ __html: caDesignated ? qlacMarriedMsg : qlacNotMarriedMsg }} />

            </>}

        </>}

    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriTermsAndConditions);

