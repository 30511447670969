import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsSetup, wizardStepsManage } from './LsriWizard.steps';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { DropDown, DropDownOption } from '../../DropDown';
import { convertDollarPctToNumber } from '../../../functions/convertStringToNumber';
import { CurrencyInput } from '../../CurrencyInput';
import { LsriSocSecStatus, LsriStage, OracleSocSec } from '../../../Gateway.dtos';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { cmsActionCreators } from '../../../store/CmsStore';
import { FormatDollar } from '../../FormatDollar';
import LsriSelectionClearedModal from './Shared/LsriSelectionClearedModal';
import { SocialSecurity } from '../../../definitions/Claims';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriSocialSecurity = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const submitIsLoading = lsri.socSecLoadState === DataLoadState.Loading
    const submitIsLoaded = lsri.socSecLoadState === DataLoadState.Loaded
    const submitIsError = lsri.socSecLoadState === DataLoadState.Error
    const showSuccessMsg = lsri.socSecLoadState === DataLoadState.Loaded

    const hasClaim_Update = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === SocialSecurity.Update)
    const hasClaim_View = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === SocialSecurity.View)

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Provide your Social Security estimate'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Social Security Estimate`

        if (lsri.wizardType === LsriStage.Setup)
            dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriSocialSecurity }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    const ssElection = lsri?.lsriInfo?.oracleLsriElection?.socialSecurity ?? {} as OracleSocSec
    const minEstAmt = lsri?.ssMin
    const maxEstAmt = lsri?.ssMax
    //const fullRetAge = ssElection.fullRetAgeStr
    const lastUpdatedDate = !ssElection.last_Updated || ssElection?.ss_Status === LsriSocSecStatus.NoInfo
        ? "Never"
        : ssElection.last_Updated

    const [showSkipConfirmation, setShowSkipConfirmation] = useState(false);
    const [showEstAmount, setShowEstAmount] = useState(ssElection.ss_Status === LsriSocSecStatus.Planning)
    const [socSecElection, setSocSecElection] = useState(ssElection);
    const [isUpdate, setIsUpdate] = useState(ssElection?.ss_Status !== undefined && ssElection?.ss_Status !== LsriSocSecStatus.NoInfo)


    useEffect(() => {
        if (lsri.wizardType === LsriStage.Setup && !lsri.menuOptions?.enabledMenuOptions?.includes(GatewayPath.LsriReview))
            dispatch(LsriActionCreators.enableReviewCheck())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.lsriInfo.oracleLsriElection?.visitedSteps])

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "SocialSecurity"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    const whereToFindCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/WhereToFind`)?.html ?? '';
    const skipConfirmationMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/SkipConfirmationMsg`)?.html ?? '';
    //End CMS Section
  

    const getStatusDDLOptions = (): (DropDownOption)[] => {
        const ddlOptions: (DropDownOption)[] = [];

        ddlOptions.push({ value: "", display: "Select Status", hideIfNotSelected: true });
        ddlOptions.push({ value: LsriSocSecStatus.Planning, display: "Not receiving benefits yet" });
        ddlOptions.push({ value: LsriSocSecStatus.Receiving, display: "Already receiving benefits" });
        ddlOptions.push({ value: LsriSocSecStatus.OptOut, display: "Opted out of Social Security" });

        return ddlOptions;
    }

    //Error validation

    const [showStatusErr, setShowStatusErr] = useState(false);
    function isStatusValid(val: string | undefined) {
        const isValid = val !== undefined && val !== ''
        setShowStatusErr(!isValid);
        return isValid;
    }

    const [showEstAmtErr, setShowEstAmtErr] = useState(false);
    function isEstAmtValid(val: number | string | undefined) {
        const isValid = showEstAmount ? (val !== undefined && Number(val) <= maxEstAmt && Number(val) >= minEstAmt) : true
        setShowEstAmtErr(!isValid);
        return isValid;
    }

    const isInlineErrors = () => {
        let isErr = false

        //Check for errors so that they all display
        isErr = !isStatusValid(socSecElection?.ss_Status) || isErr;
        isErr = !isEstAmtValid(socSecElection?.ss_Amt) || isErr;

        return isErr;
    }


    //Action handling

    const handleClickSubmit = () => {
        if (isInlineErrors())
            return;

        const socSecChangedToNo = lsri?.lsriInfo?.oracleLsriElection?.socialSecurity?.ss_Status !== LsriSocSecStatus.OptOut && socSecElection.ss_Status === LsriSocSecStatus.OptOut

        dispatch(LsriActionCreators.setSocSecLoadStateLoading())
        dispatch(LsriActionCreators.fetchLsriInfo({ socSecEstAmt: socSecElection.ss_Amt, socSecStatus: socSecElection.ss_Status, socSecChangedToNo }))

        if (isUpdate)         
            setIsUpdate(true);
    }

    const handleClickSkip = () => {
        setShowSkipConfirmation(true);
    }

    const handleClickNoEstimate = () => {
        dispatch(LsriActionCreators.setSocSecLoadStateLoading());
        dispatch(LsriActionCreators.fetchLsriInfo({ socSecEstAmt: 0, socSecStatus: LsriSocSecStatus.NoInfo }))
    }

    const handleClickGoBack = () => {
        setShowSkipConfirmation(false);
    }

    const handleStatusChange = (val: string) => {
        let showEstAmt = showEstAmount

        if (isStatusValid(val)) {         
            showEstAmt = val === LsriSocSecStatus.Planning
            setShowEstAmount(showEstAmt)
        }

        setSocSecElection({ ss_Status: val, ss_Amt: showEstAmt ? socSecElection?.ss_Amt : undefined } as OracleSocSec)
    }

    const handleEstAmtChange = (val: string) => {
        //Re-evaluate error only if it is currently displayed
        if (showEstAmtErr) isEstAmtValid(val)

        setSocSecElection({ ss_Status: socSecElection?.ss_Status, ss_Amt: val === "" ? undefined : convertDollarPctToNumber(val) } as OracleSocSec)
    }

    const goToNextStep = () => {
        switch (lsri.wizardType) {
            case LsriStage.Setup:
                dispatch(push(!lsri.mppIneligible
                    ? GatewayPath.LsriMpp
                    : GatewayPath.LsriFunding
                ))
                break;
            case LsriStage.Manage:
                dispatch(push(GatewayPath.LsriFunding));
                break;
        }
    }

    const getDefaultStatus = () => {
        if (!socSecElection?.ss_Status || socSecElection?.ss_Status === LsriSocSecStatus.NoInfo)
            return ""
        else
            return socSecElection.ss_Status
    }

    useEffect(() => {
        if (showSuccessMsg) {
            //setTimeout(() => { dispatch(LsriActionCreators.resetSocSecLoadState()) }, 3000);
            dispatch(LsriActionCreators.resetSocSecLoadState())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSuccessMsg]);

    useEffect(() => {
        if (submitIsLoaded && lsri.lsriInfo.isEligible) {
            dispatch(LsriActionCreators.setPostSocSecElectionMenuOptions());
            dispatch(LsriActionCreators.enableReviewCheck())
            goToNextStep();
        }
        else if (submitIsLoaded) {
            setIsUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitIsLoaded])


    //Content

    //const SuccessMsg = () => (
    //    <Alert variant="success" className="mb-5 fade-out">
    //        <FontAwesomeIcon icon={faCheckCircle} /><strong>Success</strong><br />
    //        Your Social Security estimate has been updated.
    //    </Alert>
    //)

    const ErrorMsg = () => (
        <Alert variant="danger" className="mb-5">
            An error occurred when updating your Social Security estimate.
        </Alert>
    )

    const SkipConfirmation = () => (<>

        <div dangerouslySetInnerHTML={{__html: skipConfirmationMsgCms}} />

        <div className="mt-4">

            {hasClaim_Update && <>
                <Button variant="outline-primary" tabIndex={3} className={`${isSmallScreen ? 'w-100' : ''}`} style={{minWidth: '240px'}}
                    onClick={() => handleClickNoEstimate()} disabled={isLoading || submitIsLoading}>
                    {submitIsLoading ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : 'Continue Without Estimate'}
                </Button>
                {!isSmallScreen && !isLoading && !submitIsLoading && <span className="ml-4 mr-3">|</span>}
            </>}

            {!isLoading && !submitIsLoading && <Button variant="link px-0 mx-0" tabIndex={4} className={`${isSmallScreen ? 'w-100 mt-3' : ''}`} onClick={() => handleClickGoBack()}>
                Go back
            </Button>}
        </div>
        
    </>)

    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: lsri.wizardType === LsriStage.Manage ? 2 : 1,
                steps: lsri.wizardType === LsriStage.Manage ? wizardStepsManage : wizardStepsSetup,
                menuOptions: lsri.menuOptions
            }}
        >

            {(isLoading || cmsIsLoading) && <LoadingSkeleton />}

            {showSkipConfirmation && <SkipConfirmation />}

            {!isLoading && !cmsIsLoading && !showSkipConfirmation && <>

                <LsriSelectionClearedModal />

                <p className="mb-5" dangerouslySetInnerHTML={{ __html: headerMsgCms }} />

                {/*showSuccessMsg && <SuccessMsg />*/}

                {submitIsError && <ErrorMsg />}

                {hasClaim_View && <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    handleClickSubmit();
                }}>

                    {lastUpdatedDate && <div style={{fontSize: '18px', lineHeight: '22px'}}>
                        Last Updated<br />
                        <strong>{lastUpdatedDate}</strong>
                    </div>}

                    <Form.Group className={`mt-4 ${showStatusErr ? "error" : ""}`} style={{ maxWidth: `${isSmallScreen ? 'initial' : '20rem'}` }}>
                        <Form.Label><strong>
                            {isUpdate
                                ? <>Choose your current status</>
                                : <>Choose your current Social Security status</>
                            }
                        </strong></Form.Label>
                        <DropDown name="socSecStatus"
                            dropDownOptions={getStatusDDLOptions()}
                            defaultValue={getDefaultStatus()}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleStatusChange(e.target.value)}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleStatusChange(e.target.value)}
                            tabIndex={1}
                            disabled={submitIsLoading || lsri.lsriInfo.disableSocSecDropdown}
                        />
                        {showStatusErr && <div className="errMsg">Invalid Status</div>}
                    </Form.Group>


                    {showEstAmount && <>

                        <p className="mt-5 mb-1" style={{ fontSize: '14px' }}><strong>Enter Social Security Estimate At Full Retirement Age</strong></p>
                        <Form.Group className={showEstAmtErr ? "error" : ""} style={{ maxWidth: `${isSmallScreen ? 'initial' : '10rem'}` }}>
                            <CurrencyInput
                                id={`socSecEstAmt`}
                                name={`socSecEstAmt`}
                                showCurrencySymbol={true}
                                defaultValue={socSecElection?.ss_Amt?.toString()}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEstAmtChange(e.target.value)}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleEstAmtChange(e.target.value)}
                                decimalScale={0}
                                maxLength={9}
                                tabIndex={2}
                                disabled={submitIsLoading}
                                leftAlign
                            />
                            {showEstAmtErr && <div className="errMsg">Amount should be between <FormatDollar amount={minEstAmt} noCents /> and <FormatDollar amount={maxEstAmt} noCents /></div>}
                        </Form.Group>

                    </>}

                    {hasClaim_Update && !lsri.lsriInfo.disableSocSecDropdown && <div className="mt-4">
                        <Button variant="primary" tabIndex={3} className={`${isSmallScreen ? 'w-100' : ''}`}
                            onClick={() => handleClickSubmit()}
                            disabled={submitIsLoading || !socSecElection?.ss_Status}
                        >
                            {submitIsLoading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : 'Submit'}
                        </Button>

                        {!isUpdate && <>
                            {!isSmallScreen && <span className="ml-4 mr-3">|</span>}

                            <Button variant="link px-0 mx-0" tabIndex={4} className={`${isSmallScreen ? 'w-100 mt-3' : ''}`}
                                onClick={() => handleClickSkip()} disabled={submitIsLoading}
                            >
                                Skip for now
                            </Button>
                        </>}
                    </div>}

                </Form>}

                <p className="mt-5" dangerouslySetInnerHTML={{ __html: whereToFindCms }} />

            </>}            

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriSocialSecurity);