export enum CmsRoutes {
    FormsGeneralAllPlans = "/generalAllPlans",
    HelpWespath = "/wespathContacts",
    ResourcesCRSP = "/resourcesCRSP",
    ResourcesRPGA = "/resourcesRPGA",
    ResourcesUMPIP = "/resourcesUMPIP",
    ResourcesHorizon = "/resourcesHorizon",
    ResourcesCPP = "/resourcesCPP",
    ResourcesCollins = "/resourcesCollins",
    ResourcesUMPH = "/resourcesUMPH",
    ResourcesChaplains = "/resourcesChaplains",
    HelpGettingStarted = "/helpGettingStarted",
    HelpSaving = "/helpSaving",
    HelpInvesting = "/helpInvesting",
    HelpPlanning = "/helpPlanning",
    HelpRetiring = "/helpRetiring",
    LearnRetirementPlans = "/learnRetirementPlans",

    LearnHealthPlans = "/learnHealthPlans",
    LarnWelfarePlans = "/learnWelfarePlans",
    DistributionOptions = "/distributionOptions",
    KeepingYourMoney = "/keepingYourMoney",
    //LifestageRetirementIncomePayments = "/lifestageRetirementIncomePayments",
    LifestageExample1 = "/lifestageExample1",
    SelfManagedRetirementIncomePayments = "/selfManagedRetirementIncomePayments",
    PublicationGeneral = "/publicationGeneral",
    PublicationInvesting = "/publicationInvesting",
    PublicationPlanning = "/publicationPlanning",
    PublicationPlans = "/publicationPlans",
    PublicationHark = "/publicationHark",
    ExplorePersonalInformation = "/explorePersonalInformation",
    ExploreBeneficiaries = "/exploreBeneficiaries",
    ExploreElectronicDelivery = "/exploreElectronicDelivery",
    ExploreLifeStage = "/exploreLifeStage",
    ExploreContributions = "/exploreContributions",
    ExploreFinancialPlanning = "/exploreFinancialPlanning",
    ExploreDistributions = "/exploreDistributions"
}


export const CmsRoutePaths = [
    { route: CmsRoutes.HelpWespath, cmsPath: "/Help/Contacts/Wespath" },
    { route: CmsRoutes.FormsGeneralAllPlans, cmsPath: "/Help/Forms/GeneralAllPlans" },
    { route: CmsRoutes.ResourcesCRSP, cmsPath: "/Help/Forms/CRSP" },
    { route: CmsRoutes.ResourcesRPGA, cmsPath: "/Help/Forms/RPGA" },
    { route: CmsRoutes.ResourcesUMPIP, cmsPath: "/Help/Forms/UMPIP" },
    { route: CmsRoutes.ResourcesHorizon, cmsPath: "/Help/Forms/Horizon" },
    { route: CmsRoutes.ResourcesCPP, cmsPath: "/Help/Forms/CPP" },
    { route: CmsRoutes.ResourcesCollins, cmsPath: "/Help/Forms/Collins" },
    { route: CmsRoutes.ResourcesUMPH, cmsPath: "/Help/Forms/UMPH" },
    { route: CmsRoutes.ResourcesChaplains, cmsPath: "/Help/Forms/Chaplains" },
    { route: CmsRoutes.HelpGettingStarted, cmsPath: "/Help/HowToGuides/GettingStarted" },
    { route: CmsRoutes.HelpSaving, cmsPath: "/Help/HowToGuides/Saving" },
    { route: CmsRoutes.HelpInvesting, cmsPath: "/Help/HowToGuides/Investing" },
    { route: CmsRoutes.HelpPlanning, cmsPath: "/Help/HowToGuides/Planning" },
    { route: CmsRoutes.HelpRetiring, cmsPath: "/Help/HowToGuides/Retiring" },
    { route: CmsRoutes.LearnRetirementPlans, cmsPath: "/Learn/BenefitsPlanInformation/RetirementPlans" },

    { route: CmsRoutes.LearnHealthPlans, cmsPath: "/Learn/BenefitsPlanInformation/HealthPlans" },
    { route: CmsRoutes.LarnWelfarePlans, cmsPath: "/Learn/BenefitsPlanInformation/WelfarePlans" },

    { route: CmsRoutes.DistributionOptions, cmsPath: "/Learn/RetirementSavingsAccount/DistributionOptions" },
    { route: CmsRoutes.KeepingYourMoney, cmsPath: "/Learn/RetirementSavingsAccount/KeepingYourMoney" },
    //{ route: CmsRoutes.LifestageRetirementIncomePayments, cmsPath: "/Learn/RetirementSavingsAccount/LSRI" },
    { route: CmsRoutes.LifestageExample1, cmsPath: "/Learn/RetirementSavingsAccount/LifestageExample1" },
    { route: CmsRoutes.SelfManagedRetirementIncomePayments, cmsPath: "/Learn/RetirementSavingsAccount/SMRI" },


    { route: CmsRoutes.PublicationGeneral, cmsPath: "/Learn/Publications/General" },
    { route: CmsRoutes.PublicationInvesting, cmsPath: "/Learn/Publications/Investing" },
    { route: CmsRoutes.PublicationPlanning, cmsPath: "/Learn/Publications/Planning" },
    { route: CmsRoutes.PublicationPlans, cmsPath: "/Learn/Publications/Plans" },
    { route: CmsRoutes.PublicationHark, cmsPath: "/Learn/Publications/Hark" },
    
    { route: CmsRoutes.ExplorePersonalInformation, cmsPath: "/Learn/ExploreByTopic/PersonalInformation" },
    { route: CmsRoutes.ExploreBeneficiaries, cmsPath: "/Learn/ExploreByTopic/Beneficiaries" },
    { route: CmsRoutes.ExploreElectronicDelivery, cmsPath: "/Learn/ExploreByTopic/ElectronicDelivery" },
    { route: CmsRoutes.ExploreLifeStage, cmsPath: "/Learn/ExploreByTopic/LifeStage" },
    { route: CmsRoutes.ExploreContributions, cmsPath: "/Learn/ExploreByTopic/Contributions" },
    { route: CmsRoutes.ExploreFinancialPlanning, cmsPath: "/Learn/ExploreByTopic/FinancialPlanning" },
    { route: CmsRoutes.ExploreDistributions, cmsPath: "/Learn/ExploreByTopic/Distributions" }
]
