import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetUmLifeOptionsResponse, GetUmLifeOptions } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IUmLifeOptionsState extends ILoadableState {
    umLifeOptions: GetUmLifeOptionsResponse
}

export const initialUmLifeOptionsState: IUmLifeOptionsState = {
    umLifeOptions: {} as GetUmLifeOptionsResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'umLifeOptions',
    initialState: {} as IUmLifeOptionsState,
    reducers: {
        resetState: () => {
            return {
                ...initialUmLifeOptionsState
            }
        },
        requestUmLifeOptions: (state: IUmLifeOptionsState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveUmLifeOptions: (state: IUmLifeOptionsState, action: PayloadAction<{ umLifeOptions: GetUmLifeOptionsResponse }>) => {
            const { umLifeOptions } = action.payload;
            return {
                ...state,
                umLifeOptions,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveUmLifeOptionsError: (state: IUmLifeOptionsState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialUmLifeOptionsState)
    }
})

const { resetState, requestUmLifeOptions, receiveUmLifeOptions, receiveUmLifeOptionsError } = slice.actions;

function fetchUmLifeOptions() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().umLifeOptions;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestUmLifeOptions());

        try {

            const umLifeOptions = await client.post(new GetUmLifeOptions());

            dispatch(receiveUmLifeOptions({ umLifeOptions }));

            if (umLifeOptions.benefitClassCode !== undefined && umLifeOptions.benefitClassCode !== "C999")
                dispatch(displayPageActionCreators.showLifeAndDisability());

        } catch (e: unknown) {
            dispatch(receiveUmLifeOptionsError());
            console.log(e);
        }

    }
}

export const umLifeOptionsActionCreators = {
    fetchUmLifeOptions,
    resetState
};

export const umLifeOptionsReducer = slice.reducer;
