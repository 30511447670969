import { Dispatch } from "react";
import { AppState } from "../configureStore";
import { createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../../definitions/ILoadableState";
import { UpdateEy } from "../../Gateway.dtos";
import { client } from "../../App";
import { getResetAction } from "../../functions/getResetAction";

export interface IState extends ILoadableState {
    isAuthorized: string,
    showSuccessMsg: boolean
}

export const initialState: IState = {
    isAuthorized: "",
    showSuccessMsg: false,
    isLoading: false,
    isLoaded: false,
    error: false
};

const slice = createSlice({
    name: 'EyAuthorization',
    initialState: {} as IState,
    reducers: {
        resetState: () => {
            return {
                ...initialState
            }
        },
        setState: (state: IState, action: PayloadAction<{ isAuthorized: string }>) => {
            const isAuthorized = action.payload.isAuthorized;
            return {
                ...state,
                isAuthorized
            }
        },
        requestUpdate: (state: IState) => {
            return {
                ...state,
                isLoading: true,
                error: false,
                showSuccessMsg: false
            }
        },
        receiveUpdate: (state: IState, action: PayloadAction<{ isAuthorized: string }>) => {
            const isAuthorized = action.payload.isAuthorized;
            return {
                ...state,
                isAuthorized,
                isLoading: false,
                error: false,
                showSuccessMsg: true
            }
        },
        receiveUpdateError: (state: IState) => {
            return {
                ...state,
                isLoading: false,
                error: true
            }
        },
        hideSuccessMsg: (state: IState) => {
            return {
                ...state,
                showSuccessMsg: false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialState)
    }
})

const {
    resetState,
    setState,
    requestUpdate,
    receiveUpdate,
    receiveUpdateError,
    hideSuccessMsg
} = slice.actions;

function updateEy(isAuthorized: boolean) {
    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState): Promise<void> => {
        const isUpdating = getState().eyAuthorization.isLoading;
        const { isLoading, isLoaded } = getState().personalInfo;
        if (isUpdating || isLoading || !isLoaded) {
            return;
        }

        dispatch(requestUpdate());

        try {
            const updateEyResponse = await client.post(new UpdateEy({ isAuthorized }));


            if (updateEyResponse.responseStatus)
                throw updateEyResponse.responseStatus.message;

            dispatch(receiveUpdate({ isAuthorized: isAuthorized ? "y" : "n" }));

        }
        catch (e: unknown) {
            dispatch(receiveUpdateError());
            console.log(e);
        }

    }
}

export const eyAuthActionCreators = {
    resetState,
    setState,
    updateEy,
    hideSuccessMsg
};

export const eyAuthReducer = slice.reducer;
