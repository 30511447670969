import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetPendingActionsResponse, GetPendingActions } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IPendingActionsState extends ILoadableState {
    pendingActions: GetPendingActionsResponse
}

export const initialPendingActionsState: IPendingActionsState = {
    pendingActions: {} as GetPendingActionsResponse,
    isLoading: false,
    isLoaded: false
};


const slice = createSlice({
    name: 'pendingActions',
    initialState: {} as IPendingActionsState,
    reducers: {
        resetState: () => {
            return {
                ...initialPendingActionsState
            }
        },
        requestPendingActions: (state: IPendingActionsState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receivePendingActions: (state: IPendingActionsState, action: PayloadAction<{ pendingActions: GetPendingActionsResponse }>) => {
            const { pendingActions } = action.payload;
            return {
                ...state,
                pendingActions,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receivePendingActionsError: (state: IPendingActionsState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialPendingActionsState)
    }
})

const { resetState, requestPendingActions, receivePendingActions, receivePendingActionsError } = slice.actions;

function fetchPendingActions() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().pendingActions;
        if (isLoading) {
            return;
        }

        dispatch(requestPendingActions());

        try {

            const pendingActions = await client.post(new GetPendingActions());

            dispatch(receivePendingActions({ pendingActions }));

            if (pendingActions.pendingActions)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receivePendingActionsError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }

    }
}

export const pendingActionsActionCreators = {
    fetchPendingActions,
    resetState
};

export const pendingActionsReducer = slice.reducer;
