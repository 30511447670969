import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsCommit } from './LsriWizard.steps';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Button, Form, Spinner } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { DropDown, DropDownOption } from '../../DropDown';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { FormatDollar } from '../../FormatDollar';
import { cmsActionCreators } from '../../../store/CmsStore';
import LsriSelectionClearedModal from './Shared/LsriSelectionClearedModal';
import { LsriStage } from '../../../Gateway.dtos';


type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriPaymentOptions = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const isCalculating = lsri.calculationLoadState === DataLoadState.Loading

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Payment Options'


    const electedDate = lsri.lsriInfo?.oracleLsriElection?.paymentOptions.find(p => p.elected === true)?.date
    const paymentOptions = lsri?.lsriInfo?.oracleLsriElection?.paymentOptions;
    const monthlyPayment = lsri.userElection.lsriLifestage


    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Payment Options`

        //Prevent invalid page rendering, usually due to back button usage
        if (lsri.loadState === DataLoadState.None) {
            dispatch(LsriActionCreators.setWizardType(LsriStage.Setup))
            dispatch(push(GatewayPath.LsriOverview))
        }
        else {

            dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriPaymentOptions }))
            dispatch(LsriActionCreators.addCheckedMenuOptions(GatewayPath.LsriPaymentOptions))
            dispatch(LsriActionCreators.enableVerifyCheck())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDateDDLOptions = (): (DropDownOption)[] => {
        const ddlOptions: (DropDownOption)[] = [];
        paymentOptions?.forEach(d => ddlOptions.push({ value: d.date, display: d.date }))

        return ddlOptions;
    }


    const handleClickContinue = () => {
        dispatch(push(GatewayPath.LsriTaxOptions));
    }

    const handleClickCancel = () => {
        dispatch(LsriActionCreators.wizardCancel())
    }

    const handleDateChange = (val: string) => {
        if (val !== electedDate) {
            dispatch(LsriActionCreators.recalcOracleLsriElection({
                userElection: lsri.userElection,
                commencementDate: val
            }))
        }
    }


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "PaymentOptions"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    //End CMS Section


    const InitialPaymentLabel = () => (
        <div className="mt-5">
            <strong>
                Initial Monthly Payment
            </strong>

            <div className="mt-1" style={{color: 'black', fontSize: '24px', fontWeight: 'normal'}}>
                <FormatDollar amount={monthlyPayment} />
            </div>
        </div>
    )

    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 0,
                steps: wizardStepsCommit,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                <div className="mb-5" dangerouslySetInnerHTML={{ __html: headerMsgCms }} />

                <LsriSelectionClearedModal />

                <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    handleClickContinue();
                }}>

                    <Form.Group className={`mt-4`} style={{ maxWidth: `${isSmallScreen ? 'initial' : '20rem'}` }}>
                        <Form.Label><strong>Select First Payment Date</strong></Form.Label>
                        <DropDown name="firstPaymentDate"
                            dropDownOptions={getDateDDLOptions()}
                            defaultValue={electedDate}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e.target.value)}
                            disabled={isCalculating || isLoading}
                            tabIndex={1}
                        />
                    </Form.Group>

                    <InitialPaymentLabel />

                    <div className="mt-5 pt-4">

                        <Button variant="primary" tabIndex={3} className={`${isSmallScreen ? 'w-100' : ''}`} disabled={isCalculating || isLoading} onClick={() => handleClickContinue()}>
                            {isCalculating ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : 'Continue'}
                        </Button>

                        {!isSmallScreen && <span className="ml-4 mr-3">|</span>}

                        <Button variant="link px-0 mx-0" tabIndex={4} className={`${isSmallScreen ? 'w-100 mt-3' : ''}`} disabled={isCalculating || isLoading} onClick={() => handleClickCancel()}>
                            Cancel
                        </Button>

                    </div>

                </Form>

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriPaymentOptions);

