import { AppState } from "./configureStore";
import { GetCmsFragment, GetCmsFragmentResponse } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { LoadState } from "../definitions/ILoadableState";

interface Error {
    errorMessage: string | unknown
}

export interface ICmsFragmentItemState extends GetCmsFragmentResponse {
    itemPath: string,
    errorMessageItem?: string,
    loadStateItem: LoadState
}

export interface ICmsFragmentsState {
    cmsFragmentResponses: ICmsFragmentItemState[],
    //cmsFragmentResponses: ICmsFragmentItemState,
    errorMessage?: string  
}

const initialCmsFragmentState: ICmsFragmentsState = {
    cmsFragmentResponses: [{ itemPath: "", loadStateItem: 'loading' } as ICmsFragmentItemState]
    //cmsFragmentResponses: { itemPath: "", loadStateItem: 'loading' } as ICmsFragmentItemState
}

export const fetchCmsFragment = createAsyncThunk<
    ICmsFragmentItemState,
    GetCmsFragment,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'fetchCmsFragment',
    async ({ path }, thunkApi) => {
        try {
            const response = await client.post(new GetCmsFragment({ path:path })) as ICmsFragmentItemState
            response.itemPath = path;
            return response;

        } catch (e: unknown) {
            console.log("error", e)
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }
)

const slice = createSlice({
    name: 'baCms',
    initialState: initialCmsFragmentState,
    reducers: {
        resetState: () => initialCmsFragmentState
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCmsFragment.pending, (state: ICmsFragmentsState, action) => {
                if (state.cmsFragmentResponses && state.cmsFragmentResponses.length > 0) {
                    const cmsFragment = state.cmsFragmentResponses.find(x => x.itemPath === action.meta.arg.path);
                    if (!cmsFragment) {
                        const cmsItemFragment = { itemPath: action.meta.arg.path,  loadStateItem: 'loading' } as ICmsFragmentItemState;
                        state.cmsFragmentResponses.push(cmsItemFragment);
                    }
                }
                else {
                    const cmsFragment: ICmsFragmentItemState = { itemPath: action.meta.arg.path,  loadStateItem: 'loading' } as ICmsFragmentItemState;
                    state.cmsFragmentResponses.push(cmsFragment);
                }
            })
            .addCase(fetchCmsFragment.fulfilled, (state: ICmsFragmentsState, { payload }) => {
                if (state.cmsFragmentResponses && state.cmsFragmentResponses.length > 0) {
                    const cmsFragment = state.cmsFragmentResponses.find(x => x.itemPath === payload.itemPath);
                    if (cmsFragment) {
                        cmsFragment.loadStateItem = 'loaded';
                        cmsFragment.fragment = payload.fragment;
                    }
                }
            })
            .addCase(fetchCmsFragment.rejected, (state: ICmsFragmentsState, action) => {
                state.errorMessage = action.error.message
            })
            .addCase(getResetAction(), (_state, _action) => initialCmsFragmentState)
    }
})

export const BaCmsReducer = slice.reducer;

export const baCmsActionCreators = {
    resetState: slice.actions.resetState,
    fetchCmsFragment
};
