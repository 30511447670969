import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsSetup } from './LsriWizard.steps';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import LsriFlowChart from './Shared/LsriFlowChart';
import { LsriFlowChartDisclaimer } from './Shared/LsriFlowChartDisclaimer';
import { cmsActionCreators } from '../../../store/CmsStore';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { LsriStage } from '../../../Gateway.dtos';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import LsriSelectionClearedModal from './Shared/LsriSelectionClearedModal';
import LsriMinPaymentMsg from './Shared/LsriMinPaymentMsg';
import { scrollToElementById } from '../../../functions/scrollToElementById';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriMpp = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const pendingActionLink = "/mybenefits_pendingactions.aspx";
    const hasPendingMpp = lsri.lsriInfo.hasPendingMpp

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Keep MPP 65% as an annuity or include in LifeStage'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - MPP 65% Options`

        //Reset calculation errors when the user changes pages
        dispatch(LsriActionCreators.resetCalcLoadState())

        if (lsri.wizardType === LsriStage.Setup)
            dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriMpp }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lsri.wizardType === LsriStage.Setup && !lsri.menuOptions?.enabledMenuOptions?.includes(GatewayPath.LsriReview))
            dispatch(LsriActionCreators.enableReviewCheck())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.lsriInfo.oracleLsriElection?.visitedSteps])

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });
    

    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "MPP"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    const rolloverMppMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/RolloverMppMsg`)?.html ?? '';
    //End CMS Section


    const PendingMpp = () => (        
        <Alert variant="warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            To see the impact of including the MPP 65% account balance in LifeStage Retirement Income, you must 
            first cancel your pending action to convert the MPP 65% to an annuity.

            <div className="mt-3 mb-2">
                <BenefitsAccessSso to={pendingActionLink}>
                    <Button variant="secondary">View Pending Actions</Button>
                </BenefitsAccessSso>
            </div>
        </Alert>
    )



    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 2,
                steps: wizardStepsSetup,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                <LsriSelectionClearedModal />

                <p className="mb-4">
                    <span dangerouslySetInnerHTML={{ __html: headerMsgCms }} />
                    &nbsp;<span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => scrollToElementById('learnMoreContent', isSmallScreen)}>Learn more</span>
                </p>

                {hasPendingMpp && <PendingMpp />}

                <LsriMinPaymentMsg isDismissable />

                <LsriFlowChart />

                <p className="my-5" id='learnMoreContent' dangerouslySetInnerHTML={{ __html: rolloverMppMsgCms }} />

                <LsriFlowChartDisclaimer />

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriMpp);

