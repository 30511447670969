import { AppState } from "./configureStore";
import { GetAssetAllocation, GetAssetAllocationResponse } from "./../Gateway.dtos";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IAssetAllocationState extends ILoadableState {
    assetAllocation: GetAssetAllocationResponse
}

export const initialAssetAllocationState: IAssetAllocationState = {
    assetAllocation: {} as GetAssetAllocationResponse,
    isLoading: false,
    isLoaded: false
};


const slice = createSlice({
    name: 'assetAllocation',
    initialState: {} as IAssetAllocationState,
    reducers: {
        resetState: () => {
            return {
                ...initialAssetAllocationState
            }
        },
        requestAssetAllocation: (state: IAssetAllocationState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveAssetAllocation: (state: IAssetAllocationState, action: PayloadAction<{ assetAllocation: GetAssetAllocationResponse }>) => {
            const { assetAllocation } = action.payload;
            return {
                ...state,
                assetAllocation,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveAssetAllocationError: (state: IAssetAllocationState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialAssetAllocationState)
    }
})

const { resetState, requestAssetAllocation, receiveAssetAllocation, receiveAssetAllocationError } = slice.actions;

function fetchAssetAllocation() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().assetAllocation;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestAssetAllocation());

        try {

            const assetAllocation = await client.post(new GetAssetAllocation());

            dispatch(receiveAssetAllocation({ assetAllocation }));

            if (assetAllocation.totalBal > 0 || assetAllocation.allocations?.length > 0)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receiveAssetAllocationError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }

    }
}

export const assetAllocationActionCreators = {
    fetchAssetAllocation,
    resetState
};

export const assetAllocationReducer = slice.reducer;
