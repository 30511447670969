import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Button, Form, Row, Col, Alert, Spinner} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { activityActionCreators } from '../../../store/ActivityStore';
import { authMultiFactorActionCreators, CodeState } from '../../../store/AuthMultiFactorStore';
import { AppDispatch } from '../../..';
import ScrollToTop from '../../ScrollToTop';

//TODO: Fix eslint disable
interface Props {
    onValidated?: Function // eslint-disable-line
}

type TAuthMultiFactorEnterCode = ReturnType<typeof mapStateToProps>;

const AuthMultiFactorEnterCode = (props: TAuthMultiFactorEnterCode) => {
    
    const [code, setCode] = useState('');
    const [codeSentAmt, setCodeSentAmt] = useState(1);
    const [showSendAnotherCode, setShowSendAnotherCode] = useState(true);

    const dispatch: AppDispatch = useDispatch();    

    const { codeState, selectedNumber, maxCodesSent } = props;
    useEffect(() => {
        dispatch(activityActionCreators.logActivity("AuthMultiFactorEnterCode"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setShowSendAnotherCode(codeSentAmt <= 2 && !maxCodesSent);
    }, [setShowSendAnotherCode, codeSentAmt, maxCodesSent])

    const submit = () => {
        dispatch(authMultiFactorActionCreators.validateCode(code));
    }

    const sendAnotherCode = () => {

        setCode('');
        setCodeSentAmt(codeSentAmt + 1);     

        dispatch(authMultiFactorActionCreators.sendCode());
    }

    const selectNewPhone = () => {
        dispatch(authMultiFactorActionCreators.backToSelectPhone());
    }


    return (
        <>
            <ScrollToTop />
            <p>
                We sent a one-time security code to {selectedNumber}. You can choose to send this code to 
                a <Link to="#" onClick={selectNewPhone}>different phone number</Link> from our records.
            </p>

            {codeState === CodeState.Expired &&
                <Alert className="my-4" variant="warning">
                    The security code you entered has expired. Codes are valid for 5 minutes. To try again we
                    can <Link to="#" tabIndex={4} onClick={sendAnotherCode}>send another code</Link> to your phone.
                </Alert>
            }

            {codeState === CodeState.Invalid &&
                <Alert className="my-4" variant="danger">
                    The code you have entered is invalid. {!props.lastAttempt ? <>Please try again or send another code to your phone.</> : <>You have one more attempt before the account is locked.</>}
                </Alert>
            }

            
            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                submit();
            }}>

                
                {   //TODO: Fix eslint
                    // eslint-disable-next-line
                    false ? <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                /> : <>
                    <Row>
                        <Col className="col-12 col-sm-6 col-md-5 col-lg-4">
                            <Form.Group controlId="code">
                                <Form.Label>Enter Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={code}
                                    autoComplete="off"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCode(e.target.value) }}
                                    maxLength={20}
                                    tabIndex={1}
                                    autoFocus
                                />
                                    <div className="footnote">Codes are only valid for 5 minutes.</div>
                            </Form.Group>
                        </Col>
                        <Col className="col-12 col-sm-6 col-md-7 mt-sm-4 pt-sm-0 pt-md-3">
                            {!props.isLoading && showSendAnotherCode && <>
                                Did not receive this code? <Link to="#" tabIndex={3} onClick={sendAnotherCode}>Send another</Link>.
                            </>}
                        </Col>
                    </Row>

                    <Row>
                        <Col className="form-group">
                                <Button className="mt-4" variant="primary" type="submit" tabIndex={2} disabled={!code || props.codeState === CodeState.Validating}>
                                {props.codeState === CodeState.Validating ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : "Next"}
                            </Button>
                        </Col>
                    </Row>
                </>}

            </Form>

        </>
    );
}

function mapStateToProps(state: AppState, ownProps: Props) {

    return {
        ...state.authMultiFactor,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...authMultiFactorActionCreators, ...activityActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthMultiFactorEnterCode);
