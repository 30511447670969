const formatPhone = function (phoneText: string, countryCode: string, mask?: boolean, addPlusIfForeign?: boolean): string {

    //Check for undefined values
    if (!phoneText) return "";
    if (!countryCode) countryCode = '';

    //Replace any non numeric characters
    phoneText = phoneText.replace(/\D/g, '');

    //Add the masking
    if (mask || mask === undefined)
        phoneText = phoneText.replace(/\d(?=\d{4})/g, "X");

    if (countryCode.toUpperCase() === 'US') {
        if (phoneText.length > 6) 
            return "(" + phoneText.substr(0, 3) + ") " + phoneText.substr(3, 3) + "-" + phoneText.substr(6, phoneText.length - 6);
        else if (phoneText.length > 3) 
            return "(" + phoneText.substr(0, 3) + ") " + phoneText.substr(3, phoneText.length - 3);
        else
            return phoneText;
    }
    else {
        return `${addPlusIfForeign ? '+' : ''}${phoneText}`;
    }
}

export { formatPhone }