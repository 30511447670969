import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsManage, wizardStepsSetup } from './LsriWizard.steps';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import LsriFlowChart from './Shared/LsriFlowChart';
import { LsriFlowChartDisclaimer } from './Shared/LsriFlowChartDisclaimer';
import { LsriAccountTransfer, LsriStage } from '../../../Gateway.dtos';
import { cmsActionCreators } from '../../../store/CmsStore';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import LsriFundingManage from './LsriFundingManage';
import LsriSelectionClearedModal from './Shared/LsriSelectionClearedModal';
import LsriMinPaymentMsg from './Shared/LsriMinPaymentMsg';
import { scrollToElementById } from '../../../functions/scrollToElementById';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriFunding = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;

    const [isLoading, setIsLoading] = useState((lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error) || lsri.isLsriPendingTransfer);

    const isManage = lsri.wizardType === LsriStage.Manage;

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = isManage
        ? 'Balance Transfer'
        : 'Choose how much to put into LifeStage Retirement Income'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Funding LifeStage`

        //Reset calculation errors when the user changes pages
        dispatch(LsriActionCreators.resetCalcLoadState())

        if (lsri.wizardType === LsriStage.Setup)
            dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriFunding }))

        if (lsri.isLsriPendingTransfer) {
            dispatch(LsriActionCreators.performBalanceTransfer({
                accountTransfer: {
                    isPending: true
                } as LsriAccountTransfer
            })).then(() => {
                setIsLoading(false);
            })
        }
    }, []);

    useEffect(() => {
        if (lsri.wizardType === LsriStage.Setup && !lsri.menuOptions?.enabledMenuOptions?.includes(GatewayPath.LsriReview))
            dispatch(LsriActionCreators.enableReviewCheck())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.lsriInfo.oracleLsriElection?.visitedSteps])


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Funding"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    const fundingLsriMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/FundingLsriMsg`)?.html ?? '';
    //End CMS Section



    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: lsri.wizardType === LsriStage.Manage ? 2 : 3,
                steps: lsri.wizardType === LsriStage.Manage ? wizardStepsManage : wizardStepsSetup,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                {isManage
                    ? <LsriFundingManage />
                    : <>

                        <LsriSelectionClearedModal />

                        <p className="mb-4">
                            <span dangerouslySetInnerHTML={{ __html: headerMsgCms }} />
                            &nbsp;<span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => scrollToElementById('learnMoreContent', isSmallScreen)}>Learn more</span>
                        </p>

                        <LsriMinPaymentMsg isDismissable />

                        <LsriFlowChart />

                        <div className="my-5" id='learnMoreContent' dangerouslySetInnerHTML={{ __html: fundingLsriMsgCms }} />

                        <LsriFlowChartDisclaimer />
                    </>
                }

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriFunding);

