import React, { useEffect } from 'react';
import { Card, Button, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/pro-solid-svg-icons';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { assetAllocationActionCreators } from '../../../store/AssetAllocationStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { GetAssetAllocationResponse } from '../../../Gateway.dtos';
import { FormatDollar } from '../../FormatDollar';
import { FormatPercent } from '../../FormatPercent';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../..';
import { push } from 'connected-react-router';
import { BAConvertedRoutes } from '../../../definitions/BAConvertedRoutes';

type TAssetAllocationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const AssetAllocation = (props: TAssetAllocationProps) => {
    const dispatch: AppDispatch = useDispatch();

    const isLoading = (props.isLoading && !props.isLoaded);
    const isErr = (!isLoading && props.assetAllocation?.responseStatus !== undefined);
    const showCard = (isErr || (!isLoading && props.assetAllocation?.allocations));
    const cardTitle = "Asset Allocations";

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchAssetAllocation();
        }
    });

    return (<>
        {isLoading && <LoadingSkeleton />}

        {showCard && 
            <Card className="pageCard secondary">
                <Card.Title><FontAwesomeIcon icon={faChartPie} className="ret-color" />{cardTitle}</Card.Title>
                <Card.Body>

                    <StripedTable allocations={props.assetAllocation} isLoading={isErr} />

                    {isErr && <Alert className="mt-2" variant="danger">Unable to retrieve data for {cardTitle}</Alert>}

                    <div className="mt-4">
                        <Button variant="secondary" onClick={() => dispatch(push(BAConvertedRoutes.InvestmentsSummary)) }>View Investment Details</Button>
                    </div>

                </Card.Body>
            </Card >
        }
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        ...state.assetAllocation
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...assetAllocationActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssetAllocation);


function StripedTable(props: { allocations: GetAssetAllocationResponse, isLoading?: boolean }) {
    const { allocations, isLoading } = props;

    return (
        <Table striped className="assetAllocationTable">

            <thead>
                <tr className="assetAllocationHeader">
                    <th style={{ width: '65%' }}>Funds</th>
                    <th style={{ width: '15%' }} className="text-nowrap">
                        <span className="desktop">% of Current<br />Balance</span>
                        <span className="mobile">Percent</span>
                    </th>
                    <th>Amount</th>
                </tr>
            </thead>

            <tbody>
                {!isLoading && allocations?.allocations.map((item, i) => (
                    <tr key={i} className="assetAllocationRow">
                        <td>{item.fund}</td>
                        <td><FormatPercent percent={item.allocPct} /></td>
                        <td><FormatDollar amount={item.allocAmt} /></td>
                    </tr>
                ))}
            </tbody>

            {!isLoading && allocations?.totalBal &&
                <tfoot>
                    <tr>
                        <th>Total</th>
                        <th colSpan={2} className="text-right">
                            <FormatDollar amount={allocations.totalBal} />
                        </th>
                    </tr>
                </tfoot>
            }

        </Table>
    );
}

const LoadingSkeleton = () => (
    <Card className="pageCard secondary pb-3">
        <div><Skeleton width={'70%'} height={'2em'} /></div>

        <div className="mt-3"><Skeleton height={'8em'} /></div>
    </Card>
);