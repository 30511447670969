import { GatewayPath } from '@wespath/gateway-navigation';
import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';


const BenefitsAccessSso = (props: {
    to: string
    children: string | JSX.Element
    buttonLink?: boolean
    className?: string
}): ReactElement => {
    let to = props.to;
    let urlBase: string = process.env.REACT_APP_API_URL;

    while (urlBase.slice(-1) === "/") {
        urlBase = urlBase.slice(0, -1);
    }

    while (to.slice(0, 1) === "/") {
        to = to.slice(1);
    }

    const prettyUrl = `/BA/${to}`;
    const isButton = props.buttonLink || typeof props.children === typeof Button

    return <a className={`${isButton ? "btn btn-link" : ""} ${props.className ? props.className : ""}`} href={prettyUrl}>{props.children}</a>
};

export default BenefitsAccessSso
