import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogActivity } from "../Gateway.dtos";
import { client } from "../App";
import { AppDispatch } from "..";

export interface IActivityState{
    page: string
    subPage: string
}

export const initialActivityState: IActivityState = {
    page: '/',
    subPage: ''
};

const slice = createSlice({
    name: 'activity',
    initialState: {} as IActivityState,
    reducers: {
        setActivity: (state: IActivityState, action: PayloadAction<{ page: string, subPage: string }>) => {
            const { page, subPage } = action.payload;
            return {
                ...state,
                page,
                subPage
            }
        }
    }
})

const { setActivity } = slice.actions;

function logActivity(subPage?: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const previousPage = getState().activity.page;
        const previousSubPage = getState().activity.subPage;
        const page = getState().router.location.pathname;
        subPage = subPage || '';
        
        //Do not log activity if page/SubPage isn't different
        if (page === previousPage && subPage === previousSubPage) {
            return
        }

        //Do not log the wizard route if the subPage for that wizard has already been logged
        if (page === previousPage && subPage === '') {
            return
        }

        dispatch(setActivity({ page, subPage }));

        try {
            const { username, participantNumber } = getState().account;

            const logPage = page + (subPage ? "_" + subPage : "")

            const activity = await client.post(new LogActivity({
                partNumber: participantNumber, username, page: logPage
            }));

            //Check for errors
            if (activity.responseStatus)
                throw activity.responseStatus.message;

        } catch (e: unknown) {
            //console.log(e);
        }

    }
}

export const activityActionCreators = {
    logActivity
};

export const activityReducer = slice.reducer;
