import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Step, WizardProgressBarProps } from "../definitions/WizardProgressBarProps";
import { PageTitle } from "./PageTitle";
import { SidePanelWizardProgress } from "./SidePanelWizardProgress";
import { useWindowDimensions } from "@wespath/gateway-navigation";
import UnlockBanner from "./UnlockBanner";
import { SidePanelTextAbove, SidePanelTextBelow, SidePanelWizardStepContent } from "./SidePanelWizardContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import LsriSyncBanner from "./pages/Lsri/Shared/LsriSyncBanner";


interface Props {
    progressBar?: WizardProgressBarProps,
    stepName: string,
    children: React.ReactNode,
    wizardTitle: string,
    wizardColor: string,
    printBtnText?: string,
    hidePrintButton?: boolean,
    hideUnlockBanner?: boolean
}

const SidePanelWizard = (props: Props): React.ReactElement => {

    const { isSmallScreen } = useWindowDimensions()

    const activeStepDetails = props.progressBar?.steps ? props.progressBar?.steps[props.progressBar?.activeIndex] : {} as Step;
    const stepHasSubPanel = activeStepDetails?.isExpandable

    const [stepPanelExpanded, setStepPanelExpanded] = useState(stepHasSubPanel)
    const [menuExpanded, setSetupMenuExpanded] = useState(false)


    const handleMobileSetupClick = () => {

        if (!menuExpanded) {
            setStepPanelExpanded(false)
            setSetupMenuExpanded(true)
        }
        else {
            setSetupMenuExpanded(false)
            setStepPanelExpanded(stepHasSubPanel)
        }
    }

    return <>

        {!props.hideUnlockBanner && <UnlockBanner />}

        {props.progressBar?.menuOptions?.lsriSyncBanner && <LsriSyncBanner />}

        {!isSmallScreen && <Card className="detailCard desktop" style={{ marginTop: '2em' }}>

            <PageTitle title={props.wizardTitle} color={`${props.wizardColor}-bg`} printBtnText={props.printBtnText} hidePrintButton={props.hidePrintButton} />

            <Card.Body className="sidePanelWizardCardBody">

                <Row className="p-0 m-0">
                    
                    {props.progressBar && <Col className={`p-0 mb-0 pt-4 sidePanelWizardCardMenu ${props.progressBar?.menuOptions?.blurContent ? 'blurContent' : ''}`}>

                        <SidePanelTextAbove wizardTitle={props.wizardTitle} />

                        {props.progressBar && <SidePanelWizardProgress progressBar={props.progressBar} wizardTitle={props.wizardTitle} />} 

                        <SidePanelTextBelow wizardTitle={props.wizardTitle} />

                    </Col>}

                    <Col className={`pt-4 px-md-4 px-lg-4 px-xl-5 ${props.progressBar?.menuOptions?.blurContent ? 'blurContent' : ''}`}>
                        <h3 className={`pb-3 ${props.wizardColor} sidePanelWizardCardHeader`}>{props.stepName}</h3>

                        <div style={{marginBottom: '7rem'}}>
                            {props.children}
                        </div>  
                    </Col>
                </Row>
            </Card.Body>
        </Card>}

        {isSmallScreen && <div className="detailCard mobile showForMd">

            <Row className="sidePanelWizardMobile mx-0">
                <div className="mobileMenuText clickable" onClick={() => handleMobileSetupClick()}>
                    {props.progressBar?.menuOptions?.mobileMenuText ?? "Setup"}<FontAwesomeIcon icon={faSlidersH} className="pl-1" size="lg" />
                </div>
                <Col className={`stepName clickable`} onClick={() => handleMobileSetupClick()}>
                    {activeStepDetails?.display}
                </Col>
                {stepHasSubPanel && <Col className="col-1 chevron pl-0 pr-2 text-right clickable" onClick={() => handleMobileSetupClick()}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </Col>}
            </Row>

            {stepPanelExpanded && <Row className="sidePanelWizardMobile stepDetails mx-0">
                <Col className="subPanel col-12" >
                    {activeStepDetails.path && <SidePanelWizardStepContent wizardTitle={props.wizardTitle} wizardPath={activeStepDetails.path} key={`${activeStepDetails.path}_expanded`} isExpanded />}
                </Col>
            </Row>}

            {menuExpanded && <Row className="sidePanelWizardMobile menuDetails mx-0" onClick={() => handleMobileSetupClick()}>
                <Col className="subPanel col-12 px-0">
                    <SidePanelTextAbove wizardTitle={props.wizardTitle} />

                    {props.progressBar && <SidePanelWizardProgress progressBar={props.progressBar} wizardTitle={props.wizardTitle} />}

                    <SidePanelTextBelow wizardTitle={props.wizardTitle} />
                </Col>
            </Row>}


            <Card>
                <PageTitle title={props.wizardTitle} color={`${props.wizardColor}-bg`} printBtnText={props.printBtnText} hidePrintButton={props.hidePrintButton} />
                <Card.Body className="mx-3 py-4">
                    <h3 className={`pb-3 ${props.wizardColor}`}>{props.stepName}</h3>

                    <div>
                        {props.children}
                    </div>  
                </Card.Body>
            </Card>
        </div>}

    </>

}

export { SidePanelWizard };