import React, { useState, CSSProperties, ReactElement } from "react";
import { Form } from "react-bootstrap";

export interface DropDownControlProps {
    dropDownOptions: (DropDownOption)[];
    id?: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onBlur?: any;
    defaultValue?: string;
    className?: string;
    disabled?: boolean;
    style?: CSSProperties;
    tabIndex?: number;
}

export interface DropDownOption {
    display: string;
    value: string;
    hideIfNotSelected?: boolean;
}

const DropDown = (props: DropDownControlProps): ReactElement => {

    const [ddlValue, setDDlValue] = useState(props.defaultValue);

    const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDDlValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    }

    const onBlurInternal = (e: React.FocusEvent<HTMLSelectElement>) => {
        if (props.onBlur) {
            props.onBlur(e);
        }
    }

    const options = props.dropDownOptions.map((ddlOption, i) => {

        if (ddlOption.hideIfNotSelected && ddlOption.value === props.defaultValue)
            return (
                <option key={i} value={ddlOption.value} disabled style={{ display: 'none' }}>
                    {ddlOption.display}
                </option>
            )
        else if (ddlOption.hideIfNotSelected)
            return null
        else
            return (
                <option key={i} value={ddlOption.value}>
                    {ddlOption.display}
                </option>
            )
        
    });

    return (
        <Form.Control
            as="select"
            style={props.style}
            id={props.id}
            name={props.name}
            value={ddlValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onChangeInternal(e) }}
            onBlur={(e: React.FocusEvent<HTMLSelectElement>) => { onBlurInternal(e) }}
            className={props.className}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
        >
            {options}
        </Form.Control>
    )
};

export { DropDown };