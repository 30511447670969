import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { formatPhone } from '../../../functions/formatPhone';
import { Phone, PhoneType, CallType } from '../../../Gateway.dtos';
import Skeleton from "react-loading-skeleton";
import { lnMultiFactorActionCreators } from '../../../store/LNMultiFactorStore';
import { activityActionCreators } from '../../../store/ActivityStore';
import { getDefaultPhone } from '../../../functions/getDefaultPhone';
import { findTypeByPhone } from '../../../functions/findTypeByPhone';
import ScrollToTop from '../../ScrollToTop';
import { AppDispatch } from '../../..';

type TMultiFactorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LNMultiFactorPhone = (props: TMultiFactorProps) => {
    const { fetchPhones, phones, isLoading, phonesLoaded } = props;
    const retrievingCode = phonesLoaded && isLoading;

    const [selPhoneNum, setSelPhoneNum] = useState(getDefaultPhone(props.phones));
    const [selCallType, setSelCallType] = useState(CallType.TEXT);
    const [selIndex, setSelIndex] = useState(0);

    const { logActivity } = props;
    useEffect(() => {
        logActivity("MultiFactorPhone");
    }, [logActivity])

    useEffect(() => {
        setSelPhoneNum(getDefaultPhone(phones));
    }, [phones]);


    useEffect(() => {
        if (!phonesLoaded && !isLoading) {
            fetchPhones();
        }
    }, [phonesLoaded, isLoading, fetchPhones]);


    const submit = () => {
        if (!selPhoneNum)
            return;

        props.fetchCode(selPhoneNum, selCallType, findTypeByPhone(phones, selPhoneNum));
    }

    const codeError = () => {
        return props.error ||
            (props.multiFactor?.transactionResponse && props.multiFactor.transactionResponse.transactionStatus !== "pending");
    }


    const DisplayPhone = (props: { phone: Phone, isLoading: boolean, index: number }) => {
        const { number, type } = props.phone

        return (
            <Row>
                <ScrollToTop />
                <Col className="col-12 my-3">

                    <p className="capitalize my-0">
                        {type === PhoneType.MOBILE ? "Text Message" : type.toString().toLowerCase()} Phone
                    </p>

                    <p className="my-1">
                        {formatPhone(number, 'US')}
                    </p>

                    <div className="form-check">
                        <label>
                            <input
                                type="radio"
                                className="form-check-input"
                                name="phoneSelection"
                                value={number + "TEXT" + props.index}
                                checked={(number + "TEXT" + props.index) === (selPhoneNum + selCallType + selIndex)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSelPhoneNum(number); setSelCallType(CallType.TEXT); setSelIndex(props.index); }}
                                disabled={props.isLoading}
                                tabIndex={(props.index * 10) + 1}
                            />
                            Text Message
                        </label>
                    </div>

                    <div className="form-check">
                        <label>
                            <input
                                type="radio"
                                className="form-check-input"
                                name="phoneSelection"
                                value={number + "VOICE" + props.index}
                                checked={(number + "VOICE" + props.index) === (selPhoneNum + selCallType + selIndex)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSelPhoneNum(number); setSelCallType(CallType.VOICE); setSelIndex(props.index); }}
                                disabled={props.isLoading}
                                tabIndex={(props.index * 10) + 2}
                            />
                            Voice Call
                        </label>
                    </div>

                </Col>
            </Row>
        )
    };

    return (<>

        {!phonesLoaded && <LoadingSkeleton />}

        {phonesLoaded && <>

            <p>
                We will send a one-time security code to the phone number we have on file. If we have multiple phone numbers
                on file, you can choose which one you would like to use. You can manage your phone numbers in your profile settings.
            </p>

            {codeError() &&
                <Alert className="my-4" variant="warning">
                    The security code could not be sent to your device. Please select another option.
                </Alert>
            }

            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                submit();
            }}>

                {props.phones?.map((phone, i) => (
                    <DisplayPhone key={i} index={i} phone={phone} isLoading={retrievingCode!} />
                ))}

                <div className="form-group">
                    <Button className="mt-4" variant="primary" type="submit" tabIndex={70} disabled={retrievingCode} autoFocus>
                        {retrievingCode ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : "Next"}
                    </Button>
                </div>

            </Form>

        </>}
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        ...state.lnMultiFactor
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...lnMultiFactorActionCreators,
        ...activityActionCreators
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LNMultiFactorPhone);

const LoadingSkeleton = () => (
    <div className="mb-5">
        <div><Skeleton width={'100%'} /></div>
        <div><Skeleton width={'100%'} /></div>
        <div className="mb-3"><Skeleton width={'100%'} /></div>

        <div className="my-5 ml-4">
            <div><Skeleton width={'10em'} /></div>
            <div><Skeleton width={'10em'} /></div>
            <div><Skeleton width={'10em'} /></div>
            <div><Skeleton width={'10em'} /></div>
        </div>

        <div className="my-5 ml-4">
            <div><Skeleton width={'10em'} /></div>
            <div><Skeleton width={'10em'} /></div>
            <div><Skeleton width={'10em'} /></div>
            <div><Skeleton width={'10em'} /></div>
        </div>
    </div>
);