import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { AppState } from '../store/configureStore';
import { NavLink } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation'
import { connect } from 'react-redux';

type TPageNotFoundProps = ReturnType<typeof mapStateToProps>;

const PageNotFound = (props: TPageNotFoundProps) => {

    return (
        <Row className="justify-content-center">
            <Col className="col-12 col-md-6 col-lg-5 col-xl-5">
                <Card className="errorCard">
                    <Card.Title>
                        Sorry!
                    </Card.Title>
                    <Card.Body>
                        <p>
                            We apologize but it seems we could not find the page you are looking for.
                        </p>

                        <Row className="mt-5">
                            <Col className="col-12 col-md-6">
                                {props.isAuthenticated ?
                                    <NavLink to={GatewayPath.BenefitsSummary} className="text-nowrap">Benefits Summary</NavLink>                                    
                                    :
                                    <NavLink to={GatewayPath.Login}>Login</NavLink>
                                }
                            </Col>
                            <Col className="col-12 col-md-6">
                                <a href="https://www.wespath.org/about-wespath/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a>
                            </Col>
                        </Row>

                        

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
function mapStateToProps(state: AppState) {
    return {
        isAuthenticated: state.account.isAuthenticated
    }
}

export default connect(
    mapStateToProps
)(PageNotFound);