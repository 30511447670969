import React, { ReactElement } from "react";
import { Row, Col } from "react-bootstrap";
import { FormatDollar } from "./FormatDollar";
import { Alert } from "react-bootstrap";

export interface AmountPanelProps {
    label: string
    balance?: string | number
    minorText?: string
    footnote?: string
    secondLabel?: string
    secondBalance?: number
    isLoading?: boolean
    noBottomBorder?: boolean
    sideMsg?: string
    isErr?: boolean
    children?: unknown
    zeroAsDash?: boolean
}
const AmountPanel = (props: AmountPanelProps): ReactElement => {

    const showBalance = !props.isErr && props.balance !== undefined;

    return (<>

        <Row className="amountPanel top">
            <Col className={`col-12 ${showBalance ? 'col-sm-6 mr-sm-0 pr-sm-0' : 'text-left'}`}>
                <h4>{props.label}</h4>
                {props.minorText &&
                    <p className="minorText mb-0">
                        {props.minorText}
                    </p>
                }
            </Col>

            {showBalance && <Col className={`${typeof props.balance === 'string' ? 'col-12' : 'ml-sm-0 pl-sm-0'}`}>
                {typeof props.balance === 'string' ?
                    <p className="mb-0 text-left">{props.balance}</p>
                    :
                    <h1 className="text-nowrap"><FormatDollar amount={props.balance} zeroAsDash={props.zeroAsDash} /></h1>
                }
            </Col>}
        </Row>

        {!props.isLoading && !props.isErr && props.secondLabel && props.secondBalance !== undefined &&
            <Row className="amountPanel bottom">
                <Col className="col-12 col-sm-6 mr-sm-0 pr-sm-0">
                    {props.secondLabel}
                </Col>
                <Col className="col-12 col-sm-6 ml-sm-0 pl-sm-0">
                    <h4 className="text-nowrap mb-0">
                        <FormatDollar amount={props.secondBalance} zeroAsDash={props.zeroAsDash} />
                    </h4>
                </Col>
            </Row>
        }

        {props.footnote && !props.isErr &&
            <Row className="mt-3 mb-4">
                <Col className="footnote">
                    {props.footnote} {props.children}
                </Col>
            </Row>
        }

        {props.isErr && <Alert className="mt-2" variant="danger">Unable to retrieve data for {props.label}</Alert>}

        <div className={`bottomBorder ${props.noBottomBorder ? "disabled" : ""}`} />

    </>);
}
export { AmountPanel };