import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppDispatch } from '../../..';
import { settingUpRetirementIncomeActionCreators } from '../../../store/SettingUpRetirementIncomeStore';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { Row, Col, Button, Card } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenDollar } from '@fortawesome/pro-solid-svg-icons';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import Skeleton from "react-loading-skeleton";
import { cmsActionCreators } from '../../../store/CmsStore';

export interface OwnProps { isSummary?: boolean }
type TSettingUpYourRetirementIncomeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SettingUpYourRetirementIncome = (props: TSettingUpYourRetirementIncomeProps) => {
    const dispatch: AppDispatch = useDispatch();
    const retirementBenefitsURL = "takeaction_retirement_benefits.aspx";
    const isErr = (props.loadState === DataLoadState.Loaded && props.settingUpRetirementIncome?.responseStatus !== undefined);
    const cmsData = props.cmsData.fragments?.find(f => f.name === "SettingUpYourRetirementMessage");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }

        if (stateNeedsLoading(props)) {
            dispatch(settingUpRetirementIncomeActionCreators.fetchSettingUpRetirementIncome())
                .then(() => {
                    setIsLoading(false);
                });
        }
        else {
            setIsLoading(false);
        }
    });

    useEffect(() => {
        setHasAccessRISPage(props.settingUpRetirementIncome.hasAccessRISPage);
    }, [props.settingUpRetirementIncome.hasAccessRISPage]);

    const [hasAccessRISPage, setHasAccessRISPage] = useState(props.settingUpRetirementIncome.hasAccessRISPage);
    const [isLoading, setIsLoading] = useState(true);


    const TitleText = () => (<>
        Setting Up Your Retirement Income
    </>);

    const SetupButton = (props: { isSummary?: boolean }) => (
        <Button variant={props.isSummary ? "outline-primary" : "secondary"}  className={props.isSummary ? "minimum-button-width" : "mt-4"}>Setup Now</Button>)

    const BodyElements = (props: { isSummary?: boolean }) => (<>
        {cmsData ? <p className={props.isSummary ? "footnote amountPanelSmall" : "thin"} dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}
        { /*
            DE_Redirect_Rule:
            1) If Retirement Income Summary page (takeaction_retirement_benefits.aspx) is available according to rules for 18.22, redirect there,  - /logon/plan/participant@dist_page_elig = ‘y’
            2) else LifeStage Retirement Income Program Overview page 18.18.01
        */}
        {hasAccessRISPage
            ? <BenefitsAccessSso to={retirementBenefitsURL}>
                <SetupButton isSummary={props.isSummary}/>
            </BenefitsAccessSso>
            : <NavLink to={GatewayPath.LsriOverview} className="nav-link px-0" activeClassName="active">
                <SetupButton isSummary={props.isSummary} />
            </NavLink>
        }
    </>)

    if (props.isSummary) {

        return (
            <>
                <div className="smallLabel"><TitleText /></div>
                {isLoading && <LoadingSkeleton />}
                {!isErr && !isLoading && <>
                    <Row className="mt-3">
                        <Col className="mx-2">
                            <FontAwesomeIcon icon={faEnvelopeOpenDollar} className="ret-color sliders-larger" />
                        </Col>
                        <Col className="col-9 mx-0 px-0">
                            <BodyElements isSummary={props.isSummary} />
                        </Col>
                    </Row>
                </>}
            </>
        );

    }
    else {

        return (
            <Card className="pageCard secondary">
                <Card.Title>
                    <FontAwesomeIcon icon={faEnvelopeOpenDollar} className="ret-color" />
                    <TitleText />
                </Card.Title>

                <Card.Body>
                    <BodyElements isSummary={props.isSummary}/>
                </Card.Body>
            </Card>
        );

    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...state.settingUpRetirementIncome,
        ...ownProps,
        cmsData: state.cms.retirement
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...settingUpRetirementIncomeActionCreators,
        fetchCmsData: cmsActionCreators.fetchRetirementCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingUpYourRetirementIncome);

const LoadingSkeleton = () => (
    <div>
        <div><Skeleton width={'8em'} height={'1em'} /></div>
        <div><Skeleton width={'10em'} height={'1.5em'} /></div>
    </div>
);