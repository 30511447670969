import React, { useLayoutEffect, useRef } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useParams } from 'react-router';

type SsoParams = {
    page: string;
};


const BaOutboundSso = () => {
    const { page } = useParams<SsoParams>();
    const formRef = useRef<HTMLFormElement>(null);
    useLayoutEffect(() => {
        formRef?.current?.submit();
    }, []);

    

    return <>
        <form ref={formRef} method="POST" action={`${process.env.REACT_APP_API_URL}/BenefitsAccessSso`}>
            <input type="hidden" name="contextToken" value={sessionStorage.getItem("PG.ContextToken") ?? ""} />
            <input type="hidden" name="page" value={page} />
        </form>
    </>
};

export { BaOutboundSso };
