const getUnformattedString = function (val: string): string {

    if (val && val.trim() !== "") {
        val = val.replace(/-/g, "").replace(/_/g, "").replace(/\//g, "").replace(/,/g, "").replace(/$/g, "").replace(/%/g, "").trim();
    }

    if (!val) {
        val = "";
    }
    return val;
}

export { getUnformattedString }