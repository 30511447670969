import { ServiceProvider } from "../Gateway.dtos";

const getServiceProvider = (providerStr: string | undefined): ServiceProvider => {
    if (providerStr === "Blue Cross Blue Shield")
        return ServiceProvider.BCBS;
    else if (providerStr === "United Health Care" || providerStr === "United Healthcare")
        return ServiceProvider.UHC;
    else
        return ServiceProvider.None;
}

const getServiceProviderString = (provider: ServiceProvider): string => {
    if (provider === ServiceProvider.BCBS)
        return "Blue Cross Blue Shield"
    else if (provider === ServiceProvider.UHC)
        return "United Healthcare"
    else
        return "";
}

export { getServiceProvider, getServiceProviderString };