import React, { useEffect } from 'react';
import { AppState } from '../../store/configureStore';
import { loginHelpCreators } from '../../store/LoginHelpStore';
import PersonalInformation from '../cards/loginHelp/PersonalInformation';
import LNMultiFactor from '../cards/multiFactor/LNMultiFactor';
import { RequestType } from '../../Gateway.dtos';
import UsernamePassword from '../cards/loginHelp/UsernamePassword';
import { WizardHeader } from '../WizardHeader';
import Email from '../cards/loginHelp/Email';
import { AppDispatch } from '../..';
import { useSelector, useDispatch } from 'react-redux';
import NeedHelpMultiFactor from '../cards/multiFactor/NeedHelpMultiFactor';

type LoginHelpAndRegistrationProps = {
    initialPageType: RequestType,
    reset: boolean
}

export const LoginHelpAndRegistration = ({
    initialPageType,
    reset
}: LoginHelpAndRegistrationProps): JSX.Element => {

    const dispatch: AppDispatch = useDispatch(); 
    const { isSuccess, stepNum, requestType } = useSelector((state: AppState) => state.loginHelp);

    //reset is passed as true in App.tsx, so anytime it is a "new" routing request, the entire page will be reset
    useEffect(() => {
        if (reset) {
            dispatch(loginHelpCreators.fetchLoginHelp(initialPageType));
        }
    }, [reset])    

    useEffect(() => {
        //Improper navigation using back button, go to login page
        if (isSuccess && stepNum === 1) {
            dispatch(loginHelpCreators.finishLoginHelp());
        }
    }, [isSuccess, stepNum]);

    const registrationSteps = ["Personal Information", "Multi-factor Authentication", "Confirm Email", "Username & Password"];
    const needHelpSteps = ["Personal Information", "Multi-factor Authentication", "Username & Password"];

    if (!isSuccess) {
        if (requestType === RequestType.REGISTRATION) {
            return <>
                <WizardHeader stepNames={registrationSteps} stepNum={stepNum} />
                {stepNum === 1 && <PersonalInformation />}
                {stepNum === 2 && <LNMultiFactor />}
                {stepNum === 3 && <Email />}
                {stepNum === 4 && <UsernamePassword />}
            </>
        } else if (requestType === RequestType.NEEDHELP) {
            return <>
                <WizardHeader stepNames={needHelpSteps} stepNum={stepNum} />
                {stepNum === 1 && <PersonalInformation />}
                {stepNum === 2 && <NeedHelpMultiFactor />}
                {stepNum === 3 && <UsernamePassword />}
            </>
        }
    }

    return <></>;
};