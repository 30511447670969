import React, { useEffect, useRef, useState } from 'react';
import { Card, Button, Row, Col, Alert } from 'react-bootstrap';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';
import { ServiceProvider } from '../../../Gateway.dtos';
import { format, parse } from 'date-fns';


type TAeBannerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const AeBanner = (props: TAeBannerProps, hasHSA: boolean) => {
    const { username, hasClaim_HealthAccess } = props;

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.BusinesSolver;
    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    const enrollmentEndDate = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.enrollmentEndDate ?? '12/31/2000')
    const enrollmentEndDateFormatted = format(parse(enrollmentEndDate, "MM/dd/yyyy", new Date()), "MMMM d")

    const handleSSO = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }
    type LinkProps = {
        children?: React.ReactNode
    }
    const BenefitSolverSsoLink: React.FC = ({ children }: LinkProps) =>
        <Button
            onClick={handleSSO}
            disabled={!hasClaim_HealthAccess}
            variant='link'
            className={`m-0 p-0 ${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
        >
            {children}
        </Button>

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return <Alert className="aeUtilityBar py-4" variant="warning">
        Annual Election is now open through {enrollmentEndDateFormatted}. <BenefitSolverSsoLink><strong> Begin your selection today.</strong></BenefitSolverSsoLink>
        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
        <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer="b" />
    </Alert>
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        username: state.account.username,
        hasHsa: state.hsa.hsa?.healthSavingsAccounts?.length > 0,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AeBanner);