import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { WizardProgressBarProps } from "../definitions/WizardProgressBarProps";
import "core-js/features/array/find-index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { GatewayPath, useWindowDimensions } from "@wespath/gateway-navigation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "..";
import { push } from "connected-react-router";
import { SidePanelWizardStepContent } from "./SidePanelWizardContent";
import Skeleton from "react-loading-skeleton";

interface Props {
    progressBar?: WizardProgressBarProps,
    wizardTitle: string,
}

interface StepProps {
    wizardTitle: string,
    wizardPath?: GatewayPath,
    displayText: string,
    target?: GatewayPath,
    isDisabled?: boolean,
    isActive?: boolean,
    isExpandable?: boolean,
    isChecked?: boolean
}

const SidePanelWizardProgress = function (props: Props): React.ReactElement {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions()
    const { progressBar } = props;    

    const handleClick = (path?: GatewayPath) => {
        if (path !== undefined)
            dispatch(push(path));
    }

    const Step = (props: StepProps) => {

        const isExpanded = !isSmallScreen && props.isActive
        const isLoading = progressBar?.menuOptions?.isLoading

        const cssStatus = props.isActive ? 'active' : props.isDisabled ? 'disabled' : '';
        const cssExpandable = props.isExpandable && isExpanded ? 'expanded' : '';  
        const faIcon = props.isExpandable && isExpanded ? faAngleDown : faAngleRight

        if (isLoading) {
            return (
                <div className={`p-0 m-0 sidePanelWidgetStep`}>
                    <Row className={`m-0 disabled`}>
                        <Col className="col-12 ml-0 pl-0 mt-1 pt-1">
                            <Skeleton />
                        </Col>
                    </Row>
                </div>
            )
        }

        return (
            <div className={`p-0 m-0 sidePanelWidgetStep ${cssStatus}`}>
                <Row
                    key={`${props.wizardPath}_row`}
                    className={`m-0 ${cssStatus} ${cssExpandable}`}
                    onClick={() => { if (!props.isDisabled && !props.isActive) handleClick(props.target) }}
                >
                    <Col className="col-11 m-0 p-0 my-auto stepName">

                        {props.isChecked && <FontAwesomeIcon icon={faCheck} className={`ret-color mr-2`} style={{fontWeight: 'normal'}}/>}

                        <span className="displayText">{props.displayText}</span>

                        {!isExpanded && props.wizardPath && <SidePanelWizardStepContent wizardTitle={props.wizardTitle} wizardPath={props.wizardPath} key={`${props.wizardPath}_collapsed`}/>}
                    
                    </Col>
                    <Col className={`col-1 m-0 p-0 my-auto ${!isExpanded ? 'pb-2' : ''}`}>
                        <FontAwesomeIcon icon={faIcon} className={`${props.isActive && !isSmallScreen ? 'ret-color' : ''}`} />
                    </Col>

                    {isExpanded && props.wizardPath && <Col className="col-12 m-0 p-0">
                        <SidePanelWizardStepContent wizardTitle={props.wizardTitle} wizardPath={props.wizardPath} isExpanded={isExpanded} key={`${props.wizardPath}_expanded`}/>
                    </Col>}
                </Row>
            </div>
        );
    }


    return (<div className="sidePanelWidget">

        {progressBar?.steps?.map((s, i) => {

            const isEnabled = progressBar?.menuOptions?.enabledMenuOptions?.find((o) => o === s.path) !== undefined
            const isHidden = progressBar?.menuOptions?.hiddenMenuOptions?.find((o) => o === s.path) !== undefined
            const isChecked = progressBar?.menuOptions?.checkedMenuOptions?.find((o) => o === s.path) !== undefined

            return (<React.Fragment key={i}>
                {!isHidden && <Step
                    key={`${s.path}_step`}
                    wizardTitle={props.wizardTitle}
                    wizardPath={s.path}
                    displayText={s.display}
                    target={s.path}
                    isDisabled={isEnabled ? false : (progressBar?.activeIndex ?? -1) < i}
                    isActive={progressBar?.activeIndex === i}
                    isExpandable={s.isExpandable}
                    isChecked={isChecked}
                />}
            </React.Fragment>)
        })}

    </div>)

};
export { SidePanelWizardProgress };