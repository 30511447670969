
const getOrdinalSuffixDate = (inputDate: string) : string => {

    const parsed = new Date(inputDate);

    const i = parsed.getDate();
    const day = parseInt(i.toString())
    let date = ''

    const j = day % 10,
        k = day % 100;

    if (j === 1 && k !== 11) {
        date = day + "st";
    }
    else if (j === 2 && k !== 12) {
        date = day + "nd";
    }
    else if (j === 3 && k !== 13) {
        date = day + "rd";
    }
    else {
        date = day + "th"
    }

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const Month = monthNames[parsed.getMonth()]
    const Year = parsed.getFullYear();
    const returnDate = Month + ' ' + date + ', ' + Year;

    return returnDate;
}

export { getOrdinalSuffixDate };