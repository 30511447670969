import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../store/configureStore';
import { AppDispatch } from '..';
import { errHandlingActionCreators } from '../store/ErrHandling';

export interface OwnProps { message?: string }
type TErrHandlingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ErrHandling = (props: TErrHandlingProps) => {
    const { message, logErr } = props;
    
    useEffect(() => {
        console.log(message);
        logErr(message);
    })

    return (
        <Row className="justify-content-center">
            <Col className="col-12 col-md-6 col-lg-5 col-xl-5">
                <Card className="errorCard">
                    <Card.Title>
                        Error
                    </Card.Title>
                    <Card.Body>
                        <p>
                            An unexpected error has occured.
                        </p>

                        <p className="text-muted small">
                            Code: c1
                        </p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...errHandlingActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrHandling);
