import React, { ReactElement } from 'react';
import { formatDate } from '../functions/formatDate';
import { GetBenefitProjectionResponse } from '../Gateway.dtos';
import { FormatDollar } from './FormatDollar';

const BenefitProjectionFooter = (props: { benefitProjection: GetBenefitProjectionResponse, children?: unknown }): ReactElement => (
    <p className="footnote amountPanelSmall beneProjFooter">
        Your current estimated monthly income is <FormatDollar amount={props.benefitProjection.estAmt} /> per month. This amount is an
        estimated income replacement of {props.benefitProjection.estPct}%. The amount is calculated as
        of {formatDate(props.benefitProjection.asOfDate)} with <a href="API/Redirect/https%3A%2F%2Fwww.wespath.org%2Fassets%2F1%2F7%2F4258.PDF/l" target="_blank" rel="noopener noreferrer">assumptions</a> including
        inflation and estimated Social Security benefits, if applicable. {props.children}
    </p>
);
export { BenefitProjectionFooter };