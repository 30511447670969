import React, { useEffect } from 'react';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import { accountActionCreators } from '../../store/AccountStore';
import AuthMultiFactorPhone from '../cards/multiFactor/AuthMultiFactorPhone';
import { CodeState } from '../../store/AuthMultiFactorStore';
import AuthMultiFactorEnterCode from '../cards/multiFactor/AuthMultiFactorEnterCode';
import { AppDispatch } from '../..';

type TMultiFactorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const TwoFactorChallenge = (props: TMultiFactorProps) => {
    const { accessDenied, redirectToAccessDenied, codeState } = props;


     useEffect(() => {
         if (redirectToAccessDenied) {
             accessDenied()
         }
     }, [redirectToAccessDenied, accessDenied]);


    const colClassName = "col-12 col-lg-10 col-xl-9";
    return (
        <Row className={`justify-content-center mt-5`}>
            <Col className={colClassName}>
                <Card className="fullPageCard">
                    <Card.Title>
                        Multi-Factor Authentication
                    </Card.Title>

                    <Card.Body>
                        
                        {codeState === CodeState.Sent
                            || codeState === CodeState.Resending
                            || codeState === CodeState.Validating
                            || codeState === CodeState.Expired
                            || codeState === CodeState.Invalid
                            ? <AuthMultiFactorEnterCode />
                            : <AuthMultiFactorPhone />
                        }

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function mapStateToProps(state: AppState) {
    const { accessDenied, codeState } = state.authMultiFactor
    return {
        page: state.router.location.pathname,
        redirectToAccessDenied: accessDenied,
        codeState
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { accessDenied } = accountActionCreators;

    return bindActionCreators({
        accessDenied
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TwoFactorChallenge);