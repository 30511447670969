import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { Button, Card, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser } from '@fortawesome/pro-solid-svg-icons';
import { usernameActionCreators } from '../../../store/accountManagement/UsernameStore';
import { Requirement } from '../../Requirement';
import { removeInvalidChars_Username } from '../../../functions/removeInvalidChars';
import { AppDispatch } from '../../..';
import { Username } from '../../../definitions/Claims';
import { activityActionCreators } from '../../../store/ActivityStore';


type TUsernameCardProps = ReturnType<typeof mapStateToProps>;

const UsernameCard = (props: TUsernameCardProps) => {
    const dispatch: AppDispatch = useDispatch();    

    const { isLoading, isEditing, error } = props;

    const [username, setUsername] = useState('');
    
    const { showSuccessMsg } = props;
    useEffect(() => {
        if (showSuccessMsg)
            setTimeout(() => { dispatch(usernameActionCreators.hideSuccessMsg()) }, 3000);
    }, [showSuccessMsg, dispatch]);

    useEffect(() => {
        if (isEditing) {
            dispatch(usernameActionCreators.checkDuplicateUsername(username));
        }
    }, [username, dispatch, isEditing]);

    useEffect(() => {
        setUsername('');
    }, [isEditing]);


    const submit = () => {
        if (!props.duplicateUsernameIsLoading) {
            dispatch(activityActionCreators.logActivity("button_username_update"));
            dispatch(usernameActionCreators.updateUsername(username));
        }
    }

    const enableSubmitBtn = () => {
        return usernameLengthIsOk() && usernameNotInUse();
    }


    const usernameLengthIsOk = () => { return username.length >= 5 && username.length <= 20; }
    const usernameNotInUse = () => { return props.isNotDuplicateUsername; }

    const UsernameRequirements = () => (
        <Col className="requirements cardStyle mt-md-4">
            <div className="mb-3">Your username must:</div>
            <Requirement isOk={usernameLengthIsOk()}>Be between 5 and 20 characters</Requirement>
            <Requirement isOk={usernameNotInUse()}>Have never been used before</Requirement>
        </Col>
     );

    const Buttons = () => {
        return (<>
            <Button className="mt-3" variant="primary" type="submit" tabIndex={2} disabled={!enableSubmitBtn() || isLoading || error}>
                {isLoading ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> : "Change Username"}
            </Button>

            <Button className="mt-3 ml-md-4" variant="link" tabIndex={3} hidden={isLoading}
                onClick={
                    () => {
                        dispatch(activityActionCreators.logActivity("button_username_cancel"));
                        dispatch(usernameActionCreators.setIsEditing({ isEditing: false }));
                    }
                }>
                Cancel
            </Button>
        </>);
    }

    if (!props.hasClaim_View) {
        return <></>;
    }

    return (
        <Card className="pageCard secondary">
            <Card.Title><FontAwesomeIcon icon={faUser} className="acctmgmt-color" />Username</Card.Title>
            <Card.Body>

                {props.error &&
                    <Alert className="mt-3" variant="danger">Unable to update Username</Alert>
                }

                {props.showSuccessMsg && <Alert className="mt-3 fade-out" variant="success">
                    <FontAwesomeIcon icon={faCheck} /><strong>Success.</strong> Your Username has been updated.
                </Alert>}

                <p>
                    Your username is <strong>{props.profileName ? props.profileUsername?.toLowerCase() : props.username?.toLowerCase()}</strong>
                </p>

                {isEditing &&
                    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        submit();
                    }}>

                        <Row>
                            <Col className="col-12 col-md-6">
                                <Form.Group>
                                    <Form.Label>Select a Username</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            value={username}
                                            disabled={isLoading}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setUsername(removeInvalidChars_Username(e.target.value)) }}
                                            maxLength={20}
                                            tabIndex={2}
                                        />
                                    </div>

                                </Form.Group>

                            </Col>

                            <UsernameRequirements />

                        </Row>

                        <Buttons />

                    </Form>
                }

                {!isEditing && props.hasClaim_Update &&
                    <Button variant="primary"
                        disabled={isLoading || error}
                        onClick={
                            () => {
                                dispatch(activityActionCreators.logActivity("button_username_change"));
                                dispatch(usernameActionCreators.beginEditing());
                            }
                    }>
                    {isLoading ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : "Change"}
                </Button>}
            </Card.Body>
        </Card>

    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.username,
        username: state.account.username,
        profileUsername: state.account.profileUsername,
        profileName: state.account.profileName,
        hasClaim_View: state.claims.claims?.claims?.find(c => c === Username.View),
        hasClaim_Update: state.claims.claims?.claims?.find(c => c === Username.Update)
    }
}

export default connect(
    mapStateToProps
)(UsernameCard);
