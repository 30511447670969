import React from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FormatDollar } from '../../../FormatDollar';
import { LsriSocSecStatus, OracleSocSec } from '../../../../Gateway.dtos';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriSocialSecuritySidePanel = (props: TLifestageRIProps) => {
    const { lsri } = props

    const ssElection = lsri?.lsriInfo?.oracleLsriElection?.socialSecurity ?? {} as OracleSocSec
    const status = ssElection.ss_Status
    const estAmt = ssElection.ss_Amt

    if (status === undefined || status === LsriSocSecStatus.Ineligible || !lsri?.lsriInfo?.isEligible)
        return null

    return (<h5>

        <FontAwesomeIcon icon={faCheck} />&nbsp;

        {status === LsriSocSecStatus.NoInfo && "Not provided yet"}

        {status === LsriSocSecStatus.OptOut && "Opted out"}

        {status === LsriSocSecStatus.Receiving && "Already receiving"}

        {status === LsriSocSecStatus.Planning && <FormatDollar amount={estAmt} noCents />}

    </h5>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriSocialSecuritySidePanel);

