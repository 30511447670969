import { faMoneyCheckAlt, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Button, Card } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppState } from '../../../store/configureStore';
import { hsaActionCreators } from '../../../store/HealthSavingsAccountStore';
import { AmountPanel } from '../../AmountPanel';
import { useState } from 'react';
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { AppDispatch } from '../../..';
import { AmountPanelSmall } from '../../AmountPanelSmall';
import { Health } from '../../../definitions/Claims';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'
import { ServiceProvider } from '../../../Gateway.dtos';

export interface OwnProps { isSummary?: boolean }
type THealthAndReimbursementProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const HealthAndReimbursement = (props: THealthAndReimbursementProps) => {
    const { hsa, isLoading, username, hasClaim_HealthAccess } = props;

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.WageWorks;

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchHsa();
        }
    });

    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }

    const numOfAccounts = hsa?.healthSavingsAccounts?.length;


    if (isLoading) {
        return props.isSummary ? <AmountPanelSmall label="" isLoading /> : <></>;
    }
    else if (numOfAccounts <= 0) {
        return <></>;
    }
    else if (props.isSummary) {

        return (<>
            {hsa.healthSavingsAccounts?.map((item, i) => (
                <AmountPanelSmall
                    label={`${item.displayName} Annual Contribution`}
                    balance={item.amount}
                    key={i}
                    noBottomBorder={true}
                />
            ))}
            <hr className="mt-0"/>
        </>);

    }
    else {

        return (<>
            <Card className="pageCard">
                <Card.Title>
                    <FontAwesomeIcon icon={faMoneyCheckAlt} className="health-color smaller" />
                    Health & Reimbursement <span className="text-nowrap">Accounts
                    <FontAwesomeIcon icon={faCheck} className="dtlCheckMark" /></span>
                </Card.Title>
                <Card.Body>
                    <p className="mb-3 thin">
                        Accounts to help you set aside funds with tax advantages to pay for your health and family care costs.
                    </p>

                    {hsa.healthSavingsAccounts?.map((account, i) => (
                        <AmountPanel label={`${account.displayName} Annual Contribution`}
                            balance={account.amount}
                            key={i}
                            noBottomBorder={true}
                        />
                    ))}

                    <Button variant="secondary"
                        onClick={handleSubmit}
                        className={`mt-4 ${!props.hasClaim_HealthAccess ? "claimControlled" : ""}`}
                        disabled={!hasClaim_HealthAccess}
                    >
                        Health & Reimbursement Accounts
                    </Button>
                </Card.Body>
            </Card>
            <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
            <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer="b" />
        </>);
    }
}
function mapStateToProps(state: AppState, ownProps: OwnProps) {
    const { username } = state.account;
    return {
        ...state.hsa,
        ...ownProps,
        username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...hsaActionCreators}, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthAndReimbursement);
