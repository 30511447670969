import React, { useEffect } from 'react';
import { Button, Card } from "react-bootstrap";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppState } from '../../../store/configureStore';
import { AmountPanel } from '../../AmountPanel';
import { AppDispatch } from '../../..';
import { medicareActionCreators } from '../../../store/MedicareStore';
import { faMoneyCheckAlt, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AmountPanelSmall } from '../../AmountPanelSmall';
import { cmsActionCreators } from '../../../store/CmsStore';
import { activityActionCreators } from '../../../store/ActivityStore';

export interface OwnProps { isSummary?: boolean }
type TMedicareProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Medicare = (props: TMedicareProps) => {
    const { medicare } = props;
    const dispatch: AppDispatch = useDispatch(); 
    const cmsData = props.cmsData.fragments?.find(f => f.name === "MedicareSupplementalMessage");
    const cmsHRAData = props.cmsData.fragments?.find(f => f.name === "HRAAnnualContributionMessage");

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchMedicare();
        }
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }            
    });    

    const showCard = medicare?.isEnrolled_ViaBen1 || medicare?.isEnrolled_ViaBen2;

    const hasVbHra1 = medicare?.isEnrolled_ViaBen1 && medicare?.amt_ViaBen1 > 0;
    const hasVbHra2 = medicare?.isEnrolled_ViaBen2 && medicare?.amt_ViaBen2 > 0;

    const SharedTextTop = () => {
        return (
            cmsData ? <p className="mb-3 thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null
        );
    }

    const SharedTextBottom = () => (<>
        {(hasVbHra1 || hasVbHra2) &&
            cmsHRAData ? <p className="my-3 thin" dangerouslySetInnerHTML={{ __html: cmsHRAData.html }} /> : null
        }
    </>);


    if (!showCard) {
        return <></>;
    }
    else if (props.isSummary) {

        return (<>
            <SharedTextTop />

            {hasVbHra1 && <AmountPanelSmall
                label={`HRA Annual Contribution${hasVbHra2 ? " (1)" : ""}`}
                balance={props.medicare.amt_ViaBen1}
                noBottomBorder={true}
            />}

            {hasVbHra2 && <AmountPanelSmall
                label={`HRA Annual Contribution${hasVbHra1 ? " (2)" : ""}`}
                balance={props.medicare.amt_ViaBen2}
                noBottomBorder={true}
            />}

            <SharedTextBottom />
        </>);

    }
    else {

        return (
            <Card className="pageCard">
                <Card.Title>
                    <FontAwesomeIcon icon={faMoneyCheckAlt} className="health-color smaller" />
                    Medicare Supplemental <span className="text-nowrap">Coverage
                    <FontAwesomeIcon icon={faCheck} className="dtlCheckMark" /></span>
                </Card.Title>
                <Card.Body>

                    <SharedTextTop />

                    {hasVbHra1 && <AmountPanel
                        label={`HRA Annual Contribution${hasVbHra2 ? " (1)" : ""}`}
                        balance={props.medicare.amt_ViaBen1}
                        noBottomBorder={true}
                    />}

                    {hasVbHra2 && <AmountPanel
                        label={`HRA Annual Contribution${hasVbHra1 ? " (2)" : ""}`}
                        balance={props.medicare.amt_ViaBen2}
                        noBottomBorder={true}
                    />}

                    <SharedTextBottom />

                    <a href="https://my.viabenefits.com/wespath" target="_blank" rel="noopener noreferrer" onClick={() => dispatch(activityActionCreators.logActivity("button_viabenefits.com"))}>
                        <Button variant="secondary" className="mt-3">Go to Via Benefits</Button>
                    </a>

                </Card.Body>
            </Card>        
        );
    }
}
function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...state.medicare,
        ...ownProps,
        cmsData: state.cms.health
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...medicareActionCreators,
        fetchCmsData: cmsActionCreators.fetchHealthCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Medicare);
