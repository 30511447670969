import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Alert, Spinner} from 'react-bootstrap';
import { formatPhone } from '../../../functions/formatPhone';
import { Link } from 'react-router-dom';
import { lnMultiFactorActionCreators } from '../../../store/LNMultiFactorStore';
import { activityActionCreators } from '../../../store/ActivityStore';
import ScrollToTop from '../../ScrollToTop';
import { AppDispatch } from '../../..';

type TMultiFactorEnterCode = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LNMultiFactorEnterCode = (props: TMultiFactorEnterCode) => {
    
    const [code, setCode] = useState('');
    const [codeSentAmt, setCodeSentAmt] = useState(1);
    const [showSendAnotherCode, setShowSendAnotherCode] = useState(true);

    const { logActivity, totalCodesSent } = props;

    useEffect(() => {
        logActivity("MultiFactorEnterCode");
    }, [logActivity])


    useEffect(() => {
        setShowSendAnotherCode(codeSentAmt <= 2 && totalCodesSent < 4);
    }, [setShowSendAnotherCode, codeSentAmt, totalCodesSent])

    const submit = () => {
        props.validateCode(code);
    }

    const sendAnotherCode = () => {

        setCode('');
        setCodeSentAmt(codeSentAmt + 1);        

        props.fetchCode(props.participant.phoneNumber, props.participant.otpDeliveryInfo);
    }

    const lastAttempt = () => {
        return props.attempts === 3;
    }

    const expired = () => {
        return props.multiFactor?.responseStatus?.message === "expired";
    }

    return (
        <>
            <ScrollToTop />

            <p>
                We sent a one-time security code to {formatPhone(props.participant.phoneNumber, 'US')}. You can choose to send this code to
                a <Link to="#" onClick={props.selectNewPhone}>different phone number</Link> from our records.
            </p>


            {!props.isLoading && props.multiFactor?.responseStatus &&
                <Alert className="my-4" variant={expired() ? "warning" : "danger"}> 
                    {expired() && <>The security code you entered has expired. Codes are valid for 10 minutes. To try again we can <Link to="#" tabIndex={4} onClick={sendAnotherCode}>send another code</Link> to your phone.</>}
                    {!expired() && lastAttempt() && "The code you have entered is invalid. You have one more attempt before the account is locked."}
                    {!expired() && !lastAttempt() && "The code you have entered is invalid. Please try again or send another code to your phone."}
                </Alert>
            }

            
            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                submit();
            }}>

                {
                    //TODO: fix eslint disable
                    // eslint-disable-next-line
                    false ? <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                /> : <>
                    <Row>
                        <Col className="col-12 col-sm-6 col-md-5 col-lg-4">
                            <Form.Group controlId="code">
                                <Form.Label>Enter Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={code}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCode(e.target.value) }}
                                    maxLength={20}
                                    tabIndex={1}
                                    autoFocus
                                />
                                    <div className="footnote">Codes are only valid for 10 minutes.</div>
                            </Form.Group>
                        </Col>
                        <Col className="col-12 col-sm-6 col-md-7 mt-sm-4 pt-sm-0 pt-md-3">
                            {!props.isLoading && showSendAnotherCode && <>
                                Did not receive this code? <Link to="#" tabIndex={3} onClick={sendAnotherCode}>Send another</Link>.
                            </>}
                        </Col>
                    </Row>

                    <Row>
                        <Col className="form-group">
                            <Button className="mt-4" variant="primary" type="submit" tabIndex={2} disabled={!code || props.isLoading}>
                                {props.isLoading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : "Next"}
                            </Button>
                        </Col>
                    </Row>
                </>}

            </Form>

        </>
    );
}

function mapStateToProps(state: AppState) {

    return {
        ...state.lnMultiFactor,
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...lnMultiFactorActionCreators, ...activityActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LNMultiFactorEnterCode);
