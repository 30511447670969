import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AppState } from '../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { stateNeedsLoading } from '../functions/stateNeedsLoading';
import { AppDispatch } from '..';
import { applicationTimeoutActionCreators } from '../store/ApplicationTimeoutStore';
import { accountActionCreators } from '../store/AccountStore';
import { activityActionCreators } from '../store/ActivityStore';
import { client } from '../App';
import { KeepSessionAlive } from '../Gateway.dtos';

function convertToMilliseconds(minutes: number) : number
{
    return minutes * 60 * 1000;
}

type TSessionTimeoutModalProps = ReturnType<typeof mapStateToProps>;

const SessionTimeoutModal = function (props: TSessionTimeoutModalProps) {
    const [isShowing, setIsShowing] = useState(false);
    const [isLogoff, setIsLogoff] = useState(false);
    const { logoffMinutes, logoffWarningMinutes, loadState, pathname } = props;
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            dispatch(applicationTimeoutActionCreators.fetchApplicationTimeout());
        }
    })


    useEffect(() => {
        let showTimer: NodeJS.Timeout;
        let logoffTimer: NodeJS.Timeout;

        if (isLogoff)
            dispatch(accountActionCreators.logout());

        if (logoffWarningMinutes && !isShowing)
            showTimer = setTimeout(() => { dispatch(activityActionCreators.logActivity("timeout_showWarning")); setIsShowing(true); }, convertToMilliseconds(logoffWarningMinutes));

        if (logoffMinutes && !isLogoff)
            logoffTimer = setTimeout(() => { dispatch(activityActionCreators.logActivity("timeout_automaticLogoff")); setIsLogoff(true); }, convertToMilliseconds(isShowing ? logoffMinutes - logoffWarningMinutes : logoffMinutes));
        
        
        return () => {
            clearTimeout(showTimer);
            clearTimeout(logoffTimer);
        }
    }, [isShowing, isLogoff, loadState, pathname, logoffMinutes, logoffWarningMinutes, dispatch])

    if (stateNeedsLoading(props)) {
        return null;
    }

    return (
        <Modal show={isShowing} backdrop="static" centered >
            <Modal.Header>
                <Modal.Title>Session about to expire</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left">
                Your online session will expire in approximately one minute.Click &quot;Keep me logged in&quot; to continue your online session or click &quot;Log out now&quot; to end your session now.
            </Modal.Body>
            <Modal.Footer className="justify-content-start">
                <Button className="mr-3" variant="primary" onClick={() => {
                    setIsShowing(false);
                    dispatch(activityActionCreators.logActivity("timeout_button_keepMeLoggedIn"));
                    client.get(new KeepSessionAlive({}))
                }}>Keep me logged in</Button>
                <Button variant="link" onClick={() => { dispatch(activityActionCreators.logActivity("timeout_button_logOutNow")); dispatch(accountActionCreators.logout()); }}>Log out now</Button>
            </Modal.Footer>
        </Modal>
    );
};

function mapStateToProps(state: AppState) {
    const { pathname } = state.router.location;
    return {
        ...state.applicationTimeout,
        pathname
    }
}

export default connect(
    mapStateToProps
)(SessionTimeoutModal);