export enum Beneficiaries {
    View = "Beneficiaries.View"
}

export enum Contact {
    Update = "Contact.Update"
}

export enum DeliveryPreferences {
    Update = "DeliveryPreferences.Update",
    View = "DeliveryPreferences.View"
}

export enum EY {
    Search = "EY.Search",
    Update = "EY.Update",
    View = "EY.View",
}

export enum Health {
    Access = "Health.Access",
    View = "Health.View"
}

export enum Password {
    Update = "Password.Update",
    View = "Password.View"
}

export enum Pin {
    Update = "Pin.Update",
    View = "Pin.View"
}

export enum Summary {
    LockedBanner = "Summary.LockedBanner",
    Unlock = "Summary.Unlock"
}

export enum UpdateBeneficiaries {
    View = "UpdateBeneficiaries.View"
}

export enum Username {
    Update = "Username.Update",
    View = "Username.View"
}

export enum WellBeing {
    Access = "WellBeing.Access",
    View = "WellBeing.View"
}

export enum SocialSecurity {
    Update = "SocialSecurity.Update",
    View = "SocialSecurity.View"
}

export enum Lsri {
    Update = "Lsri.Update",
    View = "Lsri.View"
}

export enum TaxInfo {
    Update = "TaxInfo.Update",
    View = "TaxInfo.View"
}

export enum BankInfo {
    Update = "BankInfo.Update",
    View = "BankInfo.View"
}

export enum Distribution {
    Update = "Distribution.Update",
    View = "Distribution.View"
}

