import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import ReactSwitch from "react-switch";
import { useFetchDeveloperSettingsQuery, useUpdateSsoSetttingsMutation } from "../api/developerSettingsApi";
import { UpdateSsoSettings } from "../Gateway.dtos";
import { LabelAndContent } from "./LabelAndContent";


export const DeveloperSettings = () : JSX.Element => {
    const {
        data: developerSettingsData,
        isFetching: developerSettingsIsFetching
    } = useFetchDeveloperSettingsQuery()

    const [
        updateSsoSettings,
        {
            isLoading: updateSsoSettingsIsLoading
        }
    ] = useUpdateSsoSetttingsMutation()

    const ssoToLocalHost = developerSettingsData?.ssoToLocalHost || false;

    return (
        <Container className="mt-4" >
            <Row>
                <Col>
                    <h4>Developer Settings </h4>
                </Col>
            </Row>

            <Row>
                <Col className="pb-5" >
                    <LabelAndContent className="" label="Benefits Access SSO" >
                        <div className="pt-2" >
                            <ReactSwitch
                                checked={ssoToLocalHost}
                                uncheckedIcon={< div className="switchText left eyAuth" > OFF </div>
                                }
                                checkedIcon={< div className="switchText right eyAuth" > ON </div>}
                                disabled={updateSsoSettingsIsLoading || developerSettingsIsFetching}
                                activeBoxShadow='0 0 2px 3px #007bff'
                                width={80}
                                className={`react-switch pr-3 ${ssoToLocalHost ? '' : 'react-switch-off'}`}
                                id="icon-switch"
                                onChange={() => {
                                    const dto = new UpdateSsoSettings({
                                        ssoToLocalHost: !ssoToLocalHost
                                    });
                                    updateSsoSettings(dto);
                                }}
                            />
                            <span>Localhost</span>
                        </div>
                    </LabelAndContent>
                </Col>
            </Row>
        </Container>
    )
}