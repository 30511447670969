import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from 'redux';
import { PageTitle } from '../PageTitle';
import PendingActionsAlert from '../PendingActionsAlert';
import RetirementPlans from '../cards/retirement/RetirementPlans';
import BenefitProjection from '../cards/retirement/BenefitProjection';
import Investments from '../cards/retirement/Investments';
import Transactions from '../cards/retirement/Transactions';
import AssetAllocation from '../cards/retirement/AssetAllocation';
import EyRetirement from '../cards/retirement/EY';
import Contributions from '../cards/retirement/Contributions';
import { balanceActionCreators } from '../../store/BalanceStore';
import { retirementMenuActionCreators } from '../../store/RetirementMenuStore';
import BeneficiaryReviewModal from '../BeneficiaryReviewModal';
import { AppDispatch } from '../..';
import { EY } from '../../definitions/Claims';
import UnlockBanner from '../UnlockBanner';
import FetchData from '../FetchData';
import { GatewayPath } from '@wespath/gateway-navigation'
import { displayPageActionCreators } from '../../store/DisplayPageStore';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import SettingUpYourRetirementIncome from '../cards/retirement/SettingUpYourRetirementIncome';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import Skeleton from 'react-loading-skeleton';
import { EnrollmentBanner } from '../benefitSolverEnrollment/EnrollmentBanner';
import { showSummary } from '../../functions/showSummary';
import SummaryCarousel from '../SummaryCarousel';


type TBenefitSummaryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const RetirementAndInvestments = (props: TBenefitSummaryProps) => {
    const { balance, isCustSvc, retirementIsLoaded, goToPage, showRetirement, showSummary, showSettingUpRetirementIncome, settingUpRetirementIncomeLoaded } = props;
    const asOfDate = balance.balance?.asOfDate;
    const isLoading = !showRetirement && !retirementIsLoaded;
    const showCard = !isLoading && (!isCustSvc || showRetirement);
    const dispatch: AppDispatch = useDispatch();

    //Redirect to account management if no retirement data is avaialble for CPCS users
    useEffect(() => {
        if (isCustSvc && retirementIsLoaded && !showRetirement)
            goToPage(GatewayPath.AccountManagement);
    }, [goToPage, isCustSvc, retirementIsLoaded, showRetirement]);

    useEffect(() => {
        document.title = "Benefits Access - Retirement"
    }, []);


    const Title = () => (<>
        <PageTitle title="Retirement Details" color="ret-color-bg" />
        <Card.Title id="cardAsOfDate">Data as of {asOfDate}</Card.Title>
    </>);

    const DesktopView = () => (
        <Card className="detailCard desktop">
            <Title />
            <Card.Body>
                <Row>
                    <Col className="col-12 col-lg-6">
                        <RetirementPlans />
                        <Transactions />
                        {
                            !settingUpRetirementIncomeLoaded ? <LoadingSkeleton /> :
                                showSettingUpRetirementIncome
                                    ? <SettingUpYourRetirementIncome />
                                    : <BenefitProjection />
                        }

                        <Contributions />
                    </Col>

                    <Col className="col-12 col-lg-6">
                        <Investments />
                        <AssetAllocation />
                        <EyRetirement />
                    </Col>
                </Row>

            </Card.Body>
        </Card>
    );

    const MobileView = () => (
        <div className="detailCard mobile">
            <Card>
                <Title />
                <Card.Body>
                    <RetirementPlans />
                </Card.Body>
            </Card>

            <Investments />
            <Transactions />
            <BenefitProjection />
            <Contributions />
            <AssetAllocation />
            <EyRetirement />
        </div>
    );

    return (<>

        <FetchData />

        <PendingActionsAlert />

        <BeneficiaryReviewModal />

        <UnlockBanner />

        {/*Add banner for ppts who do not get the summary page*/}
        {!showSummary && <EnrollmentBanner>
            <SummaryCarousel />
        </EnrollmentBanner>}

        {isLoading && <LoadingSkeletonFullpage isCard />}

        {showCard && <>
            <DesktopView />

            <MobileView />
        </>}

    </>)
};

function mapStateToProps(state: AppState) {
    const retirementIsLoaded = state.displayPage.showRetirement ||
        (state.balance.isLoaded && state.investments.isLoaded && state.benefitProjection.isLoaded &&
            state.monthlyBenefit.isLoaded && state.settingUpRetirementIncome.loadState === DataLoadState.Loaded);

    return {
        balance: state.balance,
        retirementIsLoaded,
        isCustSvc: state.account.profileName && !state.claims.claims?.claims?.find(c => c === EY.Search),
        showRetirement: state.displayPage.showRetirement,
        showSummary: showSummary(state.displayPage),
        showSettingUpRetirementIncome: state.settingUpRetirementIncome?.settingUpRetirementIncome?.showSettingUpRetirementIncome,
        settingUpRetirementIncomeLoaded: state.settingUpRetirementIncome.loadState === DataLoadState.Loaded
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { goToPage } = displayPageActionCreators;
    return bindActionCreators({ ...balanceActionCreators, ...retirementMenuActionCreators, goToPage }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RetirementAndInvestments);

const LoadingSkeleton = () => (
    <div>
        <div><Skeleton width={'8em'} height={'1em'} /></div>
        <div><Skeleton width={'10em'} height={'1.5em'} /></div>
    </div>
);


