import React, { useState, useEffect } from 'react';
import { baCmsActionCreators } from '../../store/BACmsStore';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { AppState } from '../../store/configureStore';
import { AppDispatch } from '../..';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { CmsFragment, GetCmsFragment } from '../../Gateway.dtos';
import { CmsRoutePaths, CmsRoutes } from '../../definitions/CmsRoutes';
import { Card, Row, Col } from 'react-bootstrap';
import { cmsActionCreators } from '../../store/CmsStore';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { BAConvertedCmsRoutePaths } from '../../definitions/BAConvertedRoutes';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export interface OwnProps { isRightMarginContent?: boolean }
type TBaCmsPageProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const BaCmsPage = (props: TBaCmsPageProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { pathname, investmentsSummary } = props;
    const [cmsItemData, setCmsItemData] = useState("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isPageDisplayed, setIsPageDisplayed] = useState(false);
    const [previousPathName, setPreviousPathName] = useState("");
    const [previousLocation, setPreviousLocation] = useState("");


    const wespathContactAnchor = CmsRoutes.HelpWespath.replace('/', '') + "#"; //"wespathContacts#"
    const apiPath = process.env.REACT_APP_API_URL;

    let cmsItemPath: string | undefined = "";

    cmsItemPath = props.isRightMarginContent ? BAConvertedCmsRoutePaths.find(x => x.route === pathname)?.cmsPath : CmsRoutePaths.find(x => x.route === pathname)?.cmsPath;

    useEffect(() => {
        if (!props.isRightMarginContent && stateNeedsLoading(props.pageTitleStateCmsData)) {
            props.fetchCmsPageTitleData();
        }
    });

    const setPageTile = () => {
        if (!props.isRightMarginContent && cmsItemPath && cmsItemPath !== "") {
            const framgmentName = cmsItemPath.replace(/\//g, '');
            const cmsData = props.pageTitleStateCmsData.fragments?.find(f => f.name === framgmentName);
            document.title = cmsData?.html ? cmsData?.html : 'No Title';
        }
    }


    function updateAPIURL(fragment: CmsFragment) {
        if (fragment && fragment.html) {
            let cmsHTML = fragment.html;
            if (apiPath !== "/API") {
                cmsHTML = cmsHTML.replace(/API\//g, (apiPath + "/"));
            }
            else if (apiPath === "/API") {
                const pageURL = window.location.protocol + "//" + window.location.hostname + "/API/";
                cmsHTML = cmsHTML.replace(/API\//g, pageURL);
            }
            return cmsHTML;
        }
        return "";
    }

    useEffect(() => {
        if (pathname !== previousPathName || !isCurrentItemLoaded()) {
            setPreviousLocation(window.location.href);
            setIsPageDisplayed(false);
            setPreviousPathName(pathname);
            setIsLoaded(false);
            let isItemStateIsLoaded = false;
            let isItemStateIsLoading = false;
            if (props.baCmsData?.cmsFragmentResponses && props.baCmsData?.cmsFragmentResponses.length > 0) {
                const item = props.baCmsData?.cmsFragmentResponses?.find((x: { itemPath: string | undefined; }) => x.itemPath === cmsItemPath);
                isItemStateIsLoaded = item?.loadStateItem === 'loaded';
                isItemStateIsLoading = item?.loadStateItem === 'loading';
                if (isItemStateIsLoaded && item) {
                    setIsLoaded(true);
                    setCmsItemData(updateAPIURL(item.fragment));
                    setPageTile();
                }
            }

            if (!isItemStateIsLoaded && !isItemStateIsLoading) {
                dispatch(baCmsActionCreators.fetchCmsFragment({ path: cmsItemPath } as GetCmsFragment))
                    .then(() => {
                        if (props.baCmsData?.cmsFragmentResponses && props.baCmsData?.cmsFragmentResponses.length > 0) {
                            const item = props.baCmsData?.cmsFragmentResponses?.find((x: { itemPath: string | undefined; }) => x.itemPath === cmsItemPath);
                            if (item) {
                                setCmsItemData(updateAPIURL(item.fragment));
                                setIsLoaded(true);
                            }
                        }
                        setPageTile();
                    })
            }
        }

        //This code is specifically to handle the Help - Contact submenu clicks with anchor 
        if (window.location.href.includes(wespathContactAnchor) && (!isPageDisplayed || window.location.href !== previousLocation)) {
            setPreviousLocation(window.location.href);
            if (isCurrentItemLoaded()) {
                setIsPageDisplayed(true);
            }
            updatePage();
        }

        //This one is to handle the anchor for first time page load by fetching the details
        if (!window.location.href.includes(wespathContactAnchor) &&
            (!isPageDisplayed || window.location.href !== previousLocation) &&
            window.location.href.includes("#")) {
            setPreviousLocation(window.location.href);
            if (isCurrentItemLoaded()) {
                setIsPageDisplayed(true);
            }
            updatePage();
        }

    });

    function isCurrentItemLoaded() {
        if (props.baCmsData?.cmsFragmentResponses && props.baCmsData?.cmsFragmentResponses.length > 0) {
            const item = props.baCmsData?.cmsFragmentResponses?.find((x: { itemPath: string | undefined; }) => x.itemPath === cmsItemPath);
            if (item && cmsItemData !== "" && cmsItemData === updateAPIURL(item.fragment)) {
                return true;
            }
        }
        return false;
    }
    function updatePage() {
        const currentLocation = window.location.href;
        window.location.href = currentLocation;
    }


    function CmsCard (className: string) {
        return (
            <Card className={className}>
                <Card.Body>
                    <Row>
                        <Col className="col-12">
                            <span dangerouslySetInnerHTML={{ __html: cmsItemData }} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    function LoadInvestmentSummaryRightContents() {
        let cmsInvestmentItemData = cmsItemData;
        /* eslint-disable react/prop-types */
        if (!investmentsSummary?.investmentsSummary?.dcBalance || !investmentsSummary?.investmentsSummary?.mppBalance) {
            if (!investmentsSummary?.investmentsSummary?.dcBalance) {
                cmsInvestmentItemData = cmsItemData.replace("$hide_dc$", "hide");
                cmsInvestmentItemData = cmsInvestmentItemData.replace("$hide_mpp_dc$", "hide");
            }
            else if (!investmentsSummary?.investmentsSummary?.mppBalance) {
                cmsInvestmentItemData = cmsItemData.replace("$hide_mpp_dc$", "hide");
            }
        }
        return (
            <span dangerouslySetInnerHTML={{ __html: cmsInvestmentItemData }} />
        );
    }

    function LoadContents(isRightMarginContent: boolean | undefined) {
        return (
            isRightMarginContent
                ? <Col xs={12} xl={3} className="py-3">
                    {cmsItemPath?.includes("InvestmentsSummary")
                        ? LoadInvestmentSummaryRightContents()
                        : <span dangerouslySetInnerHTML={{ __html: cmsItemData }} />
                    }                    
                </Col>
                : <>
                    {CmsCard("detailCard desktop")}
                    {CmsCard("detailCard mobile")}
                </>
        );
    }

    function DisplayLoading(isRightMarginContent: boolean | undefined) {
        return (
            isRightMarginContent
                ? <Col xs={12} xl={3} className="py-3"><SidePanelLoading /></Col>
                : <LoadingSkeletonFullpage isBaCmsPage />
        );
    }

    return (isLoaded 
        ? <>
            {(cmsItemData && cmsItemData !== "") 
                ? LoadContents(props.isRightMarginContent)
                : "cmsItemData Not Found...."}
          </>
        : DisplayLoading(props.isRightMarginContent));
};

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    const { pathname } = state.router.location;
    return {
        ...ownProps,
        baCmsData: state.baCms,
        pageTitleStateCmsData: state.cms.pageTitleState,
        pathname,
        investmentsSummary: state.investmentsSummary
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...baCmsActionCreators,
        fetchCmsPageTitleData: cmsActionCreators.fetchCmsPageTitleData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BaCmsPage);



const SidePanelLoading = () => {
    return (
        <span>
            <div className="right-margin">
                <SkeletonTheme color="#D8D8D8" highlightColor="#E8E8E8">
                    <div className="margin-menu-title">
                        <Skeleton />
                    </div>
                    <ul>
                        <li className="mt-3"><Skeleton count={5} /></li>
                        <li className="mt-3"><Skeleton count={2} /></li>
                        <li className="mt-3"><Skeleton count={2} /></li>
                        <li className="mt-3"><Skeleton count={4} /></li>
                    </ul>
                </SkeletonTheme>
            </div>
        </span>
    ) 
}