import React, { useEffect } from 'react';
import { AppState } from '../store/configureStore';
import { bindActionCreators } from 'redux';
import { accountActionCreators } from '../store/AccountStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '..';
import GatewayNavigation, { GatewayPath } from '@wespath/gateway-navigation';
import { PlanElection } from '../Gateway.dtos';
import { CmsRoutePaths } from '../definitions/CmsRoutes';
import { retirementMenuActionCreators } from '../store/RetirementMenuStore';
import { BAConvertedCmsRoutePaths } from '../definitions/BAConvertedRoutes';


type THeaderUserInfoProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Header = (props: THeaderUserInfoProps) => {
    const dispatch: AppDispatch = useDispatch();

    const isCMPath = () => {
        const cmsRoute = CmsRoutePaths.find(x => x.route === props.pathname);
        if (cmsRoute) {
            return true;
        }
        return false;
    }

    const isLsriPath = () => {        
        return props.pathname.includes("/LSRI/");
    }

    const isBAConvertedPagePath = () => {
        const baRoute = BAConvertedCmsRoutePaths.find(x => x.route === props.pathname);
        if (baRoute) {
            return true;
        }
        return false;
    }

    const showRetirementHeaderMenu = () => {
        if (props.pathname === GatewayPath.ManageDirectDeposit
            || props.pathname === GatewayPath.UpdateBankInformation
            || props.pathname === GatewayPath.PaymentInfo
           )
            return true;
        else
            return false;
    }

    useEffect(() => {
        if (isCMPath() || isLsriPath() || isBAConvertedPagePath() || showRetirementHeaderMenu()) {
            if (!props.retirementMenu.headerMenu || !props.retirementMenu.headerMenu.menus || props.retirementMenu.headerMenu.menus.length > 0)
                dispatch(retirementMenuActionCreators.fetchRetirementMenu());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    return (<>
        <GatewayNavigation
            gateway={{
                isGateway: true,
                rootUrl: "/",
                apiRootUrl: process.env.REACT_APP_API_URL,
                claims: props.claims,
                currentPath: (isCMPath() || isLsriPath() || isBAConvertedPagePath() || showRetirementHeaderMenu()) ? GatewayPath.Retirement : props.pathname,
                isChallenging: props.isChallenging,
                isAuthenticated: props.isAuthenticated,
                logout: props.logout
            }}
            userInfo={{
                username: props.username,
                name: props.name,
                firstName: props.firstName,
                profileName: props.profileName,
                profileFirstName: props.profileFirstName
            }}
            health={{
                showHealth: props.showHealth,
                hasHSA: props.hasHSA,
                planElections: props.planElections
            }}
            wellBeing={{
                showWellBeing: props.showWellBeing,
                hasMedical: props.hasMedical,
                hasVp: props.hasVp
            }}
            retirement={{
                showRetirement: props.showRetirement || isCMPath() || isLsriPath() || isBAConvertedPagePath() || showRetirementHeaderMenu(),
                headerMenu: props.retirementSubMenu,
                isLoaded: props.retirementIsLoaded!
            }}
            lifeAndDisability={{
                showLifeAndDisability: props.showLifeAndDisability
            }}
        />

    </>)
} 
    



function mapStateToProps(state: AppState) {
    const { name, firstName, isAuthenticated, profileName, profileFirstName, username } = state.account;
    const isChallenging = state.lnMultiFactor.isChallenging || state.authMultiFactor.isChallenging;
    const { pathname } = state.router.location;
    const retirementIsLoaded = state.displayPage.showRetirement || (state.balance.isLoaded && state.investments.isLoaded && state.benefitProjection.isLoaded && state.monthlyBenefit.isLoaded);
    const claims: string[] = state.claims.claims?.claims ?? []

    const planElections = state.healthPlans?.healthPlans?.planElections ?? [] as PlanElection[];

    const hasHSA = state.hsa.hsa?.healthSavingsAccounts?.length > 0;

    const hasMedical = state.healthPlans?.healthPlans?.hasMedical || false;
    const hasVp = state.wellBeing?.wellBeing?.vpIsQualified || false;

    return {
        claims,
        username,
        name,
        firstName,
        profileName,
        profileFirstName,
        isAuthenticated,
        isChallenging, 
        pathname,
        retirementSubMenu: state.retirementMenu.headerMenu,
        retirementMenu: state.retirementMenu,
        retirementIsLoaded,
        hasHSA,
        planElections,
        hasMedical,
        hasVp,
        ...state.displayPage
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators(accountActionCreators, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);