import * as React from "react";
import "core-js/features/array/find-index";
import LsriTextBelowSidePanel from "./pages/Lsri/SidePanelContent/LsriTextBelowSidePanel";
import LsriSocialSecuritySidePanel from "./pages/Lsri/SidePanelContent/LsriSocialSecuritySidePanel";
import { GatewayPath } from "@wespath/gateway-navigation";
import LsriMppSidePanel from "./pages/Lsri/SidePanelContent/LsriMppSidePanel";
import LsriFundingSidePanel from "./pages/Lsri/SidePanelContent/LsriFundingSidePanel";
import LsriBridgeSidePanel from "./pages/Lsri/SidePanelContent/LsriBridgeSidePanel";
import LsriQlacSidePanel from "./pages/Lsri/SidePanelContent/LsriQlacSidePanel";
import LsriTextAboveSidePanel from "./pages/Lsri/SidePanelContent/LsriTextAboveSidePanel";
import LsriReviewSidePanel from "./pages/Lsri/SidePanelContent/LsriReviewSidePanel";

const SidePanelWizardStepContent = function (props: { wizardTitle: string, wizardPath: GatewayPath, isExpanded?: boolean }): React.ReactElement {

    return (<React.Fragment>

        {props.wizardTitle === "LifeStage Retirement Income" && <div className="sidePanelContentText">
            {props.wizardPath === GatewayPath.LsriSocialSecurity && <LsriSocialSecuritySidePanel />}
            {props.wizardPath === GatewayPath.LsriMpp && <LsriMppSidePanel isExpanded={props.isExpanded} />}
            {props.wizardPath === GatewayPath.LsriFunding && <LsriFundingSidePanel isExpanded={props.isExpanded} />}
            {props.wizardPath === GatewayPath.LsriBridge && <LsriBridgeSidePanel isExpanded={props.isExpanded} />}
            {props.wizardPath === GatewayPath.LsriQlac && <LsriQlacSidePanel isExpanded={props.isExpanded} />}
            {props.wizardPath === GatewayPath.LsriReview && <LsriReviewSidePanel />}
        </div>}

    </React.Fragment>)

};

const SidePanelTextAbove = function (props: { wizardTitle: string }): React.ReactElement {
    const divClass = "sidePanelWizardTopText mx-3 my-0"

    return (<>

        {props.wizardTitle === "LifeStage Retirement Income" && <div className={divClass}><LsriTextAboveSidePanel /></div>}

    </>)

};

const SidePanelTextBelow = function (props: { wizardTitle: string }): React.ReactElement {
    const divClass = "sidePanelWizardBottomText mx-3 mt-4 mb-5"

    return (<>

        {props.wizardTitle === "LifeStage Retirement Income" && <div className={divClass}><LsriTextBelowSidePanel /></div>}

    </>)

};
export { SidePanelWizardStepContent, SidePanelTextAbove, SidePanelTextBelow };