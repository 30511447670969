import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { contributionsActionCreators } from '../../../store/ContributionsStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import { Card, Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import { benefitProjectionCreators } from '../../../store/BenefitProjectionStore';
import { AppDispatch } from '../../..';
import { formatDate } from '../../../functions/formatDate';

type TContributionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Contributions = (props: TContributionsProps) => {

    const isLoading = (props.isLoading && !props.isLoaded);
    //const isErr = (!isLoading && props.contributions?.responseStatus !== undefined);
    const hasProjection = (props.benefitProjection?.benefitProjection?.estAmt > 0)
    const showCard = (!isLoading && props.contributions?.contributionPct !== undefined && hasProjection);
    const cardTitle = "Contributions"
    const asOfDate = props.benefitProjection?.benefitProjection?.asOfDate ? formatDate(props.benefitProjection?.benefitProjection?.asOfDate) : "";

    useEffect(() => {
        if (stateNeedsLoading(props))
            props.fetchContributions();

        if (stateNeedsLoading(props.benefitProjection))
            props.fetchBenefitProjection();
    });

    return (<>

        {props.isLoading && <LoadingSkeleton />}

        {showCard && <Card className="pageCard secondary">

            <Card.Title><FontAwesomeIcon icon={faCoins} className="ret-color" />{cardTitle}</Card.Title>

            <Card.Body>
                <p className="thin">
                    Contributing enough to get your full employer match, if one is available to you, is a smart strategy to maximize your retirement savings. Your
                    retirement projection includes a contribution rate of <strong>{props.contributions.contributionPct}% of your annual compensation</strong>.
                    This contribution percentage is based on our records as of {asOfDate}.
                </p>
                <p className="thin">
                    View details about your contribution record to verify we have the correct information. 
                </p>


                 




                                
                <BenefitsAccessSso to={`/mybenefits_contributions.aspx`}>
                    <Button className="mt-2" variant="secondary">View Contribution Details</Button>
                </BenefitsAccessSso>

            </Card.Body>
        </Card >}
    </>);
}

function mapStateToProps(state: AppState) {
    const { benefitProjection } = state;

    return {
        ...state.contributions,
        benefitProjection
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...contributionsActionCreators, ...benefitProjectionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contributions);


const LoadingSkeleton = () => (
    <Card className="pageCard secondary pb-3">
        <div><Skeleton width={'100%'} count={3} /></div>
        <div><Skeleton width={'5em'} /></div>

        <div className="mt-3"><Skeleton width={'100%'} count={2}/></div>

        <div className="mt-3"><Skeleton width={'80%'} /></div>
    </Card>
);