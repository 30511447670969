import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsDollar } from '@fortawesome/pro-solid-svg-icons';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppDispatch } from '../../..';
import { cmsActionCreators } from '../../../store/CmsStore';
import EYToggleSwitch from '../../EYToggleSwitch';

type TEyAuthProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const EyAuthorization = (props: TEyAuthProps) => {
    const cmsDataMessage = props.cmsData.fragments?.find(f => f.name === "EYAuthMessage");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });
 
    return (
        <Card className="pageCard secondary">
            <Card.Title><FontAwesomeIcon icon={faCommentsDollar} className="acctmgmt-color" />EY Authorization</Card.Title>
            <Card.Body>
                <EYToggleSwitch />

                {cmsDataMessage ? <p style={{ marginTop: ".75rem" }} className="thin" dangerouslySetInnerHTML={{ __html: cmsDataMessage.html }} /> : null}

            </Card.Body>
        </Card>
    );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.retirement
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchRetirementCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EyAuthorization);

