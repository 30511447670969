import React, { ReactElement } from 'react';
import { Card, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation'
import Cpp from '../lifeAndDisability/Cpp';
import UmLifeOptions from '../lifeAndDisability/UmLifeOptions';
import { CollapsibleCard } from '../../CollapsibleCard';

const LifeAndDisabilitySummary = (props: { expandCard?: boolean }): ReactElement => {

    return (
        <CollapsibleCard headerClassName="lifedis-color-bg" headerContent="Life & Disability" expandCard={props.expandCard}>
            <Card.Title className="sumAsOfDate"></Card.Title>
            <Card.Body>

                <Cpp isSummary/>

                <UmLifeOptions isSummary/>

                <span className="text-center">
                    <NavLink to={GatewayPath.LifeAndDisability} className="nav-link px-0" activeClassName="active">
                        <Button variant="primary" className="summaryBtn">Life & Disability Details</Button>
                    </NavLink>
                </span>

            </Card.Body>
        </CollapsibleCard>
    );
}

export { LifeAndDisabilitySummary };
