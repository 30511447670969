import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { personalInfoActionCreators } from '../../../store/accountManagement/PersonalInfoStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../..';

type TPersonalInfoProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const PersonalInformation = (props: TPersonalInfoProps) => {
    const isLoading = (props.isLoading || !props.isLoaded);
    const isErr = (!isLoading && props.error);

    const info = props.personalInfo?.personalInformation;
    const isMarried = info?.maritalStatus === "Married";

    useEffect(() => {
        if (stateNeedsLoading(props)) props.fetchPersonalInfo();
    });

    return (<Card className="pageCard">
        <Card.Title><FontAwesomeIcon icon={faUserCircle} className="acctmgmt-color" />Personal Information</Card.Title>
        <Card.Body>

            {isLoading &&
                <LoadingSkeleton />
            }

            {isErr &&
                <Alert className="mt-2" variant="danger">Unable to retrieve data for Personal Information</Alert>
            }

            {!isErr && !isLoading && info && <Form>
                <Form.Group>
                    <Form.Label>Participant</Form.Label>
                    <Form.Text>{info.name}</Form.Text>
                    <Form.Text>Participant #{info.partNum}</Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Text>{info.dob}</Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Marital Status</Form.Label>
                    <Form.Text>
                        {isMarried ? <>Married since {info.marriageDate}</> : <>Not Married</>}
                    </Form.Text>
                </Form.Group>

                {isMarried && <>
                    <Form.Group>
                        <Form.Label>Spouse</Form.Label>
                        <Form.Text>{info.spouseName}</Form.Text>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Spouse Date of Birth</Form.Label>
                        <Form.Text>{info.spouseDob}</Form.Text>
                    </Form.Group>
                </>}

            </Form>}

        </Card.Body>
    </Card>);
}

function mapStateToProps(state: AppState) {
    return {
        ...state.personalInfo
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...personalInfoActionCreators}, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalInformation);

const LoadingSkeleton = () => (<>
    <Form.Group>
        <Form.Label className="mt-2"><Skeleton width={'8em'} /></Form.Label>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
    </Form.Group>
    <Form.Group>
        <Form.Label><Skeleton width={'9em'} /></Form.Label>
        <Form.Text><Skeleton width={'9em'} /></Form.Text>
    </Form.Group>
    <Form.Group>
        <Form.Label><Skeleton width={'9em'} /></Form.Label>
        <Form.Text><Skeleton width={'14em'} /></Form.Text>
    </Form.Group>
    <Form.Group>
        <Form.Label><Skeleton width={'9em'} /></Form.Label>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
    </Form.Group>
</>);