import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Button, Row, Col, Form, Spinner, Alert } from 'react-bootstrap';
import { RequestType } from '../../../Gateway.dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { accountActionCreators } from '../../../store/AccountStore';
import { Requirement } from '../../Requirement';
import { usernameActionCreators } from '../../../store/accountManagement/UsernameStore';
import { activityActionCreators } from '../../../store/ActivityStore';
import { loginHelpCreators, LoginHelpPasswordState } from '../../../store/LoginHelpStore';
import { removeInvalidChars_Username } from '../../../functions/removeInvalidChars';
import ScrollToTop from '../../ScrollToTop';
import { AppDispatch } from '../../..';

type TUsernamePasswordProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const UsernamePassword = ({
    requestType,
    checkDuplicateUsername,
    error,
    loginError,
    passwordState,
    usernameFromState,
    isNotDuplicateUsername,
    logActivity,
    isLoading,
    finishLoginHelp,
    duplicateUsernameIsLoading,
    register,
    loginHelpUpdatePassword,
    backToLogin
}: TUsernamePasswordProps) => {
    const isErr = (error ||passwordState === LoginHelpPasswordState.Errror || loginError);

    useEffect(() => {
        document.title = "Benefits Access - Username and Password"
    }, []);

    const [username, setUsername] = useState(usernameFromState ? usernameFromState.toLowerCase() : '');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [isRedirecting, setIsRedirecting] = useState(false);

    useEffect(() => {
        checkDuplicateUsername(username);
    }, [username, checkDuplicateUsername]);

    useEffect(() => {
        if (isErr && isRedirecting)
            setIsRedirecting(false);
    }, [isErr, isRedirecting, setIsRedirecting]);

    const usernameLengthIsOk = () => { return username?.length >= 5 && username?.length <= 20; }
    const usernameNotInUse = () => { return isNotDuplicateUsername; }

    const passwordLengthIsOk = () => { return password?.length >= 8; }
    const passwordHasNumber = () => { return password?.length >= 1 && /\d/.test(password); }
    const passwordHasLetter = () => { return password?.length >= 1 && /[A-Za-z]/.test(password); }
    const passwordHasSpecial = () => { return password?.length >= 1 && /.*(?=.*[\\.:,;~@#+_&<>'"()[\]*|{}^/\-$%=!?]).*/.test(password); }

    const [showRePasswordErr, setShowRePasswordErr] = useState(false);
    useEffect(() => {
        if (showRePasswordErr)
            setShowRePasswordErr(password !== rePassword)
    }, [showRePasswordErr, setShowRePasswordErr, password, rePassword]);

    useEffect(() => {
        logActivity('UsernamePassword');
    }, [logActivity])



    const headerText = () => {
        switch (requestType) {
            case RequestType.REGISTRATION:
                return "Let's setup your username and password. Choosing a unique username and a strong password will help protect your account. Longer passwords are better. We recommend that you do not reuse a username or password from another website.";
            case RequestType.NEEDHELP:
                return "Choosing a unique username and a strong password will help protect your account. Longer passwords are better. We recommend that you do not reuse a username or password from another website.";
        }
    }

    const enableSubmitBtn = () => {

        const usernameOk = () => {
            if (requestType === RequestType.REGISTRATION)
                return usernameLengthIsOk() && usernameNotInUse();
            else
                return true;
        }

        const passwordOk = () => {
            return passwordLengthIsOk() &&
                passwordHasNumber() &&
                passwordHasLetter() &&
                passwordHasSpecial() &&
                !showRePasswordErr;
        }

        return usernameOk() &&
            passwordOk() &&
            !isLoading && 
            !isRedirecting &&
            !isErr &&
            passwordState !== LoginHelpPasswordState.Updating;
    }

    const disableSecureLogin = () => {
        return passwordState === LoginHelpPasswordState.Updating || isRedirecting;
    }

    const hideCancelBtn = () => {
        return passwordState === LoginHelpPasswordState.Updating || isLoading || isRedirecting;
    }

    const secureLogin = () => {
        setIsRedirecting(true)
        finishLoginHelp();
    }

    const submit = () => {
        if (duplicateUsernameIsLoading)
            return;
        else if (password !== rePassword)
            setShowRePasswordErr(true);
        else if (requestType === RequestType.REGISTRATION) {
            setIsRedirecting(true);
            register(username, password);
        }
        else if (requestType === RequestType.NEEDHELP) {
            setIsRedirecting(true);
            loginHelpUpdatePassword(password);
        }
    }

    const cancel = () => {
        setUsername('');
        setPassword('');
        setRePassword('');
        backToLogin();
    }

    const UsernameHelp = () => {
        return (<>
            <Row className="username">
                <Col className="col-12">
                    Your username is
                    <h3>{username}</h3>
                </Col>
                <Col className="col-12">
                    <Button variant="outline-primary" tabIndex={1} onClick={() => (secureLogin())} disabled={ disableSecureLogin() }>
                        {isRedirecting || passwordState === LoginHelpPasswordState.Updating ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : <><FontAwesomeIcon icon={faLockAlt} className="mr-2" />Secure Login</>}
                    </Button>
                </Col>
            </Row>
        </>);
    }

    const UsernameRequirements = () => {
        return (
            <Col className="requirements mt-md-4">
                <div className="mb-3">Your username must:</div>
                <Requirement isOk={usernameLengthIsOk()}>Be between 5 and 20 characters</Requirement>
                <Requirement isOk={usernameNotInUse()}>Have never been used before</Requirement>
            </Col>
        );
    }

    const PasswordRequirements = () => {
        return (<>
            <Col className="requirements ml-0 mt-md-4">
                <div className="mb-3">Your case sensitive password must:</div>
                <Requirement isOk={passwordLengthIsOk()}>Be a minimum of 8 characters</Requirement>
                <Requirement isOk={passwordHasNumber()}>Have a number</Requirement>
                <Requirement isOk={passwordHasLetter()}>Have a letter</Requirement>
                <Requirement isOk={passwordHasSpecial()}>Have a special character</Requirement>
            </Col>
        </>);
    }

    const colClassName = "col-12 col-lg-10 col-xl-9";
    return (
        <Row className="justify-content-center">

            <ScrollToTop />

            <Col className={colClassName}>
                <Card className="fullPageCard">
                    <Card.Title>
                        Username & Password
                    </Card.Title>

                    <Card.Body>

                        <p>
                            {headerText()}
                        </p>

                        {isErr && <Alert className="my-2" variant="danger">
                            Unable to update information at this time.
                        </Alert>}

                        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            submit();
                        }}>

                            {requestType === RequestType.NEEDHELP ? <UsernameHelp /> :
                                <Row>
                                    <Col className="col-12 col-md-5">
                                        <Form.Group controlId="username">
                                            <Form.Label>Select a Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={username}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setUsername(removeInvalidChars_Username(e.target.value)) }}
                                                maxLength={20}
                                                tabIndex={1}
                                                autoFocus
                                            />
                                        </Form.Group>
                                    </Col>

                                    <UsernameRequirements />

                                </Row>
                            }

                            {requestType === RequestType.NEEDHELP && <>
                                <hr className="thick usernamePw"/>

                                <p className="my-4">
                                    Don&apos;t remember your password? You can change it below.
                                </p>
                            </>}

                            <Row>
                                <Col className="col-12 col-md-5">
                                    <Form.Group controlId="password">
                                        <Form.Label>
                                            {requestType === RequestType.REGISTRATION && <>Choose a Password</>}
                                            {requestType === RequestType.NEEDHELP && <>Change Your Password</>}
                                        </Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                type="password"
                                                value={password}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value)}}
                                                maxLength={40}
                                                tabIndex={2}
                                            />
                                        </div>
                                    </Form.Group>

                                    <div className="d-block d-md-none">
                                        <PasswordRequirements />
                                    </div>

                                    <br />

                                    <Form.Group controlId="rePassword">
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                type="password"
                                                value={rePassword}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setRePassword(e.target.value) }}
                                                maxLength={40}
                                                tabIndex={3}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <div className="d-none d-md-block">
                                    <PasswordRequirements />
                                </div>

                            </Row>

                            {showRePasswordErr && <Alert className="my-2 col-12" variant="danger">
                                Your passwords do not match. Please re-enter your passwords.
                            </Alert>}

                            <Button className="mt-4" variant="primary" type="submit" tabIndex={4} disabled={!enableSubmitBtn()}>
                                {passwordState === LoginHelpPasswordState.Updating || isLoading || isRedirecting ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : "Submit"}
                            </Button>

                            <Button className="pl-md-5 mt-4" variant="link" onClick={() => { cancel(); }} tabIndex={5} hidden={hideCancelBtn()}>
                                Cancel
                            </Button>

                        </Form>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function mapStateToProps(state: AppState) {
    const { requestType, passwordState } = state.loginHelp
    
    const loginError = state.account.error;
    const { duplicateUsernameIsLoading, duplicateUsernameIsLoaded, isNotDuplicateUsername } = state.username;
    const { username } = state.account;

    return {
        ...state.loginHelp,
        requestType,
        loginError,
        duplicateUsernameIsLoading,
        duplicateUsernameIsLoaded,
        isNotDuplicateUsername,
        usernameFromState: username,
        passwordState
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...accountActionCreators,
        ...usernameActionCreators,
        ...activityActionCreators,
        ...loginHelpCreators
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsernamePassword);
