import { AppState } from "./configureStore";
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetHealthPlansResponse, GetHealthPlans, CheckBenefitSolverBannerStatusResponse, CheckBenefitSolverBannerStatus } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";

interface Error {
    errorMessage: string | unknown
}

interface BenefitSolverHeaderState extends IEnumLoadableState {
    showEnrollmentBanner: boolean
    showPreEnrollmentBanner: boolean
    nextYearEligible: boolean
    enrollmentEndDate: string
    enrollmentDate: string
    jvLink: string | undefined
}

export interface IHealthPlansState extends ILoadableState {
    benefitSolverHeader: BenefitSolverHeaderState
    healthPlans: GetHealthPlansResponse
    planDetailsError: boolean
}

export const initialHealthPlansState: IHealthPlansState = {
    benefitSolverHeader: {
        showEnrollmentBanner: false,
        showPreEnrollmentBanner: false,
        nextYearEligible: false,
        enrollmentEndDate: '12/31/2000',
        enrollmentDate: '11/1/2023',
        jvLink: undefined,
        loadState: DataLoadState.None
    },
    healthPlans: {} as GetHealthPlansResponse,
    isLoading: false,
    isLoaded: false,
    error: false,
    planDetailsError: false
};

const fetchBenefitSolverHeaderInfo = createAsyncThunk<
    CheckBenefitSolverBannerStatusResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'healthPlans/fetchBenefitSolverHeaderInfo',
    async (_, thunkApi) => {
        try {

            const bsStatusResponse = await client.post(new CheckBenefitSolverBannerStatus());

            return bsStatusResponse;

        } catch (e) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const slice = createSlice({
    name: 'healthPlans',
    initialState: {} as IHealthPlansState,
    reducers: {
        resetState: () => {
            return {
                ...initialHealthPlansState
            }
        },
        requestHealthPlans: (state: IHealthPlansState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveHealthPlans: (state: IHealthPlansState, action: PayloadAction<{ healthPlans: GetHealthPlansResponse }>) => {
            const { healthPlans } = action.payload;
            return {
                ...state,
                healthPlans,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveHealthPlansError: (state: IHealthPlansState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialHealthPlansState)
            .addCase(fetchBenefitSolverHeaderInfo.pending, (state: IHealthPlansState) => {
                state.benefitSolverHeader.loadState = DataLoadState.Loading;
            })
            .addCase(fetchBenefitSolverHeaderInfo.fulfilled, (state: IHealthPlansState, { payload }) => {
                state.benefitSolverHeader.loadState = DataLoadState.Loaded
                state.benefitSolverHeader.showEnrollmentBanner = payload.showEnrollmentBanner;
                state.benefitSolverHeader.showPreEnrollmentBanner = payload.showPreEnrollmentBanner;
                state.benefitSolverHeader.nextYearEligible = payload.nextYearEligible;
                state.benefitSolverHeader.enrollmentEndDate = payload.enrollmentEndDate;
                state.benefitSolverHeader.enrollmentDate = payload.enrollmentDate;
                state.benefitSolverHeader.jvLink = payload.jvUrl;
            })
            .addCase(fetchBenefitSolverHeaderInfo.rejected, (state: IHealthPlansState, action) => {
                state.benefitSolverHeader = {
                    ...initialHealthPlansState.benefitSolverHeader,
                    loadState: DataLoadState.Error
                }
            })
    }
})

const {
    resetState,
    requestHealthPlans,
    receiveHealthPlans,
    receiveHealthPlansError
} = slice.actions;

function fetchHealthPlans() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().healthPlans;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestHealthPlans());

        try {

            const healthPlans = await client.post(new GetHealthPlans());

            dispatch(receiveHealthPlans({ healthPlans }));

            //Check for errors
            if (healthPlans.responseStatus)
                throw healthPlans.responseStatus.message;

            //Determine if health page should be displayed based on this data
            if (healthPlans.planElections?.length > 0)
                dispatch(displayPageActionCreators.showHealth());

            //Determine if wellbeing page should be displayed based on this data
            if (healthPlans.hasMedical)
                dispatch(displayPageActionCreators.showWellBeing());

        } catch (e: unknown) {
            dispatch(receiveHealthPlansError());
            console.log(e);
            //dispatch(displayPageActionCreators.showHealth());
        }

    }
}

export const healthPlansActionCreators = {
    fetchHealthPlans,
    fetchBenefitSolverHeaderInfo,
    resetState
};

export const healthPlansReducer = slice.reducer;
