import React, { ReactElement, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation'
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { balanceActionCreators } from '../../../store/BalanceStore';
import { connect, useDispatch } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppDispatch } from '../../..';
import Balance from '../retirement/Balance';
import BenefitProjection from '../retirement/BenefitProjection';
import Payments from '../retirement/Payments';
import Investments from '../retirement/Investments';
import MonthlyBenefit from '../retirement/MonthlyBenefit';
import SettingUpYourRetirementIncome from '../retirement/SettingUpYourRetirementIncome';
import { settingUpRetirementIncomeActionCreators } from '../../../store/SettingUpRetirementIncomeStore';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import Skeleton from 'react-loading-skeleton';
import { CollapsibleCard } from '../../CollapsibleCard';

type TRetirementSummaryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface OwnProps { expandCard?: boolean }
const RetirementSummary = (props: TRetirementSummaryProps): ReactElement => {
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchBalance();
            dispatch(settingUpRetirementIncomeActionCreators.fetchSettingUpRetirementIncome());
        }
    });

    return (
        <CollapsibleCard headerClassName="ret-color-bg" headerContent="Retirement" expandCard={props.expandCard}>
            <Card.Title className="sumAsOfDate">Data as of {props.balance?.asOfDate}</Card.Title>
            <Card.Body className="pt-0">

                <Balance isSummary />

                <Investments isSummary />

                <MonthlyBenefit isSummary />

                <Payments isSummary />

                {!props.settingUpRetirementIncomeLoaded ? <LoadingSkeleton />
                    : props.settingUpRetirementIncome.showSettingUpRetirementIncome
                        ? <SettingUpYourRetirementIncome isSummary />
                        : <BenefitProjection isSummary />
                }

                <span className="text-center">
                    <NavLink to={GatewayPath.Retirement} className="nav-link px-0" activeClassName="active">
                        <Button variant="primary" className="summaryBtn">Retirement Details</Button>
                    </NavLink>
                </span>

            </Card.Body>
        </CollapsibleCard>
    );
}
export { RetirementSummary };

function mapStateToProps(state: AppState, ownProps: OwnProps) {

    return {
        ...ownProps,
        ...state.balance,
        ...state.settingUpRetirementIncome,
        settingUpRetirementIncomeLoaded: state.settingUpRetirementIncome.loadState === DataLoadState.Loaded
    };
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators(balanceActionCreators, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RetirementSummary);

const LoadingSkeleton = () => (
    <div>
        <div><Skeleton width={'8em'} height={'1em'} /></div>
        <div><Skeleton width={'10em'} height={'1.5em'} /></div>
    </div>
);