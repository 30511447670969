import { AppState } from "./configureStore";
import { GetGoogleAnalyticsId } from "./../Gateway.dtos";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { AppDispatch } from "..";

export interface IGoogleAnalyticsIdState extends ILoadableState {
    analyticsId: string
}

export const initialGoogleAnalyticsId: IGoogleAnalyticsIdState = {
    analyticsId: "",
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'googleAnalyticsId',
    initialState: {} as IGoogleAnalyticsIdState,
    reducers: {
        requestGoogleAnalyticsId: (state: IGoogleAnalyticsIdState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveGoogleAnalyticsId: (state: IGoogleAnalyticsIdState, action: PayloadAction<{ analyticsId: string }>) => {
            const { analyticsId } = action.payload;
            return {
                ...state,
                analyticsId,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveGoogleAnalyticsIdError: (state: IGoogleAnalyticsIdState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }

    }
})

const { requestGoogleAnalyticsId, receiveGoogleAnalyticsId, receiveGoogleAnalyticsIdError } = slice.actions;

function fetchGoogleAnalyticsId() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().googleAnalyticsId;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestGoogleAnalyticsId());

        try {
            const googleAnalyticsId = await client.get(new GetGoogleAnalyticsId());

            dispatch(receiveGoogleAnalyticsId({ analyticsId: googleAnalyticsId.googleAnalyticsId }));

        } catch (e: unknown) {
            dispatch(receiveGoogleAnalyticsIdError());
            console.log(e);
        }

    }
}

export const googleAnalyticsIdActionCreators = {
    fetchGoogleAnalyticsId
};

export const googleAnalyticsIdReducer = slice.reducer;
