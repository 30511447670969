import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsCommit } from './LsriWizard.steps';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { push } from 'connected-react-router';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import LsriReviewPanels from './LsriReviewPanels';
import { cmsActionCreators } from '../../../store/CmsStore';
import LsriTermsAndConditions from './LsriTermsAndConditions';
import { Lsri } from '../../../definitions/Claims';
import { LsriStage } from '../../../Gateway.dtos';
import { getBusinessPaymentDate } from '../../../functions/getBusinessPaymentDate';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriVerify = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const isSubmitLoading = lsri.submitLoadState === DataLoadState.Loading
    const isSubmitError = lsri.submitLoadState === DataLoadState.Error
    const isSubmitSuccess = lsri.submitLoadState === DataLoadState.Loaded
    const [iAgreeIsChecked, setIAgreeIsChecked] = useState(false);

    const hasClaim_Update = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === Lsri.Update)

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Verify Your LifeStage Retirement Income Selections'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Verify & Submit`
    }, []);

    useEffect(() => {
        if (isSubmitSuccess)
            dispatch(push(GatewayPath.LsriSuccess))
    });

    const handleClickSubmit = () => {
        dispatch(LsriActionCreators.submitLsriElection())
    }

    const handleClickCancel = () => {
        dispatch(LsriActionCreators.wizardCancel())
    }

    //Prevent invalid page rendering, usually due to back button usage
    useEffect(() => {
        if (lsri.loadState === DataLoadState.None) {
            dispatch(LsriActionCreators.setWizardType(LsriStage.Setup))
            dispatch(push(GatewayPath.LsriOverview))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Verify"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    useEffect(() => {
        dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection))
        dispatch(cmsActionCreators.fetchLsriCmsData("Success"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const iAgreeMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/IAgreeMsg`)?.html ?? '';
    const footerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/FooterMsg`)?.html ?? '';
    //End CMS Section


    const ErrorMsg = () => (
        <Alert variant="danger" className="my-4">
            An error occurred when submitting your election.
        </Alert>
    )

    const getSelectedPaymentDate = () => {
        const selectedPayment = lsri.lsriInfo.oracleLsriElection.paymentOptions.find(p => p.elected === true);
        return selectedPayment ? new Date(selectedPayment.date) : new Date();
    }

    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 3,
                steps: wizardStepsCommit,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                <LsriReviewPanels />

                <LsriTermsAndConditions />

                <Form.Group onClick={() => setIAgreeIsChecked(!iAgreeIsChecked)} className="mt-5">
                    <Form.Check
                        checked={iAgreeIsChecked}
                        name="isForeign"
                        type="checkbox"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setIAgreeIsChecked(!iAgreeIsChecked) }}
                        label={`${iAgreeMsgCms}`}
                        style={{fontWeight: 'bold'}}
                        tabIndex={1}
                    />
                </Form.Group>

                {isSubmitError && <ErrorMsg />}

                <div className="my-4">

                    {hasClaim_Update && <>
                        <Button variant="primary" tabIndex={2} className={`${isSmallScreen ? 'w-100' : ''}`} disabled={!iAgreeIsChecked || isSubmitLoading || isSubmitError || isSubmitSuccess} onClick={() => handleClickSubmit()}>
                            {isSubmitLoading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : 'Submit'}
                        </Button>

                        {!isSmallScreen && <span className="ml-4 mr-3">|</span>}
                    </>}

                    <Button variant="link px-0 mx-0" tabIndex={3} className={`${isSmallScreen ? 'w-100 mt-3' : ''}`} disabled={isSubmitLoading} onClick={() => handleClickCancel()}>
                        Cancel
                    </Button>

                </div>

                <p dangerouslySetInnerHTML={{ __html: footerMsgCms.replace("$BusinessPaymentDate$", getBusinessPaymentDate(getSelectedPaymentDate(), lsri.lsriInfo.holidayList)) }} />

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriVerify);



