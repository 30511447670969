import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../store/configureStore';
import { bindActionCreators } from 'redux';
import { cppActionCreators } from '../store/CppStore';
import { stateNeedsLoading } from '../functions/stateNeedsLoading';
import { umLifeOptionsActionCreators } from '../store/UmLifeOptionsStore';
import { balanceActionCreators } from '../store/BalanceStore';
import { benefitProjectionCreators } from '../store/BenefitProjectionStore';
import { investmentsActionCreators } from '../store/InvestmentsStore';
import { monthlyBenefitActionCreators } from '../store/MonthlyBenefitStore';
import { hsaActionCreators } from '../store/HealthSavingsAccountStore';
import { healthPlansActionCreators } from '../store/HealthPlanStore';
import { wellBeingActionCreators } from '../store/WellBeingStore';
import { AppDispatch } from '../';
import { medicareActionCreators } from '../store/MedicareStore';
import { claimsActionCreators } from '../store/ClaimsStore';
import { Health, WellBeing, EY } from '../definitions/Claims';
import { accountActionCreators } from '../store/AccountStore';
import { retirementMenuActionCreators } from '../store/RetirementMenuStore';
import { cmsActionCreators } from '../store/CmsStore';
import { settingUpRetirementIncomeActionCreators } from '../store/SettingUpRetirementIncomeStore';


type TFetchDataProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const FetchData = (props: TFetchDataProps) => {
    const { participantNumber, isFinPlanner, claims, setPartNum, eySearch } = props;

    const dispatch = useDispatch();    

    useEffect(() => {
        //Clear out the cached participantNumber from cache
        if (claims?.isLoaded && isFinPlanner && !eySearch?.isLoaded && participantNumber)
            setPartNum({ participantNumber: "" });
    }, [claims, isFinPlanner, setPartNum, eySearch, participantNumber])


    useEffect(() => {

        //Load the Claims states
        if (stateNeedsLoading(claims))
            props.fetchClaims();

        //Load the rest of the data after claims are loaded and if financial planner, after participantNumber is populated
        if (claims?.isLoaded && (!isFinPlanner || (isFinPlanner && eySearch?.isLoaded && participantNumber))) {

            if (stateNeedsLoading(props.retirementMenu))
                dispatch(retirementMenuActionCreators.fetchRetirementMenu());

            //Load the Retirement states
            if (stateNeedsLoading(props.benefitProjection))
                props.fetchBenefitProjection();

            if (stateNeedsLoading(props.investments))
                props.fetchInvestments();

            if (stateNeedsLoading(props.balance))
                props.fetchBalance();

            if (stateNeedsLoading(props.monthlyBenefit))
                props.fetchMonthlyBenefit();

            if (stateNeedsLoading(props.settingUpRetirementIncome))
                dispatch(settingUpRetirementIncomeActionCreators.fetchSettingUpRetirementIncome());

            //Load the Life and Disability states
            if (stateNeedsLoading(props.cpp))
                props.fetchCppEligibility();

            if (stateNeedsLoading(props.umLifeOptions))
                props.fetchUmLifeOptions();

            if (stateNeedsLoading(props.umLifeOptions))
                props.fetchInvestmentsSummaryCmsData();            


            //Load the Health states
            if (props.hasClaim_HealthView) {
                if (stateNeedsLoading(props.hsa))
                    props.fetchHsa();

                if (stateNeedsLoading(props.healthPlans))
                    props.fetchHealthPlans();

                if (stateNeedsLoading(props.medicare))
                    props.fetchMedicare();

                if (stateNeedsLoading(props.healthPlans.benefitSolverHeader)) {
                    props.fetchBenefitSolverHeaderInfo();
                }
            }


            //Load the Well Being states
            if (props.hasClaim_WellBeingView && stateNeedsLoading(props.wellBeing))
                props.fetchWellBeing();
        }

        //Load common CMS
        if (stateNeedsLoading(props.cmsDataLogout))
            props.fetchLogoutCmsData();

        if (stateNeedsLoading(props.cmsDataPageTitleState))
            props.fetchCmsPageTitleData();

        if (stateNeedsLoading(props.cmsPayments))
            props.fetchPaymentsCmsData();

    });
    
    return (
        <></>
    )

};

function mapStateToProps(state: AppState) {
    const { hsa, healthPlans, cpp, umLifeOptions, balance, benefitProjection, investments, monthlyBenefit, wellBeing, medicare, claims, eySearch, retirementMenu, settingUpRetirementIncome } = state;

    return {
        balance,
        benefitProjection,
        investments,
        monthlyBenefit,
        cpp,
        umLifeOptions,
        hsa,
        healthPlans,
        wellBeing,
        medicare,
        claims,
        eySearch,
        retirementMenu,
        settingUpRetirementIncome,
        cmsDataLogout: state.cms.logout,
        participantNumber: state.account.participantNumber,
        hasClaim_WellBeingView: state.claims.claims?.claims?.find(c => c === WellBeing.View),
        hasClaim_HealthView: state.claims.claims?.claims?.find(c => c === Health.View),
        isFinPlanner: state.claims.claims?.claims?.find(c => c === EY.Search),
        cmsDataPageTitleState: state.cms.pageTitleState,
        cmsInvestmentsSummary: state.cms.investmentsSummary,
        cmsPayments: state.cms.payments,
        ...state.displayPage
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { setPartNum } = accountActionCreators;

    return bindActionCreators({
        ...cppActionCreators,
        ...umLifeOptionsActionCreators,
        ...balanceActionCreators,
        ...benefitProjectionCreators,
        ...investmentsActionCreators,
        ...monthlyBenefitActionCreators,
        ...hsaActionCreators,
        ...healthPlansActionCreators,
        ...wellBeingActionCreators,
        ...medicareActionCreators,
        ...claimsActionCreators,
        ...cmsActionCreators,
        setPartNum
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FetchData);