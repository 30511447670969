import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import NumberFormat from 'react-number-format';

export interface CurrencyInputProps {
    id?: string;
    name: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    onBlur?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    defaultValue?: string;
    className?: string;
    disabled?: boolean;
    //style?: CSSProperties;
    decimalScale: number;
    tabIndex?: number;
    showCurrencySymbol?: boolean;
    maxLength?: number;
    autoFocus?: boolean;
    leftAlign?: boolean
}



const CurrencyInput = (props: CurrencyInputProps): React.ReactElement => {
    const maxLength = props.maxLength ?? 15;
    const [inputLength, setInputLength] = useState(maxLength - 3);
    const [currencyValue, setCurrencyValue] = useState(props.defaultValue);
    const showPrefix = props.showCurrencySymbol != null ? props.showCurrencySymbol : true;
    const decimalScale = props.decimalScale !== undefined ? props.decimalScale : 2;

    const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {

        //Do not let user bypass max limit by deleting the decimal
        if (!e.target.value.includes(".") && e.target.value.length === maxLength) {
            return
        }
        else {
            setCurrencyValue(e.target.value);
            if (props.onChange) {
                props.onChange(e);
            }
        }
    }

    useEffect(() => {
        setCurrencyValue(props.defaultValue);
    }, [props.defaultValue])

    return (
        <NumberFormat
            className={`${props.leftAlign ? 'text-left' : 'text-right'} ${props.className}`}
            prefix={showPrefix ? "$" : ""}
            thousandSeparator={true}
            decimalScale={decimalScale}
            maxLength={inputLength}
            customInput={Form.Control}
            allowNegative={false}
            onKeyPress={(e) => { (e.key) === "." ? setInputLength(maxLength) : setInputLength(inputLength) }}
            defaultValue={currencyValue}
            value={currencyValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInputLength(e.target.value.includes(".") ? maxLength : maxLength - 3); onChangeInternal(e); }}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
            autoFocus={props.autoFocus}
        />
    )
}

export { CurrencyInput };