import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { loginHelpCreators } from '../../../store/LoginHelpStore';
import { isValidEmail } from '../../../functions/isValidEmail';
import ScrollToTop from '../../ScrollToTop';
import { AppDispatch } from '../../..';
import { RequestType } from '../../../Gateway.dtos';


type TEmailProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Email = (props: TEmailProps) => {
    const { error: isErr, requestType, initialRequestType } = props

    const showNotRegisteredMessage = requestType === RequestType.REGISTRATION && initialRequestType === RequestType.NEEDHELP;

    const [email, setEmail] = useState(props.personalInformationResponse.email || '');
    const [isEditing, setIsEditing] = useState(!props.personalInformationResponse.email);

    const [showEmailErr, setShowEmailErr] = useState(false);
    function isEmailValid(val: string) {
        const isValid = isValidEmail(val);
        setShowEmailErr(!isValid);
        return isValid;
    }

    const enableSubmitBtn = () => {
        return !showEmailErr &&
            !props.isLoading &&
            !isErr;
    }

    const beginEditMode = () => {
        setEmail(props.personalInformationResponse.email);
        setIsEditing(true);
    }

    const submit = () => {
        if (isEmailValid(email)) {
            props.verifyEmail(email);
        }
    }

    useEffect(() => {
        //If inline errors are displayed, re-evaluate them on changes
        if (isEditing && showEmailErr) {
            isEmailValid(email);
        }
    });

    useEffect(() => {
        document.title = "Benefits Access - Email"
    }, []);

    const colClassName = "col-12 col-lg-10 col-xl-9";
    return (
        <Row className="justify-content-center">

            <ScrollToTop />

            <Col className={colClassName}>
                <Card className="fullPageCard">
                    <Card.Title>
                        Email
                    </Card.Title>

                    <Card.Body>

                        <p className="mb-3">
                            First, please make sure we have an accurate email that you have access to anytime. This will ensure you can receive 
                            important account information and security notifications.
                        </p>

                        {showNotRegisteredMessage && 
                            <Alert variant="info">
                                It appears you have not yet registered. Simply create a username and password on the next step to complete your registration
                            </Alert>    
                        }
                        

                        {isErr && <Alert className="my-2" variant="danger">
                            Unable to update information at this time.
                        </Alert>}

                        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            submit();
                        }}>
                            <Form.Group controlId="email" className={showEmailErr ? "error" : ""}>
                                <Form.Label>{isEditing ? "Email" : "The email we have on file is:"}</Form.Label>
                                {isEditing ? <Form.Control
                                    type="text"
                                    value={email}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value) }}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isEmailValid(e.target.value) }}
                                    maxLength={40}
                                    tabIndex={3}
                                    className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6"
                                    autoFocus
                                /> : <Form.Text style={{fontSize: '150%'}}>{email}</Form.Text>}
                                {isEditing && showEmailErr && <div className="errMsg">Invalid Email</div>}                          
                            </Form.Group>

                            <Row className="mt-5">
                                <Col className="col-12">
                                    <Button className="mb-4" variant="primary" onClick={submit} type="submit" tabIndex={4} autoFocus={!isEditing} disabled={!enableSubmitBtn()}>
                                        {isEditing ? "Submit" : "My email is correct"}
                                    </Button>

                                    {!isEditing && <Button className="ml-0 ml-md-3 mb-4" variant="secondary" onClick={beginEditMode} tabIndex={5}>
                                        I need to update my email
                                        </Button>}
                                </Col>
                            </Row>

                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function mapStateToProps(state: AppState) {

    return {
        ...state.loginHelp
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...loginHelpCreators
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Email);

