import React from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { Alert, Button, Table } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { AppDispatch } from '../../../..';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH, faCheck, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import 'rc-slider/assets/index.css';
import { createSliderWithTooltip } from 'rc-slider';
import Slider from 'rc-slider/lib/Slider';
import { LsriSocSecStatus, OracleCalculation } from '../../../../Gateway.dtos';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { IconDefinition} from '@fortawesome/fontawesome-svg-core';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps { isExpanded?: boolean }
const LsriBridgeSidePanel = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();
    const { lsri, isExpanded } = props

    const SliderWithTooltip = createSliderWithTooltip(Slider);

    const isVisited = lsri.lsriInfo?.oracleLsriElection?.visitedSteps?.bridge
    const showUpdateErrMsg = lsri.calculationLoadState === DataLoadState.Error;
    const scenario = lsri?.userElection?.scenario ?? ""
    const bridgeIsSelected = lsri.userElection?.scenario?.includes("B")
    const age = lsri.lsriInfo?.prLsriInfo?.age;
    const ssElection = lsri.lsriInfo?.oracleLsriElection?.socialSecurity
    const hasSocSecEst = ssElection?.ss_Status !== LsriSocSecStatus.NoInfo && (ssElection?.ss_Amt ?? 0) > 0

    const userMatrix = lsri.lsriInfo?.oracleLsriElection?.matrix

    function getMinAge(scenario: string) {
        return userMatrix?.filter(m => m.scenario === scenario)?.reduce(function (prev, current) {
            if (prev.ssAge === 0) return current
            else if (current.ssAge === 0) return prev
            else return prev.ssAge < current.ssAge ? prev : current
        }, { ssAge: 100 } as OracleCalculation).ssAge ?? 0
    }

    function getMaxAge(scenario: string) {
        return userMatrix?.filter(m => m.scenario === scenario)?.reduce(function (prev, current) {
            return (prev.ssAge > current.ssAge) ? prev : current
        }, { ssAge: 0 } as OracleCalculation).ssAge ?? 0
    }

    const scenarioWithBridge = `B${scenario.includes("R") ? 'R' : ''}${scenario.includes("Q") ? 'Q' : ''}`
    const minAgeWithBridge = getMinAge(scenarioWithBridge)
    const maxAgeWithBridge = getMaxAge(scenarioWithBridge)

    const scenarioNoBridge = `${scenario.includes("R") ? 'R' : ''}${scenario.includes("Q") ? 'Q' : ''}`
    const minAgeNoBridge = getMinAge(scenarioNoBridge)
    const maxAgeNoBridge = getMaxAge(scenarioNoBridge)

    const minAge = bridgeIsSelected ? minAgeWithBridge : minAgeNoBridge
    const maxAge = bridgeIsSelected ? maxAgeWithBridge : maxAgeNoBridge

    const isRecivingOrOptOut = ssElection?.ss_Status === LsriSocSecStatus.Receiving || ssElection?.ss_Status === LsriSocSecStatus.OptOut
    const isIneligible = !bridgeIsSelected && (age >= 69 || isRecivingOrOptOut || (hasSocSecEst && (lsri.bridgeIneligible || minAge === 0 || maxAge === 0)));
    const disabledBridgeOptions = !((userMatrix?.findIndex(m =>
        m.scenario.includes("B") &&
        (scenario.includes("Q") ? m.scenario.includes("Q") : !m.scenario.includes("Q")) &&
        (scenario.includes("R") ? m.scenario.includes("R") : !m.scenario.includes("R"))
    ) ?? -1) >= 0)
    const nonBridgeSocOptionsExist = ((userMatrix?.findIndex(m =>
        m.ssAge === lsri.userElection?.ssAge &&
        (scenario.includes("Q") ? m.scenario.includes("Q") : !m.scenario.includes("Q")) &&
        (scenario.includes("R") ? m.scenario.includes("R") : !m.scenario.includes("R"))
    ) ?? -1) >= 0)
    const selectedAge = lsri.userElection?.ssAge

    const handleClickNext = () => {
        dispatch(push(GatewayPath.LsriQlac));
    }

    const handleChange = (selectedBridge: boolean, selectedAge: number) => {

        //Only send data to server if changes were made
        if (selectedAge !== lsri.userElection?.ssAge || selectedBridge !== bridgeIsSelected) {     

            //Check if age needs to be adjust if it's out of range for new bridge selection
            const minAge = selectedBridge ? minAgeWithBridge : minAgeNoBridge
            const maxAge = selectedBridge ? maxAgeWithBridge : maxAgeNoBridge

            if (selectedAge < minAge)
                selectedAge = minAge
            else if (selectedAge > maxAge)
                selectedAge = maxAge

            const newScenario = `${selectedBridge ? 'B' : ''}${scenario.includes("R") ? 'R' : ''}${scenario.includes("Q") ? 'Q' : ''}`
            const userElection = lsri.lsriInfo.oracleLsriElection.matrix.find(m => m.ssAge === selectedAge && m.scenario === newScenario) ?? {} as OracleCalculation

            dispatch(LsriActionCreators.updateLSRIAgeQlacBridge({ userElection }))
        }
    }


    if ((!isVisited && !isExpanded) || !lsri?.lsriInfo?.isEligible)
        return null


    if (!isExpanded) {
        return (<h5>
            <FontAwesomeIcon icon={faCheck} /> {bridgeIsSelected ? `Bridge to age ${selectedAge}` : "No bridge"}
        </h5>);
    }

    const ErrorMsg = () => (
        <Alert variant="danger" className={`mt-2 mr-3 ${isSmallScreen ? '' : 'small'}`}>
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while updating your bridge election.
        </Alert>
    )

    const getSVGURI = ({ prefix, iconName, icon }: IconDefinition) =>
        `data:image/svg+xml;base64,${btoa(
            `<svg data-prefix="${prefix}" data-icon="${iconName}"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon[0]} ${icon[1]}">
                <path fill="white" d="${icon[4]}"></path>
            </svg>`)}`;

    return (<div className="sidePanelContentText">

        {!isIneligible && <>

            <h4>See the effect of using the LifeStage bridge</h4>

            <div className="form-check">
                <label>
                    <input
                        type="radio"
                        className="form-check-input"
                        name="bridgeSelection"
                        value="Y"
                        checked={bridgeIsSelected}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(true, selectedAge) }}
                        disabled={disabledBridgeOptions}
                        tabIndex={1}
                    />                
                    <div className="mt-1">Use LifeStage funds as a bridge</div>
                </label>
            </div>

            <div className="form-check">
                <label>
                    <input
                        type="radio"
                        className="form-check-input"
                        name="bridgeSelection"
                        value="N"
                        checked={!bridgeIsSelected}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(false, selectedAge) }}
                        disabled={disabledBridgeOptions}
                        tabIndex={2}
                    />                
                    <div className="mt-1">Do not use LifeStage funds as a bridge</div>
                </label>
            </div>

            {hasSocSecEst && (!disabledBridgeOptions || nonBridgeSocOptionsExist) && <>

                <h4 className="mt-4 mb-0 pr-3">Slide to select your Social Security starting age</h4>

                <Table className="sliderTable">
                    <tbody>
                        <tr className="pl-0">
                            <td className="pl-0" style={{ color: '#0063A7', paddingRight: '8px', paddingTop: '13px', fontSize: '120%' }}>
                                {minAge}
                            </td>
                            <td className="slider">
                                <SliderWithTooltip
                                    min={minAge}
                                    max={maxAge}
                                    defaultValue={lsri.userElection?.ssAge}
                                    onAfterChange={(val: number) => handleChange(bridgeIsSelected, val)}
                                    railStyle={{
                                        backgroundColor: '#96B5DE',
                                        marginTop: -1
                                    }}
                                    trackStyle={{ backgroundColor: '#96B5DE' }}
                                    dots
                                    dotStyle={{
                                        backgroundColor: '#96B5DE',
                                        borderColor: '#96B5DE',
                                        width: 5,
                                        height: 10,
                                        borderRadius: '40%'
                                    }}
                                    handleStyle={{
                                        borderColor: '#0063A7',
                                        borderWidth: 4,
                                        height: 24,
                                        width: 24,
                                        marginTop: -11,
                                        backgroundColor: '#0063A7',
                                        backgroundImage: `url(${getSVGURI(faArrowsAltH)})`
                                    }}
                                />
                            </td>
                            <td style={{ color: '#0063A7', paddingLeft: '8px', paddingTop: '13px', fontSize: '120%' }}>
                                {maxAge}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </>}

            {showUpdateErrMsg && <ErrorMsg />}

        </>}

        <div className="buttonAndContent" style={isIneligible ? {marginTop: '5rem', marginBottom: '6rem'} : {}}>
            <Button className="px-0" variant="link" onClick={() => handleClickNext()}>
                <strong>Next</strong> - longevity income (QLAC)
            </Button>

            <div>
                Your progress is saved automatically.
            </div>
        </div>

    </div>);
};

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriBridgeSidePanel);

