import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { LsriAccountTransfer, LsriAccountTransferResponse } from '../../../Gateway.dtos';
import {  useWindowDimensions } from '@wespath/gateway-navigation';
import { DropDown, DropDownOption } from '../../DropDown';
import { CurrencyInput } from '../../CurrencyInput';
import { formatMoney } from '../../../functions/formatMoney';
import { convertDollarPctToNumber } from '../../../functions/convertStringToNumber';
import { FormatDollar } from '../../FormatDollar';
import { Distribution } from '../../../definitions/Claims';
import BenefitsAccessSso from '../../BenefitsAccessSso';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriFundingManage = (props: TLifestageRIProps) => {

    //This is a subcomponent for the LsriFunding.tsx page.  Loading data is handled on that page

    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const { tradeDate, estPayment, estPaymentDate, maxPaymentDate, noPayments, isVerified, isReceived } = lsri.accountTransferResponse ?? new LsriAccountTransferResponse()

    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const submitIsLoading = lsri.accountTransferLoadState === DataLoadState.Loading
    const isError = lsri.accountTransferLoadState === DataLoadState.Error
    const isVerifiedOrReceived = isVerified || isReceived

    const hasClaim_Update = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === Distribution.Update)
    const hasClaim_View = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === Distribution.View)
    //const hasOnlyView_Claim = (hasClaim_View && hasClaim_View?.toString().length > 0) && (!hasClaim_Update)
    const hasOnlyView_Claim = !hasClaim_Update ? ((hasClaim_View && hasClaim_View?.toString().length > 0) ? true : false) : false;

    const plans = lsri.lsriInfo.balances
    const [selAccount, setSelAccount] = useState(lsri.fundingSelAcct ?? "");
    const [selAmount, setSelAmount] = useState(lsri.fundingSelAmt?.toString() ?? "");
    const [enableContinue, setEnableContinue] = useState(false);
    const targetAccount = selAccount === "UMPIP" ? "LifeStage" : (selAccount === "LifeStage" ? "UMPIP" : "-")

    const fullLifeStageAmount = lsri.lsriInfo?.oracleLsriElection?.systemDefault?.amtAllocated
    const currentMonthlyPayment = formatMoney(lsri.userElection?.lsriLifestage ?? 0, true)


    //CMS Section
    //Loading is handled by the parent funding page
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Funding"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    const manageFundingMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/ManageFundingMsg`)?.html ?? '';
    const iAgreeMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/IAgreeMsg`)?.html ?? '';
    const confirmationEstimateMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/ConfirmationEstimateMsg`)?.html ?? '';
    //End CMS Section



    const getStatusDDLOptions = (): (DropDownOption)[] => {
        const ddlOptions: (DropDownOption)[] = [];

        ddlOptions.push({ value: "", display: "From", hideIfNotSelected: true } as DropDownOption);
        plans?.forEach((p) => {
            ddlOptions.push({
                value: p.planName,
                display: `${p.display} - ${p.hasPendingAction ? "Pending" : formatMoney(p.amount, true)}`
            } as DropDownOption)
        })        

        return ddlOptions;
    }

    //Error validation

    const [showAccountErr, setShowAccountErr] = useState(false);
    function isAccountValid(val: string | undefined) {
        const isPending = plans?.find(p => p.planName === val)?.hasPendingAction
        const isValid = val !== undefined && val !== '' && !isPending
        setShowAccountErr(!isValid);
        return isValid;
    }

    const maxAmt = plans?.find(p => p.planName === selAccount)?.amount ?? 0;
    const [showAmountErr, setShowAmountErr] = useState(false);
    function isAmountValid(val: string | undefined) {
        const amtNum = convertDollarPctToNumber(val)
        const isValid = val !== undefined && amtNum >= 1 && (selAccount !== "" ? amtNum <= maxAmt : true)
        setShowAmountErr(!isValid);
        return isValid;
    }

    const isInlineErrors = () => {
        let isErr = false

        //Check for errors so that they all display
        isErr = !isAccountValid(selAccount) || isErr;
        isErr = !isAmountValid(selAmount) || isErr;

        setEnableContinue(!isErr)

        return isErr;
    }



    //Action handling

    const handleClickSubmit = () => {
        if (!isVerified && isInlineErrors())
            return;
        let liquidate = "n";
        const selAmtConverted = convertDollarPctToNumber(selAmount)

        if (selAccount === "LifeStage") {
            if (selAmtConverted < fullLifeStageAmount && selAmtConverted === maxAmt) {
                liquidate = "t";
            }
            else {
                liquidate = selAmtConverted >= fullLifeStageAmount ? "y" : "n";
            }
        }
        else {
            liquidate = selAmtConverted >= maxAmt ? "y" : "n";
        }

        dispatch(LsriActionCreators.updateFundingSelAcct(selAccount))
        dispatch(LsriActionCreators.updateFundingSelAmt(selAmtConverted))

        dispatch(LsriActionCreators.performBalanceTransfer({
            accountTransfer: {
                sourceAccount: selAccount,
                targetAccount,
                amount: selAmtConverted,
                liquidate: liquidate,
                isSubmit: isVerified,
                isPending: false
            } as LsriAccountTransfer
        }))
    }

    const handleClickCancel = () => {
        dispatch(LsriActionCreators.resetBalanceTransferLoadState())
        setSelAccount("");
        setSelAmount("");
        dispatch(LsriActionCreators.updateFundingSelAcct(""))
        dispatch(LsriActionCreators.updateFundingSelAmt(undefined))
        setEnableContinue(false);
    }

    const handleAccountChange = (val: string) => {
        setSelAccount(val);
        setSelAmount("");
        setEnableContinue(false);
    }

    useEffect(() => {
        if (selAmount !== "") isAmountValid(selAmount)
        if (selAccount !== "") isAccountValid(selAccount)

        if (selAmount !== "" && selAccount !== "") isInlineErrors()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selAccount, selAmount])

    //Scroll to top of page if a message is displayed
    useEffect(() => {
        if (isReceived || isError)
            window.scrollTo(0, 0);
    }, [isReceived, isError])

    const SuccessMsg = () => (
        <Alert variant="success" className="mb-5">
            <FontAwesomeIcon icon={faCheckCircle} /><strong>Success</strong>
            <p className="p-0 m-0">
                Your transfer has been scheduled.  You have until 3:00 p.m., Central time  
                on {tradeDate} to <BenefitsAccessSso to={`/mybenefits_pendingactions.aspx`}>change your mind</BenefitsAccessSso>.
            </p>
        </Alert>
    )

    const ErrorMsg = () => (
        <Alert variant="error" className="mt-5">
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while submitting your Balance Transfer.
        </Alert>
    )

    const MaxPaymentMsg = () => (
        <Alert variant="warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            Based on the new LifeStage account balance, the program will only be able to provide you with payments until {maxPaymentDate}.
        </Alert>
    )

    const NoPaymentsMsg = () => (
        <Alert variant="warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            Based on the new LifeStage account balance, the program will no longer be able to provide you with a payment.
        </Alert>
    )

    const NotVerifiedMsgs = () => (<>
        <p dangerouslySetInnerHTML={{ __html: manageFundingMsgCms }} />
        <p>
            Transfers are processed based on the next market close. After the transfer is complete, your LifeStage Retirement Income will be recalculated.
        </p>
    </>)

    const VerifiedMsgs = () => (<>

        {maxPaymentDate && <MaxPaymentMsg />}

        {noPayments && <NoPaymentsMsg />}

    </>)

    const VerifiedDetails = () => (
        <Form.Group className="mb-4">
            <Form.Label><strong>Transfer Date</strong></Form.Label>
            <div>{tradeDate}</div>

            <Form.Label className="mt-5"><strong>New Estimated Monthly Payment</strong></Form.Label>
            <div><strong style={{ color: '#000' }}>{formatMoney(estPayment, true)}</strong> starting on {estPaymentDate}</div>

            <Form.Label className="mt-4"><strong>Current Monthly Payment</strong></Form.Label>
            <div>{currentMonthlyPayment}</div>
        </Form.Group>
    )

    if (!hasClaim_View)
        return null

    return (<>

        {(isLoading || cmsIsLoading) ? <LoadingSkeleton /> : <>

            {isError && <ErrorMsg />}

            {isReceived && <SuccessMsg />}

            {!isVerified && !isReceived
                ? <NotVerifiedMsgs />
                : <VerifiedMsgs />
            }

            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                handleClickSubmit();
            }}>

                <Form.Group className={`mt-3 ${showAccountErr ? "error" : ""}`} style={{ maxWidth: `${isSmallScreen || isVerifiedOrReceived ? 'initial' : '20rem'}` }}>
                    <Form.Label><strong>Source Account</strong></Form.Label>
                    {isVerifiedOrReceived
                        ? <div>{selAccount}</div>
                        : <>
                            <DropDown name="socSecStatus"
                                dropDownOptions={getStatusDDLOptions()}
                                defaultValue={selAccount}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleAccountChange(e.target.value) }}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { handleAccountChange(e.target.value) }}
                                tabIndex={1}
                            />
                            {showAccountErr && <div className="errMsg">Invalid Source Account</div>}
                        </>
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label><strong>Destination Account</strong></Form.Label>
                    <div>{targetAccount}</div>
                </Form.Group>

                <Form.Group className={`${showAmountErr ? "error" : ""}`} style={{ maxWidth: `${isSmallScreen || isVerifiedOrReceived ? 'initial' : '12rem'}` }}>
                    <Form.Label><strong>Amount</strong></Form.Label>
                    {isVerifiedOrReceived
                        ? <div><FormatDollar amount={convertDollarPctToNumber(selAmount)} /></div>
                        : <>
                            <CurrencyInput
                                id={`amount`}
                                name={`amount`}
                                showCurrencySymbol={true}
                                defaultValue={selAmount}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelAmount(e.target.value)}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => setSelAmount(e.target.value)}
                                decimalScale={2}
                                tabIndex={2}
                            />
                            {showAmountErr && <div className="errMsg">Invalid Amount</div>}
                        </>
                    }
                </Form.Group>

                {isVerifiedOrReceived && <VerifiedDetails />}

                {isVerified && <p className="pt-4" dangerouslySetInnerHTML={{ __html: iAgreeMsgCms }} />}

                {isReceived && <p className="pt-4" dangerouslySetInnerHTML={{ __html: confirmationEstimateMsgCms }} />}

                {!isReceived && <div className="mt-4">

                    {hasClaim_Update && <Button variant={`primary`} tabIndex={3} className={`${isSmallScreen ? 'w-100' : ''}`} disabled={!enableContinue || submitIsLoading} onClick={() => handleClickSubmit()}>
                        {submitIsLoading ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : `${isVerified ? "Agree & Submit" : "Continue"}`}
                    </Button>}

                    {hasOnlyView_Claim && <Button variant={`primary`} tabIndex={3} className={`${isSmallScreen ? 'w-100' : ''}`} disabled={isVerified || !enableContinue || submitIsLoading} onClick={() => handleClickSubmit()}>
                        {submitIsLoading ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : `${isVerified ? "Agree & Submit" : "Continue"}`}
                    </Button>}

                    {isVerified && <>

                        {!isSmallScreen && (hasClaim_Update || hasOnlyView_Claim) && <span className="ml-4 mr-3">|</span>}

                        <Button variant="link px-0 mx-0" tabIndex={4} className={`${isSmallScreen ? 'w-100 mt-3' : ''}`} disabled={submitIsLoading} onClick={() => handleClickCancel()}>
                            Cancel
                        </Button>

                    </>}

                </div>}

            </Form>


        </>}

    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriFundingManage);

