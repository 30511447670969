import { AppState } from "./configureStore";
import { GetEyElig, GetEyEligResponse } from "./../Gateway.dtos";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface ILifeAndDisabilityState extends ILoadableState {
    eyEligibility: GetEyEligResponse
}

export const initialEyEligibility: ILifeAndDisabilityState = {
    eyEligibility: {} as GetEyEligResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'ey',
    initialState: {} as ILifeAndDisabilityState,
    reducers: {
        resetState: () => {
            return {
                ...initialEyEligibility
            }
        },
        requestEyEligibility: (state: ILifeAndDisabilityState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveEyEligibility: (state: ILifeAndDisabilityState, action: PayloadAction<{ eyEligibility: GetEyEligResponse }>) => {
            const { eyEligibility } = action.payload;
            return {
                ...state,
                eyEligibility,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveEyEligibilityError: (state: ILifeAndDisabilityState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialEyEligibility)
    }
})

const { resetState, requestEyEligibility, receiveEyEligibility, receiveEyEligibilityError } = slice.actions;

function fetchEyEligibility() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().ey;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestEyEligibility());

        try {

            const eyEligibility = await client.post(new GetEyElig());

            dispatch(receiveEyEligibility({ eyEligibility }));

            //if (eyEligibility.eyEligible)
                //dispatch(displayPageActionCreators.showLifeAndDisability());

        } catch (e: unknown) {
            dispatch(receiveEyEligibilityError());
            console.log(e);
        }

    }
}

export const eyActionCreators = {
    fetchEyEligibility,
    resetState
};

export const eyReducer = slice.reducer;
