import { AppState } from "./configureStore";
import { ErrHandling } from "../Gateway.dtos";
import { client } from "../App";
import { AppDispatch } from "..";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IErrHandlingState {
    page: string
    message: string
}

export const initialErrHandlingState: IErrHandlingState = {
    page: '/',
    message: ''
};

const slice = createSlice({
    name: 'errHandling',
    initialState: {} as IErrHandlingState,
    reducers: {
        setErrHandling: (state: IErrHandlingState, action: PayloadAction<{ page: string, message: string }>) => {
            const { page, message } = action.payload;
            return {
                ...state,
                page,
                message
            }
        }
    }
})

const { setErrHandling } = slice.actions;

function logErr(message?: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const previousPage = getState().errHandling.page;
        const previousMessage = getState().errHandling.message;
        const page = getState().router.location.pathname;
        message = message || '';

        //Do not log err if page/message isn't different
        if (page === previousPage && message === previousMessage) {
            return
        }

        dispatch(setErrHandling({ page, message }));

        try {
            await client.post(new ErrHandling({ message, page }));

        } catch (e: unknown) {
            console.log(e);
        }

    }
}

export const errHandlingActionCreators = {
    logErr
};

export const errHandlingReducer = slice.reducer;