import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import BenefitsAccessSso from '../BenefitsAccessSso';
import { AppState } from '../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../..';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { GatewayPath } from '@wespath/gateway-navigation';
import { mppDistributionCreators } from '../../store/MPPDistributionStore';
import { BAPageHeader } from '../BAPageHeader';
import { parseCmsContent } from '../../functions/parseCmsContent';
import { LabelAndContent } from '../LabelAndContent';
import { formatMoney } from '../../functions/formatMoney';
import chkImg from '../../images/check.png';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import BALayout from '../BALayout';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { accountActionCreators } from '../../store/AccountStore';
import { cmsActionCreators } from '../../store/CmsStore';

type TMPPDistributionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const MPPDistribution = (props: TMPPDistributionProps): ReactElement => {
    //const { accessDenied } = props;
    const hasError = props.loadState === DataLoadState.Error

    const dispatch: AppDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(stateNeedsLoading(props));

    const pendingActionLink = "/mybenefits_pendingactions.aspx";
    const cmsPreGC2022MPP65Message = props.cmsData.fragments?.find(f => f.name === "PreGC2022MPP65Message")?.html ?? '';
    const cmsPostGC2022MPP65Message = props.cmsData.fragments?.find(f => f.name === "PostGC2022MPP65Message")?.html ?? '';
    const cmsMPPOptionMessage = props.cmsData.fragments?.find(f => f.name === "MPPOptionMessage")?.html ?? '';
    const cmsMPPAnnuityMessage = props.cmsData.fragments?.find(f => f.name === "MPPAnnuityMessage")?.html ?? '';
    const cmsMPPOneTimeDistributionSection = props.cmsData.fragments?.find(f => f.name === "MPPOneTimeDistributionSection")?.html ?? '';
    const cmsMPPRolloverMessage = props.cmsData.fragments?.find(f => f.name === "MPPRolloverMessage")?.html ?? '';

    useEffect(() => {
        document.title = "Benefits Access - MPP Distribution";
        setIsLoading(true);
        dispatch(mppDistributionCreators.getMPPDistribution())
            .then(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchMPPDistributionCmsData();
        }
    });

    return (<>
        <BALayout>

            {isLoading && <LoadingSkeletonFullpage />}

            {(!isLoading && !hasError) &&
                <Row>
                    <Col>
                        <BAPageHeader name="Ministerial Pension Plan Distribution" />
                        <br></br>
                        {(!props.mppDistribution.eligible) ?
                            <>
                                <Row className="mt-5">
                                    <Col>
                                        <div dangerouslySetInnerHTML={parseCmsContent(props.mppDistribution.eligibleMessage)}>
                                        </div>

                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row className="mt-2">
                                    <Col className="col-12 col-sm-8">
                                        Below are your options for the distribution of your Ministerial Pension Plan (MPP) account balance.
                                        <Row className="my-2">
                                            <LabelAndContent className="" label="Current MPP account balance: ">
                                                {formatMoney(props.mppDistribution.mppBalance, true)}
                                            </LabelAndContent>
                                        </Row>

                                        <Row className="my-2">
                                            {(props.mppDistribution.pE607?.trim() === "" && props.mppDistribution.mppBalance > 0) &&
                                                <Col>
                                                    {props.mppDistribution.postGC2022 && 
                                                        <div dangerouslySetInnerHTML={parseCmsContent(cmsPostGC2022MPP65Message)}></div>
                                                    }
                                                    {(!props.mppDistribution.postGC2022) &&
                                                        <div dangerouslySetInnerHTML={parseCmsContent(cmsPreGC2022MPP65Message)}></div>
                                                    }
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col className="col-12 col-sm-4">
                                        {props.mppDistribution.distPageElig &&
                                            <BenefitsAccessSso to={`/takeaction_retirement_benefits.aspx`} className="right">Review Retirement Benefits</BenefitsAccessSso>
                                        }
                                    </Col>

                            </Row>
                                {props.mppDistribution.showMPPOption &&
                                    <Row className="ml-2 my-3 p-3 border border-warning">
                                        <Col>
                                            <div className="px-2 py-2">
                                                <div className="font-weight-bold mb-1">Additional MPP Option</div>
                                                <div dangerouslySetInnerHTML={parseCmsContent(cmsMPPOptionMessage)}></div>

                                                {props.mppDistribution.explorerLifeStageDisplayButton?.toLowerCase() === "button" &&
                                                    <>
                                                        <NavLink to={GatewayPath.LsriSocialSecurity} className="nav-link px-0" activeClassName="active">
                                                            <Button variant="secondary" name="btnExploreOption" id="btnExploreOption" className="btn-block-xs-only mt-2">Explore LifeStage</Button>
                                                        </NavLink>
                                                    </>
                                                }
                                                {props.mppDistribution.explorerLifeStageDisplayButton?.toLowerCase() === "pending" &&
                                                    <BenefitsAccessSso to={pendingActionLink} className="secondary ml-3"><b>Pending</b></BenefitsAccessSso>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                <Row className="ml-2 my-3 p-3 border" style={{ border: "1px solid #adb5bd !important" }}>

                                    <Col >
                                        <b>MPP Annuity</b><br />
                                        <span dangerouslySetInnerHTML={parseCmsContent(cmsMPPAnnuityMessage)}></span>
                                        <Row className={props.mppDistribution.annuityDisplayBalance ? "my-3" : "my-3 float-right"}>
                                            {props.mppDistribution.annuityDisplayBalance &&
                                                <LabelAndContent className="col-12 col-sm-9" label="Estimated MPP account balance used to fund your annuity:">
                                                    {formatMoney(props.mppDistribution.annuitizationBalance, true)}
                                                </LabelAndContent>
                                            }

                                            {props.mppDistribution.annuityDisplayButton.toLowerCase() === "button" &&
                                                <Col>
                                                    <BenefitsAccessSso to="takeaction_mpp_annuity_benefits_comme_date.aspx" buttonLink={true}>
                                                        <Button className="btn-block-xs-only float-right" variant="primary">Get Started</Button>
                                                    </BenefitsAccessSso>
                                                </Col>
                                            }
                                            {props.mppDistribution.annuityDisplayButton.toLowerCase() === "check" &&
                                                <Col className={" float-right"}>
                                                    <img className="my-3 my-sm-0 float-right" src={chkImg} alt="Enrolled" />
                                                </Col>
                                            }
                                            {props.mppDistribution.annuityDisplayButton.toLowerCase() === "pending" &&
                                                <Col className="col-12 text-right">
                                                    <BenefitsAccessSso to={pendingActionLink} className="primary float-right my-3 my-sm-0"><b>Pending</b></BenefitsAccessSso>
                                                </Col>
                                            }

                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="ml-2 my-3 p-3 border" style={{ border: "1px solid #adb5bd !important" }}>

                                    <Col>
                                        <b>MPP One-time Distribution</b><br />
                                        <span dangerouslySetInnerHTML={parseCmsContent(cmsMPPOneTimeDistributionSection)}></span>

                                        <Row>
                                            <Col>
                                                <b> Rollover to UMPIP and / or Cash Distribution</b><br />
                                                <span dangerouslySetInnerHTML={parseCmsContent(cmsMPPRolloverMessage)}></span>
                                            </Col>
                                        </Row>

                                        <Row className={props.mppDistribution.rolloverCashDisplayBalance ? "my-3" : "my-3 float-right"}>
                                            {props.mppDistribution.rolloverCashDisplayBalance &&
                                                <LabelAndContent className="col-12 col-sm-9" label="Estimated amount available for distribution:">
                                                    {formatMoney(props.mppDistribution.nonAnnuitizationBalance, true)}
                                                </LabelAndContent>
                                            }

                                            {props.mppDistribution.rolloverCashDisplayButton.toLowerCase() === "button" &&
                                                <Col>
                                                    <BenefitsAccessSso to="takeaction_mpp_onetime_type.aspx" buttonLink={true}>
                                                        <Button className="btn-block-xs-only float-right my-3 my-sm-0" variant="primary">Get Started</Button>
                                                    </BenefitsAccessSso>
                                                </Col>
                                            }

                                            {props.mppDistribution.rolloverCashDisplayButton.toLowerCase() === "check" &&
                                                <Col className={"float-right"}>
                                                    <img className="my-3 my-sm-0 float-right" src={chkImg} alt="Enrolled" />
                                                </Col>

                                            }
                                            {props.mppDistribution.rolloverCashDisplayButton.toLowerCase() === "pending" &&
                                                <Col className="col-12 text-right">
                                                    <BenefitsAccessSso to={pendingActionLink} className="primary float-right my-3 my-sm-0"><b>Pending</b></BenefitsAccessSso>
                                                </Col>
                                            }

                                        </Row>
                                    </Col>
                                </Row>

                            </>
                        }

                    </Col>
                </Row>
            }

            {(!isLoading && hasError) &&
                <Alert className="my-3" variant="danger">
                    <div>Error Occurred : {props.responseError?.errorMessage}</div>
                </Alert>
            }

        </BALayout>

    </>);
}

function mapStateToProps(state: AppState) {
    return {
        ...state.mppDistribution,
        cmsData: state.cms.mppDistribution
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { accessDenied } = accountActionCreators;

    return bindActionCreators({
        accessDenied,
        fetchMPPDistributionCmsData: cmsActionCreators.fetchMPPDistributionCmsData
    }, dispatch);
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MPPDistribution);

