import React, { useEffect } from 'react';
import { AppState } from '../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Card, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faCheck } from '@fortawesome/pro-solid-svg-icons';
import ReactSwitch from 'react-switch';
import { eyAuthActionCreators } from '../store/accountManagement/EyAuthorizationStore';
import Skeleton from 'react-loading-skeleton';
import { EY } from '../definitions/Claims';
import { activityActionCreators } from '../store/ActivityStore';
import { AppDispatch } from '..';
import { stateNeedsLoading } from '../functions/stateNeedsLoading';
import { personalInfoActionCreators } from '../store/accountManagement/PersonalInfoStore';

type TEyAuthProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const EYToggleSwitch = (props: TEyAuthProps) => {
    const dispatch: AppDispatch = useDispatch();
    const isLoading = (props.dataIsLoading || !props.dataIsLoaded) && !props.dataIsErr;
    const isErr = props.dataIsErr || (!isLoading && props.isAuthorized === "");
    const isAuthorized = props.isAuthorized === "y";

    useEffect(() => {
        if (stateNeedsLoading(props.personalInfoProps)) props.fetchPersonalInfo();
    }, []);

    const { showSuccessMsg, hideSuccessMsg } = props;
    useEffect(() => {
        if (showSuccessMsg)
            setTimeout(() => { hideSuccessMsg(); }, 3000);
    }, [showSuccessMsg, hideSuccessMsg]);

    if (!props.hasClaim_View) {
        return <></>;
    }

    return (
            <>

                {isLoading &&
                    <LoadingSkeleton />
                }

                {props.error &&
                    <Alert className="mt-2" variant="danger">Unable to update EY Authorization</Alert>
                }

                {isErr &&
                    <Alert className="mt-2" variant="danger">Unable to retrieve data for EY Authorization</Alert>
                }

                {props.showSuccessMsg && <Alert className="mt-2 fade-out" variant="success">
                    <FontAwesomeIcon icon={faCheck} /><strong>Success.</strong> Your EY Authorization has been updated.
                </Alert>}

                {!isLoading && !isErr && <>
                    <p className= "mt-10">
                        EY advisors are {isAuthorized ? "authorized" : "not authorized"} to see your account.
                    </p>

                    {props.hasClaim_Update && <label className="hideForPrint">
                        <span className={`${isAuthorized ? "" : "switch-disabled"} textStyle thin`}>Authorized</span>
                        {/*https://www.npmjs.com/package/react-switch*/}
                        <ReactSwitch
                            checked={!isAuthorized}
                            onChange={() => { dispatch(activityActionCreators.logActivity(`toggle_ey_setTo_${!isAuthorized}`)); props.updateEy(!isAuthorized); }}
                            uncheckedIcon={<div className="switchText left eyAuth">ON</div>}
                            checkedIcon={<div className="switchText right eyAuth">OFF</div>}
                            className={`react-switch px-3 ${isAuthorized ? '': 'react-switch-off'}`}
                            disabled={isLoading || isErr || props.isLoading || props.error}
                            activeBoxShadow='0 0 2px 3px #007bff'
                            width={80}
                            id="icon-switch"
                        />
                        <span className={`${!isAuthorized ? "" : "switch-disabled"} textStyle thin`}>Not Authorized</span>
                    </label>}
                </>}
            </ >
    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.eyAuthorization,
        dataIsLoading: state.personalInfo.isLoading,
        dataIsLoaded: state.personalInfo.isLoaded,
        personalInfoProps: state.personalInfo,
        dataIsErr: state.personalInfo.error,
        hasClaim_View: state.claims.claims?.claims?.find(c => c === EY.View),
        hasClaim_Update: state.claims.claims?.claims?.find(c => c === EY.Update)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...eyAuthActionCreators, ...personalInfoActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EYToggleSwitch);

const LoadingSkeleton = () => (
    <div className="col-12 pl-0">
        <div className="mt-2"><Skeleton width={'20em'} /></div>
        <div><Skeleton width={'15em'} height={'2em'} /></div>
    </div>
);