import React, { ReactElement, useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopMedical } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { useSelector } from 'react-redux';
import { ServiceProvider } from '../../../Gateway.dtos';
import { SamlSelfPostForm } from '@wespath/gateway-navigation';
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';
import { AppDispatch } from '../../..';
import { cmsActionCreators } from '../../../store/CmsStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';

type TMDLiveChangeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const MDLiveChange = (props: TMDLiveChangeProps): ReactElement => {

    const cmsData = props.cmsData.fragments?.find(f => f.name === "ReviewPastPayment");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const hasClaim_HealthAccess = useSelector((state: AppState) => state.claims.claims?.claims?.find(c => c === Health.Access))
    const username = useSelector((state: AppState) => state.account.username)

    const serviceProvider = ServiceProvider.Quantum;

    const referrer = 'b'
    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);
    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent")
            formRef?.current?.submit();
        else
            setShowingSsoModal(true);
    }


    return (
        <>
            <Card className="pageCard secondary">
                <Card.Title>
                    <FontAwesomeIcon icon={faLaptopMedical} className="health-color smaller mr-0 pr-0" />
                    MDLIVE Costs Will Change In 2025
                </Card.Title>
                <Card.Body className='reviewPastClaims'>
                    <p className="thin">If you use MDLIVE for medical or behavioral health, please note there will be a cost for visits in 2025. If you have questions about what these costs might be based on your 2024 claims,
                        you can <Button className={`${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
                            variant="link"
                            onClick={handleSubmit}
                            disabled={!hasClaim_HealthAccess}
                            style={{ padding: '0px' }}
                        > contact your Care Coordinator</Button> or <a href="https://www.wespath.org/Assets/1/7/5258B.pdf" rel="noopener noreferrer" target="_blank">review the 2025 Plan Comparison</a>.</p>
                    <p className="thin">MDLIVE medical and behavioral health will still be available at no cost through 12/31/24.</p>
                </Card.Body>
            </Card>
            <SamlSelfPostForm ref={formRef} provider={serviceProvider} source={referrer} />
            <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer={referrer} />
        </>

    );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MDLiveChange);
