import React, { useEffect } from 'react';
import { AppState } from '../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pendingActionsActionCreators } from '../store/PendingActionsStore';
import BenefitsAccessSso from './BenefitsAccessSso';
import { Alert } from 'react-bootstrap';
import { AppDispatch } from '..';

type TPendingActionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const PendingActionsAlert = (props: TPendingActionsProps) => {

    const isLoading = (props.isLoading && !props.isLoaded);
    const isErr = (!isLoading && props.pendingActions?.responseStatus !== undefined);
    const showCard = (!isLoading && !isErr && props.pendingActions?.pendingActions);

    const { fetchPendingActions } = props;
    useEffect(() => {
        fetchPendingActions();
    }, [fetchPendingActions]);

    return (<>
        {showCard &&
            <Alert variant="info" className="mt-2">
                <h4>Pending Action</h4>
                You have a pending action on your account. You can <BenefitsAccessSso to={`/mybenefits_pendingactions.aspx`} >review
                all your pending actions</BenefitsAccessSso> and make changes on some of them.
            </Alert>
        }
    </>);
}

function mapStateToProps(state: AppState) {

    return {
        ...state.pendingActions
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...pendingActionsActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingActionsAlert);
