import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const FormatUnits = (props: { units?: number, unitStr?: string, negativeInItalics?: boolean, showCaret?: boolean, fractionDigits?: number }): React.ReactElement => {
    const { units, unitStr, negativeInItalics, showCaret, fractionDigits } = props;

    //Get the populated amount
    const unit = unitStr ? Number(unitStr) : units;

    const minFractionDigits = fractionDigits ? fractionDigits : 4;

    //Check if it is valid
    if (unit === undefined) return <span />;


    //Convert to formatted unit string, default is to display decimals
    const formattedUnit = new Intl.NumberFormat('en-US', { minimumFractionDigits: minFractionDigits }).format(Math.abs(unit));

    //Add formatting
    return (
        <span className="text-nowrap" style={{ fontStyle: (negativeInItalics && unit < 0 ? "italic" : ""), color: unit < 0 ? "red" : "" }}>
            {unit < 0 ? "(" + formattedUnit + ")" : unit === 0 ? "" : formattedUnit} {showCaret && unit !== 0 && <FontAwesomeIcon icon={unit > 0 ? faCaretUp : faCaretDown} />}
        </span>
    );
}
export { FormatUnits };