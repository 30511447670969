/* eslint-disable react/prop-types */
import React from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../../../..';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import { useWindowDimensions } from '@wespath/gateway-navigation';
import { FormatDollar } from '../../../FormatDollar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { getLsriChartNodes, getLsriAge, LsriBarType, LsriStepType } from './LsriFunctions';



type TLifestageRIProps = ReturnType<typeof mapStateToProps>;


const LsriDataTable = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen, isMediumScreen } = useWindowDimensions();

    const { lsri } = props;

    const isRecalculating =
        lsri.calculationLoadState === DataLoadState.Loading
        || lsri.resetLoadState === DataLoadState.Loading
        || (lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error)
    const isResetError = lsri.resetLoadState === DataLoadState.Error;

    const retAge = lsri?.lsriInfo?.oracleLsriElection?.socialSecurity?.retAge
    //const userMatrix = getUserMatrix(lsri)
    const lsriChartNodesCustom = getLsriChartNodes(lsri, retAge, false, true, false);
    const lsriChartNodesOriginal = getLsriChartNodes(lsri, retAge, true, true, false);

    //const lsriCommAge = lsri?.lsriInfo?.oracleLsriElection?.matrixCommon?.lsriCommAge;
    const lsriAge = getLsriAge(lsri, retAge);
    const socSecAge = lsri.userElection?.ssAge
    const qlacAge = lsri?.lsriInfo?.oracleLsriElection?.systemDefault?.qlacAge ?? 80

    const hideSocSecAge = socSecAge <= lsriAge
    const hideQlacAge = lsri.qlacIneligible

    const handleReset = () => {
        dispatch(LsriActionCreators.resetToOriginal())
    }

    const ResetBtn = () => (<>
        {lsri.showCompare && <Button variant="link" className="p-0 m-0"
            style={{ fontSize: "inherit", border: "none" }}
            onClick={() => handleReset()} disabled={isRecalculating} >
            Reset to default
        </Button>}
    </>)

    const DesktopBody = () => (
        <tbody>
            {!lsri.mppIneligible && <DataRow type={LsriBarType.Mpp} />}
            <DataRow type={LsriBarType.SocialSecurity} />
            <DataRow type={LsriBarType.Qlac} />
            <DataRow type={LsriBarType.LifeStage} />
            <tr>
                <td colSpan={3}></td>
                <td rowSpan={2} className="resetCell lastCol">
                    <ResetBtn />
                </td>
            </tr>
        </tbody>
    )

    const DataRow = (props: { type: LsriBarType }) => {
        return (
            <tr className={`${props.type}`}>
                <DataCell type={props.type} stepType={LsriStepType.Retirement} />
                <DataCell type={props.type} stepType={LsriStepType.SocialSecurity} />
                <DataCell type={props.type} stepType={LsriStepType.Qlac} />
                <td className="lastCol text-nowrap">
                    <div>
                        {calcRowDescription(props.type)}
                    </div>
                </td>
            </tr>
        );
    }

    const MobileTable = (props: { step: LsriStepType, age: number }) => (
        <Row>
            <Col className="lsriDataTableMobileAge col-2">
                <div>{props.age}</div>
                <div>Yrs.</div>
            </Col>
            <Col>
                <MobileBody step={props.step} />
            </Col>
        </Row>
    )

    const MobileBody = (props: { step: LsriStepType }) => (
        <Table className="lsriDataTable compact mb-5">
            <tbody>
                {!lsri.mppIneligible && <DataRowMobile type={LsriBarType.Mpp} step={props.step} />}
                <DataRowMobile type={LsriBarType.SocialSecurity} step={props.step} />
                <DataRowMobile type={LsriBarType.Qlac} step={props.step} />
                <DataRowMobile type={LsriBarType.LifeStage} step={props.step} />
            </tbody>
        </Table>
    )

    const DataRowMobile = (props: { type: LsriBarType, step: LsriStepType }) => {
        return (
            <tr className={`${props.type}`}>
                <DataCell type={props.type} stepType={props.step} />
                <td className="lastCol">
                    <div>
                        {calcRowDescription(props.type)}
                    </div>
                </td>
            </tr>
        )
    }

    const DataCell = (props: { type: LsriBarType, stepType: LsriStepType }) => {

        const isTotal = props.type === LsriBarType.Total;
        const hideData = (hideSocSecAge && props.stepType === LsriStepType.SocialSecurity) || (hideQlacAge && props.stepType === LsriStepType.Qlac)


        const customAmt = isTotal
            ? lsriChartNodesCustom?.find((n) => n.stepType === props.stepType)?.totalAmount ?? 0
            : lsriChartNodesCustom?.find((n) => n.stepType === props.stepType)?.lsriChartDataPoints?.find((p) => p.type === props.type)?.amount ?? 0

        const originalAmt = isTotal
            ? lsriChartNodesOriginal?.find((n) => n.stepType === props.stepType)?.totalAmount ?? 0
            : lsriChartNodesOriginal?.find((n) => n.stepType === props.stepType)?.lsriChartDataPoints?.find((p) => p.type === props.type)?.amount ?? 0

        const showCaret = !isTotal && customAmt !== 0 && customAmt !== originalAmt

        return (
            <td className="dataCell">
                {hideData
                    ? <div>&nbsp;</div>
                    : <Row className="m-0 p-0">
                        <Col className="m-0 p-0 col-2 text-left">
                            {showCaret && <FontAwesomeIcon
                                style={{ color: `${customAmt > originalAmt ? '#50A167' : '#B00E09'}` }}
                                icon={customAmt > originalAmt ? faCaretUp : faCaretDown}
                            />}
                        </Col>
                        <Col className="m-0 p-0 text-right">
                            <FormatDollar amount={customAmt} noCents zeroAsDash />
                        </Col>
                    </Row>
                }
            </td>
        )
    }

    const ResetErrorMsg = () => (
        <Alert variant="danger" className={`mt-4 mb-0`}>
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while resetting your election.
        </Alert>
    )

    return (<>

        {isResetError && <ResetErrorMsg />}

        {(!isSmallScreen && !isMediumScreen)
            
            ? <div className="my-0">
                <Table className="lsriDataTable mb-1">
                    <DesktopBody />
                </Table>
            </div>

            : <div className="mb-5 mt-3">
                {lsriAge && <MobileTable step={LsriStepType.Retirement} age={ lsriAge} />}
                {!hideSocSecAge && <MobileTable step={LsriStepType.SocialSecurity} age={socSecAge} />}
                {!hideQlacAge && <MobileTable step={LsriStepType.Qlac} age={qlacAge} />}
                <div className="text-left"><ResetBtn /></div>
            </div >
        }
    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriDataTable);


function calcRowDescription(barType?: LsriBarType) {

    switch (barType) {
        case LsriBarType.Total:
            return "";
        case LsriBarType.SocialSecurity:
            return "Social Security";
        case LsriBarType.Qlac:
            return "Longevity Income";
        case LsriBarType.Mpp:
            return "MPP 65% as Annuity";
        default:
            return barType;
    }
}