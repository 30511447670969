import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import { apiClient } from '../App'
import { RequestDtos, ResponseDtos, CacheTags } from './apiTypes';

export type NormalizedError = {
    errorCode?: string;
    message?: string;
    errors?: ServiceStackResponseError[];
    meta?: { [index: string]: string; };
    stackTrace?: string
}

type ServiceStackResponseError = {
    errorCode?: string;
    fieldName?: string;
    message?: string;
    meta?: { [index: string]: string; }
}

/*
    Custom BaseQuery that wraps ServiceStack JsonApiClient
 */
const serviceStackBaseQuery: BaseQueryFn<
    RequestDtos,
    ResponseDtos,
    NormalizedError
> = async (args) => {


    const { failed, error, response: data } = await apiClient.api<ResponseDtos>(args);

    if (failed) {
        const normalizedError: NormalizedError = {
            errorCode: error?.errorCode,
            message: error?.message,
            errors: error?.errors,
            meta: error?.meta,
            stackTrace: error?.stackTrace
        }

        return { error: normalizedError }
    }


    if (data?.responseStatus) {
        const normalizedError: NormalizedError = {
            errorCode: data.responseStatus.errorCode,
            message: data.responseStatus.message,
            errors: data.responseStatus.errors,
            meta: data.responseStatus.meta,
            stackTrace: data.responseStatus?.stackTrace
        }
        return { error: normalizedError }
    }

    return { data: data }
}

export const apiBase = createApi({
    baseQuery: serviceStackBaseQuery,
    endpoints: () => ({}),
    tagTypes: Object.values(CacheTags)
})