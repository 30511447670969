import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsSetup } from './LsriWizard.steps';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Button } from 'react-bootstrap';
import { GatewayPath } from '@wespath/gateway-navigation';
import { push } from 'connected-react-router';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { LsriStage } from '../../../Gateway.dtos';
import LsriReviewPanels from './LsriReviewPanels';
import { cmsActionCreators } from '../../../store/CmsStore';
import MultiFactorModal from '../../cards/multiFactor/MultiFactorModal';
import { authMultiFactorActionCreators } from '../../../store/AuthMultiFactorStore';
import LsriMinPaymentMsg from './Shared/LsriMinPaymentMsg';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriReview = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();

    const { lsri, account } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error

    const belowMinPaymentAmt = lsri.userElection?.lsriLifestage < lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.minPaymentAmt

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Review your LifeStage Retirement Income selections'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Review & Enroll`
        
        const profileName = account.profileName;
        //Check if two factor should be bypassed based on config values on service side
        if (!props.hasCompletedMultiFactor && !profileName) dispatch(authMultiFactorActionCreators.fetchTwoFactorStatus())

        //If user navigated to this page using the back button, switch back to LsriStage.Setup
        if (lsri.wizardType !== LsriStage.Setup) dispatch(LsriActionCreators.setWizardType(LsriStage.Setup));

        dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriReview }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });

    const handleClickSubmit = () => {
        const profileName = account.profileName;
        if (props.hasCompletedMultiFactor || profileName) {
            dispatch(LsriActionCreators.setWizardType(LsriStage.Commit))
            dispatch(push(GatewayPath.LsriPaymentOptions))
        }
        else {
            dispatch(authMultiFactorActionCreators.beginTransactionChallenge({ transaction: 'lsriSetup' }));
        }
    }

    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Review"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection))}, []);

    //Load cms pages on the commit wizard after review CMS is done loading
    useEffect(() => {
        if (!isLoading && !cmsIsLoading && cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) !== undefined) {
            dispatch(cmsActionCreators.fetchLsriCmsData("PaymentOptions"))
            dispatch(cmsActionCreators.fetchLsriCmsData("BankInfo"))
            dispatch(cmsActionCreators.fetchLsriCmsData("TaxOptions"))
            dispatch(cmsActionCreators.fetchLsriCmsData("Verify"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cmsIsLoading, isLoading]);


    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    //End CMS Section


    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 6,
                steps: wizardStepsSetup,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                <MultiFactorModal />

                <div className="mb-5" dangerouslySetInnerHTML={{ __html: headerMsgCms }} />

                <LsriMinPaymentMsg />

                <LsriReviewPanels />

                <div className="mt-5 ml-2">
                    <Button variant="primary" onClick={() => handleClickSubmit()} disabled={belowMinPaymentAmt}>
                        Continue To Enrollment
                    </Button>
                </div>

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        hasCompletedMultiFactor: state.authMultiFactor.hasCompletedMultiFactor,
        lsri: state.lsri,
        account: state.account
    }
}

export default connect(
    mapStateToProps
)(LsriReview);



