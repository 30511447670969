const removeInvalidChars_City = function (val: string): string {

    return val.replace(/[^\w\s-.']/gi, "").replace(/[_]/gi, "");
}
export { removeInvalidChars_City }


const removeInvalidChars_Address = function (val: string): string {

    return val.replace(/[^\w\s-.':/#]/gi, "").replace(/[_]/gi, "");
}
export { removeInvalidChars_Address }


const removeInvalidChars_Username = function (val: string): string {

    return val.replace(/[^a-z0-9+@]/gi, "");
}
export { removeInvalidChars_Username }


const removeInvalidChars_BankName = function (val: string): string {

    return val.replace(/[^\w\s&-.,/()']/gi, "");
}
export { removeInvalidChars_BankName }


const removeInvalidChars_RoutingNumber = function (val: string): string {

    return val.replace(/[^0-9]/gi, "");
}
export { removeInvalidChars_RoutingNumber }


const removeInvalidChars_BankAcctNumber = function (val: string): string {

    return val.replace(/[^0-9]/gi, "");
}
export { removeInvalidChars_BankAcctNumber }