import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BaCmsPage from './pages/BaCmsPage';

const BALayout = (props: { children: React.ReactNode; hideRightMargin?: boolean; }): JSX.Element => {
    return (
        <div className="page">
            <Row>
                <Col>
                    {props.children}
                </Col>
                {!props.hideRightMargin &&
                    <BaCmsPage isRightMarginContent={true} />
                }
            </Row>
        </div>
    )
}

export default BALayout