import React, { useEffect, useState } from 'react';
import { AppState } from '../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../..';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { Alert, Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { push } from 'connected-react-router';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import { BankAccount, BankAccountType, LsriStage } from '../../Gateway.dtos';
import { cmsActionCreators } from '../../store/CmsStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { checkABA } from '../../functions/checkABA';
import routingLogo from '../../images/Check-image.png';
import { removeInvalidChars_BankAcctNumber, removeInvalidChars_BankName, removeInvalidChars_RoutingNumber } from '../../functions/removeInvalidChars';
import { BankInfo } from '../../definitions/Claims';
import { accountActionCreators } from '../../store/AccountStore';
import { PageTitle } from '../PageTitle';
import { ILsriState, LsriActionCreators } from '../../store/LsriStore';
import { IManageDirectDepositState, manageDirectDepositActionCreators } from '../../store/ManageDirectDepositStore';

export interface OwnProps { isLsriBankInfoUpdate?: boolean }
type TUpdateBankInformationProps = ReturnType<typeof mapStateToProps>;

const UpdateBankInformation = (props: TUpdateBankInformationProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();
    const { manageDirectDeposit, lsri, isLsriBankInfoUpdate } = props;

    const wizardTitle = "LifeStage Retirement Income"

    useEffect(() => {
        document.title = isLsriBankInfoUpdate ? `Benefits Access - ${wizardTitle} - Bank Information` : `Benefits Access - Bank Information`

        console.log("Insode base load");

        if (isLsriBankInfoUpdate) {
            //Prevent invalid page rendering, usually due to back button usage
            if (lsri.loadState === DataLoadState.None) {
                dispatch(LsriActionCreators.setWizardType(LsriStage.Setup))
                dispatch(push(GatewayPath.LsriOverview))
            }

            if (lsri.wizardType === LsriStage.Commit)
                dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriBankInfo }))
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (<>
        {
            isLsriBankInfoUpdate ? <BankInformation isLsriBankInfoUpdate = { isLsriBankInfoUpdate } lsri = { lsri } manageDirectDeposit = { manageDirectDeposit } /> :
            <>
            {!isSmallScreen && <Card className="detailCard desktop" style={{ marginTop: '2em' }}>
                <PageTitle title="Bank Information" color="ret-color-bg" hidePrintButton={true} />
                <Card.Body className="sidePanelWizardCardBody">
                    <Row className="p-0 m-0">
                        <Col className={`pt-4 px-md-4 px-lg-4 px-xl-5`}>
                            <h3 className={`pb-3 ret-color sidePanelWizardCardHeader`}>Direct Deposit</h3>
                            <div style={{ marginBottom: '7rem' }}>
                                <BankInformation isLsriBankInfoUpdate={isLsriBankInfoUpdate} lsri={lsri} manageDirectDeposit={manageDirectDeposit } />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>}

            {isSmallScreen && <div className="detailCard mobile showForMd">
                <Card>
                    <PageTitle title="Bank Information" color="ret-color-bg" hidePrintButton={true} />
                    <Card.Body className="mx-3 py-4">
                        <h3 className={`pb-3 ret-color`}>Direct Deposit</h3>

                        <div>
                            <BankInformation isLsriBankInfoUpdate={isLsriBankInfoUpdate} lsri={lsri} manageDirectDeposit={manageDirectDeposit} />
                        </div>
                    </Card.Body>
                </Card>
            </div>}
        </>

        }
    </>);
};

const BankInformation = (props: {
    isLsriBankInfoUpdate: boolean | undefined,
    lsri: ILsriState,
    manageDirectDeposit: IManageDirectDepositState
}): React.ReactElement => {

    const bankInfoEntity: BankAccount = {
        bankName: '',
        routingNumber: '',
        accountNumber: '',
        bankOwnership: '',
        originalRoutingNumber: '',
        originalAccountNumber: '',
        paymentType: '',
        accountType:''
    };


    const { isLsriBankInfoUpdate, lsri, manageDirectDeposit } = props;

    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const [bankInfo, setBankInfo] = useState(isLsriBankInfoUpdate ? lsri.lsriInfo?.bankAccount : bankInfoEntity);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isFirstRender, setIsFirstRender] = useState(true)
    useEffect(() => { setIsFirstRender(false) }, [])

    const isManage = isLsriBankInfoUpdate ? lsri.wizardType === LsriStage.Manage : false;
    const hasClaim_Update = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === BankInfo.Update)
    const hasClaim_View = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === BankInfo.View)

    const showSuccessMsg = isLsriBankInfoUpdate ? lsri.updateLoadState === DataLoadState.Loaded && isManage : manageDirectDeposit.updateLoadState === DataLoadState.Loaded;

    const showErrorMsg = isLsriBankInfoUpdate ? lsri.updateLoadState === DataLoadState.Error : manageDirectDeposit.updateLoadState === DataLoadState.Error;

    const checkedMenuOptions = lsri.menuOptions.checkedMenuOptions
    const bankAcctValidationMsg = isLsriBankInfoUpdate ? lsri.bankAcctValidationMsg : manageDirectDeposit.bankAcctValidationMsg;

    const [enableContinue, setEnableContinue] = useState(true);

    const submitIsLoading = isSubmitting || (isLsriBankInfoUpdate ? lsri.updateLoadState === DataLoadState.Loading : manageDirectDeposit.updateLoadState === DataLoadState.Loading);


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "BankInfo"
    const cmsIsLoading = isLsriBankInfoUpdate
        ? cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0
        : cms.updateBankInfo ? cms.updateBankInfo?.fragments?.length < 0 : true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        dispatch(cmsActionCreators.fetchUpdateBankInfoCmsData());

        if (isLsriBankInfoUpdate) {
            dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headerMsgCms = isLsriBankInfoUpdate
        ? cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? ''
        : cms.updateBankInfo.fragments?.find(f => f.name === "HeaderMsg")?.html ?? '';
    const headerMsgManageCms = isLsriBankInfoUpdate
        ? cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsgManage`)?.html ?? ''
        : '';
    const acknowledgeMsgCms = isLsriBankInfoUpdate
        ? "I acknowlege that the above information is correct. I acknowledge that distributions will be released upon further verification as required by the administrator."
        : cms.updateBankInfo.fragments?.find(f => f.name === "AcknowledgeMsg")?.html ?? '';
    const successMsg = cms.updateBankInfo.fragments?.find(f => f.name === "SuccessMsg")?.html ?? '';

    //End CMS Section

    //Error validation

    const [showBankNameErr, setShowBankNameErr] = useState(false);
    function isBankNameValid(val: string | undefined, allowBlank?: boolean) {
        const isValid = val !== undefined && (allowBlank ? true : val !== '')
        setShowBankNameErr(!isValid);
        return isValid;
    }

    const [showAccountTypeErr, setShowAccountTypeErr] = useState(false);
    function isAccountTypeValid(val: BankAccountType | undefined) {
        const isValid = val !== undefined
        setShowAccountTypeErr(!isValid);
        return isValid;
    }

    const [showRoutingNumErr, setShowRoutingNumErr] = useState(false);
    function isRoutingNumValid(val: string | undefined, skipABACheck?: boolean, allowBlank?: boolean) {
        const isValid = val !== undefined && (allowBlank ? true : val !== '') && (skipABACheck ? true : checkABA(val))
        setShowRoutingNumErr(!isValid);
        return isValid;
    }

    const [showAccountNumErr, setShowAccountNumErr] = useState(false);
    function isAccountnumValid(val: string | undefined, allowBlank?: boolean) {
        const isValid = val !== undefined && (allowBlank ? true : (val !== '' && val.length > 3))
        setShowAccountNumErr(!isValid);
        return isValid;
    }

    //Handle submitting if bankAcctValidation is successful
    useEffect(() => {
        if (isSubmitting) {
            if (isLsriBankInfoUpdate && lsri.bankAcctValidationMsg !== undefined) {
                if (lsri.bankAcctValidationMsg?.toLowerCase() === "success") {

                    if (!isManage) {
                        dispatch(LsriActionCreators.addCheckedMenuOptions(GatewayPath.LsriBankInfo))
                        dispatch(LsriActionCreators.enableVerifyCheck())

                        //Only advance to verify if all menu options are checked, otherwise go to the unchecked menu option
                        if (checkedMenuOptions?.includes(GatewayPath.LsriPaymentOptions) && checkedMenuOptions?.includes(GatewayPath.LsriTaxOptions))
                            dispatch(push(GatewayPath.LsriVerify));
                        else if (!checkedMenuOptions?.includes(GatewayPath.LsriTaxOptions))
                            dispatch(push(GatewayPath.LsriTaxOptions));
                    }
                    else {
                        dispatch(LsriActionCreators.submitBankInfo({
                            bankAccount: {
                                ...bankInfo,
                                bankOwnership: lsri.lsriInfo.bankAccount.bankOwnership
                            }
                        }));
                    }
                }
            }
            else {
                if (manageDirectDeposit.bankAcctValidationMsg !== undefined) {
                    if (manageDirectDeposit.bankAcctValidationMsg?.toLowerCase() === "success") {
                        dispatch(manageDirectDepositActionCreators.updateBankInfo({
                            bankAccount: {
                                ...bankInfo,
                                bankOwnership: manageDirectDeposit.bankOwnership
                            }
                        }));
                    }
                }
            }

            if (isLsriBankInfoUpdate ? lsri.bankAcctValidationMsg !== undefined : manageDirectDeposit.bankAcctValidationMsg !== undefined) {
                setIsSubmitting(false)

                if (isLsriBankInfoUpdate ? lsri.denyCount === 3 : manageDirectDeposit.denyCount === 3) {
                    dispatch(accountActionCreators.accessDenied());
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.bankAcctValidationMsg, manageDirectDeposit.bankAcctValidationMsg])


    //Hide the success message
    useEffect(() => {
        if (isLsriBankInfoUpdate) {
            if (lsri.updateLoadState === DataLoadState.Loaded)
                setTimeout(() => { dispatch(LsriActionCreators.resetUpdateLoadState()) }, 3000);
        }
        else {
            if (manageDirectDeposit.updateLoadState === DataLoadState.Loaded) {
                dispatch(push(GatewayPath.ManageDirectDeposit));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.updateLoadState, manageDirectDeposit.updateLoadState]);


    useEffect(() => {
        if (isLsriBankInfoUpdate) {
            dispatch(LsriActionCreators.resetUpdateLoadState())
        }
        else {
            dispatch(manageDirectDepositActionCreators.resetUpdateLoadState())
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const SuccessMsg = () => (
        <Alert variant="success" className="mt-5 fade-out">
            <FontAwesomeIcon icon={faCheckCircle} /><strong>Success</strong><br />
            {successMsg}
        </Alert>
    )

    const ErrorMsg = () => (
        <Alert variant="error" className="mt-5">
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while updating your Bank Information.
        </Alert>
    )

    const BankAcctErrorMsg = () => (
        <Alert variant="warning" className="mt-5">
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> {bankAcctValidationMsg}
        </Alert>
    )

    const AcknowledgeMsg = () => (
        <div className="mt-4">
            {acknowledgeMsgCms}
        </div>
    )

    const isInlineErrors = () => {
        let isErr = false

        //Check for errors so that they all display
        isErr = !isBankNameValid(bankInfo?.bankName) || isErr;
        isErr = !isAccountTypeValid(bankInfo?.bankAccountType) || isErr;
        isErr = !isRoutingNumValid(bankInfo?.routingNumber) || isErr;
        isErr = !isAccountnumValid(bankInfo?.accountNumber) || isErr;

        return isErr;
    }

    useEffect(() => {
        setEnableContinue(!showBankNameErr && !showAccountTypeErr && !showRoutingNumErr && !showAccountNumErr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBankNameErr, showAccountTypeErr, showRoutingNumErr, showAccountNumErr])

    useEffect(() => {
        if (!isFirstRender && isLsriBankInfoUpdate) {

            //If any changes are performed, save the election to state and remove the check mark so that it needs to be re-verified
            dispatch(LsriActionCreators.updateBankInfo(bankInfo));
            dispatch(LsriActionCreators.removeCheckedMenuOptions(GatewayPath.LsriBankInfo))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankInfo])


    //Action handling
    const handleClickCancel = () => {
        if (isLsriBankInfoUpdate)
            dispatch(LsriActionCreators.wizardCancel());
        else
            dispatch(push(GatewayPath.ManageDirectDeposit));
    }

    const handleClickContinue = () => {
        if (isInlineErrors())
            return;

        //Set this and then validate routing number. Submit action will be handled by useEffect below
        setIsSubmitting(true)
        isLsriBankInfoUpdate ? dispatch(LsriActionCreators.validateBankInfo({ bankAccount: bankInfo })) : dispatch(manageDirectDepositActionCreators.validateBankInfo({ bankAccount: bankInfo }));
    }

    const handleBankNameChange = (bankName: string, allowBlank: boolean) => {
        bankName = removeInvalidChars_BankName(bankName)

        //Only update if it is different, not just on a blur
        if (bankName !== bankInfo.bankName && isBankNameValid(bankName, allowBlank))
            setBankInfo({ ...bankInfo, bankName } as BankAccount)
    }

    const handleRadioBtnChange = (bankAccountType: BankAccountType) => {
        if (isAccountTypeValid(bankAccountType))
            setBankInfo({ ...bankInfo, bankAccountType } as BankAccount)
    }

    const handleRoutingNumChange = (routingNumber: string, skipABACheck: boolean, allowBlank: boolean) => {
        routingNumber = removeInvalidChars_RoutingNumber(routingNumber)

        //Only update if it is different, not just on a blur
        if (routingNumber !== bankInfo.routingNumber && isRoutingNumValid(routingNumber, skipABACheck, allowBlank))
            setBankInfo({ ...bankInfo, routingNumber } as BankAccount)
    }

    const handleAccountNumChange = (accountNumber: string, allowBlank: boolean) => {
        accountNumber = removeInvalidChars_BankAcctNumber(accountNumber)

        const valueWasPasted = accountNumber.length - 1 > bankInfo.accountNumber.length

        //Only update if it is different, not just on a blur
        if (!valueWasPasted && accountNumber !== bankInfo.accountNumber && isAccountnumValid(accountNumber, allowBlank))
            setBankInfo({ ...bankInfo, accountNumber } as BankAccount)
    }


    return (<>
        {cmsIsLoading ? <LoadingSkeleton /> : <>
            <p dangerouslySetInnerHTML={{ __html: isManage ? headerMsgManageCms : headerMsgCms }} />

            {hasClaim_View && <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();

                if (enableContinue)
                    handleClickContinue();
            }}>

                <Form.Group className={`mt-5 ${showBankNameErr ? "error" : ""}`} style={{ maxWidth: `${isSmallScreen ? 'initial' : '16rem'}` }}>
                    <Form.Label><strong>Bank Name</strong></Form.Label>
                    <Form.Control
                        type="text"
                        id={`bankName`}
                        name={`bankName`}
                        value={bankInfo?.bankName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBankNameChange(e.target.value, true)}
                        maxLength={100}
                        tabIndex={1}
                        autoFocus
                    />
                    {showBankNameErr && <div className="errMsg">Invalid Bank Name</div>}
                    <div className="footnote">Only financial institutions based in the United States are permitted.</div>
                </Form.Group>


                <Form.Group className={`mt-4 ${showAccountTypeErr ? "error" : ""}`}>
                    <Form.Label><strong>Account Type</strong></Form.Label>
                    <Form.Check className="mr-1 mt-1">
                        <Form.Label>
                            <Form.Check.Input
                                type="radio"
                                name="accountTypeSelection"
                                value="Checking"
                                checked={bankInfo?.bankAccountType === BankAccountType.Checking}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleRadioBtnChange(BankAccountType.Checking) }}
                                tabIndex={1}
                            />
                            <div>Checking</div>
                        </Form.Label>
                    </Form.Check>

                    <Form.Check className="mr-1 mt-1">
                        <Form.Label>
                            <Form.Check.Input
                                type="radio"
                                name="accountTypeSelection"
                                value="Savings"
                                checked={bankInfo?.bankAccountType === BankAccountType.Savings}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleRadioBtnChange(BankAccountType.Savings) }}
                                tabIndex={2}
                            />
                            <div>Savings</div>
                        </Form.Label>
                    </Form.Check>
                    {showAccountTypeErr && <div className="errMsg mt-1">Invalid Account Type</div>}
                </Form.Group>

                <Row>
                    <Col className="col-12 col-lg-5">
                        <Form.Group className={`mt-4 ${showRoutingNumErr ? "error" : ""}`} style={{ maxWidth: `${isSmallScreen ? 'initial' : '20rem'}` }}>
                            <Form.Label><strong>Routing Number (ABA)</strong></Form.Label>
                            <Form.Control
                                type="text"
                                id={`routingNumber`}
                                name={`routingNumber`}
                                value={bankInfo?.routingNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRoutingNumChange(e.target.value, true, true)}
                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleRoutingNumChange(e.target.value, false, false)}
                                maxLength={9}
                                tabIndex={3}
                            />
                            {showRoutingNumErr && <div className="errMsg">Invalid Routing Number</div>}
                        </Form.Group>

                        <Form.Group className={`mt-4 ${showAccountNumErr ? "error" : ""}`} style={{ maxWidth: `${isSmallScreen ? 'initial' : '20rem'}` }}>
                            <Form.Label><strong>Account Number</strong></Form.Label>
                            <Form.Control
                                type="text"
                                id={`accountNumber`}
                                name={`accountNumber`}
                                value={bankInfo?.accountNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAccountNumChange(e.target.value, true)}
                                maxLength={100}
                                tabIndex={4}
                            />
                            {showAccountNumErr && <div className="errMsg">Invalid Account Number</div>}
                        </Form.Group>
                    </Col>
                    <Col className="mt-2 mt-lg-4 pt-lg-3">
                        <img alt="Example check showing routing number and account number on the bottom of the check."
                            style={{ width: '100%', maxWidth: '325px', height: 'auto' }} src={routingLogo} />
                    </Col>
                </Row>

                {showSuccessMsg && <SuccessMsg />}

                {showErrorMsg && <ErrorMsg />}

                {bankAcctValidationMsg && <BankAcctErrorMsg />}

                <div className="mt-4 pt-3">

                    {(isManage ? hasClaim_Update : true) && <>
                        <Button variant="primary" tabIndex={5} className={`${isSmallScreen ? 'w-100' : ''}`} disabled={!enableContinue || (submitIsLoading)} onClick={() => handleClickContinue()}>
                            {submitIsLoading && !showErrorMsg ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : <>{isLsriBankInfoUpdate ? 'Agree & Submit' : 'Continue'}</>}
                        </Button>

                        {!isSmallScreen && <span className="ml-4 mr-3">|</span>}
                    </>}

                    <Button variant="link px-0 mx-0" tabIndex={6} className={`${isSmallScreen ? 'w-100 mt-3' : ''}`} disabled={(submitIsLoading)} onClick={() => handleClickCancel()}>
                        Cancel
                    </Button>
                    <AcknowledgeMsg />
                </div>

            </Form>}
        </>}
    </>)
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        manageDirectDeposit: state.manageDirectDeposit,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(UpdateBankInformation);

