import { AppState } from "./configureStore";
import { Phone, GetAuthPhonesResponse, GetAuthPhones, PhoneType, SendAuthCode, AuthentifyDeliveryMethod, SendAuthCodeResponse, ValidateCode, ValidateCodeResponse, ChallengeType, GetAuthStatus, LsriStage } from "./../Gateway.dtos";
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GatewayPath } from '@wespath/gateway-navigation'
import { push } from "connected-react-router";
import { findPhoneByType } from "../functions/findPhoneByType";
import { formatPhone } from "../functions/formatPhone";
import { loginHelpCreators } from "./LoginHelpStore";
import { accountActionCreators } from "./AccountStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { usernameActionCreators } from "./accountManagement/UsernameStore";
import { contactInfoActionCreators } from "./accountManagement/ContactInfoStore";
import { passwordActionCreators } from "./accountManagement/PasswordStore";
import { titleCase } from "../functions/titleCase";
import { activityActionCreators } from "./ActivityStore";
import { LsriActionCreators } from "./LsriStore";

interface Error {
    errorMessage: string | unknown
}

export enum CodeState {
    None,
    Sending,
    Resending,
    Sent,
    Validating,
    Invalid,
    Expired
}

export type MultiFactorTransactionType = 'contact' | 'password' | 'username' | 'lsriSetup'

export interface IAuthMultiFactorState extends ILoadableState {
    isChallenging: boolean,
    phones: Phone[],
    phonesLoaded: boolean,
    phoneType: PhoneType,
    deliveryMethod: AuthentifyDeliveryMethod,
    codeState: CodeState,
    lastAttempt: boolean,
    accessDenied: boolean,
    maxCodesSent: boolean,
    selectedNumber: string,
    challengeType: ChallengeType,
    redirect: GatewayPath | undefined,
    page: string,
    hasCompletedMultiFactor: boolean,
    activeTransaction: MultiFactorTransactionType | undefined
}

export const initialAuthMultiFactorState: IAuthMultiFactorState = {
    isChallenging: false,
    phones: {} as Phone[],
    phonesLoaded: false,
    phoneType: {} as PhoneType,
    deliveryMethod: {} as AuthentifyDeliveryMethod,
    codeState: CodeState.None,
    lastAttempt: false,
    isLoading: false,
    isLoaded: false,
    accessDenied: false,
    maxCodesSent: false,
    selectedNumber: "",
    challengeType: ChallengeType.Login,
    redirect: undefined,
    page: "",
    hasCompletedMultiFactor: false,
    activeTransaction: undefined
};

const fetchTwoFactorStatus = createAsyncThunk<
    boolean,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'authMultiFactor/fetchTwoFactorStatus',
    async (_, thunkApi) => {
        try {

            const authStatus = await client.post(new GetAuthStatus())
            return authStatus.twoFactorComplete;

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)


const slice = createSlice({
    name: 'authMultiFactor',
    initialState: {} as IAuthMultiFactorState,
    reducers: {
        resetState: () => {
            return {
                ...initialAuthMultiFactorState
            }
        },
        setIsChallenging: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                isChallenging: true
            }
        },
        removeIsChallenging: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                isChallenging: false
            }
        },
        resetPhones: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                phones: {} as Phone[], 
                phonesLoaded: false
            }
        },
        requestPhones: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receivePhones: (state: IAuthMultiFactorState, action: PayloadAction<{ phones: GetAuthPhonesResponse }>) => {
            const { phones } = action.payload.phones;
            return {
                ...state,
                phones,
                phonesLoaded: true,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        requestSendCode: (state: IAuthMultiFactorState, action: PayloadAction<{ phoneNumber: string, phoneType: PhoneType, deliveryMethod: AuthentifyDeliveryMethod, codeState: CodeState }>) => {
            const { phoneNumber, phoneType, deliveryMethod, codeState } = action.payload;
            return {
                ...state,
                selectedNumber: phoneNumber,
                phoneType,
                deliveryMethod,
                codeState: codeState,
                isLoading: true
            }
        },
        receiveSendCodeResponse: (state: IAuthMultiFactorState, action: PayloadAction<{ sendCodeResponse: SendAuthCodeResponse }>) => {
            const { accessDenied, maxCodesSent } = action.payload.sendCodeResponse
            return {
                ...state,
                codeState: CodeState.Sent,
                accessDenied,
                maxCodesSent,
                isLoading: false
            }
        },
        requestValidateCode: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                codeState: CodeState.Validating
            }
        },
        receiveValidateCodeResponse: (state: IAuthMultiFactorState, action: PayloadAction<{ validateCodeResponse: ValidateCodeResponse }>) => {
            const { accessDenied, expired, codeCorrect, lastAttempt } = action.payload.validateCodeResponse;
            let codeState = state.codeState;
            if (expired) {
                codeState = CodeState.Expired;
            } else if (!codeCorrect) {
                codeState = CodeState.Invalid;
            }
            return {
                ...state,
                lastAttempt,
                accessDenied,
                codeState
            }
        },
        resetCodeState: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                codeState: CodeState.None,
                phoneType: {} as PhoneType,
                deliveryMethod: {} as AuthentifyDeliveryMethod,
            }
        },
        receivePhonesError: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }   
        },
        setChallengeType: (state: IAuthMultiFactorState, action: PayloadAction<{ challengeType: ChallengeType, redirect?: GatewayPath, transaction?: MultiFactorTransactionType }>) => {
            const { challengeType, redirect, transaction } = action.payload;
            return {
                ...state,
                challengeType,
                redirect,
                activeTransaction: transaction
            }
        },
        beginTransactionChallenge: (state: IAuthMultiFactorState, action: PayloadAction<{transaction: MultiFactorTransactionType }>) => {
            const {  transaction } = action.payload;
            return {
                ...state,
                challengeType: ChallengeType.Transaction,
                isChallenging: true,
                activeTransaction: transaction
            }
        },
        setChallengeComplete: (state: IAuthMultiFactorState) => {
            return {
                ...state,
                hasCompletedMultiFactor: true,
                isChallenging: false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialAuthMultiFactorState)
            .addCase(fetchTwoFactorStatus.fulfilled, (state: IAuthMultiFactorState, { payload }) => {
                state.hasCompletedMultiFactor = payload;
            })
    }
})

const {
    resetState,
    setIsChallenging,
    removeIsChallenging,
    setChallengeComplete,
    resetPhones,
    requestPhones,
    receivePhones,
    receivePhonesError,
    requestSendCode,
    receiveSendCodeResponse,
    requestValidateCode,
    receiveValidateCodeResponse,
    resetCodeState,
    beginTransactionChallenge
} = slice.actions;

function fetchAuthPhones() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().authMultiFactor
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestPhones());

        try {

            const phones = await client.post(new GetAuthPhones());

            dispatch(receivePhones({ phones }));

        } catch (e: unknown) {
            dispatch(receivePhonesError());
            console.log(e);
        }

    }
}

function sendCode(phoneType?: PhoneType, deliveryMethod?: AuthentifyDeliveryMethod ) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { codeState, phones, challengeType, activeTransaction } = getState().authMultiFactor
        if (codeState === CodeState.Sending) {
            return;
        }

        const newCodeState = (codeState === CodeState.Sent ||
            codeState === CodeState.Validating ||
            codeState === CodeState.Invalid ||
            codeState === CodeState.Expired) ? CodeState.Resending : CodeState.Sending;

        let transType = "";
        if (activeTransaction === "contact")
            transType = "Update Contact Information"
        else if (activeTransaction === "password" || activeTransaction === "username")
            transType = "Update " + titleCase(activeTransaction);
        else
            transType = getState().authMultiFactor.page;


        if (!phoneType)
            phoneType = getState().authMultiFactor.phoneType;
        if (!deliveryMethod)
            deliveryMethod = getState().authMultiFactor.deliveryMethod;
        
        const phoneNumber = findPhoneByType(phones, phoneType);
        const formattedNumber = formatPhone(phoneNumber.number, !phoneNumber.isForeign ? 'US' : 'NA', true, true);


        dispatch(requestSendCode({ phoneNumber: formattedNumber, phoneType, deliveryMethod, codeState: newCodeState }));

        try {

            const sendCodeResponse = await client.post(new SendAuthCode({
                phoneType,
                deliveryMethod,
                challengeType,
                page: transType
            }));

            //console.log(sendCodeResponse);
            dispatch(receiveSendCodeResponse({ sendCodeResponse }));

        } catch (e: unknown) {
            dispatch(receivePhonesError());
            console.log(e);
        }

    }
}

function validateCode(code: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { codeState, challengeType, redirect, activeTransaction } = getState().authMultiFactor
        if (codeState === CodeState.Sending) {
            return;
        }

        dispatch(requestValidateCode());

        try {

            const validateCodeResponse = await client.post(new ValidateCode({
                code
            }))

            if (validateCodeResponse.codeCorrect) {
                dispatch(accountActionCreators.setIsLocked({ isLocked: false }));
                dispatch(removeIsChallenging());

                if (challengeType === ChallengeType.LoginHelp) {
                    dispatch(loginHelpCreators.nextStep());
                } else if (challengeType === ChallengeType.Transaction) {
                    dispatch(setChallengeComplete())

                    if (activeTransaction === 'username') {
                        dispatch(usernameActionCreators.beginEditing());
                    } else if (activeTransaction === 'contact') {
                        dispatch(contactInfoActionCreators.beginEditing());
                    } else if (activeTransaction === 'password') {
                        dispatch(passwordActionCreators.beginEditing());
                    } else if (activeTransaction === 'lsriSetup') {
                        dispatch(LsriActionCreators.setWizardType(LsriStage.Commit))
                        dispatch(push(GatewayPath.LsriPaymentOptions))
                    }
                }
                else {
                    const pushTo = redirect ? redirect : GatewayPath.BenefitsSummary;
                    dispatch(resetState());
                    dispatch(setChallengeComplete())
                    dispatch(push(pushTo));
                }
            } else {
                dispatch(receiveValidateCodeResponse({ validateCodeResponse }));
            }

        } catch (e: unknown) {
            dispatch(receivePhonesError());
            console.log(e);
        }

    }
}

function cancelMultiFactor() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { codeState } = getState().authMultiFactor
        if (codeState === CodeState.Sending) {
            return;
        }

        dispatch(activityActionCreators.logActivity("CancelMultiFactor"));
        dispatch(accountActionCreators.setIsLocked({ isLocked: false }));
        dispatch(removeIsChallenging());
    }
}

function backToSelectPhone() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const {isLoading } = getState().authMultiFactor
        if (isLoading) {
            return;
        }

        dispatch(resetCodeState());
    }
}

function setChallengeType(challengeType: ChallengeType, redirect?: GatewayPath) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        if (challengeType === getState().authMultiFactor.challengeType) {
            return;
        }

        dispatch(slice.actions.setChallengeType({ challengeType, redirect }));
    }
}



export const authMultiFactorActionCreators = {
    setIsChallenging,
    removeIsChallenging,
    fetchAuthPhones,
    sendCode,
    validateCode,
    backToSelectPhone,
    resetState,
    resetPhones,
    setChallengeType,
    beginTransactionChallenge,
    fetchTwoFactorStatus,
    cancelMultiFactor
};

export const authMultiFactorReducer = slice.reducer;
