import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../store/configureStore';
import { AppDispatch } from '..';
import { accountActionCreators } from '../store/AccountStore';
import { Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { unlockActionCreators } from '../store/UnlockStore';
import { Summary } from '../definitions/Claims';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheck } from '@fortawesome/pro-solid-svg-icons';

type TUnlockProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const UnlockBanner = (props: TUnlockProps) => {
    const { unlock, isLoading, isLoaded, hasClaim_LockedBanner, hasClaim_Unlock, performUnlock, page } = props;
    const isErr = unlock?.responseStatus !== undefined;

    const [showSuccessBanner, setShowSuccessBanner] = useState(false);
    const [showLockedBanner, setShowLockedBanner] = useState(props.isLocked);
    const [unlockedOnPage, setUnlockedOnPage] = useState('');

    const clickUnlock = () => {
        performUnlock();
    }

    //Check if unlock was successful and show the success banner
    useEffect(() => {
        if (showLockedBanner && isLoaded && !unlock.responseStatus) {
            setUnlockedOnPage(page);
            setShowSuccessBanner(true);
            setShowLockedBanner(false);
        }
    }, [unlock, setShowSuccessBanner, showLockedBanner, setShowLockedBanner, isLoaded, setUnlockedOnPage, page])


    //Remove the success banner when navigating to new page
    useEffect(() => {
        if (showSuccessBanner && page !== unlockedOnPage)
            setShowSuccessBanner(false);
    }, [page, unlockedOnPage, showSuccessBanner, setShowSuccessBanner])


    return (<>
        {hasClaim_LockedBanner && (showLockedBanner || showSuccessBanner) && <div className={`utilityBar ${showSuccessBanner ? 'success' : 'error'}`}>
            <div className="container maxWidth">

                {isErr && <Alert variant="error">Unable to unlock participant at this time.</Alert>}

                <Row>
                    <Col className="col-12 col-md-8 mt-2">
                        <h1>
                            <FontAwesomeIcon icon={showSuccessBanner ? faCheck : faLock} className="mr-2" />

                            {showSuccessBanner ? <>
                                Success. {props.name}&apos;s account is unlocked.
                            </> : <>
                                    {props.name}&apos;s account is locked.
                            </>}
                        </h1>
                    </Col>
                    <Col className="mt-4 mt-md-0 col-12 col-md-4 text-md-right">
                        {hasClaim_Unlock && !showSuccessBanner &&
                            <Button variant="primary" type="submit" tabIndex={2} onClick={() => { clickUnlock(); }} disabled={isLoading || isErr}>
                                {isLoading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : "Unlock"}
                            </Button>
                        }
                    </Col>
                </Row>
            </div>
        </div>}
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        hasClaim_LockedBanner: state.claims.claims?.claims?.find(c => c === Summary.LockedBanner),
        hasClaim_Unlock: state.claims.claims?.claims?.find(c => c === Summary.Unlock),
        name: state.account.profileName,
        isLocked: state.account.isLocked,
        page: state.router.location.pathname,
        ...state.unlock
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...unlockActionCreators, ...accountActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnlockBanner);
