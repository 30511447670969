import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { monthlyBenefitActionCreators } from '../../../store/MonthlyBenefitStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AmountPanel } from '../../AmountPanel';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import { AppDispatch } from '../../..';
import { AmountPanelSmall } from '../../AmountPanelSmall';

export interface OwnProps { isSummary?: boolean }
type TMonthlyBenefitProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const MonthlyBenefit = (props: TMonthlyBenefitProps) => {
    const { monthlyBenefit, isSummary } = props;

    const isLoading = (props.isLoading && !props.isLoaded);
    const isErr = (!isLoading && monthlyBenefit?.responseStatus !== undefined);
    const isQdro = monthlyBenefit?.isQdro;
    const isDcbc = monthlyBenefit?.isDcbc;
    const showCard = !isLoading && (isErr || monthlyBenefit?.label) && !(isSummary && (isQdro || isDcbc));

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchMonthlyBenefit();
        }
    });

    
    if (!showCard) {
        return <></>;
    }
    else if (isSummary) {

        return (
            <AmountPanelSmall
                label={monthlyBenefit?.label ? monthlyBenefit.label : "Accrued Monthly Benefit"}
                balance={monthlyBenefit?.monthlyBenefitAmt}
                minorText={monthlyBenefit?.nextPayDate ? `Next Payment: ${monthlyBenefit.nextPayDate}` : ""}
                footnote={GetFootnoteMsg(monthlyBenefit?.label, monthlyBenefit?.moreBenMsg)}
                isErr={isErr}
            >
                {monthlyBenefit && ShowAnnuityPaymentLink(monthlyBenefit?.label, monthlyBenefit?.moreBenMsg) &&
                    <BenefitsAccessSso to={`/takeaction_retirement_benefits.aspx`} >View payment details</BenefitsAccessSso>
                }
            </AmountPanelSmall>
        );

    }
    else {
        return (<>
            {isQdro || isDcbc ?
                <>
                    <AmountPanel
                        label={monthlyBenefit?.label ? monthlyBenefit.label : "Accrued Monthly Benefit"}
                        isErr={isErr}
                    />
                    {isDcbc && <p className="pt-3 thin">
                        Until your terminated benefit conversion is complete, you cannot view retirement benefit estimates or projections, 
                        or take distributions from CRSP DB, MPP, Pre-82. Please contact Wespath for assistance.
                    </p>}

                    {isQdro && <p className="pt-3 thin">Benefits are subject to a QDRO.</p>}
                </>
                :
                <AmountPanel
                    label={monthlyBenefit?.label ? monthlyBenefit.label : "Accrued Monthly Benefit"}
                    balance={monthlyBenefit?.monthlyBenefitAmt}
                    minorText={monthlyBenefit?.nextPayDate ? `Next Payment: ${monthlyBenefit.nextPayDate}` : ""}
                    footnote={GetFootnoteMsg(monthlyBenefit?.label, monthlyBenefit?.moreBenMsg)}
                    isErr={isErr}
                >
                    {monthlyBenefit && ShowAnnuityPaymentLink(monthlyBenefit?.label, monthlyBenefit?.moreBenMsg) &&
                        <BenefitsAccessSso to={`/takeaction_retirement_benefits.aspx`} >View payment details</BenefitsAccessSso>
                    }
                </AmountPanel>
            }


        </>);

    }

}

function mapStateToProps(state: AppState, ownProps: OwnProps) {

    return {
        ...state.monthlyBenefit,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...monthlyBenefitActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlyBenefit);


function GetFootnoteMsg(label: string, display?: boolean): string {
    if (label === "Accrued Monthly Benefit")
        return "Accrued defined benefit payable at normal retirement with credited service earned to date.";
    else if (label === "Annuity Payment" && display)
        return "You may have additional benefits that have not been setup for distribution yet.";
    else
        return "";
}
export { GetFootnoteMsg }

function ShowAnnuityPaymentLink(label: string, display: boolean): boolean {

    return (display && label === "Annuity Payment");
}
export { ShowAnnuityPaymentLink }