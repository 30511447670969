import { AppState } from "./configureStore";
import { GetSettingUpRetirementIncomeResponse, GetSettingUpRetirementIncome } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

interface Error {
    errorMessage: string | unknown
}

export interface ISettingUpRetirementIncomeState extends IEnumLoadableState {
    settingUpRetirementIncome: GetSettingUpRetirementIncomeResponse
}

export const initialSettingUpRetirementIncome: ISettingUpRetirementIncomeState = {
    settingUpRetirementIncome: {} as GetSettingUpRetirementIncomeResponse,
    loadState: DataLoadState.None
};

const fetchSettingUpRetirementIncome = createAsyncThunk<
    GetSettingUpRetirementIncomeResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'settingUpRetirementIncome/fetchSettingUpRetirementIncome',
    async (_, thunkApi) => {
        try {

            const settingUpRetirementIncome = await client.post(new GetSettingUpRetirementIncome());

            return settingUpRetirementIncome;

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const slice = createSlice({
    name: 'settingUpRetirementIncome',
    initialState: initialSettingUpRetirementIncome,
    reducers: {
        resetState: () => initialSettingUpRetirementIncome
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSettingUpRetirementIncome.pending, (state: ISettingUpRetirementIncomeState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchSettingUpRetirementIncome.fulfilled, (state: ISettingUpRetirementIncomeState, { payload }) => {
                state.loadState = DataLoadState.Loaded
                state.settingUpRetirementIncome = payload;
            })
            .addCase(fetchSettingUpRetirementIncome.rejected, (state: ISettingUpRetirementIncomeState, action) => {
                state.loadState = DataLoadState.Error;
            })
            .addCase(getResetAction(), (_state, _action) => initialSettingUpRetirementIncome)
    }
})

const { resetState } = slice.actions;

export const settingUpRetirementIncomeActionCreators = {
    fetchSettingUpRetirementIncome,
    resetState
};

export const settingUpRetirementIncomeReducer = slice.reducer;