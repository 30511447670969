import React, { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Health } from '../../../definitions/Claims';

type TLearnAbout2DesktopProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const LearnAbout2Desktop = (props: TLearnAbout2DesktopProps, hasHSA: boolean) => {

    const jvLink = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.jvLink ?? "")

    const cmsData = props.cmsData?.fragments?.find(f => f.name === "LearnAbout2")?.html.replace("$alexLink", jvLink) ?? '';

    const handleSSO = () => {

        window.open(jvLink, "_blank");
    }

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (<>

        <Card className="pageCard secondary desktop">
            <Card.Title className='desktop'>
                &nbsp;
            </Card.Title>
            <Card.Body style={{ paddingBottom: '0px' }} className='desktop'>

                {!cmsData ? <LoadingSkeleton /> : <><br />

                    {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData }} /> : null}

                    <Button className='ml-3'
                        variant="secondary"
                        onClick={handleSSO}
                    >
                        Get Started with ALEX
                    </Button>

                </>}

            </Card.Body>
        </Card>

    </>);
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        username: state.account.username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LearnAbout2Desktop);
