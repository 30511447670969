import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Card, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCheck } from '@fortawesome/pro-solid-svg-icons';
import ReactSwitch from 'react-switch';
import { deliveryPreferencesActionCreators } from '../../../store/accountManagement/DeliveryPreferencesStore';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../..';
import { DeliveryPreferences } from '../../../definitions/Claims';
import { activityActionCreators } from '../../../store/ActivityStore';
import { cmsActionCreators } from '../../../store/CmsStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import AccountManagement from '../../pages/AccountManagement';

type TDeliveryPreferenceProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const DeliveryPreference = (props: TDeliveryPreferenceProps) => {
    const dispatch: AppDispatch = useDispatch();
    const isLoading = (props.dataIsLoading || !props.dataIsLoaded) && !props.dataIsErr;
    const isErr = props.dataIsErr;
    const isElecDeliv = props.isElecDeliv === "y";

    const cmsDataEmail = props.cmsData.fragments?.find(f => f.name === "EmailMsg")?.html ?? "";
    const cmsDataMail = props.cmsData.fragments?.find(f => f.name === "MailMsg")?.html ?? "";

    const mailMsg = isElecDeliv ? cmsDataEmail : cmsDataMail

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const { showSuccessMsg, hideSuccessMsg } = props;
    useEffect(() => {
        if (showSuccessMsg)
            setTimeout(() => { hideSuccessMsg(); }, 3000);
    }, [showSuccessMsg, hideSuccessMsg]);

    if (!props.hasClaim_View) {
        return <></>;
    }

    return (
        <Card className="pageCard secondary">
            <Card.Title><FontAwesomeIcon icon={faEnvelope} className="acctmgmt-color" />Delivery Preferences</Card.Title>
            <Card.Body>

                {isLoading &&
                    <LoadingSkeleton />
                }

                {props.error &&
                    <Alert className="mt-2" variant="danger">Unable to update Delivery Preferences</Alert>
                }

                {isErr &&
                    <Alert className="mt-2" variant="danger">Unable to retrieve data for Delivery Preferences</Alert>
                }

                {props.showSuccessMsg && <Alert className="mt-2 fade-out" variant="success">
                    <FontAwesomeIcon icon={faCheck} /><strong>Success.</strong> Your Delivery Preferences has been updated.
                </Alert>}

                {!isLoading && !isErr && <> 
                    
                    <p dangerouslySetInnerHTML={{ __html: mailMsg }} />

                    {props.hasClaim_Update && <label className="hideForPrint">
                        <span className={`${isElecDeliv ? "" : "switch-disabled"} textStyle thin`}>Email</span>
                        {/*https://www.npmjs.com/package/react-switch*/}
                        <ReactSwitch
                            checked={!isElecDeliv}
                            onChange={() => { dispatch(activityActionCreators.logActivity(`toggle_elecDeliv_setTo_${!isElecDeliv}`)); props.updateElecDeliv(!isElecDeliv); }}
                            uncheckedIcon={<div className="switchText left email">EMAIL ON</div>}
                            checkedIcon={<div className="switchText right email">MAIL ON</div>}
                            activeBoxShadow='0 0 2px 3px #007bff'
                            className={`react-switch px-3 ${isElecDeliv ? '' : 'react-switch-off'}`}
                            disabled={isLoading || isErr || props.isLoading || props.error}
                            width={100}
                            id="icon-switch"
                        />
                        <span className={`${!isElecDeliv ? "" : "switch-disabled"} textStyle thin`}>US Mail</span>
                    </label>}
                </>}
    
            </Card.Body>
        </Card>

    );
}

function mapStateToProps(state: AppState ) {
    return {
        ...state.deliveryPreferences,
        dataIsLoading: state.personalInfo.isLoading,
        dataIsLoaded: state.personalInfo.isLoaded,
        dataIsErr: state.personalInfo.error,
        hasClaim_View: state.claims.claims?.claims?.find(c => c === DeliveryPreferences.View),
        hasClaim_Update: state.claims.claims?.claims?.find(c => c === DeliveryPreferences.Update),
        cmsData: state.cms.accountmanagement
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...deliveryPreferencesActionCreators,
        fetchCmsData: cmsActionCreators.fetchAccountManagementCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryPreference);

const LoadingSkeleton = () => (
    <div className="col-12 pl-0">
        <div className="mt-2"><Skeleton width={'20em'} /></div>
        <div><Skeleton width={'15em'} height={'2em'} /></div>
    </div>
);