import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { FederalWithholdingOption, LsriPaymentOption, LsriStage, Pre2022FedMaritalStatus } from '../../../Gateway.dtos';
import { ReviewPanel, ReviewPanelProps, ReviewPanelRecord } from '../../ReviewPanel';
import { formatMoney, formatMoneyString } from '../../../functions/formatMoney';
import { Button, Col, Row } from 'react-bootstrap';
import { BankInfo, TaxInfo } from '../../../definitions/Claims';
import parse from "date-fns/parse";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import { titleCase } from '../../../functions/titleCase';
import { balanceActionCreators } from '../../../store/BalanceStore';
import { GatewayPath } from '@wespath/gateway-navigation';
import { NavLink } from 'react-router-dom';
import { convertStringToNumber } from '../../../functions/convertStringToNumber';
import { getFedMarritalStatus } from '../../../functions/getFedMarritalStatus';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriReviewPanels = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();

    const { lsri, balance } = props;
    const { bankAccount, taxOptions } = lsri.lsriInfo
    const userElection = lsri?.userElection;
    const isManage = lsri.wizardType === LsriStage.Manage;
    const isSetup = lsri.wizardType === LsriStage.Setup;
    const isPending = lsri.wizardType === LsriStage.Pending;
    const isQlacOnly = (lsri.wizardType === LsriStage.Manage && lsri?.lsriInfo?.messages?.find(m => m.type?.toLowerCase() === "qlaconly"));

    const hasClaim_View_TaxInfo = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === TaxInfo.View)
    const hasClaim_View_BankInfo = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === BankInfo.View)

    const scenario = lsri?.userElection?.scenario ?? ""

    const bridgeIsSelected = scenario.includes("B");
    const qlacIsSelected = scenario.includes("Q");
    const includeMpp = lsri.userElection?.includeMpp

    const payment = lsri.lsriInfo?.oracleLsriElection?.paymentOptions?.find(p => p.elected === true) ?? new LsriPaymentOption()
    const lsriStartDate = payment?.date ?? lsri.lsriInfo?.oracleLsriElection?.matrixCommon?.lsriCommDate ?? ""
    const monthlyPayment = formatMoney(lsri.userElection?.lsriLifestage ?? 0, true)
    const commitDate = lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.commitDate
    const commitDateStr = isPending && commitDate ? format(parse(commitDate, "MM/dd/yyyy hh:mm:ss aa", new Date()), "MM/dd/yyyy") : undefined
    const asOfDate = isPending ? commitDateStr : (balance?.balance?.asOfDate ?? format(new Date(), "MM/dd/yyyy"))
    const fundingAmt = lsri.userElection?.amtAllocated ?? 0

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });

    useEffect(() => {
        if (stateNeedsLoading(balance))
            dispatch(balanceActionCreators.fetchBalance());
    });

    function GetHeaderLink(redirectPath: GatewayPath): JSX.Element | null {
        return (
            <>
                <span>{<div className="mx-2" style={{ float: "left" }}>|</div>}{<b><NavLink to={redirectPath} style={{ color: "#0063A7" }} activeClassName="active">Edit</NavLink></b>}</span>
            </>
        );
    }
    function GetEstPaymentPanelProps(): ReviewPanelProps | null {

        if (isManage) {
            return {
                heading: 'Next Monthly Payment',
                value: `${monthlyPayment} on ${lsriStartDate}`,
                text: `Your payment will be reviewed and adjusted at the beginning of each calendar year, or sooner if you make a transfer into or out of your LifeStage account.`
            } as ReviewPanelProps
        }

        return {
            heading: "Estimated Initial Monthly Payment",
            value: `${monthlyPayment} ${isSetup ? '' : `starting on ${lsriStartDate}`}`,
            text: `Your actual monthly payment amount will be calculated on the processing date. It may differ from the amount shown due to market fluctuations or other account activity.`
        } as ReviewPanelProps
    }

    function GetMppReviewPanelProps(): ReviewPanelProps | null {

        if (lsri.mppIneligible)
            return null

        return {
            heading: "MPP 65% Options",
            value: includeMpp ? 'Included in LifeStage' : 'Keep as an annuity',
            text: includeMpp ? 'MPP 65% will be included in your LifeStage Retirement Income.' : 'MPP 65% will not be included in your LifeStage Retirement Income. You will need to set up an annuity for it separately.'
        } as ReviewPanelProps
    }

    function GetFundingReviewPanelProps(): ReviewPanelProps | null {

        if (isManage) {
            return {
                heading: "LifeStage Retirement Income Account",
                value: `${formatMoney(fundingAmt, true)} as of ${asOfDate}`,
                text: <div className="my-3">{`You can `}
                    {<b><NavLink to={GatewayPath.LsriFunding} style={{ color: "#0063A7" }} activeClassName="active">add or remove money</NavLink></b>}
                    {` from LifeStage Retirement Income. Please keep in mind changing the funding amount may result in a different monthly payment.`}
                </div>
            } as ReviewPanelProps
        }
        else {
            if (!userElection?.pctAllocated)
                return null

            return {
                heading: "Funding LifeStage",
                value: `${Math.round(userElection.pctAllocated)}% (${formatMoney(fundingAmt, true)} as of ${asOfDate})`,
                text: 'The actual amount will be calculated on the processing date. It may differ from the amount shown due to market fluctuations or other account activity.'
            } as ReviewPanelProps
        }
    }

    function GetBridgeReviewPanelProps(): ReviewPanelProps | null {

        const bridgeEndDate = lsri.lsriInfo?.oracleLsriElection?.systemDefault?.bridgeEndDate ?? undefined
        const bridgeEndDateDisplay = bridgeEndDate ? format(new Date(bridgeEndDate), "MMMM, yyyy") : "";
        const pastBridgeEndDate = bridgeEndDate ? isAfter(new Date(), parse(bridgeEndDate, "MM/dd/yyyy", new Date())) : false

        const textValue = isManage
            ? `LifeStage Retirement Income will replace your Social Security income until your selected starting age. Your last bridge payment will be in ${bridgeEndDateDisplay}.`
            : `LifeStage Retirement Income will replace your Social Security income until your selected starting age.`

        if (isManage && (!bridgeIsSelected || pastBridgeEndDate))
            return null

        return {
            heading: "Bridge to Social Security",
            value: bridgeIsSelected ? `Bridge to age ${lsri.userElection?.ssAge}` : 'No bridge',
            text: bridgeIsSelected ? textValue : ''
        } as ReviewPanelProps
    }

    function GetQLACReviewPanelProps(): ReviewPanelProps | null {

        const qlacMonthlyPayment = formatMoney(lsri.userElection?.qlacQlac ?? 0, true)
        const qlacDate = lsri.systemElection?.qlacDate
        const caMsg = lsri.lsriInfo?.caDesignated ? `${titleCase(lsri.lsriInfo?.personalInfo?.spouseName)} is eligible to receive 70% of this amount as your survivor.` : ''

        const handleQlacBtnClick = () => {
            const url = `${process.env.REACT_APP_API_URL}/DownloadDocument/${lsri.lsriInfo.qlacCertificateGUID}`;
            window.open(url);
        }

        if (isManage) {
            if (!qlacIsSelected)
                return null

            return {
                heading: "Longevity Income (QLAC)",
                value: `${qlacMonthlyPayment} on ${qlacDate}`,
                text: <>
                    <p>Your longevity income is paid directly by Pacific Life. {caMsg}</p>
                    {lsri.lsriInfo.qlacCertificateGUID && <>
                        <p>You can view and download a PDF of your QLAC certificate for your records.</p>
                        <Button variant="outline-primary" className="mb-4" onClick={() => handleQlacBtnClick()}>View Your Certificate (PDF)</Button>
                    </>}
                </>
            } as ReviewPanelProps
        }
        else {
            const qlacFundingAmt = formatMoneyString(lsri.userElection?.qlacPool ?? "0", true)
            const qlacFundingPct = lsri.userElection?.qlacPerc ?? "0"

            return {
                heading: "Longevity Income (QLAC)",
                value: qlacIsSelected ? 'Included' : 'Not included',
                text: qlacIsSelected ? `Use ${qlacFundingPct} (${qlacFundingAmt} as of ${asOfDate}) of your LifeStage account to purchase ${qlacMonthlyPayment} in guaranteed monthly income beginning on ${qlacDate}. ${caMsg}` : ''
            } as ReviewPanelProps
        }
    }

    function GetBankInfoReviewPanelProps(): ReviewPanelProps | null {

        const bankAccountLast4 = bankAccount.accountNumber?.length > 4
            ? bankAccount.accountNumber?.substr(bankAccount.accountNumber?.length - 4, 4)
            : bankAccount.accountNumber

        if (isManage) {
            return {
                heading: "Bank Information",
                headerLink: GetHeaderLink(GatewayPath.LsriBankInfo),
                value: '',
                text: <div className="pb-3">{bankAccount.bankName}<br />Account ending in: {bankAccountLast4}</div>
            } as ReviewPanelProps
        }
        else {
            return {
                heading: "Bank Information",
                headerLink: undefined,
                value: '',
                text: <div className="pb-3">{bankAccount.bankName}<br />Account ending in: {bankAccountLast4}</div>
            } as ReviewPanelProps
        }
    }

    interface DispalyW4PFieldProps { fieldText: string; fieldValue?: string | undefined; showBold?: boolean; displayFieldColClass?: string | undefined; noIndent?: boolean }
    const DispalyW4PField = (props: DispalyW4PFieldProps): React.ReactElement => {

        const fieldColClass = (props.displayFieldColClass && props.displayFieldColClass.trim().length > 0) ? props.displayFieldColClass : 'col-6';

        return (
            <Row style={{ whiteSpace: "break-spaces" }} className={props.showBold ? "mb-2 font-weight-bold" : "mb-2"}>
                <Col className={props.fieldValue ? (props.noIndent ? fieldColClass : `${fieldColClass} ml-3`) : (props.noIndent ? "col-11" : "col-11 ml-3")}>
                    {props.fieldText}
                </Col>
                {props.fieldValue &&
                    <Col className="col-sm-3 col-md-2 mr-1 text-right">
                        {props.fieldValue}
                    </Col>
                }
            </Row>
        );
    }

    function GetTaxReviewPanelProps(): ReviewPanelProps | null {

        const defaultElectionText = "The income tax withholding elections below reflect default values.";
        const doNotWithholdText = "You elected to have no federal income taxes withheld.";

        if (!taxOptions)
            return null

        const { fedMaritalStatus, stateOfResidence, federalWithholding, pre2022FederalWithholding, taxableAnnualPay,
            otherTaxablePay, dependentChildrenCredits, dependentOtherCredits, otherOptionalCredits, otherIncome,
            deductions, extraWithholding, pre2022FedMaritalStatus, additionalAmount, numberOfAllowances, isW4POnFile } = taxOptions

        const state = lsri.lsriInfo?.stateList?.states?.find(s => s.value === stateOfResidence)
        const showW4PView = (lsri.wizardType === LsriStage.Commit) || ((lsri.wizardType === LsriStage.Pending || lsri.wizardType === LsriStage.Manage) && isW4POnFile);

        const FederalTaxDetails = () => {

            return (

                <>
                    {!showW4PView &&
                        <>
                            < Col className="col-6 reviewPanelItemList">
                                <div>Federal</div>
                                <ul>
                                    {pre2022FederalWithholding === FederalWithholdingOption.DoNotWithhold
                                        ? <li>{doNotWithholdText}</li>
                                        : <>
                                            <li>{pre2022FedMaritalStatus === Pre2022FedMaritalStatus.Pre2022MarriedHigherRate ? 'Married but withholding at higher single rate' : pre2022FedMaritalStatus?.toString().replace("Pre2022", "")}</li>
                                            <li>{numberOfAllowances} Allowances</li>
                                            {additionalAmount !== undefined && additionalAmount > 0 ? <li>{formatMoney(additionalAmount, true)} Additional Amount</li> : null}
                                        </>
                                    }
                                </ul>
                            </Col>
                        </>
                    }


                    {
                        showW4PView &&

                        <>
                            <Col className="reviewPanelItemList">
                                <div className="font-weight-bold mb-2">Federal Income Tax Withholding Elections</div>
                                <Row>
                                    <Col>
                                        {federalWithholding === FederalWithholdingOption.DoNotWithhold ?
                                            (
                                                <DispalyW4PField fieldText={doNotWithholdText} displayFieldColClass={"col-sm-10 col-md-7"} noIndent />
                                            ) :
                                            (
                                                <>
                                                    {((!federalWithholding || federalWithholding === FederalWithholdingOption.Withhold) && (!fedMaritalStatus)) &&
                                                        <DispalyW4PField fieldText={defaultElectionText} displayFieldColClass={"col-sm-10 col-md-7"} noIndent />
                                                    }
                                                    <DispalyW4PField fieldText={getFedMarritalStatus(fedMaritalStatus)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Taxable annual pay from all jobs:"} fieldValue={formatMoney(convertStringToNumber(taxableAnnualPay), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Taxable annual pay from lower-paying pensions and annuities:"} fieldValue={formatMoney(convertStringToNumber(otherTaxablePay), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Total taxable income from jobs, pensions, and annuities:"} fieldValue={formatMoney(convertStringToNumber(taxableAnnualPay) + convertStringToNumber(otherTaxablePay), true)} displayFieldColClass={"col-sm-10 col-md-7"} showBold />
                                                    <br />
                                                    <DispalyW4PField fieldText={"Qualified children credit:"} fieldValue={formatMoney(convertStringToNumber(dependentChildrenCredits), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Other dependents credit:"} fieldValue={formatMoney(convertStringToNumber(dependentOtherCredits), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Additional credits:"} fieldValue={formatMoney(convertStringToNumber(otherOptionalCredits), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Total dependent and other credits:"} fieldValue={formatMoney(convertStringToNumber(dependentChildrenCredits) + convertStringToNumber(dependentOtherCredits) + convertStringToNumber(otherOptionalCredits), true)} displayFieldColClass={"col-sm-10 col-md-7"} showBold />
                                                    <br />
                                                    <DispalyW4PField fieldText={"Other income:"} fieldValue={formatMoney(convertStringToNumber(otherIncome), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Deductions:"} fieldValue={formatMoney(convertStringToNumber(deductions), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                    <DispalyW4PField fieldText={"Extra withholding:"} fieldValue={formatMoney(convertStringToNumber(extraWithholding), true)} displayFieldColClass={"col-sm-10 col-md-7"} />
                                                </>
                                            )
                                        }

                                    </Col>
                                </Row>
                                <Row>
                                    <StateTaxDetailsW4P />
                                </Row>

                            </Col>
                        </>
                    }
                </>

            );
        }

        const StateTaxDetails = () => (<Col className="col-6 reviewPanelItemList">
            <div>State</div>
            {state && <ul className="reviewPanelItemList">
                <li>{state.display}</li>
                <li>{state.withholdingFlag ? 'State income taxes will be withheld at the default rate.' : 'No state income taxes will be withheld.'}</li>
            </ul>}
        </Col>)

        const StateTaxDetailsW4P = () => (

            <Col className="reviewPanelItemList my-4">
                <div className="font-weight-bold mb-2">State Income Tax Withholding Elections</div>

                {state && <div>
                    <DispalyW4PField fieldText={state.display} />
                    <DispalyW4PField fieldText={state.withholdingFlag ? "State income taxes will be withheld at the default rate." : "No state income taxes will be withheld."} />
                </div>}
            </Col>)

        if (isManage) {
            return {
                heading: "Income Tax Withholding Elections",
                headerLink: GetHeaderLink(GatewayPath.LsriTaxOptions),
                value: '',
                text: <Row><FederalTaxDetails />{!showW4PView && <StateTaxDetails />}</Row>
            } as ReviewPanelProps
        }
        else {
            return {
                heading: "Income Tax Withholding Elections",
                value: '',
                text: <Row><FederalTaxDetails />{!showW4PView && <StateTaxDetails />}</Row>
            } as ReviewPanelProps
        }
    }

    return (<>
        {lsri.wizardType === LsriStage.Setup && <ReviewPanel>
            <ReviewPanelRecord reviewPanelProps={GetEstPaymentPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetMppReviewPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetFundingReviewPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetBridgeReviewPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetQLACReviewPanelProps()} />
        </ReviewPanel>}


        {(lsri.wizardType === LsriStage.Commit || lsri.wizardType === LsriStage.Pending) && <ReviewPanel>
            <ReviewPanelRecord reviewPanelProps={GetEstPaymentPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetMppReviewPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetFundingReviewPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetBridgeReviewPanelProps()} />
            <ReviewPanelRecord reviewPanelProps={GetQLACReviewPanelProps()} />
            {hasClaim_View_TaxInfo ? <ReviewPanelRecord reviewPanelProps={GetTaxReviewPanelProps()} /> : <></>}
            {hasClaim_View_BankInfo ? <ReviewPanelRecord reviewPanelProps={GetBankInfoReviewPanelProps()} /> : <></>}
        </ReviewPanel>}

        {lsri.wizardType === LsriStage.Manage && <ReviewPanel>
            {(!isQlacOnly) ?
                <>
                    <ReviewPanelRecord reviewPanelProps={GetEstPaymentPanelProps()} />
                    <ReviewPanelRecord reviewPanelProps={GetFundingReviewPanelProps()} />
                    <ReviewPanelRecord reviewPanelProps={GetBridgeReviewPanelProps()} />
                    <ReviewPanelRecord reviewPanelProps={GetQLACReviewPanelProps()} />
                    {hasClaim_View_TaxInfo ? <ReviewPanelRecord reviewPanelProps={GetTaxReviewPanelProps()} /> : <></>}
                    {hasClaim_View_BankInfo ? < ReviewPanelRecord reviewPanelProps={GetBankInfoReviewPanelProps()} /> : <></>}
                </>
                :
                <>
                    <ReviewPanelRecord reviewPanelProps={GetQLACReviewPanelProps()} />
                </>
            }
        </ReviewPanel>}

    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri,
        balance: state.balance
    }
}

export default connect(
    mapStateToProps
)(LsriReviewPanels);



