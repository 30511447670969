import React from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { AppDispatch } from '../../../..';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { OracleCalculation } from '../../../../Gateway.dtos';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { formatMoney, formatMoneyString } from '../../../../functions/formatMoney';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps { isExpanded?: boolean }
const LsriQlacSidePanel = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();
    const { lsri, isExpanded } = props

    const isVisited = lsri.lsriInfo?.oracleLsriElection?.visitedSteps?.qlac
    const showUpdateErrMsg = lsri.calculationLoadState === DataLoadState.Error;

    const isIneligible = lsri.qlacIneligible;
    const scenario = lsri?.userElection?.scenario ?? ""
    const disabledQlacOptions = !((lsri.lsriInfo?.oracleLsriElection?.matrix?.findIndex(m =>
        m.ssAge === lsri.userElection?.ssAge &&
        m.scenario.includes("Q") &&
        (scenario.includes("B") ? m.scenario.includes("B") : !m.scenario.includes("B")) && 
        (scenario.includes("R") ? m.scenario.includes("R") : !m.scenario.includes("R"))
    ) ?? -1) >= 0)
    const qlacIsSelected = scenario.includes("Q");
    const enableNextButton = lsri?.menuOptions?.enabledMenuOptions?.find(o => o === GatewayPath.LsriReview) !== undefined;
    const electionWithQlac = lsri.lsriInfo?.oracleLsriElection?.matrix?.find(m =>
        m.ssAge === lsri.userElection?.ssAge &&
        m.scenario.includes("Q") &&
        (scenario.includes("B") ? m.scenario.includes("B") : !m.scenario.includes("B")) &&
        (scenario.includes("R") ? m.scenario.includes("R") : !m.scenario.includes("R"))
    )
    const monthlyPayment = electionWithQlac?.qlacStart?.qlac ?? 0
    const qlacDate = lsri.systemElection.qlacDate
    const fundingAmt = electionWithQlac?.qlacPool ?? "0"
    const fundingPct = electionWithQlac?.qlacPerc ?? "0"
    const caDesignated = lsri.lsriInfo?.caDesignated

    const handleClickNext = () => {
        dispatch(push(GatewayPath.LsriReview));
    }

    const handleChange = (qlacSelection: boolean) => {

        //Only send data to server if changes were made
        if (qlacSelection !== scenario.includes("Q")) {
            const newScenario = `${scenario.includes("B") ? 'B' : ''}${scenario.includes("R") ? 'R' : ''}${qlacSelection ? 'Q' : ''}`
            const userElection = lsri.lsriInfo.oracleLsriElection.matrix.find(m => m.ssAge === lsri.userElection.ssAge && m.scenario === newScenario) ?? {} as OracleCalculation

            dispatch(LsriActionCreators.updateLSRIAgeQlacBridge({ userElection }))
        }
    }

    if (!lsri?.lsriInfo?.isEligible || ((qlacIsSelected === undefined || !isVisited) && !isExpanded))
        return null

    if (!isExpanded) {
        return (<h5>
            <FontAwesomeIcon icon={faCheck} /> {qlacIsSelected ? "Included" : "Not included"}
        </h5>);
    }

    const ErrorMsg = () => (
        <Alert variant="danger" className={`mt-2 mr-3 ${isSmallScreen ? '' : 'small'}`}>
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while updating your funding amount.
        </Alert>
    )

    const LongevityDetails = () => (
        <div className="mt-2 mb-3 text-nowrap">
            <strong>Monthly payment is {formatMoney(monthlyPayment, true)}<br /></strong>
            Begins on {qlacDate}<br />
            Cost is {fundingPct} ({formatMoneyString(fundingAmt, true)} today)<br />
            {caDesignated && 'Surviving spouse receives 70%'}
        </div>
    )


    return (<div className="sidePanelContentText">

        {!isIneligible && <>

            <h4>See the effect of guaranteed Longevity Income (QLAC)</h4>

            <div className="form-check mr-1">
                <label>
                    <input
                        type="radio"
                        className="form-check-input"
                        name="qlacSelection"
                        value="Y"
                        checked={qlacIsSelected}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(true) }}
                        disabled={disabledQlacOptions}
                        tabIndex={1}
                    />
                    <div className="mt-1">Include longevity income</div>
                    {electionWithQlac && <LongevityDetails />}
                </label>
            </div>

            <div className="form-check mr-1">
                <label>
                    <input
                        type="radio"
                        className="form-check-input"
                        name="qlacSelection"
                        value="N"
                        checked={!qlacIsSelected}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(false) }}
                        disabled={disabledQlacOptions}
                        tabIndex={2}
                    />
                    <div className="mt-1">Do not include longevity income</div>
                </label>
            </div>

            {showUpdateErrMsg && <ErrorMsg />}

        </>}

        <div className="buttonAndContent" style={isIneligible ? { marginTop: '5rem', marginBottom: '6rem' } : {}}>
            <Button className="px-0" variant="link" onClick={() => handleClickNext()} disabled={!enableNextButton}>
                <strong>Next</strong> - review & enroll
            </Button>

            <div>
                Your progress is saved automatically.
            </div>
        </div>

    </div>);
};

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriQlacSidePanel);

