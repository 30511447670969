import { Dispatch } from "react";
import { AppState } from "../configureStore";
import { createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../../definitions/ILoadableState";
import { GetPersonalInfoResponse, GetPersonalInfo, UpdatePersonalInfo } from "../../Gateway.dtos";
import { client } from "../../App";
import { deliveryPreferencesActionCreators } from "./DeliveryPreferencesStore";
import { eyAuthActionCreators } from "./EyAuthorizationStore";
import { contactInfoActionCreators } from "./ContactInfoStore";
import { getResetAction } from "../../functions/getResetAction";

export interface IPersonalInfoState extends ILoadableState {
    personalInfo: GetPersonalInfoResponse
}

export const initialPersonalInfoState: IPersonalInfoState = {
    personalInfo: {} as GetPersonalInfoResponse,
    isLoading: false,
    isLoaded: false,
    error: false
};

const slice = createSlice({
    name: 'PersonalInfo',
    initialState: {} as IPersonalInfoState,
    reducers: {
        resetState: () => {
            return {
                ...initialPersonalInfoState
            }
        },
        requestPersonalInfo: (state: IPersonalInfoState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receivePersonalInfo: (state: IPersonalInfoState, action: PayloadAction<{ personalInfoResponse: GetPersonalInfoResponse }>) => {
            const personalInfo = action.payload.personalInfoResponse;
            return {
                ...state,
                personalInfo,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receivePersonalInfoError: (state: IPersonalInfoState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        },
        updatePersonalInfo: (state: IPersonalInfoState, action: PayloadAction<{ personalInfo: GetPersonalInfoResponse }>) => {
            const personalInfo = action.payload.personalInfo;
            return {
                ...state,
                personalInfo
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialPersonalInfoState)
    }
})

const {
    resetState,
    requestPersonalInfo,
    receivePersonalInfo,
    receivePersonalInfoError,
    updatePersonalInfo
} = slice.actions;

function fetchPersonalInfo() {
    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().personalInfo;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestPersonalInfo());

        try {
            const personalInfoResponse = await client.post(new GetPersonalInfo());

            //Check for errors
            if (personalInfoResponse.responseStatus)
                throw personalInfoResponse.responseStatus.message;

            //Get the data
            dispatch(receivePersonalInfo({ personalInfoResponse }));

            //Update stores that use this data
            dispatch(deliveryPreferencesActionCreators.setState({ isElecDeliv: personalInfoResponse.personalInformation.electronicDelivery }));
            dispatch(eyAuthActionCreators.setState({ isAuthorized: personalInfoResponse.personalInformation.eyAuthorization }));

            const contactInfo = {} as UpdatePersonalInfo;
            contactInfo.textPhone = personalInfoResponse.personalInformation.textPhone;
            contactInfo.phone = personalInfoResponse.personalInformation.phone;
            contactInfo.altPhone = personalInfoResponse.personalInformation.altPhone;
            contactInfo.email = personalInfoResponse.personalInformation.email;
            contactInfo.countryCode = personalInfoResponse.personalInformation.countryCode;
            contactInfo.streetAddress1 = personalInfoResponse.personalInformation.streetAddress1;
            contactInfo.streetAddress2 = personalInfoResponse.personalInformation.streetAddress2;
            contactInfo.streetAddress3 = personalInfoResponse.personalInformation.streetAddress3;
            contactInfo.city = personalInfoResponse.personalInformation.city;
            contactInfo.state = personalInfoResponse.personalInformation.state;
            contactInfo.zipCode = personalInfoResponse.personalInformation.zipCode;

            const invalidAddress = personalInfoResponse.personalInformation.invalidAddress;

            dispatch(contactInfoActionCreators.setState({ contactInfo, invalidAddress }));

        } catch (e: unknown) {
            dispatch(receivePersonalInfoError());
            console.log(e);
        }

    }
}

export const personalInfoActionCreators = {
    fetchPersonalInfo,
    resetState,
    updatePersonalInfo
};

export const personalInfoReducer = slice.reducer;
