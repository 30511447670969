import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetEySearchResponse, GetEySearch } from "../Gateway.dtos";
import { AppDispatch } from "..";
import { accountActionCreators } from "./AccountStore";
import { getResetAction } from "../functions/getResetAction";
import { resetApplication } from "../functions/resetApplication";
import { push } from "connected-react-router";
import { GatewayPath } from "@wespath/gateway-navigation";

export interface IEySearchState extends ILoadableState {
    eySearch: GetEySearchResponse
}

export const initialEySearchState: IEySearchState = {
    eySearch: {} as GetEySearchResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'eySearch',
    initialState: {} as IEySearchState,
    reducers: {
        resetState: () => {
            return {
                ...initialEySearchState
            }
        },
        requestEySearch: (state: IEySearchState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveEySearch: (state: IEySearchState, action: PayloadAction<{ eySearch: GetEySearchResponse }>) => {
            const { eySearch } = action.payload;
            return {
                ...state,
                eySearch,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveEySearchError: (state: IEySearchState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (state, action) => {
                const isEySearch = !!action?.payload?.isEySearch
                if (isEySearch) {
                    return state
                } else {
                    return initialEySearchState
                }
            })
    }
})

const { resetState, requestEySearch, receiveEySearch, receiveEySearchError } = slice.actions;

function fetchEySearch(searchValue: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().eySearch;
        if (isLoading) {
            return;
        }

        dispatch(requestEySearch());

        //Clear out all the stores so that they reload when ppt is found
        dispatch(resetApplication({ isEySearch: true }))

        try {

            const eySearch = await client.post(new GetEySearch({ searchValue }));

            dispatch(receiveEySearch({ eySearch }));

            if (eySearch.pptIsFound && !eySearch.responseStatus) {
                //Update account store
                const { participantNumber, name, firstName, lastName } = eySearch;

                dispatch(accountActionCreators.setProfileName({ profileName: name, profileFirstName: firstName, profileLastName: lastName, profileUsername: "" }));
                dispatch(accountActionCreators.setPartNum({ participantNumber }));

                dispatch(push(GatewayPath.Retirement));
            }
                

        } catch (e: unknown) {
            dispatch(receiveEySearchError());
            console.log(e);
        }



    }
}

export const eySearchActionCreators = {
    fetchEySearch,
    resetState
};

export const eySearchReducer = slice.reducer;
