import React from 'react';
import { Col, Row } from 'react-bootstrap';

export interface ReviewPanelSmallProps {
    heading: string,
    value: JSX.Element | string,
    text: JSX.Element | string
}

const ReviewPanelSmallRecord = (props: { reviewPanelProps: ReviewPanelSmallProps | null, children?: React.ReactElement | string }): React.ReactElement | null => {
    const { reviewPanelProps } = props

    if (!reviewPanelProps || reviewPanelProps === null)
        return null

    return (
        <Col className="reviewPanelItem col-12 col-sm-6 col-md-12 col-lg-6 pr-0 mb-4 mb-sm-2 mb-md-4 mb-lg-2">

            <h4 className="mb-0">{reviewPanelProps.heading}</h4>

            <h5>{reviewPanelProps.value}</h5>

            {typeof reviewPanelProps.text === 'string'
                ? <p>{reviewPanelProps.text}</p>
                : <>{reviewPanelProps.text}</>
            }

            {props.children}

        </Col>
    )
}

const ReviewPanelSmall = (props: { children?: React.ReactElement | React.ReactElement[] }): React.ReactElement => {

    return (
        <Row className="reviewPanelSmall">
            {props.children}
        </Row>
    );
}

export { ReviewPanelSmall, ReviewPanelSmallRecord };

