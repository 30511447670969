import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import {
    GetMultiFactorResponse,
    LexisNexisParticipant,
    CallType,
    GetMultiFactorChallenge,
    LexisNexisOTP,
    GetOTPValidation,
    Ruleset,
    Phone,
    GetPhones,
    LexisNexisKBA,
    GetKBAValidation,
    ChoiceID,
    PhoneType,
    LexisNexisPhoneType
} from "../Gateway.dtos";
import { client } from "../App";
import { GatewayPath } from '@wespath/gateway-navigation'
import { loginHelpCreators } from "./LoginHelpStore";
import { push } from "connected-react-router";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { accountActionCreators } from "./AccountStore";

export interface IMultiFactorState extends ILoadableState {
    multiFactor: GetMultiFactorResponse
    participant: LexisNexisParticipant
    phones: Phone[],
    phonesLoaded: boolean,
    attempts: number,
    totalCodesSent: number,
    isChallenging: boolean,
    redirect: GatewayPath | undefined
}

export const initialMultiFactorState: IMultiFactorState = {
    multiFactor: {} as GetMultiFactorResponse,
    participant: {} as LexisNexisParticipant,
    phones: {} as Phone[],
    phonesLoaded: false,
    attempts: 0,
    totalCodesSent: 0,
    isChallenging: false,
    redirect: undefined,
    isLoading: false,
    isLoaded: false,
    error: false
};

const slice = createSlice({
    name: 'LexisNexisMultiFactor',
    initialState: {} as IMultiFactorState,
    reducers: {
        resetState: () => {
            return {
                ...initialMultiFactorState
            }
        },
        beginMultiFactor: (state: IMultiFactorState) => {
            const { redirect, phones, attempts, phonesLoaded } = state;
            return {
                ...initialMultiFactorState,
                isChallenging: true,
                redirect,
                phones,
                phonesLoaded,
                attempts
            }
        },
        requestMultiFactorUpdate: (state: IMultiFactorState) => {
            return {
                ...state,
                isLoading: true
            }
        },
        setParticipant: (state: IMultiFactorState, action: PayloadAction<{ participant: LexisNexisParticipant }>) => {
            const { participant } = action.payload;
            return {
                ...state,
                participant,
                isLoaded: true
            }
        },
        setPhones: (state: IMultiFactorState, action: PayloadAction<{ phones: Phone[] }>) => {
            const { phones } = action.payload;
            return {
                ...state,
                phones,
                phonesLoaded: true,
                isLoading: false
            }
        },
        increaseAttempts: (state: IMultiFactorState) => {
            return {
                ...state,
                attempts: state.attempts + 1
            }
        },
        increaseTotalCodesSent: (state: IMultiFactorState) => {
            return {
                ...state,
                totalCodesSent: state.totalCodesSent + 1
            }
        },
        receiveMultiFactorUpdate: (state: IMultiFactorState, action: PayloadAction<{ multiFactor: GetMultiFactorResponse }>) => {
            const { multiFactor } = action.payload;
            return {
                ...state,
                multiFactor,
                isLoading: false,
                error: multiFactor.responseStatus?.message !== undefined
            }
        },
        receiveMultiFactorError: (state: IMultiFactorState) => {
            return {
                ...state,
                isLoading: false,
                error: true
            }
        },
        resetMultiFactorError: (state: IMultiFactorState) => {
            return {
                ...state,
                multiFactor: {} as GetMultiFactorResponse,
                error: false
            }
        },
        setIsChallenging: (state: IMultiFactorState, action: PayloadAction<{ isChallenging: boolean, redirect?: GatewayPath }>) => {
            const { isChallenging, redirect } = action.payload;
            return {
                ...state,
                isChallenging,
                redirect
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialMultiFactorState)
    }
})

const {
    resetState,
    beginMultiFactor,
    setParticipant,
    setPhones,
    increaseAttempts,
    increaseTotalCodesSent,
    requestMultiFactorUpdate,
    receiveMultiFactorUpdate,
    receiveMultiFactorError,
    resetMultiFactorError,
    setIsChallenging
} = slice.actions;

function initiateMultiFactor() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().lnMultiFactor;

        if (isLoading) {
            return;
        }

        dispatch(beginMultiFactor());

        const { lastName, birthday } = getState().loginHelp;

        const participant = {} as LexisNexisParticipant;
        participant.lastName = lastName;
        participant.birthDate = birthday;
        participant.phoneNumber = "";
        participant.otpDeliveryInfo = CallType.TEXT;
        participant.callerType = "Participant";
        participant.ruleset = Ruleset.OTP;
        participant.page = getState().router.location.pathname.replace("/","");

        dispatch(setParticipant({ participant }));       

    }
}

function selectNewPhone() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {

        const { participant } = getState().lnMultiFactor;
        const newPpt = {} as LexisNexisParticipant;

        newPpt.firstName = participant.firstName;
        newPpt.lastName = participant.lastName;
        newPpt.middleName = participant.middleName;
        newPpt.birthDate = participant.birthDate;
        newPpt.address = participant.address;
        newPpt.ssn = participant.ssn;
        newPpt.userName = participant.userName;
        newPpt.participantNumber = participant.participantNumber;
        newPpt.provider = participant.provider;
        newPpt.threatMetrixSessionId = participant.threatMetrixSessionId;
        newPpt.requestKey = participant.requestKey;
        newPpt.page = participant.page;

        newPpt.phoneNumber = "";
        newPpt.otpDeliveryInfo = CallType.TEXT;
        newPpt.callerType = participant.callerType;
        newPpt.ruleset = Ruleset.OTP;

        dispatch(resetMultiFactorError());
        dispatch(setParticipant({ participant: newPpt }));

    }
}

function fetchPhones() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading, phonesLoaded, phones } = getState().lnMultiFactor;

        if (phonesLoaded || isLoading || phones?.length > 0) {
            return;
        }

        dispatch(requestMultiFactorUpdate());

        try {

            const getPhones = await client.post(new GetPhones());

            if (getPhones.responseStatus !== undefined)
                throw getPhones.responseStatus.message;

            dispatch(setPhones({ phones: getPhones.phones }));

        } catch (e: unknown) {
            dispatch(receiveMultiFactorError());
            console.log(e);
        }

    }
}

function fetchCode(phoneNumber: string, otpDeliveryInfo: CallType, phoneType?: PhoneType) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading, isLoaded, participant } = getState().lnMultiFactor;
        if (isLoading || !isLoaded) {
            return;
        }

        const newPpt = {} as LexisNexisParticipant;

        newPpt.firstName = participant.firstName;
        newPpt.lastName = participant.lastName;
        newPpt.middleName = participant.middleName;
        newPpt.birthDate = participant.birthDate;
        newPpt.address = participant.address;
        newPpt.ssn = participant.ssn;
        newPpt.userName = participant.userName;
        newPpt.participantNumber = participant.participantNumber;
        newPpt.callerType = participant.callerType;
        newPpt.provider = participant.provider;
        newPpt.threatMetrixSessionId = participant.threatMetrixSessionId;
        newPpt.requestKey = participant.requestKey;
        newPpt.page = participant.page;

        newPpt.phoneNumber = phoneNumber;
        newPpt.otpDeliveryInfo = otpDeliveryInfo;
        newPpt.ruleset = Ruleset.OTP;
        newPpt.phoneType = (phoneType ? phoneType : newPpt.phoneType) as LexisNexisPhoneType;

        //Update our participant info
        dispatch(setParticipant({ participant: newPpt }));

        dispatch(requestMultiFactorUpdate());

        dispatch(increaseTotalCodesSent());

        try {

            const multiFactor = await client.post(
                new GetMultiFactorChallenge({ participant: newPpt })
            );

            //evaluate if kba was returned
            if (multiFactor.transactionResponse?.transactionStatus === "kba") {

                const kbaPpt = {} as LexisNexisParticipant;
                kbaPpt.firstName = participant.firstName;
                kbaPpt.lastName = participant.lastName;
                kbaPpt.middleName = participant.middleName;
                kbaPpt.birthDate = participant.birthDate;
                kbaPpt.address = participant.address;
                kbaPpt.ssn = participant.ssn;
                kbaPpt.userName = participant.userName;
                kbaPpt.participantNumber = participant.participantNumber;
                kbaPpt.callerType = participant.callerType;
                kbaPpt.provider = participant.provider;
                kbaPpt.threatMetrixSessionId = participant.threatMetrixSessionId;
                kbaPpt.requestKey = participant.requestKey;
                kbaPpt.page = participant.page;
                kbaPpt.phoneNumber = participant.phoneNumber;
                kbaPpt.otpDeliveryInfo = participant.otpDeliveryInfo;
                kbaPpt.ruleset = Ruleset.KBA;
                kbaPpt.phoneType = participant.phoneType;

                dispatch(setParticipant({ participant: kbaPpt }));
            }

            dispatch(receiveMultiFactorUpdate({ multiFactor }));

        } catch (e: unknown) {
            dispatch(receiveMultiFactorError());
            console.log("Error returned for fetchCode response: ", e);
        }
    }
}

function validateCode(code: string) {

    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading, multiFactor, participant, redirect } = getState().lnMultiFactor;
        if (isLoading || multiFactor.transactionResponse?.transactionStatus !== "pending") {
            return;
        }

        const tran = multiFactor.transactionResponse;

        try {

            dispatch(requestMultiFactorUpdate());

            dispatch(increaseAttempts());

            const otpResponse = new LexisNexisOTP();
            otpResponse.passCode = code;
            otpResponse.addReqInstResp = tran.addReqInstResp;
            otpResponse.conversationId = tran.conversationId;
            otpResponse.reqInstDetailId = tran.reqInstDetailId;

            const multiFactor = await client.post(
                new GetOTPValidation({ otpResponse, participant })
            );

            dispatch(receiveMultiFactorUpdate({ multiFactor }));

            if (multiFactor.responseStatus !== undefined)
                throw multiFactor.responseStatus.message;

            //evaluate if kba was returned
            if (multiFactor.transactionResponse.transactionStatus === "kba") {

                const kbaPpt = {} as LexisNexisParticipant;
                kbaPpt.firstName = participant.firstName;
                kbaPpt.lastName = participant.lastName;
                kbaPpt.middleName = participant.middleName;
                kbaPpt.birthDate = participant.birthDate;
                kbaPpt.address = participant.address;
                kbaPpt.ssn = participant.ssn;
                kbaPpt.userName = participant.userName;
                kbaPpt.participantNumber = participant.participantNumber;
                kbaPpt.callerType = participant.callerType;
                kbaPpt.provider = participant.provider;
                kbaPpt.threatMetrixSessionId = participant.threatMetrixSessionId;
                kbaPpt.requestKey = participant.requestKey;
                kbaPpt.page = participant.page;
                kbaPpt.phoneNumber = participant.phoneNumber;
                kbaPpt.otpDeliveryInfo = participant.otpDeliveryInfo;
                kbaPpt.ruleset = Ruleset.KBA;
                kbaPpt.phoneType = participant.phoneType;

                dispatch(setParticipant({ participant: kbaPpt }));
            }
            else {
                if (redirect)
                    dispatch(push(redirect));
                else
                    dispatch(loginHelpCreators.nextStep());

                dispatch(setIsChallenging({ isChallenging: false, redirect: undefined }));
            }


        } catch (e: unknown) {
            dispatch(receiveMultiFactorError());
            console.log("Error returned for validateCode response: ", e);
        }

    }
}

function fetchQuestions() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading, participant } = getState().lnMultiFactor;
        if (isLoading) {
            return;
        }
        
        const kbaPpt = {} as LexisNexisParticipant;
        kbaPpt.firstName = participant.firstName;
        kbaPpt.lastName = participant.lastName;
        kbaPpt.middleName = participant.middleName;
        kbaPpt.birthDate = participant.birthDate;
        kbaPpt.address = participant.address;
        kbaPpt.ssn = participant.ssn;
        kbaPpt.userName = participant.userName;
        kbaPpt.participantNumber = participant.participantNumber;
        kbaPpt.callerType = participant.callerType;
        kbaPpt.provider = participant.provider;
        kbaPpt.threatMetrixSessionId = participant.threatMetrixSessionId;
        kbaPpt.requestKey = participant.requestKey;
        kbaPpt.page = participant.page;
        kbaPpt.phoneNumber = participant.phoneNumber;
        kbaPpt.otpDeliveryInfo = participant.otpDeliveryInfo;
        kbaPpt.ruleset = Ruleset.KBA;
        kbaPpt.phoneType = participant.phoneType;

        dispatch(setParticipant({ participant: kbaPpt }));

        dispatch(requestMultiFactorUpdate());

        try {

            const multiFactor = await client.post(
                new GetMultiFactorChallenge({ participant })
            );

            dispatch(receiveMultiFactorUpdate({ multiFactor }));

        } catch (e: unknown) {
            dispatch(receiveMultiFactorError());
            console.log("Error returned for fetchQuestions response: ", e);
        }
    }
}

function validateAnswers(a0: number, a1: number, a2?: number) {

    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading, multiFactor, participant, redirect } = getState().lnMultiFactor;
        if (isLoading) {
            return;
        }

        const tran = multiFactor.transactionResponse;

        try {

            dispatch(requestMultiFactorUpdate());

            const kbaResponse = new LexisNexisKBA();
            kbaResponse.addReqInstResp = tran.addReqInstResp;
            kbaResponse.conversationId = tran.conversationId;
            kbaResponse.reqInstDetailId = tran.reqInstDetailId;
            kbaResponse.questionSetId = tran.questionsets.questionSetId;
            kbaResponse.qAs = [
                {
                    questionId: tran.questionsets.questions[0].questionID,
                    choices: [new ChoiceID({ choice: a0 })]
                },
                {
                    questionId: tran.questionsets.questions[1].questionID,
                    choices: [new ChoiceID({ choice: a1 })]
                }
            ];

            if (a2) {
                kbaResponse.qAs.push({
                    questionId: tran.questionsets.questions[2].questionID,
                    choices: [new ChoiceID({ choice: a2 })]
                });
            }

            const multiFactor = await client.post(
                new GetKBAValidation({ kbaResponse, participant })
            );

            dispatch(receiveMultiFactorUpdate({ multiFactor }));

            if (multiFactor.responseStatus !== undefined)
                throw multiFactor.responseStatus.message;

            //evaluate the result
            if (multiFactor.transactionResponse.transactionStatus === "passed") {
                if (redirect)
                    dispatch(push(redirect));
                else
                    dispatch(loginHelpCreators.nextStep());

                dispatch(setIsChallenging({ isChallenging: false, redirect: undefined }));
            }
            else if (multiFactor.transactionResponse.transactionStatus === "error" || multiFactor.transactionResponse.transactionStatus === "failed") {
                dispatch(accountActionCreators.accessDenied());
            }

        } catch (e: unknown) {
            dispatch(receiveMultiFactorError());
            console.log("Error returned for validateAnswers response: ", e);
        }

    }
}

export const lnMultiFactorActionCreators = {
    resetState,
    initiateMultiFactor,
    setIsChallenging,
    selectNewPhone,
    fetchPhones,
    setPhones,
    fetchCode,
    validateCode,
    fetchQuestions,
    validateAnswers,
    requestMultiFactorUpdate,
    receiveMultiFactorUpdate
};

export const lnMultiFactorReducer = slice.reducer;
