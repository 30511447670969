import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsSetup } from './LsriWizard.steps';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import LsriFlowChart from './Shared/LsriFlowChart';
import { LsriFlowChartDisclaimer } from './Shared/LsriFlowChartDisclaimer';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { LsriSocSecStatus, LsriStage } from '../../../Gateway.dtos';
import { push } from 'connected-react-router';
import LsriSelectionClearedModal from './Shared/LsriSelectionClearedModal';
import { scrollToElementById } from '../../../functions/scrollToElementById';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriBridge = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const bridgeIsSelected = lsri.userElection?.scenario?.includes("B")
    const age = lsri.lsriInfo?.prLsriInfo?.age;
    const ssElection = lsri.lsriInfo?.oracleLsriElection?.socialSecurity
    const hasSocSecEst = ssElection?.ss_Status !== LsriSocSecStatus.NoInfo && (ssElection?.ss_Amt ?? 0) > 0

    const userMatrix = lsri.lsriInfo?.oracleLsriElection?.matrix
    const scenario = lsri?.userElection?.scenario ?? ""
    const disabledBridgeOptions = !((userMatrix?.findIndex(m =>
        m.ssAge === lsri.userElection?.ssAge &&
        m.scenario.includes("B") &&
        (scenario.includes("Q") ? m.scenario.includes("Q") : !m.scenario.includes("Q")) &&
        (scenario.includes("R") ? m.scenario.includes("R") : !m.scenario.includes("R"))
    ) ?? -1) >= 0)
    const nonBridgeSocOptionsExist = ((userMatrix?.findIndex(m =>
        m.ssAge === lsri.userElection?.ssAge &&
        (scenario.includes("Q") ? m.scenario.includes("Q") : !m.scenario.includes("Q")) &&
        (scenario.includes("R") ? m.scenario.includes("R") : !m.scenario.includes("R"))
    ) ?? -1) >= 0)

    const isRecivingOrOptOut = ssElection?.ss_Status === LsriSocSecStatus.Receiving || ssElection?.ss_Status === LsriSocSecStatus.OptOut
    const isIneligible = !bridgeIsSelected && (age >= 69 || isRecivingOrOptOut || (hasSocSecEst && (lsri.bridgeIneligible || (disabledBridgeOptions && !nonBridgeSocOptionsExist))));

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Use LifeStage Retirement Income to bridge to Social Security'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Bridge to Social Security`

        //Reset calculation errors when the user changes pages
        dispatch(LsriActionCreators.resetCalcLoadState())

        if (lsri.wizardType === LsriStage.Setup)
            dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriBridge }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lsri.wizardType === LsriStage.Setup && !lsri.menuOptions?.enabledMenuOptions?.includes(GatewayPath.LsriReview))
            dispatch(LsriActionCreators.enableReviewCheck())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.lsriInfo.oracleLsriElection?.visitedSteps])

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });

    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Bridge"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    const maximizeMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/MaximizeMsg`)?.html ?? '';
    const doNotBridgeMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/DoNotBridgeMsg`)?.html ?? '';
    const noSocSecMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/NoSocSecMsg`)?.html ?? '';
    //End CMS Section


    function getIneligMsg(): string {
        if (age >= 69)
            return 'you are close to or over age 70.'
        else if (ssElection?.ss_Status === LsriSocSecStatus.Receiving)
            return 'you are already receiving Social Security.'
        else if (ssElection?.ss_Status === LsriSocSecStatus.OptOut)
            return 'you opted out of Social Security.'
        else
            return 'you do not have sufficient funds in the program.'
    }

    const IneligibleMsg = () => (<>
        <Alert variant="info" className="mt-4 mb-5">
            <FontAwesomeIcon icon={faInfoCircle} />This feature is not available because {getIneligMsg()}
        </Alert>

        <Button variant="outline-primary" onClick={() => dispatch(push(GatewayPath.LsriQlac))} style={{fontWeight: 'normal'}}>
            <strong>Next</strong> - Longevity Income (QLAC)
        </Button>
    </>)

    const DoNotBridgeMsg = () => (
        <Alert variant="warning" className="mb-5">
            <FontAwesomeIcon icon={faExclamationTriangle} /><span dangerouslySetInnerHTML={{ __html: doNotBridgeMsgCms }} />
        </Alert>
    )

    const NoSocSecMsg = () => (
        <Alert variant="warning" className="mb-5">
            <FontAwesomeIcon icon={faExclamationTriangle} /><span dangerouslySetInnerHTML={{ __html: noSocSecMsgCms }} />&nbsp;
            <span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => dispatch(push(GatewayPath.LsriSocialSecurity))}>
                Update your Social Security estimate.
            </span>
        </Alert>
    )


    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 4,
                steps: wizardStepsSetup,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>
                <LsriSelectionClearedModal />
                {isIneligible
                    ? <IneligibleMsg />
                    : <>

                        <p className="mb-4">
                            <span dangerouslySetInnerHTML={{ __html: headerMsgCms }} />
                            &nbsp;<span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => scrollToElementById('learnMoreContent', isSmallScreen)}>Learn more</span>
                        </p>

                        {!hasSocSecEst && <NoSocSecMsg />}

                        {hasSocSecEst && !bridgeIsSelected && <DoNotBridgeMsg />}

                        <LsriFlowChart />

                        <div className="my-5" id='learnMoreContent' dangerouslySetInnerHTML={{ __html: maximizeMsgCms }} />

                        <LsriFlowChartDisclaimer />

                    </>
                }

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriBridge);

