import React, { useEffect } from 'react';
import { faClipboardList } from '@fortawesome/pro-solid-svg-icons';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PanelWithIcon } from '../../PanelWithIcon';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { cmsActionCreators } from '../../../store/CmsStore';
import { connect } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';

export interface OwnProps { isSummary?: boolean }
type THealthQuotientProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const HealthQuotient = (props: THealthQuotientProps) => {

    const cmsData = props.cmsData.fragments?.find(f => f.name === "HealthQuotient");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    if (props.isSummary) {
        return (
            <PanelWithIcon icon={faClipboardList} iconColor="wellbeing-color" title="Health Check">
                {cmsData ? <span dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : <LoadingSkeleton className="mb-0" />}
            </PanelWithIcon>
        );
    }
    else {
        return (
            <Card className="pageCard secondary">
                <Card.Title><FontAwesomeIcon icon={faClipboardList} className="wellbeing-color" />Health Check</Card.Title>
                <Card.Body>
                    {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : <LoadingSkeleton />}
                </Card.Body>
            </Card>
        );
    }

}
function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        cmsData: state.cms.wellbeing,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchWellBeingCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthQuotient);