import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { AppState } from '../../../../store/configureStore';
import { AppDispatch } from '../../../..';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { LsriActionCreators } from '../../../../store/LsriStore';
import parse from "date-fns/parse";
import format from "date-fns/format";
import { push } from 'connected-react-router';
import { LsriStage } from '../../../../Gateway.dtos';

type TUnlockProps = ReturnType<typeof mapStateToProps>;

const LsriSyncBanner = (props: TUnlockProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { lsri, isParticipant } = props;
    const isLoaded = lsri.loadState === DataLoadState.Loaded
    const isSyncing = lsri.resetLoadState === DataLoadState.Loading;
    const lastSync = lsri.lastsync;
    const isManage = lsri.wizardType === LsriStage.Manage;


    //This action will occur if lsriwizard type changed due to data
    useEffect(() => {
        if (lsri.redirectToLastPopulatedStep) {
            dispatch(push(lsri.redirectToLastPopulatedStep));
            dispatch(LsriActionCreators.resetRedirectToLastPopulatedStep());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.redirectToLastPopulatedStep]);

    const syncDateMsg = () => {
        if (!lastSync)
            return "Last synced never";
        
        const parsed = parse(lastSync, "MM/dd/yyyy hh:mm:ss aa", new Date())
        return `Last synced on ${format(parsed, "MM/dd/yyyy")} at ${format(parsed, "hh:mm:ss aa")}`;
    }

    const handleResetBtn = () => (
        dispatch(LsriActionCreators.resetToPpt()) 
    )

    return (<>
        {!isParticipant && isLoaded && !isManage && <div className={`utilityBar action`} style={{ marginTop: '-1.5rem' }}>
            <div className="container maxWidth">
                <Row>
                    <Col className="col-12 col-md-8 mt-2">
                        <h1>
                            Sync selections with participant - {syncDateMsg()}
                        </h1>
                    </Col>
                    <Col className="mt-4 mt-md-0 col-12 col-md-4 text-md-right">
                        <Button variant="primary" type="submit" tabIndex={2} onClick={() => { handleResetBtn() }} disabled={isSyncing}>
                            {isSyncing ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : "Sync now"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>}
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        isParticipant: !state.account.profileName,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriSyncBanner);
