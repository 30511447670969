import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { AppDispatch } from '../../../..';
import { AppState } from '../../../../store/configureStore';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { GatewayPath } from '@wespath/gateway-navigation';
import { push } from 'connected-react-router';
import { LsriSelectionClearedFlag, LsriStage } from '../../../../Gateway.dtos';

type TBeneReviewProps = ReturnType<typeof mapStateToProps>;

const LsriSelectionClearedModal = (props: TBeneReviewProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { lsri, pathname } = props
    const isPaymentOptionPage = pathname === GatewayPath.LsriPaymentOptions
    const selectionCleared = lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.selectionCleared

    const handleClickRedirectToPaymentOptions = () => {
        dispatch(LsriActionCreators.setShowSelectionClearedModal(false))
        dispatch(LsriActionCreators.setWizardType(LsriStage.Setup))
        dispatch(push(GatewayPath.LsriSocialSecurity))
    }

    const handleClickResume = () => {
        dispatch(LsriActionCreators.setShowSelectionClearedModal(false))
    }

    if (!lsri.showSelectionClearedModal)
        return null

    const SharedText = 'Please review the current options available to you.'

    const headerText = selectionCleared === LsriSelectionClearedFlag.I
        ? 'Your LifeStage Retirement Income elections are no longer available'
        : 'Your Options Have Changed'

    const BodyText = () => (<>

        {selectionCleared === LsriSelectionClearedFlag.C && <p className="pt-0">
            The last election you made affects your LifeStage Retirement Income options. {!isPaymentOptionPage && `${SharedText}`}
        </p>}

        {selectionCleared === LsriSelectionClearedFlag.I && <>
            <p className="pt-0">
                The elections you made previously are no longer available because of changes to your account. This is
                typically caused by changes in account balance and your intent to retire date.
            </p>
            <p>
                Please make new elections to continue with your application.
            </p>
        </>}

    </>)
    
    const ContinueBtn = () => (<>
        {isPaymentOptionPage
            ? <Button className="mt-3 mb-0 p-0" variant="link" onClick={() => handleClickRedirectToPaymentOptions()}>{SharedText}</Button>
            : <Button className="mr-4 mt-4" variant="primary" onClick={() => handleClickResume()}>Continue</Button>
        }     
    </>)

    return (<>
        <Modal
            dialogClassName="lsri-modal"
            show={lsri.showSelectionClearedModal}
            backdrop="static"
            centered
            onHide={() => handleClickResume()}
            onEscapeKeyDown={() => handleClickResume()}
            backdropClassName="lsri-modal-backdrop"
        >
            <Modal.Header closeButton={false} closeLabel="Close (X)" style={{ border: "none" }}>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ border: "none" }}>

                <BodyText />

                <ContinueBtn />

            </Modal.Body>
        </Modal>
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        pathname: state.router.location.pathname,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriSelectionClearedModal);
