const scrollToElementById = function (id: string, isSmallScreen: boolean) {
    const element = document.getElementById(id);
    const headerHeight = isSmallScreen ? 120 : 220;

    if (element) {
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
};

export { scrollToElementById };
