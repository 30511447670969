import React, { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Health } from '../../../definitions/Claims';

type TLearnAboutProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const LearnAbout = (props: TLearnAboutProps, hasHSA: boolean) => {

    const jvLink = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.jvLink ?? "")

    const cmsData = props.cmsData?.fragments?.find(f => f.name === "LearnAbout");

    const cmsDataLearnAbout2 = props.cmsData?.fragments?.find(f => f.name === "LearnAbout2")?.html.replace("$alexLink", jvLink) ?? '';

    const handleSSO = () => {
        window.open(jvLink, "_blank");
    }

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (<>

        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faCompass} className="health-color smaller" />
                Learn About Your 2025 HealthFlex Options
            </Card.Title>
            <Card.Body style={{ paddingBottom: '0px' }} className='learnAbout'>

                {!cmsData ? <LoadingSkeleton /> : <>

                    {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                </>}

                {!cmsDataLearnAbout2 ? <LoadingSkeleton /> : <>

                    {cmsDataLearnAbout2 ? <p className="thin mobile pt-1" dangerouslySetInnerHTML={{ __html: cmsDataLearnAbout2 }} /> : null}

                    <Button className='mobile ml-0 mb-3'
                        variant="secondary"
                        onClick={handleSSO}
                    >
                        Get Started with ALEX
                    </Button>

                </>}

            </Card.Body>
        </Card>
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        username: state.account.username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LearnAbout);
