import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsPending, wizardStepsManage } from './LsriWizard.steps';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import LsriReviewPanels from './LsriReviewPanels';
import LsriFlowChart from './Shared/LsriFlowChart';
import { LsriFlowChartDisclaimer } from './Shared/LsriFlowChartDisclaimer';
import { cmsActionCreators } from '../../../store/CmsStore';
import { LsriStage } from '../../../Gateway.dtos';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import { ReviewPanelSmall, ReviewPanelSmallRecord } from '../../ReviewPanelSmall';
import { ReviewPanelProps } from '../../ReviewPanel';
import { formatMoneyString } from '../../../functions/formatMoney';
import parse from "date-fns/parse";
import format from "date-fns/format";
import { Link } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation';


type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriSummary = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const ssAge = lsri.userElection?.ssAge;
    const nextPaymentDate = lsri.lsriInfo?.oracleLsriElection?.paymentOptions?.find(p => p.elected === true)?.date ?? lsri.lsriInfo?.oracleLsriElection?.matrixCommon?.lsriCommDate ?? "";
    const isPending = lsri.wizardType === LsriStage.Pending;
    const isManage = lsri.wizardType === LsriStage.Manage;


    const { messages, pendingTradeDate, unavailableUntilDate } = lsri.lsriInfo
    const showPendingTransferMsg = messages?.find(m => m.type === "pendTransfer")
    const bridgeEndingMsg = messages?.find(m => m.type === "bridgeEnd")
    const qlacBeginningMsg = messages?.find(m => m.type === "qlacStart")

    const pendingActionLink = "/mybenefits_pendingactions.aspx";

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Your LifeStage Retirement Income'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Your LifeStage Summary`
    }, []);

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });

    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Summary"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0
    
    useEffect(() => {
        dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection))
        dispatch(cmsActionCreators.fetchLsriCmsData("ProgramOverview"))

        if (lsri.wizardType === LsriStage.Manage) {
            dispatch(cmsActionCreators.fetchLsriCmsData("Funding"))
            dispatch(cmsActionCreators.fetchLsriCmsData("TaxOptions"))
            dispatch(cmsActionCreators.fetchLsriCmsData("BankInfo"))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bridgeMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/BridgeMsg`)?.html.replace("$age", ssAge?.toString()) ?? '';
    //End CMS Section

    const PendingMsg = () => (
        <Alert variant="info">
            <FontAwesomeIcon icon={faInfoCircle} />
            <strong>Your LifeStage Retirement Income application is pending.</strong>
            <div className="mt-2">
                {unavailableUntilDate && unavailableUntilDate !== "00/00/0000"
                    ? <>
                        Management of your retirement income is not available at this time due to processing. You may view and manage your retirement income
                        after 8:00 a.m., Central time, on {format(parse(unavailableUntilDate, "MM/dd/yyyy", new Date()), "MMMM dd, yyyy")}.
                    </>
                    : <>
                        You have until 3:00 p.m., Central time on {pendingTradeDate} to <BenefitsAccessSso to={pendingActionLink} >change your mind</BenefitsAccessSso>.
                    </>
                }
            </div>
        </Alert>
    )

    const PendingTransferMsg = () => (
        <Alert variant="info">
            <FontAwesomeIcon icon={faInfoCircle} />
            <strong>Pending Transfer</strong>
            <div className="mt-2">You have a <Link to={GatewayPath.LsriFunding}>pending transfer</Link> which, once processed,
            may affect your monthly payment and LifeStage Retirement Income Account balance.</div>
        </Alert>
    )

    const BridgeEndingMsg = () => (
        <Alert variant="info">
            <FontAwesomeIcon icon={faInfoCircle} />
            <strong>Your bridge is ending soon</strong>
            <div className="mt-2">Based on our records, your Social Security benefit will start on {bridgeEndingMsg?.date} and your LifeStage payment will be adjusted to an estimated {formatMoneyString(bridgeEndingMsg?.adjustedPmt ?? "", true)}.</div>
        </Alert>
    )

    const QlacBeginningMsg = () => (
        <Alert variant="info">
            <FontAwesomeIcon icon={faInfoCircle} />
            <strong>Your QLAC is beginning soon</strong>
            <div className="mt-2">Based on our records, your Longevity Income will start on {qlacBeginningMsg?.date} and your LifeStage payment will be adjusted to an estimated {formatMoneyString(qlacBeginningMsg?.adjustedPmt ?? "", true)}.</div>
        </Alert>
    )

    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 1,
                steps: lsri.wizardType === LsriStage.Manage ? wizardStepsManage : wizardStepsPending,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                {isPending || isManage
                    ? <>

                        {isPending && <PendingMsg />}

                        {showPendingTransferMsg && <PendingTransferMsg />}

                        {bridgeEndingMsg && <BridgeEndingMsg />}

                        {qlacBeginningMsg && <QlacBeginningMsg />}

                    </>
                    : <>

                        <div className="mb-5" dangerouslySetInnerHTML={{ __html: bridgeMsgCms }} />

                        <ReviewPanelSmall>
                            <ReviewPanelSmallRecord reviewPanelProps={{heading: "Next Payment Date", value: `${nextPaymentDate}`, text: ``} as ReviewPanelProps} />
                        </ReviewPanelSmall>

                        <LsriFlowChart />

                        <LsriFlowChartDisclaimer />

                        <p className="mt-5 pt-5"><strong>Your lifeStage Retirement Details</strong></p>

                    </>
                }

                <div className="mt-5">
                    <LsriReviewPanels />
                </div>

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriSummary);

