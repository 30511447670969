import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { balanceActionCreators } from '../../../store/BalanceStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AmountPanel } from '../../AmountPanel';
import { Installment } from '../../../Gateway.dtos';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import { AppDispatch } from '../../..';
import { AmountPanelSmall } from '../../AmountPanelSmall';
import { Link } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation';

export interface OwnProps { isSummary?: boolean }
type TPaymentsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Payments = (props: TPaymentsProps) => {

    const isLoading = (props.isLoading && !props.isLoaded);
    const showCard = (!isLoading && props.balance?.installments?.length > 0);

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchBalance();
        }
    });

    const PaymentLsriSmriLink = (props: { installment: Installment }) => {
        return (
            (props.installment.type === "LSRI")
                ? <Link to={GatewayPath.LsriOverview}>
                    View payment details
                </Link>
                : <BenefitsAccessSso to={`/view_manage_retirement_income.aspx`} >View payment details</BenefitsAccessSso>
                               
            )
    }

    if (!showCard) {
        return <></>;
    }
    else if (props.isSummary) {

        return (<>
            {props.balance.installments.map((item, i) => (
                <AmountPanelSmall key={i}
                    label="Payment"
                    minorText={item.nextPayDate && item.payment > 0 ? `Next Payment: ${item.nextPayDate}` : ""}
                    balance={item.nextPayDate && !(item.payment > 0) ? BalanceMsg(item.nextPayDate) : item.payment}
                    footnote={PaymentMsg(item)}
                    isLoading={false}
                >
                    <PaymentLsriSmriLink installment={item}/>
                </AmountPanelSmall>
            ))}
        </>);

    }
    else {

        return (<>
            {props.balance.installments.map((item, i) => (
                <AmountPanel key={i}
                    label="Payment"
                    minorText={item.nextPayDate && item.payment > 0 ? `Next Payment: ${item.nextPayDate}` : ""}
                    balance={item.nextPayDate && !(item.payment > 0) ? BalanceMsg(item.nextPayDate) : item.payment}
                    footnote={PaymentMsg(item)}
                    isLoading={false}
                >
                    <PaymentLsriSmriLink installment={item} />
                </AmountPanel>
            ))}
        </>);

    }


}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...state.balance,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...balanceActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Payments);


function PaymentMsg(i: Installment) {

    if (i.type === "LSRI")
        return "Your retirement income payment is managed by LifeStage Retirement Income and paid out in monthly installments."
    else if (i.frequency != null && i.payment > 0)
        return "Your retirement income payment is self-managed and paid out in " + FreqFix(i.frequency) + " installments."
    else if (i.frequency != null && i.duration > 0)
        return "Your retirement income payment is self-managed and paid out " + i.frequency + " over the next " + DurationFix(i) + "."
    else
        return "";

}

function BalanceMsg(nextPayDate: string) {
    return `Your next payment is scheduled for ${nextPayDate}. The exact amount depends on your account's value when the payment is calculated.`
}

function FreqFix(freq: string) {

    //Return corrected text for the word 'annually'
    return freq === "annually" ? "annual" : freq;
}

function DurationFix(i: Installment) {

    //Return corrected text for the phrase "# years" or "# months"
    let period = i.frequency === "annually" ? "year" : "month";

    //make it plural if needed
    period = i.duration > 1 ? period + "s" : period;

    return i.duration + " " + period
}
