import React, { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeight } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { activityActionCreators } from '../../../store/ActivityStore';

type TWWProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const WW = (props: TWWProps) => {
    const dispatch: AppDispatch = useDispatch(); 
    const cmsData = props.cmsData.fragments?.find(f => f.name === "WWMessage");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (

        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faWeight} className="wellbeing-color smaller" />
            WeightWatchers
        </Card.Title>
            <Card.Body>

                {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                <a href="https://www.weightwatchers.com/us/HealthFlex" className="text-nowrap" target="_blank" rel="noopener noreferrer" onClick={() => dispatch(activityActionCreators.logActivity("button_weightwatchers.com"))}>
                    <Button className="mt-3" variant="secondary">Go to WeightWatchers</Button>
                </a>

            </Card.Body>
        </Card>

    );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.wellbeing
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchWellBeingCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WW);
