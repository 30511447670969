import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Row, Col, Alert, Card, Button } from 'react-bootstrap';
import { AppState } from '../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../..';
import { useWindowDimensions } from '@wespath/gateway-navigation';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import { IPaymentsInfoState, paymentsInfoActionCreators } from '../../store/PaymentsInfoStore';
import { OtherDeduction, PastPaymentInfo } from '../../Gateway.dtos';
import { PageTitle } from '../PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faHistory, faSortSizeUp, faSortSizeDown, faCalendarCheck, faMoneyCheckEdit } from '@fortawesome/pro-solid-svg-icons';
import { faAngleRight, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { cmsActionCreators } from '../../store/CmsStore';
import { bindActionCreators } from 'redux';
import Skeleton from 'react-loading-skeleton';
import { FormatDollar } from '../FormatDollar';
import ManageDirectDeposit, { DDCallFrom } from './ManageDirectDeposit';

type TPaymentsInfoProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const PaymentsInfo = (props: TPaymentsInfoProps): ReactElement => {
    const { isSmallScreen } = useWindowDimensions();

    const cmsNoPastPaymentsMessage = props.cmsData.fragments?.find(f => f.name === "NoPastPaymentsMessage")?.html ?? '';
    const cmsPastPaymentLandingMessage = props.cmsData.fragments?.find(f => f.name === "PastPaymentLandingMessage")?.html ?? '';
    const showUpcomingPaynManageDD = false;

    useEffect(() => {
        document.title = "Benefits Access - Payments";

        props.fetchPaymentsCmsData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const NoPastPaymentDataMsg = (propsNoPastPaymentDataMsg: {
        pastPaymentData: PastPaymentInfo[],
    }): React.ReactElement => {


        return (cmsNoPastPaymentsMessage === '' ? <div className="noPastPaymentDataMsg"><Skeleton width={'50em'} /></div>
            :
             <>
                <div className="noPastPaymentDataMsg">
                    {cmsNoPastPaymentsMessage}
                </div>
             </>)
    }

    const PastPaymentsLandingMsg = (propsPastPaymentsLandingMsg: {numberOfMonths: number}) => {

        let landingMsg = cmsPastPaymentLandingMessage;

        if (cmsPastPaymentLandingMessage) {
            landingMsg = cmsPastPaymentLandingMessage.replace("{mths}", propsPastPaymentsLandingMsg.numberOfMonths + "");
        }

        return (landingMsg === '' ? <p className="pastPaymentsSubText"><Skeleton width={'50em'} /></p>
            : <>
                <p className="pastPaymentsSubText">
                    <p dangerouslySetInnerHTML={{ __html: landingMsg }} />
                </p>
            </>)
    }

    const PastPayments = (props: {
        paymentInfo: IPaymentsInfoState,
        isSmallScreenRequest: boolean
    }): React.ReactElement => {
        const dispatch: AppDispatch = useDispatch();
        const [pastPaymentData, setPastPaymentData] = useState(props.paymentInfo.pastPaymentInfos);
        const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
        const [sortAsc, setSortAsc] = useState(true); 

        const showPastPayemntsGrid = true;

        const LoadingPayInfoSkeleton = () => {
            return (<div className="pastPaymentsGrid">
                <Row className="rowPastPayment">
                    <Col className="date col-2"><Skeleton width={'6em'} /></Col>
                    <Col className="col-3 plan"><Skeleton width={'12em'} /></Col>
                    <Col className="gross col-1"><Skeleton width={'6em'} /></Col>
                    <Col className="col-6" style={{ paddingLeft: '60px' }}>
                        <Row>
                            <Col className="col-8"><Skeleton width={'16em'} /></Col>
                            <Col className="netPayment col-4"><Skeleton width={'6em'} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="rowPastPayment">
                    <Col className="date col-2"><Skeleton width={'6em'} /></Col>
                    <Col className="col-3 plan"><Skeleton width={'12em'} /></Col>
                    <Col className="gross col-1"><Skeleton width={'6em'} /></Col>
                    <Col className="col-6" style={{ paddingLeft: '60px' }}>
                        <Row>
                            <Col className="col-8"><Skeleton width={'16em'} /></Col>
                            <Col className="netPayment col-4"><Skeleton width={'6em'} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="rowPastPayment">
                    <Col className="date col-2"><Skeleton width={'6em'} /></Col>
                    <Col className="col-3 plan"><Skeleton width={'12em'} /></Col>
                    <Col className="gross col-1"><Skeleton width={'6em'} /></Col>
                    <Col className="col-6" style={{ paddingLeft: '60px' }}>
                        <Row>
                            <Col className="col-8"><Skeleton width={'16em'} /></Col>
                            <Col className="netPayment col-4"><Skeleton width={'6em'} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="rowPastPayment">
                    <Col className="date col-2"><Skeleton width={'6em'} /></Col>
                    <Col className="col-3 plan"><Skeleton width={'12em'} /></Col>
                    <Col className="gross col-1"><Skeleton width={'6em'} /></Col>
                    <Col className="col-6" style={{ paddingLeft: '60px' }}>
                        <Row>
                            <Col className="col-8"><Skeleton width={'16em'} /></Col>
                            <Col className="netPayment col-4"><Skeleton width={'6em'} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="rowPastPayment">
                    <Col className="date col-2"><Skeleton width={'6em'} /></Col>
                    <Col className="col-3 plan"><Skeleton width={'12em'} /></Col>
                    <Col className="gross col-1"><Skeleton width={'6em'} /></Col>
                    <Col className="col-6" style={{ paddingLeft: '60px' }}>
                        <Row>
                            <Col className="col-8"><Skeleton width={'16em'} /></Col>
                            <Col className="netPayment col-4"><Skeleton width={'6em'} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="rowPastPayment">
                    <Col className="date col-2"><Skeleton width={'6em'} /></Col>
                    <Col className="col-3 plan"><Skeleton width={'12em'} /></Col>
                    <Col className="gross col-1"><Skeleton width={'6em'} /></Col>
                    <Col className="col-6" style={{ paddingLeft: '60px' }}>
                        <Row>
                            <Col className="col-8"><Skeleton width={'16em'} /></Col>
                            <Col className="netPayment col-4"><Skeleton width={'6em'} /></Col>
                        </Row>
                    </Col>
                </Row>
            </div>);
        }

        const LoadingSmPayInfoSkeleton = () => {
            return (<div className="smPastPaymentsGrid">
                <Row style={{justifyContent:'space-between'} }>
                    <Skeleton width={'8em'} style={{ marginLeft: '15px' }} height={25} />
                    <Skeleton width={'8em'} height={35} style={{ marginRight: '15px' }} />
                </Row>
                <Row style={{ justifyContent: 'space-between' }} >
                     <Skeleton width={'6em'} height={25} style={{ marginLeft: '15px' }} />
                    <Skeleton width={'7em'} height={25} style={{ marginRight: '15px' }} />
                </Row>
                <hr className="smGridHr" />
                <Row style={{ justifyContent: 'space-between' }}>
                    <Skeleton width={'8em'} style={{ marginLeft: '15px' }} height={25} />
                    <Skeleton width={'8em'} height={35} style={{ marginRight: '15px' }} />
                </Row>
                <Row style={{ justifyContent: 'space-between' }} >
                    <Skeleton width={'6em'} height={25} style={{ marginLeft: '15px' }} />
                    <Skeleton width={'7em'} height={25} style={{ marginRight: '15px' }} />
                </Row>
                <hr className="smGridHr" />
                <Row style={{ justifyContent: 'space-between' }}>
                    <Skeleton width={'8em'} style={{ marginLeft: '15px' }} height={25} />
                    <Skeleton width={'8em'} height={35} style={{ marginRight: '15px' }} />
                </Row>
                <Row style={{ justifyContent: 'space-between' }} >
                    <Skeleton width={'6em'} height={25} style={{ marginLeft: '15px' }} />
                    <Skeleton width={'7em'} height={25} style={{ marginRight: '15px' }} />
                </Row>

                <hr className="smGridHr" />
                <Row style={{ justifyContent: 'space-between' }}>
                    <Skeleton width={'8em'} style={{ marginLeft: '15px' }} height={25} />
                    <Skeleton width={'8em'} height={35} style={{ marginRight: '15px' }} />
                </Row>
                <Row style={{ justifyContent: 'space-between' }} >
                    <Skeleton width={'6em'} height={25} style={{ marginLeft: '15px' }} />
                    <Skeleton width={'7em'} height={25} style={{ marginRight: '15px' }} />
                </Row>

                <hr className="smGridHr" />
                <Row style={{ justifyContent: 'space-between' }}>
                    <Skeleton width={'8em'} style={{ marginLeft: '15px' }} height={25} />
                    <Skeleton width={'8em'} height={35} style={{ marginRight: '15px' }} />
                </Row>
                <Row style={{ justifyContent: 'space-between' }} >
                    <Skeleton width={'6em'} height={25} style={{ marginLeft: '15px' }} />
                    <Skeleton width={'7em'} height={25} style={{ marginRight: '15px' }} />
                </Row>

                <hr className="smGridHr" />
                <Row style={{ justifyContent: 'space-between' }}>
                    <Skeleton width={'8em'} style={{ marginLeft: '15px' }} height={25} />
                    <Skeleton width={'8em'} height={35} style={{ marginRight: '15px' }} />
                </Row>
                <Row style={{ justifyContent: 'space-between' }} >
                    <Skeleton width={'6em'} height={25} style={{ marginLeft: '15px' }} />
                    <Skeleton width={'7em'} height={25} style={{ marginRight: '15px' }} />
                </Row>

            </div>);
        }


        const fetchPastPaymentsPageData = async () => {
            if (!props.paymentInfo.allrecordsFetched) {
                dispatch(paymentsInfoActionCreators.fetchPastPaymentsInfo({ pageNumber: props.paymentInfo.pageNumber, perPageRecords: 100, isSmallScreenRequest: props.isSmallScreenRequest }));
            }
        };


        const handleScroll = async () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 201
                && (props.paymentInfo.loadState === DataLoadState.Loaded || props.paymentInfo.loadState === DataLoadState.None)
                && showPastPayemntsGrid
                && !props.paymentInfo.allrecordsFetched) {
                dispatch(paymentsInfoActionCreators.updatePageNumber(props.paymentInfo.pageNumber + 1));
                fetchPastPaymentsPageData();
            }
        };

        const compareByDate = (a: PastPaymentInfo, b: PastPaymentInfo, ascending = true) => {
            // Parse the date strings into Date objects
            const dateA = new Date(a.paymentDate);
            const dateB = new Date(b.paymentDate);

            // Compare the Date objects based on ascending order
            if (sortAsc) {
                setSortAsc(false);
                return dateA.getTime() - dateB.getTime();
            } else {
                setSortAsc(true);
                return dateB.getTime() - dateA.getTime(); // Reverse for descending order
            }
        };

        const handleSort = () => {
            const sortedData = pastPaymentData.slice().sort(compareByDate);
            setPastPaymentData(sortedData);
        };

        //Call this only once when page number is 1
        if (props.paymentInfo.loadState === DataLoadState.None
            && showPastPayemntsGrid
            && !props.paymentInfo.allrecordsFetched) {
            dispatch(paymentsInfoActionCreators.updatePageNumber(props.paymentInfo.pageNumber + 1));
            fetchPastPaymentsPageData();
        }



        useEffect(() => {
            if (showPastPayemntsGrid) {
                window.addEventListener("scroll", handleScroll);

                return () => window.removeEventListener("scroll", handleScroll);
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            setPastPaymentData(props.paymentInfo.pastPaymentInfos);
        }, [props.paymentInfo.pastPaymentInfos])

        const handleExpandCollapse = (id: number) => {
            setExpandedRows((prevExpandedRows) => {
                const newExpandedRows = new Set(prevExpandedRows);
                if (newExpandedRows.has(id)) {
                    newExpandedRows.delete(id);
                } else {
                    newExpandedRows.add(id);
                }
                return newExpandedRows;
            });
        };

        const OtherDeductionsUI = (propsOtherDeductionsUI: {
                otherDeductions: OtherDeduction[]
            }): React.ReactElement => {
                const { otherDeductions } = propsOtherDeductionsUI;
            if (otherDeductions && otherDeductions.length > 0) {
                return (<>
                    {otherDeductions.map((item, i) => (<Fragment key={i}>
                        <Row key={i} style={{paddingBottom:'2px'} }>
                            <Col className="otherDeductionsAmtCol col-6" style={{ textAlign: 'right' }}><FormatDollar amount={item.otherDeductionAmt} /></Col>
                            <Col className="otherDeductionsTxtCol  col-6">{item.otherDeductionType}</Col>
                        </Row>
                    </Fragment>))}
                </>);
            }
            else {
                return (<></>)
            }
        }



        return (
            props.isSmallScreenRequest ?
                <>
                    {props.paymentInfo.loadState === DataLoadState.Error ?
                        <Alert variant="danger" className="mt-4 col-12 col-sm-12 col-lg-8">
                            <FontAwesomeIcon icon={faExclamationCircle} />
                            <strong>Error.</strong> {props.paymentInfo.errorMessage}
                        </Alert>
                        :
                        (props.paymentInfo.loadState !== DataLoadState.Loading || (pastPaymentData && pastPaymentData.length > 0)) &&
                        <>
                            {(pastPaymentData && pastPaymentData.length > 0) ?
                                <>
                                    <PastPaymentsLandingMsg numberOfMonths={pastPaymentData[0].nbrOfMonths} />
                                    <div className={showPastPayemntsGrid ? "smPastPaymentsGrid" : "d-none"}>
                                        <Row className="smGridRowNetPayment">
                                            <Col className="col-12 smGridColNetPayment">Net Payment</Col>
                                        </Row>
                                        <hr className="smGridHr" />

                                        {pastPaymentData.map((payInfo: PastPaymentInfo, i) => {
                                            return (<Fragment key={i}>

                                                <Row className="smGridRowNDtNet">
                                                    <Col className="col-6 smGridColPaymentDate">{payInfo.paymentDate}</Col>
                                                    <Col className="col-6 smGridColNet"><FormatDollar amount={payInfo.net} /></Col>
                                                </Row>
                                                <Row className="smGridRowPlDetails">
                                                    <Col className="col-8 smGridColPlanName">
                                                        {payInfo.plan === "Annuity from one DB plan or more" ? <><span>Annuity <p className="smGridColPlanNameAnnuityPart">From one DB plan or more</p></span></> : payInfo.plan}
                                                    </Col>
                                                    <Col className="col-4 smGridColNet">
                                                        <Button className="smBtnDetails" onClick={() => handleExpandCollapse(payInfo.rowId)}>Details</Button>
                                                    </Col>
                                                </Row>
                                                <Row className="smOtherDeductionDetails">
                                                    <Col>
                                                        {expandedRows.has(payInfo.rowId) &&
                                                            <Row>
                                                                <Col className="col-5"></Col>
                                                                <Col className="">
                                                                    <Row className="smRowGrossOtherDeductions">
                                                                        <Col className="smGrossTotalLabel">Gross Total</Col>    
                                                                        <Col className="col-5 smGrossTotalVal"><FormatDollar amount={payInfo.gross} /></Col>
                                                                    </Row>
                                                                    {payInfo.totalDeductionsAmt !== 0 &&
                                                                        <>
                                                                            {payInfo.otherDeductions.map((item, i) => (<Fragment key={i}>
                                                                                <Row key={i} className="smRowGrossOtherDeductions">
                                                                                    <Col className="smOtherDeductionsLabel">{item.otherDeductionType}</Col>
                                                                                    <Col className="col-5 smOtherDeductionsVal" style={{ textAlign: "right" }}><FormatDollar amount={item.otherDeductionAmt} /></Col>
                                                                                </Row>
                                                                            </Fragment>))}
                                                                        </>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="smGridColPlanDescription">
                                                        {payInfo.planDescription}
                                                    </Col>
                                                </Row>
                                                <hr className="smGridBtnHr" />
                                            </Fragment>);
                                        })}
                                    </div>
                                </>
                                : <NoPastPaymentDataMsg pastPaymentData={pastPaymentData} />
                            }
                        </>
                    }
                    {(props.paymentInfo.loadState === DataLoadState.Loading) && <LoadingSmPayInfoSkeleton />}
                </>
            :
                <>
                    <h3 className={`pb-0 ret-color sidePanelWizardCardHeader`}>
                        <FontAwesomeIcon icon={faHistory} className="pastPaymentIcon"/> <span className="pastPaymentText">Past Payments</span>
                    </h3>
                    {props.paymentInfo.loadState === DataLoadState.Error ?
                        <Alert variant="danger" className="mt-4 col-12 col-sm-12 col-lg-8">
                            <FontAwesomeIcon icon={faExclamationCircle} />
                            <strong>Error.</strong> {props.paymentInfo.errorMessage}
                        </Alert>
                        :
                        (props.paymentInfo.loadState !== DataLoadState.Loading || (pastPaymentData && pastPaymentData.length > 0)) &&
                            <>
                                {(pastPaymentData && pastPaymentData.length > 0) ?
                                    <>
                                        <PastPaymentsLandingMsg numberOfMonths={pastPaymentData[0].nbrOfMonths} />
                                        <div className={showPastPayemntsGrid ? "pastPaymentsGrid" : "d-none"}>
                                            <Row className="gridHeaderRow">
                                                <Col className="gridHeaderCol gridHeaderDate col-2">
                                                    <span onClick={() => handleSort()} className="sortButton"><u>Date</u> <FontAwesomeIcon icon={sortAsc ? faSortSizeDown : faSortSizeUp} className="collapseRowIcon" /></span>
                                                    <span className="printSortButton">Date</span>
                                                </Col>
                                            <Col className="gridHeaderCol col-3">Type</Col>
                                            <Col className="gridHeaderCol col-1" style={{ textAlign: 'right', padding:'0px' }}>Gross</Col>
                                            <Col className="col-6" style={{ paddingLeft: '60px' }}>
                                                <Row>
                                                    <Col className="gridHeaderCol col-8" style={{ textAlign: 'center', paddingLeft: '0px' }}>Taxes & Deductions</Col>
                                                    <Col className="gridHeaderCol col-4" style={{ textAlign: 'right' }}>Net Payment</Col>                                                    
                                                </Row>
                                            </Col>
                                            </Row>
                                            <hr />
                                            {pastPaymentData.map((payInfo: PastPaymentInfo, i) => {
                                                return (
                                                    <Row key={i} className="rowPastPayment">
                                                        <Col className="date col-2">{payInfo.paymentDate}</Col>
                                                        <Col className="col-3 plan">
                                                            {payInfo.plan === "Annuity from one DB plan or more" ? <><span>Annuity <span className="planNameAnnuityPart">from one DB plan or more</span></span></> : payInfo.plan}
                                                            <Row>
                                                                <Col className="planDescription">
                                                                    {payInfo.planDescription}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="gross col-1"><FormatDollar amount={payInfo.gross} /></Col>
                                                        <Col className="col-6" style={{paddingLeft:'60px'} }>
                                                            <Row>
                                                                <Col className="col-8">
                                                                    <span
                                                                        className="expandCollapseButton"
                                                                        onClick={() => handleExpandCollapse(payInfo.rowId)}>
                                                                        <Row style={{ textAlign: 'right', paddingBottom:'7px' }}>
                                                                            <Col className="taxDeductionsTotal col-6" style={{ textAlign: 'right' }}><FormatDollar amount={payInfo.totalDeductionsAmt} /></Col>
                                                                            <Col className="taxDeductionsDetailsText  col-6" style={{ textAlign: 'left' }}>
                                                                                <span className={payInfo.totalDeductionsAmt !== 0 ? "detailsText" : "d-none"}> Details{'\u00A0'}
                                                                                    {expandedRows.has(payInfo.rowId) ? (
                                                                                        <FontAwesomeIcon icon={faAngleDown} className="collapseRowAngleIcon" /> // Use faAngleRight for collapse
                                                                                    ) : (
                                                                                        <FontAwesomeIcon icon={faAngleRight} className="collapseRowAngleIcon" /> // Use faAngleDown for expand
                                                                                    )}
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </span>

                                                                    <Row className={expandedRows.has(payInfo.rowId) ? 'showDeatils' : 'hideDetails' }>
                                                                        <Col>
                                                                            <OtherDeductionsUI otherDeductions={payInfo.otherDeductions} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col className="netPayment col-4"><FormatDollar amount={payInfo.net} /></Col>
                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                );
                                            })}
                                        </div>
                                    </>
                                    : <NoPastPaymentDataMsg pastPaymentData={pastPaymentData} />
                                }
                            </>
                    }
                    {props.paymentInfo.loadState === DataLoadState.Loading && <LoadingPayInfoSkeleton />}
                </>
        );

    }

    const UpcomingPayments = (props: {
        paymentInfo: IPaymentsInfoState,
        isSmallScreenRequest: boolean
    }): React.ReactElement => {
        return (
            <>
                {props.isSmallScreenRequest ? 
                    <>
                    </>
                    : <h3 className={`pb-0 ret-color sidePanelWizardCardHeader`}>
                        <FontAwesomeIcon icon={faCalendarCheck} className="pastPaymentIcon" /> <span className="pastPaymentText">Estimated Upcoming Payments</span>
                    </h3>
                }
            </>
            )
    }

    return (
        <>
            {!isSmallScreen && <Card className="detailCard desktop" style={{ marginTop: '2em', minHeight: "30em" }}>
                <PageTitle title="Payment Information" color="ret-color-bg" hidePrintButton={false} printBtnText="Print Information"/>
                <Card.Body className="sidePanelWizardCardBody">
                    <Row className="p-0 m-0">
                        <Col className={`pt-4 px-md-3 px-lg-3 px-xl-4 ml-2`}>
                            {showUpcomingPaynManageDD &&
                                <Row>
                                    <Col className="col-6">
                                        <UpcomingPayments paymentInfo={props} isSmallScreenRequest={isSmallScreen} />
                                    </Col>
                                    <Col className="col-6">
                                        <ManageDirectDeposit callFrom={DDCallFrom.PaymentInfo} />
                                    </Col>
                                </Row>
                            }
                            <div style={{ marginBottom: '5rem' }}>
                                <PastPayments paymentInfo={props} isSmallScreenRequest={isSmallScreen}/>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>}

            {isSmallScreen && <div className="detailCard mobile showForMd">
                {showUpcomingPaynManageDD && 
                    <>
                        <Card>
                            <Card.Header className="ret-color-bg">
                                <Row className="titleRow">
                                    <Col className="col-12 col-md-8">
                                        <FontAwesomeIcon icon={faCalendarCheck} className="pastPaymentIcon" /> <span className="">Estimated Payments</span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="mx-3 py-4">
                                <div>
                                    <UpcomingPayments paymentInfo={props} isSmallScreenRequest={isSmallScreen} />
                                </div>
                            </Card.Body>
                        </Card>             
                        <Card>
                            <Card.Header className="ret-color-bg">
                                <Row className="titleRow">
                                    <Col className="col-12 col-md-8">
                                        <FontAwesomeIcon icon={faMoneyCheckEdit} className="manageDDIcon" /> <span className="">Manage Deposits</span>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="mx-3 py-4">
                                <div>
                                    <ManageDirectDeposit callFrom={DDCallFrom.PaymentInfo} />
                                </div>
                            </Card.Body>
                        </Card>
                    </>
                }
                <Card>
                    <Card.Header className="ret-color-bg">
                        <Row className="titleRow">
                            <Col className="col-12 col-md-8">
                                <FontAwesomeIcon icon={faHistory} className="pastPaymentIcon" /> <span className="">Past Payments</span>
                            </Col>
                        </Row>
                    </Card.Header>                    
                    <Card.Body className="mx-3 py-4">
                        <div>
                            <PastPayments paymentInfo={props} isSmallScreenRequest={isSmallScreen}/>
                        </div>
                    </Card.Body>
                </Card>
            </div>}
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        ...state.paymentsInfo,
        cmsData: state.cms.payments
    }
}
function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchPaymentsCmsData: cmsActionCreators.fetchPaymentsCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentsInfo);