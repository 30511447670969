import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetMedicareResponse, GetMedicare } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IMedicareState extends ILoadableState {
    medicare: GetMedicareResponse
}

export const initialMedicareState: IMedicareState = {
    medicare: {} as GetMedicareResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'medicare',
    initialState: {} as IMedicareState,
    reducers: {
        resetState: () => {
            return {
                ...initialMedicareState
            }
        },
        requestMedicare: (state: IMedicareState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveMedicare: (state: IMedicareState, action: PayloadAction<{ medicare: GetMedicareResponse }>) => {
            const { medicare } = action.payload;
            return {
                ...state,
                medicare,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveMedicareError: (state: IMedicareState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialMedicareState)
    }
})

const { resetState, requestMedicare, receiveMedicare, receiveMedicareError } = slice.actions;

function fetchMedicare() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().medicare;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestMedicare());

        try {

            const medicare = await client.post(new GetMedicare());

            dispatch(receiveMedicare({ medicare }));

            //Check for errors
            if (medicare.responseStatus)
                throw medicare.responseStatus.message;

            //Determine if health page should be displayed based on this data
            if (medicare.isEnrolled_ViaBen1 || medicare.isEnrolled_ViaBen2)
                dispatch(displayPageActionCreators.showHealth());

        } catch (e: unknown) {
            dispatch(receiveMedicareError());
            console.log(e);
            //dispatch(displayPageActionCreators.showMedicare());
        }



    }
}

export const medicareActionCreators = {
    fetchMedicare,
    resetState
};

export const medicareReducer = slice.reducer;
