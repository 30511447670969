import React, { ReactElement } from "react";
import { FormatDollar } from "./FormatDollar";

enum LabelType {
    Projected,
    Target
}

interface IPoint {
    x: number
    y: number
}

interface ITriangle {
    points: string
    transform: string
}

interface ILabelProps {
    x: number
    y: number
    transform: string
    textAnchor: string
}

interface ILineAndLabelProps {
    percentage: number
    text: ILabelProps
    triangle: ITriangle
}

interface IProjectionValues {
    projected: ILineAndLabelProps
    target: ILineAndLabelProps
}

const ProjectionGauge = (props: { size: number; projected: number; target: number; arcSize: number; text: string; }): ReactElement => {
    const {
        size,
        target,
        text
    } = props;

    let { arcSize, projected } = props;
    let modifiedArcSize = false;

    if (arcSize < 0) {
        console.warn("ProjectionGauge arcSize must be positive. Taking the absolute value.");
        arcSize = Math.abs(arcSize);
        modifiedArcSize = true;
    }

    if (arcSize > 1) {
        console.warn("ProjectionGauge arcSize must a value between 0 and 1. Figuring it out.");
        const remainder = arcSize % Math.floor(arcSize);
        arcSize = remainder === 0 ? 1 : remainder;
        modifiedArcSize = true;
    }

    if (modifiedArcSize) {
        console.log(`Effective Arc Size: ${arcSize}`);
    }

    const displayProj = projected;

    if (projected > 100) {
        projected = 100;
    }

    const onTarget = Math.abs(projected - target) < 1;
    
    const cx = size / 2;
    const cy = size / 2;
    const strokeWidth = size * 0.1;
    const radius = (size * 0.75) / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const emptySize = (1 - arcSize);
    const halfEmptyArc = (emptySize * 360) / 2;
    const rotate = halfEmptyArc - 270
    const bgOffset = emptySize * circumference;
    const arcLength = circumference - bgOffset;


    const projectedOffset = bgOffset + (1 - (projected / 100)) * arcLength;

    const targetOffset = bgOffset + (1 - (target / 100)) * arcLength;

    const bgProps: React.SVGProps<SVGCircleElement> = {
        style: {
            strokeDashoffset: bgOffset,
            stroke: "#D1D2D4"
        },
        cx,
        cy,
        r: radius,
        strokeWidth,
        strokeDasharray: circumference
    }

    const targetProps: React.SVGProps<SVGCircleElement> = {
        ...bgProps,
        style: {
            strokeDashoffset: targetOffset
        }
    }

    const projectedProps: React.SVGProps<SVGCircleElement> = {
        ...bgProps,
        style: {
            strokeDashoffset: projectedOffset,
            stroke: projected < 84 ? "url(#under)" : "url(#over)"
        }
    }

    const percentToRadians = (percent: number): number => {
        const angle = (percent / 100) * (360 * arcSize);
        return angle * (Math.PI / 180);
    }

    const getLineAndLabelProps = (percent: number, tickLength: number, labelType: LabelType): ILineAndLabelProps => {
        const innerRadius = radius + strokeWidth / 1.5;
        const outerRadius = innerRadius + tickLength;
        const textRadius = outerRadius + strokeWidth / 3;

        const angleInRadians = percentToRadians(percent);

        const cos = Math.cos(angleInRadians);
        const sin = Math.sin(angleInRadians);

        const texty = cy + (textRadius * sin);
        const textx = cx + (textRadius * cos);

        const textPoint = adjustPoint({
            y: cy + (textRadius * sin),
            x: cx + (textRadius * cos)
        }, labelType)

        const x = cx + (innerRadius * cos);
        const y = cy + (innerRadius * sin);

        return {
            percentage: percent,
            text: {
                ...textPoint,
                transform: getTransform(textx, texty),
                textAnchor: getTextAnchor(percent)
            },
            triangle: {
                points: `${x},${y} ${x + 5},${y + 0} ${x + 2.5},${y + 4.3}`,
                transform: `rotate(${angleInRadians * 50}, ${x}, ${y})`
            }

        }

    }

    const adjustPoint = (point: IPoint, type: LabelType): IPoint => {
        let percent = 0;
        if (type === LabelType.Projected) {
            percent = projected;
        } else {
            percent = target;
        }

        if (percent <= 20 || percent >= 75) {
            point = movePointUp(point, -5)
        }

        if (percent >= 33 && percent < 66) {
            point = movePointUp(point, Math.abs(percent - 50) / 2)
        }
        const difference = Math.abs(projected - target);
        if (difference <= 3 && !onTarget) {
            const adjust = 10 - 2.5*difference;
            if (projected > target) {
                point = movePointUp(point, type === LabelType.Projected ? -1 * adjust : adjust)
            } else {
                point = movePointUp(point, type === LabelType.Projected ? adjust : -1 * adjust)
            }
        }

        return point;
    }

    const movePointUp = (point: IPoint, move: number): IPoint => {
        const angleInRadians = (rotate - halfEmptyArc) * (Math.PI / 180)

        const x = point.x - (move * Math.cos(angleInRadians))
        const y = point.y - (move * Math.sin(angleInRadians))

        return {x,y}
    }

    const getTransform = (x: number, y: number): string => `rotate(${-1 * rotate} ${x}  ${y})`;

    const getTextAnchor = (percent: number): string => {
        if (percent < 33) {
            return "end";
        } else if (percent < 66) {
            return "middle";
        } else {
            return "left";
        }
    }

    const getIndicatorColor = (percent: number): string => {
        if (percent <= 75) {
            return "#B00E09";
        } else if (percent < 84) {
            return "#E68B29";
        } else {
            return "#52958B";
        }
    }

    const linesAndLabels: IProjectionValues = {
        projected: getLineAndLabelProps(projected, 3, LabelType.Projected),
        target: getLineAndLabelProps(target, 3, LabelType.Target)
    }
    
    return (
        <div className="text-center mb-n4">
            <div>
                <div style={{
                    position: "relative",
                    display: "inline-block",
                }}>                    
                    <svg
                        style={{
                            transform: `rotate(${rotate}deg)`,
                            fill: "none",
                            overflow: "visible",
                            strokeLinecap: "round"
                        }}
                        width={`${size}px`}
                        height={`${size}px`}
                        viewBox={`0 0 ${size} ${size}`}
                    >
                        <circle {...bgProps} />
                        <circle {...targetProps} />
                        <circle {...projectedProps} />
                        <g strokeLinecap="round" stroke="#000" strokeWidth={2}>
                            <polygon {...linesAndLabels.projected.triangle} style={{ fill: getIndicatorColor(projected), stroke: getIndicatorColor(projected) }} />
                            {!onTarget && <polygon {...linesAndLabels.target.triangle} style={{ fill: '#E68B29', stroke: '#E68B29' }} />}
                        </g>
                        <g fill="#000" fontSize="0.75rem">                            
                            <text {...linesAndLabels.projected.text}>
                                <tspan fontWeight="bold">{displayProj}% Projected</tspan>
                            </text>
                            {!onTarget && <text {...linesAndLabels.target.text}>
                                <tspan fontWeight="bold">{target}% Target</tspan>
                            </text>}
                            {onTarget && <text {...linesAndLabels.target.text}
                                x={linesAndLabels.target.text.x + (displayProj.toString() === displayProj.toFixed(0) ? 24 : 34)}
                                y={linesAndLabels.target.text.y + ((displayProj - 85) * 4 + 16)}
                            >
                                <tspan fontWeight="bold">On Target</tspan>
                            </text>}
                        </g>
                    </svg>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        margin: "auto"
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%"
                        }} className="text-center">
                            <div>
                                <div style={{ fontSize: "1rem" }}><FormatDollar amountStr={text} /></div>
                                <div className="small text-muted">Per Month</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export { ProjectionGauge }