import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetWellBeingResponse, GetWellBeing } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IWellBeingState extends ILoadableState {
    wellBeing: GetWellBeingResponse
}

export const initialWellBeingState: IWellBeingState = {
    wellBeing: {} as GetWellBeingResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'wellBeing',
    initialState: {} as IWellBeingState,
    reducers: {
        resetState: () => {
            return {
                ...initialWellBeingState
            }
        },
        requestWellBeing: (state: IWellBeingState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveWellBeing: (state: IWellBeingState, action: PayloadAction<{ wellBeing: GetWellBeingResponse }>) => {
            const { wellBeing } = action.payload;
            return {
                ...state,
                wellBeing,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveWellBeingError: (state: IWellBeingState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialWellBeingState)
    }
})

const { resetState, requestWellBeing, receiveWellBeing, receiveWellBeingError } = slice.actions;

function fetchWellBeing() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().wellBeing;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestWellBeing());

        try {

            const wellBeing = await client.post(new GetWellBeing());

            dispatch(receiveWellBeing({ wellBeing }));

            //Check for errors
            if (wellBeing.responseStatus)
                throw wellBeing.responseStatus.message;

            //Determine if health page should be displayed based on this data
            if (wellBeing.vpIsQualified || wellBeing.bfwIsQualified)
                dispatch(displayPageActionCreators.showWellBeing());

        } catch (e: unknown) {
            dispatch(receiveWellBeingError());
            console.log(e);
            //dispatch(displayPageActionCreators.showWellBeing());
        }



    }
}

export const wellBeingActionCreators = {
    fetchWellBeing,
    resetState
};

export const wellBeingReducer = slice.reducer;
