import React from 'react';


export interface BAPageHeaderProps { name: string; className?: string; center?: boolean }
export const BAPageHeader = (props: BAPageHeaderProps): React.ReactElement => {
    const { className, name, center } = props;
    const extraClass = className ? ` ${className}` : "";
    return (<div className={`d-flex py-3 pb-n1 font-weight-bolder ${extraClass}`}>
        <h1 className={`d-inline ${center && "mx-auto"}`} dangerouslySetInnerHTML={{ __html: name }}></h1>
    </div>);
};