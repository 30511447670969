const formatSsn = function (ssn: string): string {
    ssn = ssn.replace(/\D/g, '');

    if (ssn.length > 5)
        return ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, ssn.length < 9 ? ssn.length -5 : 4);
    else if (ssn.length > 3)
        return ssn.substr(0, 3) + "-" + ssn.substr(3);
    else
        return ssn;
};
export { formatSsn };
