import { LsriStageCalculations, OracleCalculation } from "../../../../Gateway.dtos";
import { ILsriState } from "../../../../store/LsriStore";


export interface LsriChartNode {
    age: number
    stepType: LsriStepType
    totalAmount: number
    totalAmountWithMin: number
    lsriChartDataPoints: LsriChartDataPoint[]
}

export interface LsriChartDataPoint {
    type: LsriBarType
    amount: number
    minLeft: number
    maxLeft: number
    minRight: number
    maxRight: number
}

export enum LsriStepType {
    Retirement,
    SocialSecurity,
    Qlac
}

export enum LsriBarType {
    Total = "Total",
    LifeStage = "LifeStage",
    SocialSecurity = "SocialSecurity",
    Mpp = "Mpp",
    Qlac = "Qlac"
}

const min = 600

function getUserMatrix(lsri: ILsriState): OracleCalculation[] {
    return lsri.lsriInfo?.oracleLsriElection?.matrix
}


function getMinSsAge(userMatrix: OracleCalculation[], retAge: number): number {

    const age = userMatrix?.reduce(function (prev, current) {
        if (prev.ssAge === 0) return current
        else if (current.ssAge === 0) return prev
        else return prev.ssAge < current.ssAge ? prev : current
    }, { ssAge: 100 } as OracleCalculation).ssAge ?? retAge

    return age === 100 ? retAge : age
}

function getMaxSsAge(userMatrix: OracleCalculation[]): number {

    return userMatrix?.reduce(function (prev, current) {
        return (prev.ssAge > current.ssAge) ? prev : current
    }, { ssAge: 0 } as OracleCalculation).ssAge ?? 70
}


function lsriChartDataPoint(current: LsriStageCalculations, next: LsriStageCalculations, lsriMaxRight: number): LsriChartDataPoint[] {

    if (!current || !next)
        return [] as LsriChartDataPoint[]

    return [
        {
            type: LsriBarType.Mpp,
            amount: current.mpp,
            minLeft: current.socialSecurity + current.qlac + current.lifeStage,
            maxLeft: current.socialSecurity + current.qlac + current.lifeStage + current.mpp,
            minRight: next.socialSecurity + next.qlac + next.lifeStage,
            maxRight: next.socialSecurity + next.qlac + next.lifeStage + next.mpp
        },
        {
            type: LsriBarType.SocialSecurity,
            amount: current.socialSecurity,
            minLeft: current.qlac + current.lifeStage,
            maxLeft: current.qlac + current.lifeStage + current.socialSecurity,
            minRight: next.qlac + next.lifeStage,
            maxRight: next.qlac + next.lifeStage + next.socialSecurity
        },
        {
            type: LsriBarType.Qlac,
            amount: current.qlac,
            minLeft: current.lifeStage,
            maxLeft: current.lifeStage + current.qlac,
            minRight: next.lifeStage,
            maxRight: next.lifeStage + next.qlac
        },
        {
            type: LsriBarType.LifeStage,
            amount: current.lifeStage,
            minLeft: 0,
            maxLeft: current.lifeStage,
            minRight: 0,
            maxRight: lsriMaxRight
        }
    ] as LsriChartDataPoint[]

}

function calculateLsriMaxRight(age: number, minAge: number, socSecAge: number, next: LsriStageCalculations, useBridge: boolean, showSocSecStep: boolean): number {

    if (age === minAge && showSocSecStep)
        return useBridge ? next.lifeStage + next.socialSecurity : next.lifeStage
    else if (age === socSecAge || (age === minAge && !showSocSecStep))
        return next.lifeStage + next.qlac
    else
        return next.lifeStage;
}


function getLsriChartNodes(lsri: ILsriState, retAge: number, getOriginal?: boolean, isTable?: boolean, enforceMin?: boolean): LsriChartNode[] {

    //Get data
    //const userMatrix = getUserMatrix(lsri)
    const selectedMatrix = getOriginal ? lsri.systemElection : lsri.userElection

    if (selectedMatrix === undefined)
        return [] as LsriChartNode[];

    //Calculate ages
    //const lsriCommAge = lsri?.lsriInfo?.oracleLsriElection?.matrixCommon?.lsriCommAge;
    const lsriAge = getLsriAge(lsri, retAge);
    const socSecAge = selectedMatrix.ssAge
    const qlacAge = lsri?.lsriInfo?.oracleLsriElection?.systemDefault?.qlacAge ?? 80

    //Determine selected options
    const hideSocSecAge = socSecAge <= lsriAge || lsri.bridgeIneligible
    const hideQlacAge = lsri.qlacIneligible || (!isTable && !selectedMatrix?.scenario?.includes("Q"))
    const useBridge = selectedMatrix?.scenario?.includes("B")
    const supressSocSecOnLs = !useBridge && socSecAge > lsriAge

    //Determine start and end phases
    const { lsriStart, socSecStart, qlacStart, end } = selectedMatrix
    const lsriNext = !hideSocSecAge ? socSecStart : (!hideQlacAge ? qlacStart : end)
    const socSecNext = !hideQlacAge ? qlacStart : end
    const qlacNext = end

    const lsriChartNodes: (LsriChartNode)[] = [];

    if (lsriStart) {
        const totalAmount = (supressSocSecOnLs ? 0 : lsriStart.socialSecurity) + lsriStart.qlac + lsriStart.lifeStage + lsriStart.mpp
        const totalAmountWithMin = isGreaterThanMin(supressSocSecOnLs ? 0 : lsriStart.socialSecurity) + isGreaterThanMin(lsriStart.qlac) + isGreaterThanMin(lsriStart.lifeStage) + isGreaterThanMin(lsriStart.mpp)

        lsriChartNodes.push({
            age: lsriAge,
            stepType: LsriStepType.Retirement,
            totalAmount,
            totalAmountWithMin,
            lsriChartDataPoints: lsriChartDataPoint(
                enforceMinimum(enforceMin ?? false, supressSocSecOnLs ? { ...lsriStart, socialSecurity: 0 } : lsriStart),
                enforceMinimum(enforceMin ?? false, lsriNext),
                calculateLsriMaxRight(lsriAge, lsriAge, socSecAge, enforceMinimum(enforceMin ?? false, lsriNext), useBridge, !hideSocSecAge)
            )
        } as LsriChartNode)
    }

    if (!hideSocSecAge && socSecStart) {
        const totalAmount = socSecStart.socialSecurity + socSecStart.qlac + socSecStart.lifeStage + socSecStart.mpp
        const totalAmountWithMin = isGreaterThanMin(socSecStart.socialSecurity) + isGreaterThanMin(socSecStart.qlac) + isGreaterThanMin(socSecStart.lifeStage) + isGreaterThanMin(socSecStart.mpp)

        lsriChartNodes.push({
            age: socSecAge,
            stepType: LsriStepType.SocialSecurity,
            totalAmount,
            totalAmountWithMin,
            lsriChartDataPoints: lsriChartDataPoint(
                enforceMinimum(enforceMin ?? false, socSecStart),
                enforceMinimum(enforceMin ?? false, socSecNext),
                calculateLsriMaxRight(socSecAge, lsriAge, socSecAge, enforceMinimum(enforceMin ?? false, socSecNext), useBridge, !hideSocSecAge)
            )
        } as LsriChartNode)
    }

    if (!hideQlacAge && qlacStart) {
        const totalAmount = qlacStart.socialSecurity + qlacStart.qlac + qlacStart.lifeStage + qlacStart.mpp
        const totalAmountWithMin = isGreaterThanMin(qlacStart.socialSecurity) + isGreaterThanMin(qlacStart.qlac) + isGreaterThanMin(qlacStart.lifeStage) + isGreaterThanMin(qlacStart.mpp)

        lsriChartNodes.push({
            age: qlacAge,
            stepType: LsriStepType.Qlac,
            totalAmount,
            totalAmountWithMin,
            lsriChartDataPoints: lsriChartDataPoint(
                enforceMinimum(enforceMin ?? false, qlacStart),
                enforceMinimum(enforceMin ?? false, qlacNext),
                calculateLsriMaxRight(qlacAge, lsriAge, socSecAge, enforceMinimum(enforceMin ?? false, qlacNext), useBridge, !hideSocSecAge)
            )
        } as LsriChartNode)
    }

    return lsriChartNodes

}

function enforceMinimum(enforceMin: boolean, inputCalculations: LsriStageCalculations): LsriStageCalculations {

    if (!enforceMin)
        return inputCalculations;

    return {
        lifeStage: isGreaterThanMin(inputCalculations.lifeStage),
        socialSecurity: isGreaterThanMin(inputCalculations.socialSecurity),
        mpp: isGreaterThanMin(inputCalculations.mpp),
        qlac: isGreaterThanMin(inputCalculations.qlac)
    } as LsriStageCalculations
}

function isGreaterThanMin(val: number): number {
    return val === 0 || val >= min ? val : min
}

function getPlanFullName(planName: string): string {
    switch (planName) {
        case "MPP35":
            return "Ministerial Pension Plan"
        case "CRSPDC":
            return "Clergy Retirement Security Program"
        case "RPGA":
            return "Retirement Plan for General Agencies"
        case "UMPIP":
            return "United Methodist Personal Investment Plan"
        case "HORIZON":
        case "HRZ":
            return "Horizon 401(k) Plan"
        default:
            return planName
    }
}

function getLsriAge(lsri: ILsriState, retAge: number): number {
    const userMatrix = getUserMatrix(lsri);
    const lsriCommAge = lsri?.lsriInfo?.oracleLsriElection?.matrixCommon?.lsriCommAge;

    return lsriCommAge ?? getMinSsAge(userMatrix, retAge);
}

export { getUserMatrix, getMinSsAge, getMaxSsAge, getLsriChartNodes, getPlanFullName, getLsriAge };