import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from 'redux';
import { RequestType } from '../../Gateway.dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import { loginHelpCreators } from '../../store/LoginHelpStore';
import { AppDispatch } from '../..';
import FetchData from '../FetchData';


type TSuccessProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Success = (props: TSuccessProps) => {

    const viewSummary = () => {
        props.goToSummary();
    }

    const viewPersonalInfo = () => {
        props.goToAccountMgmt();
    }

    useEffect(() => {
        document.title = "Benefits Access - Success"
    }, []);

    const colClassName = "col-12 col-lg-10 col-xl-9 loginBox";
    return (<>

        <FetchData />

        <Row className="justify-content-center mt-5">
            <Col className={colClassName}>
                <Card className="fullPageCard" style={{ minHeight: "30em" }}>
                    <Card.Body>

                        <h1 className="mb-5 success-green">
                            <FontAwesomeIcon className="mr-2"
                                icon={props.requestType === RequestType.REGISTRATION ? faUserCheck : faShieldCheck}
                            />
                            Success
                        </h1>

                        <Row style={{ minHeight: "12em"}}>
                            <Col>
                                {props.requestType === RequestType.REGISTRATION && <>
                                    <p className="text-left">
                                        You have successfully setup your account. You can now use Wespath&apos;s Benefits Access to 
                                        review your benefits, obtain information on your plans or project future retirement benefits, 
                                        24 hours a day, seven days a week.  
                                    </p>

                                    <p className="text-left">
                                        We encourage you to review your settings and make sure we have your most up to date
                                        information. <Button className="p-0" variant="link" onClick={() => (viewPersonalInfo())}>
                                            Verify & update your personal info
                                        </Button>
                                    </p>
                                </>}

                                {props.requestType === RequestType.NEEDHELP && <p>
                                    You have successfully changed your password.
                                </p>}

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {props.isLoading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : <Button variant="primary" onClick={() => (viewSummary())}>
                                        View Your Account
                                    </Button>
                                }
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>);

};

function mapStateToProps(state: AppState) {
    const { requestType } = state.loginHelp;

    return {
        ...state.account,
        requestType
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { goToAccountMgmt, goToSummary } = loginHelpCreators
    return bindActionCreators({
        goToAccountMgmt,
        goToSummary
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Success);

