import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from '@reduxjs/toolkit';
import { accountActionCreators } from '../../store/AccountStore';
import { GatewayPath } from '@wespath/gateway-navigation'
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { AppDispatch } from '../..';
import { cmsActionCreators } from '../../store/CmsStore';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { resetApplication } from '../../functions/resetApplication';
import Skeleton from 'react-loading-skeleton';
import { activityActionCreators } from '../../store/ActivityStore';

type TLogoutProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const Logoff = (props: TLogoutProps) => {
    const dispatch = useDispatch();
    const colClassName = "col-12 col-lg-10 col-xl-9";

    useEffect(() => {
        document.title = "Benefits Access - Logoff"
    }, []);

    const cmsData = props.cmsData.fragments?.find(f => f.name === "LogoffMessage");

    useEffect(() => {
        if (!stateNeedsLoading(props.account)) {
            dispatch(accountActionCreators.logout());
        }

        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const cmsIsLoading = cmsData === undefined;

    return (
        <Row className="justify-content-center mt-5">
            <Col className={colClassName}>
                <Card className="fullPageCard" style={{ minHeight: '30rem' }}>
                    <Card.Title>
                        You have successfully logged out.
                    </Card.Title>

                    <Card.Body>

                        {cmsIsLoading ?
                            <>
                                <p className="mt-5"><Skeleton count={3} /></p>
                                <p className="mb-5"><Skeleton count={2} /></p>
                            </> : <>

                                {cmsData ? <p className="my-5 thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                                <div className="d-inline">
                                    <NavLink to={GatewayPath.Login} className="pl-0 pr-5" activeClassName="active" onClick={() => dispatch(activityActionCreators.logActivity("button_loginAgain"))}>
                                        <Button variant="outline-primary" className="mb-3 mb-md-0">Log In Again</Button>
                                    </NavLink>

                                    <a href="http://www.wespath.org" target="_blank" rel="noopener noreferrer" onClick={() => dispatch(activityActionCreators.logActivity("link_wespath.org"))}>
                                        Wespath&apos;s Public Website
                                    </a>
                                </div>
                            </>
                        }

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

}

function mapStateToProps(state: AppState) {

    return {
        account:state.account,
        cmsData: state.cms.logout
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...accountActionCreators,
        fetchCmsData: cmsActionCreators.fetchLogoutCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logoff);
