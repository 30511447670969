import React, { useEffect } from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../..';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { LsriStage } from '../../../../Gateway.dtos';
import { cmsActionCreators } from '../../../../store/CmsStore';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriTextBelowSidePanel = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { lsri } = props

    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Shared"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const menuReviewSetupMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/MenuReviewSetupMsg`)?.html ?? '';
    const menuQuestionsMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/MenuQuestionsMsg`)?.html ?? '';
    //End CMS Section

    if (cmsIsLoading)
        return null


    return (<>

        {lsri.wizardType === LsriStage.Setup && !isLoading &&
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: menuReviewSetupMsgCms }} />}

        <div dangerouslySetInnerHTML={{ __html: menuQuestionsMsgCms}}/>

    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriTextBelowSidePanel);

