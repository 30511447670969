import { AppState } from "./configureStore";
import { CmsFragment, GetCmsFragmentCollection } from "./../Gateway.dtos";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IAccountManagementCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}
export interface IHealthCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IHealthAECmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IWellBeingCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ILoginCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ILogoutCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IRetirementCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ISummaryCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ILifeAndDisabilityCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ICmsPageTitleState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ILsriCmsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IInvestmentsSummaryState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ISelfManagedRetirementIncomeState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IMPPDistributionState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ILifeStageInvestmentOptionState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IDirectDepositState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IUpdateBankInfotState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface IPaymentsState extends IEnumLoadableState {
    fragments: CmsFragment[]
}

export interface ICmsState {
    accountmanagement:IAccountManagementCmsState,
    health: IHealthCmsState,
    healthAE: IHealthCmsState,
    wellbeing: IWellBeingCmsState,
    login: ILoginCmsState,
    logout: ILogoutCmsState,
    retirement: IRetirementCmsState,
    summary: ISummaryCmsState,
    lifeanddisability: ILifeAndDisabilityCmsState,
    pageTitleState: ICmsPageTitleState,
    lsri: ILsriCmsState,
    investmentsSummary: IInvestmentsSummaryState,
    selfManagedRetirementIncome: ISelfManagedRetirementIncomeState,
    mppDistribution: IMPPDistributionState,
    lifeStageInvestmentOption: ILifeStageInvestmentOptionState,
    directDeposit: IDirectDepositState,
    updateBankInfo: IUpdateBankInfotState,
    payments: IPaymentsState,
}

export const initialCmsState: ICmsState = {
    accountmanagement: { loadState: DataLoadState.None } as IAccountManagementCmsState,
    health: { loadState: DataLoadState.None } as IHealthCmsState,
    healthAE: { loadState: DataLoadState.None } as IHealthAECmsState,
    wellbeing: { loadState: DataLoadState.None } as IWellBeingCmsState,
    login: { loadState: DataLoadState.None } as ILoginCmsState,
    logout: { loadState: DataLoadState.None } as ILogoutCmsState,
    retirement: { loadState: DataLoadState.None } as IRetirementCmsState,
    summary: { loadState: DataLoadState.None } as ISummaryCmsState,
    lifeanddisability: { loadState: DataLoadState.None } as ILifeAndDisabilityCmsState,
    pageTitleState: { loadState: DataLoadState.None } as ICmsPageTitleState,
    lsri: { loadState: DataLoadState.None } as ILsriCmsState,
    investmentsSummary: { loadState: DataLoadState.None } as IInvestmentsSummaryState,
    selfManagedRetirementIncome: { loadState: DataLoadState.None } as ISelfManagedRetirementIncomeState,
    mppDistribution: { loadState: DataLoadState.None } as IMPPDistributionState,
    lifeStageInvestmentOption: { loadState: DataLoadState.None } as ILifeStageInvestmentOptionState,
    directDeposit: { loadState: DataLoadState.None } as IDirectDepositState,
    updateBankInfo: { loadState: DataLoadState.None } as IUpdateBankInfotState,
    payments: { loadState: DataLoadState.None } as IDirectDepositState
};


const slice = createSlice({
    name: 'cmsData',
    initialState: initialCmsState,
    reducers: {
        resetState: () => {
            return {
                ...initialCmsState
            }
        },
        requestHealthCmsData: (state: ICmsState) => {
            return {
                ...state,
                health: {
                    ...state.health,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveHealthCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                health: {
                    ...state.health,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveHealthCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                health: {
                    ...state.health,
                    loadState: DataLoadState.Error
                }
                
            }
        },
        requestHealthAECmsData: (state: ICmsState) => {
            return {
                ...state,
                healthAE: {
                    ...state.healthAE,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveHealthAECmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                healthAE: {
                    ...state.healthAE,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveHealthAECmsDataError: (state: ICmsState) => {
            return {
                ...state,
                healthAE: {
                    ...state.healthAE,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestAccountManagementCmsData: (state: ICmsState) => {
            return {
                ...state,
                accountmanagement: {
                    ...state.accountmanagement,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveAccountManagementCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                accountmanagement: {
                    ...state.accountmanagement,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveAccountManagementCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                accountmanagement: {
                    ...state.accountmanagement,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestWellBeingCmsData: (state: ICmsState) => {
            return {
                ...state,
                wellbeing: {
                    ...state.wellbeing,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveWellBeingCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                wellbeing: {
                    ...state.wellbeing,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveWellBeingCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                wellbeing: {
                    ...state.wellbeing,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestLoginCmsData: (state: ICmsState) => {
            return {
                ...state,
                login: {
                    ...state.login,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveLoginCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                login: {
                    ...state.login,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveLoginCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                login: {
                    ...state.login,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestLogoutCmsData: (state: ICmsState) => {
            return {
                ...state,
                logout: {
                    ...state.logout,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveLogoutCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                logout: {
                    ...state.logout,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveLogoutCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                logout: {
                    ...state.logout,
                    loadState: DataLoadState.Error
                }

            }
        },


        requestRetirementCmsData: (state: ICmsState) => {
            return {
                ...state,
                retirement: {
                    ...state.retirement,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveRetirementCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                retirement: {
                    ...state.retirement,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveRetirementCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                retirement: {
                    ...state.retirement,
                    loadState: DataLoadState.Error
                }

            }
        },



        requestSummaryCmsData: (state: ICmsState) => {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveSummaryCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                summary: {
                    ...state.summary,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveSummaryCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loadState: DataLoadState.Error
                }

            }
        },

        requestLifeAndDisabilityCmsData: (state: ICmsState) => {
            return {
                ...state,
                lifeanddisability: {
                    ...state.lifeanddisability,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveLifeAndDisabilityCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                lifeanddisability: {
                    ...state.lifeanddisability,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveLifeAndDisabilityCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                lifeanddisability: {
                    ...state.lifeanddisability,
                    loadState: DataLoadState.Error
                }

            }
        },


        requestCmsPageTitleData: (state: ICmsState) => {
            return {
                ...state,
                pageTitleState: {
                    ...state.pageTitleState,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveCmsPageTitleData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                pageTitleState: {
                    ...state.pageTitleState,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveCmsPageTitleDataError: (state: ICmsState) => {
            return {
                ...state,
                pageTitleState: {
                    ...state.pageTitleState,
                    loadState: DataLoadState.Error
                }

            }
        },

        requestLsriCmsData: (state: ICmsState) => {
            return {
                ...state,
                lsri: {
                    ...state.lsri,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveLsriCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                lsri: {
                    ...state.lsri,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveLsriCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                lsri: {
                    ...state.lsri,
                    loadState: DataLoadState.Error
                }

            }
        },

        requestInvestmentsSummaryCmsData: (state: ICmsState) => {
            return {
                ...state,
                investmentsSummary: {
                    ...state.investmentsSummary,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveInvestmentsSummaryCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                investmentsSummary: {
                    ...state.investmentsSummary,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveInvestmentsSummaryCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                investmentsSummary: {
                    ...state.investmentsSummary,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestSelfManagedRetirementIncomeCmsData: (state: ICmsState) => {
            return {
                ...state,
                selfManagedRetirementIncome: {
                    ...state.selfManagedRetirementIncome,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveSelfManagedRetirementIncomeCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                selfManagedRetirementIncome: {
                    ...state.selfManagedRetirementIncome,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveSelfManagedRetirementIncomeCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                selfManagedRetirementIncome: {
                    ...state.selfManagedRetirementIncome,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestMPPDistributionCmsData: (state: ICmsState) => {
            return {
                ...state,
                mppDistribution: {
                    ...state.mppDistribution,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveMPPDistributionCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                mppDistribution: {
                    ...state.mppDistribution,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveMPPDistributionCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                mppDistribution: {
                    ...state.mppDistribution,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestLifeStageInvestmentOptionCmsData: (state: ICmsState) => {
            return {
                ...state,
                lifeStageInvestmentOption: {
                    ...state.lifeStageInvestmentOption,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveLifeStageInvestmentOptionCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                lifeStageInvestmentOption: {
                    ...state.lifeStageInvestmentOption,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveLifeStageInvestmentOptionCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                lifeStageInvestmentOption: {
                    ...state.lifeStageInvestmentOption,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestDirectDepositCmsData: (state: ICmsState) => {
            return {
                ...state,
                directDeposit: {
                    ...state.directDeposit,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveDirectDepositCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                directDeposit: {
                    ...state.directDeposit,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveDirectDepositCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                directDeposit: {
                    ...state.directDeposit,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestUpdateBankInfoCmsData: (state: ICmsState) => {
            return {
                ...state,
                updateBankInfo: {
                    ...state.updateBankInfo,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receiveUpdateBankInfoCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                updateBankInfo: {
                    ...state.updateBankInfo,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receiveUpdateBankInfoCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                updateBankInfo: {
                    ...state.updateBankInfo,
                    loadState: DataLoadState.Error
                }

            }
        },
        requestPaymentsCmsData: (state: ICmsState) => {
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loadState: DataLoadState.Loading
                }
            }
        },
        receivePaymentsCmsData: (state: ICmsState, action: PayloadAction<{ fragments: CmsFragment[] }>) => {
            const { fragments } = action.payload;
            return {
                ...state,
                payments: {
                    ...state.payments,
                    fragments,
                    loadState: DataLoadState.Loaded
                }
            }
        },
        receivePaymentsCmsDataError: (state: ICmsState) => {
            return {
                ...state,
                payments: {
                    ...state.payments,
                    loadState: DataLoadState.Error
                }

            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (state, action) => {
                const isEySearch = !!action?.payload?.isEySearch
                if (isEySearch) {
                    return state
                } else {
                    return {
                        ...initialCmsState,
                        logout: state.logout,
                        login: state.login
                    }
                }
            })
    }
})

const { resetState } = slice.actions;

function fetchHealthCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.health;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestHealthCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/Health" }));

            dispatch(slice.actions.receiveHealthCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveHealthCmsDataError());
            //console.log(e);
        }

    }
}

function fetchHealthAECmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.healthAE;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestHealthAECmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/HealthAE" }));

            dispatch(slice.actions.receiveHealthAECmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveHealthAECmsDataError());
            //console.log(e);
        }

    }
}

function fetchAccountManagementCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.accountmanagement;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestAccountManagementCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/AccountManagement" }));

            dispatch(slice.actions.receiveAccountManagementCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveAccountManagementCmsDataError());
            //console.log(e);
        }

    }
}

function fetchWellBeingCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.wellbeing;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestWellBeingCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/Wellbeing" }));

            dispatch(slice.actions.receiveWellBeingCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveWellBeingCmsDataError());
            //console.log(e);
        }

    }
}

function fetchLoginCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.login;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestLoginCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/Login" }));

            dispatch(slice.actions.receiveLoginCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveLoginCmsDataError());
            //console.log(e);
        }

    }
}

function fetchLogoutCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.logout;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestLogoutCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/Logoff" }));

            dispatch(slice.actions.receiveLogoutCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveLogoutCmsDataError());
            //console.log(e);
        }

    }
}

function fetchRetirementCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.retirement;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestRetirementCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/Retirement" }));

            dispatch(slice.actions.receiveRetirementCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveRetirementCmsDataError());
            //console.log(e);
        }

    }
}

function fetchSummaryCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.summary;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestSummaryCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/Summary" }));

            dispatch(slice.actions.receiveSummaryCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveSummaryCmsDataError());
            //console.log(e);
        }

    }
}


function fetchLifeAndDisabilityCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.lifeanddisability;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestLifeAndDisabilityCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/LifeAndDisability" }));

            dispatch(slice.actions.receiveLifeAndDisabilityCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveLifeAndDisabilityCmsDataError());
            //console.log(e);
        }

    }
}

function fetchCmsPageTitleData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.pageTitleState;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestCmsPageTitleData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/CmsPageTitle" }));

            dispatch(slice.actions.receiveCmsPageTitleData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveCmsPageTitleDataError());
            //console.log(e);
        }

    }
}

function fetchLsriCmsData(subPath: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {

        if (getState().cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${subPath}/`)) >= 0)
            return

        dispatch(slice.actions.requestLsriCmsData());

        try {
            const path = `/LSRI/${subPath}`
            const cmsData = await client.get(new GetCmsFragmentCollection({ path }));

            const fragments = getState().cms.lsri?.fragments ? [...getState().cms.lsri?.fragments] : [] as CmsFragment[];
            cmsData.fragments.forEach(f => fragments.push({ name: `${subPath}/${f.name}`, html: f.html } as CmsFragment))

            dispatch(slice.actions.receiveLsriCmsData({ fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveLsriCmsDataError());
            //console.log(e);
        }

    }
}

function fetchInvestmentsSummaryCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.investmentsSummary;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestInvestmentsSummaryCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/InvestmentsSummary" }));

            dispatch(slice.actions.receiveInvestmentsSummaryCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveInvestmentsSummaryCmsDataError());
            //console.log(e);
        }

    }
}

function fetchSelfManagedRetirementIncomeCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.selfManagedRetirementIncome;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestSelfManagedRetirementIncomeCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/SelfManagedRetirementIncome" }));

            dispatch(slice.actions.receiveSelfManagedRetirementIncomeCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveSelfManagedRetirementIncomeCmsDataError());
            //console.log(e);
        }

    }
}

function fetchMPPDistributionCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.mppDistribution;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestMPPDistributionCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/MPPDistribution" }));

            dispatch(slice.actions.receiveMPPDistributionCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveMPPDistributionCmsDataError());
            //console.log(e);
        }

    }
}

function fetchLifeStageInvestmentOptionCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.lifeStageInvestmentOption;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestLifeStageInvestmentOptionCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/LifeStageInvestmentOptions" }));

            dispatch(slice.actions.receiveLifeStageInvestmentOptionCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveLifeStageInvestmentOptionCmsDataError());
        }

    }
}

function fetchDirectDepositCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.directDeposit;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestDirectDepositCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/DirectDeposit" }));

            dispatch(slice.actions.receiveDirectDepositCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveDirectDepositCmsDataError());
            console.log(e);
        }

    }
}

function fetchPaymentsCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.payments;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
                return;
        }

        dispatch(slice.actions.requestPaymentsCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/Payments" }));

            dispatch(slice.actions.receivePaymentsCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receivePaymentsCmsDataError());
            console.log(e);
        }

    }
}

function fetchUpdateBankInfoCmsData() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { loadState } = getState().cms.updateBankInfo;
        if (loadState === DataLoadState.Loaded || loadState === DataLoadState.Loading) {
            return;
        }

        dispatch(slice.actions.requestUpdateBankInfoCmsData());

        try {

            const cmsData = await client.get(new GetCmsFragmentCollection({ path: "/UpdateBankInfo" }));

            dispatch(slice.actions.receiveUpdateBankInfoCmsData({ fragments: cmsData.fragments }));

        } catch (e: unknown) {
            dispatch(slice.actions.receiveUpdateBankInfoCmsDataError());
            console.log(e);
        }

    }
}

export const cmsActionCreators = {
    fetchAccountManagementCmsData,
    fetchHealthCmsData,
    fetchHealthAECmsData,
    fetchWellBeingCmsData,
    fetchLoginCmsData,
    fetchLogoutCmsData,
    fetchRetirementCmsData,
    fetchSummaryCmsData,
    fetchLifeAndDisabilityCmsData,
    fetchCmsPageTitleData,
    fetchLsriCmsData,
    fetchInvestmentsSummaryCmsData,
    resetState,
    fetchSelfManagedRetirementIncomeCmsData,
    fetchMPPDistributionCmsData,
    fetchLifeStageInvestmentOptionCmsData,
    fetchDirectDepositCmsData,
    fetchUpdateBankInfoCmsData,
    fetchPaymentsCmsData
};

export const cmsReducer = slice.reducer;
