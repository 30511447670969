import React from "react";
import { Col } from "react-bootstrap";
import { parseCmsContent } from "../functions/parseCmsContent";



export interface LabelAndContentProps {
    label: string;
    children: string | React.ReactElement | React.ReactElement[];
    className: string;
    isRequired?: boolean;
    title?: string;
}

const LabelAndContent = (props: LabelAndContentProps): React.ReactElement => {

    return (
        <Col className={props.className}>
            <h5 className="text-secondary mb-0 pr-3" title={props.title}>
                {
                    props.isRequired
                        ? <>{props.label} <span style={{ color: "red" }}>*</span></>
                        : <><span dangerouslySetInnerHTML={parseCmsContent(props.label)} /></>
                }
            </h5>
            {props.children}
        </Col>
    )
};


export { LabelAndContent };
