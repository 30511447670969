import React, { ReactElement, useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { useSelector } from 'react-redux';
import { ServiceProvider } from '../../../Gateway.dtos';
import { SamlSelfPostForm } from '@wespath/gateway-navigation';
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';
import { AppDispatch } from '../../..';
import { cmsActionCreators } from '../../../store/CmsStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';

type TReviewPastClaimsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const ReviewPastClaims = (props: TReviewPastClaimsProps): ReactElement => {

    const cmsData = props.cmsData.fragments?.find(f => f.name === "ReviewPastPayment");

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const hasClaim_HealthAccess = useSelector((state: AppState) => state.claims.claims?.claims?.find(c => c === Health.Access))
    const username = useSelector((state: AppState) => state.account.username)

    const serviceProvider = ServiceProvider.Quantum;

    const referrer = 'b'
    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);
    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent")
            formRef?.current?.submit();
        else
            setShowingSsoModal(true);
    }


    return (
        <>
            <Card className="pageCard secondary">
                <Card.Title>
                    <FontAwesomeIcon icon={faFileCheck} className="health-color smaller mr-0 pr-0" />
                    Review Your 2024 Claims
                </Card.Title>
                <Card.Body className='reviewPastClaims'>

                    {!cmsData ? <LoadingSkeleton /> : <>

                        {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}
                        <p className="thin">Contact your Care Coordinator if you have questions about what your current claims might cost if you choose a different plan in 2025.</p>
                    </>}
                    <Button className={`${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
                        variant="secondary"
                        onClick={handleSubmit}
                        disabled={!hasClaim_HealthAccess}
                    >
                    Log Into Your Care Coordination Account
                    </Button>

                </Card.Body>
            </Card>
            <SamlSelfPostForm ref={formRef} provider={serviceProvider} source={referrer} />
            <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer={referrer} />
        </>

    );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewPastClaims);
