import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const ToolTip = (props: { title?: string, children?: React.ReactElement | string | undefined, className?: string, suppressIcon?: boolean }): React.ReactElement => {

    if (!props.title)
        return (
            <span className="tooltip-button-wrapper">
                <span className={props.className}>{props.children}</span>
            </span>
        )

    return (
        <OverlayTrigger
            trigger="click"
            key='top-start'
            placement='top-start'
            rootClose
            overlay={
                <Popover id={`popover-positioned-top-start`} className="tooltip-popover">
                    <Popover.Content>
                        {props.title}
                    </Popover.Content>
                </Popover>
            }
        >
            <Button variant="link" className="tooltip-button-wrapper">
                <span className={props.className} style={props.suppressIcon ? { textDecoration: 'underline' } : {}}>{props.children}</span>
                {!props.suppressIcon && <FontAwesomeIcon icon={faInfoCircle} className="ml-1 tooltip-icon" />}
            </Button>
        </OverlayTrigger>
    )
};

export { ToolTip };
