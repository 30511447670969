import { Dispatch } from "react";
import { AppState } from "../configureStore";
import { createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../../definitions/ILoadableState";
import { ChangeUsername, GetDuplicateUsernameResponse, GetDuplicateUsernameValidation } from "../../Gateway.dtos";
import { client } from "../../App";
import { contactInfoActionCreators } from "./ContactInfoStore";
import { accountActionCreators } from "../AccountStore";
import { passwordActionCreators } from "./PasswordStore";
import { getResetAction } from "../../functions/getResetAction";
import { authMultiFactorActionCreators } from "../AuthMultiFactorStore";
import { AppDispatch } from "../..";

interface IState extends ILoadableState {
    isEditing: boolean
    showSuccessMsg: boolean
    duplicateUsernameIsLoading: boolean
    duplicateUsernameIsLoaded: boolean
    isNotDuplicateUsername: boolean
}

const initialState: IState = {
    isEditing: false,
    showSuccessMsg: false,
    duplicateUsernameIsLoading: false,
    duplicateUsernameIsLoaded: false,
    isNotDuplicateUsername: false,
    isLoading: false,
    isLoaded: false,
    error: false
};

const slice = createSlice({
    name: 'UpdateUsername',
    initialState: {} as IState,
    reducers: {
        resetState: () => {
            return {
                ...initialState
            }
        },
        setIsEditing: (state: IState, action: PayloadAction<{ isEditing: boolean }>) => {
            const { isEditing } = action.payload;
            return {
                ...state,
                isEditing
            }
        },
        setIsLoading: (state: IState, action: PayloadAction<{ isLoading: boolean }>) => {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoading: isLoading
            }
        },
        requestUpdate: (state: IState) => {
            return {
                ...state,
                isLoading: true,
                error: false,
                showSuccessMsg: false
            }
        },
        receiveUpdate: (state: IState) => {
            return {
                ...state,
                isLoading: false,
                error: false,
                showSuccessMsg: true,
                isEditing: false
            }
        },
        receiveUpdateError: (state: IState) => {
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                error: true
            }
        },
        hideSuccessMsg: (state: IState) => {
            return {
                ...state,
                showSuccessMsg: false
            }
        },
        requestUsernameCheck: (state: IState) => {
            return {
                ...state,
                duplicateUsernameIsLoading: true,
                duplicateUsernameIsLoaded: false
            }
        },
        receiveUsernameCheck: (state: IState, action: PayloadAction<{ isDuplicate: boolean }>) => {
            const { isDuplicate } = action.payload;
            return {
                ...state,
                duplicateUsernameIsLoading: false,
                duplicateUsernameIsLoaded: true,
                isNotDuplicateUsername: !isDuplicate
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialState)
    }
})

const {
    resetState,
    setIsEditing,
    requestUpdate,
    receiveUpdate,
    receiveUpdateError,
    hideSuccessMsg,
    requestUsernameCheck,
    receiveUsernameCheck
} = slice.actions;

function updateUsername(newUsername: string) {
    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().username;
        if (isLoading) {
            return;
        }

        try {

            //Check for duplicate username again
            dispatch(requestUsernameCheck());

            const duplicateUsernameResponse = await client.post(new GetDuplicateUsernameValidation({ username: newUsername }));

            dispatch(receiveUsernameCheck({ isDuplicate: duplicateUsernameResponse.isDuplicate }));

            if (duplicateUsernameResponse.isDuplicate)
                return;



            //Change username
            dispatch(requestUpdate());

            const changeUsernameResponse = await client.post(new ChangeUsername({ newUsername }));

            if (changeUsernameResponse.responseStatus)
                throw changeUsernameResponse.responseStatus.message;

            dispatch(accountActionCreators.setUsername({ username: newUsername }));
            dispatch(receiveUpdate());
            dispatch(setIsEditing({ isEditing: false }));
            

        }
        catch (e: unknown) {
            dispatch(receiveUpdateError());
            console.log(e);
        }

    }
}

function beginEditing() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        dispatch(slice.actions.setIsLoading({ isLoading: true }));
        await dispatch(authMultiFactorActionCreators.fetchTwoFactorStatus());
        dispatch(slice.actions.setIsLoading({ isLoading: false }));
        if (getState().authMultiFactor.hasCompletedMultiFactor) {
            //Cancel the other editable cards
            dispatch(contactInfoActionCreators.setIsEditing({ isEditing: false }));
            dispatch(passwordActionCreators.setIsEditing({ isEditing: false }));

            //Switch to edit mode
            dispatch(setIsEditing({ isEditing: true }));
        } else {
            dispatch(authMultiFactorActionCreators.beginTransactionChallenge({transaction: 'username'}))
        }
    }
}

function checkDuplicateUsername(username: string) {

    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState): Promise<boolean> => {

        //Username is always invalid if less than 5, dont even check the server
        if (!username || username?.length < 5) {
            dispatch(receiveUsernameCheck({ isDuplicate: true }));
            return true;
        }

        try {

            dispatch(requestUsernameCheck());

            const duplicateUsernameResponse = await client.post(
                new GetDuplicateUsernameValidation({ username })
            );

            dispatch(receiveUsernameCheck({ isDuplicate: duplicateUsernameResponse.isDuplicate }));

            return duplicateUsernameResponse.isDuplicate;

        } catch (e: unknown) {
            const response: GetDuplicateUsernameResponse = e as GetDuplicateUsernameResponse;
            dispatch(receiveUsernameCheck({ isDuplicate: true }));
            console.log("Error returned for duplicateUsernameResponse: ", response?.responseStatus ? response.responseStatus.message : e);

            return true;
        }

    }
}

export const usernameActionCreators = {
    resetState,
    beginEditing,
    setIsEditing,
    updateUsername,
    hideSuccessMsg,
    checkDuplicateUsername
};

export const usernameReducer = slice.reducer;
