const isValidPhone = (val: string, isDomestic: boolean, isOptional?: boolean): boolean => {

    val = val.replace(/\D/g, '');

    if ((isOptional === undefined || isOptional) && val.length === 0)
        return true;
    else if (isDomestic)
        return val.length === 10;
    else
        return val.length >= 7;
}
export { isValidPhone };