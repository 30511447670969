import { Dispatch } from "react";
import { AppState } from "../configureStore";
import { createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../../definitions/ILoadableState";
import { ChangePassword } from "../../Gateway.dtos";
import { client } from "../../App";
import { contactInfoActionCreators } from "./ContactInfoStore";
import { usernameActionCreators } from "./UsernameStore";
import { getResetAction } from "../../functions/getResetAction";
import { AppDispatch } from "../..";
import { authMultiFactorActionCreators } from "../AuthMultiFactorStore";

interface IState extends ILoadableState {
    isEditing: boolean
    showSuccessMsg: boolean
}

const initialState: IState = {
    isEditing: false,
    showSuccessMsg: false,
    isLoading: false,
    isLoaded: false,
    error: false
};

const slice = createSlice({
    name: 'UpdatePassword',
    initialState: {} as IState,
    reducers: {
        resetState: () => {
            return {
                ...initialState
            }
        },
        setIsEditing: (state: IState, action: PayloadAction<{ isEditing: boolean }>) => {
            const { isEditing } = action.payload;
            return {
                ...state,
                isEditing
            }
        },
        setIsLoading: (state: IState, action: PayloadAction<{ isLoading: boolean }>) => {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoading: isLoading
            }
        },
        requestUpdate: (state: IState) => {
            return {
                ...state,
                isLoading: true,
                error: false,
                showSuccessMsg: false
            }
        },
        receiveUpdate: (state: IState) => {
            return {
                ...state,
                isLoading: false,
                error: false,
                showSuccessMsg: true,
                isEditing: false
            }
        },
        receiveUpdateError: (state: IState) => {
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                error: true
            }
        },
        hideSuccessMsg: (state: IState) => {
            return {
                ...state,
                showSuccessMsg: false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialState)
    }
})

const {
    resetState,
    setIsEditing,
    requestUpdate,
    receiveUpdate,
    receiveUpdateError,
    hideSuccessMsg
} = slice.actions;

function updatePassword(newPassword: string) {
    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().password;
        if (isLoading) {
            return;
        }

        dispatch(requestUpdate());

        try {
            const { username } = getState().account;

            const changePasswordResponse = await client.post(new ChangePassword({ newPassword, username }));

            if (changePasswordResponse.responseStatus)
                throw changePasswordResponse.responseStatus.message;

            dispatch(receiveUpdate());
            dispatch(setIsEditing({ isEditing: false }));

        }
        catch (e: unknown) {
            dispatch(receiveUpdateError());
            console.log(e);
        }

    }
}

function beginEditing() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        dispatch(slice.actions.setIsLoading({ isLoading: true }));
        await dispatch(authMultiFactorActionCreators.fetchTwoFactorStatus());
        dispatch(slice.actions.setIsLoading({ isLoading: false }));
        if (getState().authMultiFactor.hasCompletedMultiFactor) {
            //Cancel the other editable cards
            dispatch(contactInfoActionCreators.setIsEditing({ isEditing: false }));
            dispatch(usernameActionCreators.setIsEditing({ isEditing: false }));

            //Switch to edit mode
            dispatch(setIsEditing({ isEditing: true }));
        } else {
            dispatch(authMultiFactorActionCreators.beginTransactionChallenge({ transaction: 'password' }))
        }
    }
}

export const passwordActionCreators = {
    resetState,
    beginEditing,
    setIsEditing,
    updatePassword,
    hideSuccessMsg
};

export const passwordReducer = slice.reducer;
