import "core-js/es/number";


const formatMoneyString = function (money: string, cents?: boolean): string {
    const number = Number(money);
    if (Number.isNaN(number)) {
        return money;
    } else {
        return formatMoney(number, cents);
    }
}

const formatMoney = function (money: number, cents?: boolean): string {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: (cents ? 2 : 0), minimumFractionDigits: (cents ? 2 : 0) }).format(money)
}

const formatMoneyWithoutSymbol = function (money: number, cents?: boolean): string {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: (cents ? 2 : 0), minimumFractionDigits: (cents ? 2 : 0) }).format(money)
}

const formatMoneyWithDigits = function (money: number, fractionDigits: number): string {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits }).format(money)
}
export { formatMoney, formatMoneyString, formatMoneyWithDigits, formatMoneyWithoutSymbol }