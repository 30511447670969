import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import BenefitsAccessSso from '../BenefitsAccessSso';
import { AppState } from '../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../..';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { GatewayPath } from '@wespath/gateway-navigation';
import { Link } from 'react-router-dom';
import { selfManagedRetirementIncomeActionCreators } from '../../store/SelfManagedRetirementIncomeStore';
import { BAPageHeader } from '../BAPageHeader';
import BALayout from '../BALayout';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import { bindActionCreators } from 'redux';
import { cmsActionCreators } from '../../store/CmsStore';


type TSelfManagedRetirementIncomeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SelfManagedRetirementIncome = (props: TSelfManagedRetirementIncomeProps): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(stateNeedsLoading(props));


    const cmsLSRIPendingIneligible = props.cmsData.fragments?.find(f => f.name === "LSRIPendingIneligible")?.html ?? '';

    useEffect(() => {
        document.title = "Benefits Access - Self-Managed Retirement Income";
        setIsLoading(true);
        dispatch(selfManagedRetirementIncomeActionCreators.fetchSelfManagedRetirementIncome())
            .then(() => {
                setIsLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchSelfManagedRetirementIncomeCmsData();
        }
    });

    return (
        <BALayout>

            {isLoading && <LoadingSkeletonFullpage />}

            {!isLoading &&

                <>
                    <BAPageHeader name="Self-Managed Retirement Income" />

                    {props.loadState === DataLoadState.Error &&
                        <>
                            <Alert variant="error">
                                {props.errorMessage}
                            </Alert>
                        </>
                    }

                    {props.selfManagedRetirementIncome.smriElig === "N" &&
                        (
                            <Fragment key={"2"}>
                                <Row>
                                    <Col>
                                        {props.selfManagedRetirementIncome.selfManagedRetirementMessage?.map((selfManagedMessage, i) => {
                                            return (
                                                <Fragment key={i + 10}>
                                                    <Row>
                                                        <Col>
                                                            <div dangerouslySetInnerHTML={{ __html: selfManagedMessage.messageText }}>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </Fragment>
                                            )
                                        })
                                        }
                                    </Col>
                                </Row>
                                {props.selfManagedRetirementIncome.isLsriPending &&
                                    <Row>
                                        <Col>
                                            <div dangerouslySetInnerHTML={{ __html: cmsLSRIPendingIneligible }}>
                                            </div>
                                        </Col>
                                    </Row>

                                }

                                <Row>
                                    <Col>
                                        {props.selfManagedRetirementIncome.distPageElig &&
                                            <BenefitsAccessSso to={`/takeaction_retirement_benefits.aspx`} className="right">Review Retirement Benefits</BenefitsAccessSso>
                                        }
                                    </Col>
                                </Row>

                            </Fragment>
                        )
                    }

                    {props.selfManagedRetirementIncome.smriElig !== "N" &&
                        <Fragment key={"3"}>
                            <Row>
                                <Col className={"text-justify"}>
                                    The Self-Managed Retirement Income feature allows you to elect monthly or annual payments from your Wespath-administered defined contribution accounts.
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {props.selfManagedRetirementIncome.distPageElig &&
                                        <BenefitsAccessSso to={`/takeaction_retirement_benefits.aspx`} className="right">Review Retirement Benefits</BenefitsAccessSso>
                                    }
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className={"text-justify"}>
                                    Consider your payment amount carefully if you expect to make your accounts last for your lifetime. For guidance on how much to take in Self-Managed Retirement Income, use this tool:
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BenefitsAccessSso to={`/lifestage_retirement_income_calculator.aspx`} className="right">Calculate LifeStage Retirement Income</BenefitsAccessSso>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col className={"text-justify"} >
                                    If you would like help managing your payments, consider using LifeStage Retirement Income. LifeStage Retirement Income calculates your monthly payment and adjusts it annually to keep up with inflation or when market conditions warrant, while LifeStage Investment Management Service manages your investments.
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Link to={GatewayPath.LsriOverview} style={{ float: "right" }}>
                                        Sign up for LifeStage Retirement Income
                                    </Link>
                                </Col>
                            </Row>

                            {props.selfManagedRetirementIncome.dbBenefitElig &&
                                <Fragment key={4}>
                                    <br />
                                    <Row>
                                        <Col className={"text-justify"}>
                                            In addition to your Defined Contribution Account balance, you have a defined benefit pension and/or annuity that will provide you with income during your retirement.
                                        </Col>
                                    </Row>
                                </Fragment>
                            }

                            <br />
                            <Row>
                                <Col>
                                    Your application is not complete until you click the &#34;Submit&#34; button on the Verify page.
                                </Col>
                            </Row>
                            <hr />
                            <Row className="my-3">
                                <Col>
                                    <BenefitsAccessSso to="self_managed_retirement_consolidate_accounts.aspx" className="right">
                                        <Button className="btn-block-xs-only float-right" variant="primary">Get Started</Button>
                                    </BenefitsAccessSso>
                                </Col>
                            </Row>
                        </Fragment>
                    }

                </>
            }
        </BALayout>
    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.selfManagedRetirementIncome,
        cmsData: state.cms.selfManagedRetirementIncome
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchSelfManagedRetirementIncomeCmsData: cmsActionCreators.fetchSelfManagedRetirementIncomeCmsData
    }, dispatch);
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelfManagedRetirementIncome);

