import React from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import Footer from './Footer';
import '../styles/site.scss';
import { GatewayPath } from '@wespath/gateway-navigation'
import Header from './Header';
import SessionTimeoutModal from './SessionTimeoutModal';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ErrHandling from './ErrHandling';
import GoogleAnalyticsScripts from './GoogleAnalyticsScripts';



const Layout = (props: { children: React.ReactNode }) : JSX.Element => {
    const { pathname } = useLocation();
    const unProtectedPaths: string[] = [GatewayPath.Login, GatewayPath.Logoff, GatewayPath.Registration, GatewayPath.NeedHelp, GatewayPath.AccessDenied, GatewayPath.Maintenance];

    const isPathProtected = () => {
        return !unProtectedPaths.includes(pathname);
    }

    function ErrorFallback({ error }: FallbackProps) {
        return (
            <ErrHandling message={error?.message} />
        )
    }

    return (
        <>
            <GoogleAnalyticsScripts />
            <Header />
            <Container className="px-xl-0 main">
                <div>
                    {pathname === GatewayPath.Maintenance ? <>
                        {props.children}
                    </> :
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            {props.children}
                        </ErrorBoundary>
                    }
                </div>
            </Container>
            <Footer />
            {isPathProtected() &&
                <SessionTimeoutModal />
            }
        </>
    )
};

export default Layout


