import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { balanceActionCreators } from '../../../store/BalanceStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AmountPanel } from '../../AmountPanel';
import { AppDispatch } from '../../..';
import { AmountPanelSmall } from '../../AmountPanelSmall';

export interface OwnProps { isSummary?: boolean }
type TBalanceProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Balance = (props: TBalanceProps) => {

    const isLoading = (props.isLoading && !props.isLoaded);
    const isErr = (!isLoading && props.balance?.responseStatus !== undefined);

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchBalance();
        }
    });


    if (props.isSummary) {

        return (
            <AmountPanelSmall
                label="Account Balance"
                balance={props.balance?.balanceAmt}
                isLoading={isLoading}
                isErr={isErr}
                zeroAsDash
                showStatementsLink
            />
        );

    }
    else {

        return (
            <AmountPanel
                label="Account Balance"
                balance={props.balance?.balanceAmt}
                secondLabel="Amount Change (YTD)"
                secondBalance={props.balance?.ytdAmtChange}
                footnote={props.balance?.multiProfile ? "Your account balance may include funds from multiple accounts (e.g. spouse and non-spouse beneficiaries)." : ""}
                isLoading={isLoading}
                isErr={isErr}
                zeroAsDash
            />
        );

    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...state.balance,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...balanceActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Balance);
