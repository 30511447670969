import { AppState } from "./configureStore";
import { GetInvestmentsSummaryResponse, GetInvestmentsSummary } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { accountActionCreators } from "./AccountStore";

interface Error {
    errorMessage: string
}

export interface IInvestmentsSummaryState extends IEnumLoadableState {
    errorMessage: string | undefined;
    investmentsSummary: GetInvestmentsSummaryResponse
}

export const initialInvestmentsSummary: IInvestmentsSummaryState = {
    investmentsSummary: {} as GetInvestmentsSummaryResponse,
    loadState: DataLoadState.None,
    errorMessage: ""
};

const fetchInvestmentsSummary = createAsyncThunk<
    GetInvestmentsSummaryResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'investmentsSummary/fetchInvestmentsSummary',
    async (_, thunkApi) => {
        try {

            const investmentsSummary = await client.post(new GetInvestmentsSummary());

            if (investmentsSummary.responseStatus && investmentsSummary.responseStatus?.message) {
                throw investmentsSummary.responseStatus?.message;
            }

            return investmentsSummary;

        } catch (e: unknown) {
            const response: GetInvestmentsSummaryResponse = e as GetInvestmentsSummaryResponse;
            if (response?.responseStatus?.errorCode?.toLowerCase() === "unauthorized")
                thunkApi.dispatch(accountActionCreators.accessDenied("You don't have access to this page."));

            return thunkApi.rejectWithValue(response?.responseStatus ? { errorMessage: response.responseStatus.message } : { errorMessage: "Unknown error." });
        }
    }

)

const slice = createSlice({
    name: 'investmentsSummary',
    initialState: initialInvestmentsSummary,
    reducers: {
        resetState: () => initialInvestmentsSummary
    },
    extraReducers: builder => {
        builder
            .addCase(fetchInvestmentsSummary.pending, (state: IInvestmentsSummaryState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchInvestmentsSummary.fulfilled, (state: IInvestmentsSummaryState, { payload }) => {
                state.loadState = DataLoadState.Loaded
                state.investmentsSummary = payload;
            })
            .addCase(fetchInvestmentsSummary.rejected, (state: IInvestmentsSummaryState, action) => {
                state.loadState = DataLoadState.Error;
                state.errorMessage = action.payload?.errorMessage;
            })
            .addCase(getResetAction(), (_state, _action) => initialInvestmentsSummary)
    }
})

const { resetState } = slice.actions;

export const investmentsSummaryActionCreators = {
    fetchInvestmentsSummary,
    resetState
};

export const investmentsSummaryReducer = slice.reducer;