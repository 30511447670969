import React from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriReviewSidePanel = (props: TLifestageRIProps) => {
    const { lsri } = props

    if (lsri.progressCurrent === lsri.progressMax)
        return null

    return (<>
        <em style={{ fontSize: '12px', color: 'black' }}>Visit all sections to enroll</em>
        <ProgressBar className="reviewProgressBar ml-2" now={lsri.progressCurrent} max={lsri.progressMax} />
    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriReviewSidePanel);

