import React, { useEffect, useState } from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { AppDispatch } from '../../../..';
import { FormatDollar } from '../../../FormatDollar';
import { CurrencyInput } from '../../../CurrencyInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { convertDollarPctToNumber } from '../../../../functions/convertStringToNumber';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { LsriStage, OracleCalculation } from '../../../../Gateway.dtos';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { ToolTip } from '../../../ToolTip';
import { getPlanFullName } from '../Shared/LsriFunctions';
import { formatMoney } from '../../../../functions/formatMoney';
import { scrollToElementById } from '../../../../functions/scrollToElementById';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps { isExpanded?: boolean }
const LsriFundingSidePanel = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();
    const { lsri, isExpanded } = props
    const { cms } = useSelector((state: AppState) => state)
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`Funding/`)) < 0
    const adjustFundingMsg = cms.lsri?.fragments?.find(f => f.name === `Funding/AdjustFundingMsg`)?.html ?? '';
    const isLoading = cmsIsLoading || lsri.calculationLoadState === DataLoadState.Loading || (lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error)
    const showUpdateErrMsg = lsri.calculationLoadState === DataLoadState.Error;
    const isManage = lsri.wizardType === LsriStage.Manage;

    const isVisited = lsri.lsriInfo?.oracleLsriElection?.visitedSteps?.funding
    const [fundingAmt, setFundingAmt] = useState(lsri.userElection?.amtAllocated);
    const fundingPct = Math.round(lsri.userElection?.pctAllocated);
    const maxAmt = lsri.systemElection?.availBalance ?? 0
    const plans = lsri.lsriInfo?.balances?.filter(x => x.display !== "")?.filter(y => y.display.toUpperCase() !== "TOTAL") //Just display the plans whose name is not blank
    const [scrollToChart, setScrollToChart] = useState(false)


    //Reset election if server was unable to process the change
    useEffect(() => {
        if (lsri.calculationLoadState === DataLoadState.Error)
            setFundingAmt(lsri.userElection?.amtAllocated)

        if (scrollToChart && lsri.calculationLoadState === DataLoadState.Loaded) {
            if (isSmallScreen) scrollToElementById("lsriFlowChart", isSmallScreen)
            setScrollToChart(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.calculationLoadState])


    const [showEstAmtErr, setShowEstAmtErr] = useState(false);
    function isEstAmtValid(val: number | string | undefined) {
        const isValid = val !== undefined && val !== '' && convertDollarPctToNumber(val.toString()) <= maxAmt 
        setShowEstAmtErr(!isValid);
        return isValid;
    }

    const recalcElection = () => {
        dispatch(LsriActionCreators.recalcOracleLsriElection({
            userElection: {
                ...lsri.userElection,
                amtAllocated: fundingAmt
            } as OracleCalculation
        }))
    }


    const handleClickRecalculate = () => {
        if (isEstAmtValid(fundingAmt)) {
            setScrollToChart(true)
            recalcElection()
        }
    }

    const handleClickNext = () => {
        //If amount is invalid, set it to 0
        const amt = showEstAmtErr ? 0 : fundingAmt

        if (amt !== lsri.userElection?.amtAllocated)
            recalcElection()

        dispatch(push(GatewayPath.LsriBridge))
    }

    const handleFundingAmtChange = (val: string) => {
        if (isEstAmtValid(val)) {
            const valToNum = convertDollarPctToNumber(val)
            setFundingAmt(valToNum);
            //dispatch(LsriActionCreators.setFundingAmt(valToNum));
        }
    }

    if (!isExpanded && (fundingAmt === undefined || !isVisited || !lsri?.lsriInfo?.isEligible))
        return null

    if (isManage)
        return null

    if (!isExpanded) {
        return (<h5>
            <FontAwesomeIcon icon={faCheck} /> {fundingPct}% ({formatMoney(fundingAmt, true)} today)
        </h5>);
    }

    const ErrorMsg = () => (
        <Alert variant="danger" className={`mt-2 mr-3 ${isSmallScreen ? '' : 'small'}`}>
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while updating your funding amount.
        </Alert>
    )


    const PlanList = () => (<>

        <p className={`mt-3 mb-3 mr-2`}>
            Your available total includes:
        </p>

        {plans?.map((p, i) => <Row key={i} className="planDetails">
            <Col className={`col-4 pr-0`} >
                <ToolTip title={getPlanFullName(p.planName)} className="text-nowrap" suppressIcon >{p.display}</ToolTip>
            </Col>
            <Col className={`col-8 pl-0 ${isSmallScreen ? 'pr-4' : 'pr-5'} text-right`}><FormatDollar amount={p.amount} /></Col>
        </Row>)}
        <Row className="planDetails">
            <Col className={`col-4 text-nowrap`}><strong>Total</strong></Col>
            <Col className={`col-8 ${isSmallScreen ? 'pr-4' : 'pr-5'} text-right`}><strong><FormatDollar amount={maxAmt} /></strong></Col>
        </Row>

    </>)

    const PctOfBalanceMsg = () => (
        <p className={`mt-4 mb-3 mr-2`}>
            Amount equals <strong>{fundingPct}% of your available balances</strong> today. The actual amount will depend on the market value as of your first payment date.
        </p>
    )


    return (<div className="sidePanelContentText">

        <PlanList />

        <h4 className="mt-4 mb-1" >Adjust funding amount</h4>

        <p className="mr-3" dangerouslySetInnerHTML={{ __html: adjustFundingMsg }} />

        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (!showEstAmtErr)
                handleClickRecalculate();
        }}>

            <Row className="m-0 p-0">
                <Col className="col-6 m-0 p-0">

                    <Form.Group className={`mb-3 ${showEstAmtErr ? "error" : ""}`}>
                        <CurrencyInput
                            id={`socSecEstAmt`}
                            name={`socSecEstAmt`}
                            className="px-2"
                            showCurrencySymbol={true}
                            defaultValue={fundingAmt?.toFixed(2)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFundingAmtChange(e.target.value)}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleFundingAmtChange(e.target.value)}
                            disabled={isLoading}
                            decimalScale={2}
                            maxLength={14}
                            autoFocus
                            leftAlign
                            tabIndex={2}
                        />
                        {showEstAmtErr && <div className="errMsg">Invalid Amount</div>}
                    </Form.Group>
                </Col>
                {fundingAmt !== maxAmt && <Col className="col-6 footnote m-0 p-0 pt-4 pl-2">
                    Max <FormatDollar amount={maxAmt} />
                </Col>}
            </Row>

            <div>
                <Button variant="primary" size="sm" tabIndex={3} className={`${isSmallScreen ? 'w-100' : ''}`} disabled={showEstAmtErr || isLoading} onClick={() => handleClickRecalculate()}>
                    Recalculate
                </Button>
            </div>

            {showUpdateErrMsg && <ErrorMsg />}

        </Form>

        <PctOfBalanceMsg />

        <div className="buttonAndContent">
            <Button className="px-0" variant="link" tabIndex={4} onClick={() => handleClickNext()}>
                <strong>Next</strong> - bridge to Social Security
            </Button>

            <div>
                Your progress is saved automatically.
            </div>
        </div>

    </div>);
};

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriFundingSidePanel);