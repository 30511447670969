import { createAction } from "@reduxjs/toolkit";

export type ResetAppStatePayload = {
    keepLoginHelpRequestType?: boolean
    isEySearch?: boolean
    isBapSso?: boolean
    keepPwLock?: boolean
    isLogin?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getResetAction = function () {
    return createAction<ResetAppStatePayload | undefined>("RESET_APP_STATE")
};
export { getResetAction };