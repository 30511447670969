import parse from "date-fns/parse";
import format from "date-fns/format";

const friendlyDate = function (date: string) : string {
    if (!date) {
        return "";
    }
    const parsed = parse(date, "MM/dd/yyyy", new Date())
    return format(parsed, "LLLL dd, yyyy");
};

export { friendlyDate };
