import { Dispatch } from "react";
import { AppState } from "../configureStore";
import { createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../../definitions/ILoadableState";
import { UpdatePersonalInfo } from "../../Gateway.dtos";
import { client } from "../../App";
import { passwordActionCreators } from "./PasswordStore";
import { usernameActionCreators } from "./UsernameStore";
import { getResetAction } from "../../functions/getResetAction";
import { authMultiFactorActionCreators } from "../AuthMultiFactorStore";
import { AppDispatch } from "../..";

interface IState extends ILoadableState {
    isEditing: boolean
    showSuccessMsg: boolean
    textPhone: string
    phone: string
    altPhone: string
    email: string
    countryCode: string
    streetAddress1: string
    streetAddress2: string
    streetAddress3: string
    city: string
    stateAbbr: string
    zipCode: string
    invalidAddress: boolean
}

const initialState: IState = {
    isEditing: false,
    showSuccessMsg: false,
    textPhone: '',
    phone: '',
    altPhone: '',
    email: '',
    countryCode: '',
    streetAddress1: '',
    streetAddress2: '',
    streetAddress3: '',
    city: '',
    stateAbbr: '',
    zipCode: '',
    invalidAddress: false,
    isLoading: false,
    isLoaded: false,
    error: false
};

const slice = createSlice({
    name: 'ContactInfo',
    initialState: {} as IState,
    reducers: {
        resetState: () => {
            return {
                ...initialState
            }
        },
        setState: (state: IState, action: PayloadAction<{ contactInfo: UpdatePersonalInfo, invalidAddress: boolean }>) => {
            const { textPhone, phone, altPhone, email, countryCode, streetAddress1, streetAddress2, streetAddress3, city, zipCode } = action.payload.contactInfo;
            const invalidAddress = action.payload.invalidAddress;
            const stateAbbr = action.payload.contactInfo.state;
            return {
                ...state,
                textPhone,
                phone,
                altPhone,
                email,
                countryCode,
                streetAddress1,
                streetAddress2,
                streetAddress3,
                city,
                stateAbbr,
                zipCode,
                invalidAddress
            }
        },
        setIsEditing: (state: IState, action: PayloadAction<{ isEditing: boolean }>) => {
            const { isEditing } = action.payload;
            return {
                ...state,
                isEditing
            }
        },
        setIsLoading: (state: IState, action: PayloadAction<{ isLoading: boolean }>) => {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoading: isLoading
            }
        },
        requestUpdate: (state: IState) => {
            return {
                ...state,
                isLoading: true,
                error: false,
                showSuccessMsg: false
            }
        },
        receiveUpdate: (state: IState) => {
            return {
                ...state,
                invalidAddress: false,
                isLoading: false,
                error: false,
                showSuccessMsg: true,
                isEditing: false
            }
        },
        receiveUpdateError: (state: IState) => {
            return {
                ...state,
                isLoading: false,
                isEditing: false,
                error: true
            }
        },
        hideSuccessMsg: (state: IState) => {
            return {
                ...state,
                showSuccessMsg: false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialState)
    }
})

const {
    resetState,
    setState,
    setIsEditing,
    requestUpdate,
    receiveUpdate,
    receiveUpdateError,
    hideSuccessMsg
} = slice.actions;

function updateContactInfo(contactInfo: UpdatePersonalInfo) {
    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState): Promise<void> => {
        const isUpdating = getState().contactInfo.isLoading;
        const { isLoading, isLoaded } = getState().personalInfo;
        if (isUpdating || isLoading || !isLoaded) {
            return;
        }

        dispatch(requestUpdate());

        try {
            const updateContactInfoResponse = await client.post(new UpdatePersonalInfo({ ...contactInfo }));

            if (updateContactInfoResponse.responseStatus)
                throw updateContactInfoResponse.responseStatus.message;

            dispatch(receiveUpdate());
            dispatch(setIsEditing({ isEditing: false }));
            dispatch(setState({ contactInfo, invalidAddress: false }));


        }
        catch (e: unknown) {
            dispatch(receiveUpdateError());
            console.log(e);
        }

    }
}

function beginEditing() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => { 
        dispatch(slice.actions.setIsLoading({ isLoading: true }));
        await dispatch(authMultiFactorActionCreators.fetchTwoFactorStatus());
        dispatch(slice.actions.setIsLoading({ isLoading: false }));
        if (getState().authMultiFactor.hasCompletedMultiFactor) {
            //Cancel the other editable cards
            dispatch(passwordActionCreators.setIsEditing({ isEditing: false }));
            dispatch(usernameActionCreators.setIsEditing({ isEditing: false }));

            //Switch to edit mode
            dispatch(setIsEditing({ isEditing: true }));
        }
        else {
            dispatch(authMultiFactorActionCreators.beginTransactionChallenge({ transaction: 'contact' }))
        }
    }
}

export const contactInfoActionCreators = {
    resetState,
    setState,
    setIsEditing,
    beginEditing,
    updateContactInfo,
    hideSuccessMsg
};

export const contactInfoReducer = slice.reducer;
