import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsCommit, wizardStepsManage } from './LsriWizard.steps';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { LsriStage } from '../../../Gateway.dtos';
import { cmsActionCreators } from '../../../store/CmsStore';
import UpdateBankInformation from '../UpdateBankInformation';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriBankInfo = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error


    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Bank Information'

    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "BankInfo"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);


    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: lsri.wizardType === LsriStage.Manage ? 4 : 2,
                steps: lsri.wizardType === LsriStage.Manage ? wizardStepsManage : wizardStepsCommit,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>
                <UpdateBankInformation isLsriBankInfoUpdate={true} />
            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriBankInfo);

