import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsManage, wizardStepsPending, wizardStepsSetup } from './LsriWizard.steps';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { LsriSocSecStatus, LsriStage } from '../../../Gateway.dtos';
import { cmsActionCreators } from '../../../store/CmsStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenDollar, faCoins, faExclamationCircle, faInfoCircle, faMoneyCheckAlt, faShieldAlt, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import wespathLogo from '../../../images/WP_Logo2x.png';
import consistentIncomeImg from '../../../images/LSRI_ConsistentIncome.png';
import fundingBgImg from '../../../images/LSRI_FundingBg.png';
import overTimeImg from '../../../images/LSRI_IncomeOverTime.png';
import videoPlaceholderImg from '../../../images/LSRI_VideoPlaceholder.png';
import { BAConvertedRoutes } from '../../../definitions/BAConvertedRoutes';
import LsriSelectionClearedModal from './Shared/LsriSelectionClearedModal';
import LsriMinPaymentMsg from './Shared/LsriMinPaymentMsg';
import { scrollToElementById } from '../../../functions/scrollToElementById';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriOverview = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const isError = lsri.loadState === DataLoadState.Error

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'LifeStage Retirement Income'

    const notEligible = !isError && !lsri.lsriInfo.isEligible;
    const showIneligMsg = notEligible && lsri.wizardType === LsriStage.Setup
    const eligMsg = lsri.lsriInfo.eligMsg;
    const ss_Status = lsri?.lsriInfo?.oracleLsriElection?.socialSecurity?.ss_Status

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Program Overview`
    }, []);    

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.loadState]);

    useEffect(() => {
        if (lsri.redirectToLastPopulatedStep) {
            dispatch(push(lsri.redirectToLastPopulatedStep));
            dispatch(LsriActionCreators.resetRedirectToLastPopulatedStep());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.redirectToLastPopulatedStep]);


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "ProgramOverview"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection))}, []);

    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    const headerMsg2Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg2`)?.html?.replace("&amp;VideoPlaceHolder", videoPlaceholderImg) ?? '';
    const flexibilityCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/Flexibility`)?.html ?? '';

    const atAGlance_A1Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A1`)?.html?.replace("&amp;wespathLogo", wespathLogo) ?? '';
    const atAGlance_A1CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A1Dtls1`)?.html?.replace("&amp;wespathLogo", wespathLogo) ?? '';
    const atAGlance_A1CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A1Dtls2`)?.html ?? '';
    const atAGlance_A2Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A2`)?.html?.replace("&amp;fundingBgImg", fundingBgImg) ?? '';
    const atAGlance_A2CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A2Dtls1`)?.html ?? '';
    const atAGlance_A2CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A2Dtls2`)?.html ?? '';
    const atAGlance_A3Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A3`)?.html ?? '';
    const atAGlance_A3CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A3Dtls1`)?.html ?? '';
    const atAGlance_A3CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_A3Dtls2`)?.html ?? '';
    const atAGlance_B1Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B1`)?.html ?? '';
    const atAGlance_B1CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B1Dtls1`)?.html ?? '';
    const atAGlance_B1CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B1Dtls2`)?.html ?? '';
    const atAGlance_B2Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B2`)?.html ?? '';
    const atAGlance_B2CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B2Dtls1`)?.html ?? '';
    const atAGlance_B2CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B2Dtls2`)?.html ?? '';
    const atAGlance_B3Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B3`)?.html ?? '';
    const atAGlance_B3CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B3Dtls1`)?.html ?? '';
    const atAGlance_B3CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_B3Dtls2`)?.html ?? '';
    const atAGlance_C1Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_C1`)?.html?.replace("&amp;consistentIncomeImg", consistentIncomeImg) ?? '';
    const atAGlance_C1CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_C1Dtls1`)?.html?.replace("&amp;consistentIncomeImg", consistentIncomeImg) ?? '';
    const atAGlance_C1CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_C1Dtls2`)?.html?.replace("&amp;consistentIncomeImg", consistentIncomeImg) ?? '';
    const atAGlance_C3Cms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_C3`)?.html?.replace("&amp;overTimeImg", overTimeImg) ?? '';
    const atAGlance_C3CmsDtls1 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_C3Dtls1`)?.html?.replace("&amp;overTimeImg", overTimeImg) ?? '';
    const atAGlance_C3CmsDtls2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_C3Dtls2`)?.html?.replace("&amp;overTimeImg", overTimeImg) ?? '';
    const atAGlance_FooterCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_Footer`)?.html ?? '';
    const atAGlance_HeaderCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/AtAGlance_Header`)?.html ?? '';


    //Preload other lsri wizard cms if lsri data is done loading
    //useEffect(() => {
    //    if (!isLoading && !cmsIsLoading) {
    //        dispatch(cmsActionCreators.fetchLsriCmsData("SocialSecurity"))

    //        if (lsri.lsriInfo.isEligible) {
    //            if (!lsri.mppIneligible) dispatch(cmsActionCreators.fetchLsriCmsData("MPP"))
    //            dispatch(cmsActionCreators.fetchLsriCmsData("Funding"))
    //            dispatch(cmsActionCreators.fetchLsriCmsData("Bridge"))
    //            dispatch(cmsActionCreators.fetchLsriCmsData("QLAC"))
    //            dispatch(cmsActionCreators.fetchLsriCmsData("Review"))
    //        }
            
    //    }

    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [isLoading, cmsIsLoading])

    //End CMS Section


    //Scroll to expanded card if user clicks to expand it
    const [expandedCardNum, setExpandedCardNum] = useState(-1);
    useEffect(() => {
        if (expandedCardNum > -1)
            scrollToElementById('cardExpandedDetails', isSmallScreen)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedCardNum])

    const [scrollToCard, setScrollToCard] = useState(-1);
    useEffect(() => {
        if (scrollToCard > -1) {
            if (isSmallScreen) scrollToElementById(`lsriOverviewCard-${scrollToCard}`, isSmallScreen)
            setScrollToCard(-1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToCard])

    function handleShowLess(cardNum: number) {
        setScrollToCard(isSmallScreen && cardNum < 0 ? expandedCardNum : -1)
        setExpandedCardNum(cardNum)
    }

    const handleClickGetStarted = () => {

        if (lsri.wizardType === LsriStage.Setup) {
            if (!ss_Status || ss_Status === LsriSocSecStatus.NoInfo)
                dispatch(push(GatewayPath.LsriSocialSecurity))
            else
                dispatch(push(!lsri.mppIneligible
                    ? GatewayPath.LsriMpp
                    : GatewayPath.LsriFunding
                ))
        }
        else if (lsri.wizardType === LsriStage.Manage || lsri.wizardType === LsriStage.Pending) {
            dispatch(push(GatewayPath.LsriSummary));
        }

    }

    const ErrorMsg = () => (
        <Alert variant="danger" className="my-4">
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while retrieving your information.
        </Alert>
    )

    const IneligMsg = () => (
        <Alert variant="info" className="my-4">
            <FontAwesomeIcon icon={faInfoCircle} />
            {eligMsg && <span dangerouslySetInnerHTML={{ __html: eligMsg }} />}
        </Alert>
    )

    const LsriOverviewHeader = () => (<>

        <p>
            <span dangerouslySetInnerHTML={{ __html: headerMsgCms.replace("&amp;getStartedLink", "") }} />
            {(!notEligible && headerMsgCms.includes("&amp;getStartedLink")) && <span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => handleClickGetStarted()}>Get started</span>}
        </p>

        {isError && <ErrorMsg />}

        {showIneligMsg && <IneligMsg />}

        <LsriMinPaymentMsg evaluateInitElection/>

        <p dangerouslySetInnerHTML={{ __html: headerMsg2Cms }} />

    </>)

    const headerColors = ['#8D0034', '#0063A7', '#0063A7', '#E68B29', '#52958B', '#7C6991', '#9B4F0F', '#9B4F0F']

    const OptionsAtAGlance = () => (
        <div className="my-5">

            <div className="text-center">
                <div dangerouslySetInnerHTML={{ __html: atAGlance_HeaderCms }} />
                {!notEligible && <Button variant="primary mt-2 mb-5" disabled={isError} onClick={() => handleClickGetStarted()}>Get Started</Button>}
            </div>

            {expandedCardNum > -1 && <OptionAtAGlanceDetails />}

            <OptionAtAGlanceCards />
     
        </div>
    )

    const getSummaryText = (cardNum: number): string => {

        switch (cardNum) {
            case 0:
                return atAGlance_A1Cms
            case 1:
                return atAGlance_A2Cms
            case 2:
                return atAGlance_A3Cms
            case 3:
                return atAGlance_B1Cms
            case 4:
                return atAGlance_B2Cms
            case 5:
                return atAGlance_B3Cms
            case 6:
                return atAGlance_C1Cms
            case 7:
                return atAGlance_C3Cms
            default:
                return ""
        }
    }

    const getExpandedText = (cardNum: number): [string, string] => {

        if (cardNum === 0)
            return [atAGlance_A1CmsDtls1, atAGlance_A1CmsDtls2]
        else if (cardNum === 1)
            return [atAGlance_A2CmsDtls1, atAGlance_A2CmsDtls2]
        else if (cardNum === 2)
            return [atAGlance_A3CmsDtls1, atAGlance_A3CmsDtls2]
        else if (cardNum === 3)
            return [atAGlance_B1CmsDtls1, atAGlance_B1CmsDtls2]
        else if (cardNum === 4)
            return [atAGlance_B2CmsDtls1, atAGlance_B2CmsDtls2]
        else if (cardNum === 5)
            return [atAGlance_B3CmsDtls1, atAGlance_B3CmsDtls2]
        else if (cardNum === 6)
            return [atAGlance_C1CmsDtls1, atAGlance_C1CmsDtls2]
        else if (cardNum === 7)
            return [atAGlance_C3CmsDtls1, atAGlance_C3CmsDtls2]
        else
            return ["", ""]
    }

    const OptionAtAGlanceDetails = () => (<Row className="pr-0">
        <Col className={`col-12 pr-0`}>
            <Card className={`lsriOverviewCard large ml-0`} id="cardExpandedDetails">
                <Card.Header style={{ backgroundColor: headerColors[expandedCardNum] }} />
                <Card.Body className="px-2 p-sm-4">

                    <div dangerouslySetInnerHTML={{ __html: getExpandedText(expandedCardNum)[0] }} />

                    <Button className={`learnMoreLink pl-0 mb-3`} variant="link" onClick={() => handleShowLess(-1)}>Show less</Button>

                    <div dangerouslySetInnerHTML={{ __html: getExpandedText(expandedCardNum)[1] }} />

                </Card.Body>
            </Card>
        </Col>
    </Row>)

    const OverviewCard = (props: { cardNumber: number, isMedium?: boolean, isExpanded?: boolean, showLearnMore?: boolean, learnMoreIcon?: IconDefinition }) => (
        <Col className={`px-0 col-12 col-md-12 ${props.isMedium ? 'medium col-lg-6' : 'col-sm-6 col-lg-4'}`}>

            {!props.isExpanded && <Card className={`lsriOverviewCard ${props.isMedium ? 'medium' : ''}`} id={`lsriOverviewCard-${props.cardNumber}`} >
                <Card.Header style={{ backgroundColor: `${headerColors[props.cardNumber]}` }} />
                <Card.Body>

                    <div dangerouslySetInnerHTML={{ __html: getSummaryText(props.cardNumber) }} />

                    {props.learnMoreIcon &&
                        <FontAwesomeIcon icon={props.learnMoreIcon} size='3x' color={headerColors[props.cardNumber]} />
                    }

                    {props.showLearnMore &&
                        <Button className={`learnMoreLink ${props.learnMoreIcon ? 'pl-3' : ''}`} variant="link" onClick={() => handleShowLess(props.cardNumber)} style={props.learnMoreIcon && { marginTop: '-20px' }}>
                            Learn More
                        </Button>
                    }

                </Card.Body>
            </Card>}

        </Col>
    )
    
    const OptionAtAGlanceCards = () => {
        return (<>
            <Row className="pl-2">
                <OverviewCard cardNumber={0} isExpanded={expandedCardNum === 0} showLearnMore />
                <OverviewCard cardNumber={1} isExpanded={expandedCardNum === 1} showLearnMore learnMoreIcon={faMoneyCheckAlt} />
                <OverviewCard cardNumber={2} isExpanded={expandedCardNum === 2} showLearnMore learnMoreIcon={faCoins} />
                <OverviewCard cardNumber={3} isExpanded={expandedCardNum === 3} showLearnMore />
                <OverviewCard cardNumber={4} isExpanded={expandedCardNum === 4} showLearnMore learnMoreIcon={faEnvelopeOpenDollar} />
                <OverviewCard cardNumber={5} isExpanded={expandedCardNum === 5} showLearnMore learnMoreIcon={faShieldAlt} />
                <OverviewCard cardNumber={6} isExpanded={expandedCardNum === 6} showLearnMore isMedium />
                <OverviewCard cardNumber={7} isExpanded={expandedCardNum === 7} showLearnMore isMedium />
            </Row>
            <div className="mt-3" dangerouslySetInnerHTML={{ __html: atAGlance_FooterCms }} />
        </>)
    }

    const menuStep = () => {
        if (lsri.wizardType === LsriStage.Manage)
            return wizardStepsManage
        else if (lsri.wizardType === LsriStage.Pending)
            return wizardStepsPending
        else 
            return wizardStepsSetup
    }

    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 0,
                steps: menuStep(),
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                <LsriSelectionClearedModal />

                <LsriOverviewHeader />

                <OptionsAtAGlance />

                <div className="my-5">
                    <div className="text-left" dangerouslySetInnerHTML={{ __html: flexibilityCms.replace("&amp;selfManageText", "") }} />

                    {flexibilityCms.includes("&amp;selfManageText") &&
                        <strong><em>
                            If LifeStage Retirement Income does not align with your retirement goals you can choose 
                            to <span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => (dispatch(push(BAConvertedRoutes.SelfManagedRetirementIncome)))}>
                                <strong><em>self-manage your Wespath retirement plan balances</em></strong>
                            </span>.
                        </em></strong>
                    }
                </div>

                {!notEligible && <Button variant="primary" disabled={isError} onClick={() => handleClickGetStarted()}>
                    Get Started
                </Button>}

            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps    
)(LsriOverview);

