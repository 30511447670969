import { Step } from "../../../definitions/WizardProgressBarProps";
import { GatewayPath } from '@wespath/gateway-navigation'

export const wizardStepsSetup: Step[] = [
    { display: "Program Overview", path: GatewayPath.LsriOverview },
    { display: "Social Security Estimate", path: GatewayPath.LsriSocialSecurity },
    { display: "MPP 65% Options", path: GatewayPath.LsriMpp, isExpandable: true },
    { display: "Funding LifeStage", path: GatewayPath.LsriFunding, isExpandable: true },
    { display: "Bridge to Social Security", path: GatewayPath.LsriBridge, isExpandable: true },
    { display: "Longevity Income", path: GatewayPath.LsriQlac, isExpandable: true },
    { display: "Review & Enroll", path: GatewayPath.LsriReview }
];

export const wizardStepsCommit: Step[] = [
    { display: "Payment Options", path: GatewayPath.LsriPaymentOptions },
    { display: "Tax Options", path: GatewayPath.LsriTaxOptions },
    { display: "Bank Information", path: GatewayPath.LsriBankInfo },
    { display: "Verify & Submit", path: GatewayPath.LsriVerify }
];

export const wizardStepsManage: Step[] = [
    { display: "Program Overview", path: GatewayPath.LsriOverview },
    { display: "Your LifeStage Summary", path: GatewayPath.LsriSummary },
    { display: "Balance Transfer", path: GatewayPath.LsriFunding },
    { display: "Tax Options", path: GatewayPath.LsriTaxOptions },
    { display: "Bank Information", path: GatewayPath.LsriBankInfo }
];

export const wizardStepsPending: Step[] = [
    { display: "Program Overview", path: GatewayPath.LsriOverview },
    { display: "Your LifeStage Summary", path: GatewayPath.LsriSummary }
];