import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { PlanType, ServiceProvider } from '../../../Gateway.dtos';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { SamlSelfPostForm } from '@wespath/gateway-navigation';
import { SAMLSsoModal } from '../../SAMLSsoModal';


type THealthAccountsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const HealthAccounts = (props: THealthAccountsProps) => {
    const { hsa, planElections } = props;

    const hasHsa = hsa.hsa?.healthSavingsAccounts?.length > 0
    const medicalPlan = planElections?.find(p => p.type === PlanType.MEDICAL);
    const isHsaEligible = medicalPlan?.plan?.includes("HSA");

    const cmsHealthAccountsBCBSwithHSA = props.cmsData.fragments?.find(f => f.name === "HealthAccountsBCBSwithHSA")?.html ?? '';
    const cmsHealthAccountsNoHSA = props.cmsData.fragments?.find(f => f.name === "HealthAccountsNoHSA")?.html ?? '';
    const cmsHealthAccountsPostAE = props.cmsData.fragments?.find(f => f.name === "HealthAccountsPostAE")?.html ?? ''; 
    const cmsHealthAccountsPostAEVideoBullet = props.cmsData.fragments?.find(f => f.name === "HealthAccountsPostAEVideoBullet")?.html ?? ''; 

    const preEnrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showPreEnrollmentBanner ?? false)
    const enrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showEnrollmentBanner ?? false)

    const showAEContent = preEnrollmentPeriod || enrollmentPeriod

    const username = useSelector((state: AppState) => state.account.username)

    const referrer = 'b'
    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);


    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const pageText = ():string => {

        if (!showAEContent)
            return cmsHealthAccountsPostAE.concat(isHsaEligible ? cmsHealthAccountsPostAEVideoBullet : '')
        else if (isHsaEligible)
            //As we have only one health provider removing the condition and keeping only one CMS which has updated text for 2025
            return cmsHealthAccountsBCBSwithHSA 
        else 
            return cmsHealthAccountsNoHSA
    }

    const PageButton = () => {

        if (showAEContent && !hasHsa) {
            return <Button
                variant="secondary"
                onClick={() => window.open("https://www.wespath.org/health-well-being/AE/Health-Account-Advantages", "_blank")}
            >
                Learn About Health Accounts
            </Button>
        }
        else {
            return <></>
        }
    }
    

    return (
        <>
            <Card className="pageCard secondary">
                <Card.Title>
                    <FontAwesomeIcon icon={faShieldCheck} className="health-color smaller" />
                    Make Health Accounts Work For You
                </Card.Title>
                <Card.Body>

                    {!pageText() ? <LoadingSkeleton /> : <>


                        <p className="thin" dangerouslySetInnerHTML={{ __html: pageText() }} />

                        <PageButton />
                    </>}

                </Card.Body>
            </Card>
            <SamlSelfPostForm ref={formRef} provider={ServiceProvider.BusinesSolver} source={referrer} />
            <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={ServiceProvider.BusinesSolver} username={username} referrer={referrer} />
        </>
        
    );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        hsa: state.hsa,
        planElections: state.healthPlans?.healthPlans?.planElections
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthAccounts);