import React, { ReactElement, useEffect } from 'react';
import { Card, Button, Row, Col, Table, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import { AppState } from '../../../store/configureStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { transactionsActionCreators } from '../../../store/TransactionsStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { Transaction } from '../../../Gateway.dtos';
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons';
import { FormatDollar } from '../../FormatDollar';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../..';

type TTransactionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Transactions = (props: TTransactionsProps): ReactElement => {
    const isLoading = (props.isLoading && !props.isLoaded);
    const isErr = (!isLoading && props.transactions?.responseStatus !== undefined);
    const cardTitle = "Recent Transactions"

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchTransactions();
        }
    });
    return (<>

        {isLoading && <LoadingSkeleton />}

        {!isLoading && <Card className="pageCard secondary">
            <Card.Title><FontAwesomeIcon icon={faFileInvoiceDollar} className="ret-color" />{cardTitle}</Card.Title>
            <Card.Subtitle>Transaction history may not reflect your most recent or pending transactions.</Card.Subtitle>
            <Card.Body>
                    
                {props.transactions?.transactions &&
                    <StripedTable data={props.transactions?.transactions} isLoading={isErr} />
                }

                {!isErr && !props.transactions?.transactions &&
                    <div className="my-2 ml-2">No transaction history information exists for the last 31 days.</div>
                }

                {isErr && <Alert className="mt-2" variant="danger">Unable to retrieve data for {cardTitle}</Alert>}
    
                <BenefitsAccessSso to={`/mybenefits_transaction_history.aspx`}>
                    <Button variant="secondary" className="mt-5">View More Transactions</Button>
                </BenefitsAccessSso>
                <BenefitsAccessSso to={`/mybenefits_statements.aspx`}>
                    <Button className="mt-md-5 mt-2 ml-md-4" variant="link">View Statements</Button>
                </BenefitsAccessSso>

            </Card.Body>
        </Card>}
    </>);
}
function mapStateToProps(state: AppState) {
    return {
        ...state.transactions
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...transactionsActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Transactions);

function StripedTable(props: { data: Transaction[], isLoading?: boolean }) {
    const { data, isLoading } = props;

    const DesktopRow = () => (
        <Table striped className="desktop">
            <Header />
            <tbody>
                {!isLoading && data?.map((item, i) => (
                    <tr key={i}>
                        <td>{item.plan}</td>
                        <td><Type type={item.type} subType={item.subType} /></td>
                        <td>{item.date}</td>
                        <td><FormatDollar amount={item.amount} negativeInItalics /></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    const MobileRow = () => (
        <Table striped className="mobile">
            <thead />
            <tbody>
                {!isLoading && data?.map((item, i) => (
                    <tr key={i}>
                        <td className="py-1">
                            <Row>
                                <SmallLabel label="Plan" className="col-4">{item.plan}</SmallLabel>
                                <SmallLabel label="Date" className="col-4">{item.date}</SmallLabel>
                                <SmallLabel label="Amount" className="col-4"><FormatDollar amount={item.amount} negativeInItalics /></SmallLabel>
                            </Row>
                            <Row>                                
                                <SmallLabel label="Type" className="col-12"><Type type={item.type} subType={item.subType} /></SmallLabel>
                            </Row>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    return (<>
        <DesktopRow />

        <MobileRow />
    </>);
}

const Header = () => (
    <thead>
        <tr>
            <th style={{ width: '17%' }}>Plan</th>
            <th>Type</th>
            <th style={{ width: '10%' }}>Date</th>
            <th style={{ width: '22.5%' }}>Amount</th>
        </tr>
    </thead>
);

function Type(props: { type: string, subType: string }) {
    return (<>
        <div>{props.type}</div>

        {props.subType &&
            <div className="TransactionSubType">({props.subType})</div>
        }
    </>);
}

function SmallLabel(props: { label: string, children: string | ReactElement, className: string }) {

    return (
        <Col className={`py-1 text-left ${props.className}`}>
            <div className="stripedTableSmallLabel">{props.label}</div>
            <div className="stripedTableSmallValue">{props.children}</div>
        </Col>
    );

}

const LoadingSkeleton = () => (
    <Card className="pageCard secondary">
        <div><Skeleton width={'70%'} height={'2em'} /></div>
        <div><Skeleton /></div>

        <div className="mt-3"><Skeleton height={'6em'} /></div>
    </Card>
);