import * as React from "react";
import { NetRateofReturnDetail } from "../../../Gateway.dtos";

export interface NetRateofReturnGridRowProps { netRateofReturnDetail: NetRateofReturnDetail, i: number }
function NetRateofReturnGridRow(props: NetRateofReturnGridRowProps): React.ReactElement {
    const netRateofReturnDetail = props.netRateofReturnDetail;

    return (
        <>
            <td className="border-left border-right text-right">
                {netRateofReturnDetail.amount}{netRateofReturnDetail.amount !== "N/A" ? "%" : ""}
            </td>
        </>
    );
}

export { NetRateofReturnGridRow };