import { ILoadableState } from "../definitions/ILoadableState";
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";

//Type Guard. https://www.typescriptlang.org/docs/handbook/advanced-types.html
function isEnumState(state: ILoadableState | IEnumLoadableState): state is IEnumLoadableState {
    return (state as IEnumLoadableState)?.loadState !== undefined;
}


const stateNeedsLoading = function (state: ILoadableState | IEnumLoadableState): boolean {
    if (isEnumState(state)) {
        return state?.loadState === DataLoadState.None;
    } else {
        if (state?.error) {
            return false;
        }
        return !state?.isLoaded && !state?.isLoading;
    }    
};

export { stateNeedsLoading };
