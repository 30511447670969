import React, { useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { PageTitle } from '../PageTitle';
import Dental from '../cards/health/Dental';
import Medical from '../cards/health/Medical';
import Vision from '../cards/health/Vision';
import Prescription from '../cards/health/Prescription';
import Biometric from '../cards/health/Biometric';
import Telemedicine  from '../cards/health/Telemedicine';
import HealthAndReimbursement from '../cards/health/HealthAndReimbursement';
import { AppState } from '../../store/configureStore';
import { connect } from 'react-redux';
import BeneficiaryReviewModal from '../BeneficiaryReviewModal';
import Medicare from '../cards/health/Medicare';
import { Health } from '../../definitions/Claims';
import UnlockBanner from '../UnlockBanner';
import { PlanType } from '../../Gateway.dtos';

type THealthProps = ReturnType<typeof mapStateToProps>;

const HealthPage = ({
    hasHsa,
    hasClaim,
    hasMedicare,
    planElections,
    dateAsOf
}: THealthProps) => {

    useEffect(() => {
        document.title = "Benefits Access - Health"
    }, []);

    const hasMedical = !!planElections?.find(p => p.type === PlanType.MEDICAL);
    const hasPrescription = !!planElections?.find(p => p.type === PlanType.PHARMACY);
    const hasDental = !!planElections?.find(p => p.type === PlanType.DENTAL);
    const hasVision = !!planElections?.find(p => p.type === PlanType.VISION);

    const showOtherBenefits = hasHsa || hasMedical || hasPrescription || hasDental || hasVision

    if (!hasClaim) {
        return <></>;
    }


    const Title = () => (<>
        <PageTitle title="Health Details" color="health-color-bg" />
        <Card.Title id="cardAsOfDate">Data as of {dateAsOf}</Card.Title>
    </>);

    const DesktopView = () => (
        <Card className="detailCard desktop" style={{ marginTop: `${showOtherBenefits ? '0' : '2em'}` }}>
            <Title />
            <Card.Body>
                <Row>
                    <Col className="col-12 col-lg-6">
                        <Medicare />
                        {hasHsa ? <HealthAndReimbursement /> : <Medical />}
                        <Prescription />
                        <Vision />
                        {showOtherBenefits && <Telemedicine />}                        
                    </Col>
                    <Col className="col-12 col-lg-6">
                        {hasHsa && <Medical />}
                        <Dental />
                        {showOtherBenefits && <Biometric />}                        
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );

    const MobileView = () => (
        <div className="detailCard mobile">
            <Card>
                <Title />
                <Card.Body>
                    {hasHsa ? <HealthAndReimbursement /> :
                        hasMedicare ? <Medicare /> : <Medical />}
                </Card.Body>
            </Card>

            {(hasHsa && hasMedicare) && <Medicare />}
            {(hasHsa || hasMedicare) && <Medical />}
            <Prescription />
            <Dental />
            <Vision />
            {showOtherBenefits && <>
                <Biometric />
                <Telemedicine />
            </>}
        </div>
    );

    return (
        <>
            {/*<HealthQuotientAlert />*/}

            <BeneficiaryReviewModal />

            <UnlockBanner />

            <DesktopView />

            <MobileView />
        </>
    )
};
function mapStateToProps(state: AppState) {
    return {
        hasMedicare: state.medicare?.medicare?.isEnrolled_ViaBen1 || state.medicare?.medicare?.isEnrolled_ViaBen2,
        planElections: state.healthPlans?.healthPlans?.planElections,
        hasHsa: state.hsa.hsa?.healthSavingsAccounts?.length > 0,
        dateAsOf: state.healthPlans.healthPlans?.dateAsOf,
        hasClaim: state.claims.claims?.claims.find(c => c === Health.View)
    }
}

export default connect(
    mapStateToProps
)(HealthPage);
