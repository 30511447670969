import { AppState } from "./configureStore";
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { push } from "connected-react-router";
import { GatewayPath } from "@wespath/gateway-navigation";
import { AddVisitedStep, BankAccount, CallOracleResetProcess, GetLsriInfo, GetLsriInfoResponse, LsriAccountTransfer, LsriAccountTransferResponse, LsriSocSecStatus, LsriStage, OracleCalculation, OracleLsriElection, OracleUpdateLSRIAgeQlacBridgeResponse, SubmitLsriElection, TaxOptions, UpdateLSRIAgeQlacBridge, UpdateLsriBankAccount, UpdateLsriTaxOptions, RecalcOracleLsriElection, ValidateBankAccount, LsriSelectionClearedFlag, CallOracleResetToPptVersion, DeleteLsri, OracleVisitedSteps } from "../Gateway.dtos";
import { client } from "../App";
import { MenuOptions } from "../definitions/WizardProgressBarProps";
import { DataLoadState, IEnumLoadableState } from "../definitions/IEnumLoadableState";
import format from "date-fns/format";


interface Error {
    errorMessage: string | unknown
}

export interface ILsriState extends IEnumLoadableState {
    lsriInfo: GetLsriInfoResponse,
    systemElection: OracleCalculation,
    userElection: OracleCalculation,
    previousUserElection: OracleCalculation,
    showCompare: boolean,
    showSelectionClearedModal: boolean,

    ssIneligible: boolean,
    mppIneligible: boolean,
    bridgeIneligible: boolean,
    qlacIneligible: boolean,

    accountTransferResponse: LsriAccountTransferResponse,
    bankAcctValidationMsg?: string,
    minPaymentMsgClosed: boolean

    ssMin: number,
    ssMax: number,

    wizardType?: LsriStage,
    redirectToLastPopulatedStep?: GatewayPath,

    progressMax: number,
    progressCurrent: number,

    lastsync?: string,

    fundingSelAcct: string,
    fundingSelAmt?: number,
    isLsriPendingTransfer: boolean,

    menuOptions: MenuOptions,

    updateLoadState: DataLoadState,
    submitLoadState: DataLoadState,
    calculationLoadState: DataLoadState,
    resetLoadState: DataLoadState,
    accountTransferLoadState: DataLoadState,
    socSecLoadState: DataLoadState,

    denyCount : number
}

const initialLsriState: ILsriState = {
    lsriInfo: {} as GetLsriInfoResponse,
    systemElection: {} as OracleCalculation,
    userElection: {} as OracleCalculation,
    previousUserElection: {} as OracleCalculation,
    showCompare: false,
    showSelectionClearedModal: false,

    ssIneligible: false,
    mppIneligible: false,
    bridgeIneligible: false,
    qlacIneligible: false,

    accountTransferResponse: {} as LsriAccountTransferResponse,
    bankAcctValidationMsg: undefined,
    minPaymentMsgClosed: false,

    ssMin: 0,
    ssMax: 0,

    wizardType: undefined,
    redirectToLastPopulatedStep: undefined,

    progressMax: 5,
    progressCurrent: 0,

    lastsync: undefined,

    fundingSelAcct: "",
    fundingSelAmt: undefined,
    isLsriPendingTransfer: false,

    menuOptions: {
        enabledMenuOptions: [] as GatewayPath[],
        hiddenMenuOptions: [] as GatewayPath[],
        checkedMenuOptions: [] as GatewayPath[],
        lsriSyncBanner: true,
        isLoading: false
    } as MenuOptions,

    loadState: DataLoadState.None,
    updateLoadState: DataLoadState.None,
    submitLoadState: DataLoadState.None,
    calculationLoadState: DataLoadState.Loaded,     //Setting this to loaded at default will load the animation the first time
    resetLoadState: DataLoadState.None,
    accountTransferLoadState: DataLoadState.None,
    socSecLoadState: DataLoadState.None,

    denyCount: 0
}

const setEnabledMenuOptions = createAction<GatewayPath[]>('lsri/setEnabledMenuOptions');
const setCheckedMenuOptions = createAction<GatewayPath[]>('lsri/setCheckedMenuOptions');
const setProgressCurrent = createAction<number>('lsri/setProgressCurrent');

const setWizardType = createAction<LsriStage>('lsri/setWizardType');
const setShowSelectionClearedModal = createAction<boolean>('lsri/setShowSelectionClearedModal');
const setShowMinPaymentMsg = createAction('lsri/setShowMinPaymentMsg');
const updateBankInfo = createAction<BankAccount>('lsri/updateBankInfo');
const updateTaxOptions = createAction<TaxOptions>('lsri/updateTaxOptions');
const updateFundingSelAcct = createAction<string>('lsri/updateFundingSelAcct');
const updateIsLsriPendingTransfer = createAction<string>('lsri/updateIsLsriPendingTransfer');
const updateFundingSelAmt = createAction<number | undefined>('lsri/updateFundingSelAmt');
const setPostSocSecElectionMenuOptions = createAction('lsri/setPostSocSecElectionMenuOptions');
const resetRedirectToLastPopulatedStep = createAction('lsri/resetRedirectToLastPopulatedStep');
const resetSocSecLoadState = createAction('lsri/resetSocSecLoadState');
const resetUpdateLoadState = createAction('lsri/resetUpdateLoadState');
const resetCalcLoadState = createAction('lsri/resetCalcLoadState');
const resetBalanceTransferLoadState = createAction('lsri/resetBalanceTransferLoadState');

const setSocSecLoadStateLoading = createAction('lsri/setSocSecLoadStateLoading');

const fetchLsriInfo = createAsyncThunk<
    {
        response: GetLsriInfoResponse,
        oracleLsriElection: OracleLsriElection,
        userElection: OracleCalculation,
        wizardType: LsriStage,
        hiddenMenuOptions: GatewayPath[],
        enabledMenuOptions: GatewayPath[],
        lastPopulatedStep: GatewayPath | undefined,
        ssIneligible: boolean,
        mppIneligible: boolean,
        updateSocSecState: boolean,
        progressCurrent: number
    },
    { socSecStatus?: LsriSocSecStatus, socSecEstAmt?: number, socSecChangedToNo?: boolean },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/fetchLsriInfo',
    async (_, thunkApi) => {
        try {

            //This will show the update message if the user is passing in a social security value
            const updateSocSecState = _.socSecStatus !== undefined;

            const response = await client.post(new GetLsriInfo({
                stage: thunkApi.getState().lsri.wizardType,
                socSecStatus: _.socSecStatus,
                socSecEstAmt: _.socSecEstAmt,
                socSecChangedToNo: _.socSecChangedToNo ?? false
            }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            if ((response.oracleLsriElection?.socialSecurity && response.isEligible) || response.stage === LsriStage.Pending || response.stage === LsriStage.Manage) {

                //Populate return values
                const wizardType = response.stage
                const oracleLsriElection = response.oracleLsriElection;
                const visitedSteps = oracleLsriElection?.visitedSteps
                const progressCurrent = calculateProgressCounter(visitedSteps)
                let lastPopulatedStep = undefined
                const skipLastPopulatedStepRedirect = thunkApi.getState().lsri?.lsriInfo?.oracleLsriElection?.visitedSteps !== undefined
                const socSecStatus = oracleLsriElection?.socialSecurity?.ss_Status
                const userElection = oracleLsriElection?.matrix?.find(m => m.elected) ?? oracleLsriElection?.systemDefault ?? {} as OracleCalculation
                const isQlacOnly = response?.messages?.find(m => m.type?.toLowerCase() === "qlaconly")

                //Check eligibility based on data
                const ssIneligible = oracleLsriElection?.socialSecurity?.ss_Status === LsriSocSecStatus.Ineligible
                const mppIneligible = !response.showMppPage

                //Determine if any menu options should be hidden
                const hiddenMenuOptions: (GatewayPath)[] = [];
                thunkApi.getState().lsri.menuOptions.hiddenMenuOptions?.forEach((h) => hiddenMenuOptions.push(h))

                if (ssIneligible) hiddenMenuOptions.push(GatewayPath.LsriSocialSecurity)
                if (mppIneligible) hiddenMenuOptions.push(GatewayPath.LsriMpp)

                if (isQlacOnly) {
                    hiddenMenuOptions.push(GatewayPath.LsriFunding);
                    hiddenMenuOptions.push(GatewayPath.LsriTaxOptions);
                    hiddenMenuOptions.push(GatewayPath.LsriBankInfo);
                }

                //Determine if any menu options should be enabled
                const enabledMenuOptions: (GatewayPath)[] = [];

                if (wizardType === LsriStage.Setup || wizardType === LsriStage.Commit) {
                    enabledMenuOptions.push(GatewayPath.LsriSocialSecurity)

                    //Determine if additional steps should be enabled based on social security election
                    if (socSecStatus !== undefined || ssIneligible) {

                        //Enable these options if user has reviewed SS or is ineligible
                        enabledMenuOptions.push(GatewayPath.LsriMpp)
                        enabledMenuOptions.push(GatewayPath.LsriFunding)
                        enabledMenuOptions.push(GatewayPath.LsriBridge)
                        enabledMenuOptions.push(GatewayPath.LsriQlac)
                        enabledMenuOptions.push(GatewayPath.LsriPaymentOptions)
                        enabledMenuOptions.push(GatewayPath.LsriBankInfo)
                        enabledMenuOptions.push(GatewayPath.LsriTaxOptions)
                    }

                    //Detemine if more steps should be enabled based on newElection data
                    let enableReviewStep = true
                    const isParticipant = !thunkApi.getState().account.profileName

                    if (isParticipant) {
                        visitedSteps?.socialSecurity ? lastPopulatedStep = GatewayPath.LsriSocialSecurity : enableReviewStep = false
                        if (!mppIneligible) { visitedSteps?.mpp ? lastPopulatedStep = GatewayPath.LsriMpp : enableReviewStep = false }
                        visitedSteps?.funding ? lastPopulatedStep = GatewayPath.LsriFunding : enableReviewStep = false
                        visitedSteps?.bridge ? lastPopulatedStep = GatewayPath.LsriBridge : enableReviewStep = false
                        visitedSteps?.qlac ? lastPopulatedStep = GatewayPath.LsriQlac : enableReviewStep = false
                    }
                    else {
                        //If not ppt, enable review step as long as socSecStatus has a value
                        enableReviewStep = socSecStatus !== undefined
                    }

                    if (enableReviewStep)
                        enabledMenuOptions.push(GatewayPath.LsriReview)

                    if (visitedSteps?.review && enableReviewStep)
                        lastPopulatedStep = GatewayPath.LsriReview

                    //Do not redirect if user was previously in the wizard and made a change that called this function again
                    if (skipLastPopulatedStepRedirect)
                        lastPopulatedStep = undefined

                }

                if (wizardType === LsriStage.Commit) {
                    enabledMenuOptions.push(GatewayPath.LsriPaymentOptions)
                    enabledMenuOptions.push(GatewayPath.LsriBankInfo)
                    enabledMenuOptions.push(GatewayPath.LsriTaxOptions)
                }
                else if (wizardType === LsriStage.Manage) {
                    lastPopulatedStep = GatewayPath.LsriSummary
                    enabledMenuOptions.push(GatewayPath.LsriSummary)

                    if (!isQlacOnly) {
                        enabledMenuOptions.push(GatewayPath.LsriFunding)
                        enabledMenuOptions.push(GatewayPath.LsriTaxOptions)
                        enabledMenuOptions.push(GatewayPath.LsriBankInfo)
                    }
                }
                else if (wizardType === LsriStage.Pending) {
                    lastPopulatedStep = GatewayPath.LsriSummary
                    enabledMenuOptions.push(GatewayPath.LsriSummary)
                }

                return {
                    response,
                    oracleLsriElection,
                    userElection,
                    wizardType,
                    hiddenMenuOptions,
                    enabledMenuOptions,
                    lastPopulatedStep,
                    ssIneligible,
                    mppIneligible,
                    updateSocSecState,
                    progressCurrent
                };
            }
            else {
                const mppIneligible = !response.showMppPage;
                const hiddenMenuOptions: (GatewayPath)[] = [];
                if (mppIneligible) hiddenMenuOptions.push(GatewayPath.LsriMpp)
                return {
                    response,
                    oracleLsriElection: response.oracleLsriElection,
                    userElection: {} as OracleCalculation,
                    wizardType: LsriStage.Setup,
                    hiddenMenuOptions: hiddenMenuOptions,
                    enabledMenuOptions: [] as GatewayPath[],
                    lastPopulatedStep: undefined,
                    ssIneligible: false,
                    mppIneligible: true,
                    updateSocSecState,
                    progressCurrent: 0
                };
            }
        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)


const addVisitedStep = createAsyncThunk<
    { fullReset: boolean },
    { path: GatewayPath },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/addVisitedStep',
    async (_, thunkApi) => {
        try {
            const response = await client.post(new AddVisitedStep({ step: _.path, lsriPartId: thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            //Check to see if data needs to be refreshed
            return {
                fullReset: response.partDoesNotExist === true
            }

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const updateLSRIAgeQlacBridge = createAsyncThunk<
    {
        userElection: OracleCalculation,
        oracleResponse: OracleUpdateLSRIAgeQlacBridgeResponse
    },
    { userElection: OracleCalculation },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/updateLSRIAgeQlacBridge',
    async (_, thunkApi) => {

        try {

            //Get the scenario
            const scenario = (thunkApi.getState().lsri.lsriInfo.oracleLsriElection.matrix.find(m =>
                    m.scenario === _.userElection.scenario &&
                    m.ssAge === _.userElection.ssAge &&
                    m.includeMpp === _.userElection.includeMpp
                ) ?? {} as OracleCalculation).scenario

            const response = await client.post(new UpdateLSRIAgeQlacBridge({
                scenario,
                lsriPartId: thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId,
                qlacFlag: _.userElection.scenario.includes("Q"),
                bridgeFlag: _.userElection.scenario.includes("B"),
                selectedSSAge: _.userElection.ssAge
            }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            return { oracleResponse: response.output, userElection: _.userElection };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const recalcOracleLsriElection = createAsyncThunk<
    {
        oracleLsriElection: OracleLsriElection,
        userElection: OracleCalculation
    },
    { userElection: OracleCalculation, commencementDate?: string },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/recalcOracleLsriElection',
    async (_, thunkApi) => {

        try {            

            const response = await client.post(new RecalcOracleLsriElection({
                lsriPartId: thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId,
                commencementDate: _.commencementDate ?? thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.paymentOptions.find(p => p.elected === true)?.date,
                dcAmount: _.userElection.amtAllocated,
                includeMpp: _.userElection.includeMpp
            }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;


            const oracleLsriElection = response.oracleLsriElection
            const userElection = oracleLsriElection?.matrix?.find(m => m.elected) ?? oracleLsriElection?.systemDefault ?? {} as OracleCalculation

            return { oracleLsriElection, userElection }


        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const submitLsriElection = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/submitLsriElection',
    async (_, thunkApi) => {
        try {
            const response = await client.post(new SubmitLsriElection({
                bankInfo: thunkApi.getState().lsri.lsriInfo.bankAccount,
                taxOptions: thunkApi.getState().lsri.lsriInfo.taxOptions,
                selectedPaymentDate: thunkApi.getState().lsri.lsriInfo.oracleLsriElection.paymentOptions.find(p => p.elected === true)?.date ?? "",
                oracleCalculation: thunkApi.getState().lsri.userElection,
                mppRolloverPercent: thunkApi.getState().lsri.lsriInfo.mppRolloverPercent,
                partType: thunkApi.getState().lsri.lsriInfo.partType,
                lsriPartId: thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId,
                caDesignated: thunkApi.getState().lsri.lsriInfo.caDesignated,
                hasRoth: thunkApi.getState().lsri.lsriInfo.rothMsg
            }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const validateBankInfo = createAsyncThunk<
    { bankAcctValidationMsg?: string, bankOwnership?: string, denyCount: number },
    { bankAccount: BankAccount },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/validateBankInfo',
    async (_, thunkApi) => {
        try {

            const bankAccount = _.bankAccount
            const response = await client.post(new ValidateBankAccount({ bankAccount }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            return {
                bankAcctValidationMsg: response.bankAcctValidationMsg,
                bankOwnership: response.bankOwnership,
                denyCount: response.denyCount
            };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const submitBankInfo = createAsyncThunk<
    { bankAccount: BankAccount },
    { bankAccount: BankAccount },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/submitBankInfo',
    async (_, thunkApi) => {
        try {

            const bankAccount = _.bankAccount

            const response = await client.post(new UpdateLsriBankAccount({ bankAccount }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;            

            return { bankAccount };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const submitTaxOptions = createAsyncThunk<
    { taxOptions: TaxOptions },
    { taxOptions: TaxOptions },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/submitTaxOptions',
    async (_, thunkApi) => {
        try {

            const taxOptions = _.taxOptions
            const response = await client.post(new UpdateLsriTaxOptions({ taxOptions }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            return { taxOptions };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const performBalanceTransfer = createAsyncThunk<
    { accountTransferResponse: LsriAccountTransferResponse },
    { accountTransfer: LsriAccountTransfer },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/performBalanceTransfer',
    async (_, thunkApi) => {
        try {

            const accountTransfer = _.accountTransfer
            const response = await client.post(new LsriAccountTransfer({ ...accountTransfer }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            return { accountTransferResponse: response };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const resetToOriginalSetup = createAsyncThunk<
    { oracleLsriElection: OracleLsriElection },
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/resetToOriginalSetup',
    async (_, thunkApi) => {
        try {

            //Tell server that we're restarting and get new values
            const lsriPartId = thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId
            const response = await client.post(new CallOracleResetProcess({ lsriPartId }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            return {
                oracleLsriElection: response.oracleLsriElection
            };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const resetToPptVersion = createAsyncThunk<
    {
        oracleLsriElection: OracleLsriElection,
        fullReset: boolean
    },
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/resetToPptVersion',
    async (_, thunkApi) => {
        try {

            //Tell server that we're restarting and get new values
            const lsriPartId = thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId
            const response = await client.post(new CallOracleResetToPptVersion({ lsriPartId }));
            const currentStage = thunkApi.getState().lsri.wizardType

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            //Check if data indicates that transaction is now pending
            const newStage = response.oracleLsriElection?.socialSecurity?.commitDate ? LsriStage.Pending : LsriStage.Setup
            const fullReset = newStage !== currentStage || (newStage === LsriStage.Setup && response.oracleLsriElection?.socialSecurity?.ss_Status !== undefined)

            return {
                oracleLsriElection: response.oracleLsriElection,
                fullReset
            };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const deleteLsriElection = createAsyncThunk <
    void,
    { deleteFromOracle: boolean },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lsri/deleteLsriElection',
    async (_, thunkApi) => {
        try {
            const lsriPartId = thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId
            const isPending = thunkApi.getState().lsri.wizardType === LsriStage.Pending
            const isParticipant = !thunkApi.getState().account.profileName
            
            //Delete election from oracle for non-ppts based on variable setting
            if (_.deleteFromOracle && lsriPartId && !isParticipant && !isPending) {
                await client.post(new DeleteLsri({
                    lsriPartId: thunkApi.getState().lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.lsriPartId
                }));
            }
            else
                return

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }
)

const slice = createSlice({
    name: 'lsri',
    initialState: initialLsriState,
    reducers: {
        resetState: () => initialLsriState,
        saveInitiate: (state: ILsriState) => {
            return {
                ...state
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLsriInfo.pending, (state: ILsriState) => {
                if (state.socSecLoadState !== DataLoadState.Loading) state.loadState = DataLoadState.Loading
                state.menuOptions.isLoading = true
                state.menuOptions.enabledMenuOptions = []
                state.menuOptions.checkedMenuOptions = []
            })
            .addCase(fetchLsriInfo.fulfilled, (state: ILsriState, { payload }) => {
                state.loadState = DataLoadState.Loaded
                state.lsriInfo = payload.response
                state.wizardType = payload.wizardType
                state.systemElection = payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation
                state.userElection = payload.userElection
                state.previousUserElection = payload.userElection
                state.redirectToLastPopulatedStep = payload.lastPopulatedStep
                state.menuOptions.hiddenMenuOptions = payload.hiddenMenuOptions
                state.menuOptions.enabledMenuOptions = payload.enabledMenuOptions
                state.menuOptions.mobileMenuText = payload.wizardType === LsriStage.Manage || payload.wizardType === LsriStage.Pending ? "Manage" : "Setup"
                state.menuOptions.isLoading = false
                state.ssIneligible = payload.ssIneligible
                state.mppIneligible = payload.mppIneligible
                state.bridgeIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("B"));
                state.qlacIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("Q"));
                state.showCompare = showCompare(payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation, payload.userElection)
                state.ssMin = payload.response?.prLsriInfo?.ss_Min_Amt ?? state.ssMin
                state.ssMax = payload.response?.prLsriInfo?.ss_Max_Amt ?? state.ssMax
                state.showSelectionClearedModal = (payload.oracleLsriElection?.socialSecurity?.selectionCleared ?? LsriSelectionClearedFlag.N) !== LsriSelectionClearedFlag.N
                state.menuOptions.blurContent = (payload.oracleLsriElection?.socialSecurity?.selectionCleared ?? LsriSelectionClearedFlag.N) !== LsriSelectionClearedFlag.N
                state.progressMax = 5 - (state.mppIneligible ? 1 : 0)
                state.progressCurrent = payload.progressCurrent
                if (payload.response.oracleLsriElection?.systemDefault?.lastSync)
                    state.lastsync = payload.response.oracleLsriElection?.systemDefault?.lastSync
                if (payload.updateSocSecState)
                    state.socSecLoadState = DataLoadState.Loaded

                state.isLsriPendingTransfer = payload.response.messages?.findIndex(m => m.type === "pendTransfer") > -1

                state.submitLoadState = DataLoadState.None
            })
            .addCase(fetchLsriInfo.rejected, (state: ILsriState, action) => {
                state.loadState = DataLoadState.Error
                state.calculationLoadState = DataLoadState.Error
                state.menuOptions.isLoading = false
                if (state.socSecLoadState === DataLoadState.Loading) {
                    state.socSecLoadState = DataLoadState.Error
                }
            })
            .addCase(resetToOriginalSetup.pending, (state: ILsriState) => {
                state.resetLoadState = DataLoadState.Loading
            })
            .addCase(resetToOriginalSetup.fulfilled, (state: ILsriState, { payload }) => {
                const userElection = payload.oracleLsriElection?.matrix?.find(m => m.elected) ?? payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation

                state.resetLoadState = DataLoadState.Loaded
                state.lsriInfo.oracleLsriElection = payload.oracleLsriElection
                state.systemElection = payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation
                state.userElection = userElection
                state.previousUserElection = userElection
                state.bridgeIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("B"));
                state.qlacIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("Q"));
                state.showCompare = false
            })
            .addCase(resetToOriginalSetup.rejected, (state: ILsriState, action) => {
                state.resetLoadState = DataLoadState.Error
            })
            .addCase(resetToPptVersion.pending, (state: ILsriState) => {
                state.resetLoadState = DataLoadState.Loading
            })
            .addCase(resetToPptVersion.fulfilled, (state: ILsriState, { payload }) => {
                state.resetLoadState = DataLoadState.Loaded
                state.lastsync = format(new Date(), "MM/dd/yyyy hh:mm:ss aa")

                if (payload.fullReset) {
                    state.loadState = DataLoadState.None
                    state.redirectToLastPopulatedStep = GatewayPath.LsriOverview
                } else {
                    const userElection = payload.oracleLsriElection?.matrix?.find(m => m.elected) ?? payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation

                    state.lsriInfo.oracleLsriElection = payload.oracleLsriElection
                    state.systemElection = payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation
                    state.userElection = userElection
                    state.previousUserElection = userElection
                    state.bridgeIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("B"));
                    state.qlacIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("Q"));
                    state.showCompare = showCompare(payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation, userElection)
                }
            })
            .addCase(resetToPptVersion.rejected, (state: ILsriState, action) => {
                state.resetLoadState = DataLoadState.Error
            })
            .addCase(setEnabledMenuOptions, (state: ILsriState, { payload }) => {
                state.menuOptions.enabledMenuOptions = payload
            })
            .addCase(setCheckedMenuOptions, (state: ILsriState, { payload }) => {
                state.menuOptions.checkedMenuOptions = payload
            })
            .addCase(setProgressCurrent, (state: ILsriState, { payload }) => {
                state.progressCurrent = payload
            })
            .addCase(recalcOracleLsriElection.pending, (state: ILsriState, { meta }) => {
                state.calculationLoadState = DataLoadState.Loading
                state.resetLoadState = DataLoadState.None                           //Clear this if it was previously errored
                //Save the states without waiting for oracle to save it to make changes more responsive
                state.userElection = meta.arg.userElection
            })
            .addCase(recalcOracleLsriElection.fulfilled, (state: ILsriState, { meta, payload }) => {
                if (payload.oracleLsriElection?.partDoesNotExist) {
                    console.log("performing full reset in recalcOracleLsriElection");
                    state.loadState = DataLoadState.None
                    state.redirectToLastPopulatedStep = GatewayPath.LsriOverview
                }                
                state.calculationLoadState = DataLoadState.Loaded
                state.lsriInfo.oracleLsriElection = payload.oracleLsriElection
                state.systemElection = payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation
                state.userElection = payload.userElection
                state.previousUserElection = payload.userElection
                state.bridgeIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("B"));
                state.qlacIneligible = !payload.oracleLsriElection?.matrix?.find(m => m.scenario?.includes("Q"));
                state.showSelectionClearedModal = (payload.oracleLsriElection?.socialSecurity?.selectionCleared ?? LsriSelectionClearedFlag.N) !== LsriSelectionClearedFlag.N
                state.menuOptions.blurContent = (payload.oracleLsriElection?.socialSecurity?.selectionCleared ?? LsriSelectionClearedFlag.N) !== LsriSelectionClearedFlag.N
                state.showCompare = showCompare(payload.oracleLsriElection?.systemDefault ?? {} as OracleCalculation, payload.userElection)
            })
            .addCase(recalcOracleLsriElection.rejected, (state: ILsriState) => {
                state.calculationLoadState = DataLoadState.Error
                state.userElection = state.previousUserElection                     //Rollback the election on error
            })
            .addCase(updateLSRIAgeQlacBridge.pending, (state: ILsriState, { meta }) => {
                state.resetLoadState = DataLoadState.None                           //Clear this if it was previously errored
                state.calculationLoadState = DataLoadState.None                     //Clear this if it was previously errored
                //Save the states without waiting for oracle to save it to make changes more responsive
                state.userElection = meta.arg.userElection
                state.showCompare = showCompare(state.systemElection, meta.arg.userElection)
            })
            .addCase(updateLSRIAgeQlacBridge.fulfilled, (state: ILsriState, { payload }) => {
                state.previousUserElection = payload.userElection
                if (payload.oracleResponse?.partDoesNotExist) {
                    console.log("performing full reset in UpdateLSRIAgeQlacBridge");
                    state.loadState = DataLoadState.None
                    state.redirectToLastPopulatedStep = GatewayPath.LsriOverview
                }
            })
            .addCase(updateLSRIAgeQlacBridge.rejected, (state: ILsriState) => {
                state.calculationLoadState = DataLoadState.Error
                state.userElection = state.previousUserElection                     //Rollback the election on error
            })
            .addCase(submitLsriElection.pending, (state: ILsriState) => {
                state.submitLoadState = DataLoadState.Loading
            })
            .addCase(submitLsriElection.fulfilled, (state: ILsriState, { payload }) => {
                state.submitLoadState = DataLoadState.Loaded
                state.loadState = DataLoadState.None                                //Force overview page to reload data next time it is visited
            })
            .addCase(submitLsriElection.rejected, (state: ILsriState) => {
                state.submitLoadState = DataLoadState.Error
            })
            .addCase(deleteLsriElection.pending, (state: ILsriState) => {
                state.loadState = DataLoadState.None                                //Force overview page to reload data next time it is visited
            })
            .addCase(deleteLsriElection.fulfilled, (state: ILsriState, { payload }) => {
                state.accountTransferLoadState = DataLoadState.None
                state.accountTransferResponse = {} as LsriAccountTransferResponse

                state.fundingSelAcct = ""
                state.fundingSelAmt = undefined
            })
            .addCase(addVisitedStep.pending, (state: ILsriState, { meta }) => {
                //Update these before waiting for response from oracle
                if (meta.arg.path === GatewayPath.LsriSocialSecurity) state.lsriInfo.oracleLsriElection.visitedSteps.socialSecurity = true
                else if (meta.arg.path === GatewayPath.LsriMpp) state.lsriInfo.oracleLsriElection.visitedSteps.mpp = true
                else if (meta.arg.path === GatewayPath.LsriFunding) state.lsriInfo.oracleLsriElection.visitedSteps.funding = true
                else if (meta.arg.path === GatewayPath.LsriBridge) state.lsriInfo.oracleLsriElection.visitedSteps.bridge = true
                else if (meta.arg.path === GatewayPath.LsriQlac) state.lsriInfo.oracleLsriElection.visitedSteps.qlac = true
                else if (meta.arg.path === GatewayPath.LsriReview) state.lsriInfo.oracleLsriElection.visitedSteps.review = true
                else if (meta.arg.path === GatewayPath.LsriPaymentOptions) state.lsriInfo.oracleLsriElection.visitedSteps.payment = true
                else if (meta.arg.path === GatewayPath.LsriBankInfo) state.lsriInfo.oracleLsriElection.visitedSteps.bank = true
                else if (meta.arg.path === GatewayPath.LsriTaxOptions) state.lsriInfo.oracleLsriElection.visitedSteps.tax = true
                else if (meta.arg.path === GatewayPath.LsriVerify) state.lsriInfo.oracleLsriElection.visitedSteps.verify = true
            })
            .addCase(addVisitedStep.fulfilled, (state: ILsriState, { payload }) => {
                if (payload.fullReset) {
                    console.log("performing full reset in VisitedSteps");
                    state.loadState = DataLoadState.None
                    state.redirectToLastPopulatedStep = GatewayPath.LsriOverview
                }
            })
            .addCase(setWizardType, (state: ILsriState, { payload }) => {
                state.loadState = (state.wizardType === LsriStage.Setup && payload === LsriStage.Commit) || (state.wizardType === LsriStage.Commit && payload === LsriStage.Setup)
                    ? state.loadState
                    : DataLoadState.None
                state.wizardType = payload
            })
            .addCase(setShowSelectionClearedModal, (state: ILsriState, { payload }) => {
                state.showSelectionClearedModal = payload
                state.menuOptions.blurContent = payload
                state.calculationLoadState = DataLoadState.None     //Stop the LsriChart drawing animation
            })
            .addCase(setShowMinPaymentMsg, (state: ILsriState) => {
                state.minPaymentMsgClosed = true
                state.calculationLoadState = DataLoadState.None     //Stop the LsriChart drawing animation
            })
            .addCase(setPostSocSecElectionMenuOptions, (state: ILsriState) => {
                if (state.lsriInfo.isEligible)
                    state.menuOptions.enabledMenuOptions = [
                        GatewayPath.LsriSocialSecurity,
                        GatewayPath.LsriMpp,
                        GatewayPath.LsriFunding,
                        GatewayPath.LsriBridge,
                        GatewayPath.LsriQlac,
                        GatewayPath.LsriPaymentOptions,
                        GatewayPath.LsriBankInfo,
                        GatewayPath.LsriTaxOptions
                    ] as GatewayPath[]                
            })
            .addCase(validateBankInfo.pending, (state: ILsriState) => {
                state.bankAcctValidationMsg = undefined
            })
            .addCase(validateBankInfo.fulfilled, (state: ILsriState, { payload }) => {
                state.bankAcctValidationMsg = payload.bankAcctValidationMsg
                state.lsriInfo.bankAccount.bankName = state.lsriInfo.bankAccount.bankName.replace(/&/g, '').replace(/'/g, "").replace(/|/g, "").replace(/`/g, "").replace(/"/g, "")
                if (payload.bankOwnership) state.lsriInfo.bankAccount.bankOwnership = payload.bankOwnership
                state.denyCount = payload.denyCount
            })
            .addCase(validateBankInfo.rejected, (state: ILsriState) => {
                state.updateLoadState = DataLoadState.Error
            })
            .addCase(updateBankInfo, (state: ILsriState, { payload }) => {
                state.lsriInfo.bankAccount = payload
            })
            .addCase(submitBankInfo.pending, (state: ILsriState) => {
                state.updateLoadState = DataLoadState.Loading
                state.bankAcctValidationMsg = undefined
            })
            .addCase(submitBankInfo.fulfilled, (state: ILsriState, { payload }) => {
                state.lsriInfo.bankAccount = payload.bankAccount
                state.updateLoadState = DataLoadState.Loaded
            })
            .addCase(submitBankInfo.rejected, (state: ILsriState) => {
                state.updateLoadState = DataLoadState.Error
            })
            .addCase(updateTaxOptions, (state: ILsriState, { payload }) => {
                state.lsriInfo.taxOptions = payload
            })
            .addCase(submitTaxOptions.pending, (state: ILsriState) => {
                state.updateLoadState = DataLoadState.Loading
            })
            .addCase(submitTaxOptions.fulfilled, (state: ILsriState, { payload }) => {
                state.lsriInfo.taxOptions = payload.taxOptions
                state.updateLoadState = DataLoadState.Loaded
            })
            .addCase(submitTaxOptions.rejected, (state: ILsriState) => {
                state.updateLoadState = DataLoadState.Error
            })
            .addCase(updateFundingSelAcct, (state: ILsriState, { payload }) => {
                state.fundingSelAcct = payload
            })
            .addCase(updateFundingSelAmt, (state: ILsriState, { payload }) => {
                state.fundingSelAmt = payload
            })
            .addCase(updateIsLsriPendingTransfer, (state: ILsriState, { payload }) => {
                state.isLsriPendingTransfer = payload === "true"
            })
            .addCase(performBalanceTransfer.pending, (state: ILsriState) => {
                state.accountTransferLoadState = DataLoadState.Loading
            })
            .addCase(performBalanceTransfer.fulfilled, (state: ILsriState, { payload }) => {
                state.accountTransferResponse = payload.accountTransferResponse
                state.fundingSelAcct = payload.accountTransferResponse.sourceAccount
                state.fundingSelAmt = payload.accountTransferResponse.amount
                state.accountTransferLoadState = DataLoadState.Loaded
            })
            .addCase(performBalanceTransfer.rejected, (state: ILsriState) => {
                state.accountTransferLoadState = DataLoadState.Error
            })
            .addCase(resetRedirectToLastPopulatedStep, (state: ILsriState) => {
                state.redirectToLastPopulatedStep = undefined
            })
            .addCase(resetSocSecLoadState, (state: ILsriState) => {
                state.socSecLoadState = DataLoadState.None
            })
            .addCase(setSocSecLoadStateLoading, (state: ILsriState) => {
                state.socSecLoadState = DataLoadState.Loading
            })
            .addCase(resetUpdateLoadState, (state: ILsriState) => {
                state.updateLoadState = DataLoadState.None
                state.bankAcctValidationMsg = undefined
            })
            .addCase(resetCalcLoadState, (state: ILsriState) => {
                state.calculationLoadState = DataLoadState.None
            })
            .addCase(resetBalanceTransferLoadState, (state: ILsriState) => {
                state.accountTransferLoadState = DataLoadState.None
                state.accountTransferResponse = {} as LsriAccountTransferResponse
            })
            .addCase(getResetAction(), (_state, _action) => initialLsriState)
    }
})

function enableReviewCheck() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {

        if (getState().lsri.menuOptions?.enabledMenuOptions?.includes(GatewayPath.LsriReview))
            return

        let enableReviewStep = true

        const visitedSteps = getState().lsri.lsriInfo.oracleLsriElection?.visitedSteps
        const isParticipant = !getState().account.profileName

        if (isParticipant) {
            if (!visitedSteps?.mpp && !getState().lsri.mppIneligible) enableReviewStep = false
            if (!visitedSteps?.funding) enableReviewStep = false
            if (!visitedSteps?.bridge && !getState().lsri.bridgeIneligible) enableReviewStep = false
            if (!visitedSteps?.qlac && !getState().lsri.qlacIneligible) enableReviewStep = false
        }
        else {
            //If not ppt, enable review step as long as socSecStatus has a value
            enableReviewStep = getState().lsri.lsriInfo.oracleLsriElection?.socialSecurity?.ss_Status !== undefined
        }

        //Enable the Review step
        if (enableReviewStep) {
            const enabledMenuOptions: (GatewayPath)[] = [];
            getState().lsri.menuOptions.enabledMenuOptions?.forEach((h) => enabledMenuOptions.push(h))
            enabledMenuOptions.push(GatewayPath.LsriReview)

            dispatch(setEnabledMenuOptions(enabledMenuOptions))
        }               

        //Calculate the progress bar
        const progressCounter = calculateProgressCounter(visitedSteps)

        dispatch(setProgressCurrent(enableReviewStep ? getState().lsri.progressMax : progressCounter))
        
    }
}

function calculateProgressCounter(visitedSteps: OracleVisitedSteps): number {

    return (visitedSteps?.socialSecurity ? 1 : 0)
        + (visitedSteps?.mpp ? 1 : 0)
        + (visitedSteps?.funding ? 1 : 0)
        + (visitedSteps?.bridge ? 1 : 0)
        + (visitedSteps?.qlac ? 1 : 0)
}

function enableVerifyCheck() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {

        if (getState().lsri.menuOptions?.enabledMenuOptions?.includes(GatewayPath.LsriVerify))
            return

        let enableVerifyStep = true

        const checkedMenuOptions = getState().lsri.menuOptions.checkedMenuOptions

        if (!checkedMenuOptions?.includes(GatewayPath.LsriPaymentOptions)) enableVerifyStep = false
        if (!checkedMenuOptions?.includes(GatewayPath.LsriTaxOptions)) enableVerifyStep = false
        if (!checkedMenuOptions?.includes(GatewayPath.LsriBankInfo)) enableVerifyStep = false

        //Enable the Verify step
        if (enableVerifyStep) {
            const enabledMenuOptions: (GatewayPath)[] = [];
            getState().lsri.menuOptions.enabledMenuOptions?.forEach((h) => enabledMenuOptions.push(h))
            enabledMenuOptions.push(GatewayPath.LsriVerify)

            dispatch(setEnabledMenuOptions(enabledMenuOptions))
        }
    }
}

function addCheckedMenuOptions(path: GatewayPath) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {

        if (getState().lsri.menuOptions.checkedMenuOptions?.includes(path))
            return

        const checkedMenuOptions: (GatewayPath)[] = [];
        getState().lsri.menuOptions.checkedMenuOptions?.forEach((h) => checkedMenuOptions.push(h))
        checkedMenuOptions.push(path)

        dispatch(setCheckedMenuOptions(checkedMenuOptions))
    }
}

function removeCheckedMenuOptions(path: GatewayPath) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {

        if (!getState().lsri.menuOptions.checkedMenuOptions?.includes(path)) 
            return

        const checkedMenuOptions: (GatewayPath)[] = [];
        getState().lsri.menuOptions.checkedMenuOptions?.forEach((h) => h !== path ? checkedMenuOptions.push(h) : null)

        dispatch(setCheckedMenuOptions(checkedMenuOptions))
    }
}

function wizardCancel() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        if (getState().lsri.wizardType === LsriStage.Commit) {
            dispatch(LsriActionCreators.setWizardType(LsriStage.Setup))
            dispatch(push(GatewayPath.LsriReview))
        }
        else {
            dispatch(push(GatewayPath.LsriReview))
        }
    }
}

function resetToOriginal() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        dispatch(resetToOriginalSetup());
        dispatch(setPostSocSecElectionMenuOptions());
    }
}

function resetToPpt() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        dispatch(resetToPptVersion());
    }
}

function showCompare(orig: OracleCalculation, custom: OracleCalculation): boolean {

    if (orig?.lsriStart?.lifeStage === undefined)
        return false

    return !(
        orig.lsriStart.lifeStage === custom?.lsriStart.lifeStage
        && orig.lsriStart.mpp === custom?.lsriStart.mpp
        && orig.lsriStart.socialSecurity === custom?.lsriStart.socialSecurity
        && orig.lsriStart.qlac === custom?.lsriStart.qlac
        && orig.socSecStart.lifeStage === custom?.socSecStart.lifeStage
        && orig.socSecStart.mpp === custom?.socSecStart.mpp
        && orig.socSecStart.socialSecurity === custom?.socSecStart.socialSecurity
        && orig.socSecStart.qlac === custom?.socSecStart.qlac
        && orig.qlacStart.lifeStage === custom?.qlacStart.lifeStage
        && orig.qlacStart.mpp === custom?.qlacStart.mpp
        && orig.qlacStart.socialSecurity === custom?.qlacStart.socialSecurity
        && orig.qlacStart.qlac === custom?.qlacStart.qlac
    );
}

export const LsriActionCreators = {
    fetchLsriInfo,
    setWizardType,
    setShowSelectionClearedModal,
    resetToOriginal,
    resetToPpt,
    setPostSocSecElectionMenuOptions,
    validateBankInfo,
    updateBankInfo,
    submitBankInfo,
    updateTaxOptions,
    submitTaxOptions,
    performBalanceTransfer,
    enableReviewCheck,
    enableVerifyCheck,
    addCheckedMenuOptions,
    removeCheckedMenuOptions,
    addVisitedStep,
    recalcOracleLsriElection,
    updateLSRIAgeQlacBridge,
    submitLsriElection,
    deleteLsriElection,
    setShowMinPaymentMsg,
    updateFundingSelAcct,
    updateFundingSelAmt,
    updateIsLsriPendingTransfer,
    resetRedirectToLastPopulatedStep,
    resetSocSecLoadState,
    setSocSecLoadStateLoading,
    resetUpdateLoadState,
    resetCalcLoadState,
    resetBalanceTransferLoadState,
    resetState: slice.actions.resetState,
    saveInitiate: slice.actions.saveInitiate,
    wizardCancel
};

export const LsriReducer = slice.reducer;


