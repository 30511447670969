import React, { ReactElement } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const Maintenance = (): ReactElement => {

    return (
        <Row className="justify-content-center">
            <Col className="col-12 col-md-6 col-lg-5 col-xl-5">
                <Card className="errorCard">
                    <Card.Title>
                        Maintenance
                    </Card.Title>
                    <Card.Body>
                        <p>
                            Benefits Access is temporarily unavailable for system maintenance. Please try again later. We apologize for any inconvenience.
                        </p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
export { Maintenance };