import { AppState } from "./configureStore";
import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetHsaResponse, GetHsa } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IHsaState extends ILoadableState {
    hsa: GetHsaResponse
}

export const initialHsaState: IHsaState = {
    hsa: {} as GetHsaResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'hsa',
    initialState: {} as IHsaState,
    reducers: {
        resetState: () => {
            return {
                ...initialHsaState
            }
        },
        requestHsa: (state: IHsaState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveHsa: (state: IHsaState, action: PayloadAction<{ hsa: GetHsaResponse }>) => {
            const { hsa } = action.payload;
            return {
                ...state,
                hsa,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveHsaError: (state: IHsaState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialHsaState)
    }
})

const { resetState, requestHsa, receiveHsa, receiveHsaError } = slice.actions;

function fetchHsa() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().hsa;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestHsa());

        try {

            const hsa = await client.post(new GetHsa());

            dispatch(receiveHsa({ hsa }));

            //Check for errors
            if (hsa.responseStatus)
                throw hsa.responseStatus.message;

            //Determine if health page should be displayed based on this data
            if (hsa.healthSavingsAccounts?.length > 0)
                dispatch(displayPageActionCreators.showHealth());

        } catch (e: unknown) {
            dispatch(receiveHsaError());
            console.log(e);
            //dispatch(displayPageActionCreators.showHealth());
        }



    }
}

export const hsaActionCreators = {
    fetchHsa,
    resetState
};

export const hsaReducer = slice.reducer;
