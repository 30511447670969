import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AppState } from '../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { GatewayPath } from '@wespath/gateway-navigation'
import { ChallengeType } from '../Gateway.dtos';
import { MultiFactorTransactionType } from '../store/AuthMultiFactorStore';
import { AppDispatch } from '..';
import { displayPageActionCreators } from '../store/DisplayPageStore';

export interface ProtectedRouteProps extends RouteProps {
    challengeActiveTransaction: MultiFactorTransactionType | undefined;
    isAuthenticated: boolean;
    isChallenging: boolean;
    challengeType: ChallengeType | string | undefined;
    page: string;
}



const ProtectedRoute = (props: ProtectedRouteProps) => {
    const dispatch: AppDispatch = useDispatch();
    let redirectPath = '';
    if (!props.isAuthenticated) {
        dispatch(displayPageActionCreators.setRedirectFromSummary({ redirectFromSummary: props.page }))
        redirectPath = GatewayPath.Login;
    }
    else if (props.isChallenging && (props.page !== GatewayPath.Challenge
        && props.page !== GatewayPath.NeedHelp
        && props.page !== GatewayPath.AccountManagement
        && props.page !== GatewayPath.LsriReview)) {
        //do not let user navigate away from the challenge page
        if (props.challengeType === "Registration")
            redirectPath = GatewayPath.Registration;
        else if (props.challengeType === ChallengeType.LoginHelp)
            redirectPath = GatewayPath.NeedHelp;
        else if (props.challengeType === ChallengeType.Transaction && props.challengeActiveTransaction === 'lsriSetup')
            redirectPath = GatewayPath.LsriReview
        else if (props.challengeType === ChallengeType.Transaction)
            redirectPath = GatewayPath.AccountManagement
        else
            redirectPath = GatewayPath.Challenge;
    }

    if (redirectPath) {
        const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
        return <Route component={renderComponent} render={undefined} />;
    } 

    //if (props.isBaCms) {
    //    const renderComponent = () => <BaCmsPage itemPath={props.baCmsPath ? props.baCmsPath : ""} />;
    //    return <Route component={renderComponent} path={props.path} />;
    //}

    return <Route a {...props} />;
};
    
function mapStateToProps(state: AppState) {
    const isChallenging = state.lnMultiFactor.isChallenging || state.authMultiFactor.isChallenging;
    const challengeType = state.lnMultiFactor.isChallenging ? "Registration" : state.authMultiFactor.challengeType;

    return {
        isAuthenticated: state.account.isAuthenticated,
        isChallenging,
        challengeType,
        challengeActiveTransaction: state.authMultiFactor.activeTransaction,
        page: state.router.location.pathname
    }
}

export default connect(
    mapStateToProps
)(ProtectedRoute);