import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Row, Col, Alert, Card } from 'react-bootstrap';
import { AppState } from '../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../..';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { useWindowDimensions } from '@wespath/gateway-navigation';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import { BankInfo } from '../../definitions/Claims';
import { push } from "connected-react-router";
import { GatewayPath } from '@wespath/gateway-navigation'
import { manageDirectDepositActionCreators } from '../../store/ManageDirectDepositStore';
import { BankAccount, BankAccountType, GetManageDirectDepositInfoResponse } from '../../Gateway.dtos';
import { PageTitle } from '../PageTitle';
import { HidenShowBankAccount } from '../HidenShowBankAccount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faMoneyCheckEdit } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../store/CmsStore';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { SplitTreatments } from '@splitsoftware/splitio-react';


export enum DDCallFrom {
    ManageDirectDeposit,
    PaymentInfo
}

export interface OwnProps { callFrom?: DDCallFrom }
type TManageDirectDepositProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ManageDirectDeposit = (props: TManageDirectDepositProps): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(stateNeedsLoading(props));
    const { manageDirectDepositInfo } = props;
    const { isSmallScreen } = useWindowDimensions();

    const hasClaim_Update = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === BankInfo.Update)

    const cmsLandingMessage = props.cmsData.fragments?.find(f => f.name === "LandingMessage")?.html ?? '';
    const cmsMultiBankActMessage = props.cmsData.fragments?.find(f => f.name === "MultiBankActMessage")?.html ?? '';
    const cmsNoBankInfoMessage = props.cmsData.fragments?.find(f => f.name === "NoBankInfoMessage")?.html ?? '';
    const cmsSuccessBankUpdate = props.cmsData.fragments?.find(f => f.name === "SuccessBankUpdate")?.html ?? ''; 
    const cmsSuccessMsgDisplayTime = props.cmsData.fragments?.find(f => f.name === "SuccessMsgDisplayTime")?.html ?? '12000'; 

    const showSuccessMsg = props.showSuccessMsg;


    useEffect(() => {
        document.title = "Benefits Access - Direct Deposit";
        props.fetchUpdateBankInfoCmsData();
        props.fetchDirectDepositCmsData();

        dispatch(manageDirectDepositActionCreators.fetchManageDirectDepositInfo())
            .then(() => {
                setIsLoading(false);
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.updateLoadState === DataLoadState.Loaded) {
            setTimeout(() => { dispatch(manageDirectDepositActionCreators.resetUpdateLoadState()) }, Number(cmsSuccessMsgDisplayTime));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.updateLoadState]);

    const handleClickUpdate = () => {
        dispatch(push(GatewayPath.UpdateBankInformation));
    }

    const MultipleBankAccountMsg = () => (
        <Alert variant="warning" className="mt-5 col-12 col-sm-12 col-lg-8">
            <FontAwesomeIcon icon={faExclamationCircle} />
            {cmsMultiBankActMessage}
        </Alert>
    )

    const SuccessMsg = () => (
        <Alert variant="success" className="col-12 col-sm-12 col-lg-10">
            <FontAwesomeIcon icon={faCheckCircle} /><strong>Success</strong><br />
            {cmsSuccessBankUpdate}
        </Alert>
    )

    const NoBankDataMsg = (propsNoBankDataMsg: {
        manageDirectDepositResponse: GetManageDirectDepositInfoResponse,
        callFrom?: DDCallFrom
    }): React.ReactElement => {
        const { manageDirectDepositResponse, callFrom } = propsNoBankDataMsg;

        return <>
            <Alert variant="info" className={callFrom === DDCallFrom.PaymentInfo ? "mt-4 col-12" : "mt-4 col-12 col-sm-12 col-lg-8"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                {manageDirectDepositResponse.responseStatus.errorCode === "100"
                    ?  cmsNoBankInfoMessage 
                    : manageDirectDepositResponse.responseStatus.message}
            </Alert>

            <NavLink to={GatewayPath.Retirement} className="retirement-link-dd">View your retirement details</NavLink>
        </>

    }

    const LandingPageMsg = (propsLandingPageMsg: {
        callFrom?: DDCallFrom
    }): React.ReactElement => {

        const { callFrom } = propsLandingPageMsg;

        return <>


            <SplitTreatments names={['PG_Enable_Payments_Page']}>
                {({ treatments, isReady }) => {
                    if (isReady) {
                        return (<>
                            {treatments['PG_Enable_Payments_Page'].treatment === "on" ? (
                                <span className="bank-dd-msg">{cmsLandingMessage} {callFrom !== DDCallFrom.PaymentInfo && <NavLink to={GatewayPath.PaymentInfo}>View payment details</NavLink>}</span>
                            )
                                : (<span className="bank-dd-msg">{cmsLandingMessage}</span>
)
                            }
                        </>)
                    }
                }}
            </SplitTreatments>

        </>

    }

    interface BankAccountDetailsProps { bankAccountDetails: BankAccount, isMoreThanOneBankAccount: boolean,isSmallScreenRequest?: boolean }
    const BankAccountDetails = (propsBankAccountDetails: BankAccountDetailsProps): ReactElement => {
        return (
            <div className="bank-account-details">
                <div className={`bank-payment-type ${propsBankAccountDetails.isMoreThanOneBankAccount ? 'd' : 'd-none'}`}>{propsBankAccountDetails.bankAccountDetails.paymentType}</div>
                <div className="bank-name">{propsBankAccountDetails.bankAccountDetails.bankName}</div>
                <span className="bank-account-label">ABA Routing Number: <span className="bank-account-label-value">{propsBankAccountDetails.bankAccountDetails.routingNumber}</span></span>
                <p className="bank-account-label">{propsBankAccountDetails.bankAccountDetails.bankAccountType === BankAccountType.Checking ? "Checking " : "Savings "} Account {!propsBankAccountDetails.isSmallScreenRequest && "Ending in"} <HidenShowBankAccount accountNumber={propsBankAccountDetails.bankAccountDetails.accountNumber} isSmallScreenRequest={propsBankAccountDetails.isSmallScreenRequest} /></p>                
            </div>
        );
    }

    const BankInformation = (propsBankInformation: {
        manageDirectDepositResponse: GetManageDirectDepositInfoResponse,
        callFrom?: DDCallFrom
    }): React.ReactElement => {
        const { manageDirectDepositResponse, callFrom } = propsBankInformation;

        return <>
            {(isLoading || cmsLandingMessage === '') ? callFrom === DDCallFrom.PaymentInfo ? <LoadingSkeleton /> : <LoadingSkeletonFullpage /> :
            <>
                {!isLoading && 
                    manageDirectDepositResponse.responseStatus == null ?
                    <div>
                            <LandingPageMsg callFrom={callFrom} />

                            {manageDirectDepositResponse?.bankAccounts.map((item, i) => (
                                <BankAccountDetails bankAccountDetails={item} isMoreThanOneBankAccount={manageDirectDepositResponse?.bankAccounts.length > 1} key={i} isSmallScreenRequest={isSmallScreen } />
                        ))
                        }
                        {manageDirectDepositResponse?.bankAccounts.length > 1 &&
                            <MultipleBankAccountMsg />
                        }
                        <div className="mt-3 pt-3">
                            {(hasClaim_Update) && <>
                                <Button variant="primary" tabIndex={5} className={`${isSmallScreen ? 'w-100' : ''}`} onClick={() => handleClickUpdate()}>
                                    Change
                                </Button>
                            </>}
                        </div>
                    </div>
                    : !isLoading && 
                        <>
                            <LandingPageMsg callFrom={callFrom} />
                            <NoBankDataMsg manageDirectDepositResponse={manageDirectDepositResponse} callFrom={callFrom } />
                        </>
                }
            </>}
        </>
    }

    return (
        <>
            {props.callFrom === DDCallFrom.PaymentInfo ?
                !isSmallScreen ?
                    <Row>
                        <Col>
                            <h3 className={`pb-0 ret-color sidePanelWizardCardHeader`}><FontAwesomeIcon icon={faMoneyCheckEdit} className="manageDDHeaderIcon" /> <span className="manageDDHeader">Manage Direct Deposits</span></h3>
                            <div style={{ marginBottom: '5rem' }}>
                                {props.loadState === DataLoadState.Error ?
                                    <Alert variant="danger" className="mt-4 col-12">
                                        <FontAwesomeIcon icon={faExclamationCircle} />
                                        <strong>Error.</strong> {props.errorMessage}
                                    </Alert>
                                    :
                                    <BankInformation manageDirectDepositResponse={manageDirectDepositInfo} callFrom={props.callFrom} />
                                }
                            </div>
                        </Col>
                    </Row>
                    : <div>
                        {props.loadState === DataLoadState.Error ?
                            <Alert variant="danger" className="mt-4 col-12 col-sm-12 col-lg-8">
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                {props.errorMessage}
                            </Alert>
                            :
                            <BankInformation manageDirectDepositResponse={manageDirectDepositInfo} callFrom={props.callFrom} />
                        }
                    </div>

                : <>
                    {!isSmallScreen && <Card className="detailCard desktop" style={{ marginTop: '2em', minHeight: "30em" }}>
                        <PageTitle title="Bank Information" color="ret-color-bg" hidePrintButton={true} />
                        <Card.Body className="sidePanelWizardCardBody">
                            <Row className="p-0 m-0">
                                <Col className={`pt-4 px-md-4 px-lg-4 px-xl-5`}>
                                    {showSuccessMsg && <SuccessMsg />}
                                    <h3 className={`pb-0 ret-color sidePanelWizardCardHeader`}>Direct Deposit</h3>
                                    <div style={{ marginBottom: '5rem' }}>
                                        {props.loadState === DataLoadState.Error ?
                                            <Alert variant="danger" className="mt-4 col-12 col-sm-12 col-lg-8">
                                                <FontAwesomeIcon icon={faExclamationCircle} />
                                                <strong>Error.</strong> {props.errorMessage}
                                            </Alert>
                                            :
                                            <BankInformation manageDirectDepositResponse={manageDirectDepositInfo} callFrom={props.callFrom} />
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>}

                    {isSmallScreen && <div className="detailCard mobile showForMd">
                        <Card>
                            <PageTitle title="Bank Information" color="ret-color-bg" hidePrintButton={true} />
                            <Card.Body className="mx-3 py-4">
                                {showSuccessMsg && <SuccessMsg />}
                                <h3 className={`pb-3 ret-color`}>Direct Deposit</h3>

                                <div>
                                    {props.loadState === DataLoadState.Error ?
                                        <Alert variant="danger" className="mt-4 col-12 col-sm-12 col-lg-8">
                                            <FontAwesomeIcon icon={faExclamationCircle} />
                                            {props.errorMessage}
                                        </Alert>
                                        :
                                        <BankInformation manageDirectDepositResponse={manageDirectDepositInfo} />
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </div>}
                </>
            }

        </>
    );
};

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        ...state.manageDirectDeposit,
        cmsData: state.cms.directDeposit,
        cmsUpdateBankInfo: state.cms.updateBankInfo
    }
}
function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchDirectDepositCmsData: cmsActionCreators.fetchDirectDepositCmsData,
        fetchUpdateBankInfoCmsData: cmsActionCreators.fetchUpdateBankInfoCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageDirectDeposit);