import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { GatewayPath } from '@wespath/gateway-navigation'
import { AppDispatch } from '..';
import { AppState } from './configureStore';
import { SetShowHealth, SetShowLifeAndDisability, SetShowWellBeing } from '../Gateway.dtos';
import { client } from '../App';
import { getResetAction } from '../functions/getResetAction';

export interface IDisplayPageState {
    showRetirement: boolean,
    showHealth: boolean,
    hasSetServerHealth: boolean,
    showLifeAndDisability: boolean,
    hasSetServerLifeAndDisability: boolean,
    showWellBeing: boolean
    hasSetServerWellBeing: boolean
    redirectFromSummary?: string
}

export const initialDisplayPageState: IDisplayPageState = {
    showRetirement: false,
    showHealth: false,
    hasSetServerHealth: false,
    showLifeAndDisability: false,
    hasSetServerLifeAndDisability: false,
    showWellBeing: false,
    hasSetServerWellBeing: false,
    redirectFromSummary: undefined
};

const showHealth = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch,
        state: AppState
    }
>(
    'displayPage/showHealth',
    async (_, thunkApi) => {
        if (!thunkApi.getState().displayPage.hasSetServerHealth) {
            client.post(new SetShowHealth());
        }

    }
)

const showLifeAndDisability = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch,
        state: AppState
    }
>(
    'displayPage/showLifeAndDisability',
    async (_, thunkApi) => {
        if (!thunkApi.getState().displayPage.hasSetServerLifeAndDisability) {
            client.post(new SetShowLifeAndDisability());
        }
    }
)

const showWellBeing = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch,
        state: AppState
    }
>(
    'displayPage/showWellBeing',
    async (_, thunkApi) => {
        if (!thunkApi.getState().displayPage.hasSetServerWellBeing) {
            client.post(new SetShowWellBeing());
        }
    }
)

const slice = createSlice({
    name: 'displayPage',
    initialState: initialDisplayPageState,
    reducers: {
        resetState: () => {
            return {
                ...initialDisplayPageState
            }
        },
        showRetirement: (state: IDisplayPageState) => {
            return {
                ...state,
                showRetirement: true
            }
        },
        hideRetirement: (state: IDisplayPageState) => {
            return {
                ...state,
                showRetirement: false
            }
        },
        hideHealth: (state: IDisplayPageState) => {
            return {
                ...state,
                showHealth: false
            }
        },
        hideLifeAndDisability: (state: IDisplayPageState) => {
            return {
                ...state,
                showLifeAndDisability: false
            }
        },
        hideWellBeing: (state: IDisplayPageState) => {
            return {
                ...state,
                showWellBeing: false
            }
        },
        setRedirectFromSummary: (state: IDisplayPageState, action: PayloadAction<{ redirectFromSummary: string | undefined }>) => {
            const { redirectFromSummary } = action.payload;
            return {
                ...state,
                redirectFromSummary
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(showHealth.pending, (state: IDisplayPageState) => {
                state.showHealth = true
            })
            .addCase(showLifeAndDisability.pending, (state: IDisplayPageState) => {
                state.showLifeAndDisability = true
            })
            .addCase(showWellBeing.pending, (state: IDisplayPageState) => {
                state.showWellBeing = true
            })
            .addCase(showHealth.fulfilled, (state: IDisplayPageState) => {
                state.hasSetServerHealth = true
            })
            .addCase(showLifeAndDisability.fulfilled, (state: IDisplayPageState) => {
                state.hasSetServerLifeAndDisability = true
                
            })
            .addCase(showWellBeing.fulfilled, (state: IDisplayPageState) => {
                state.hasSetServerWellBeing = true
            })
            .addCase(getResetAction(), (_state, _action) => initialDisplayPageState)
    }
})

const {
    resetState,
    showRetirement,
    hideRetirement,
    hideHealth,
    hideLifeAndDisability,
    hideWellBeing,
    setRedirectFromSummary
} = slice.actions;

function goToPage(page: GatewayPath | string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { redirectFromSummary } = getState().displayPage;

        if (page === redirectFromSummary) 
            dispatch(setRedirectFromSummary({redirectFromSummary: undefined}))
        
        dispatch(push(page));
    }
}



export const displayPageActionCreators = {
    resetState,
    showRetirement,
    hideRetirement,
    showHealth,
    hideHealth,
    showLifeAndDisability,
    hideLifeAndDisability,
    showWellBeing,
    hideWellBeing,
    goToPage,
    setRedirectFromSummary
};

export const displayPageReducer = slice.reducer;


