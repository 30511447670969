import {
    UpdateSsoSettings,
    UpdateSsoSettingsResponse,
    GetDeveloperSettings,
    GetDeveloperSettingsResponse
} from '../Gateway.dtos'

export enum CacheTags {
    DeveloperSettings = 'DeveloperSettings'
}

export type RequestDtos =
    UpdateSsoSettings |
    GetDeveloperSettings

export type ResponseDtos =
    undefined |
    UpdateSsoSettingsResponse |
    GetDeveloperSettingsResponse