import React, { ReactElement } from "react";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const PanelWithIcon = (props: { children: unknown, icon: IconProp, title?: string, iconColor?: string, subPanel?: boolean }): ReactElement => {
    const { children, icon, title, iconColor, subPanel } = props;

    return (<>
        <Row className={subPanel ? "subPanel" : ""}>
            <FontAwesomeIcon icon={icon} size="2x" className={`panelIcon ${iconColor} ${subPanel && title ? "hasTitle" : ""}`} />

            <div className="panelWithIcon">

                {title && <div className="panelHeader">{title}</div>}

                {children}

            </div>
        </Row>

        <div className="bottomBorder" />
    </>);
}
export { PanelWithIcon }