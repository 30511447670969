import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { stateNeedsLoading } from '../functions/stateNeedsLoading';
import { AppState } from '../store/configureStore';
import { beneReviewActionCreators } from '../store/BeneReviewStore';
import BenefitsAccessSso from './BenefitsAccessSso';
import { AppDispatch } from '..';
import { Beneficiaries } from '../definitions/Claims';

type TBeneReviewProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const BeneficiaryReviewModal = (props: TBeneReviewProps) => {

    const {
        showBeneMsg,
        isLoading,
        participantNumber,
        profileName,
        hasClaim,
        retirementIsLoaded,
        lifeAndDisIsLoaded,
        healthIsLoaded,
        wellBeingIsLoaded
    } = props;   

    const isPpt = participantNumber && !profileName;
    const dataIsLoaded = retirementIsLoaded && lifeAndDisIsLoaded && healthIsLoaded && wellBeingIsLoaded;

    function reviewBene(code: string) {
        props.updateBeneReview(code);
    }

    useEffect(() => {
        if (hasClaim && isPpt && stateNeedsLoading(props)) {
            props.fetchBeneReview();
        }
    });

    return (<>
        {dataIsLoaded && isPpt && showBeneMsg && <Modal dialogClassName="beneModal" show={showBeneMsg} backdrop="static" centered onHide={() => reviewBene("REMIND")} onEscapeKeyDown={() => reviewBene("DONTSHOW")} >
            <Modal.Header closeButton={true} closeLabel="Close (X)" style={{ border: "none" }}>
                <Modal.Title><h2 className="text-blue d-inline">Are your beneficiaries up to date?</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-justify" style={{ border: "none" }}>
                <Row>
                    <Col>
                        Have you had or adopted a child, gotten married or divorced, experienced other life changes? Make sure you
                        know who will receive your benefits when you pass away, and what contact information Wespath has on file.
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Button className="btnLinkAsText" variant="link" onClick={() => reviewBene("DONTSHOW")}>
                            Do not show me this until next year
                        </Button>. You can manage your beneficiaries at any time.
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
                <div className="w-100">
                    <Row>
                        <Col className="col-12 col-sm-12 col">
                            <BenefitsAccessSso to={`/beneficiaries.aspx`}>
                                <Button className="beneModalBtn my-1" variant="primary" onClick={() => reviewBene("REVIEW")} disabled={isLoading}>
                                    Review Beneficiaries
                                </Button>
                            </BenefitsAccessSso>
                            <Button className="beneModalBtn my-1 ml-md-3" variant="secondary" onClick={() => reviewBene("NOUPDATE")} disabled={isLoading}>
                                No Update Needed
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            Questions? Contact Wespath at 1-800-851-2201 business days from 8:00 a.m. to 6:00 p.m., Central time.
                        </Col>
                    </Row>
                </div>
            </Modal.Footer>
        </Modal>
        }
    </>);
}

function mapStateToProps(state: AppState) {
    const retirementIsLoaded = state.displayPage.showRetirement || (state.balance.isLoaded && state.investments.isLoaded && state.benefitProjection.isLoaded && state.monthlyBenefit.isLoaded);
    const lifeAndDisIsLoaded = state.displayPage.showLifeAndDisability || (state.cpp.isLoaded && state.umLifeOptions.isLoaded);
    const healthIsLoaded = state.displayPage.showHealth || (state.hsa.isLoaded && state.healthPlans.isLoaded);
    const wellBeingIsLoaded = state.displayPage.showWellBeing || state.wellBeing.isLoaded; 

    return {
        ...state.beneReview,
        profileName: state.account.profileName,
        participantNumber: state.account.participantNumber,
        hasClaim: state.claims.claims?.claims?.find(c => c === Beneficiaries.View),
        retirementIsLoaded,
        lifeAndDisIsLoaded,
        healthIsLoaded,
        wellBeingIsLoaded
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...beneReviewActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BeneficiaryReviewModal);
