import React, { ReactElement } from 'react';
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const ProviderLabel = (props: { label: string; value: string; value2?: string, isSummary?: boolean }): ReactElement => {

    const { label, value, value2, isSummary } = props;

    if (isSummary) {        

        return (
            <Row className="mb-0">
                <Col className="cvgHeader mr-0 pr-0 col-12">
                    {label}
                    <FontAwesomeIcon className="ml-1 text-success" icon={faCheck} />
                </Col>

                <Col className="cvgValue mr-0 pr-0 col-12">
                    {value}
                </Col>

                {value2 && <Col className="cvgLabel mr-0 pr-0 col-12">
                    {value2}
                </Col>}
            </Row>
        );

    }
    else {

        return (
            <Col className="providerLabel col-12">
                <div>{label}</div>
                <h6>{value}</h6>
            </Col>
        );

    }
}
export { ProviderLabel };