import { AppState } from "./configureStore";
import { HeaderMenu, GetBenefitsAccessMenus } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

interface Error {
    errorMessage: string | unknown
}

export interface IRetirementMenuState extends IEnumLoadableState {
    headerMenu: HeaderMenu
}

export const initialRetirementMenu: IRetirementMenuState = {
    headerMenu: {} as HeaderMenu,
    loadState: DataLoadState.None
};

const fetchRetirementMenu = createAsyncThunk<
    HeaderMenu,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'retirementMenu/fetchRetirementMenu',
    async (_, thunkApi) => {
        try {

            const retirementMenus = await client.post(new GetBenefitsAccessMenus({ isGateway: true }));

            return retirementMenus.headerMenu;

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const slice = createSlice({
    name: 'retirementMenu',
    initialState: initialRetirementMenu,
    reducers: {
        resetState: () => initialRetirementMenu
    },
    extraReducers: builder => {
        builder
            .addCase(fetchRetirementMenu.pending, (state: IRetirementMenuState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchRetirementMenu.fulfilled, (state: IRetirementMenuState, { payload }) => {
                state.loadState = DataLoadState.Loaded
                state.headerMenu = payload;
            })
            .addCase(fetchRetirementMenu.rejected, (state: IRetirementMenuState, action) => {
                state.loadState = DataLoadState.Error;
            })
            .addCase(getResetAction(), (_state, _action) => initialRetirementMenu)
    }
})

const { resetState } = slice.actions;

export const retirementMenuActionCreators = {
    fetchRetirementMenu,
    resetState
};

export const retirementMenuReducer = slice.reducer;
