import React, { useEffect } from 'react';
import Helmet from "react-helmet";
import { connect } from 'react-redux';
import { AppState } from '../store/configureStore';
import { bindActionCreators } from 'redux';
import { AppDispatch } from '../';
import { googleAnalyticsIdActionCreators } from '../store/GoogleAnalyticsIdStore';
import { stateNeedsLoading } from '../functions/stateNeedsLoading';
import { titleCase } from '../functions/titleCase';


type TGoogleAnalyticsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const GoogleAnalyticsScripts = (props: TGoogleAnalyticsProps) => {
    const { analyticsId, page } = props;


    useEffect(() => {
        if (stateNeedsLoading(props))
            props.fetchGoogleAnalyticsId();
    });

    if (!analyticsId)
        return null;

    return (
        <Helmet>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${analyticsId}', {
                    'page_title' : 'Benefits Access - ${titleCase(page.replace("/", ""))}', 
                    'page_path': '${page}'
                });`}
            </script>
        </Helmet>
    );
    
}

function mapStateToProps(state: AppState) {
    return { ...state.googleAnalyticsId, page: state.router.location.pathname }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...googleAnalyticsIdActionCreators }, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleAnalyticsScripts);