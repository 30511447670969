/*
    Validate ABA Routing number
    
    Taken from http://www.brainjar.com/js/validation/
*/
const checkABA = function (s: string): boolean {

    let i, n, t;

    // First, remove any non-numeric characters.
    t = "";

    for (i = 0; i < s.length; i++) {
        const c = parseInt(s.charAt(i), 10);
        if (c >= 0 && c <= 9)
            t = t + c;
    }

    // Check the length, it should be nine digits.

    if (t.length !== 9)
        return false;

    //ABA numbers that begin with 5 are internal to a financial institution and should be rejected

    if (t.indexOf('5') === 0)
        return false;

    // Now run through each digit and calculate the total.

    n = 0;

    for (i = 0; i < t.length; i += 3) {
        n += parseInt(t.charAt(i), 10) * 3 + parseInt(t.charAt(i + 1), 10) * 7 + parseInt(t.charAt(i + 2), 10);
    }

    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.

    if (n !== 0 && n % 10 === 0)
        return true;
    else
        return false;
}

export { checkABA }