import { Dispatch } from "react";
import { AppState } from "../configureStore";
import { createSlice, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../../definitions/ILoadableState";
import { UpdateElectronicDelivery } from "../../Gateway.dtos";
import { client } from "../../App";
import { getResetAction } from "../../functions/getResetAction";

interface IState extends ILoadableState {
    isElecDeliv: string,
    showSuccessMsg: boolean
}

const initialState: IState = {
    isElecDeliv: "",
    showSuccessMsg: false,
    isLoading: false,
    isLoaded: false,
    error: false
};

const slice = createSlice({
    name: 'DeliveryPreferences',
    initialState: {} as IState,
    reducers: {
        resetState: () => {
            return {
                ...initialState
            }
        },
        setState: (state: IState, action: PayloadAction<{ isElecDeliv: string }>) => {
            const isElecDeliv = action.payload.isElecDeliv;
            return {
                ...state,
                isElecDeliv
            }
        },
        requestUpdate: (state: IState) => {
            return {
                ...state,
                isLoading: true,
                error: false,
                showSuccessMsg: false
            }
        },
        receiveUpdate: (state: IState, action: PayloadAction<{ isElecDeliv: string }>) => {
            const isElecDeliv = action.payload.isElecDeliv;
            return {
                ...state,
                isElecDeliv,
                isLoading: false,
                error: false,
                showSuccessMsg: true
            }
        },
        receiveUpdateError: (state: IState) => {
            return {
                ...state,
                isLoading: false,
                error: true
            }
        },
        hideSuccessMsg: (state: IState) => {
            return {
                ...state,
                showSuccessMsg: false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialState)
    }
})

const {
    resetState,
    setState,
    requestUpdate,
    receiveUpdate,
    receiveUpdateError,
    hideSuccessMsg
} = slice.actions;

function updateElecDeliv(isElectronicDelivery: boolean) {
    return async (dispatch: Dispatch<AnyAction>, getState: () => AppState): Promise<void> => {
        const isUpdating = getState().deliveryPreferences.isLoading;
        const { isLoading, isLoaded } = getState().personalInfo;
        if (isUpdating || isLoading || !isLoaded) {
            return;
        }

        dispatch(requestUpdate());

        try {
            const updateElecDelivResponse = await client.post(new UpdateElectronicDelivery({ isElectronicDelivery }));


            if (updateElecDelivResponse.responseStatus)
                throw updateElecDelivResponse.responseStatus.message;

            dispatch(receiveUpdate({ isElecDeliv: isElectronicDelivery ? "y" : "n" }));


        }
        catch (e: unknown) {
            dispatch(receiveUpdateError());
            console.log(e);
        }

    }
}

export const deliveryPreferencesActionCreators = {
    resetState,
    setState,
    updateElecDeliv,
    hideSuccessMsg
};

export const deliveryPreferencesReducer = slice.reducer;
