import {
    UpdateSsoSettings,
    UpdateSsoSettingsResponse,
    GetDeveloperSettings,
    GetDeveloperSettingsResponse,
} from '../Gateway.dtos'
import { apiBase } from './apiBase'
import { CacheTags } from './apiTypes'

const extendedApi = apiBase.injectEndpoints({
    endpoints: builder => ({
        fetchDeveloperSettings: builder.query<GetDeveloperSettingsResponse, void>({
            query: () => new GetDeveloperSettings(),
            providesTags: [CacheTags.DeveloperSettings]
        }),
        updateSsoSetttings: builder.mutation<UpdateSsoSettingsResponse, UpdateSsoSettings>({
            query: (body) => body,
            invalidatesTags: [CacheTags.DeveloperSettings]
        }),
    }),
    overrideExisting: false,
})

export const { useFetchDeveloperSettingsQuery, useUpdateSsoSetttingsMutation } = extendedApi