import { AppState } from "./configureStore";
import { GetBenefitProjection, GetBenefitProjectionResponse } from "./../Gateway.dtos";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IBenefitProjectionState extends ILoadableState {
    benefitProjection: GetBenefitProjectionResponse
}

export const initialBenefitProjectionState: IBenefitProjectionState = {
    benefitProjection: {} as GetBenefitProjectionResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'benefitProjection',
    initialState: {} as IBenefitProjectionState,
    reducers: {
        resetState: () => {
            return {
                ...initialBenefitProjectionState
            }
        },
        requestBenefitProjection: (state: IBenefitProjectionState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveBenefitProjection: (state: IBenefitProjectionState, action: PayloadAction<{ benefitProjection: GetBenefitProjectionResponse }>) => {
            const { benefitProjection } = action.payload;
            return {
                ...state,
                benefitProjection,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveBenefitProjectionError: (state: IBenefitProjectionState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialBenefitProjectionState)
    }
})

const { resetState, requestBenefitProjection, receiveBenefitProjection, receiveBenefitProjectionError } = slice.actions;

function fetchBenefitProjection() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().benefitProjection;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestBenefitProjection());

        try {

            const benefitProjection = await client.post(new GetBenefitProjection());

            dispatch(receiveBenefitProjection({ benefitProjection }));

            if (benefitProjection.goalAmt > 0)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receiveBenefitProjectionError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }

    }
}

export const benefitProjectionCreators = {
    fetchBenefitProjection,
    resetState
};

export const benefitProjectionReducer = slice.reducer;
