import { AppState } from "./configureStore";
import { GetApplicationTimeoutResponse, GetApplicationTimeout } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

interface Error {
    errorMessage: string | unknown
}

export interface IApplicationTimeoutState extends IEnumLoadableState {
    logoffMinutes: number
    logoffWarningMinutes: number
}

export const initialApplicationTimeout: IApplicationTimeoutState = {
    logoffMinutes: 0,
    logoffWarningMinutes: 0,
    loadState: DataLoadState.None
};

const fetchApplicationTimeout = createAsyncThunk<
    GetApplicationTimeoutResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'applicationTimeout/fetch',
    async (_, thunkApi) => {
        try {
            const timeout = await client.post(new GetApplicationTimeout());
            return timeout;

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const slice = createSlice({
    name: 'applicationTimeout',
    initialState: initialApplicationTimeout,
    reducers: {
        resetState: () => initialApplicationTimeout
    },
    extraReducers: builder => {
        builder
            .addCase(fetchApplicationTimeout.pending, (state: IApplicationTimeoutState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchApplicationTimeout.fulfilled, (state: IApplicationTimeoutState, { payload }) => {
                state.loadState = DataLoadState.Loaded
                state.logoffMinutes = payload.logoffMinutes;
                state.logoffWarningMinutes = payload.logoffWarningMinutes;
            })
            .addCase(fetchApplicationTimeout.rejected, (state: IApplicationTimeoutState) => {
                state.loadState = DataLoadState.Error;
            })
            .addCase(getResetAction(), (_state, _action) => initialApplicationTimeout)
    }
})

const { resetState } = slice.actions;

export const applicationTimeoutActionCreators = {
    fetchApplicationTimeout,
    resetState
};

export const applicationTimeoutReducer = slice.reducer;
