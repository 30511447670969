import { AppState } from "./configureStore";
import { RequestType, GetPersonalInfoValidationResponse, GetPersonalInfoValidation, CreateNewUser, ChangePassword, PasswordOrigType, Authenticate, ChallengeType, Logoff } from "../Gateway.dtos";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GatewayPath } from '@wespath/gateway-navigation'
import { push } from "connected-react-router";
import { accountActionCreators } from "./AccountStore";
import { lnMultiFactorActionCreators } from "./LNMultiFactorStore";
import { authMultiFactorActionCreators } from "./AuthMultiFactorStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export enum LoginHelpPasswordState {
    None,
    Updating,
    Updated,
    Errror
}

export interface ILoginHelpState extends ILoadableState {
    requestType: RequestType,
    initialRequestType: RequestType
    personalInformationResponse: GetPersonalInfoValidationResponse
    lastName: string
    birthday: string
    suffix: string
    email: string
    stepNum: number
    isSuccess: boolean,
    passwordState: LoginHelpPasswordState
}

export const initialLoginHelpState: ILoginHelpState = {
    requestType: {} as RequestType,
    initialRequestType: {} as RequestType,
    personalInformationResponse: {} as GetPersonalInfoValidationResponse,
    lastName: "",
    birthday: "",
    suffix: "",
    email: "",
    stepNum: 1,
    isSuccess: false,
    isLoading: false,
    isLoaded: false,
    passwordState: LoginHelpPasswordState.None
};

const slice = createSlice({
    name: 'loginHelp',
    initialState: {} as ILoginHelpState,
    reducers: {
        resetState: () => {
            return {
                ...initialLoginHelpState
            }
        },
        resetStateKeepReqType: (state: ILoginHelpState) => {
            return {
                ...initialLoginHelpState,
                requestType: state.requestType,
                isSuccess: state.isSuccess
            }
        },
        receiveLoginHelp: (state: ILoginHelpState, action: PayloadAction<{ requestType: RequestType }>) => {
            const { requestType } = action.payload;
            return {
                ...state,
                requestType,
                initialRequestType: requestType,
                stepNum: 1,
                isLoaded: true
            }
        },
        nextStep: (state: ILoginHelpState) => {
            return {
                ...state,
                stepNum: state.stepNum + 1
            }
        },
        requestPersonalInformation: (state: ILoginHelpState, action: PayloadAction<{ lastName: string, birthday: string, suffix: string }>) => {
            const { lastName, birthday, suffix } = action.payload;
            return {
                ...state,
                personalInformationResponse: {} as GetPersonalInfoValidationResponse,
                lastName,
                birthday,
                suffix,
                isLoading: true,
                isLoaded: false,
                error: false
            }
        },
        receivePersonalInformation: (state: ILoginHelpState, action: PayloadAction<{ personalInformationResponse: GetPersonalInfoValidationResponse }>) => {
            const { personalInformationResponse } = action.payload;
            return {
                ...state,
                personalInformationResponse,
                isLoading: false,
                isLoaded: true,
                error: personalInformationResponse?.responseStatus !== undefined
            }
        },
        receivePersonalInformationError: (state: ILoginHelpState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        },
        changeToNeedHelp: (state: ILoginHelpState) => {
            return {
                ...state,
                personalInformationResponse: {} as GetPersonalInfoValidationResponse,
                isLoading: false,
                isLoaded: false,
                error: false
            }
        },
        changeToRegistration: (state: ILoginHelpState) => {
            return {
                ...state,
                requestType: RequestType.REGISTRATION,
                stepNum: 2
            }
        },
        setEmail: (state: ILoginHelpState, action: PayloadAction<{ email: string }>) => {
            const { email } = action.payload;
            return {
                ...state,
                email
            }
        },
        requestRegistration: (state: ILoginHelpState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: false
            }
        },
        receiveRegistration: (state: ILoginHelpState, action: PayloadAction<{ registerdSuccessfully: boolean }>) => {
            const { registerdSuccessfully } = action.payload;
            return {
                ...state,
                isSuccess: registerdSuccessfully,
                isLoading: false,
                isLoaded: true,
                error: !registerdSuccessfully
            }
        },
        requestPasswordUpdate: (state: ILoginHelpState) => {
            return {
                ...state,
                passwordState: LoginHelpPasswordState.Updating
            }
        },
        receivePasswordUpdate: (state: ILoginHelpState) => {
            return {
                ...state,
                isSuccess: true,
                passwordState: LoginHelpPasswordState.Updated
            }
        },
        receivePasswordUpdateError: (state: ILoginHelpState) => {
            return {
                ...state,
                passwordState: LoginHelpPasswordState.Errror
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (state, action) => {
                const keepRequestType = action?.payload?.keepLoginHelpRequestType
                if (keepRequestType) {
                    return {
                        ...initialLoginHelpState,
                        requestType: state.requestType
                    }
                } else {
                    return initialLoginHelpState
                }
            })
    }
})

const {
    resetState,
    resetStateKeepReqType,
    receiveLoginHelp,
    nextStep,
    requestPersonalInformation,
    receivePersonalInformation,
    receivePersonalInformationError,
    changeToNeedHelp,
    changeToRegistration,
    setEmail,
    requestRegistration,
    receiveRegistration,
    requestPasswordUpdate,
    receivePasswordUpdate,
    receivePasswordUpdateError
} = slice.actions;

export const loginHelpReducer = slice.reducer;


function fetchLoginHelp(requestType: RequestType) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const loginHelpState = getState().loginHelp;
        if (loginHelpState.isLoading) {
            return;
        }

        //Reset any related stores
        dispatch(resetState);
        dispatch(authMultiFactorActionCreators.resetState());
        dispatch(lnMultiFactorActionCreators.resetState());
        dispatch(accountActionCreators.resetState());

        //Set the request type
        dispatch(receiveLoginHelp({ requestType }));
        if (requestType === RequestType.NEEDHELP)
            dispatch(authMultiFactorActionCreators.setChallengeType(ChallengeType.LoginHelp));
    }
}

function validatePersonalInformation(lastName: string, ssn: string, birthday: string, suffix: string, threatMetrixSessionId?: string) {

    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading, requestType } = getState().loginHelp;
        if (isLoading) {
            return;
        }

        lastName = lastName.trim();

        try {

            

            dispatch(requestPersonalInformation({ lastName, birthday, suffix }));

            await client.post(new Logoff());

            const personalInformationResponse = await client.post(
                new GetPersonalInfoValidation({ requestType, lastName, ssn, birthday, threatMetrixSessionId })
            );

            

            dispatch(receivePersonalInformation({ personalInformationResponse }));

            //Save the data to state
            const { username, participantNumber, firstName, name, contextToken, isRegistered } = personalInformationResponse

            if (personalInformationResponse.responseStatus !== undefined)
                throw personalInformationResponse.responseStatus.message;

            sessionStorage.setItem("PG.ContextToken", contextToken);

            dispatch(accountActionCreators.receiveAccountInfo({
                name,
                firstName,
                lastName,
                participantNumber,
                isAuthenticated: (requestType === RequestType.NEEDHELP && isRegistered)
            }));

            dispatch(accountActionCreators.setUsername({ username }));

            if (requestType === RequestType.NEEDHELP && !isRegistered) {
                dispatch(changeToRegistration())
            } else {
                if (requestType === RequestType.NEEDHELP)
                    dispatch(authMultiFactorActionCreators.setIsChallenging());

                dispatch(loginHelpCreators.nextStep());
            }

        } catch (e: unknown) {

            dispatch(receivePersonalInformationError());
            console.log("Error returned for personalInformation response: ", e);
        }

    }
}

function finishLoginHelp() {
    return async (dispatch: AppDispatch): Promise<void> => { 
        //dispatch(resetState());
        dispatch(accountActionCreators.logout(true));
        dispatch(push(GatewayPath.Login));
    }
}

function register(username: string, password: string) {

    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading, email } = getState().loginHelp;
        if (isLoading) {
            return;
        }

        try {

            dispatch(requestRegistration());

            const createNewUserResponse = await client.post(
                new CreateNewUser({ username, password, email })
            );

            if (createNewUserResponse.responseStatus !== undefined)
                throw createNewUserResponse.responseStatus.message;

            dispatch(receiveRegistration({ registerdSuccessfully: true }));

            await client.post(new Logoff({preserveCache: true})); //Remove session cookie that was created in validatePersonalInformation

            const auth = await client.post(new Authenticate({
                 provider: "credentials",
                 userName: username,
                 password: password,
                 meta: {
                    "registrationSessionId": createNewUserResponse.sessionId
                 }

            }));

            const contextToken = auth.meta["ContextToken"];

            sessionStorage.setItem("PG.ContextToken", contextToken);

            dispatch(accountActionCreators.setAuthenticated({ isAuthenticated: true }));
            dispatch(accountActionCreators.setUsername({ username }));
            dispatch(push(GatewayPath.Success));
            dispatch(resetStateKeepReqType());

        } catch (e: unknown) {

            dispatch(receiveRegistration({ registerdSuccessfully: false }));
            console.log("Error returned for register response: ", e);
        }

    }
}

function loginHelpUpdatePassword(newPassword: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { passwordState } = getState().loginHelp
        if (passwordState === LoginHelpPasswordState.Updating) {
            return;
        }

        dispatch(requestPasswordUpdate());

        try {
            const { username } = getState().account;

            const changePasswordResponse = await client.post(new ChangePassword({
                newPassword,
                username,
                passwordOrigType: PasswordOrigType.NEEDHELP
            }));

            if (changePasswordResponse.responseStatus)
                throw changePasswordResponse.responseStatus.message;

            dispatch(receivePasswordUpdate());

            dispatch(push(GatewayPath.Success));
            dispatch(resetStateKeepReqType());
            
        }
        catch (e: unknown) {
            dispatch(receivePasswordUpdateError());
            console.log(e);
        }

    }
}

function goToNeedHelp() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().loginHelp;
        if (isLoading) {
            return;
        }
        dispatch(changeToNeedHelp());
        dispatch(push(GatewayPath.NeedHelp));

    }
}

function verifyEmail(email: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().loginHelp;
        if (isLoading) {
            return;
        }
        dispatch(setEmail({ email }));
        dispatch(nextStep());
    }
}

function goToAccountMgmt() {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(authMultiFactorActionCreators.resetState());
        dispatch(push(GatewayPath.AccountManagement));
    }
}


function goToSummary() {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(authMultiFactorActionCreators.resetState());
        dispatch(push(GatewayPath.BenefitsSummary));
    }
}

function backToLogin() {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(push(GatewayPath.Login));
    }
}

export const loginHelpCreators = {
    resetState,
    resetStateKeepReqType,
    fetchLoginHelp,
    nextStep,
    verifyEmail,
    validatePersonalInformation,
    goToNeedHelp,
    goToAccountMgmt,
    goToSummary,
    backToLogin,
    register,
    finishLoginHelp,
    loginHelpUpdatePassword
};