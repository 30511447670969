import React, { ReactElement } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { PageTitle } from './PageTitle';
import Skeleton from 'react-loading-skeleton';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useWindowDimensions } from '@wespath/gateway-navigation';

const LoadingSkeletonFullpage = (props: { isCard?: boolean, isBaCmsPage?: boolean }): ReactElement => (<>

    {props.isCard && <IsCard />}

    {props.isBaCmsPage && <IsBaCmsPage />}

    {!props.isCard && !props.isBaCmsPage && <DefaultFullPageLoad />}

</>);
export { LoadingSkeletonFullpage };




const IsBaCmsPage = (): ReactElement => {
    const { isSmallScreen } = useWindowDimensions();
    return (
        <Card className="detailCard">
            <Card.Body style={{width: `${isSmallScreen ? '100%' : '80%'}`}}>
                <DefaultFullPageLoad />
            </Card.Body>
        </Card>
    )
}


const DefaultFullPageLoad = (): ReactElement => (
    <div style={{minHeight: '500px'}}>            
        <h1 className="pt-4 pb-3"><Skeleton width={'65%'} /></h1> 
        <LoadingSkeleton />
    </div>
);

const IsCard = (): ReactElement => (
    <Card className="detailCard mt-5">
        <PageTitle title=" " hidePrintButton />
        <Card.Body>
            <Row>
                <Col className="col-12 col-lg-6">
                    <GenericLoadingSkeleton />
                    <GenericLoadingSkeleton />
                </Col>
            </Row>

        </Card.Body>
    </Card>
);

const GenericLoadingSkeleton = (): ReactElement => (
    <Card className="pageCard py-5">
        <div><Skeleton width={'50%'} height={'2em'} /></div>
        <div><Skeleton width={'50%'} /></div>

        <div className="mt-3"><Skeleton height={'2.5em'} /></div>

        <div className="mt-3"><Skeleton height={'1.5em'} /></div>
    </Card>
);

