import React from 'react';
import { Col, Row } from 'react-bootstrap';

export interface ReviewPanelProps {
    heading: string,
    headerLink?: JSX.Element,
    value: JSX.Element | string,
    text: JSX.Element | string,
}

const ReviewPanelRecord = (props: { reviewPanelProps: ReviewPanelProps | null }): React.ReactElement | null => {
    const { reviewPanelProps } = props

    if (!reviewPanelProps || reviewPanelProps === null)
        return null

    return (
        <Row className="reviewPanelItem h-100">
            <Col className="px-0">

                <>{<h4 style={reviewPanelProps.headerLink ? { float: "left" } : {}}>{reviewPanelProps.heading}</h4>} {reviewPanelProps.headerLink && <span>{reviewPanelProps.headerLink} </span>}</>

                <h5>{reviewPanelProps.value}</h5>

                {typeof reviewPanelProps.text === 'string'
                    ? <p>{reviewPanelProps.text}</p>
                    : <>{reviewPanelProps.text}</>
                }

            </Col>
        </Row>
    )
}

const ReviewPanel = (props: { children?: React.ReactElement | React.ReactElement[] }): React.ReactElement => {

    return (
        <div className="reviewPanel mx-2">
            {props.children}
        </div>
    );
}

export { ReviewPanel, ReviewPanelRecord };

