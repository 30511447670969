import parse from "date-fns/parse";
import format from "date-fns/format";

const formatDate = function (date: string) : string {
    const parsed = parse(date, "yyyy-MM-dd", new Date())
    return format(parsed, "MM/dd/yyyy");
};

const formatPaymentDate = function (date: string | undefined): string {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    if (date !== undefined) {
        const parsed = parse(date, "MM/dd/yyyy", new Date())
        const year = parsed.getFullYear();
        const monthName = monthNames[parsed.getMonth()];

        return monthName + ", " + year.toString();
    }
    else
        return "Invalid Date.";
};

export { formatDate, formatPaymentDate };
