import React, { ReactElement, useState } from 'react';
import { Button } from "react-bootstrap";

const truncateAccountNumber = (accountNumber: string, isSmallScreenRequest?: boolean) =>
    accountNumber.length > 4 ? `${isSmallScreenRequest ? "......" : "" }${accountNumber.substring(accountNumber.length - 4, accountNumber.length)}` : accountNumber;

interface HidenShowBankAccountProps { accountNumber: string, isSmallScreenRequest?: boolean }
const HidenShowBankAccount = (props: HidenShowBankAccountProps): ReactElement => {
    // create a toggle state
    const [showTruncate, setShowTruncate] = useState(true);

    // when the toggle is active, show the truncated text
    // if not, show the text in full. 
    return (
        <>
            <span className="bank-account-label-value">{showTruncate ? truncateAccountNumber(props.accountNumber, props.isSmallScreenRequest) : props.accountNumber} </span>
            {props.isSmallScreenRequest ?
                <Button className="smBtnShow col-2" onClick={() => setShowTruncate(!showTruncate)}>Show</Button>
                : <Button variant="link" className="tooltip-button-wrapper ml-2" onClick={() => setShowTruncate(!showTruncate)}>
                    <span style={{ textDecoration: 'underline' }}>{showTruncate ? 'View' : 'Hide'} account number</span>
                </Button>
            }
        </>
    );
}
export { HidenShowBankAccount };