import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { QuestionInfo } from '../../../Gateway.dtos';
import Skeleton from 'react-loading-skeleton';
import { lnMultiFactorActionCreators } from '../../../store/LNMultiFactorStore';
import { activityActionCreators } from '../../../store/ActivityStore';
import ScrollToTop from '../../ScrollToTop';
import { AppDispatch } from '../../..';

type TMultiFactorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LNMultiFactorQuestions = (props: TMultiFactorProps) => {

    const { multiFactor, fetchQuestions, isLoading } = props;

    const questionsLoaded = multiFactor?.transactionResponse?.questionsets?.questions?.length > 0;
    const hasPassed = multiFactor?.transactionResponse?.transactionStatus === "passed";

    const [answerId0, setAnswerId0] = useState(0);
    const [answerId1, setAnswerId1] = useState(0);
    const [answerId2, setAnswerId2] = useState(0);

    const [question0, setQuestion0] = useState(new QuestionInfo());
    const [question1, setQuestion1] = useState(new QuestionInfo());
    const [question2, setQuestion2] = useState(new QuestionInfo());

    const { logActivity } = props;
    useEffect(() => {
        logActivity("MultiFactorQuestions");
    }, [logActivity])

    useEffect(() => {
        if (!isLoading && !questionsLoaded && !hasPassed) {
            fetchQuestions();
        }
    }, [multiFactor, fetchQuestions, isLoading, questionsLoaded, hasPassed]);

    useEffect(() => {
        const questions = multiFactor.transactionResponse?.questionsets?.questions;

        if (questions) {
            if (question0?.questionText !== questions[0]?.questionText) {
                setQuestion0(questions[0]);
                setAnswerId0(0);
            }
            if (question1?.questionText !== questions[1]?.questionText) {
                setQuestion1(questions[1]);
                setAnswerId1(0);
            }
            if (question2?.questionText !== questions[2]?.questionText) {
                setQuestion2(questions[2]);
                setAnswerId2(0);
            }
        }
    }, [multiFactor, question0, question1, question2]);


    //Scroll to top if questions have changed
    useEffect(() => {  
        window.scrollTo(0, 0);
    }, [question0, question1, question2]);


    function answerIsClicked(eventName: string, eventValue: string) {

        const val = parseInt(eventValue);

        switch (eventName) {
            case "answer0":
                setAnswerId0(val)
                break;
            case "answer1":
                setAnswerId1(val)
                break;
            case "answer2":
                setAnswerId2(val)
                break;
            default:
                return;
        }

    }

    const enableNextBtn = () => {
        return (answerId0 !== 0 && answerId1 !== 0 && (question2?.questionText ? answerId2 !== 0 : true));
    }

    const submit = () => {
        props.validateAnswers(answerId0, answerId1, answerId2);
    }
    
    function QuestionsAndAnswers(props: { question: QuestionInfo, checkedAnswer: number, i: number }) {
        const { question, checkedAnswer, i } = props;
        return (
            <Row>
                <Col className="col-12 my-3">
                    <p className="capitalize">
                        {question.questionText}
                    </p>

                    {question.answers?.map((answer, j) => (
                        <div className="form-check" key={j}>
                            <label>
                                <input
                                    type="radio"
                                    name={'answer' + i}
                                    className="form-check-input"
                                    value={answer.choiceId}
                                    checked={answer.choiceId === checkedAnswer}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { answerIsClicked(e.target.name, e.target.value) }}
                                    /> {answer.text.statement}
                            </label>
                        </div>
                    ))}
                </Col>
            </Row>
        );
    }

    return (<>

        <ScrollToTop />

        <p>
            We are unable to send a one-time code to your phone at this time. Instead answering
            the questions below will help us verify your identity.
        </p>

        {!questionsLoaded && <LoadingSkeleton />}

        {questionsLoaded && <>

            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                submit();
            }}>

                {question0?.questionText &&
                    <QuestionsAndAnswers question={question0} checkedAnswer={answerId0} i={0} />
                }

                {question1?.questionText &&
                    <QuestionsAndAnswers question={question1} checkedAnswer={answerId1} i={1} />
                }

                {question2?.questionText &&
                    <QuestionsAndAnswers question={question2} checkedAnswer={answerId2} i={2} />
                }

                <div className="form-group">
                    <Button className="mt-4" variant="primary" type="submit" disabled={!enableNextBtn() || props.isLoading}>
                        {props.isLoading ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : "Next"}
                    </Button>
                </div>

            </Form>

        </>}
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        ...state.lnMultiFactor
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...lnMultiFactorActionCreators,
        ...activityActionCreators
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LNMultiFactorQuestions);


const LoadingSkeleton = () => (
    <div className="mb-5">
        <QuestionSkeleton />

        <QuestionSkeleton />

        <QuestionSkeleton />
    </div>
);

const QuestionSkeleton = () => (
    <div className="my-5 ml-4">
        <div className="my-1"><Skeleton width={'75%'} /></div>
        <div className="my-1"><Skeleton width={'25%'} /></div>
        <div className="my-1"><Skeleton width={'25%'} /></div>
        <div className="my-1"><Skeleton width={'25%'} /></div>
        <div className="my-1"><Skeleton width={'25%'} /></div>
        <div className="my-1"><Skeleton width={'25%'} /></div>
    </div>
);