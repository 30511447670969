import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetBeneReview, UpdateBeneReview } from "../Gateway.dtos";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IBeneReviewState extends ILoadableState {
    showBeneMsg: boolean
}

export const initialBeneReviewState: IBeneReviewState = {
    showBeneMsg: false,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'beneReview',
    initialState: {} as IBeneReviewState,
    reducers: {
        resetState: () => {
            return {
                ...initialBeneReviewState
            }
        },
        requestBeneReview: (state: IBeneReviewState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveBeneReview: (state: IBeneReviewState, action: PayloadAction<{ showBeneMsg: boolean }>) => {
            const { showBeneMsg } = action.payload;
            return {
                ...state,
                showBeneMsg,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveBeneReviewError: (state: IBeneReviewState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialBeneReviewState)
    }
})

const {
    resetState,
    requestBeneReview,
    receiveBeneReview,
    receiveBeneReviewError
} = slice.actions;

function fetchBeneReview() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().beneReview;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestBeneReview());

        try {

            const beneReview = await client.post(new GetBeneReview());

            dispatch(receiveBeneReview({ showBeneMsg: beneReview.showBeneMsg }));

        } catch (e: unknown) {
            dispatch(receiveBeneReviewError());
            console.log(e);
        }
    }
}

function updateBeneReview(code: string) {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().beneReview;
        if (isLoading) {
            return;
        }

        dispatch(requestBeneReview());

        try {

            //Close the modal immediately by setting reviewResponse to empty
            dispatch(receiveBeneReview({ showBeneMsg: false }));
            
            //Update the users response
            const beneReviewUpdate = await client.post(new UpdateBeneReview({ code }));

            //Check result
            if (beneReviewUpdate.responseStatus)
                throw beneReviewUpdate.responseStatus.message;
            else
                dispatch(receiveBeneReview({ showBeneMsg: false}));

        } catch (e: unknown) {
            dispatch(receiveBeneReviewError());
            console.log(e);
        }
    }
}

export const beneReviewActionCreators = {
    fetchBeneReview,
    updateBeneReview,
    resetState
};

export const beneReviewReducer = slice.reducer;
