import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBan } from '@fortawesome/pro-solid-svg-icons';

const Requirement = (props: { isOk: boolean, children?: unknown }): ReactElement => {

    return (
        <div className={props.isOk ? "pass" : "fail"}>
            <FontAwesomeIcon icon={props.isOk ? faCheck : faBan} className="pr-2" size="lg" />{props.children}
        </div>
    );
}

export { Requirement }