import React, { useEffect } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { healthPlansActionCreators } from '../../../store/HealthPlanStore';
import { connect, useDispatch } from 'react-redux';
import { PlanType } from '../../../Gateway.dtos';
import { ProviderLabel } from '../../ProviderLabel';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppDispatch } from '../../..';
import { cmsActionCreators } from '../../../store/CmsStore';
import { activityActionCreators } from '../../../store/ActivityStore';

type TVisionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Vision = (props: TVisionProps) => {
    const { healthPlans } = props;
    const dispatch: AppDispatch = useDispatch(); 

    const planData = healthPlans?.planElections ? healthPlans?.planElections[healthPlans.planElections.findIndex(i => i.type === PlanType.VISION)] : undefined;

    const cmsData = props.cmsData.fragments?.find(f => f.name === "VisionMessage");

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchHealthPlans();
        }

        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (<>
        {planData && <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faEye} className="health-color smaller" />
                Vision
                <FontAwesomeIcon icon={faCheck} className="dtlCheckMark" />
            </Card.Title>
            <Card.Body>

                <Row className="providerLabelGroup">
                    <Col className="col-12 col-sm-6">
                        {planData.provider && <ProviderLabel label="Insurance Company" value={planData.provider} />}
                        {planData.plan && <ProviderLabel label="Plan" value={planData.plan} />}
                        {planData.tier && <ProviderLabel label="Coverage" value={planData.tier} />}
                    </Col>
                    <Col className="col-12 col-sm-6">
                        {planData.obligations.map((item, i) => (i < 3 &&
                            <ProviderLabel key={i} label={item.label} value={item.value} />
                        ))}
                    </Col>
                </Row>

                {planData.obligations?.length > 3 &&
                    <Row className="providerLabelGroup" >
                        {planData.obligations.map((item, i) => (i >= 3 &&
                            <Col className="col-12 col-sm-6" key={i}>
                                <ProviderLabel label={item.label} value={item.value} />
                            </Col>
                        ))}
                    </Row>
                }

                {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                <a href="https://www.vsp.com" target="_blank" rel="noopener noreferrer" onClick={() => dispatch(activityActionCreators.logActivity("button_vsp.com"))}>
                    <Button variant="secondary" className="mt-3">Go to VSP</Button>
                </a>

            </Card.Body>
        </Card>
        }
    </>);
}

function mapStateToProps(state: AppState) {

    return {
        ...state.healthPlans,
        cmsData: state.cms.health
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...healthPlansActionCreators,
        fetchCmsData: cmsActionCreators.fetchHealthCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Vision);