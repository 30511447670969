import React, { useState, useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { Button, Card, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { passwordActionCreators } from '../../../store/accountManagement/PasswordStore';
import { Requirement } from '../../Requirement';
import { AppDispatch } from '../../..';
import { Password } from '../../../definitions/Claims';
import { activityActionCreators } from '../../../store/ActivityStore';

type TPasswordProps = ReturnType<typeof mapStateToProps>

const PasswordCard = (props: TPasswordProps) => {
    const dispatch: AppDispatch = useDispatch(); 
    const isLoading = (props.isLoading);
    const isErr = (props.error);
    const isEditing = props.isEditing;

    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    const [showRePasswordErr, setShowRePasswordErr] = useState(false);
    useEffect(() => {
        if (showRePasswordErr)
            setShowRePasswordErr(password !== rePassword)
    }, [showRePasswordErr, setShowRePasswordErr, password, rePassword]);

    const { showSuccessMsg } = props;
    useEffect(() => {
        if (showSuccessMsg)
            setTimeout(() => { dispatch(passwordActionCreators.hideSuccessMsg()) }, 3000);
    }, [showSuccessMsg, dispatch]);

    useEffect(() => {
        if (isEditing) {
            setPassword('');
            setRePassword('');
            setShowRePasswordErr(false);
        } else {
            setPassword('');
            setRePassword('');
        }
    }, [isEditing]);


    const submit = () => {
        if (password !== rePassword)
            setShowRePasswordErr(true);
        else {
            dispatch(activityActionCreators.logActivity("button_password_update"));
            dispatch(passwordActionCreators.updatePassword(password));
        }
    }

    const enableSubmitBtn = () => {
        return passwordLengthIsOk() &&
            passwordHasNumber() &&
            passwordHasLetter() &&
            passwordHasSpecial() && 
            !showRePasswordErr;
    }

    const passwordLengthIsOk = () => { return password.length >= 8; }
    const passwordHasNumber = () => { return /\d/.test(password); }
    const passwordHasLetter = () => { return /[A-Za-z]/.test(password); }
    const passwordHasSpecial = () => { return /.*(?=.*[\\.:,;~@#+_&<>'"()[\]*|{}^/\-$%=!?]).*/.test(password); }

    const PasswordRequirements = () => (
        <Col className="requirements cardStyle mt-md-4 ml-0">
            <div className="mb-3">Your case sensitive password must:</div>
            <Requirement isOk={passwordLengthIsOk()}>Be a minimum of 8 characters</Requirement>
            <Requirement isOk={passwordHasNumber()}>Have a number</Requirement>
            <Requirement isOk={passwordHasLetter()}>Have a letter</Requirement>
            <Requirement isOk={passwordHasSpecial()}>Have a special character</Requirement>
        </Col>
    );

    const Buttons = () => {
        return (<>
            <Button className="mt-3" variant="primary" type="submit" tabIndex={4} disabled={!enableSubmitBtn() || isLoading || isErr}>
                {isLoading ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> : "Change Password"}
            </Button>

            <Button className="mt-3 ml-md-4" variant="link" tabIndex={5} hidden={isLoading}
                onClick={
                    () => {
                        dispatch(activityActionCreators.logActivity("button_password_cancel"));
                        dispatch(passwordActionCreators.setIsEditing({ isEditing: false }));
                    }
                } >
                Cancel
            </Button>
        </>);
    }

    if (!props.hasClaim_View) {
        return <></>;
    }

    return (
        <Card className="pageCard secondary">
            <Card.Title><FontAwesomeIcon icon={faLock} className="acctmgmt-color" />Password</Card.Title>
            <Card.Body>

                {props.error &&
                    <Alert className="mt-3" variant="danger">Unable to update Password</Alert>
                }

                {props.showSuccessMsg && <Alert className="mt-3 fade-out" variant="success">
                    <FontAwesomeIcon icon={faCheck} /><strong>Success.</strong> Your Password has been updated.
                </Alert>}

                <p>
                    Choosing a unique, long password will help protect your account. Do not reuse passwords from other websites.
                </p>

                {isEditing &&
                    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        submit();
                    }}>

                        <Row>
                            <Col className="col-12 col-md-6">
                                <Form.Group>
                                    <Form.Label>Change Your Password</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="password"
                                            value={password}
                                            disabled={isLoading}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value); }}
                                            maxLength={40}
                                            tabIndex={2}
                                        />
                                    </div>

                                </Form.Group>

                                <div className="d-block d-md-none">
                                    <PasswordRequirements />
                                </div>

                                <Form.Group>
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type="password"
                                            value={rePassword}
                                            disabled={isLoading}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setRePassword(e.target.value); }}
                                            maxLength={40}
                                            tabIndex={3}
                                    />
                                    </div>

                                </Form.Group>

                            </Col>

                            <div className="d-none d-md-block">
                                <PasswordRequirements />
                            </div>

                        </Row>

                        <Buttons />

                        {showRePasswordErr &&
                            <Alert className="mt-3" variant="danger">Your passwords do not match. Please re-enter your passwords.</Alert>
                        }

                    </Form>
                }
                    
                {!isEditing && props.hasClaim_Update && <Button variant="primary" disabled={isLoading || isErr}
                    onClick={
                        () => {
                            dispatch(activityActionCreators.logActivity("button_password_change"));
                            dispatch(passwordActionCreators.beginEditing());
                        }
                    }>
                    {isLoading ? <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : "Change"}
                </Button>}

            </Card.Body>
        </Card>

    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.password,
        hasClaim_View: state.claims.claims?.claims?.find(c => c === Password.View),
        hasClaim_Update: state.claims.claims?.claims?.find(c => c === Password.Update)
    }
}

export default connect(
    mapStateToProps
)(PasswordCard);