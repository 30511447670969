import { Phone, PhoneType } from "../Gateway.dtos";

const findPhoneByType = function (phones: Phone[], type: PhoneType) : Phone {

    for (let i = 0; i < phones.length; i++) {
        if (phones[i].type === type)
            return phones[i];
    }

    return new Phone;
}

export { findPhoneByType }