import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsSetup } from './LsriWizard.steps';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import LsriFlowChart from './Shared/LsriFlowChart';
import { LsriFlowChartDisclaimer } from './Shared/LsriFlowChartDisclaimer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { LsriStage } from '../../../Gateway.dtos';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { cmsActionCreators } from '../../../store/CmsStore';
import { Alert, Button } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { LsriPersonData } from './Shared/LsriPersonData';
import LsriSelectionClearedModal from './Shared/LsriSelectionClearedModal';
import { scrollToElementById } from '../../../functions/scrollToElementById';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriQlac = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error

    const isElected = lsri.userElection?.scenario.includes("Q")
    const personalInfo = lsri.lsriInfo?.personalInfo;
    const incomeExceeded = lsri.lsriInfo?.oracleLsriElection?.socialSecurity?.partFlag?.toUpperCase() === "Y" && lsri.lsriInfo?.oracleLsriElection?.matrixCommon?.qlacExceedFlag?.toUpperCase() === "Y";    //TODO:CB - need to determine if sum of other DB divided by pre-retirement income is greater than or eqaul to 85%
    const enableNextButton = lsri?.menuOptions?.enabledMenuOptions?.find(o => o === GatewayPath.LsriReview) !== undefined;
    const qlacAgeRestrict = lsri.lsriInfo?.prLsriInfo?.qlacAgeRestrict;
    const caDesignated = lsri.lsriInfo?.caDesignated




    const scenario = lsri?.userElection?.scenario ?? ""
    const disabledQlacOptions = !((lsri.lsriInfo?.oracleLsriElection?.matrix?.findIndex(m =>
        m.ssAge === lsri.userElection?.ssAge &&
        m.scenario.includes("Q") &&
        (scenario.includes("B") ? m.scenario.includes("B") : !m.scenario.includes("B")) &&
        (scenario.includes("R") ? m.scenario.includes("R") : !m.scenario.includes("R"))
    ) ?? -1) >= 0)

    const isIneligible = lsri.qlacIneligible || disabledQlacOptions || qlacAgeRestrict;

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Choose to include guaranteed longevity income'

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Longevity Income (QLAC)`

        //Reset calculation errors when the user changes pages
        dispatch(LsriActionCreators.resetCalcLoadState())

        if (lsri.wizardType === LsriStage.Setup)
            dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriQlac }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lsri.wizardType === LsriStage.Setup && !lsri.menuOptions?.enabledMenuOptions?.includes(GatewayPath.LsriReview))
            dispatch(LsriActionCreators.enableReviewCheck())        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.lsriInfo.oracleLsriElection?.visitedSteps])

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "QLAC"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    const verifySpouseMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/VerifySpouseMsg`)?.html ?? '';
    const longevityIncomeMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/LongevityIncomeMsg`)?.html ?? '';
    const optOutMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/OptOutMsg`)?.html ?? '';
    const incomeExceededMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/IncomeExceededMsg`)?.html ?? '';
    //End CMS Section


    function getIneligMsg(): string {        
        if (qlacAgeRestrict)
            return 'your age is outside of the allowed range.'
        else
            return 'you do not have sufficient funds in the program.'
    }

    const IneligibleMsg = () => (<>
        <Alert variant="info" className="mt-4 mb-5">
            <FontAwesomeIcon icon={faInfoCircle} />This feature is not available because {getIneligMsg()}
        </Alert>

        <Button variant="outline-primary" onClick={() => dispatch(push(GatewayPath.LsriReview))} style={{ fontWeight: 'normal' }} disabled={!enableNextButton}>
            <strong>Next</strong> - Review & Enroll
        </Button>
    </>)

    const OptOutMsg = () => (
        <Alert variant="warning" className="mb-5">
            <FontAwesomeIcon icon={faExclamationTriangle} /><span dangerouslySetInnerHTML={{ __html: optOutMsgCms }} />
        </Alert>
    )

    const IncomeExceededMsg = () => (
        <Alert variant="warning" className="mb-5">
            <FontAwesomeIcon icon={faExclamationTriangle} /><span dangerouslySetInnerHTML={{ __html: incomeExceededMsgCms }} />
        </Alert>
    )


    const SpouseVerification = () => (<>
        <p className="mt-5" dangerouslySetInnerHTML={{ __html: verifySpouseMsgCms }} />

        <LsriPersonData
            fullName={personalInfo?.spouseName}
            ssn={personalInfo?.spouseSsn}
            dob={personalInfo?.spouseDob}
        />
    </>)

    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: 5,
                steps: wizardStepsSetup,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                {isIneligible
                    ? <IneligibleMsg />
                    : <>

                        <LsriSelectionClearedModal />

                        <p className="mb-4">
                            <span dangerouslySetInnerHTML={{ __html: headerMsgCms }} />
                            &nbsp;<span className="btn-link p-0" style={{ cursor: 'pointer' }} onClick={() => scrollToElementById('learnMoreContent', isSmallScreen)}>Learn more</span>
                        </p>

                        {incomeExceeded && <IncomeExceededMsg />}

                        {!incomeExceeded && !isElected && <OptOutMsg />}

                        <LsriFlowChart />

                        {caDesignated && <SpouseVerification />}

                        <p className="my-5" id='learnMoreContent' dangerouslySetInnerHTML={{ __html: longevityIncomeMsgCms }} />

                        <LsriFlowChartDisclaimer />

                    </>
                }
            </>}

        </SidePanelWizard>

    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriQlac);

