import React, { useEffect, useRef, useState } from 'react';
import { Carousel, Row, Col, Card } from 'react-bootstrap';
import { AppState } from '../store/configureStore';
import { AppDispatch } from '..';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cmsActionCreators } from '../store/CmsStore';
import { stateNeedsLoading } from '../functions/stateNeedsLoading';
import { SamlSelfPostForm } from '@wespath/gateway-navigation';
import { SAMLSsoModal } from './SAMLSsoModal';
import { Health } from '../definitions/Claims';
import healthCheckImg from '../images/Health-Check.png';
import giftIconImg from '../images/Gift-icon.png';
import { ServiceProvider } from '../Gateway.dtos';
import { bannerOptionsActionCreators } from '../store/BannerStore';


type TSummaryCarouselProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const SummaryCarousel = (props: TSummaryCarouselProps) => {

    const { username, hasClaim_HealthAccess, showVpBanner, showNonHfBanner, showCompassBanner } = props;

    const searchTerm = "CommunicationBanner";
    const searchTermVP = "VirginPulseBanner";
    const searchTermNonHf = "NonHealthFlexBanner";
    const searchTermCompass = "CompassBanner";

    const hasCompassBannerFromCms = props.cmsData?.fragments?.findIndex((item) => (item.name.includes(searchTermCompass))) >= 0

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }

        if (hasCompassBannerFromCms && stateNeedsLoading(props.bannerOptions)) {
            props.fetchBannerOptions();
        }
    });

    const formRef = useRef<HTMLFormElement>(null);

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    const [serviceProvider, setServiceProvider] = useState(ServiceProvider.None);

    useEffect(() => {
        if (serviceProvider === ServiceProvider.None)
            return;
        else if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
            setServiceProvider(ServiceProvider.None);
        }
        else
            setShowingSsoModal(true);

    }, [serviceProvider]);

    const hideModal = () => {
        setShowingSsoModal(false);
        setServiceProvider(ServiceProvider.None);
    }

    const VpBanner = (props: { text: string }) => (
        <table>
            <tbody>
                <tr>
                    <td width='125px'>
                        <img style={{ width: '100%' }} src={healthCheckImg} alt="health check mark" />
                    </td>
                    <td className="pl-3">
                        <span dangerouslySetInnerHTML={{ __html: props.text }} />

                        {hasClaim_HealthAccess && <span
                            className="btn-link p-0 pl-1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setServiceProvider(ServiceProvider.VirginPulse)}
                        >
                            Take the Health Check!
                        </span>}
                    </td>
                </tr>
            </tbody>
        </table>
    )

    return (<>
        {props.cmsData?.fragments?.find(f => (
            f.name.includes(searchTerm) ||
            (f.name.includes(searchTermVP) && showVpBanner) ||
            (f.name.includes(searchTermNonHf) && showNonHfBanner) ||
            (f.name.includes(searchTermCompass) && showCompassBanner)
        )) && <>
                <Carousel className="mb-3" controls={false} indicators={false}>

                    {props.cmsData?.fragments?.map((item, i) => (item.name.includes(searchTermVP) && showVpBanner &&
                        <Carousel.Item key={`vp-${i}`}>
                            <Card className="py-2 pb-md-0">
                                <Card.Body>
                                    <VpBanner text={item.html} />
                                </Card.Body>
                            </Card>
                        </Carousel.Item>
                    ))}

                    {props.cmsData?.fragments?.map((item, i) => (item.name.includes(searchTermNonHf) && showNonHfBanner &&
                        <Carousel.Item key={`hf-${i}`}>
                            <Card className="py-2 pb-md-0">
                                <Card.Body dangerouslySetInnerHTML={{ __html: item?.html?.replace("&amp;giftIconImg", giftIconImg) ?? '' }} />
                            </Card>
                        </Carousel.Item>
                    ))}

                    {props.cmsData?.fragments?.map((item, i) => (item.name.includes(searchTermCompass) && showCompassBanner &&
                        <Carousel.Item key={`compass-${i}`}>
                            <Card className="py-2 pb-md-0">
                                <Card.Body dangerouslySetInnerHTML={{ __html: item.html }} />
                            </Card>
                        </Carousel.Item>
                    ))}

                    {props.cmsData?.fragments?.map((item, i) => (item.name.includes(searchTerm) &&
                        <Carousel.Item key={`communication-${i}`}>
                            <Card className="py-2 pb-md-0">
                                <Card.Body dangerouslySetInnerHTML={{ __html: item.html }} />
                            </Card>
                        </Carousel.Item>
                    ))}

                </Carousel>
            </>}

        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
        <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => hideModal()} serviceProvider={serviceProvider} username={username} referrer="b" />
    </>);
}

function mapStateToProps(state: AppState) {

    return {
        username: state.account.username,
        hasClaim_HealthAccess: state.claims?.claims?.claims?.find(c => c === Health.Access),
        cmsData: state.cms.summary,
        showVpBanner: state.wellBeing?.wellBeing?.vpIsQualified && state.healthPlans?.healthPlans?.hasMedical,
        showNonHfBanner: state.healthPlans.isLoaded && !state.healthPlans?.healthPlans?.hasMedical,
        bannerOptions: state.bannerOptions,
        showCompassBanner: state.bannerOptions?.showCompassBanner
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchSummaryCmsData,
        fetchBannerOptions: bannerOptionsActionCreators.fetchBannerOptions
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SummaryCarousel);