import React, { useEffect, useRef, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopMedical } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';
import { ServiceProvider } from '../../../Gateway.dtos';

type TTelemedicineProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Telemedicine = (props: TTelemedicineProps) => {
    const { username, hasClaim_HealthAccess } = props;
    const cmsData = props.cmsData.fragments?.find(f => f.name === "TelemedicineMessage");

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.MDLive;

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }

    return (<>

        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faLaptopMedical} className="health-color smaller" />
            Telemedicine
        </Card.Title>
            <Card.Body>

                {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                <p className="thin">
                    Register in advance for easy access.
                </p>

                <Button variant="secondary"
                    className={`mt-3 ${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
                    onClick={handleSubmit}
                    disabled={!hasClaim_HealthAccess}
                >
                    Go to MDLIVE
                </Button>

            </Card.Body>
        </Card>
        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
        <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer="b" />
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.health,
        username: state.account.username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Telemedicine);