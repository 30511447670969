import { faAddressCard, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Skeleton from "react-loading-skeleton";
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../../..';
import { Contact } from '../../../definitions/Claims';
import { formatPhone } from '../../../functions/formatPhone';
import { formatZip } from '../../../functions/formatZip';
import { isValidEmail } from '../../../functions/isValidEmail';
import { isValidPhone } from '../../../functions/isValidPhone';
import { removeInvalidChars_Address, removeInvalidChars_City } from '../../../functions/removeInvalidChars';
import { titleCase } from '../../../functions/titleCase';
import { UpdatePersonalInfo } from '../../../Gateway.dtos';
import { contactInfoActionCreators } from '../../../store/accountManagement/ContactInfoStore';
import { AppState } from '../../../store/configureStore';
import { DropDown } from '../../DropDown';
import { activityActionCreators } from '../../../store/ActivityStore';

type TPersonalInfo = ReturnType<typeof mapStateToProps>;

const ContactInformation = (props: TPersonalInfo) => {
    const dispatch: AppDispatch = useDispatch();  
    const isLoading = (props.dataIsLoading || !props.dataIsLoaded);
    const isSubmitting = props.isLoading
    const isErr = (props.dataIsErr);
    const isEditing = props.isEditing;
    const { showSuccessMsg } = props

    const [showUpdateSpouseModal, setShowUpdateSpouseModal] = useState(false);

    const origCountryCode = props.countryCode === "US" || props.countryCode === "" ? "US" : props.countryCode;

    const [isForeignEdit, setIsForeignEdit] = useState(true);

    const [countryCodeEdit, setCountryCodeEdit] = useState('');
    const [showCountryErr, setShowCountryErr] = useState(false);
    function isCountryValid(val: string) {
        const isValid = (isForeignEdit && val !== "US") || (!isForeignEdit && val === "US");
        setShowCountryErr(!isValid);
        return isValid;
    }
    
    const [textPhoneEdit, setTextPhoneEdit] = useState('');
    const [showTextPhoneErr, setShowTextPhoneErr] = useState(false);
    function isTextPhoneValid(val: string, isDomestic?: boolean) {
        const isValid = isValidPhone(val, isDomestic || !isForeignEdit, false);
        setShowTextPhoneErr(!isValid);
        return isValid;
    }

    const [phoneEdit, setPhoneEdit] = useState('');
    const [showPhoneErr, setShowPhoneErr] = useState(false);
    function isPhoneValid(val: string, isDomestic?: boolean) {
        const isValid = isValidPhone(val, isDomestic || !isForeignEdit, true);
        setShowPhoneErr(!isValid);
        return isValid;
    }

    const [altPhoneEdit, setAltPhoneEdit] = useState('');
    const [showAltPhoneErr, setShowAltPhoneErr] = useState(false);
    function isAltPhoneValid(val: string, isDomestic?: boolean) {
        const isValid = isValidPhone(val, isDomestic || !isForeignEdit, true);
        setShowAltPhoneErr(!isValid);
        return isValid;
    }

    const [emailEdit, setEmailEdit] = useState('');
    const [showEmailErr, setShowEmailErr] = useState(false);
    function isEmailValid(val: string) {
        const isValid = isValidEmail(val);
        setShowEmailErr(!isValid);
        return isValid;
    }

    const [streetAddress1Edit, setStreetAddress1Edit] = useState('');
    const [showStreetAddress1Err, setShowStreetAddress1Err] = useState(false);
    function isStreetAddress1Valid(val: string) {
        const isValid = val.length !== 0;
        setShowStreetAddress1Err(!isValid);
        return isValid;
    }

    const [streetAddress2Edit, setStreetAddress2Edit] = useState('');
    const [showStreetAddress2ErrMsg, setShowStreetAddress2ErrMsg] = useState('');
    function isStreetAddress2Valid(val: string) {
        let msg = ""

        if (val === "" && streetAddress3Edit.length > 0)
            msg = "Street 2 must be populated if Street 3 is populated"
        else if (val === "")
            msg = ""
        else if (val.trim().toLowerCase() === streetAddress1Edit.trim().toLowerCase())
            msg = "Street 2 cannot match Street 1"

        setShowStreetAddress2ErrMsg(msg);
        return msg === "";
    }

    const [streetAddress3Edit, setStreetAddress3Edit] = useState('');
    const [showStreetAddress3ErrMsg, setShowStreetAddress3ErrMsg] = useState('');
    function isStreetAddress3Valid(val: string) {
        let msg = ""
        const valTrim = val.trim().toLowerCase()

        if (valTrim === "")
            msg = ""
        else if (valTrim === streetAddress1Edit.trim().toLowerCase())
            msg = "Street 3 cannot match Street 1"
        else if (valTrim === streetAddress2Edit.trim().toLowerCase())
            msg = "Street 3 cannot match Street 2"
        else if (valTrim === cityEdit.trim().toLowerCase())
            msg = "Street 3 cannot match City"
        else if (valTrim === countryCodeEdit.toLowerCase() || valTrim === getCountryDisplay(countryCodeEdit).toLowerCase())
            msg = "Street 3 cannot match Country"

        setShowStreetAddress3ErrMsg(msg);
        return msg === "";
    }

    const [cityEdit, setCityEdit] = useState('');
    const [showCityErr, setShowCityErr] = useState(false);
    function isCityValid(val: string) {
        const isValid = (!isForeignEdit && val.length > 1) || (isForeignEdit && val.length === 0);
        setShowCityErr(!isValid);
        return isValid;
    }

    const [stateEdit, setStateEdit] = useState('');
    const [showStateErr, setShowStateErr] = useState(false);
    function isStateValid(val: string) {
        const isValid = (!isForeignEdit && val.length !== 0) || (isForeignEdit && val.length === 0);
        setShowStateErr(!isValid);
        return isValid;
    }

    const [zipCodeEdit, setZipCodeEdit] = useState('');
    const [showZipCodeErr, setShowZipCodeErr] = useState(false);
    function isZipCodeValid(val: string) {
        const isValid = (!isForeignEdit && (val.length === 5 || val.length === 10)) || (isForeignEdit && val.length === 0);
        setShowZipCodeErr(!isValid);
        return isValid;
    }

    function getStateDisplay(state: string) {
        const stateFound = props.stateList?.states.find(function (s) {return s.value === state;});

        if (stateFound) {
            //Get title case and replace some values that dont convert properly
            return titleCase(stateFound.display)
                .replace(" Of ", " of ")
                .replace(" The ", " the ")
                .replace("U.s.", "U.S.")
                .replace("(a", "(A")
                .replace("(o", "(O")
                .replace("(p", "(P");
        }
        
        return state;
    }

    function getCountryDisplay(country: string | undefined): string {

        if (country === "US") return titleCase("UNITED STATES");

        const countryFound = props.countryList?.countries.find(function (c) { return c.value === country; });
        return titleCase(countryFound?.display ? countryFound.display : country);
    }

    const isInvalidAddress = () => {
        return props.invalidAddress || props.countryCode === "" ||
            (props.countryCode === "US" && (props.streetAddress1 === "" || props.city === "" || props.stateAbbr === "" || props.zipCode === "")) ||
            (props.countryCode !== "US" && props.streetAddress1 === "");
    }

    useEffect(() => {
        //If inline errors are displayed, re-evaluate them on changes
        if (isEditing) {
            if (showTextPhoneErr) { isTextPhoneValid(textPhoneEdit); }
            if (showPhoneErr) isPhoneValid(phoneEdit);
            if (showAltPhoneErr) isAltPhoneValid(altPhoneEdit);
            if (showEmailErr) isEmailValid(emailEdit);
            if (showCountryErr) isCountryValid(countryCodeEdit);
            if (showStreetAddress1Err) isStreetAddress1Valid(streetAddress1Edit);
            if (showStreetAddress2ErrMsg) isStreetAddress2Valid(streetAddress2Edit);
            if (showStreetAddress3ErrMsg) isStreetAddress3Valid(streetAddress3Edit);
            if (showCityErr) isCityValid(cityEdit);
            if (showStateErr) isStateValid(stateEdit);
            if (showZipCodeErr) isZipCodeValid(zipCodeEdit);
        }
    });

    useEffect(() => {
        if (showSuccessMsg)
            setTimeout(() => { dispatch(contactInfoActionCreators.hideSuccessMsg())}, 3000);
    }, [showSuccessMsg, dispatch]);

    useEffect(() => {
        if (isEditing) {
            //Set the initial values
            setIsForeignEdit(origCountryCode !== "US");
            setPhoneEdit(formatPhone(props.phone, origCountryCode, false));
            setTextPhoneEdit(formatPhone(props.textPhone, origCountryCode, false));
            setAltPhoneEdit(formatPhone(props.altPhone, origCountryCode, false));
            setEmailEdit(props.email);
            resetAddresses();
        } else {
            //Clear all errors
            setShowPhoneErr(false);
            setShowTextPhoneErr(false);
            setShowAltPhoneErr(false);
            setShowEmailErr(false);
            setShowCountryErr(false);
            setShowStreetAddress1Err(false);
            setShowStreetAddress2ErrMsg('');
            setShowStreetAddress3ErrMsg('');
            setShowCityErr(false);
            setShowStateErr(false);
            setShowZipCodeErr(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    useEffect(() => {
        if (countryCodeEdit === '' && origCountryCode)
            setCountryCodeEdit(origCountryCode)
    }, [origCountryCode, setCountryCodeEdit, countryCodeEdit])

    function resetAddresses() {
        setCountryCodeEdit(origCountryCode);
        setStreetAddress1Edit(removeInvalidChars_Address(props.streetAddress1));
        setStreetAddress2Edit(removeInvalidChars_Address(props.streetAddress2));
        setStreetAddress3Edit(removeInvalidChars_Address(props.streetAddress3));
        setCityEdit(removeInvalidChars_City(props.city));
        setStateEdit(props.stateAbbr);
        setZipCodeEdit(props.zipCode);
    }

    function clearAddresses() {
        setStreetAddress1Edit('');
        setStreetAddress2Edit('');
        setStreetAddress3Edit('');
        setCityEdit('');
        setStateEdit('');
        setZipCodeEdit('');
    }

    function switchAddrType() {
        //Get the opposite of the current value since it will be changing
        const isForeign = !isForeignEdit; 

        //If switching back to original status (domestic/foreign), repopulate with original values
        if ((origCountryCode === "US" && !isForeign) || (origCountryCode !== "US" && isForeign))
            resetAddresses();
        else
            clearAddresses();

        //Format phone numbers to match foreign/domestic
        setTextPhoneEdit(formatPhone(textPhoneEdit, isForeign ? "" : "US", false));
        setPhoneEdit(formatPhone(phoneEdit, isForeign ? "" : "US", false));
        setAltPhoneEdit(formatPhone(altPhoneEdit, isForeign ? "" : "US", false));

        if (!isForeign) {
            //Check phone number format
            isTextPhoneValid(textPhoneEdit, true);
            isPhoneValid(phoneEdit, true);
            isAltPhoneValid(altPhoneEdit, true);

            //Check that country code is populated since user cannot elect it
            setCountryCodeEdit("US");
        }

        setIsForeignEdit(isForeign);   
    }

    const isInlineErrors = () => {
        let isErr = false

        //Check for errors so that they all display
        isErr = !isTextPhoneValid(textPhoneEdit) || isErr;
        isErr = !isPhoneValid(phoneEdit) || isErr;
        isErr = !isAltPhoneValid(altPhoneEdit) || isErr;
        isErr = !isEmailValid(emailEdit) || isErr;
        isErr = !isCountryValid(countryCodeEdit) || isErr;
        isErr = !isStreetAddress1Valid(streetAddress1Edit) || isErr;
        isErr = !isStreetAddress2Valid(streetAddress2Edit) || isErr;
        isErr = !isStreetAddress3Valid(streetAddress3Edit) || isErr;
        isErr = !isCityValid(cityEdit) || isErr;
        isErr = !isStateValid(stateEdit) || isErr;
        isErr = !isZipCodeValid(zipCodeEdit) || isErr;

        return isErr;
    }

    const createPersonalInfo = (updateSpouse?: string) => {
        const personalInfo = new UpdatePersonalInfo();

        personalInfo.textPhone = textPhoneEdit;
        personalInfo.phone = phoneEdit;
        personalInfo.altPhone = altPhoneEdit;
        personalInfo.email = emailEdit;
        personalInfo.countryCode = countryCodeEdit;
        personalInfo.countryName = getCountryDisplay(countryCodeEdit);
        personalInfo.streetAddress1 = streetAddress1Edit;
        personalInfo.streetAddress2 = streetAddress2Edit;
        personalInfo.streetAddress3 = streetAddress3Edit;
        personalInfo.city = cityEdit;
        personalInfo.state = stateEdit;
        personalInfo.zipCode = zipCodeEdit;
        personalInfo.updateSpouseAddr = updateSpouse === 'Y';

        return personalInfo;
    }

    const submit = (updateSpouse?: string) => {

        if (isInlineErrors())
            return;

        if (props.isMarried && !updateSpouse) {
            setShowUpdateSpouseModal(true);
            return;
        }

        setShowUpdateSpouseModal(false);

        const personalInfo = createPersonalInfo(updateSpouse);

        dispatch(activityActionCreators.logActivity("button_contactInfo_update"));
        dispatch(contactInfoActionCreators.updateContactInfo(personalInfo));
    }


    const UpdateSpouseModal = () => {

        const handleNo = () => submit('N');
        const handleYes = () => submit('Y');

        return (
            <Modal show={showUpdateSpouseModal} backdrop="static" keyboard={false} >
                <Modal.Body>
                    Do you wish to update your spouse&apos;s address as well?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNo}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleYes}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const formColClassXl = "col-sm-10 col-md-7 col-lg-9 col-xl-10"
    const formColClassLg = "col-sm-7 col-md-5 col-lg-8 col-xl-7"
    const formColClassMd = "col-sm-6 col-md-4 col-lg-7 col-xl-6"
    const formColClassSm = "col-sm-4 col-md-3 col-lg-5 col-xl-4"
    return (
        <Card className="pageCard secondary">
            <Card.Title><FontAwesomeIcon icon={faAddressCard} className="acctmgmt-color" />Contact Information</Card.Title>
            <Card.Body>

                <UpdateSpouseModal />

                {isLoading &&
                    <LoadingSkeleton />
                }

                {isErr && <Alert className="mt-2" variant="danger">
                    Unable to retrieve data for Contact Information
                </Alert>}

                {!isLoading && isInvalidAddress() && <Alert className="mt-2" variant="danger">
                    Wespath does not have a valid address for you. Please add or update your contact information.
                </Alert>}

                {props.showSuccessMsg && <Alert className="mt-3 fade-out" variant="success">
                    <FontAwesomeIcon icon={faCheck} /><strong>Success.</strong> Your information has been updated.
                </Alert>}

                {!isLoading && !isErr &&
                <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    submit();
                }}>

                    <Row>
                        <Col className={formColClassMd}>
                            <Form.Group className={showTextPhoneErr ? "error" : ""}>
                                <Form.Label>Text Messages</Form.Label>
                                {isEditing ?
                                    <Form.Control
                                        type="text"
                                        value={textPhoneEdit}
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setTextPhoneEdit(formatPhone(e.target.value, isForeignEdit ? "" : "US", false)) }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isTextPhoneValid(e.target.value) }}
                                        maxLength={isForeignEdit ? 20 : 14}
                                        tabIndex={1}
                                        autoFocus
                                    /> :
                                    <Form.Text>{props.textPhone ? formatPhone(props.textPhone, origCountryCode, false) : "-"}</Form.Text>
                                }
                                {isEditing && showTextPhoneErr && <div className="errMsg">Invalid Text Messages Phone</div>} 
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className={formColClassMd}>
                            <Form.Group className={showPhoneErr ? "error" : ""}>
                                <Form.Label>Primary</Form.Label>
                                {isEditing ?
                                    <Form.Control
                                        type="text"
                                        value={phoneEdit}
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPhoneEdit(formatPhone(e.target.value, isForeignEdit ? "" : "US", false)) }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isPhoneValid(e.target.value) }}
                                        maxLength={isForeignEdit ? 20 : 14}
                                        tabIndex={2}
                                    /> :
                                    <Form.Text>{props.phone ? formatPhone(props.phone, origCountryCode, false) : "-"}</Form.Text>
                                }
                                {isEditing && showPhoneErr && <div className="errMsg">Invalid Primary Phone</div>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className={formColClassMd}>
                            <Form.Group className={showAltPhoneErr ? "error" : ""}>
                                <Form.Label>Secondary</Form.Label>
                                {isEditing ?
                                    <Form.Control
                                        type="text"
                                        value={altPhoneEdit}
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setAltPhoneEdit(formatPhone(e.target.value, isForeignEdit ? "" : "US", false)) }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isAltPhoneValid(e.target.value) }}
                                        maxLength={isForeignEdit ? 20 : 14}
                                        tabIndex={3}
                                    /> :
                                    <Form.Text>{props.altPhone ? formatPhone(props.altPhone, origCountryCode, false) : "-"}</Form.Text>
                                }
                                {isEditing && showAltPhoneErr && <div className="errMsg">Invalid Secondary Phone</div>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className={formColClassXl}>
                            <Form.Group className={showEmailErr ? "error" : ""}>
                                <Form.Label>Email</Form.Label>
                                {isEditing ?
                                    <Form.Control
                                        type="text"
                                        value={emailEdit}
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmailEdit(e.target.value) }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isEmailValid(e.target.value) }}
                                        maxLength={40}
                                        tabIndex={4}
                                    /> :
                                    <Form.Text>{props.email}</Form.Text>
                                }
                                {isEditing && showEmailErr && <div className="errMsg">Invalid Email</div>}
                            </Form.Group>
                        </Col>
                    </Row>

                    {/*Clean display for address when not editing*/}
                    {!isEditing &&
                        <Row>
                            <Col className="col-12">
                                <Form.Group>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Text>{props.streetAddress1}</Form.Text>
                                    {props.streetAddress2 && <Form.Text>{props.streetAddress2}</Form.Text>}
                                    {props.streetAddress3 && <Form.Text>{props.streetAddress3}</Form.Text>}
                                
                                    {origCountryCode === "US" && <>
                                        <Form.Text>{props.city}</Form.Text>
                                        <Form.Text>{getStateDisplay(props.stateAbbr)}, {formatZip(props.zipCode)}</Form.Text>
                                    </>}
                                    <Form.Text>{getCountryDisplay(props.countryCode)}</Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                    }

                    {isEditing && <>
                        <Row>
                            <Col className="col-12">
                                <Form.Group>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Check
                                        checked={isForeignEdit}
                                        name="isForeign"
                                        type="checkbox"
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { switchAddrType() }}
                                        label="This is a non U.S. address"
                                        tabIndex={5}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {isForeignEdit && <ForeignAddrInstr />}

                        {isForeignEdit &&
                            <Row>
                                <Col className={formColClassXl}>
                                    <Form.Group className={showCountryErr ? "error" : ""}>
                                        <Form.Label>Country</Form.Label>
                                        <DropDown name="country"
                                            dropDownOptions={props.countryList.countries}
                                            defaultValue={countryCodeEdit ? countryCodeEdit : origCountryCode}
                                            disabled={isSubmitting}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCountryCodeEdit(e.target.value); isCountryValid(e.target.value); }}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isCountryValid(e.target.value); }}
                                            tabIndex={10}
                                        />
                                        {showCountryErr && <div className="errMsg">Invalid Country</div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col className={formColClassLg}>
                                <Form.Group className={showStreetAddress1Err ? "error" : ""}>
                                    <Form.Label>Street 1</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={streetAddress1Edit}
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStreetAddress1Edit(removeInvalidChars_Address(e.target.value)) }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isStreetAddress1Valid(removeInvalidChars_Address(e.target.value)) }}
                                        maxLength={40}
                                        tabIndex={11}
                                    />
                                    {showStreetAddress1Err && <div className="errMsg">Invalid Street 1</div>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className={formColClassLg}>
                                <Form.Group className={showStreetAddress2ErrMsg ? "error" : ""}>
                                    <Form.Label>Street 2</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={streetAddress2Edit}
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStreetAddress2Edit(removeInvalidChars_Address(e.target.value)) }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isStreetAddress2Valid(removeInvalidChars_Address(e.target.value)) }}
                                        maxLength={40}
                                        tabIndex={12}
                                    />
                                    {showStreetAddress2ErrMsg && <div className="errMsg">{showStreetAddress2ErrMsg}</div>}
                                </Form.Group>
                            </Col>
                        </Row>

                        {isForeignEdit && <Row>
                            <Col className={formColClassLg}>
                                <Form.Group className={showStreetAddress3ErrMsg ? "error" : ""}>
                                    <Form.Label>Street 3</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={streetAddress3Edit}
                                        disabled={isSubmitting}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStreetAddress3Edit(removeInvalidChars_Address(e.target.value)) }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isStreetAddress3Valid(removeInvalidChars_Address(e.target.value)) }}
                                        maxLength={40}
                                        tabIndex={13}
                                    />
                                    {showStreetAddress3ErrMsg && <div className="errMsg">{showStreetAddress3ErrMsg}</div>}
                                </Form.Group>
                            </Col>
                        </Row>}

                        {!isForeignEdit && <>
                            <Row>
                                <Col className={formColClassMd}>
                                    <Form.Group className={showCityErr ? "error" : ""}>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={cityEdit}
                                            disabled={isSubmitting}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCityEdit(removeInvalidChars_City(e.target.value)) }}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isCityValid(removeInvalidChars_City(e.target.value)) }}
                                            maxLength={28}
                                            tabIndex={14}
                                        />
                                        {showCityErr && <div className="errMsg">Invalid City</div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className={formColClassMd}>
                                    <Form.Group className={showStateErr ? "error" : ""}>
                                        <Form.Label>State</Form.Label>
                                        <DropDown name="state"
                                            dropDownOptions={props.stateList.states}
                                            defaultValue={stateEdit}
                                            disabled={isSubmitting}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setStateEdit(e.target.value) }}
                                            tabIndex={15}
                                        />
                                        {showStateErr && <div className="errMsg">Invalid State</div>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className={formColClassSm}>
                                    <Form.Group className={showZipCodeErr ? "error" : ""}>
                                        <Form.Label>ZIP</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={zipCodeEdit}
                                            disabled={isSubmitting}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setZipCodeEdit(formatZip(e.target.value)) }}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { isZipCodeValid(e.target.value) }}
                                            maxLength={40}
                                            tabIndex={16}
                                        />
                                        {showZipCodeErr && <div className="errMsg">Invalid ZIP Code</div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>}

                        <Button className="mt-4" variant="primary" type="submit" tabIndex={20} disabled={isSubmitting || props.error}>
                            {isSubmitting ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : "Update Information"}
                        </Button>
                        <Button className="mt-4 ml-md-4" variant="link" tabIndex={21} hidden={isSubmitting}
                            onClick={
                                () => {
                                    dispatch(activityActionCreators.logActivity("button_contactInfo_cancel"));
                                    dispatch(contactInfoActionCreators.setIsEditing({ isEditing: false }));
                                }
                            }
                        >
                            Cancel
                        </Button>
                    </>}

                    {!isEditing && props.hasClaim_Update && <Button variant="primary" className="mt-2"
                        disabled={props.error}
                        onClick={() => {
                            dispatch(activityActionCreators.logActivity("button_contactInfo_change"));
                            dispatch(contactInfoActionCreators.beginEditing());
                        }}
                    >
                        {props.isLoading ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : "Change"}
                    </Button>}

                    {props.error &&
                        <Alert className="mt-3" variant="danger">Unable to update Contact Information</Alert>
                    }

                </Form>}
                
            </Card.Body>
        </Card>

    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.contactInfo,
        countryList: state.personalInfo?.personalInfo?.countryList,
        stateList: state.personalInfo?.personalInfo?.stateList,
        isMarried: state.personalInfo?.personalInfo?.personalInformation?.maritalStatus === "Married",
        dataIsLoading: state.personalInfo.isLoading,
        dataIsLoaded: state.personalInfo.isLoaded,
        dataIsErr: state.personalInfo.error,
        hasClaim_Update: state.claims.claims?.claims?.find(c => c === Contact.Update),
        hasCompletedMultiFactor: state.authMultiFactor.hasCompletedMultiFactor,
        isChallenging: state.authMultiFactor.isChallenging
    }
}

export default connect(
    mapStateToProps
)(ContactInformation);

const LoadingSkeleton = () => (<>
    <Form.Group>
        <Form.Label className="mt-2"><Skeleton width={'7em'} /></Form.Label>
        <Form.Text><Skeleton width={'9em'} /></Form.Text>
    </Form.Group>
    <Form.Group>
        <Form.Label><Skeleton width={'7em'} /></Form.Label>
        <Form.Text><Skeleton width={'9em'} /></Form.Text>
    </Form.Group>
    <Form.Group>
        <Form.Label><Skeleton width={'7em'} /></Form.Label>
        <Form.Text><Skeleton width={'9em'} /></Form.Text>
    </Form.Group>
    <Form.Group>
        <Form.Label><Skeleton width={'9em'} /></Form.Label>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
    </Form.Group>
    <Form.Group>
        <Form.Label><Skeleton width={'6em'} /></Form.Label>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
        <Form.Text><Skeleton width={'12em'} /></Form.Text>
    </Form.Group>
</>);

const ForeignAddrInstr = () => (
    <div>
        <p>
            A foreign address should be entered as follows:
        </p>

        <p>
            Street 1<br />
            Street address of post office box number
        </p>

        <p>
            Street 2<br />
            City or town, other principle subdivision (such as providence, state, or country)
            and postal code (in some countries the postal code may precede the city or town name.)
        </p>

        <p>
            Street 3<br />
            Use this line if the information above does not fit on two lines
        </p>

        <p>
            <i><strong>Examples:</strong></i><br />
            Canada<br />
            1010 Clear Street<br />
            Ottawa ON K1A 0B1
        </p>

        <p>
            England<br />
            2045 Royal Road<br />
            London WIP 6HQ
        </p>

        <p>
            France<br />
            2045 Royal Road<br />
            06570 St Paul
        </p>
    </div>
);