import React, { useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { PageTitle } from '../PageTitle';
import Cpp from '../cards/lifeAndDisability/Cpp';
import UmLifeOptions from '../cards/lifeAndDisability/UmLifeOptions';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from 'redux';
import { cppActionCreators } from '../../store/CppStore';
import { connect } from 'react-redux';
import { umLifeOptionsActionCreators } from '../../store/UmLifeOptionsStore';
import { UmLifeEligType, GetUmLifeEligType } from '../../functions/getUmLifeEligibilityType';
import BeneficiaryReviewModal from '../BeneficiaryReviewModal';
import { AppDispatch } from '../..';
import UnlockBanner from '../UnlockBanner';

type TLifeAndDisabilityProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const BenefitsSummary = (props: TLifeAndDisabilityProps) => {
    const { cpp, umLifeOptions } = props;

    useEffect(() => {
        document.title = "Benefits Access - Life and Disability"
    }, []);

    useEffect(() => {
        if (stateNeedsLoading(cpp))
            props.fetchCppEligibility();

        if (stateNeedsLoading(umLifeOptions))
            props.fetchUmLifeOptions();
        
    });

    const showCppCard = (props.cpp?.cppEligibility?.cppEligible);
    const showUmLife = (GetUmLifeEligType(props.umLifeOptions?.umLifeOptions?.benefitClassCode) !== UmLifeEligType.INELIGIBLE);
    const colClass = "col-12 col-lg-6";

    const Title = () => (<>
        <PageTitle title="Life & Disability Details" color="lifedis-color-bg" />
        <Card.Title id="cardAsOfDate"></Card.Title>
    </>);

    const DesktopView = () => (
        <Card className="detailCard desktop" style={{marginTop: '2em'}}>
            <Title />
            <Card.Body>
                <Row>
                    {showCppCard &&
                        <Col className={colClass}>
                            <Cpp />
                        </Col>
                    }

                    {showUmLife &&
                        <Col className={colClass}>
                            <UmLifeOptions />
                        </Col>
                    }

                </Row>
            </Card.Body>
        </Card>
    );

    const MobileView = () => (
        <div className="detailCard mobile">
            <Card>
                <Title />
                <Card.Body>
                    {showCppCard && <Cpp />}
                    {!showCppCard && showUmLife && <UmLifeOptions />}

                </Card.Body>
            </Card>

            {showCppCard && showUmLife && <UmLifeOptions />}
        </div>
    );

    return (<>
        <BeneficiaryReviewModal />

        <UnlockBanner />

        <DesktopView />

        <MobileView />
    </>);

};

function mapStateToProps(state: AppState) {
    const { cpp, umLifeOptions } = state;

    return {
        cpp,
        umLifeOptions
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...cppActionCreators, ...umLifeOptionsActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BenefitsSummary);

