import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { accountActionCreators } from '../../../store/AccountStore';
import AuthMultiFactorEnterCode from './AuthMultiFactorEnterCode';
import AuthMultiFactorPhone from './AuthMultiFactorPhone';
import { CodeState } from '../../../store/AuthMultiFactorStore';



type TMultiFactorProps = ReturnType<typeof mapStateToProps>

const MultiFactorModal = (props: TMultiFactorProps) => {
    const { redirectToAccessDenied, codeState, hasCompletedMultiFactor, isChallenging } = props;
    const dispatch: AppDispatch = useDispatch();    
    useEffect(() => {
        if (redirectToAccessDenied) {
            dispatch(accountActionCreators.accessDenied());
        }
    }, [redirectToAccessDenied, dispatch]);

    if (hasCompletedMultiFactor) {
        return null;
    }


    return <>
        <Modal
            show={isChallenging}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Multi-Factor Authentication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {codeState === CodeState.Sent
                    || codeState === CodeState.Resending
                    || codeState === CodeState.Validating
                    || codeState === CodeState.Expired
                    || codeState === CodeState.Invalid
                    ? <AuthMultiFactorEnterCode />
                    : <AuthMultiFactorPhone />
                }
            </Modal.Body>
        </Modal>
    </>
}

function mapStateToProps(state: AppState) {
    const { accessDenied, codeState, hasCompletedMultiFactor, isChallenging } = state.authMultiFactor
    return {
        page: state.router.location.pathname,
        redirectToAccessDenied: accessDenied,
        codeState,
        hasCompletedMultiFactor,
        isChallenging
    }
}


export default connect(
    mapStateToProps
)(MultiFactorModal);