import React from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { Button } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { AppDispatch } from '../../../..';
import { GatewayPath } from '@wespath/gateway-navigation';
import { LsriStage } from '../../../../Gateway.dtos';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriTextAboveSidePanel = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { lsri } = props

    const hancleClickSetup = () => {
        dispatch(LsriActionCreators.setWizardType(LsriStage.Setup))
        dispatch(push(GatewayPath.LsriReview))
    }

    if (lsri.wizardType !== LsriStage.Commit)
        return null;
    
    return (
        <Button className="m-0 p-0 mt-2 mb-4 pb-2" variant="link" onClick={() => hancleClickSetup()}>
            &lt; Back To Setup
        </Button>
    );
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriTextAboveSidePanel);

