import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { healthPlansActionCreators } from '../../../store/HealthPlanStore';
import { connect, useDispatch } from 'react-redux';
import { PlanType, ServiceProvider } from '../../../Gateway.dtos';
import { ProviderLabel } from '../../ProviderLabel';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppDispatch } from '../../..';
import { cmsActionCreators } from '../../../store/CmsStore';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';

type TDentalProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Dental = (props: TDentalProps) => {
    const { healthPlans, username, hasClaim_HealthAccess } = props;

    const planData = healthPlans?.planElections ? healthPlans?.planElections[healthPlans.planElections.findIndex(i => i.type === PlanType.DENTAL)] : undefined;

    const cmsData = props.cmsData.fragments?.find(f => f.name === "DentalMessage");

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchHealthPlans();
        }

        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.Cigna

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }

    return (<>
        {planData && <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faTooth} className="health-color" />
                Dental
                <FontAwesomeIcon icon={faCheck} className="dtlCheckMark" />
            </Card.Title>
            <Card.Body>

                <Row className="providerLabelGroup">
                    <Col className="col-12 col-sm-6">
                        {planData.provider && <ProviderLabel label="Insurance Company" value={planData.provider} />}
                        {planData.plan && <ProviderLabel label="Plan" value={planData.plan} />}
                        {planData.tier && <ProviderLabel label="Coverage" value={planData.tier} />}
                    </Col>
                    <Col className="col-12 col-sm-6">
                        {planData.obligations.map((item, i) => (i < 3 &&
                            <ProviderLabel key={i} label={item.label} value={item.value} />
                        ))}
                    </Col>
                </Row>

                {planData.obligations?.length > 3 &&
                    <Row className="providerLabelGroup" >
                        {planData.obligations.map((item, i) => (i >= 3 &&
                            <Col className="col-12 col-sm-6" key={i}>
                                <ProviderLabel label={item.label} value={item.value} />
                            </Col>
                        ))}
                    </Row>
                }

                {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                <Button variant="secondary"
                    className={`mt-3 ${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
                    onClick={handleSubmit}
                    disabled={!hasClaim_HealthAccess}
                >
                    Go to Cigna
                </Button>

            </Card.Body>
        </Card>}
        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
        <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer="b" />
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        ...state.healthPlans,
        cmsData: state.cms.health,
        username: state.account.username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...healthPlansActionCreators,
        fetchCmsData: cmsActionCreators.fetchHealthCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dental);