import { AppState } from "./configureStore";
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { GetPastPaymentsInfo, GetPastPaymentsInfoResponse, PastPaymentInfo } from "../Gateway.dtos";
import { getResetAction } from "../functions/getResetAction";
import { accountActionCreators } from "./AccountStore";

interface Error {
    errorMessage: string | unknown
}

export interface IPaymentsInfoState extends IEnumLoadableState {
    errorMessage: string | undefined,
    perPageRecords: number,
    pageNumber:number,
    updateLoadState: DataLoadState,
    showSuccessMsg: boolean,
    pastPaymentInfos: PastPaymentInfo[],
    allrecordsFetched: boolean;
}

export const initialPaymentsInfo: IPaymentsInfoState = {
    loadState: DataLoadState.None,
    errorMessage: "",
    perPageRecords: 100,
    pageNumber:1,
    updateLoadState: DataLoadState.None,
    showSuccessMsg: false,
    pastPaymentInfos: [],
    allrecordsFetched: false
};

const fetchPastPaymentsInfo = createAsyncThunk<
    GetPastPaymentsInfoResponse,
    { pageNumber: number, perPageRecords: number, isSmallScreenRequest: boolean },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'paymentsInfo/GetPastPaymentsInfo',
    async (_, thunkApi) => {
        try {

            const response = await client.post(new GetPastPaymentsInfo({
                pageNumber: _.pageNumber,
                perPageRecords: thunkApi.getState().paymentsInfo.perPageRecords,
                isSmallScreenRequest: _.isSmallScreenRequest
            }));

            
            //Check for errors
            if (response.responseStatus)
                throw response;

            return response;

        } catch (e: unknown) {
            console.log(e);
            const response: GetPastPaymentsInfoResponse = e as GetPastPaymentsInfoResponse;
            if (response?.responseStatus?.errorCode?.toLowerCase() === "unauthorized")
                thunkApi.dispatch(accountActionCreators.accessDenied("You don't have access to this page."));

            return thunkApi.rejectWithValue(response?.responseStatus ? { errorMessage: "Unable to retrieve the Past Payments." } : { errorMessage: "Unknown error." });

        }
    }

)

const resetUpdateLoadState = createAction('paymentsInfo/resetUpdateLoadState');
const updatePastPaymentData = createAction<PastPaymentInfo[]>('paymentsInfo/updatePastPaymentData');
const updatePageNumber = createAction<number>('paymentsInfo/updatePageNumber');

const slice = createSlice({
    name: 'paymentsInfo',
    initialState: initialPaymentsInfo,
    reducers: {
        resetState: () => initialPaymentsInfo
    },
    extraReducers: builder => {
        builder
            .addCase(fetchPastPaymentsInfo.pending, (state: IPaymentsInfoState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchPastPaymentsInfo.fulfilled, (state: IPaymentsInfoState, { payload }) => {
                state.loadState = DataLoadState.Loaded
            //    state.loadState = DataLoadState.None
                //    state.manageDirectDepositInfo = payload;
                const pastPaymentsInfoResponse: GetPastPaymentsInfoResponse = payload as GetPastPaymentsInfoResponse;
                if (pastPaymentsInfoResponse &&
                    pastPaymentsInfoResponse.pastPaymentInfos && pastPaymentsInfoResponse.pastPaymentInfos.length > 0 &&
                    pastPaymentsInfoResponse.pastPaymentInfos.length > 0
                    && !state.allrecordsFetched) {
                    const nextPaymentsPageData = pastPaymentsInfoResponse.pastPaymentInfos;
                    const prevPayments = state.pastPaymentInfos;
                    state.pastPaymentInfos = [...prevPayments, ...nextPaymentsPageData];

                    //If current page number equals to the no of pages then allrecordsFetched
                    if (state.pageNumber-1 === pastPaymentsInfoResponse.pastPaymentInfos[0].nbrOfPages) {
                        state.allrecordsFetched = true;
                    }
                }
                else {
                    state.allrecordsFetched = true;
                }
            })
            .addCase(fetchPastPaymentsInfo.rejected, (state: IPaymentsInfoState, action) => {
                state.loadState = DataLoadState.Error;
                state.errorMessage = typeof action.payload?.errorMessage === 'string' ? action.payload?.errorMessage.toString() : "";
            })
            .addCase(getResetAction(), (_state, _action) => initialPaymentsInfo)
            .addCase(updatePastPaymentData, (state: IPaymentsInfoState, { payload }) => {
                state.pastPaymentInfos = payload
            })
            .addCase(updatePageNumber, (state: IPaymentsInfoState, { payload }) => {
                state.pageNumber = payload
            })
    }
})



const { resetState } = slice.actions;

export const paymentsInfoActionCreators = {
    resetUpdateLoadState,
    resetState,
    fetchPastPaymentsInfo,
    updatePastPaymentData,
    updatePageNumber
};

export const paymentsInfoReducer = slice.reducer;