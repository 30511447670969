import React, { ReactElement } from "react";

export default function ProjectionGaugeDefs(): ReactElement {
    return (
        <svg width="0" height="0" style={{padding: '0', margin: '0'}}>
            <defs>
                <linearGradient id="under">
                    <stop offset="14.6%" stopColor="#E68B29" />
                    <stop offset="81.98%" stopColor="#F1B576" />
                </linearGradient>
                <linearGradient id="over">
                    <stop offset="15.18%" stopColor="#50A167" />
                    <stop offset="81.16%" stopColor="#81D699" />
                </linearGradient>
            </defs>
        </svg>
    );
}