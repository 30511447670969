import { IDisplayPageState } from "../store/DisplayPageStore";

const showSummary = function (displayPage?: IDisplayPageState): boolean {

    if (!displayPage)
        return false;

    let pageCount = 0

    if (displayPage.showRetirement) pageCount++;
    if (displayPage.showHealth) pageCount++;
    if (displayPage.showLifeAndDisability) pageCount++;
    if (displayPage.showWellBeing) pageCount++;

    return pageCount > 1;

};
export { showSummary };