import * as React from "react";
import { formatMoney } from "../../../functions/formatMoney";
import { PlanGroupOptionDetails } from "../../../Gateway.dtos";
import { FormatDollar } from "../../FormatDollar";
import { FormatUnits } from "../../FormatUnits";

export interface ByPlanGroupOptionProps { planList: PlanGroupOptionDetails, i: number }
function PlanGridOptionGridRow(props: ByPlanGroupOptionProps): React.ReactElement {
    const planList = props.planList;

    return (
        <>
            <tr>
                <td className="border-left">
                    <span className="print-legends" style={{ "backgroundColor": planList.color, "width": "20px", "height": "10px", "border": "solid 1px black", "display": "inline-block" }}></span>
                </td>
                <td className="text-left border-right">
                    <a className="secondary" style={{ whiteSpace: "nowrap" }} title={planList.tooltip} href={planList.link} target="_blank" rel="noreferrer">
                        {planList.name}
                    </a>
                </td>
                <td className="text-right border-right">
                    <FormatDollar amount={planList.price} fractionDigits={4}/>
                </td>
                <td className="text-right border-right">
                    <FormatUnits units= {planList.units} />
                </td>
                <td className="text-right border-right">
                    {formatMoney(planList.balance, true)}
                </td>
                <td className="text-right border-right">
                    {planList.ratio}%
                </td>
            </tr>
        </>
    );
}

export { PlanGridOptionGridRow };