import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Layout from './components/Layout';
import BenefitsSummary from './components/pages/Summary';
import RetirementAndInvestments from './components/pages/Retirement';
import HealthPage from './components/pages/Health';
import HealthFlexBenefitsPage from './components/pages/HealthFlexBenefits';
import LifeAndDisability from './components/pages/LifeAndDisability';
import WellBeingPage from './components/pages/WellBeing';
import Logoff from './components/pages/Logoff';
import Login from './components/pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { JsonApiClient, JsonServiceClient } from '@servicestack/client';
import AccessDenied from './components/pages/AccessDenied';
import Success from './components/pages/Success';
import { BaOutboundSso } from './components/pages/BaOutboundSso'
import AccountManagement from './components/pages/AccountManagement';
import { useEffect, useState } from 'react';
import { bindActionCreators} from 'redux';
import { activityActionCreators } from './store/ActivityStore';
import { connect, useDispatch } from 'react-redux';
import { AppState } from './store/configureStore';
import { loginHelpCreators } from './store/LoginHelpStore';
import TwoFactorChallenge from './components/pages/TwoFactorChallenge';
import ScrollToTop from './components/ScrollToTop';
import ProjectionGaugeDefs from './components/ProjectionGaugeDefs';
import { AppDispatch } from '.';
import BapInboundSso from './components/pages/BapInboundSso';
import PageNotFound from './components/PageNotFound';
import { Maintenance } from './components/Maintenance';
import SiteMap from './components/pages/SiteMap';
import { GatewayPath } from '@wespath/gateway-navigation'
import { accountActionCreators } from './store/AccountStore';
import BaCmsPage from './components/pages/BaCmsPage';
import { CmsRoutes } from './definitions/CmsRoutes';
import LsriOverview from './components/pages/Lsri/LsriOverview';
import LsriSocialSecurity from './components/pages/Lsri/LsriSocialSecurity';
import LsriMpp from './components/pages/Lsri/LsriMpp';
import LsriFunding from './components/pages/Lsri/LsriFunding';
import LsriBridge from './components/pages/Lsri/LsriBridge';
import LsriQlac from './components/pages/Lsri/LsriQlac';
import LsriReview from './components/pages/Lsri/LsriReview';
import LsriPaymentOptions from './components/pages/Lsri/LsriPaymentOptions';
import LsriBankInfo from './components/pages/Lsri/LsriBankInfo';
import LsriTaxOptions from './components/pages/Lsri/LsriTaxOptions';
import LsriVerify from './components/pages/Lsri/LsriVerify';
import LsriSummary from './components/pages/Lsri/LsriSummary';
import LsriSuccess from './components/pages/Lsri/LsriSuccess';
import SelfManagedRetirementIncome from './components/pages/SelfManagedRetirementIncome'
import MPPDistribution from './components/pages/MPPDistribution';
import { BAConvertedRoutes } from './definitions/BAConvertedRoutes';
import LifeStageInvestmentOptions from './components/pages/LifeStageInvestmentOptions';
import InvestmentsSummary from './components/pages/InvestmentsSummary';
import { LsriActionCreators } from './store/LsriStore';
import { DataLoadState } from './definitions/IEnumLoadableState';
import { LoginHelpAndRegistration } from './components/pages/LoginHelpAndRegistration';
import { RequestType } from './Gateway.dtos';
import ManageDirectDeposit from './components/pages/ManageDirectDeposit';
import UpdateBankInformation from './components/pages/UpdateBankInformation';
import PaymentsInfo from './components/pages/PaymentsInfo';
import { SplitClient, SplitFactoryProvider, SplitSdk } from '@splitsoftware/splitio-react';


export const client = new JsonServiceClient(process.env.REACT_APP_API_URL)

export const apiClient = JsonApiClient.create(process.env.REACT_APP_API_URL).apply(c => {
    c.basePath = '/api'
    c.headers = new Headers()
});

export const pgConfig = window.ParticipantGatewayConfig

client.requestFilter = req => {

    const contextToken = sessionStorage.getItem("PG.ContextToken");
    if (contextToken) {
        const initialHeaders: HeadersInit | undefined = req.headers
        const headers = new Headers(initialHeaders)
        headers.append("Wespath-Context-Token", contextToken)
        req.headers = headers
    }
}

apiClient.requestFilter = req => {

    const contextToken = sessionStorage.getItem("PG.ContextToken");
    if (contextToken) {
        const initialHeaders: HeadersInit | undefined = req.headers
        const headers = new Headers(initialHeaders)
        headers.append("Wespath-Context-Token", contextToken)
        req.headers = headers
    }
}

const splitConfig: SplitIO.IBrowserSettings = {
    core: {
        authorizationKey: pgConfig.SplitSdkKey,
        key: 'anonymous'
    },
    startup: {
        readyTimeout: 1.5
    }
}

export const splitFactory: SplitIO.ISDK = SplitSdk(splitConfig);

export const splitClient = splitFactory.client();


type TAppProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const NeedHelpPage = () => <LoginHelpAndRegistration initialPageType={RequestType.NEEDHELP} reset />;
const RegistrationPage = () => <LoginHelpAndRegistration initialPageType={RequestType.REGISTRATION} reset />;

const App = (props: TAppProps) => {
    const dispatch: AppDispatch = useDispatch();  

    const [splitUserId, setSplitUserId] = useState(splitConfig.core.key);

    const { pathname, logActivity, lsriLoadState, username } = props;
    useEffect(() => {
        if (pathname.toLowerCase() !== GatewayPath.Login.toLowerCase()) {
            logActivity();
        }
    }, [pathname, logActivity])

    useEffect(() => {
        if (username)
            setSplitUserId(username);
        else
            setSplitUserId('unknown');
    }, [username])

    const [previousPage, setPreviousPage] = useState('');
    const [currentPage, setCurrentPage] = useState('');
    useEffect(() => {
        setPreviousPage(currentPage);
        setCurrentPage(pathname);
    }, [pathname, setPreviousPage, currentPage, setCurrentPage])


    //Clear out load state of LSRI if user is coming to lsri wizard from somewhere else
    useEffect(() => {
        if (lsriLoadState === DataLoadState.Loaded && currentPage.toLowerCase().includes("/lsri") && !previousPage.toLowerCase().includes("/lsri"))
            dispatch(LsriActionCreators.deleteLsriElection({ deleteFromOracle: false }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousPage, currentPage])


    //Check for invalid page navigation back to LoginHelp
    const isLoginHelp = currentPage === GatewayPath.Registration || currentPage === GatewayPath.NeedHelp;
    if (!!previousPage && isLoginHelp && (
        previousPage !== GatewayPath.Login &&
        previousPage !== GatewayPath.Registration &&
        previousPage !== GatewayPath.NeedHelp &&
        previousPage !== GatewayPath.AccessDenied)
    ) {
        props.resetAccountState();
        props.backToLogin();        
    } 

    //Check that accountState is cleared when navigating away from NeedHelp
    if (previousPage === GatewayPath.NeedHelp && props.isAuthenticated && props.stepNum > 1 &&
        currentPage !== GatewayPath.Success && currentPage !== GatewayPath.NeedHelp) {
        props.resetAccountState();
        props.backToLogin();
    }


    return (
        <SplitFactoryProvider updateOnSdkUpdate config={splitConfig}>
            <SplitClient splitKey={splitUserId}>
                <Layout>
                    <Switch>
                        <Redirect exact from="/" to={GatewayPath.Login} />
                        <Redirect exact from="/index.html" to={GatewayPath.Login} />
                        <Route path={GatewayPath.Login} component={Login} />
                        <Route path={GatewayPath.Logoff} component={Logoff} />
                        <Route path={GatewayPath.Registration} component={RegistrationPage} />
                        <Route path={GatewayPath.NeedHelp} component={NeedHelpPage} />
                        <Route path={GatewayPath.AccessDenied} component={AccessDenied} />
                        <Route path={GatewayPath.BapInboundSso} component={BapInboundSso} />
                        <Route path={GatewayPath.Maintenance} component={Maintenance} />
                        <ProtectedRoute path={GatewayPath.Success} component={Success} />
                        <ProtectedRoute path={GatewayPath.BenefitsSummary} component={BenefitsSummary} />
                        <ProtectedRoute path={GatewayPath.Retirement} component={RetirementAndInvestments} />
                        <ProtectedRoute path={GatewayPath.Health} component={HealthPage} />
                        <ProtectedRoute path={GatewayPath.HealthFlexBenefits} component={HealthFlexBenefitsPage} />
                        <ProtectedRoute path={GatewayPath.LifeAndDisability} component={LifeAndDisability} />
                        <ProtectedRoute path={GatewayPath.WellBeing} component={WellBeingPage} />
                        <ProtectedRoute path={GatewayPath.AccountManagement} component={AccountManagement} />
                        <ProtectedRoute path={GatewayPath.Challenge} component={TwoFactorChallenge} />  
                        <ProtectedRoute path={GatewayPath.SiteMap} component={SiteMap} />


                        <ProtectedRoute path={GatewayPath.LsriOverview} component={LsriOverview} />
                        <ProtectedRoute path={GatewayPath.LsriSocialSecurity} component={LsriSocialSecurity} />
                        <ProtectedRoute path={GatewayPath.LsriMpp} component={LsriMpp} />
                        <ProtectedRoute path={GatewayPath.LsriFunding} component={LsriFunding} />
                        <ProtectedRoute path={GatewayPath.LsriBridge} component={LsriBridge} />
                        <ProtectedRoute path={GatewayPath.LsriQlac} component={LsriQlac} />
                        <ProtectedRoute path={GatewayPath.LsriReview} component={LsriReview} />
                        <ProtectedRoute path={GatewayPath.LsriPaymentOptions} component={LsriPaymentOptions} />
                        <ProtectedRoute path={GatewayPath.LsriBankInfo} component={LsriBankInfo} />
                        <ProtectedRoute path={GatewayPath.LsriTaxOptions} component={LsriTaxOptions} />
                        <ProtectedRoute path={GatewayPath.LsriVerify} component={LsriVerify} />
                        <ProtectedRoute path={GatewayPath.LsriSuccess} component={LsriSuccess} />
                        <ProtectedRoute path={GatewayPath.LsriSummary} component={LsriSummary} />
                        <ProtectedRoute path={`/BA/:page`} component={BaOutboundSso} />
                        <ProtectedRoute path={CmsRoutes.HelpWespath} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.FormsGeneralAllPlans} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesCRSP} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesRPGA} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesUMPIP} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesHorizon} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesCPP} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesCollins} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesUMPH} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ResourcesChaplains} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.HelpGettingStarted} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.HelpSaving} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.HelpInvesting} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.HelpPlanning} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.HelpRetiring} component={BaCmsPage} />

                        <ProtectedRoute path={CmsRoutes.LearnRetirementPlans} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.LearnHealthPlans} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.LarnWelfarePlans} component={BaCmsPage} />

                        <ProtectedRoute path={CmsRoutes.DistributionOptions} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.KeepingYourMoney} component={BaCmsPage} />
                        {/*<ProtectedRoute path={CmsRoutes.LifestageRetirementIncomePayments} component={BaCmsPage} />*/}
                        <ProtectedRoute path={CmsRoutes.LifestageExample1} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.SelfManagedRetirementIncomePayments} component={BaCmsPage} />

                        <ProtectedRoute path={CmsRoutes.PublicationGeneral} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.PublicationInvesting} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.PublicationPlanning} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.PublicationPlans} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.PublicationHark} component={BaCmsPage} />

                        <ProtectedRoute path={CmsRoutes.ExplorePersonalInformation} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ExploreBeneficiaries} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ExploreElectronicDelivery} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ExploreLifeStage} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ExploreContributions} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ExploreFinancialPlanning} component={BaCmsPage} />
                        <ProtectedRoute path={CmsRoutes.ExploreDistributions} component={BaCmsPage} />


                        <ProtectedRoute path={BAConvertedRoutes.SelfManagedRetirementIncome} component={SelfManagedRetirementIncome} />
                        <ProtectedRoute path={BAConvertedRoutes.MppDistribution} component={MPPDistribution} />
                        <ProtectedRoute path={BAConvertedRoutes.LifeStageInvestmentOptions} component={LifeStageInvestmentOptions} />
                        <ProtectedRoute path={BAConvertedRoutes.InvestmentsSummary} component={InvestmentsSummary} />
                        <ProtectedRoute path={GatewayPath.ManageDirectDeposit} component={ManageDirectDeposit} />
                        <ProtectedRoute path={GatewayPath.UpdateBankInformation} component={UpdateBankInformation} />
                        <ProtectedRoute path={GatewayPath.PaymentInfo} component={PaymentsInfo} />


                        <Route component={PageNotFound} />
                    </Switch>
                    <ScrollToTop />
                    <ProjectionGaugeDefs />
                </Layout>
            </SplitClient>
        </SplitFactoryProvider>
       );
}

function mapStateToProps(state: AppState) {
    const { pathname } = state.router.location;
    const { isAuthenticated,username } = state.account;
    const { stepNum } = state.loginHelp;
    const lsriLoadState = state.lsri.loadState
    return {
        pathname,
        isAuthenticated,
        stepNum,
        lsriLoadState,
        username
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { backToLogin } = loginHelpCreators;
    const resetAccountState = accountActionCreators.resetState;
    return bindActionCreators({ ...activityActionCreators, backToLogin, resetAccountState }, dispatch);
    //return bindActionCreators({ ...activityActionCreators }, dispatch);
}



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

