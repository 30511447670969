import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { umLifeOptionsActionCreators } from '../../../store/UmLifeOptionsStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { GetUmLifeEligType, UmLifeEligType } from '../../../functions/getUmLifeEligibilityType';
import { AppDispatch } from '../../..';
import { PanelWithIcon } from '../../PanelWithIcon';
import { cmsActionCreators } from '../../../store/CmsStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';

export interface OwnProps { isSummary?: boolean }
type TUmLifeOptionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const UmLifeOptions = (props: TUmLifeOptionsProps) => {
    const { umLifeOptions } = props;

    const eligType = GetUmLifeEligType(umLifeOptions?.benefitClassCode);

    const showCard = eligType === UmLifeEligType.ELIGIBLE || eligType === UmLifeEligType.OPTIONAL;

    const cmsUMLifeOptionsMessage = props.cmsData.fragments?.find(f => f.name === "UMLifeOptionsMessage");
    const cmsUMLifeOptionsWithLinksMessage = props.cmsData.fragments?.find(f => f.name === "UMLifeOptionsWithLinksMessage");

    const sharedName = "UMLifeOptions";
    const sharedText = "Our records indicate you are currently eligible for UMLifeOptions coverage.";

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchUmLifeOptions();
        }
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    if (!showCard) {
        return (<></>);
    }
    else if (props.isSummary) {
        return (
             <PanelWithIcon icon={faUserPlus} iconColor="lifedis-color">

                <div className="panelHeader">
                    {sharedName}
                </div>

                <div>
                    <strong>{sharedText}</strong>
                </div>

                {cmsUMLifeOptionsMessage ? <div dangerouslySetInnerHTML={{ __html: cmsUMLifeOptionsMessage.html }} /> : <LoadingSkeleton />}

            </PanelWithIcon>
        );
    }
    else {
        return (
            <Card className="pageCard secondary">

                <Card.Title>
                    <FontAwesomeIcon icon={faUserPlus} className="lifedis-color smaller" />
                    {sharedName}
                </Card.Title>

                <Card.Subtitle>{sharedText}</Card.Subtitle>

                <Card.Body>

                    {cmsUMLifeOptionsWithLinksMessage && <p className="thin" dangerouslySetInnerHTML={{ __html: cmsUMLifeOptionsWithLinksMessage.html }} />}

                </Card.Body>
            </Card>
        );
    }
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...state.umLifeOptions,
        cmsData: state.cms.lifeanddisability,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...umLifeOptionsActionCreators,
        fetchCmsData: cmsActionCreators.fetchLifeAndDisabilityCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UmLifeOptions);