import { getDay } from "date-fns/esm"; 

const getBusinessPaymentDate = (date: Date, holidayList: string[] | undefined): string => {
    if (!isHoliday(date, holidayList) && !isWeekend(date)) {
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }
    else {
        return getBusinessPaymentDate(new Date(date.setDate(date.getDate() + 1)), holidayList);
    }
}

const isWeekend = (date: Date) => {
    const day = getDay(date);
    return !(day !== 0 && day !== 6);
};

const isHoliday = (date: Date, holidayList: string[] | undefined) => {
    if (holidayList && holidayList.length > 0) {
        const usHolidays = [...holidayList];
        const isFound = usHolidays.find(function (dateItem) { return dateItem === date.toDateString() });

        if (isFound != null) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        //No Holiday List 
        return false;
    }
}

export { getBusinessPaymentDate }