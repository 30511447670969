import { FedMaritalStatus } from "../Gateway.dtos";

const getFedMarritalStatus = function (marritalStatus: FedMaritalStatus | undefined): string {
    switch (marritalStatus) {
        case FedMaritalStatus.Single:
            return "Single or Married filing separately";
        case FedMaritalStatus.Married:
            return "Married filing jointly or Qualifying widow(er)";
        case FedMaritalStatus.HeadOfHousehold:
            return "Head of household";
        default:
            return "Single or Married filing separately";
    }
}

export { getFedMarritalStatus }