import React, { ReactElement } from "react";
import { Row, Col, Alert, Button } from "react-bootstrap";
import { FormatPercent } from "./FormatPercent";
import { FormatDollar } from "./FormatDollar";
import Skeleton from "react-loading-skeleton";
import BenefitsAccessSso from "./BenefitsAccessSso";

export interface AmountPanelProps {
    label: string
    balance?: string | number
    percent?: number
    minorText?: string
    footnote?: string
    isLoading?: boolean
    noBottomBorder?: boolean
    sideMsg?: string
    isErr?: boolean
    children?: unknown
    zeroAsDash?: boolean
    isPercent?: boolean
    showStatementsLink?: boolean
}
const AmountPanelSmall = (props: AmountPanelProps): ReactElement => {

    const isPercent = props.percent || props.isPercent;

    return (<>

        {props.isLoading && <LoadingSkeleton />}

        {!props.isLoading && <>

            <div className="smallLabel">{props.label}</div>

            {!props.isErr && <>
                <Row>
                    <Col className="summaryValue">
                        {isPercent && <FormatPercent percent={props.percent} showCaret />}

                        {!isPercent && <>
                            {typeof props.balance === 'string' ?
                                <p>{props.balance}</p>
                                :
                                <FormatDollar amount={props.balance} zeroAsDash={props.zeroAsDash} />
                            }
                        </>}
                </Col>

                    {props.sideMsg &&
                        <Col className="col-5 col-sm-6 col-md-12 ml-0 pt-1 ">
                            <div className="footnote amountPanelSmall" >
                                {props.sideMsg}
                            </div>
                        </Col>
                    }
                </Row>

                {props.showStatementsLink &&
                    <BenefitsAccessSso to={`/mybenefits_statements.aspx`}>
                        <Button className="pl-0" variant="link">View Statements</Button>
                    </BenefitsAccessSso>
                }
                
                {(props.footnote || props.children) &&
                    <Row>
                        <Col className="footnote amountPanelSmall">
                            {props.footnote} {props.children}
                        </Col>
                    </Row>
                }
            </>}
        </>}

        {props.isErr && <Alert className="mt-2" variant="danger">Unable to retrieve data for {props.label}</Alert>}

        <div className={`bottomBorder ${props.noBottomBorder ? "disabled" : ""}`} />

    </>);
}
export { AmountPanelSmall };

const LoadingSkeleton = () => (
    <div>
        <div><Skeleton width={'8em'} height={'1em'} /></div>
        <div><Skeleton width={'10em'} height={'1.5em'} /></div>
    </div>
);