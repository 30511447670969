import React, { useEffect, Fragment } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { GatewayPath } from '@wespath/gateway-navigation'
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { retirementMenuActionCreators } from '../../store/RetirementMenuStore';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { DataLoadState } from '../../definitions/IEnumLoadableState';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { showSummary } from '../../functions/showSummary';

type TSiteMapProps = ReturnType<typeof mapStateToProps>

const SiteMap = (props: TSiteMapProps) => {
    const { headerMenu, displayPage } = props;
    const { showRetirement, showHealth, showLifeAndDisability, showWellBeing } = displayPage;

    const dispatch = useDispatch();
    const isLoading = props.loadState !== DataLoadState.Loaded && props.loadState !== DataLoadState.Error

    useEffect(() => {
        if (stateNeedsLoading(props)) dispatch(retirementMenuActionCreators.fetchRetirementMenu());
    });

    useEffect(() => {
        document.title = "Benefits Access - SiteMap"
    }, []);

    const AllBenefits = () => (
        <Card className="pageCard siteMap">
            <Card.Title>
                All Benefits
            </Card.Title>

            <Row>
                <Col>
                    {showSummary(displayPage) && <Card.Subtitle><NavLink to={GatewayPath.BenefitsSummary}>Summary</NavLink></Card.Subtitle>}
                    {showRetirement && <Card.Subtitle><NavLink to={GatewayPath.Retirement}>Retirement</NavLink></Card.Subtitle>}
                    {showHealth && <Card.Subtitle><NavLink to={GatewayPath.Health}>Health</NavLink></Card.Subtitle>}
                    {showWellBeing && <Card.Subtitle><NavLink to={GatewayPath.WellBeing}>Well-being</NavLink></Card.Subtitle>}
                    {showLifeAndDisability && <Card.Subtitle><NavLink to={GatewayPath.LifeAndDisability}>Life & Disability</NavLink></Card.Subtitle>}
                </Col>
            </Row>

        </Card>
    )

    const RetirementBenefits = () => (
        <Card className="pageCard siteMap mt-5">

            <Card.Title>Retirement Benefits</Card.Title>

            <Row>
                <Col className="col-12 col-md-4">
                    <RetSection text="Accounts" />
                    <RetSection text="Distributions" />
                </Col>
                <Col className="col-12 col-md-4">
                    <RetSection text="Learn" />
                </Col>
                <Col className="col-12 col-md-4">
                    <RetSection text="Profile" />
                    <RetSection text="Help" />
                </Col>
            </Row>

        </Card>
    )

    const RetSection = (props: { text: string }) => (<>
        {
            //TODO: fix eslint disable
            // eslint-disable-next-line
            headerMenu?.menus?.map((menu, i) => (menu.text === props.text && <Fragment key={i}>

                <Card.Subtitle className="pt-4 pb-3">
                    {menu.text}
                </Card.Subtitle>

                {menu.children?.map((menuItem, j) => (
                    <Card.Body key={j} className={`${!menuItem.children ? "noChildren" : ""} ${j === 0 && !menuItem.children ? "addBottomPadding" : ""}`}>
                        {menuItem.link ?
                            <MenuLink gatewayLink={menuItem.gatewayLink} externalLink={menuItem.externalLink} link={menuItem.link} text={menuItem.text} />
                            :
                            <>
                                <Card.Subtitle className="small">
                                    {menuItem.text}
                                </Card.Subtitle>

                                {menuItem.children?.map((menuItemChild, x) => (
                                    <MenuLink key={x} gatewayLink={menuItemChild.gatewayLink} externalLink={menuItemChild.externalLink} link={menuItemChild.link} text={menuItemChild.text} />
                                ))}
                            </>
                        }
                    </Card.Body>
                ))}

            </Fragment>))}
    </>);

    return (
        <>
            <Container>
                <Row className="justify-content-center">
                    <Col className="col-12">
                        <Card className="fullPageCard" style={{ paddingBottom: '5rem', minHeight: '20rem' }}>
                            {isLoading && <LoadingSkeletonFullpage />}
                            {!isLoading &&
                                <>

                                    <AllBenefits />

                                    {showRetirement && <RetirementBenefits />}

                                </>
                            }
                        </Card>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

function mapStateToProps(state: AppState) {
    return {
        ...state.retirementMenu,
        displayPage: state.displayPage
    }
}

//function mapDispatchToProps(dispatch: AppDispatch) {
//    return {}
//}

export default connect(
    mapStateToProps,
    null
)(SiteMap);


const MenuLink = (props: { gatewayLink: boolean, externalLink: boolean, link: string, text: string }) => (

    <div>
        {props.gatewayLink
            ? <NavLink to={props.link}>{props.text}</NavLink>
            : (props.externalLink
                    ? <a href={props.link} target="_blank" rel="noopener noreferrer">{props.text}</a >
                    : <a href={props.link}>{props.text}</a>
                )
        }
    </div>
);