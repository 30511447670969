export enum BAConvertedRoutes {
    SelfManagedRetirementIncome = "/selfManagedRetirementIncome",
    MppDistribution = "/mppDistribution",
    LifeStageInvestmentOptions = "/lifeStageInvestmentOptions",
    InvestmentsSummary = "/investmentsSummary"
}

export const BAConvertedCmsRoutePaths = [
    { route: BAConvertedRoutes.SelfManagedRetirementIncome, cmsPath: "/RightMarginContent/Distributions/SelfManagedRetirementIncome"},
    { route: BAConvertedRoutes.MppDistribution, cmsPath: "/RightMarginContent/Distributions/MppDistribution" },
    { route: BAConvertedRoutes.LifeStageInvestmentOptions, cmsPath: "/RightMarginContent/Accounts/LifeStageInvestmentOptions" },
    { route: BAConvertedRoutes.InvestmentsSummary, cmsPath: "/RightMarginContent/Accounts/InvestmentsSummary" }
]