import React from 'react';
import up from '../images/Up.png';
import down from '../images/Down.png';
import noChange from '../images/NoChange.png';

const FormatPercent = (props: { percent?: number, showCaret?: boolean }) : JSX.Element => {
    const { percent, showCaret } = props;

    const percentStr = percent?.toFixed(1).toString();

    function imgSrc() {

        if (percent === undefined || percent === 0)
            return noChange;
        else if (percent > 0)
            return up;
        else if (percent < 0)
            return down;

    }

    //Show "-" if the value is undefined
    return (<>
        {percent === undefined ? "-" : <>
            {percentStr}% {showCaret && <img src={imgSrc()} id="percentCaret" alt="Percent Caret" />}
        </>}
    </>);
}
export { FormatPercent };