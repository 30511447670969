import React, { useEffect, useRef, useState } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';
import { ServiceProvider } from '../../../Gateway.dtos';
import { activityActionCreators } from '../../../store/ActivityStore';
import parse from "date-fns/parse";
import format from "date-fns/format";
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';

type TInformedChoiceProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const InformedChoice = (props: TInformedChoiceProps) => {
    const { username, hasClaim_HealthAccess } = props;
        
    const enrollmentDate = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.enrollmentDate ?? '10/30/2024')
    const enrollmentDateFormatted = format(parse(enrollmentDate, "MM/d/yyyy", new Date()), "MMMM d, yyyy")

    const preEnrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showPreEnrollmentBanner ?? false)
    const jvLink = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.jvLink ?? "")

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProviderBS = ServiceProvider.BusinesSolver;

    const cmsIsLoading = props.cmsData.loadState !== DataLoadState.Loaded
    const cmsInformedChoiceContent = props.cmsData.fragments?.find(f => f.name === "InformedChoiceContent")?.html.replace("$alexLink", jvLink) ?? ''
    const cmsInformedChoiceHelpMessage = props.cmsData.fragments?.find(f => f.name === "InformedChoiceHelpMessage")?.html ?? ''
    
    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const [serviceProvider, setServiceProvider] = useState({} as ServiceProvider);
    const [ssoClicked, setSsoClicked] = useState(false);

    useEffect(() => {
        if (ssoClicked) {
            setSsoClicked(false)
            handleSSO()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssoClicked]);

    const handleSSO = () => { 
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }

    type LinkProps = {
        children?: React.ReactNode
    }
    const BenefitSolverSsoButton: React.FC = ({ children }: LinkProps) =>
        <Button
            onClick={() => { setServiceProvider(serviceProviderBS); setSsoClicked(true) }}
            disabled={!hasClaim_HealthAccess}
            className="claimControlled"
        >
            {children}
        </Button>

    return (<>

        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faCogs} className="health-color smaller" />
                Make an Informed Choice
            </Card.Title>
            <Card.Body style={{ paddingBottom:'0px'}}>


                {cmsIsLoading ? <LoadingSkeleton /> : 
                    <p className="thin m-0" dangerouslySetInnerHTML={{ __html: cmsInformedChoiceContent }} />
                }

                {preEnrollmentPeriod &&
                    <p className="thin"><strong>Come back after {enrollmentDateFormatted} to enroll.</strong></p>
                }   
                
                
                {<Row className="mb-3">
                    <Col className="col-6">
                        {preEnrollmentPeriod ? <Button variant="disable" style={{ marginRight: '100px' }}>Enroll</Button>
                            : <BenefitSolverSsoButton>Enroll</BenefitSolverSsoButton>
                        }
                    </Col>
                </Row>}

                <p className="thin" dangerouslySetInnerHTML={{ __html: cmsInformedChoiceHelpMessage }} />

            </Card.Body>
        </Card>
        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
        <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer="b" />
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        username: state.account.username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InformedChoice);