import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const FormatDollar = (props: { amount?: number, amountStr?: string, noCents?: boolean, negativeInItalics?: boolean, showCaret?: boolean, zeroAsDash?: boolean, fractionDigits?: number }) : JSX.Element => {
    const { amount, amountStr, negativeInItalics, noCents, showCaret, zeroAsDash, fractionDigits } = props;

    const maxFractionDigits = fractionDigits ? fractionDigits : 2;

    //Get the populated amount
    const dollar = amountStr ? Number(amountStr) : amount;

    //Check if it is valid
    if (dollar === undefined) return <span />;

    if (zeroAsDash && amount === 0) return <span>—</span>;

    //Convert to formatted dollar string, default is to display decimals
    const formattedAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: (noCents ? 0 : maxFractionDigits), minimumFractionDigits: (noCents ? 0 : maxFractionDigits) }).format(dollar);

    //Add formatting
    return (
        <span className="text-nowrap" style={{ fontStyle: (negativeInItalics && dollar < 0 ? "italic" : "") }}>
            {formattedAmount} {showCaret && dollar !== 0 && <FontAwesomeIcon icon={dollar > 0 ? faCaretUp : faCaretDown} />}
        </span>
    );
}
export { FormatDollar };