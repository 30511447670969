import { AppState } from "./configureStore";
import { GetLifeStageInvestmentOptionsResponse, GetLifeStageInvestmentOptions } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { accountActionCreators } from "./AccountStore";

interface Error {
    errorMessage: string | unknown
}

export interface ILifeStageInvestmentOptionsState extends IEnumLoadableState {
    errorMessage: string | undefined | unknown;
    lifeStageInvestmentOptions: GetLifeStageInvestmentOptionsResponse
}

export const initialLifeStageInvestmentOptions: ILifeStageInvestmentOptionsState = {
    lifeStageInvestmentOptions: {} as GetLifeStageInvestmentOptionsResponse,
    loadState: DataLoadState.None,
    errorMessage: ""
};

const fetchLifeStageInvestmentOptions = createAsyncThunk<
    GetLifeStageInvestmentOptionsResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'lifeStageInvestmentOptions/fetchLifeStageInvestmentOptions',
    async (_, thunkApi) => {
        try {

            const lifeStageInvestmentOptions = await client.post(new GetLifeStageInvestmentOptions());

            if (lifeStageInvestmentOptions.responseStatus && lifeStageInvestmentOptions.responseStatus?.message) {
                throw lifeStageInvestmentOptions.responseStatus?.message;
            }

            return lifeStageInvestmentOptions;

        } catch (e: unknown) {
            const response: GetLifeStageInvestmentOptionsResponse = e as GetLifeStageInvestmentOptionsResponse;
            if (response?.responseStatus?.errorCode?.toLowerCase() === "unauthorized")
                thunkApi.dispatch(accountActionCreators.accessDenied("You don't have access to this page."));

            return thunkApi.rejectWithValue(response?.responseStatus ? { errorMessage: response.responseStatus.message } : { errorMessage: "Unknown error." });
        }
    }

)

const slice = createSlice({
    name: 'lifeStageInvestmentOptions',
    initialState: initialLifeStageInvestmentOptions,
    reducers: {
        resetState: () => initialLifeStageInvestmentOptions
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLifeStageInvestmentOptions.pending, (state: ILifeStageInvestmentOptionsState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchLifeStageInvestmentOptions.fulfilled, (state: ILifeStageInvestmentOptionsState, { payload }) => {
                state.loadState = DataLoadState.Loaded
                state.lifeStageInvestmentOptions = payload;
            })
            .addCase(fetchLifeStageInvestmentOptions.rejected, (state: ILifeStageInvestmentOptionsState, action) => {
                state.loadState = DataLoadState.Error;
                state.errorMessage = action.payload?.errorMessage;
            })
            .addCase(getResetAction(), (_state, _action) => initialLifeStageInvestmentOptions)
    }
})

const { resetState } = slice.actions;

export const lifeStageInvestmentOptionsActionCreators = {
    fetchLifeStageInvestmentOptions,
    resetState
};

export const lifeStageInvestmentOptionsReducer = slice.reducer;