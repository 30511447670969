import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Row, Col, Card, Button, Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Health } from '../../definitions/Claims';
import { PlanElection, PlanType, ServiceProvider } from '../../Gateway.dtos';
import { AppState } from '../../store/configureStore';
import { SAMLSsoModal } from '../SAMLSsoModal';
import RedBagImg from '../../images/RedBag.png';
import StethoscopeImg from '../../images/Stethoscope.png';
import { useWindowDimensions } from '@wespath/gateway-navigation';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'
import { activityActionCreators } from '../../store/ActivityStore';
import { AppDispatch } from '../..';
import parse from "date-fns/parse";
import format from "date-fns/format";


type EnrollmentBannerProps = {
    children?: React.ReactNode,
    customClass?: string
}

const EnrollmentBanner = ({
    children,
    customClass
}: EnrollmentBannerProps): ReactElement => {

    const dispatch: AppDispatch = useDispatch(); 

    const preEnrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showPreEnrollmentBanner ?? false)
    const enrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showEnrollmentBanner ?? false)
    const nextYearEligible = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.nextYearEligible ?? false)
    const enrollmentEndDate = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.enrollmentEndDate ?? '12/31/2000')
    const enrollmentEndDateFormatted = format(parse(enrollmentEndDate, "MM/dd/yyyy", new Date()), "MMMM d")
    const enrollmentEndDateFormattedTag = format(parse(enrollmentEndDate, "MM/dd/yyyy", new Date()), "do").replace(format(parse(enrollmentEndDate, "MM/dd/yyyy", new Date()), "d"), "")
    const jvLink = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.jvLink ?? "")

    const { isSmallScreen } = useWindowDimensions()

    const serviceProvider = ServiceProvider.BusinesSolver
    const referrer = 'b'

    const formRef = React.useRef<HTMLFormElement>(null);

    const nextYear = Number(format(parse(enrollmentEndDate, "MM/dd/yyyy", new Date()), "yyyy")) + 1

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    const planElections = useSelector((state: AppState) => state.healthPlans?.healthPlans?.planElections ?? [] as PlanElection[])
    const hasHSA = useSelector((state: AppState) => state.hsa.hsa?.healthSavingsAccounts?.length > 0)
    const username = useSelector((state: AppState) => state.account.username)
    const hasClaim_HealthAccess = useSelector((state: AppState) => state.claims.claims?.claims?.find(c => c === Health.Access))

    const hasMedical = !!planElections?.find(p => p.type === PlanType.MEDICAL);
    const hasPrescription = !!planElections?.find(p => p.type === PlanType.PHARMACY);
    const hasDental = !!planElections?.find(p => p.type === PlanType.DENTAL);
    const hasVision = !!planElections?.find(p => p.type === PlanType.VISION);

    const enrollmentEligible = hasHSA || hasMedical || hasDental || hasVision || hasPrescription || nextYearEligible;

    const handleSSO = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }
    type LinkProps = {
        children?: React.ReactNode
    }
    const BenefitSolverSsoLink: React.FC = ({ children }: LinkProps) =>
        <Button
            className={`${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
            style={isSmallScreen ? { width: 'inherit' } : {}}
            onClick={handleSSO}
            disabled={!hasClaim_HealthAccess}
            >
                {children}
        </Button>

    const BannerImg = () => <Image
        src={preEnrollmentPeriod
            ? StethoscopeImg
            : RedBagImg}
        style={{ maxWidth: '186px', borderRadius: '10px', border: preEnrollmentPeriod ? '1px solid #D1D2D4' : 'none' }}
    />

    const BannerContent = () => <div style={{ maxWidth: '610px' }}>
        <h2 style={{ color: preEnrollmentPeriod  ? "black" : "white"}}>
            {preEnrollmentPeriod
                ? 'HealthFlex Annual Election Is Coming'
                : `Select Your ${nextYear} HealthFlex Benefits Now`
            }
        </h2>

        <p style={{ color: preEnrollmentPeriod ? "black" : "white" }}>
            {preEnrollmentPeriod
                ? 'Explore your health plan options now.'
                : <>Now through {enrollmentEndDateFormatted}<sup>{enrollmentEndDateFormattedTag}</sup></>}
        </p>

        <div className={isSmallScreen ? "text-center mt-4" : "text-left mr-3"}>
            {preEnrollmentPeriod
                ? <a href="/HealthFlexBenefits"><Button variant="tertiary" style={isSmallScreen ? {width: 'inherit'} : {}}>Learn More</Button></a>
                : <BenefitSolverSsoLink>Get Started</BenefitSolverSsoLink>}
        </div>
        
    </div>

    const ContentBody: React.FC = () => <Row className='pb-3'>


        {isSmallScreen
            ? <div className="my-4  text-center">
                <Col className="col-12 mb-4">
                    <BannerImg />
                </Col>
                <Col>
                    <BannerContent />
                </Col>
            </div>
            : <Col>
                <table style={{ width: '100%'}}>
                    <tr>
                        <td>
                            <BannerContent />
                        </td>
                        <td className="text-right">
                            <BannerImg />
                        </td>
                    </tr>
                </table>
            </Col>
        }

        

    </Row>

    return <div className={`banner ${customClass ?? ''}`}>
        {enrollmentEligible && (preEnrollmentPeriod || enrollmentPeriod) && <>
            <Card className="pt-3 mb-2 shadow-sm"
                style={{ border: '1px solid #D1D2D4', borderRadius: '10px', backgroundColor: preEnrollmentPeriod ? '#F7F7F7' : '#E68B29', maxWidth: isSmallScreen ? '374px' : '900px' }}
            >
                <Card.Body className="pb-0">
                    <ContentBody />
                </Card.Body>
            </Card>
            <SamlSelfPostForm ref={formRef} provider={serviceProvider} source={referrer} />
            <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer={referrer} />
            
        </>}

        {children}
    </div>
}

export { EnrollmentBanner }