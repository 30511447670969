import * as React from "react";
import { VictoryPie } from "victory";
import { PlanGroupOptionDetails } from "../../../Gateway.dtos";

export interface InvestmentCurrentAllocationGraphsProps { planGroupOption: PlanGroupOptionDetails[] }
const InvestmentCurrentAllocationGraphs = function (props: InvestmentCurrentAllocationGraphsProps): React.ReactElement {
    const planGroupOption = props.planGroupOption;

    function pieData(planObj: PlanGroupOptionDetails[], dataToRet: string) {

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const retArr: any[] = []
        planObj.forEach(plan => {
            switch (dataToRet.toUpperCase()) {
                case "XY":
                    retArr.push({
                        x: plan.name + " : " + plan.ratio + "%",
                        y: parseFloat(plan.ratio.toString())
                    });
                    break;
                case "COLORS":
                    retArr.push(plan.color);
                    break;
            }
        });

        return retArr
    }

    return (
        <>
            <VictoryPie
                data={pieData(planGroupOption, "xy")}
                colorScale={pieData(planGroupOption, "colors")}
                labels={() => ""}
            />
        </>
    );
}
export { InvestmentCurrentAllocationGraphs };