import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faExclamationTriangle, faTimesCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

enum MessageTypes {
    "Info",
    "Error",
    "Warning",
    "Other"
}

export interface MessagePopupProps { showPopup: boolean; messageType: MessageTypes; popupTitle: string; okButtonText: string; children: any; resetPopupFlag: any; showCancelButton?: boolean; cancelButtonText?: string; cancelAsLink?: boolean; onOkClick?: any; onCancelClick?: any }
const MessagePopup = function (props: MessagePopupProps) {
    const [showErrorPopup, setshowErrorPopup] = useState(props.showPopup);
    const displayCancelButton = props.showCancelButton ?? false;
    const cancelButtonText = props.cancelButtonText ?? "Cancel";
    const cancelButtonAsLink = props.cancelAsLink ?? false;


    useEffect(
        () => (
            setshowErrorPopup(props.showPopup)
        ),
        [props.showPopup]
    );

    const getClassName = () => {
        let clsName: string;

        switch (props.messageType) {
            case MessageTypes.Error:
                clsName = "fa-lg text-danger mr-1";
                break;
            case MessageTypes.Warning:
                clsName = "fa-lg text-warning mr-1";
                break;
            case MessageTypes.Info:
                clsName = "fa-lg text-info mr-1";
                break;
            case MessageTypes.Other:
                clsName = "fa-lg mr-1";
                break;
        }
        return clsName;

    }

    const getIcon = () => {
        let iconToReturn: IconDefinition = faInfoCircle;

        switch (props.messageType) {
            case MessageTypes.Error:
                iconToReturn = faTimesCircle;
                break;
            case MessageTypes.Warning:
                iconToReturn = faExclamationTriangle;
                break;
            case MessageTypes.Info:
                iconToReturn = faInfoCircle;
                break;
        }

        return iconToReturn;
    }

    const clickOk = () => {
        if (props.onOkClick) {
            props.onOkClick();
        }
        props.resetPopupFlag(false);
        setshowErrorPopup(false);
    }

    return (
        <>
            <Modal show={showErrorPopup} backdrop="static" centered >
                {props.messageType !== MessageTypes.Other &&
                    <Modal.Header>
                        <Modal.Title>
                            <FontAwesomeIcon icon={getIcon()} className={getClassName()} /> {props.popupTitle}
                        </Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body className="text-justify">
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-block-xs-only float-right" variant="primary" onClick={() => { clickOk() }}>{props.okButtonText}</Button>
                    {displayCancelButton && <Button className="btn-block-xs-only" variant={cancelButtonAsLink ? "link" : "secondary"}
                        onClick={() =>
                            {
                                props.resetPopupFlag(false);
                                setshowErrorPopup(false);
                                if (props.onCancelClick) props.onCancelClick();
                            }
                        }>{cancelButtonText}</Button>}
                </Modal.Footer>
            </Modal>
        </>
    );

}

export { MessagePopup, MessageTypes };