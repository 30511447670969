import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { UnlockResponse, Unlock } from "../Gateway.dtos";
import { AppDispatch } from "..";
import { accountActionCreators } from "./AccountStore";

export interface IUnlockState extends ILoadableState {
    unlock: UnlockResponse
}

export const initialUnlockState: IUnlockState = {
    unlock: {} as UnlockResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'unlock',
    initialState: {} as IUnlockState,
    reducers: {
        resetState: () => {
            return {
                ...initialUnlockState
            }
        },
        requestUnlock: (state: IUnlockState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveUnlock: (state: IUnlockState, action: PayloadAction<{ unlock: UnlockResponse }>) => {
            const { unlock } = action.payload;
            return {
                ...state,
                unlock,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveUnlockError: (state: IUnlockState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }

    }
})

const { resetState, requestUnlock, receiveUnlock, receiveUnlockError } = slice.actions;

function performUnlock() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoading } = getState().unlock;
        if (isLoading) {
            return;
        }

        dispatch(requestUnlock());

        try {

            const unlock = await client.post(new Unlock());

            dispatch(receiveUnlock({ unlock }));

            if (!unlock.responseStatus)
                dispatch(accountActionCreators.setIsLocked({isLocked: false}));

        } catch (e: unknown) {
            dispatch(receiveUnlockError());
            console.log(e);
        }



    }
}

export const unlockActionCreators = {
    performUnlock,
    resetState
};

export const unlockReducer = slice.reducer;
