import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { displayPageActionCreators } from "./DisplayPageStore";
import { client } from "../App";
import { GetContributionPct, GetContributionPctResponse } from "../Gateway.dtos";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IContributionsState extends ILoadableState {
    contributions: GetContributionPctResponse
}

export const initialContributionsState: IContributionsState = {
    contributions: {} as GetContributionPctResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'contributions',
    initialState: {} as IContributionsState,
    reducers: {
        resetState: () => {
            return {
                ...initialContributionsState
            }
        },
        requestContributions: (state: IContributionsState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveContributions: (state: IContributionsState, action: PayloadAction<{ contributions: GetContributionPctResponse }>) => {
            const { contributions } = action.payload;
            return {
                ...state,
                contributions,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveContributionsError: (state: IContributionsState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialContributionsState)
    }
})

const { resetState, requestContributions, receiveContributions, receiveContributionsError } = slice.actions;

function fetchContributions() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().contributions;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestContributions());

        try {

            const contributions = await client.post(new GetContributionPct());

            dispatch(receiveContributions({ contributions }));

            if (contributions.responseStatus === undefined)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receiveContributionsError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }



    }
}

export const contributionsActionCreators = {
    fetchContributions,
    resetState
};

export const contributionsReducer = slice.reducer;
