import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../';
import { cmsActionCreators } from '../../../../store/CmsStore';
import { AppState } from '../../../../store/configureStore';

export const LsriFlowChartDisclaimer = (): React.ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Shared"

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const flowChartDisclaimerCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/FlowChartDisclaimer`)?.html ?? '';
    

    return (
        <div className="mt-5 small" dangerouslySetInnerHTML={{__html: flowChartDisclaimerCms}} />
    );
};


