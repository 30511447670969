import React, { useEffect } from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../';
import { stateNeedsLoading } from '../../../../functions/stateNeedsLoading';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { LoadingSkeleton } from '../../../LoadingSkeleton';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { useWindowDimensions } from '@wespath/gateway-navigation';
import LsriDataTable from './LsriDataTable';
import { getLsriChartNodes, getMaxSsAge, getUserMatrix, LsriBarType, LsriChartDataPoint, LsriChartNode, LsriStepType } from './LsriFunctions';
import { formatMoney } from '../../../../functions/formatMoney';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

interface IPoint {
    x: number
    y: number
}

const LsriFlowChart = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isMediumScreen, isSmallScreen, isLargeScreenOrSmaller, width } = useWindowDimensions();

    const { lsri } = props;
    const retAge = lsri?.lsriInfo?.oracleLsriElection?.socialSecurity?.retAge
    const lsriChartNodesCustom = getLsriChartNodes(lsri, retAge, false, true, true);
    const lsriChartNodesOriginal = getLsriChartNodes(lsri, retAge, true, true, true);
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const isRecalculating = lsri.calculationLoadState === DataLoadState.Loading || lsri.resetLoadState === DataLoadState.Loading
    const isLoadingAfterRecalc = lsri.calculationLoadState === DataLoadState.Loaded || lsri.resetLoadState === DataLoadState.Loaded
    const smallSize = isSmallScreen || isMediumScreen
    const showCompare = lsri.showCompare

    useEffect(() => {
        if (stateNeedsLoading(lsri))
            dispatch(LsriActionCreators.fetchLsriInfo({}));
    });

    function calculateChartHeight() {
        if (isSmallScreen)
            return width > 600 ? width / 2 : width / 1.5
        else if (isMediumScreen)
            return 230
        else if (isLargeScreenOrSmaller)
            return 250
        else
            return 300
    }

    function calculateChartWidth() {
        if (isSmallScreen)
            return width > 600 ? 600 : width
        else if (isMediumScreen)
            return 480
        else if (width <= 1340)
            return 670
        else
            return 770
    }

    const chartHeight = calculateChartHeight();
    const labelHeight = smallSize ? 60 : 50;
    const viewBoxWidth = calculateChartWidth();
    const viewBoxHeight = chartHeight + labelHeight
    const arrowSize = 6;
    const maxAmt = calcMaxAmt(lsriChartNodesCustom) + (isSmallScreen ? 2000 : 500);  //Add a little extra space at the top for labels
    const scale = chartHeight / maxAmt

    const barWidth = smallSize ? 80 : 100
    const growthWidth = viewBoxWidth * (smallSize ? .10 : .15)

    const fadeInDuration = 0.4
    const fadeOutDuration = 0.2
    //const animationSteps = 2



    const hasSocSecStep = lsriChartNodesCustom.findIndex((n) => n.stepType === LsriStepType.SocialSecurity) > -1
    const socSecMaxAge = getMaxSsAge(getUserMatrix(lsri))
    const retirementAge = lsriChartNodesCustom.find((n) => n.stepType === LsriStepType.Retirement)?.age ?? 62
    const socSecAge = lsriChartNodesCustom.find((n) => n.stepType === LsriStepType.SocialSecurity)?.age ?? socSecMaxAge
    const shiftMultiplier = 73 - retirementAge

    function calcShiftRight(colNum: number, extend?: boolean) {

        if (colNum === (extend ? 0 : 1)) {

            return hasSocSecStep
                ? (socSecMaxAge - socSecAge) * (barWidth / shiftMultiplier) * -1
                : growthWidth + barWidth
        }      

        return 0
    }


    const FooterLabel = (props: { node: LsriChartNode, colNum: number }) => {
        const x = (props.colNum * barWidth) + (props.colNum * growthWidth) + calcShiftRight(props.colNum)

        return (<>
            <text x={`${x + (barWidth / 2) - (smallSize ? 22 : 12)}px`} y={`${chartHeight + 30}`} className="age-text" >{props.node.age > 0 ? props.node.age : ''}</text>
            <text x={`${x + (barWidth / 2) + (smallSize ? 5 : 15)}px`} y={`${chartHeight + 30}`} className="age-text-yrs" >Yrs.</text>
        </>)
    }

    const BarCell = (props: { data: LsriChartDataPoint, colNum: number }) => {

        return (
            <rect
                x={`${(props.colNum * barWidth) + (props.colNum * growthWidth) + calcShiftRight(props.colNum)}px`}
                y={`${chartHeight - (props.data.maxLeft * scale)}`}
                width={`${barWidth}px`}
                height={`${props.data.amount * scale}`}
                className={`${props.data.type} bar-cell`}
            />
        )
    }

    const BarCellLoading = (props: { colNum: number }) => {

        if (!(isLoadingAfterRecalc || isRecalculating))
            return null;

        const barTotHeight = lsriChartNodesCustom[props.colNum].totalAmountWithMin * scale;

        return (
            <rect
                x={`${(props.colNum * barWidth) + (props.colNum * growthWidth) + calcShiftRight(props.colNum) - 1}px`}
                y={`${(chartHeight - barTotHeight) - 1}`}
                width={`${barWidth + 2}px`} height={`${barTotHeight + 2}`}
                style={{
                    fill: 'white'
                }}
            >
                {isRecalculating && <animate attributeName="visibility" dur={`${fadeInDuration}s`} fill="freeze" from="hidden" to="visible" />}

                <animate attributeName="height"
                    from={`${isRecalculating ? 0 : barTotHeight}`}
                    to={`${isRecalculating ? barTotHeight : 0}`}
                    dur={`${isRecalculating ? fadeOutDuration : fadeInDuration}s`}
                    begin={`${isRecalculating ? fadeOutDuration : '0'}s`}
                    fill="freeze"
                />
            </rect>
        )
    }

    const BarCellLabel = (props: { data: LsriChartDataPoint, colNum: number }) => {

        let x = (props.colNum * barWidth) + (props.colNum * growthWidth) + calcShiftRight(props.colNum) + (smallSize ? 7 : 19)
        const y = props.data.type === LsriBarType.LifeStage
            ? chartHeight - (props.data.minLeft * scale) - 10
            : chartHeight - (props.data.minLeft * scale) - (props.data.amount / 2 * scale) + 7
        let shiftUpDown = 0

        //Reduce font size based on cell height
        let reduceFontSize = props.data.type === LsriBarType.SocialSecurity || props.data.type === LsriBarType.Qlac
            ? props.data.amount * scale < 40
            : props.data.amount * scale < 25

        //Reduce font size for certain screen sizes
        if (props.data.type === LsriBarType.SocialSecurity && isMediumScreen)
            reduceFontSize = true

        if (reduceFontSize) {
            x = x + 7

            if (props.data.type === LsriBarType.LifeStage)
                shiftUpDown = -6
            else if (props.data.type === LsriBarType.Mpp)
                shiftUpDown = 2
            else if (props.data.type === LsriBarType.SocialSecurity)
                shiftUpDown = -7
            else if (props.data.type === LsriBarType.Qlac)
                shiftUpDown = -7
        }

        if (isRecalculating)
            return <></>

        return (<>

            {props.data.type === LsriBarType.LifeStage && <text x={x + 2} y={y - shiftUpDown} className={`${props.data.type} bar-cell-text ${reduceFontSize ? 'smaller' : ''}`}>
                LifeStage
            </text>}

            {props.data.type === LsriBarType.SocialSecurity && <>
                <text x={x - 14 + (reduceFontSize ? 4 : 0)} y={y - 10 - shiftUpDown} className={`${props.data.type} bar-cell-text ${reduceFontSize ? 'smaller' : ''}`}>
                    Social Security
                </text>
            </>}

            {props.data.type === LsriBarType.Qlac && <>
                <text x={x + 1 - (reduceFontSize ? (smallSize ? 7 : 20) : 0)} y={y - 10 - shiftUpDown} className={`${props.data.type} bar-cell-text ${reduceFontSize ? 'smaller' : ''}`}>
                    Longevity{reduceFontSize && <>{smallSize ? " Inc" : " Income"}</>}
                </text>
                {!reduceFontSize &&
                    <text x={x + 8} y={y + 5 - shiftUpDown - (reduceFontSize ? 5 : 0)} className={`${props.data.type} bar-cell-text ${reduceFontSize ? 'smaller' : ''}`}>
                        Income
                    </text>
                }
            </>}

            {props.data.type === LsriBarType.Mpp && <text x={x + 16} y={y - shiftUpDown} className={`${props.data.type} bar-cell-text ${reduceFontSize ? 'smaller' : ''}`}>
                MPP
            </text>}

        </>)
    }

    const GrowthCell = (props: { data: LsriChartDataPoint, colNum: number, rowNum: number, isFinalRecord: boolean }) => {

        const totBarWidth = (props.colNum + 1) * barWidth
        const totGrowthWidth = (props.colNum + 1) * growthWidth

        //These shift the connection point for where the growth lands in relation to the bar, common options: (0, barWidth/2, barWidth)
        const overlapIntoNext = barWidth / 2
        const overlapIntoCurrent = barWidth / 2

        const leftX = totBarWidth + (props.colNum * growthWidth) + calcShiftRight(props.colNum) - overlapIntoCurrent
        const rightX = props.isFinalRecord ? viewBoxWidth - arrowSize - 3 : totBarWidth + totGrowthWidth + overlapIntoNext + calcShiftRight(props.colNum, true)

        const topLeft = { x: leftX, y: chartHeight - (props.data.maxLeft * scale) } as IPoint
        const bottomLeft = { x: leftX, y: chartHeight - (props.data.minLeft * scale) } as IPoint
        const topRight = { x: rightX, y: chartHeight - (props.data.maxRight * scale) } as IPoint
        const bottomRight = { x: rightX, y: chartHeight - (props.data.minRight * scale) } as IPoint

        return (<>
            <polygon
                points={`${topLeft.x},${topLeft.y} ${bottomLeft.x},${bottomLeft.y} ${bottomRight.x},${bottomRight.y} ${topRight.x},${topRight.y}`}
                className={`${props.data.type} growth-cell ${props.rowNum}`}
            >
                {isLoadingAfterRecalc && <animate
                    id={`growth_${props.colNum}_${props.rowNum}`}
                    attributeName="points"
                    dur={`${fadeInDuration}s`}
                    begin={`${fadeInDuration}s`}
                    fill="freeze"
                    from={`${topLeft.x},${topLeft.y} ${bottomLeft.x},${bottomLeft.y} ${bottomLeft.x},${bottomLeft.y} ${topLeft.x},${topLeft.y}`}
                    to={`${topLeft.x},${topLeft.y} ${bottomLeft.x},${bottomLeft.y} ${bottomRight.x},${bottomRight.y} ${topRight.x},${topRight.y}`} />}
            </polygon>

            {(isLoadingAfterRecalc || isRecalculating) &&
                <polygon
                    points={`${topLeft.x},${topLeft.y} ${bottomLeft.x},${bottomLeft.y} ${bottomRight.x},${bottomRight.y} ${topRight.x},${topRight.y}`}
                    style={{
                        fill: 'white'
                    }}
                >
                    {!isRecalculating && <animate attributeName="visibility" dur={`${fadeInDuration * 2}s`} fill="freeze" from="visible" to="hidden" />}

                    {isRecalculating && <animate
                        id={`recalc_overlay_${props.colNum}_${props.rowNum}`}
                        attributeName="points"
                        dur={`${fadeOutDuration}s`}
                        fill="freeze"
                        from={`${topRight.x},${topRight.y} ${bottomRight.x},${bottomRight.y} ${bottomRight.x},${bottomRight.y} ${topRight.x},${topRight.y}`}
                        to={`${topLeft.x},${topLeft.y} ${bottomLeft.x},${bottomLeft.y} ${bottomRight.x},${bottomRight.y} ${topRight.x},${topRight.y}`}
                    />}

                </polygon>
            }
        </>)
    }

    function shiftDollarAmt(totalAmountWithMin: number, amtXPosition: number): number {

        if (!smallSize) {
            if (totalAmountWithMin < 100)
                return amtXPosition + 26
            else if (totalAmountWithMin < 1000)
                return amtXPosition + 15
            else if (totalAmountWithMin >= 10000)
                return amtXPosition - 10
        }

        return amtXPosition
    }

    const MySelectionsLabel = (props: { customNode: LsriChartNode, origNode?: LsriChartNode, colNum: number }) => {
        const x = (props.colNum * barWidth) + (props.colNum * growthWidth) + calcShiftRight(props.colNum)
        const y = chartHeight - (props.customNode.totalAmountWithMin * scale) - (smallSize && showCompare ? 55 : 35)

        const labelXPosition = smallSize ? x + (barWidth / 2) - 30 : x + (barWidth / 2) - 36 - (showCompare ? 15 : 10)
        const labelXPositionDflt = labelXPosition + (smallSize ? 0 : 95)
        const amtXposition = smallSize ? labelXPosition : x + (barWidth / 2) - 18 - (showCompare ? 15 : 10)

        const MySelections = () => (<>
            {showCompare && <text x={`${labelXPosition - (smallSize ? 10 : 0)}px`} y={`${y + (smallSize ? 25 : 0)}`} className="my-selections" >My Selections</text>}
            <text
                x={`${shiftDollarAmt(props.customNode.totalAmountWithMin, amtXposition)}px`}
                y={`${y + 18 + (smallSize && showCompare ? 25 : 0)}`}
                className="my-selections-dollar"
            >
                {formatMoney(props.customNode.totalAmount)}
            </text>
        </>)
        
        const Default = () => (
            !props.origNode ? null : <>
                <text x={`${labelXPositionDflt + (smallSize ? 10 : 0)}px`} y={`${y + (smallSize ? -20 : 0)}`} className="my-default" >Default</text>
                <text
                    x={`${smallSize && showCompare ? shiftDollarAmt(props.origNode.totalAmountWithMin, amtXposition) : labelXPositionDflt}px`}
                    y={`${y + 18 + (smallSize ? -20 : 0)}`} className="my-default-dollar"
                >
                    {formatMoney(props.origNode.totalAmount)}
                </text>
            </>
        )

        if (isRecalculating)
            return <></>

        return (<>
            <MySelections />
            {showCompare && <Default />}
        </>)
    }

    const BaseLine = () => (<>
        <line
            x1="0" y1={chartHeight}
            x2={viewBoxWidth - arrowSize} y2={chartHeight}
            stroke="#4C4C4C"
        />
        <polygon
            points={`${viewBoxWidth - arrowSize},${chartHeight + (arrowSize / 2)} ${viewBoxWidth - arrowSize},${chartHeight - (arrowSize / 2)} ${viewBoxWidth},${chartHeight}`}
            stroke="#4C4C4C"
        />
    </>)

    const LabelLoadOverlay = () => {

        if (!(isLoadingAfterRecalc || isRecalculating))
            return null;

        return (
            <rect
                x="0px"
                y={`${-1 * (arrowSize / 2)}px`}
                width={`${viewBoxWidth}px`} height={`${labelHeight}`}
                style={{
                    fill: 'white'
                }}
                transform={`rotate(180 ${viewBoxWidth / 2} ${viewBoxHeight / 2})`}
            >
                {isRecalculating && <animate attributeName="visibility" dur={`${fadeInDuration}s`} fill="freeze" from="hidden" to="visible" />}

                <animate attributeName="height"
                    from={`${isRecalculating ? 0 : labelHeight}`}
                    to={`${isRecalculating ? labelHeight : 0}`}
                    dur={`${isRecalculating ? fadeOutDuration : fadeInDuration}s`}
                    begin={`${isRecalculating ? fadeOutDuration : '0'}s`}
                    fill="freeze"
                />
            </rect>
        )
    }

    const DataGraph = () => (
        <svg
            width='100%'
            height={`${chartHeight + labelHeight}px`}
            viewBox={`0 0 ${viewBoxWidth} ${chartHeight + labelHeight}`}
            className="lsriChart"
            id="lsriFlowChart"
        >
            
            {lsriChartNodesCustom?.map((node, i) => <React.Fragment key={i}>
                {node.lsriChartDataPoints?.filter((data) => data.amount > 0)?.map((data, j) => {
                    return <React.Fragment key={j}>
                        <GrowthCell data={data} colNum={i} rowNum={j} isFinalRecord={i === lsriChartNodesCustom.length - 1} />
                    </React.Fragment>
                })}
                {node.lsriChartDataPoints?.filter((data) => data.amount > 0)?.map((data, j) => {
                    return <React.Fragment key={j}>
                        <BarCell data={data} colNum={i} />
                        <BarCellLabel data={data} colNum={i} />
                        <BarCellLoading colNum={i} />
                    </React.Fragment>
                })}
                <MySelectionsLabel customNode={node} origNode={lsriChartNodesOriginal.find(n => n.stepType === node.stepType)} colNum={i} />
                <FooterLabel node={node} colNum={i} />
            </React.Fragment>)}

            <BaseLine />

            <text x={`${viewBoxWidth - (smallSize ? 40 : 80)}px`} y={`${chartHeight + 30}`} className="age-text lighter" >85+</text>
            {!smallSize && <text x={`${viewBoxWidth - 40}px`} y={`${chartHeight + 30}`} className="age-text-yrs lighter" >Yrs.</text>}

            <LabelLoadOverlay />

        </svg>
    )

    return (<>

        {isLoading ? <LoadingSkeleton /> : <>

            <DataGraph />

            <LsriDataTable />

        </>}

    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriFlowChart);



function calcMaxAmt(data: LsriChartNode[]) {

    if (data?.length > 0) {
        const maxRight = data[data.length - 1]?.lsriChartDataPoints.reduce(function (prev, current) {
            return (prev.maxRight > current.maxRight) ? prev : current
        }).maxRight

        const barsMax = data?.reduce(function (prev, current) {
            return (prev.totalAmountWithMin > current.totalAmountWithMin) ? prev : current
        }).totalAmountWithMin;

        return Math.max(maxRight, barsMax);
    }

    //Send back a default so that the chart renders
    return 10000;

}
