import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/pro-solid-svg-icons';
import { ProjectionGauge } from '../../ProjectionGauge';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { benefitProjectionCreators } from '../../../store/BenefitProjectionStore';
import { connect } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { BenefitProjectionFooter } from '../../BenefitProjectionFooter';
import { AppDispatch } from '../../..';

export interface OwnProps { isSummary?: boolean }
type TBenefitProjectionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const BenefitProjection = (props: TBenefitProjectionProps) => {

    const { benefitProjection, isDcbc, isRetired } = props

    const isLoading = (props.isLoading && !props.isLoaded) || props.balanceIsLoading;
    const showCard = !isLoading && props.benefitProjection?.estAmt > 0 && !isDcbc && !isRetired;
    const retirementDate = new Date(props.benefitProjection?.retirementDate ?? "01/01/2000") as Date;
    const showCRSPMessage = showCard && props.benefitProjection.isCrspActiveParticipant && retirementDate >= new Date("2026-07-01T00:00:00");

    useEffect(() => {

        if (stateNeedsLoading(props)) {
            props.fetchBenefitProjection();
        }
    });

    const marginShift = () => {
        if (benefitProjection.estPct > 60)
            return "mr-5";
    }

    const TitleText = () => (<>
        Retirement Projection{benefitProjection.ageAtRetirement && <> at age {benefitProjection.ageAtRetirement}</>}
    </>);

    const Gauge = () => (
        <ProjectionGauge
            size={240}
            arcSize={0.7}
            projected={benefitProjection.estPct}
            target={benefitProjection.goalPct}
            text={benefitProjection.estAmt.toString()}
        />
    );


    if (!showCard) {
        return <></>;
    }
    else if (props.isSummary) {

        return (<>
            <div className="smallLabel">
                <TitleText />
            </div>

            {!showCRSPMessage && <>
                <div className={`projectionGaugeSummary ${marginShift()}`}>
                    <Gauge />
                </div>

                <BenefitProjectionFooter benefitProjection={benefitProjection}>
                    {/*<BenefitsAccessSso to={`/mybenefits_readiness_tool.aspx`} >Customize</BenefitsAccessSso>*/}
                </BenefitProjectionFooter> </>
            }

            {showCRSPMessage && <h4 className="mt-2">Projection tools are being updated to include Compass</h4>}

        </>);

    }
    else {

        return (
            <Card className="pageCard secondary">
                <Card.Title>
                    <FontAwesomeIcon icon={faCalculator} className="ret-color" />
                    <TitleText />
                </Card.Title>

                <Card.Body>

                    {!showCRSPMessage && <>

                        <div className={`projectionGaugeDetail ${marginShift()}`}>
                            <Gauge />
                        </div>

                        <BenefitProjectionFooter benefitProjection={benefitProjection} />

                        {/*<BenefitsAccessSso to={`/mybenefits_readiness_tool.aspx`} className="mt-4">
                            <Button variant="secondary">Customize Projection</Button>
                        </BenefitsAccessSso>*/}
                    </>}

                    {showCRSPMessage && <h4>Projection tools are being updated to include Compass</h4>}

                </Card.Body>
            </Card>
        );

    }



}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BenefitProjection);

function mapStateToProps(state: AppState, ownProps: OwnProps) {

    return {
        ...state.benefitProjection,
        ...ownProps,
        isDcbc: state.monthlyBenefit.monthlyBenefit?.isDcbc,
        isRetired: state.balance.balance?.isRetired,
        balanceIsLoading: state.balance.isLoading,
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...benefitProjectionCreators
    }, dispatch);
}
