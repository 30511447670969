import * as React from "react";
import { parseCmsContent } from "../functions/parseCmsContent";

export interface SectionHeaderProps {
    name: string;
    className?: string;
    id?: string;
    suppressHr?: boolean;
    title?: string;
}

const SectionHeader = function(props: SectionHeaderProps):React.ReactElement {
    function getHeaderInner() {
        return <span dangerouslySetInnerHTML={parseCmsContent(props.name)} />
    }

    return (
        <div className={props.className}>
            {!props.suppressHr && <hr />}
            {props.id && <a className="anchor" id={props.id}></a>}
            <h4 className="text-gold" title={props.title}>{getHeaderInner()}</h4>
        </div>
    )
};
export { SectionHeader };