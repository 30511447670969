import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsDollar } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { eyActionCreators } from '../../../store/EyEligStore';
import { AppDispatch } from '../../..';
import { cmsActionCreators } from '../../../store/CmsStore';
import { activityActionCreators } from '../../../store/ActivityStore';
import EYToggleSwitch from '../../EYToggleSwitch';

type TEyProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const EyRetirement = (props: TEyProps) => {
    const dispatch: AppDispatch = useDispatch(); 
    const cmsData = props.cmsData.fragments?.find(f => f.name === "EYFinancialMessage");

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchEyEligibility();
        }
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (<>
        {props.eyEligibility?.eyEligible === "Y" &&
            <Card className="pageCard secondary">
                <Card.Title><FontAwesomeIcon icon={faCommentsDollar} className="ret-color" />EY Financial Planning Services</Card.Title>
                <Card.Body>

                    {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                    <a className="btn btn-link mb-10" style={{marginBottom:"3rem"}} href="https://wespath.eynavigate.com/" target="_blank" rel="noopener noreferrer" onClick={() => dispatch(activityActionCreators.logActivity("button_eynavigate.com"))}>
                        <Button variant="secondary">EY Financial Planning Center</Button>
                    </a>

                    <EYToggleSwitch />

                </Card.Body>
            </Card>
        }
    </>);
}

function mapStateToProps(state: AppState) {

    return {
        ...state.ey,
        cmsData: state.cms.retirement
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...eyActionCreators,
        fetchCmsData: cmsActionCreators.fetchRetirementCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EyRetirement);
