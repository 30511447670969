import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { GetClaims, GetClaimsResponse } from "../Gateway.dtos";
import { accountActionCreators } from "./AccountStore";
import { getResetAction } from "../functions/getResetAction";

export interface IClaimsState extends ILoadableState {
    claims: GetClaimsResponse
}

export const initialClaimsState: IClaimsState = {
    claims: {} as GetClaimsResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'claims',
    initialState: {} as IClaimsState,
    reducers: {
        resetState: () => {
            return {
                ...initialClaimsState
            }
        },
        requestClaims: (state: IClaimsState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveClaims: (state: IClaimsState, action: PayloadAction<{ claims: GetClaimsResponse }>) => {
            const { claims } = action.payload;
            return {
                ...state,
                claims,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveClaimsError: (state: IClaimsState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (state, action) => {
                const isEySearch = !!action?.payload?.isEySearch
                if (isEySearch) {
                    return state
                } else {
                    return initialClaimsState
                }
            })
    }
})

const { resetState, requestClaims, receiveClaims, receiveClaimsError } = slice.actions;

function fetchClaims() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().claims;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestClaims());

        try {

            const claims = await client.post(new GetClaims());

            dispatch(receiveClaims({ claims }));

        } catch (e: unknown) {
            dispatch(receiveClaimsError());
            console.log(e);

            dispatch(accountActionCreators.accessDenied());
        }

    }
}

export const claimsActionCreators = {
    fetchClaims,
    resetState
};

export const claimsReducer = slice.reducer;
