import React, { useEffect } from 'react';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { LsriActionCreators } from '../../../store/LsriStore';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { push } from 'connected-react-router';
import UnlockBanner from '../../UnlockBanner';
import { PageTitle } from '../../PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { LsriStage } from '../../../Gateway.dtos';
import { cmsActionCreators } from '../../../store/CmsStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import BenefitsAccessSso from '../../BenefitsAccessSso';
import { getBusinessPaymentDate } from '../../../functions/getBusinessPaymentDate';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriSuccess = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    //const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error

    const wizardTitle = "LifeStage Retirement Income"

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Success`
    }, []);

    const getSelectedPaymentDate = () => {
        const selectedPayment = lsri.lsriInfo.oracleLsriElection.paymentOptions.find(p => p.elected === true);
        return selectedPayment ? new Date(selectedPayment.date) : new Date();
    }

    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "Success"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    const successMsgCms = (cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/SuccessMsg`)?.html ?? '').replace("$BusinessPaymentDate$", getBusinessPaymentDate(getSelectedPaymentDate(), lsri.lsriInfo.holidayList));
    //End CMS Section

    const ChangeYourMindBtn = () => 
        <BenefitsAccessSso to={`/mybenefits_pendingactions.aspx`}>change your mind</BenefitsAccessSso>    

    const handleClickManage = () => {
        dispatch(LsriActionCreators.setWizardType(LsriStage.Pending))
        dispatch(push(GatewayPath.LsriOverview))
    }

    const BodyContent = () => (
        <Row className="pt-5" style={{ paddingBottom: '10rem' }}>
            <Col className="px-md-5 offset-lg-2 col-lg-8 text-center">
                <h2 className="mb-5">
                    <FontAwesomeIcon className="mr-2 success-green" icon={faCheckCircle} /> Success
                </h2>

                <p>
                    {cmsIsLoading
                        ? <LoadingSkeleton />
                        : <><span dangerouslySetInnerHTML={{ __html: successMsgCms.replace("$changeYourMindBtn", "") }} /> {successMsgCms.includes("$changeYourMindBtn") && <><ChangeYourMindBtn />.</>}</>
                    }
                </p>

                <Button variant="outline-primary" onClick={() => (handleClickManage())} style={{ marginTop: '5rem' }} >Manage LifeStage Retirement</Button>
            </Col>
        </Row>
    )

    return (<>

        <UnlockBanner />

        {!isSmallScreen && <Card className="detailCard desktop" style={{ marginTop: '2em' }}>

            <PageTitle title={wizardTitle} color={`ret-color-bg`} printBtnText="Print" />

            <Card.Body className="sidePanelWizardCardBody">

                <BodyContent />

            </Card.Body>
        </Card>}


        {isSmallScreen && <div className="detailCard mobile">
            <Card>

                <PageTitle title={wizardTitle} color={`ret-color-bg`} printBtnText="Print" />

                <Card.Body className="mx-3 py-4">
                    
                    <BodyContent />

                </Card.Body>
            </Card>
        </div>}

    </>);
};

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriSuccess);



