import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescriptionBottle, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { healthPlansActionCreators } from '../../../store/HealthPlanStore';
import { cmsActionCreators } from '../../../store/CmsStore';
import { connect } from 'react-redux';
import { PlanType, ServiceProvider } from '../../../Gateway.dtos';
import { ProviderLabel } from '../../ProviderLabel';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppDispatch } from '../../..';
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'

type TPrescriptionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;



const Prescription = (props: TPrescriptionProps) => {
    const { healthPlans, hasClaim_HealthAccess, username } = props;

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    const planData = healthPlans?.planElections ? healthPlans?.planElections[healthPlans.planElections.findIndex(i => i.type === PlanType.PHARMACY)] : undefined;

    const cmsData = props.cmsData.fragments?.find(f => f.name === "PrescriptionDrugsMessage");

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.Optum;

    useEffect(() => {
        if (stateNeedsLoading(props))
            props.fetchHealthPlans();

        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }

    return (<>
        {planData && <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faPrescriptionBottle} className="health-color" />
                Prescription <span className="text-nowrap">Drugs
                <FontAwesomeIcon icon={faCheck} className="dtlCheckMark"/></span>
            </Card.Title>
            <Card.Body>

                <Row className="providerLabelGroup">
                    <Col className="col-12 col-sm-6">
                        {planData.provider && <ProviderLabel label="Pharmacy Benefit Manager" value={planData.provider} />}
                        {planData.obligations.map((item, i) => (
                            i < 2 && <ProviderLabel key={i} label={item.label} value={item.value} />
                        ))}

                    </Col>
                    <Col className="col-12 col-sm-6">
                        {planData.obligations.map((item, i) => (i >= 2 &&
                            <ProviderLabel key={i} label={item.label} value={item.value} />
                        ))}
                    </Col>
                </Row>

                {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                <Button variant="secondary"
                    onClick={handleSubmit}
                    className={`mt-4 ${!props.hasClaim_HealthAccess ? "claimControlled" : ""}`}
                    disabled={!hasClaim_HealthAccess}
                >
                    Go to OptumRx
                </Button>     

            </Card.Body>
        </Card>}
        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
        <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer="b" />
    </>);
}

function mapStateToProps(state: AppState) {
    const { username } = state.account;
    return {
        ...state.healthPlans,
        cmsData: state.cms.health,
        username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...healthPlansActionCreators,
        fetchCmsData: cmsActionCreators.fetchHealthCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Prescription);
