export enum UmLifeEligType {
    INELIGIBLE,
    ELIGIBLE,
    OPTIONAL
}

export function GetUmLifeEligType(code?: string): UmLifeEligType {

    if (code === undefined || code === "C999")
        return UmLifeEligType.INELIGIBLE;
    else if (code === "C256")
        return UmLifeEligType.ELIGIBLE;
    else
        return UmLifeEligType.OPTIONAL;
}
