import React from "react"
import Skeleton from "react-loading-skeleton"

const LoadingSkeleton = (props: { className?: string }): React.ReactElement => (
    <div className={`${props.className ?? 'mb-5'}`}>
        <Skeleton width={'100%'} count={4} />
        <br /><Skeleton width={'75%'} />
    </div>
)

export { LoadingSkeleton }