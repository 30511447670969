import React, { Fragment, ReactElement } from "react";
import { Row, Col } from "react-bootstrap";
import { VictoryPie } from "victory";
import { Fund, FundGroup } from "../Gateway.dtos";

interface FundGroupProps { fundGroupData: FundGroup[] }
function FundGroupList(props: FundGroupProps) {
    return (<>
        {props.fundGroupData.map((group, i) =>
            <Fragment key={i}>
                <Row className="mb-3">
                    <Col>
                        <strong>{group.groupName}</strong>
                        <FundRow fundRowData={group.funds} />
                    </Col>
                </Row>
            </Fragment>
        )}
    </>);
}

interface FundRowProps { fundRowData: Fund[] }
function FundRow(props: FundRowProps) {

    if (props.fundRowData) {
        return (<>
            {props.fundRowData.map((fund, i) =>
                <Fragment key={i}>
                    <Row className="ml-2 mr-0 pr-0">
                        <Col className="col-1 pl-0 pr-2" >
                            <span style={{ "backgroundColor": fund.color, "width": "20px", "height": "10px", "border": "solid 1px black", "display": "inline-block" }}></span>
                        </Col>
                        <Col className="col-2 pl-0 ml-0 pr-3 text-right">
                            <span style={{ whiteSpace: "nowrap" }}>{fund.allocation}%</span>
                        </Col>
                        <Col className="col-lg-8 px-0 mx-0">
                            <a href={fund.href} className="secondary" rel="noreferrer" target="_blank" title={fund.title}>{fund.name}</a>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </>);
    }
    else {
        return (<></>)
    }
}

function pieData(fundObj: FundGroup[], dataToRet: string) {
    /* eslint-disable @typescript-eslint/no-explicit-any*/
    const retArr: any[] = [];
    fundObj.forEach(fundGroup => {
        fundGroup.funds.forEach(fund => {
            switch (dataToRet) {
                case "xy":
                    retArr.push({
                        x: fund.name + " : " + fund.allocation + "%",
                        y: parseInt(fund.allocation)
                    });
                    break;
                case "colors":
                    retArr.push(fund.color);
                    break;
            }
        });
    });

    return retArr
}


interface InvestmentMixProps { investmentTitle: string, investmentMixData: FundGroup[] }
const InvestmentMix = (props: InvestmentMixProps): ReactElement => {

    return (<>
        <Row>
            <Col style={{ height: '70px'}}>
                <h4 className="text-center mt-4 mt-sm-2 pb-0 pb-md-4 pb-lg-0">
                    {props.investmentTitle}
                </h4>
            </Col>
        </Row>
        <div className="victoryPieSvg">

            <VictoryPie
                data={pieData(props.investmentMixData, "xy")}
                colorScale={pieData(props.investmentMixData, "colors")}
                labels={() => ""}
            />
            <FundGroupList fundGroupData={props.investmentMixData} />
        </div>
    </>)
};

export { InvestmentMix };