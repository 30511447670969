import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToolTip } from "./ToolTip";

export interface MobileLabelAndContentProps {
    label: string,
    value?: React.ReactElement | string | undefined,
    title?: string
    label2?: string,
    value2?: React.ReactElement | string | undefined,
    title2?: string
    hideIfBlank?: boolean,
    secondColLeft?: boolean,
}

export interface MobileLabelAndContentThreeColumnProps extends MobileLabelAndContentProps {
    label3?: string,
    value3?: React.ReactElement | string | undefined,
    title3?: string
}

const MobileLabelAndContent = (props: MobileLabelAndContentProps): React.ReactElement => {

    const hideCol1 = props.hideIfBlank && !props.value
    const hideCol2 = (props.hideIfBlank && !props.value2) || !props.label2

    return (
        <Row className="mobile-label-content-row">
            {!hideCol1 && <Col className={`px-1 ${hideCol2 ? 'col-12' : 'col-6'}`}>
                <CardLabel label={props.label} title={props.title}>{props.value}</CardLabel>
            </Col>}
            {!hideCol2 && <Col className={`col-6 pl-1 ${props.secondColLeft ? '' : 'pr-2 text-right'}`}>
                <CardLabel label={props.label2 ? props.label2 : ''} title={props.title2}>{props.value2}</CardLabel>
            </Col>}
        </Row>
    );
}

const MobileLabelAndContentThreeColumn = (props: MobileLabelAndContentThreeColumnProps): React.ReactElement => {

    return (
        <Row className="mobile-label-content-row">
            <Col className="px-1 col-4">
                <CardLabel label={props.label} title={props.title}>{props.value}</CardLabel>
            </Col>
            <Col className="px-1 col-4">
                <CardLabel label={props.label2 ? props.label2 : ''} title={props.title2}>{props.value2}</CardLabel>
            </Col>
            <Col className="px-1 col-4">
                <CardLabel label={props.label3 ? props.label3 : ''} title={props.title3}>{props.value3}</CardLabel>
            </Col>
        </Row>
    );
}

const MobileTotal = (props: { label: string, value?: React.ReactElement | string | undefined, secondColLeft?: boolean }): React.ReactElement => {

    return (
        <Row className="mobile-total">
            <Col className="col-6 px-0 mx-0">
                {props.label}
            </Col>
            <Col className={`col-6 ${props.secondColLeft ? '' : 'text-right'} px-0 mx-0`}>
                <b>{props.value}</b>
            </Col>
        </Row>
    );
}


export { MobileLabelAndContent, MobileTotal, MobileLabelAndContentThreeColumn };

const CardLabel = (props: { label: string, children: React.ReactElement | string | undefined, title?: string }) => (
    <div className="mb-3">
        <ToolTip title={props.title}><strong>{props.label}</strong></ToolTip>
        <div>{!props.children ? <span>&nbsp;</span> : props.children}</div>
    </div>
)