import { Phone, PhoneType } from "../Gateway.dtos";

const findTypeByPhone = function (phones: Phone[], phoneNum: string): PhoneType {

    for (let i = 0; i < phones.length; i++) {
        if (phones[i].number === phoneNum)
            return phones[i].type;
    }

    return PhoneType.PRIMARY;
}

export { findTypeByPhone }