import React, { useRef, useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { GatewayPath, SamlSelfPostForm } from '@wespath/gateway-navigation'
import { AppState } from '../../../store/configureStore';
import { healthPlansActionCreators } from '../../../store/HealthPlanStore';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import Medicare from '../health/Medicare';
import HealthAndReimbursement from '../health/HealthAndReimbursement';
import Medical from '../health/Medical';
import { PlanType, ServiceProvider } from '../../../Gateway.dtos';
import { CollapsibleCard } from '../../CollapsibleCard';
import SAMLSsoModal from '../../SAMLSsoModal';
import Health from '../../pages/Health';

type THealthProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface OwnProps { expandCard?: boolean }
const HealthSummary = (props: THealthProps, hasClaim_HealthAccess: boolean) => {
    const { hasHsa, planElections, hasMedicare, username} = props;

    const hasMedical = !!planElections?.find(p => p.type === PlanType.MEDICAL);
    const hasPrescription = !!planElections?.find(p => p.type === PlanType.PHARMACY);
    const hasDental = !!planElections?.find(p => p.type === PlanType.DENTAL);
    const hasVision = !!planElections?.find(p => p.type === PlanType.VISION);

    const preEnrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showPreEnrollmentBanner ?? false)
    const enrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showEnrollmentBanner ?? false)

    const showAEContent = preEnrollmentPeriod || enrollmentPeriod

    const showOtherBenefits = hasHsa || hasMedical || hasPrescription || hasDental || hasVision

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.BusinesSolver;
    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    

    const handleSSO = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }
    type LinkProps = {
        children?: React.ReactNode
    }
    const BenefitSolverSsoLink: React.FC = ({ children }: LinkProps) =>
        <Button
            onClick={handleSSO}
            disabled={!hasClaim_HealthAccess}
            variant='link'
            className="m-0 p-0"
        >
            {children}
        </Button>

    return (
        <CollapsibleCard headerClassName="health-color-bg" headerContent="Health" expandCard={props.expandCard}>
            <Card.Title className="sumAsOfDate">Data as of {props.dateAsOf}</Card.Title>

            {showAEContent ? 
                <Alert className="mx-3 p-3" variant="warning">
                    <h5><strong>HealthFlex 2025 – Get Ready!</strong></h5>
                    <p className="mb-0">Learn about your HealthFlex options for 2025.<a href="/HealthFlexBenefits"><Button variant="link" className="pl-1">Get Started.</Button></a></p>
                </Alert>
                : <></> }
            

            <Card.Body className="pt-0">

                <Medicare isSummary />

                {hasMedicare && showOtherBenefits && <hr />}

                <HealthAndReimbursement isSummary />

                <Medical isSummary />

                {showOtherBenefits && <>

                    <hr />

                    <p className="footnote amountPanelSmall">
                        You have additional benefits that can help you live a healthier life and improve your well-being.
                    </p>

                </>}

                <span className="text-center">
                    <NavLink to={GatewayPath.Health} className="nav-link px-0" activeClassName="active">
                        <Button variant="primary" className="summaryBtn">Health Details</Button>
                    </NavLink>
                </span>
                
            </Card.Body>
        </CollapsibleCard>
    );
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        hasMedicare: state.medicare?.medicare?.isEnrolled_ViaBen1 || state.medicare?.medicare?.isEnrolled_ViaBen2,
        planElections: state.healthPlans?.healthPlans?.planElections,
        hasHsa: state.hsa.hsa?.healthSavingsAccounts?.length > 0,
        dateAsOf: state.healthPlans?.healthPlans?.dateAsOf,
        username: state.account.username
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...healthPlansActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthSummary);