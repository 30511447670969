import React, { ReactElement } from 'react';
import { AppState } from '../store/configureStore';
import { connect } from 'react-redux';
import { ExternalLinkModal } from '@wespath/gateway-navigation'
import { ServiceProvider } from '../Gateway.dtos';

export interface SAMLSsoProps { isShowing: boolean; hideModal: () => void; serviceProvider: ServiceProvider; referrer?: string }

const SAMLSsoModal = function (props: ReturnType<typeof mapStateToProps>): ReactElement {
    return <ExternalLinkModal {...props} />
};

function mapStateToProps(state: AppState, ownProps: SAMLSsoProps) {
    const { username } = state.account;
    return {
        username,
        ...ownProps
    }
}


export default connect(
    mapStateToProps
)(SAMLSsoModal);

export { SAMLSsoModal };
