import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import { PageTitle } from '../PageTitle';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from 'redux';
import WW from '../cards/wellbeing/WW';
import EmployeeAssistance from '../cards/wellbeing/EmployeeAssistance';
import DiabetesPrevention from '../cards/wellbeing/DiabetesPrevention';
import HealthCoaching from '../cards/wellbeing/HealthCoaching';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import { healthPlansActionCreators } from '../../store/HealthPlanStore';
import { wellBeingActionCreators } from '../../store/WellBeingStore';
import BeneficiaryReviewModal from '../BeneficiaryReviewModal';
import { AppDispatch } from '../..';
import VirginPulse from '../cards/wellbeing/VirginPulse';
import Biometric from '../cards/health/Biometric';
import HealthQuotient from '../cards/wellbeing/HealthQuotient';
import { WellBeing, Health } from '../../definitions/Claims';
import UnlockBanner from '../UnlockBanner';
import DiseasePrevention from '../cards/wellbeing/DiseasePrevention';


type TWellBeingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const WellBeingPage = (props: TWellBeingProps) => {

    useEffect(() => {
        document.title = "Benefits Access - WellBeing"
    }, []);

    const hasMedical = props.healthPlans?.healthPlans?.hasMedical || false;
    const hasVp = props.wellBeing?.wellBeing?.vpIsQualified || false;
    const hasBfw = props.wellBeing?.wellBeing?.bfwIsQualified || false;
    const dateAsOf = props.healthPlans.healthPlans?.dateAsOf

    useEffect(() => {        
        if (props.hasClaim_HealthView && stateNeedsLoading(props.healthPlans))
            props.fetchHealthPlans();

        if (props.hasClaim_WellBeingView && stateNeedsLoading(props.wellBeing))
            props.fetchWellBeing();
    });

    if (!props.hasClaim_WellBeingView && !props.hasClaim_HealthView) {
        return <></>;
    }

    const Title = () => (<>
        <PageTitle title="Well-Being Details" color="wellbeing-color-bg" />
        <Card.Title id="cardAsOfDate">Data as of {dateAsOf}</Card.Title>
    </>);

    const DesktopView = () => (
        <Card className="detailCard desktop">
            <Title />
            <Card.Body>
                <Row>
                    <Col className="col-12 col-lg-6">
                        {hasVp && <VirginPulse />}
                        {hasBfw && <Biometric isWellBeing />}
                        {hasMedical && <HealthQuotient />}
                        {hasMedical && <WW />}
                    </Col>
                    {hasMedical && <Col className="col-12 col-lg-6">
                        <EmployeeAssistance />
                        <DiseasePrevention />
                        <DiabetesPrevention />
                        <HealthCoaching />
                    </Col>}

                </Row>

            </Card.Body>
        </Card>
    );

    const MobileView = () => (
        <div className="detailCard mobile">
            <Card>
                <Title />
                <Card.Body>
                    {hasVp ? <VirginPulse /> : <EmployeeAssistance />}
                </Card.Body>
            </Card>

            {hasVp && <EmployeeAssistance />}
            {hasBfw && <Biometric isWellBeing />}
            {hasMedical && <>
                <HealthQuotient />
                <DiseasePrevention />
                <DiabetesPrevention />
                <WW />
                <HealthCoaching />
            </>}
        </div>
    );

    return (<>
        <BeneficiaryReviewModal />

        <UnlockBanner />

        <DesktopView />

        <MobileView />
    </>);
};

function mapStateToProps(state: AppState) {
    const { healthPlans, wellBeing } = state;

    return {
        wellBeing,
        healthPlans,
        hasClaim_WellBeingView: state.claims.claims?.claims?.find(c => c === WellBeing.View),
        hasClaim_HealthView: state.claims.claims?.claims?.find(c => c === Health.View)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        ...healthPlansActionCreators,
        ...wellBeingActionCreators
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WellBeingPage);


