import React, { ReactElement } from 'react';
import { formatZip } from '../../../../functions/formatZip';
import { titleCase } from '../../../../functions/titleCase';

const LsriPersonData = (
    props: {
        fullName: string,
        ssn?: string,
        addrLine1?: string,
        addrLine2?: string,
        addrLine3?: string,
        city?: string,
        state?: string,
        zip?: string,
        country?: string,
        dob?: string
    }
): ReactElement => (
    <p style={{ lineHeight: '20px' }}>

        <strong>{props.fullName}</strong><br />

        {props.ssn && <>
            SSN: {props.ssn && <>***-**-{props.ssn?.substr(5, 4)}</>}<br />
        </>}

        {props.addrLine1 && <>
            {titleCase(props.addrLine1)} <br />
        </>}

        {props.addrLine2 && <>
            {titleCase(props.addrLine2)} <br />
        </>}

        {props.addrLine3 && <>
            {titleCase(props.addrLine3)} <br />
        </>}

        {props.city && <>
            {titleCase(props.city)} <br />
        </>}

        {(props.state || props.zip) && <>
            {props.state && <>{props.state}, </>}{props.zip && <>{formatZip(props.zip)}</>} <br />
        </>}

        {props.dob && <>
            Date of Birth: {props.dob}
        </>}

    </p>
)
export { LsriPersonData };