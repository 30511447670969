import { useWindowDimensions } from '@wespath/gateway-navigation';
import React, { ReactElement, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const CollapsibleCard = (props: {
    headerContent?: string | ReactElement
    headerClassName?: string,
    expandCard?: boolean
    children: ReactElement | ReactElement[]
}): ReactElement => {

    const { headerContent, headerClassName, children, expandCard } = props;
    const { isSmallScreen } = useWindowDimensions();

    const enableLoadAnimation = isSmallScreen;

    const [isExpanded, setIsExpanded] = useState(!isSmallScreen);

    useEffect(() => {
        //Expand card if parent tells it to expand
        if (expandCard && !isExpanded)
            setIsExpanded(true)
    }, [expandCard])

    useEffect(() => {
        //Expand card if moving from small screen to desktop dimension
        if (!isSmallScreen && !isExpanded)
            setIsExpanded(true)
    }, [isSmallScreen])

    return (
        <Card>

            <Card.Header
                className={headerClassName ?? ''}
                style={!isExpanded ? { cursor: 'pointer' } : {}}
                onClick={() => !isExpanded ? setIsExpanded(true) : null}
            >
                {headerContent ?? ''}
            </Card.Header>

            {enableLoadAnimation
                ? <SlideDown className="collapsible-card">{isExpanded ? children : null}</SlideDown>
                : <>{children}</>
            }

        </Card>
    )
};
export { CollapsibleCard };