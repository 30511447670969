import { AppState } from "./configureStore";
import { GetCppEligibility, GetCppEligibilityResponse } from "./../Gateway.dtos";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface ILifeAndDisabilityState extends ILoadableState {
    cppEligibility: GetCppEligibilityResponse
}

export const initialCppEligibility: ILifeAndDisabilityState = {
    cppEligibility: {} as GetCppEligibilityResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'cpp',
    initialState: {} as ILifeAndDisabilityState,
    reducers: {
        resetState: () => {
            return {
                ...initialCppEligibility
            }
        },
        requestCppEligibility: (state: ILifeAndDisabilityState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveCppEligibility: (state: ILifeAndDisabilityState, action: PayloadAction<{ cppEligibility: GetCppEligibilityResponse }>) => {
            const { cppEligibility } = action.payload;
            return {
                ...state,
                cppEligibility,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveCppEligibilityError: (state: ILifeAndDisabilityState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }
    }
    ,
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialCppEligibility)
    }
})

const { resetState, requestCppEligibility, receiveCppEligibility, receiveCppEligibilityError } = slice.actions;

function fetchCppEligibility() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().cpp;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestCppEligibility());

        try {

            const cppEligibility = await client.post(new GetCppEligibility());

            if (cppEligibility.cppEligible)
                dispatch(displayPageActionCreators.showLifeAndDisability());

            dispatch(receiveCppEligibility({ cppEligibility }));

        } catch (e: unknown) {
            dispatch(receiveCppEligibilityError());
            console.log(e);
        }

    }
}

export const cppActionCreators = {
    fetchCppEligibility,
    resetState
};

export const cppReducer = slice.reducer;
