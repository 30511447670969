import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../..';
import { SidePanelWizard } from '../../SidePanelWizard';
import { wizardStepsCommit, wizardStepsManage } from './LsriWizard.steps';
import { LsriActionCreators } from '../../../store/LsriStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { push } from 'connected-react-router';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { LsriStage, FederalWithholdingOption, TaxOptions, FedMaritalStatus } from '../../../Gateway.dtos';
import { DropDown, DropDownOption } from '../../DropDown';
import { convertStringToNumber } from '../../../functions/convertStringToNumber';
import { cmsActionCreators } from '../../../store/CmsStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { LsriPersonData } from './Shared/LsriPersonData';
import { TaxInfo } from '../../../definitions/Claims';
import NumberFormat from 'react-number-format';
import { formatMoneyWithoutSymbol } from '../../../functions/formatMoney';
import { MessagePopup, MessageTypes } from '../../MessagePopup';
import { parseCmsContent } from "../../../functions/parseCmsContent";

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

const LsriTaxOptions = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();

    const { lsri } = props;
    const isLoading = lsri.loadState !== DataLoadState.Loaded && lsri.loadState !== DataLoadState.Error
    const isManage = lsri.wizardType === LsriStage.Manage;
    const hasClaim_Update = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === TaxInfo.Update)
    const hasClaim_View = useSelector((state: AppState) => state).claims?.claims?.claims?.find(c => c === TaxInfo.View)

    const showSuccessMsg = lsri.updateLoadState === DataLoadState.Loaded && isManage;
    const showErrorMsg = lsri.updateLoadState === DataLoadState.Error;
    const [taxOptions, setTaxOptions] = useState(lsri.lsriInfo?.taxOptions);
    const personalInfo = lsri.lsriInfo?.personalInfo;
    const [enableContinue, setEnableContinue] = useState(true)
    const disableRadioOptions = taxOptions?.federalWithholding === FederalWithholdingOption.DoNotWithhold
    const [disableAllInput, setDisableAllInput] = useState(taxOptions?.federalWithholding === FederalWithholdingOption.DoNotWithhold || taxOptions?.fedMaritalStatus === undefined || taxOptions?.fedMaritalStatus === FedMaritalStatus.Unknown || taxOptions?.fedMaritalStatus?.trim().length === 0)
    const submitIsLoading = lsri.updateLoadState === DataLoadState.Loading;
    const doesNotWithhold = !lsri.lsriInfo?.stateList?.states?.find(s => s.value === taxOptions?.stateOfResidence)?.withholdingFlag;

    const [totalTaxablePay, setTotalTaxablePay] = useState(0.00);
    const [totalOtherCredits, setTotalOtherCredits] = useState(0.00);
    const [showW4PPopup, setShowW4PPopup] = useState(false);
    const [showEstimatorPopup, setShowEstimatorPopup] = useState(false);

    const monthlyPayment = lsri.userElection?.lsriLifestage ?? 0;

    const wizardTitle = "LifeStage Retirement Income"
    const stepName = 'Tax Withholding';

    useEffect(() => {
        document.title = `Benefits Access - ${wizardTitle} - Tax Withholding`

        //Prevent invalid page rendering, usually due to back button usage
        if (lsri.loadState === DataLoadState.None) {
            dispatch(LsriActionCreators.setWizardType(LsriStage.Setup))
            dispatch(push(GatewayPath.LsriOverview))
        }

        if (lsri.wizardType === LsriStage.Commit)
            dispatch(LsriActionCreators.addVisitedStep({ path: GatewayPath.LsriTaxOptions }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //CMS Section
    const { cms } = useSelector((state: AppState) => state)
    const cmsSection = "TaxOptions"
    const cmsIsLoading = cms.lsri?.fragments?.findIndex(f => f.name.startsWith(`${cmsSection}/`)) < 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(cmsActionCreators.fetchLsriCmsData(cmsSection)) }, []);

    //const headerMsgCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsg`)?.html ?? '';
    //const headerMsgManageCms = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/HeaderMsgManage`)?.html ?? '';
    const headerTextW4P = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/headerTextW4P`)?.html ?? '';
    const tipText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/tipText`)?.html ?? '';
    const headerSecurityText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/headerSecurityText`)?.html ?? '';
    const sectionHeaderFederal = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/sectionHeaderFederal`)?.html ?? '';
    const step1HeaderText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step1HeaderText`)?.html ?? '';
    const step1ContactWespathText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step1ContactWespathText`)?.html ?? '';
    const step1Footer1Of2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step1Footer1Of2`)?.html ?? '';
    const step1Footer2Of2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step1Footer2Of2`)?.html ?? '';
    const step1SkipText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step1SkipText`)?.html ?? '';
    const step2HeaderText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2HeaderText`)?.html ?? '';
    const step2Header2Text = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2Header2Text`)?.html ?? '';
    const step2Message1Of2Text = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2Message1Of2Text`)?.html ?? '';
    const step2Message2Of2Text = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2Message2Of2Text`)?.html ?? '';
    const step2AText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2AText`)?.html ?? '';
    const step2BText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2BText`)?.html ?? '';
    const step2B_1Text = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2B_1Text`)?.html ?? '';
    const step2B_2Text = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2B_2Text`)?.html ?? '';
    const step2TipTextPart1Of2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2TipTextPart1Of2`)?.html ?? '';
    const step2TipTextPart2Of2 = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2TipTextPart2Of2`)?.html ?? '';
    const step2TotalText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step2TotalText`)?.html ?? '';
    const step3HeaderText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step3HeaderText`)?.html ?? '';
    const step3TotalIncomeText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step3TotalIncomeText`)?.html ?? '';
    const step3DependentChildrenText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step3DependentChildrenText`)?.html ?? '';
    const step3OtherDependentText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step3OtherDependentText`)?.html ?? '';
    const step3OtherOptionalCreditText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step3OtherOptionalCreditText`)?.html ?? '';
    const step3TotalText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step3TotalText`)?.html ?? '';
    const step4HeaderText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step4HeaderText`)?.html ?? '';
    const step4AText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step4AText`)?.html ?? '';
    const step4BText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step4BText`)?.html ?? '';
    const step4CText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step4CText`)?.html ?? '';
    const step5Text = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/step5Text`)?.html ?? '';
    const sectionHeaderState = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/sectionHeaderState`)?.html ?? '';

    const popupTitle = "Confirmation";
    const okButtonText = "Continue";
    const cancelButtonText = "Stay on Wespath's website";
    const confirmationMessageText = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/outsideWespathLinkConfirmationMessage`)?.html ?? '';
    const irsW4PLink = cms.lsri?.fragments?.find(f => f.name === `${cmsSection}/irsW4PLink`)?.html ?? '';

    //End CMS Section

    //Get State List
    const getStateDDLOptions = (): (DropDownOption)[] => {
        const ddlOptions: (DropDownOption)[] = [];

        ddlOptions.push({ value: "", display: "Select State", hideIfNotSelected: true });
        lsri.lsriInfo?.stateList?.states?.forEach(s => ddlOptions.push({ value: s.value, display: s.display }))

        return ddlOptions;
    }


    //Error validation

    const [showFedWithholdErr, setShowFedWithholdErr] = useState(false);
    function isFedWithholdValid(val: string | undefined, skipSetErr?: boolean) {
        const isValid = val !== undefined && val !== ''
        if (!skipSetErr) setShowFedWithholdErr(!isValid);
        return isValid;
    }

    const [showMaritalStatusErr, setShowMaritalStatusErr] = useState(false);
    function isMaritalStatusValid(val: string | undefined, skipSetErr?: boolean) {
        const isValid = taxOptions?.federalWithholding === FederalWithholdingOption.Withhold ? val !== undefined && val !== '' : true
        if (!skipSetErr) setShowMaritalStatusErr(!isValid);
        return isValid;
    }

    const [showStateErr, setShowStateErr] = useState(false);
    function isStateValid(val: string | undefined, skipSetErr?: boolean) {
        const isValid = val !== undefined && val !== ''
        if (!skipSetErr) setShowStateErr(!isValid);
        return isValid;
    }

    const [showExtraWithholdingError, setShowExtraWithholdingError] = useState(false);
    function isAmountValid(val: string | undefined, skipSetErr?: boolean) {
        const extraWithholdingAmount = (val !== undefined && val !== '' ? parseFloat(val) : 0);

        const isValid = extraWithholdingAmount <= monthlyPayment;
        if (!skipSetErr) setShowExtraWithholdingError(!isValid);
        return isValid;
    }

    useEffect(
        () => {
            const initWithholdingAmount = taxOptions?.extraWithholding !== undefined && taxOptions?.extraWithholding !== '' ? parseFloat(taxOptions?.extraWithholding) : 0;
            if (initWithholdingAmount > monthlyPayment)
                setShowExtraWithholdingError(true);
            else
                setShowExtraWithholdingError(false)

        }, [taxOptions?.extraWithholding, monthlyPayment]
    )

    const isInlineErrors = (skipSetErr?: boolean) => {
        let isErr = false

        //Check for errors so that they all display
        //isErr = !isFedWithholdValid(taxOptions?.federalWithholding, skipSetErr) || isErr;
        //isErr = !isMaritalStatusValid(taxOptions?.fedMaritalStatus, skipSetErr) || isErr;
        //isErr = !isAllowancesValid(taxOptions?.numberOfAllowances, skipSetErr) || isErr;
        //isErr = !isAmountValid(taxOptions?.extraWithholding, skipSetErr) || isErr;
        isErr = !isMaritalStatusValid(taxOptions?.fedMaritalStatus, skipSetErr) || isErr;
        isErr = !isAmountValid(taxOptions?.extraWithholding, skipSetErr) || isErr;
        isErr = !isStateValid(taxOptions?.stateOfResidence, skipSetErr) || isErr;

        return isErr;
    }

    useEffect(() => {
        setEnableContinue(
            !showFedWithholdErr &&
            !showStateErr &&
            !showExtraWithholdingError &&
            (taxOptions?.federalWithholding === FederalWithholdingOption.Withhold
                ? (!showMaritalStatusErr)
                : true
            )
        )
    }, [showFedWithholdErr, showStateErr, taxOptions?.federalWithholding, taxOptions?.fedMaritalStatus, showMaritalStatusErr, showExtraWithholdingError])


    useEffect(() => {
        //Save the election to state
        dispatch(LsriActionCreators.updateTaxOptions(taxOptions));

        //Check if check mark should be added or removed based on errors     
        if (!isManage && !isInlineErrors(true)) {
            dispatch(LsriActionCreators.addCheckedMenuOptions(GatewayPath.LsriTaxOptions))
            dispatch(LsriActionCreators.enableVerifyCheck())
        }
        else
            dispatch(LsriActionCreators.removeCheckedMenuOptions(GatewayPath.LsriTaxOptions))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxOptions])


    //Action handling

    const handleClickContinue = () => {
        if (isInlineErrors())
            return;

        if (!isManage) {
            dispatch(push(GatewayPath.LsriBankInfo));
        }
        else {
            dispatch(LsriActionCreators.submitTaxOptions({ taxOptions }));
        }
    }

    //Hide the success message
    useEffect(() => {
        if (lsri.updateLoadState === DataLoadState.Loaded)
            setTimeout(() => { dispatch(LsriActionCreators.resetUpdateLoadState()) }, 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lsri.updateLoadState]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { dispatch(LsriActionCreators.resetUpdateLoadState()) }, [])

    useEffect(
        () => {
            setTotalTaxablePay(convertStringToNumber(taxOptions?.taxableAnnualPay) + convertStringToNumber(taxOptions?.otherTaxablePay))
        }, [taxOptions.taxableAnnualPay, taxOptions.otherTaxablePay]
    )

    useEffect(
        () => {
            setTotalOtherCredits(convertStringToNumber(taxOptions?.dependentChildrenCredits) + convertStringToNumber(taxOptions?.dependentOtherCredits) + convertStringToNumber(taxOptions?.otherOptionalCredits))
        }, [taxOptions.dependentChildrenCredits, taxOptions.dependentOtherCredits, taxOptions.otherOptionalCredits]
    )

    useEffect(
        () => {
            if (taxOptions?.federalWithholding === FederalWithholdingOption.DoNotWithhold || taxOptions?.fedMaritalStatus === undefined || taxOptions?.fedMaritalStatus === FedMaritalStatus.Unknown || taxOptions?.fedMaritalStatus?.trim().length === 0) {
                setDisableAllInput(true);
            }
            else {
                setDisableAllInput(false);
            }
        }, [taxOptions?.fedMaritalStatus, taxOptions?.federalWithholding]
    )

    const handleClickCancel = () => {
        dispatch(LsriActionCreators.wizardCancel())
    }

    const handleFedWithholdChange = (federalWithholding: FederalWithholdingOption) => {
        if (isFedWithholdValid(federalWithholding))
            setTaxOptions({ ...taxOptions, federalWithholding } as TaxOptions)

        if (federalWithholding === FederalWithholdingOption.DoNotWithhold) {
            resetAllFields();
        }
    }

    const handleMaritalStatusChange = (fedMaritalStatus: FedMaritalStatus) => {
        if (isMaritalStatusValid(fedMaritalStatus))
            setTaxOptions({ ...taxOptions, fedMaritalStatus } as TaxOptions)
    }

    //const handleAllowancesChange = (numberOfAllowances: string, skipSetErr: boolean, allowBlank: boolean) => {
    //    if (isAllowancesValid(numberOfAllowances, skipSetErr, allowBlank))
    //        setTaxOptions({ ...taxOptions, numberOfAllowances: numberOfAllowances === "" ? undefined : convertStringToNumber(numberOfAllowances) } as TaxOptions)
    //}

    //const handleAdditionalAmtChange = (additionalAmount: string) => {
    //    setTaxOptions({ ...taxOptions, additionalAmount: additionalAmount === "" ? undefined : convertDollarPctToNumber(additionalAmount) } as TaxOptions)
    //}

    const handleStateRadioBtnChange = (stateOfResidence: string) => {
        if (isStateValid(stateOfResidence))
            setTaxOptions({ ...taxOptions, stateOfResidence } as TaxOptions)
    }

    const handleTaxableAnnualPayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, taxableAnnualPay: e.target.value } as TaxOptions)
    }

    const handleOtherTaxablePayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, otherTaxablePay: e.target.value } as TaxOptions)
    }

    const handleDependentChildrenCreditsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, dependentChildrenCredits: e.target.value } as TaxOptions)
    }

    const handleDependentOtherCreditsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, dependentOtherCredits: e.target.value } as TaxOptions)
    }

    const handleOtherOptionalCreditsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, otherOptionalCredits: e.target.value } as TaxOptions)
    }

    const handleOtherIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, otherIncome: e.target.value } as TaxOptions)
    }

    const handleDeductionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, deductions: e.target.value } as TaxOptions);
    }

    const handleExtraWithholdingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxOptions({ ...taxOptions, extraWithholding: e.target.value } as TaxOptions)
        isAmountValid(e.target.value)
    }

    function openW4PPopup() {
        setShowW4PPopup(true);
    }

    const W4POkClick = () => {
        window.open(irsW4PLink, "_blank");
    }

    const EstimatorOkClick = () => {
        window.open("http://www.irs.gov/W4App", "_blank");
    }

    const SuccessMsg = () => (
        <Alert variant="success" className="mt-5 fade-out">
            <FontAwesomeIcon icon={faCheckCircle} /><strong>Success</strong><br />
            Your Tax Options have been updated.
        </Alert>
    )

    const ErrorMsg = () => (
        <Alert variant="error" className="mt-5">
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while updating your Tax Options.
        </Alert>
    )

    interface StepAndHeaderProps { stepText: string, headerText?: string | undefined }
    const StepAndHeader = (props: StepAndHeaderProps) => (
        <>
            <h5 className={"mt-5 mb-3 ret-color font-weight-bold"}>{props.stepText}</h5>
            {props.headerText && <h6 className={"mb-3 ret-color font-weight-bold"}>{props.headerText}</h6>}
        </>
    )

    interface SectionHeaderProps { displayText: string }
    const SectionHeader = (props: SectionHeaderProps) => (
        <h4 className={"mb-3 ret-color font-weight-bold"}>{props.displayText}</h4>
    )

    const W4PLink = () => (
        <NavLink to="#" onClick={() => openW4PPopup()}>Form W-4P</NavLink>
    )

    function resetAllFields() {
        setTaxOptions({
            ...taxOptions,
            federalWithholding: FederalWithholdingOption.DoNotWithhold,
            fedMaritalStatus: undefined,
            taxableAnnualPay: "",
            otherTaxablePay: "",
            dependentChildrenCredits: "",
            dependentOtherCredits: "",
            otherOptionalCredits: "",
            otherIncome: "",
            deductions: "",
            extraWithholding: "",
        } as TaxOptions);
    }

    const HeaderAndTip = () => (
        <>
            <p dangerouslySetInnerHTML={parseCmsContent(headerTextW4P)} />
            <p dangerouslySetInnerHTML={parseCmsContent(headerSecurityText)} />

            <div className={"mb-4 alert alert-primary border border-primary"} role="alert">
                <Row className="mb-1 alert-primary font-weight-bold">
                    <Col>
                        TIP
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {tipText}
                    </Col>
                </Row>
            </div>
        </>
    )

    const MessagePopups = () => (
        <>
            <MessagePopup showPopup={showW4PPopup} popupTitle={popupTitle} messageType={MessageTypes.Other} okButtonText={okButtonText}
                showCancelButton={true} cancelButtonText={cancelButtonText} onOkClick={W4POkClick} cancelAsLink={true}
                resetPopupFlag={(e: boolean | ((prevState: boolean) => boolean)) => { setShowW4PPopup(e) }}>
                <span dangerouslySetInnerHTML={parseCmsContent(confirmationMessageText)} />
            </MessagePopup>
            <MessagePopup showPopup={showEstimatorPopup} popupTitle={popupTitle} messageType={MessageTypes.Info} okButtonText={okButtonText}
                showCancelButton={true} cancelButtonText={cancelButtonText} onOkClick={EstimatorOkClick}
                resetPopupFlag={(e: boolean | ((prevState: boolean) => boolean)) => { setShowEstimatorPopup(e) }}>
                {confirmationMessageText}
            </MessagePopup>
        </>
    )

    const PersonalData = () => (<>
        <LsriPersonData
            fullName={personalInfo?.name}
            ssn={personalInfo?.ssn}
            addrLine1={personalInfo?.streetAddress1}
            addrLine2={personalInfo?.streetAddress2}
            addrLine3={personalInfo?.streetAddress3}
            city={personalInfo?.city}
            state={personalInfo?.state}
            zip={personalInfo?.zipCode}
            country={personalInfo?.countryCode}
        />

        {/*personalInfo?.maritalStatus?.toLowerCase() === "married" && <LsriPersonData
            fullName={personalInfo?.spouseName}
            ssn={personalInfo?.spouseSsn}
            addrLine1={personalInfo?.spouseLine1}
            addrLine2={personalInfo?.spouseLine2}
            addrLine3={personalInfo?.spouseLine3}
            city={personalInfo?.spouseCity}
            state={personalInfo?.spouseState}
            zip={personalInfo?.spousePostalCode}
            country={personalInfo?.spouseCountryCode}
        />*/}
    </>)


    return (

        <SidePanelWizard
            stepName={stepName}
            wizardTitle={wizardTitle}
            wizardColor="ret-color"
            printBtnText="Print"
            progressBar={{
                activeIndex: lsri.wizardType === LsriStage.Manage ? 3 : 1,
                steps: lsri.wizardType === LsriStage.Manage ? wizardStepsManage : wizardStepsCommit,
                menuOptions: lsri.menuOptions
            }}
        >

            {isLoading || cmsIsLoading ? <LoadingSkeleton /> : <>

                {hasClaim_View && <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();

                    if (enableContinue)
                        handleClickContinue();
                }}>

                    <HeaderAndTip />

                    {/*Step-1*/}
                    <SectionHeader displayText={sectionHeaderFederal} />
                    <StepAndHeader stepText={"Step 1"} headerText={step1HeaderText} />
                    <p dangerouslySetInnerHTML={parseCmsContent(step1ContactWespathText)} />

                    <PersonalData />

                    <p className="mt-4" dangerouslySetInnerHTML={parseCmsContent(step1SkipText)} />

                    <h6 className={"mt-4 mb-1 ret-color font-weight-bold"}>Status</h6>

                    <Form.Check type="radio" name="_fedmaritalstatus" id="_fedmaritalstatusSI" value="si" className="mt-2"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaritalStatusChange(FedMaritalStatus.Single)}
                        checked={taxOptions?.fedMaritalStatus === FedMaritalStatus.Single}
                        label="Single or Married filing separately"
                        disabled={disableRadioOptions}
                        tabIndex={2}
                    />
                    <Form.Check type="radio" name="_fedmaritalstatus" id="_fedmaritalstatusMA" value="ma" className="mt-2"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaritalStatusChange(FedMaritalStatus.Married)}
                        checked={taxOptions?.fedMaritalStatus === FedMaritalStatus.Married}
                        label="Married filing jointly or Qualifying surviving spouse"
                        disabled={disableRadioOptions}
                        tabIndex={3}
                    />
                    <Form.Check type="radio" name="_fedmaritalstatus" id="_fedmaritalstatusMS" value="ms" className="mt-2"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaritalStatusChange(FedMaritalStatus.HeadOfHousehold)}
                        checked={taxOptions?.fedMaritalStatus === FedMaritalStatus.HeadOfHousehold}
                        label="Head of household (Check only if you are unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.)"
                        disabled={disableRadioOptions}
                        tabIndex={4}
                    />

                    <p className={"my-5"}>
                        <span dangerouslySetInnerHTML={parseCmsContent(step1Footer1Of2)} /><W4PLink /><span dangerouslySetInnerHTML={parseCmsContent(step1Footer2Of2)} />
                    </p>



                    {/*Step-2*/}
                    <StepAndHeader stepText={"Step 2"} headerText={step2HeaderText} />
                    <p><span dangerouslySetInnerHTML={parseCmsContent(step2Message1Of2Text)} /><b><W4PLink /></b> <span dangerouslySetInnerHTML={parseCmsContent(step2Message2Of2Text)} /></p>
                    <p dangerouslySetInnerHTML={parseCmsContent(step2Header2Text)} />
                    <p dangerouslySetInnerHTML={parseCmsContent(step2AText)} />
                    <p dangerouslySetInnerHTML={parseCmsContent(step2BText)} />

                    <EntryBox
                        text={step2B_1Text}
                        textIdName="_taxableannualpay"
                        value={taxOptions?.taxableAnnualPay}
                        textboxOnChange={handleTaxableAnnualPayChange}
                        disableAllInput={disableAllInput}
                        tabIndex={5}
                        indent
                    />

                    <EntryBox
                        text={step2B_2Text}
                        textIdName="_othertaxablepay"
                        value={taxOptions?.otherTaxablePay}
                        textboxOnChange={handleOtherTaxablePayChange}
                        disableAllInput={disableAllInput}
                        tabIndex={6}
                        indent
                    />

                    <TotalRow text={step2TotalText} value={totalTaxablePay} indent />

                    <h6 className={"ret-color font-weight-bold mt-4"}>TIP</h6>
                    <span dangerouslySetInnerHTML={parseCmsContent(step2TipTextPart1Of2)} /><W4PLink /><span dangerouslySetInnerHTML={parseCmsContent(step2TipTextPart2Of2)} />




                    {/*Step-3*/}
                    <StepAndHeader stepText={"Step 3"} headerText={step3HeaderText} />
                    <p dangerouslySetInnerHTML={parseCmsContent(step3TotalIncomeText)} />

                    <EntryBox
                        text={step3DependentChildrenText}
                        textIdName="_dependentchildrencredits"
                        value={taxOptions?.dependentChildrenCredits}
                        textboxOnChange={handleDependentChildrenCreditsChange}
                        disableAllInput={disableAllInput}
                        tabIndex={7}
                        indent
                    />

                    <EntryBox
                        text={step3OtherDependentText}
                        textIdName="_dependentothercredits"
                        value={taxOptions?.dependentOtherCredits}
                        textboxOnChange={handleDependentOtherCreditsChange}
                        disableAllInput={disableAllInput}
                        tabIndex={8}
                        indent
                    />

                    <EntryBox
                        text={step3OtherOptionalCreditText}
                        textIdName="_otheroptionalcredits"
                        value={taxOptions?.otherOptionalCredits}
                        textboxOnChange={handleOtherOptionalCreditsChange}
                        disableAllInput={disableAllInput}
                        tabIndex={9}
                    />

                    <TotalRow text={step3TotalText} value={totalOtherCredits} />



                    {/*Step-4*/}
                    <StepAndHeader stepText={"Step 4"} headerText={step4HeaderText} />

                    <EntryBox
                        text={step4AText}
                        textIdName="_otherincome"
                        value={taxOptions?.otherIncome}
                        textboxOnChange={handleOtherIncomeChange}
                        disableAllInput={disableAllInput}
                        tabIndex={10}
                    />

                    <EntryBox
                        text={step4BText}
                        textIdName="_deductions"
                        value={taxOptions?.deductions}
                        textboxOnChange={handleDeductionsChange}
                        disableAllInput={disableAllInput}
                        tabIndex={11}
                    />

                    <EntryBox
                        text={step4CText}
                        textIdName="_extrawithholding"
                        value={taxOptions?.extraWithholding}
                        textboxOnChange={handleExtraWithholdingChange}
                        disableAllInput={disableAllInput}
                        tabIndex={12}
                        errMsg={showExtraWithholdingError ? "Extra withholding amount cannot exceed the estimated benefit amount." : undefined}
                    />




                    {/*Step-5*/}
                    <StepAndHeader stepText={"Step 5"} />

                    <Form.Check style={{ float: "left" }} type="checkbox" name="_fedtaxflag" id="_fedtaxflag"
                        value={taxOptions?.federalWithholding === FederalWithholdingOption.DoNotWithhold ? FederalWithholdingOption.DoNotWithhold : FederalWithholdingOption.Withhold}
                        checked={taxOptions?.federalWithholding === FederalWithholdingOption.DoNotWithhold}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFedWithholdChange(e.target.checked ? FederalWithholdingOption.DoNotWithhold : FederalWithholdingOption.Withhold)}
                        label="" /><span dangerouslySetInnerHTML={parseCmsContent(step5Text)}
                        tabIndex={13}
                    />


                    {/*State Withholding*/}
                    <div className="mt-5">
                        <SectionHeader displayText={sectionHeaderState} />

                        <Form.Group className={`${showStateErr ? "error" : ""}`} style={{ maxWidth: `${isSmallScreen ? 'initial' : '20rem'}` }}>
                            <Form.Label>State of Residence</Form.Label>
                            <DropDown name="state"
                                dropDownOptions={getStateDDLOptions()}
                                defaultValue={taxOptions?.stateOfResidence}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleStateRadioBtnChange(e.target.value) }}
                                tabIndex={14}
                            />
                            {showStateErr && <div className="errMsg">Invalid State</div>}
                        </Form.Group>

                        {doesNotWithhold
                            ? <p>We do not withhold state income taxes for your state of residence.</p>
                            : <p>
                                We will withhold state income taxes at the state&apos;s default rate unless you submit
                                a <a href="API/Redirect/https%3A%2F%2Fwww.wespath.org%2Fassets%2F1%2F7%2F3421.PDF/l" target="_blank" rel="noopener noreferrer">State Tax Withholding</a> form
                                instructing otherwise.
                            </p>
                        }
                    </div>



                    {showSuccessMsg && <SuccessMsg />}

                    {showErrorMsg && <ErrorMsg />}

                    <div className="mt-5 pt-4">

                        {(isManage ? hasClaim_Update : true) && <>
                            <Button variant="primary" tabIndex={15} className={`${isSmallScreen ? 'w-100' : ''}`} disabled={!enableContinue || submitIsLoading} onClick={() => handleClickContinue()}>
                                {submitIsLoading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : <>{isManage ? 'Agree & Submit' : 'Continue'}</>}
                            </Button>

                            {!isSmallScreen && <span className="ml-4 mr-3">|</span>}
                        </>}

                        <Button variant="link px-0 mx-0" tabIndex={16} className={`${isSmallScreen ? 'w-100 mt-3' : ''}`} disabled={submitIsLoading} onClick={() => handleClickCancel()}>
                            Cancel
                        </Button>

                    </div>

                </Form>}

            </>}

            <MessagePopups />

        </SidePanelWizard>

    );
};

const EntryBox = (props: { text: string, value: string, textboxOnChange?: any, textIdName: string, indent?: boolean, disableAllInput: boolean, errMsg?: string, tabIndex?: number }) => {

    const rowClass = `mb-4`
    const col1Class = props.indent
        ? `offset-2 col-10 offset-sm-1 col-sm-6 offset-md-2 col-md-10 offset-lg-1 col-lg-6 col-xl-7 mb-2 mb-sm-0 mb-md-2 md-lg-0`
        : `col-12 col-sm-7 col-md-12 col-lg-7 col-xl-8 mb-2 mb-sm-0 mb-md-2 md-lg-0`
    const col2Class = props.indent
        ? `offset-2 col-10 offset-sm-0 col-sm-5 offset-md-2 col-md-10 offset-lg-0 col-lg-5 col-xl-4`
        : `col-12 col-sm-5 col-md-12 col-lg-5 col-xl-4`

    const showErr = props.errMsg ?? false

    return (
        <Row className={rowClass}>
            <Col className={col1Class}>
                <span dangerouslySetInnerHTML={parseCmsContent(props.text)} />
            </Col>
            <Col className={col2Class}> 
                <Form.Group className={`${showErr ? "error" : ""}`} >
                    <span style={{ float: "left", marginRight: "5px", marginTop: "8px" }}>$ </span>
                    <NumberFormat
                        customInput={Form.Control}
                        style={{ maxWidth: `160px`, textAlign: "right" }}
                        id={props.textIdName}
                        name={props.textIdName}
                        key={props.textIdName + "_textbox"}
                        decimalScale={2}
                        maxLength={10}
                        disabled={props.disableAllInput}
                        onFocus={(e) => { e.target.select() }}
                        value={props.value ?? ""}
                        allowNegative={false}
                        onChange={(e) => { props.textboxOnChange(e) }}
                        tabIndex={props.tabIndex}
                    />
                    {showErr && <div className="errMsg">{props.errMsg}</div>}
                </Form.Group>
            </Col>
        </Row>
    )
}

const TotalRow = (props: { text: string, value: number, indent?: boolean }) => {

    const rowClass = `font-weight-bold`
    const col1Class = props.indent
        ? `offset-2 col-10 offset-sm-1 col-sm-6 offset-md-2 col-md-10 offset-lg-1 col-lg-6 col-xl-7 mb-2 mb-sm-0 mb-md-2 md-lg-0`
        : `col-12 col-sm-7 col-md-12 col-lg-7 col-xl-8 mb-2 mb-sm-0 mb-md-2 md-lg-0`
    const col2Class = props.indent
        ? `offset-2 col-10 offset-sm-0 col-sm-5 offset-md-2 col-md-10 offset-lg-0 col-lg-5 col-xl-4`
        : `col-12 col-sm-5 col-md-12 col-lg-5 col-xl-4`

    return (
        <Row className={rowClass} >
            <Col className={col1Class}>
                <span className={"mb-3"} dangerouslySetInnerHTML={parseCmsContent(props.text)} />
            </Col>
            <Col className={col2Class}>
                <div style={{ float: "left", marginLeft: "180 px", marginTop: "0px" }}>{"$ "}</div>
                <div style={{ maxWidth: `170px`, textAlign: "right" }}>{formatMoneyWithoutSymbol(props.value, true)}</div>
            </Col>
        </Row>
    )
}

function mapStateToProps(state: AppState) {
    return {
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriTaxOptions);

