import { Phone, PhoneType } from "../Gateway.dtos";
import { findPhoneByType } from "./findPhoneByType";

const getDefaultPhone = function (phones?: Phone[]): string {

    if (!phones)
        return ''

    let retPhone = findPhoneByType(phones, PhoneType.MOBILE);

    if (!retPhone)
        retPhone = findPhoneByType(phones, PhoneType.PRIMARY);

    if (!retPhone)
        retPhone = findPhoneByType(phones, PhoneType.SECONDARY);

    return retPhone.number

}

export { getDefaultPhone }