import { AppState } from "./configureStore";
import { GetMPPDistributionResponse, GetMPPDistribution } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { accountActionCreators } from "./AccountStore";

interface Error {
    errorCode: string;
    errorMessage: string
}

export interface IMPPDistributionState extends IEnumLoadableState {
    mppDistribution: GetMPPDistributionResponse
    responseError:Error
}

export const initialMPPDistribution: IMPPDistributionState = {
    mppDistribution: {} as GetMPPDistributionResponse,
    responseError: {} as Error,
    loadState: DataLoadState.None
};

const getMPPDistribution = createAsyncThunk<
    GetMPPDistributionResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'mppDistribution/getMPPDistribution',
    async (_, thunkApi) => {
        try {

            const mppDistribution = await client.post(new GetMPPDistribution());

            if (mppDistribution.responseStatus) {
                throw (mppDistribution.responseStatus);
            }

            return mppDistribution;

        } catch (e: unknown) {
            const response: GetMPPDistributionResponse = e as GetMPPDistributionResponse;
            if (response?.responseStatus?.errorCode?.toLowerCase() === "unauthorized")
                thunkApi.dispatch(accountActionCreators.accessDenied("You don't have access to this page."));

            return thunkApi.rejectWithValue(response?.responseStatus
                ? { errorMessage: response?.responseStatus.message, errorCode: response?.responseStatus.errorCode }
                : { errorMessage: "Unknown error.", errorCode: "" });
        }
    }

)

const slice = createSlice({
    name: 'mppDistribution',
    initialState: initialMPPDistribution,
    reducers: {
        resetState: () => initialMPPDistribution
    },
    extraReducers: builder => {
        builder
            .addCase(getMPPDistribution.pending, (state: IMPPDistributionState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(getMPPDistribution.fulfilled, (state: IMPPDistributionState, { payload }) => {
                state.loadState = DataLoadState.Loaded;
                state.mppDistribution = payload;
            })
            .addCase(getMPPDistribution.rejected, (state: IMPPDistributionState, action) => {
                state.responseError = {
                    errorMessage: action.payload !== undefined ? action.payload.errorMessage : "Unknown error."
                    , errorCode: action.payload !== undefined ? action.payload.errorCode : "000"
                };
                state.loadState = DataLoadState.Error;
            })
            .addCase(getResetAction(), (_state, _action) => initialMPPDistribution)
    }
})

const { resetState } = slice.actions;

export const mppDistributionCreators = {
    getMPPDistribution,
    resetState
};

export const mppDistributionReducer = slice.reducer;