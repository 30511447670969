import React, { useEffect } from 'react';
import { Investment, RateOfReturn } from '../../../Gateway.dtos';
import { Card, Button, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatPercent } from '../../FormatPercent';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { investmentsActionCreators } from '../../../store/InvestmentsStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../../..';
import { AmountPanelSmall } from '../../AmountPanelSmall';
import { push } from 'connected-react-router';
import { BAConvertedRoutes } from '../../../definitions/BAConvertedRoutes';
import { NavLink } from 'react-router-dom';


export interface OwnProps { isSummary?: boolean }
type TInvestmentsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Investments = (props: TInvestmentsProps) => {

    const dispatch: AppDispatch = useDispatch();

    const isLoading = (props.isLoading && !props.isLoaded);
    const isErr = !isLoading && props.investments?.responseStatus !== undefined;
    const showCard = isErr || isLoading || props.investments?.investments?.length > 0;
    const cardTitle = "Investments"
    const asOfDate = props.investments?.investments?.length > 0 ? props.investments?.investments[0]?.asOfDate : null;

    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.fetchInvestments();
        }
    });

    function GetLabelName(itemName: string): string {
        if (itemName.toUpperCase() === "MPP") {
            return "MPP (YTD)"
        }
        return "Combined DC (YTD)";
    }


    if (!showCard) {
        return <></>;
    }
    else if (isLoading) {
        return <>{!props.isSummary && <LoadingSkeleton />}</>;
    }
    else if (props.isSummary) {

        return (<>
            <Row>

                {!isErr && props.investments?.investments?.map((item, i) => (
                    <Col key={i} className="col-6">
                        <RateOfReturnLabel label={GetLabelName(item.name)} rates={item.ratesOfReturn} />
                    </Col>
                ))}

                {isErr && <Col className="col-12"><AmountPanelSmall label="Combined DC (YTD)" isErr noBottomBorder /></Col>}

            </Row>

            {!isErr && asOfDate && <Row>
                <Col className="footnote amountPanelSmall">
                    The posted rates of return are calculated as of {asOfDate}.
                </Col>
            </Row>}

            <div className="bottomBorder" />
        </>);

    }
    else {

        return (
            <Card className="pageCard secondary">
                <Card.Title>
                    <FontAwesomeIcon icon={faChartLine} className="ret-color" />
                    Investments
                </Card.Title>
                <Card.Body>

                    {isErr && <Alert className="mt-2" variant="danger">Unable to retrieve data for {cardTitle}</Alert>}

                    {props.investments.investments?.map((item, i) => (
                        <InvestmentPanel key={i} investment={item} />
                    ))}                   

                    {!isErr && asOfDate && <p className="mb-4 thin">
                        The posted rates of return are calculated as of {asOfDate}.
                    </p>}

                    <div className="mt-0 pt-2">
                        <Button variant="secondary" onClick={() => dispatch(push(BAConvertedRoutes.InvestmentsSummary))}>Manage Investment Options</Button>
                    </div>

                    <p className="mt-4 pt-2 thin mb-0 font-italic">
                        EY advisors are available at 1-800-360-2539.
                    </p>

                </Card.Body>
            </Card>
        );

    }

}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...state.investments,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...investmentsActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Investments);


const InvestmentPanel = (props: { investment: Investment, isLoading?: boolean }) => {
    const { name, risk, socialSecElig, ratesOfReturn } = props.investment

    const msg = InvestmentMsg(name, risk, socialSecElig);

    return (<>
        <h4>{name === "MPP" ? "MPP " : "Combined DC"}</h4>
        {ratesOfReturn && <RateOfReturnTable pageData={ratesOfReturn} />}
        <p className="mb-4 thin">{msg}</p>
    </>)
};

function InvestmentMsg(name: string, risk: string, socialSecElig: boolean) {

    const invType = name === "MPP" ? "MPP " : "";
    const ssElig = (socialSecElig ? "" : "No ") + "Social Security Eligibility.";

    if (name === "MPP")
        return "Your " + invType + "investments are professionally managed by LifeStage. Your current allocations are aligned with a " + risk + " tolerance and " + ssElig;
    else
        return <><NavLink to={BAConvertedRoutes.InvestmentsSummary}>View Details</NavLink> of your current account allocations.</>;

}

const RateOfReturnTable = (props: { pageData: RateOfReturn[] }) => {
    const rates = props.pageData;

    return (<>
        <Row className="mt-3 mx-0">
            <Col className="col-12 col-md-6 px-0 mr-0 text-nowrap"><h5>Net Rate of Return (YTD)</h5></Col>
            <Col className="col-12 col-md-6 ror-header-right">
                <h4>
                    <Row>
                        <Col className="text-right mx-0 px-0 text-nowrap">
                            {rates.map((item, i) => (
                                item.label === "YTD" && <FormatPercent key={i} percent={item.rate} showCaret />
                            ))}
                        </Col>
                    </Row>
                </h4>
            </Col>
        </Row>

        <RateOfRetRow rates={rates}/>

    </>);
}

const RateOfRetRow = (props: { rates: RateOfReturn[] }) => (
    <Row className="ror-table mx-0">
        {props.rates.map((item, i) => (
            <Col key={i} className="col-2 ror-cell">
                {item.label}<br />
                <FormatPercent percent={item.rate} />
            </Col>
        ))}
    </Row>
);

const RateOfReturnLabel = (props: { label: string, rates: RateOfReturn[], isLoading?: boolean, isErr?: boolean }) => {

    const ytdIndex = props.rates.findIndex(i => i.label === "YTD");

    return (
        <AmountPanelSmall
            label={props.label}
            percent={props.rates[ytdIndex].rate}
            isLoading={props.isLoading}
            isErr={props.isErr}
            noBottomBorder
            isPercent
        />
    );
}

const LoadingSkeleton = () => (
    <Card className="pageCard secondary pb-3">
        <div><Skeleton width={'10em'} height={'2em'} /></div>

        <div className="mt-3"><Skeleton width={'12em'} height={'1.5em'} /></div>
        <div><Skeleton width={'70%'}/></div>

        <div className="mt-3"><Skeleton height={'1.5em'} /></div>
        <div><Skeleton height={'3em'} /></div>
    </Card>
);