import { AppState } from "./configureStore";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { client } from "../App";
import { GetBalanceResponse, GetBalance } from "../Gateway.dtos";
import { displayPageActionCreators } from "./DisplayPageStore";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";

export interface IBalanceState extends ILoadableState {
    balance: GetBalanceResponse
}

export const initialBalanceState: IBalanceState = {
    balance: {} as GetBalanceResponse,
    isLoading: false,
    isLoaded: false
};

const slice = createSlice({
    name: 'balance',
    initialState: {} as IBalanceState,
    reducers: {
        resetState: () => {
            return {
                ...initialBalanceState
            }
        },
        requestBalance: (state: IBalanceState) => {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        },
        receiveBalance: (state: IBalanceState, action: PayloadAction<{ balance: GetBalanceResponse }>) => {
            const { balance } = action.payload;
            return {
                ...state,
                balance,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveBalanceError: (state: IBalanceState) => {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: true
            }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (_state, _action) => initialBalanceState)
    }
})

const { resetState, requestBalance, receiveBalance, receiveBalanceError } = slice.actions;

function fetchBalance() {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().balance;
        if (isLoaded || isLoading) {
            return;
        }

        dispatch(requestBalance());

        try {

            const balance = await client.post(new GetBalance());

            dispatch(receiveBalance({ balance }));

            if (balance.balanceAmt > 0 || balance.installments?.length > 0 || balance.showRetirement)
                dispatch(displayPageActionCreators.showRetirement());

        } catch (e: unknown) {
            dispatch(receiveBalanceError());
            console.log(e);
            dispatch(displayPageActionCreators.showRetirement());
        }

        

    }
}

export const balanceActionCreators = {
    fetchBalance,
    resetState
};

export const balanceReducer = slice.reducer;
