import React, { useState, useEffect, useRef } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking, faMobileAlt } from '@fortawesome/pro-solid-svg-icons';
import { PanelWithIcon } from '../../PanelWithIcon';
import { AppState } from '../../../store/configureStore';
import { connect } from 'react-redux';
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { cmsActionCreators } from '../../../store/CmsStore';
import { SamlSelfPostForm } from '@wespath/gateway-navigation'
import { ServiceProvider } from '../../../Gateway.dtos';
import { LoadingSkeleton } from '../../LoadingSkeleton';

export interface OwnProps { isSummary?: boolean }
type TVirginPulseProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const VirginPulse = ({
    username,
    hasClaim_HealthAccess,
    cmsData,
    isSummary,
    fetchCmsData
}: TVirginPulseProps) => {

    const cmsVirginPulseShared = cmsData.fragments?.find(f => f.name === "VirginPulseShared");
    const cmsVirginPulseDetails = cmsData.fragments?.find(f => f.name === "VirginPulseDetails");

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.VirginPulse;

    useEffect(() => {
        if (stateNeedsLoading(cmsData)) {
            fetchCmsData();
        }
    });

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }    
    }

    const SharedPanel = () => (
        <PanelWithIcon icon={faMobileAlt} iconColor="wellbeing-color" title={`${isSummary ? "Virgin Pulse" : ""}`} subPanel={!isSummary}>
            {cmsVirginPulseShared ? <span dangerouslySetInnerHTML={{ __html: cmsVirginPulseShared.html }} /> : <LoadingSkeleton className="mb-0" />}
        </PanelWithIcon>
    );


    if (isSummary) {
        return <SharedPanel />;
    }
    else {

        return (<>
            <Card className="pageCard">
                <Card.Title>
                    <FontAwesomeIcon icon={faWalking} className="wellbeing-color larger" />
                    Virgin Pulse
                </Card.Title>
                <Card.Body>
                    
                    {cmsVirginPulseDetails ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsVirginPulseDetails.html }} /> : null}

                    <SharedPanel />

                    <Button variant="secondary"
                        onClick={handleSubmit}
                        className={`mt-4 ${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
                        disabled={!hasClaim_HealthAccess}
                    >
                        Access Virgin Pulse
                    </Button>
                </Card.Body>
            </Card>
            <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
            <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer="b" />
        </>);
    }
    
}
function mapStateToProps(state: AppState, ownProps: OwnProps) {
    const { username } = state.account;
    return {
        username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access),
        cmsData: state.cms.wellbeing,
        ...ownProps
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchWellBeingCmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VirginPulse);