import React from 'react';
import { AppState } from '../../../../store/configureStore';
import { connect, useDispatch } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation';
import { AppDispatch } from '../../../..';
import { LsriActionCreators } from '../../../../store/LsriStore';
import { OracleCalculation } from '../../../../Gateway.dtos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { DataLoadState } from '../../../../definitions/IEnumLoadableState';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

type TLifestageRIProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps { isExpanded?: boolean }
const LsriMppSidePanel = (props: TLifestageRIProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSmallScreen } = useWindowDimensions();
    const { lsri, isExpanded } = props

    const isVisited = lsri.lsriInfo?.oracleLsriElection?.visitedSteps?.mpp
    const includeMpp = lsri.userElection?.includeMpp
    const showUpdateErrMsg = lsri.calculationLoadState === DataLoadState.Error;
    const isMppAnnuityAvailable = lsri.lsriInfo.isMppAnnuityAvailable

    const handleClickSubmit = () => {
        dispatch(push(GatewayPath.LsriFunding));
    }

    const handleChange = (includeMppStr: string) => {

        const includeMpp: boolean = includeMppStr === "Y";

        //Only send data to server if changes were made
        if (includeMpp !== lsri.userElection?.includeMpp) {
            dispatch(LsriActionCreators.recalcOracleLsriElection({
                userElection: {
                    ...lsri.userElection,
                    includeMpp
                } as OracleCalculation
            }))
        }
    }


    if (!lsri?.lsriInfo?.isEligible || lsri.mppIneligible || ((includeMpp === undefined || !isVisited) && !isExpanded))
        return null


    if (!isExpanded) {
        return (<h5>

            <FontAwesomeIcon icon={faCheck} />&nbsp;

            {!includeMpp && "Keep as an annuity"}            

            {includeMpp && "Included in LifeStage"}

        </h5>);
    }

    const ErrorMsg = () => (
        <Alert variant="danger" className={`mt-2 mr-3 ${isSmallScreen ? '' : 'small'}`}>
            <FontAwesomeIcon icon={faExclamationCircle} /><strong>Error.</strong> An error occurred while updating your MPP Option.
        </Alert>
    )

    return (<div className="sidePanelContentText">

        <h4>See the effect of Including MPP 65% in LifeStage Retirement Income</h4>

        <div className="form-check">
            <label>
                <input
                    type="radio"
                    className="form-check-input"
                    name="mppSelection"
                    value="N"
                    checked={!includeMpp}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e.target.value) }}
                    disabled={!isMppAnnuityAvailable}
                    tabIndex={1}
                />
                <div className="title">Keep MPP 65% as an annuity</div>
                <div className="subTitle">(Set up separately in Benefits Access)</div>
            </label>
        </div>

        <div className="form-check">
            <label>
                <input
                    type="radio"
                    className="form-check-input"
                    name="mppSelection"
                    value="Y"
                    checked={includeMpp}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChange(e.target.value) }}
                    disabled={!isMppAnnuityAvailable}
                    tabIndex={2}
                />
                <div className="title">Include MPP 65% in LifeStage Retirement Income</div>
                <div className="subTitle">(Funds will continue to be invested)</div>
            </label>
        </div>

        {showUpdateErrMsg && <ErrorMsg />}

        <div className="buttonAndContent">
            <Button className="px-0" variant="link" onClick={() => handleClickSubmit()}>
                <strong>Next</strong> - funding LifeStage
            </Button>

            <div>
                Your progress is saved automatically.
            </div>
        </div>

    </div>);
};

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        ...ownProps,
        lsri: state.lsri
    }
}

export default connect(
    mapStateToProps
)(LsriMppSidePanel);

