import { AppState } from "./configureStore";
import { BankAccount, GetManageDirectDepositInfo, GetManageDirectDepositInfoResponse, UpdateBankAccount, ValidateBankAccountInfo } from "./../Gateway.dtos";
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { IEnumLoadableState, DataLoadState } from "../definitions/IEnumLoadableState";
import { client } from "../App";
import { AppDispatch } from "..";
import { getResetAction } from "../functions/getResetAction";
import { accountActionCreators } from "./AccountStore";

interface Error {
    errorMessage: string | unknown
}

export interface IManageDirectDepositState extends IEnumLoadableState {
    errorMessage: string | undefined,
    manageDirectDepositInfo: GetManageDirectDepositInfoResponse,
    bankAcctValidationMsg?: string,
    denyCount: number,
    updateLoadState: DataLoadState,
    showSuccessMsg: boolean,
    bankOwnership: string
}

export const initialManageDirectDeposit: IManageDirectDepositState = {
    manageDirectDepositInfo: {} as GetManageDirectDepositInfoResponse,
    loadState: DataLoadState.None,
    errorMessage: "",
    bankAcctValidationMsg: undefined,
    denyCount: 0,
    updateLoadState: DataLoadState.None,
    showSuccessMsg: false,
    bankOwnership: ""
};

const fetchManageDirectDepositInfo = createAsyncThunk<
    GetManageDirectDepositInfoResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'manageDirectDeposit/GetManageDirectDepositInfo',
    async (_, thunkApi) => {
        try {

            const manageDirectDepositInfo = await client.post(new GetManageDirectDepositInfo());

            if (manageDirectDepositInfo.responseStatus && manageDirectDepositInfo.responseStatus?.message) {
                if (manageDirectDepositInfo.responseStatus.errorCode !== "100") {
                    throw manageDirectDepositInfo;
                }
            }

            return manageDirectDepositInfo;

        } catch (e: unknown) {
            console.log(e);
            const response: GetManageDirectDepositInfoResponse = e as GetManageDirectDepositInfoResponse;
            if (response?.responseStatus?.errorCode?.toLowerCase() === "unauthorized")
                thunkApi.dispatch(accountActionCreators.accessDenied("You don't have access to this page."));

            return thunkApi.rejectWithValue(response?.responseStatus ? { errorMessage: "An error occurred while fetching your Direct Deposit information." } : { errorMessage: "Unknown error." });
        }
    }

)

const validateBankInfo = createAsyncThunk<
    { bankAcctValidationMsg?: string, bankOwnership?: string, denyCount: number },
    { bankAccount: BankAccount },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'manageDirectDeposit/ValidateBankAccountInfo',
    async (_, thunkApi) => {
        try {

            const bankAccount = _.bankAccount
            const response = await client.post(new ValidateBankAccountInfo({ bankAccount: bankAccount, isLsriBankAccountValidation: false }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            return {
                bankAcctValidationMsg: response.bankAcctValidationMsg,
                bankOwnership: response.bankOwnership,
                denyCount: response.denyCount
            };

        } catch (e: unknown) {
            console.log(e);
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const updateBankInfo = createAsyncThunk<
    { bankAccount: BankAccount },
    { bankAccount: BankAccount },
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'manageDirectDeposit/updateBankAccount',
    async (_, thunkApi) => {
        try {

            const bankAccount = _.bankAccount

            const response = await client.post(new UpdateBankAccount({ bankAccount }));

            //Check for errors
            if (response.responseStatus)
                throw response.responseStatus.message;

            return { bankAccount };

        } catch (e: unknown) {
            return thunkApi.rejectWithValue({ errorMessage: e });
        }
    }

)

const resetUpdateLoadState = createAction('manageDirectDeposit/resetUpdateLoadState');

const slice = createSlice({
    name: 'manageDirectDeposit',
    initialState: initialManageDirectDeposit,
    reducers: {
        resetState: () => initialManageDirectDeposit
    },
    extraReducers: builder => {
        builder
            .addCase(fetchManageDirectDepositInfo.pending, (state: IManageDirectDepositState) => {
                state.loadState = DataLoadState.Loading;
            })
            .addCase(fetchManageDirectDepositInfo.fulfilled, (state: IManageDirectDepositState, { payload }) => {
                //state.loadState = DataLoadState.Loaded
                state.loadState = DataLoadState.None
                state.manageDirectDepositInfo = payload;
            })
            .addCase(fetchManageDirectDepositInfo.rejected, (state: IManageDirectDepositState, action) => {
                state.loadState = DataLoadState.Error;
                state.errorMessage = typeof action.payload?.errorMessage === 'string'  ? action.payload?.errorMessage.toString() : "";
            })
            .addCase(validateBankInfo.pending, (state: IManageDirectDepositState) => {
                state.bankAcctValidationMsg = undefined
                state.updateLoadState = DataLoadState.None
            })
            .addCase(validateBankInfo.fulfilled, (state: IManageDirectDepositState, { payload }) => {
                state.bankAcctValidationMsg = payload.bankAcctValidationMsg
                //state.lsriInfo.bankAccount.bankName = state.lsriInfo.bankAccount.bankName.replace(/&/g, '').replace(/'/g, "").replace(/|/g, "").replace(/`/g, "").replace(/"/g, "")
                //if (payload.bankOwnership) state.lsriInfo.bankAccount.bankOwnership = payload.bankOwnership
                if (payload.bankOwnership) state.bankOwnership = payload.bankOwnership
                state.denyCount = payload.denyCount
            })
            .addCase(validateBankInfo.rejected, (state: IManageDirectDepositState) => {
                state.updateLoadState = DataLoadState.Error
            })
            .addCase(resetUpdateLoadState, (state: IManageDirectDepositState) => {
                state.updateLoadState = DataLoadState.None
                state.bankAcctValidationMsg = undefined
                state.showSuccessMsg = false
            })
            .addCase(updateBankInfo.pending, (state: IManageDirectDepositState) => {
                state.updateLoadState = DataLoadState.Loading
                state.bankAcctValidationMsg = undefined
            })
            .addCase(updateBankInfo.fulfilled, (state: IManageDirectDepositState, { payload }) => {
                //state.bankAccount = payload.bankAccount
                state.updateLoadState = DataLoadState.Loaded
                state.showSuccessMsg = true;
            })
            .addCase(updateBankInfo.rejected, (state: IManageDirectDepositState) => {
                state.updateLoadState = DataLoadState.Error
            })
            .addCase(getResetAction(), (_state, _action) => initialManageDirectDeposit)
    }
})

const { resetState } = slice.actions;

export const manageDirectDepositActionCreators = {
    fetchManageDirectDepositInfo,
    validateBankInfo,
    resetUpdateLoadState,
    updateBankInfo,
    resetState
};

export const manageDirectDepositReducer = slice.reducer;