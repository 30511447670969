
const convertStringToNumber = (val: string): number => {
    if (val && val.trim() !== "") {
        return parseFloat(val);
    }
    else {
        return 0;
    }
}

const convertDollarPctToNumber = (value: string | undefined): number => {

    if (value === undefined || value === "" || value === "." || value === "$." || value === ".%") value = "0"

    return parseFloat(value.replace("$", "").replace(/,/gi, ""))
}

export { convertStringToNumber, convertDollarPctToNumber };

