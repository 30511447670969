import * as React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { formatMoney } from "../../../functions/formatMoney";
import { PlanGroupOptionDetails } from "../../../Gateway.dtos";
import { FormatDollar } from "../../FormatDollar";
import { FormatUnits } from "../../FormatUnits";
import { MobileLabelAndContent, MobileTotal } from "../../MobileLabelAndContent";
import { PlanGridOptionGridRow } from "./PlanGridOptionGridRow";


export interface MybenefitsInvestmentsProps { planGroupOption: PlanGroupOptionDetails[], planGroupBalanceTotal: number, planGroupRatioTotal: number }
function PlanGridOptionGrid(props: MybenefitsInvestmentsProps): React.ReactElement {
    const planGroupOption = props.planGroupOption;

    return (<>
        <div>
            <Table size="sm" className="table-striped-ba mb-4">
                <thead>
                    <tr className="pl-2">
                        <th className="text-left border-left border-right border-bottom-none" colSpan={2}>Fund Name</th>
                        <th className="text-right border-right border-bottom-none">Unit Price</th>
                        <th className="text-right border-right border-bottom-none">Units</th>
                        <th className="text-right border-right border-bottom-none">Current Balance</th>
                        <th className="text-right border-right border-bottom-none">% of Current Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        planGroupOption.map((lstPlanGroup, i) => {
                            return (
                                <PlanGridOptionGridRow planList={lstPlanGroup} i={i} key={i} />
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr className="border-bottom">
                        <td className="text-left border-left border-right" colSpan={4}>&nbsp;</td>
                        <td className="text-right border-right"><strong>{formatMoney(props.planGroupBalanceTotal, true)}</strong></td>
                        <td className="text-right border-right"><strong>{props.planGroupRatioTotal}%</strong></td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    </>)
}

function MobilePlanGridOptionGrid(props: MybenefitsInvestmentsProps): React.ReactElement {
    const planGroupOption = props.planGroupOption;

    return (<>
        <div className="summary-table-mobile">
            {
                planGroupOption.map((planList, i) => 
                    <React.Fragment key={"plangrp" + i}>
                        <Row className="summary-table-mobile-type">
                            <Col className="col-1 px-0 mx-0">
                                <span style={{ "backgroundColor": planList.color, "width": "20px", "height": "10px", "border": "solid 1px black", "display": "inline-block" }}></span>
                            </Col>
                            <Col className="col-11 px-0 mx-0">
                                <a className="secondary" style={{ whiteSpace: "nowrap" }} title={planList.tooltip} href={planList.link} target="_blank" rel="noreferrer">
                                    {planList.name}
                                </a>
                            </Col>
                        </Row>

                        <Card className="summary-table-mobile-card">
                            <Card.Body>
                                <MobileLabelAndContent
                                    label="Unit Price" value={<FormatDollar amount={planList.price} fractionDigits={4} />}
                                    label2="Units" value2={<FormatUnits units={planList.units} />} />
                                <MobileLabelAndContent
                                    label="Current Balance" value={formatMoney(planList.balance, true)}
                                    label2="% of Current Balance" value2={planList.ratio + "%"} />
                            </Card.Body>
                        </Card>
                    </React.Fragment>
                )
            }

        </div>
        <MobileTotal label="Total for all current balance" value={formatMoney(props.planGroupBalanceTotal, true)} />
        <MobileTotal label="Total for all % of current balance" value={props.planGroupRatioTotal + "%"} />

    </>)
}

export { PlanGridOptionGrid, MobilePlanGridOptionGrid };