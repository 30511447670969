import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../store/configureStore';
import { AppDispatch } from '..';
import { accountActionCreators } from '../store/AccountStore';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { eySearchActionCreators } from '../store/EySearchStore';
import { EY } from '../definitions/Claims';

type TEYSearchProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const EYSearchBanner = (props: TEYSearchProps) => {
    const { fetchEySearch, isLoading, isLoaded, hasClaim_Search, eySearch } = props;

    const [partNumSearch, setPartNumSearch] = useState('');    

    const pptNotFound = isLoaded && !eySearch?.pptIsFound && !eySearch?.responseStatus;   

    const enableSubmit = () => {
        return partNumSearch.length === 7 || partNumSearch.length === 9;
    }

    const clickSearch = () => {
        if (enableSubmit()) {
            fetchEySearch(partNumSearch);
            setPartNumSearch('');
        }
    }

    useEffect(() => {
        if (partNumSearch.length > 9) {
            setPartNumSearch(partNumSearch.substring(0, 9))
        }
    }, [partNumSearch, setPartNumSearch])

    return (<>
        {hasClaim_Search && <div className="utilityBar action">
            <div className="container maxWidth">

                {pptNotFound && <Alert variant="error">Participant not found.</Alert>}

                <h1>Find a Participant</h1>

                Use a participant number or Social Security Number to search and view the retirement benefit details of a 
                participant that has authorized EY access.
           
                <Row className="mt-3">
                    <Col className="col-12 col-md-5 col-lg-4 col-xl-3">
                        <Form.Group className="mb-3 mb-md-0">
                            <div className="input-group">
                                <Form.Control
                                    type="text"
                                    value={partNumSearch}
                                    disabled={isLoading}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPartNumSearch(e.target.value.replace(/[^0-9]/gi, "")) }}
                                    maxLength={11}
                                    tabIndex={1}
                                    autoFocus
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button variant="primary" type="submit" tabIndex={2} onClick={() => { clickSearch(); }} disabled={isLoading || !enableSubmit()}>
                            {isLoading ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : "Find Participant"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>}
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        ...state.eySearch,
        hasClaim_Search: state.claims.claims?.claims?.find(c => c === EY.Search)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...eySearchActionCreators, ...accountActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EYSearchBanner);
